import { assertNotUndefined } from "utils/hx/util/types";
import React, { useState, useCallback } from "react";
import { DeliveryOptionReq } from "adl-gen/ferovinum/app/deliveryoptions";
import { AppService } from "adl-gen/app-service";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { RepurchaseRequest } from "../organisation-repurchase-stock/organisation-repurchase-stock-page";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { AppRoutes } from "../../../../../app/app-routes";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { Loader } from "components/widgets/loader/loader";
import { isLoaded } from "utils/utility-types";
import { OrganisationRepurchaseDeliveryPageView } from "./organisation-repurchase-delivery-page-view";
import { useAppService } from "../../../../../hooks/use-app-service";
import { StorageLocationId } from "adl-gen/ferovinum/app/db";

export interface RepurchaseFlowState {
  repurchaseRequest: RepurchaseRequest;
  deliveryOptionRequest?: DeliveryOptionReq;
  destinationLocationId?: StorageLocationId;
}

export const OrganisationRepurchaseDeliveryPage = () => {
  const location = useLocation();
  const history = useHistory();
  const repurchaseFlowState = location.state as RepurchaseFlowState | undefined;
  const service: AppService = useAppService();

  const selectedOrgId = assertNotUndefined(useSelectedOrgId());

  const [initialDeliveryReq] = useState<DeliveryOptionReq>(
    repurchaseFlowState?.deliveryOptionRequest ?? {
      kind: "inWarehouseTransfer",
      value: {},
    },
  );

  const getOrganisationDeliveryLocations = useCallback(
    async () => await service.getOrganisationDeliveryLocations({ organisationId: selectedOrgId }),
    [selectedOrgId, service],
  );
  const [loadingOrganisationDeliveryLocations] = useLoadingDataState(getOrganisationDeliveryLocations);

  const getStorageLocationView = useCallback(async () => {
    const storageLocationId = assertNotUndefined(repurchaseFlowState?.repurchaseRequest?.storageLocationId);
    const locations = await service.getStorageLocations({
      orgId: selectedOrgId,
      selector: { kind: "byIds", value: [storageLocationId] },
    });
    return assertNotUndefined(locations[0]);
  }, [selectedOrgId, repurchaseFlowState, service]);
  const [loadingStorageLocationView] = useLoadingDataState(getStorageLocationView);

  const getInternalTransferLocations = useCallback(async () => {
    if (!isLoaded(loadingStorageLocationView)) {
      return [];
    }

    // If the location is not a part of a group, a user can only select the current locations site code
    if (!loadingStorageLocationView.value.warehouseGroup) {
      return loadingStorageLocationView.value.siteCode ? [loadingStorageLocationView.value] : [];
    }

    const locations = await service.getStorageLocations({
      orgId: selectedOrgId,
      selector: {
        kind: "byWarehouseGroup",
        value: loadingStorageLocationView.value.warehouseGroup,
      },
    });

    return locations.filter(loc => loc.siteCode);
  }, [loadingStorageLocationView, selectedOrgId, service]);
  const [loadingInternalTransferLocations] = useLoadingDataState(getInternalTransferLocations);

  const onConfirm = useCallback(
    (deliveryOptionRequest: DeliveryOptionReq, destinationLocationId?: StorageLocationId) => {
      const state: RepurchaseFlowState = {
        deliveryOptionRequest,
        destinationLocationId,
        repurchaseRequest: assertNotUndefined(repurchaseFlowState).repurchaseRequest,
      };
      history.push(AppRoutes.RepurchaseConfirmation, state);
    },
    [history, repurchaseFlowState],
  );

  const onBack = useCallback(() => {
    history.push(AppRoutes.Repurchase, repurchaseFlowState);
  }, [history, repurchaseFlowState]);

  if (repurchaseFlowState === undefined || repurchaseFlowState.repurchaseRequest?.productsSaleOrders.length === 0) {
    return <Redirect to={AppRoutes.Index} />;
  } else {
    return (
      <Loader loadingStates={[loadingOrganisationDeliveryLocations, loadingStorageLocationView]} fullScreen>
        {isLoaded(loadingOrganisationDeliveryLocations) &&
          isLoaded(loadingStorageLocationView) &&
          isLoaded(loadingInternalTransferLocations) && (
            <OrganisationRepurchaseDeliveryPageView
              repurchaseRequest={repurchaseFlowState.repurchaseRequest}
              initialDeliveryReq={initialDeliveryReq}
              storageLocation={loadingStorageLocationView.value}
              organisationDeliveryLocations={loadingOrganisationDeliveryLocations.value}
              internalTransferLocations={loadingInternalTransferLocations.value}
              onConfirm={onConfirm}
              onBack={onBack}
            />
          )}
      </Loader>
    );
  }
};
