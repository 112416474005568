import React from "react";
import { BaseNoteProps, StickyNote } from "../sticky-note";

export interface DeliveryAddressStickyNoteProps extends Partial<BaseNoteProps> {
  deliveryPointName: string;
  streetName: string;
  town: string;
  postCode: string;
}
export const DeliveryAddressStickyNote = ({
  title,
  action,
  deliveryPointName,
  streetName,
  town,
  postCode,
}: DeliveryAddressStickyNoteProps) => {
  return (
    <StickyNote
      title={title ?? "Delivery address"}
      line1={deliveryPointName}
      line2={streetName}
      line3={`${town}, ${postCode}`}
      action={action}
    />
  );
};
