import React, { FC } from "react";
import { NewDealRequestState, NewDealRequestStateEvent } from "adl-gen/ferovinum/app/db";
import { Extends } from "utils/ts-utils";
import { LocalDate } from "adl-gen/common";
import { formatLocalDate } from "utils/date-utils";
import {
  NewDealRequestStep,
  NewDealRequestStateStepData,
  NewDealRequestStateStepper,
  NewDealRequestStateStepperProps,
} from "components/widgets/new-deal-requests/new-deal-request-state-stepper/new-deal-request-state-stepper";

export type CarrierNewDealRequestState =
  | Extends<
      NewDealRequestState,
      "collectionRequested" | "collectionAccepted" | "collectionConfirmed" | "carrierDeliveryConfirmed"
    >
  | "carrierIsPaid";
const CARRIER_VISIBLE_STATES = [
  "collectionAccepted",
  "collectionConfirmed",
  "carrierDeliveryConfirmed",
  "carrierIsPaid",
] as const;

/** Utility function to transform events from a procurement flow into steps data used in this widget */
export function procurementEventsToCarrierStepsData(
  events: NewDealRequestStateEvent[],
  carrierIsPaidTimestamp: number | null,
  supplierEarliestCollectionDate: LocalDate | null,
  carrierEarliestCollectionDate: LocalDate | null,
): Partial<Record<CarrierNewDealRequestState, NewDealRequestStateStepData>> {
  const result = events.reduce(
    (m, e) => {
      if (CARRIER_VISIBLE_STATES.findIndex(s => s === e.state) > -1) {
        switch (e.state) {
          case "collectionRequested": {
            m[e.state] = {
              instant: e.time,
              description: supplierEarliestCollectionDate
                ? `Earliest collection ${formatLocalDate(supplierEarliestCollectionDate)}`
                : undefined,
            };
            break;
          }
          case "collectionAccepted": {
            m[e.state] = {
              instant: e.time,
              description: carrierEarliestCollectionDate
                ? `Confirmed collection ${formatLocalDate(carrierEarliestCollectionDate)}`
                : undefined,
            };
            break;
          }
          default: {
            // @ts-ignore
            m[e.state] = { instant: e.time };
            break;
          }
        }
      }
      return m;
    },
    {} as Partial<Record<CarrierNewDealRequestState, NewDealRequestStateStepData>>,
  );
  if (carrierIsPaidTimestamp !== null) {
    result.carrierIsPaid = { instant: carrierIsPaidTimestamp };
  }
  return result;
}

const CARRIER_STEPS_BY_STATE: Record<CarrierNewDealRequestState, NewDealRequestStep<CarrierNewDealRequestState>> = {
  collectionRequested: {
    state: "collectionRequested",
    label: "Shipment Requested",
  },
  collectionAccepted: {
    state: "collectionAccepted",
    label: "Shipment accepted",
  },
  collectionConfirmed: {
    state: "collectionConfirmed",
    label: "Shipment collected",
  },
  carrierDeliveryConfirmed: {
    state: "carrierDeliveryConfirmed",
    label: "Shipment delivered",
  },
  carrierIsPaid: {
    state: "carrierIsPaid",
    label: "Freight invoice paid by Ferovinum",
  },
};

function getProcurementSteps(): NewDealRequestStep<CarrierNewDealRequestState>[] {
  return CARRIER_VISIBLE_STATES.map(s => CARRIER_STEPS_BY_STATE[s]);
}

export type CarrierNewDealRequestStateStepperProps = Omit<
  NewDealRequestStateStepperProps<CarrierNewDealRequestState>,
  "getNewDealRequestStateSteps"
>;
export const CarrierNewDealRequestStateStepper: FC<CarrierNewDealRequestStateStepperProps> = props => {
  return <NewDealRequestStateStepper {...props} getNewDealRequestStateSteps={getProcurementSteps} />;
};
