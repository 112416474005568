import { texprUiConfig } from "adl-gen/ferovinum/app/uiconfig";
import { RESOLVER } from "adl-gen/resolver";
import { createJsonBinding, Json } from "adl-gen/runtime/json";

declare const window: Window & {
  /** UI config JSON injected via index.html */
  UI_CONFIG: Json;
};

const uiConfigJb = createJsonBinding(RESOLVER, texprUiConfig());

// Fetch and parse the typed UiConfig record from the window
// This has been populated via a script tag in index.html
export function parseUiConfig() {
  const uiConfigStr = window.UI_CONFIG;

  if (!uiConfigStr) {
    throw new Error("UI_CONFIG not populated");
  }
  try {
    return uiConfigJb.fromJson(uiConfigStr);
  } catch (e) {
    throw new Error("Could not parse UI_CONFIG: " + e);
  }
}
