import { SnackbarContent, Stack, TextField, Typography } from "@mui/material";
import React from "react";

import { LoadingButton } from "@mui/lab";
import { FerovinumLogo } from "components/logos/logos";
import { Link } from "components/widgets/link/link";
import { AppRoutes } from "../../../app/app-routes";

export interface ForgotPasswordPageProps {
  onRequestResetPasswordLink: (email: string) => Promise<{ success: boolean }>;
}

/**
 * Landing page of the app.
 */
export const ForgotPasswordPage = (props: ForgotPasswordPageProps) => {
  const { onRequestResetPasswordLink } = props;
  const [email, setEmail] = React.useState<string>("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const onForgotPassword = async () => {
    // If we have already successfully sent a link, don't double send
    if (success || loading) {
      return;
    }

    setLoading(true);
    const res = await onRequestResetPasswordLink(email);
    if (res.success) {
      setSuccess(true);
    }
    setLoading(false);
  };

  /** Render the page */
  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <FerovinumLogo color="purple" />
        <Typography variant="h4">Forgot Password</Typography>
      </Stack>
      <Stack spacing={2}>
        {success && (
          <SnackbarContent
            message={"Password reset link was sent successfully. Please check your email to reset your password."}
            sx={{
              marginTop: theme => theme.spacing(1),
              marginBottom: theme => theme.spacing(1),
              backgroundColor: theme => theme.palette.success.main,
            }}
          />
        )}
        <Typography>Please enter the email address associated with your ferovinum account.</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </Stack>

      <Stack spacing={2} alignContent={"center"} justifyContent={"center"} textAlign={"center"}>
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          onClick={onForgotPassword}
          loading={loading}
          disabled={success || loading}>
          Request Reset Link
        </LoadingButton>
        <Link variant="big" href={AppRoutes.Login}>
          Back to Login
        </Link>
      </Stack>
    </Stack>
  );
};
