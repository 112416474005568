import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { BigDecimal, Instant, LocalDate } from "adl-gen/common";
import { DbKey } from "adl-gen/common/db";
import {
  CollectionDetails,
  NominatedPurchasersForPurchaser,
  PurchaseRequestBreakdownForPurchaser,
  PurchaserPurchaseRequestView,
} from "adl-gen/ferovinum/app/api";
import {
  Currency,
  NominatedPurchaserTerms,
  Organisation,
  OrganisationId,
  PurchaseRequest,
  PurchaseRequestDeliveryOption,
  PurchaseRequestPaymentTermsPeriod,
  PurchaseRequestSalePriceType,
  PurchaseRequestStateEvent,
} from "adl-gen/ferovinum/app/db";
import { useConfirmationDialog, useTermsDialog } from "components/context/global-dialog/use-dialog";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import { Dropdown } from "components/widgets/dropdown/dropdown";
import { InfoField } from "components/widgets/info-field/info-field";
import React, { useCallback, useMemo, useState } from "react";
import { formatDate } from "utils/date-utils";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { assertNotUndefined } from "utils/hx/util/types";
import { isLoaded, isLoading } from "utils/utility-types";
import { PortalPageContentHeader } from "../../../../../../../apps/client/src/ui/layouts/portal-page-content-header/portal-page-content-header";
import { AppRoutes } from "../../../../app/app-routes";
import {
  formatDiscountPct,
  getPurchaseRequestStateDescription,
  isPurchaseRequestFinalState,
} from "../../../../utils/purchase-request-utils";
import { PortalPageContent } from "../../../layouts/portal-page-content/portal-page-content";
import { ColGroup } from "../../../widgets/colgroup";
import { ActionableSummaryCard } from "../../../widgets/common/actionable-summary-card/actionable-summary-card";
import { OrganisationProductSummary } from "../../../widgets/organisation-product-summary/organisation-product-summary";
import { buildSalePriceSuffix } from "../../../widgets/purchase-requests/organisation-product-selection-table/organisation-product-selection-table";
import { AcceptPurchaseRequestCollectionDialog } from "../../../widgets/purchase-requests/purchaser/accept-purchase-request-dialog/accept-purchase-request-collection-dialog";
import { AcceptPurchaseRequestDeliveryDialog } from "../../../widgets/purchase-requests/purchaser/accept-purchase-request-dialog/accept-purchase-request-delivery-dialog";
import { useIsPaymentOverdue } from "../hooks/purchaser-payment-request-hooks";

export interface PurchaserPurchaseRequestsPageViewProps {
  activeOrganisation?: OrganisationId;
  purchaseRequests: PurchaserPurchaseRequestView[];
  organisations: NominatedPurchasersForPurchaser[];

  onChangeOrganisation(id: DbKey<Organisation>): void;

  getPurchaseRequestBreakdown(id: DbKey<PurchaseRequest>): Promise<PurchaseRequestBreakdownForPurchaser>;

  onTermsAccepted: (
    purchaseRequestId: DbKey<PurchaseRequest>,
    collectionDetails: CollectionDetails | null,
  ) => Promise<void>;
  onTermsRejected: (purchaseRequestId: DbKey<PurchaseRequest>) => Promise<void>;
  onFetchTerms: (purchaseRequestId: DbKey<PurchaseRequest>) => Promise<NominatedPurchaserTerms>;
}

export const PurchaserPurchaseRequestsPageView = ({
  activeOrganisation,
  purchaseRequests,
  organisations,
  onChangeOrganisation,
  getPurchaseRequestBreakdown,
  onTermsAccepted,
  onTermsRejected,
  onFetchTerms,
}: PurchaserPurchaseRequestsPageViewProps) => {
  const selectedOrganisation = organisations.find(p => p.organisationId === activeOrganisation);
  const [activePurchaseRequest, setActivePurchaseRequest] = useState<PurchaserPurchaseRequestView>();

  const showAcceptPurchaseModal = async (purchaseRequest: PurchaserPurchaseRequestView) => {
    setActivePurchaseRequest(purchaseRequest);
  };
  const { showTermsDialog } = useTermsDialog();
  const termsClicked = async (purchaseRequestId: string) => {
    const { terms } = await onFetchTerms(purchaseRequestId);
    await showTermsDialog({ terms: [terms] });
  };

  return (
    <PortalPageContent header={<PortalPageContentHeader title="Orders" />}>
      <Stack spacing={2}>
        {organisations.length > 0 && selectedOrganisation ? (
          <Stack spacing={2} divider={<Divider sx={{ color: "common.grey3" }} />}>
            <>
              {activePurchaseRequest &&
                activePurchaseRequest.purchaseRequestDeliveryOption.kind == "collectFromStorageLocation" && (
                  <AcceptPurchaseRequestCollectionDialog
                    {...activePurchaseRequest}
                    open={true}
                    onAccept={async (collectionDetails: CollectionDetails | null) => {
                      await onTermsAccepted(activePurchaseRequest.purchaseRequestId, collectionDetails);
                      setActivePurchaseRequest(undefined);
                    }}
                    onCancel={() => setActivePurchaseRequest(undefined)}
                  />
                )}
              {activePurchaseRequest &&
                activePurchaseRequest.purchaseRequestDeliveryOption.kind == "deliveryToNominatedPurchaser" && (
                  <AcceptPurchaseRequestDeliveryDialog
                    {...activePurchaseRequest}
                    open={true}
                    onAccept={async () => {
                      await onTermsAccepted(activePurchaseRequest.purchaseRequestId, null);
                      setActivePurchaseRequest(undefined);
                    }}
                    onCancel={() => setActivePurchaseRequest(undefined)}
                  />
                )}
              <Dropdown<OrganisationId>
                inputLabel=""
                defaultValue={{
                  label: selectedOrganisation.organisationName,
                  value: selectedOrganisation.organisationId,
                }}
                menuItems={organisations.map(p => ({
                  label: p.organisationName,
                  value: p.organisationId,
                }))}
                onChange={v => onChangeOrganisation(assertNotUndefined(v))}
              />
            </>

            {purchaseRequests.length === 0 ? (
              <Typography variant="body1" color="common.grey7">
                You have no purchase requests
              </Typography>
            ) : (
              <>
                {purchaseRequests.map(pr => (
                  <PurchaseRequestDetails
                    key={pr.purchaseRequestId}
                    purchaseRequest={pr}
                    getPurchaseRequestBreakdown={getPurchaseRequestBreakdown}
                    acceptClicked={async () => showAcceptPurchaseModal(pr)}
                    rejectClicked={() => onTermsRejected(pr.purchaseRequestId)}
                    termsClicked={() => termsClicked(pr.purchaseRequestId)}
                  />
                ))}
              </>
            )}
          </Stack>
        ) : (
          <Typography variant="body1" color="common.grey7">
            You have no organisations
          </Typography>
        )}
      </Stack>
    </PortalPageContent>
  );
};

interface PurchaseRequestDetailsProps {
  purchaseRequest: PurchaserPurchaseRequestView;

  getPurchaseRequestBreakdown(id: DbKey<PurchaseRequest>): Promise<PurchaseRequestBreakdownForPurchaser>;

  acceptClicked: () => Promise<void>;
  rejectClicked: () => Promise<void>;
  termsClicked: () => Promise<void>;
}

const PurchaseRequestDetails = ({
  purchaseRequest,
  getPurchaseRequestBreakdown,
  acceptClicked,
  rejectClicked,
  termsClicked,
}: PurchaseRequestDetailsProps) => {
  const isInFinalState = isPurchaseRequestFinalState(purchaseRequest.state);
  const showAcceptOrRejectAction = (purchaseRequest: PurchaserPurchaseRequestView): boolean => {
    return purchaseRequest.state === "new";
  };

  const [loadingBreakdown] = useLoadingDataState(
    useCallback(
      () => getPurchaseRequestBreakdown(purchaseRequest.purchaseRequestId),
      [purchaseRequest, getPurchaseRequestBreakdown],
    ),
  );

  return (
    <ActionableSummaryCard
      isInitiallyExpanded={!isInFinalState && isLoaded(loadingBreakdown)}
      expandText="Products in this purchase request"
      cardContent={
        isLoaded(loadingBreakdown) && (
          <ProductsTable
            breakdown={loadingBreakdown.value}
            salePriceType={purchaseRequest.salePriceType}
            currency={purchaseRequest.currency}
          />
        )
      }
      isLoading={isLoading(loadingBreakdown)}
      title={purchaseRequest.purchaseRequestNumber}
      titleHref={`${AppRoutes.PurchaserPurchaseRequestDetails}/${purchaseRequest.purchaseRequestId}`}
      cardActions={
        showAcceptOrRejectAction(purchaseRequest) && (
          <AcceptOrderActions rejectClicked={rejectClicked} acceptClicked={acceptClicked} termsClicked={termsClicked} />
        )
      }
      headerContent={<PurchaseRequestDetailsHeader {...purchaseRequest} />}
    />
  );
};

interface AcceptOrderActionsProps {
  rejectClicked: () => Promise<void>;
  acceptClicked: () => Promise<void>;
  termsClicked: () => Promise<void>;
}

const AcceptOrderActions = ({ rejectClicked, acceptClicked, termsClicked }: AcceptOrderActionsProps) => {
  const { showConfirmationDialog } = useConfirmationDialog();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const onShowTerms = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    await termsClicked();
  };

  const handleAction = async (fn: () => Promise<void>) => {
    setLoading(true);
    await fn();
    setLoading(false);
  };

  return (
    <Stack direction={"row"} spacing={2}>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox value={acceptTerms} onClick={() => setAcceptTerms(!acceptTerms)} disabled={loading} />}
          label={
            <Stack direction="row" spacing={1}>
              <Typography>I agree to the </Typography>
              <Link onClick={onShowTerms}>Terms</Link>
            </Stack>
          }
        />
      </FormGroup>
      <LoadingActionButton
        variant="outlined"
        color="error"
        loading={loading}
        onClick={async () => {
          await showConfirmationDialog({
            title: "Are you sure you want to reject these terms?",
            confirmAction: {
              onClick: async () => {
                await rejectClicked();
              },
            },
          });
        }}>
        Reject
      </LoadingActionButton>
      <LoadingActionButton
        variant="contained"
        color="success"
        loading={loading}
        disabled={!acceptTerms}
        onClick={async () => handleAction(acceptClicked)}>
        Accept
      </LoadingActionButton>
    </Stack>
  );
};

interface PurchaseRequestDetailsHeaderProps {
  stateEvents: PurchaseRequestStateEvent[];
  paymentTermsPeriod: PurchaseRequestPaymentTermsPeriod;
  collectionDays: number;
  purchaseRequestDeliveryOption: PurchaseRequestDeliveryOption;
  paidAt?: Instant;
  orgNetReceivablePaymentDate?: LocalDate;
  collectedDate: LocalDate | null;
  deliveredDate: LocalDate | null;
}

const PurchaseRequestDetailsHeader = ({
  stateEvents,
  paymentTermsPeriod,
  collectionDays,
  purchaseRequestDeliveryOption,
  paidAt,
  orgNetReceivablePaymentDate,
  collectedDate,
  deliveredDate,
}: PurchaseRequestDetailsHeaderProps) => {
  const stateText = getPurchaseRequestStateDescription({
    stateEvents,
    collectionDays,
    purchaseRequestDeliveryOption,
    purchaserPaidAt: paidAt ?? null,
    orgNetReceivablePaymentDate: orgNetReceivablePaymentDate ?? null,
    collectedDate,
    deliveredDate,
  });
  const createdAt = formatDate(new Date(stateEvents[0].time));

  const isPaymentOverdue = useIsPaymentOverdue({ paymentTermsPeriod, stateEvents });

  return (
    <>
      <InfoField
        sx={{ alignItems: "flex-end", flexDirection: "column-reverse" }}
        label="Created on"
        value={createdAt}
        textAlign="right"
      />
      <InfoField
        sx={{ alignItems: "flex-end" }}
        value={stateText ?? ""}
        label={isPaymentOverdue ? "Payment is overdue. Please make a payment." : ""}
        color={isPaymentOverdue ? "common.error" : undefined}
        textAlign="right"
      />
    </>
  );
};

const ProductsTable = ({
  breakdown: { lineItems, netSubtotal, dealDiscountAmount },
  salePriceType,
  currency,
}: {
  breakdown: PurchaseRequestBreakdownForPurchaser;
  salePriceType: PurchaseRequestSalePriceType;
  currency: Currency;
}) => {
  const hasDiscount = lineItems.some(li => li.priceDiscount !== null);
  const colWidths = useMemo(() => [...[50, 20, 20, 20], ...(hasDiscount ? [20] : [])], [hasDiscount]);
  return (
    <>
      <Table>
        <ColGroup widths={colWidths} />
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <CenterTableCell>Quantity</CenterTableCell>
            <CenterTableCell>
              Unit purchase price <br />({buildSalePriceSuffix(salePriceType)})
            </CenterTableCell>
            {hasDiscount && (
              <CenterTableCell>
                Unit Discount Included
                <br />
                <Typography variant="caption">% of unit price</Typography>
              </CenterTableCell>
            )}
            <RightTableCell>Subtotal</RightTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {lineItems.map(({ product, paidUnits, unitPrice, priceDiscount, freeUnits, subTotal }) => {
            return (
              <TableRow key={product.id}>
                <TableCell>
                  <OrganisationProductSummary {...product.value} />
                </TableCell>

                <CenterTableCell>
                  <Typography>{paidUnits.value}</Typography>
                  {freeUnits && <Typography variant="caption">+{freeUnits.value} free</Typography>}
                </CenterTableCell>
                <CenterTableCell>
                  <CurrencyRenderer value={unitPrice} currency={currency} />
                </CenterTableCell>
                {hasDiscount && (
                  <CenterTableCell>
                    {priceDiscount ? (
                      <>
                        <CurrencyRenderer value={priceDiscount} currency={currency} />
                        <Typography variant="caption">{formatDiscountPct(unitPrice, priceDiscount)}%</Typography>
                      </>
                    ) : (
                      "-"
                    )}
                  </CenterTableCell>
                )}
                <RightTableCell>
                  <CurrencyRenderer value={subTotal} currency={currency} />
                </RightTableCell>
              </TableRow>
            );
          })}
          {dealDiscountAmount && (
            <TotalRow label="Deal Discount" currency={currency} value={dealDiscountAmount} colSpan={colWidths.length} />
          )}
          <TotalRow
            label="Net subtotal"
            currency={currency}
            value={netSubtotal}
            colSpan={colWidths.length}
            bold={true}
          />
        </TableBody>
      </Table>
    </>
  );
};

interface TotalRowProps {
  label: string;
  value: BigDecimal;
  currency: Currency;
  colSpan: number;
  bold?: boolean;
}

const TotalRow = ({ label, value, currency, colSpan, bold }: TotalRowProps) => {
  const textVariant = bold ? "subtitle1Bold" : undefined;
  return (
    <TableRow sx={{ backgroundColor: "common.grey1" }}>
      <TableCell colSpan={colSpan - 2} />
      <RightTableCell>
        <Typography variant={textVariant}>{label}</Typography>
      </RightTableCell>
      <RightTableCell>
        <CurrencyRenderer variant={textVariant} value={value} currency={currency} />
      </RightTableCell>
    </TableRow>
  );
};

const RightTableCell = styled(TableCell)({
  textAlign: "right",
});
const CenterTableCell = styled(TableCell)({
  textAlign: "center",
});
