import { Button } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid";
import { Instant } from "adl-gen/common";
import { DbKey, WithDbId } from "adl-gen/common/db";
import { StorageLocationView } from "adl-gen/ferovinum/app/api";
import { StorageLocation, StorageLocationId } from "adl-gen/ferovinum/app/db";
import { StockMovementWfState, WfState, Workflow, WorkflowId } from "adl-gen/ferovinum/app/workflow";
import { TabbedPage } from "components/library/widgets/tabbed-page";
import { GridTable, GridTableColumnFactory } from "components/widgets/grid-table/grid-table";
import { Loader } from "components/widgets/loader/loader";
import React, { useCallback, useState } from "react";
import { WorkflowQueryBuilder } from "utils/WorkflowQueryBuilder";
import { extractDeliveryStatus, titleCase } from "utils/conversion-utils";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { assertNotUndefined } from "utils/hx/util/types";
import { LoadingValue, isLoaded } from "utils/utility-types";
import { AppRoutes } from "../../../../app/app-routes";
import { useAppService } from "../../../../hooks/use-app-service";
import { PortalPageContentHeader } from "../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../layouts/portal-page-content/portal-page-content";
import { useSelectedOrgId } from "../../../layouts/portal-page-layout/portal-page";
interface StockMovementTableRow {
  stockMovementId: DbKey<Workflow>;
  reference: string;
  dateRequested: Instant;
  startingLocationName: string;
  destinationLocationName: string;
  state: StockMovementWfState;
  deliveryStatus: string;
}

// TODO: could change query to filter on actionable role -> if has role then in progress, else complete
const inProgressStates: Extract<StockMovementWfState, "requested" | "approved">[] = ["requested", "approved"];
const completedStates: Exclude<StockMovementWfState, "requested" | "approved">[] = [
  "completed",
  "rejected",
  "cancelled",
];
const PRODUCT_TABLE_COLUMNS = (() => {
  const factory = new GridTableColumnFactory<StockMovementTableRow>();
  return [
    factory.makeHyperLinkColumn({
      header: "Reference No.",
      name: "referenceNumber",
      hrefGetter: row => `${AppRoutes.StockMovementDetails}/${row.stockMovementId}`,
      getter: row => row.reference,
    }),
    factory.makeTextColumn({
      header: "Date Requested",
      name: "dateRequested",
      getter: row => new Date(row.dateRequested).toLocaleDateString(),
    }),
    factory.makeTextColumn({
      header: "From (Starting Location)",
      name: "startingLocation",
      getter: row => row.startingLocationName,
    }),
    factory.makeTextColumn({
      header: "To (Destination)",
      name: "destinationLocation",
      getter: row => row.destinationLocationName,
    }),
    factory.makeTextColumn({
      header: "State",
      name: "state",
      getter: row => titleCase(row.state),
    }),
    factory.makeTextColumn({
      header: "Delivery Status",
      name: "deliveryStatus",
      getter: row => row.deliveryStatus,
    }),
  ];
})();

export function StockMovementDashboardPage() {
  const service = useAppService();
  const organisationId = assertNotUndefined(useSelectedOrgId());
  const [filter, setFilter] = useState("inProgress");
  const [dataCache, setDataCache] = useState<{
    complete?: StockMovementTableRow[];
    inProgress?: StockMovementTableRow[];
  }>({});

  const [tableSortModel, setTableSortModel] = useState<{
    inProgress: GridSortModel;
    complete: GridSortModel;
  }>({ inProgress: [], complete: [] });

  const fetchStorageLocationViews = useCallback(
    async (storageLocationIds: StorageLocationId[]): Promise<Map<DbKey<StorageLocation>, StorageLocationView>> => {
      if (storageLocationIds.length === 0) {
        return new Map();
      }

      const storageLocations = new Map<DbKey<StorageLocation>, StorageLocationView>();
      (
        await service.getStorageLocations({
          orgId: organisationId,
          selector: { kind: "byIds", value: storageLocationIds },
        })
      ).forEach((storageLocation: StorageLocationView) => {
        storageLocations.set(storageLocation.id, storageLocation);
      });

      return storageLocations;
    },
    [organisationId, service],
  );

  const fetchStockMovements = useCallback(async () => {
    const complete = filter === "complete";
    const cachedData = dataCache[complete ? "complete" : "inProgress"];
    if (cachedData != undefined) return cachedData;
    const queryStates = complete ? completedStates : inProgressStates;
    const query = new WorkflowQueryBuilder()
      .addTypes("stock_movement")
      .addStates(...queryStates.map(state => ({ kind: "stock_movement", value: state }) as WfState))
      .build();
    const stockMovements = await service.orgWfQuery({
      organisationId: organisationId,
      params: query,
    });

    let transportWfs: WithDbId<Workflow>[] = [];
    const stockMovementIds: WorkflowId[] = stockMovements.map(wf => wf.id);
    if (stockMovementIds.length > 0) {
      transportWfs = await service.orgWfQuery({
        organisationId: organisationId,
        params: WorkflowQueryBuilder.createParentIdQuery(...stockMovementIds),
      });
    }
    const storageLocationViews = await fetchStorageLocationViews(
      stockMovements.reduce((acc, wf) => {
        if (wf.value.data.kind != "stock_movement") throw new Error("Invalid workflow data");
        const startingLocId = wf.value.data.value.request.startingLocationId;
        const destLocId = wf.value.data.value.request.destinationLocationId;
        return [...acc, startingLocId, destLocId];
      }, [] as StorageLocationId[]),
    );

    const rows = buildTableRows(stockMovements, transportWfs, storageLocationViews).sort(
      (a, b) => b.dateRequested - a.dateRequested,
    );

    setDataCache(data => ({ ...data, [complete ? "complete" : "inProgress"]: rows }));
    return rows;
  }, [filter, dataCache, service, organisationId, fetchStorageLocationViews]);

  const [loadingData] = useLoadingDataState(fetchStockMovements);

  return (
    <PortalPageContent header={<PortalPageContentHeader title="Stock Movement" />}>
      <TabbedPage
        handleTabChange={({ key }) => setFilter(assertNotUndefined(key))}
        tabs={[
          {
            label: "In Progress",
            key: "inProgress",
            content: (
              <LoadingTable
                loadingData={loadingData}
                sortModel={tableSortModel.inProgress}
                onSortModelChange={(sortModel: GridSortModel) =>
                  setTableSortModel(rest => ({ ...rest, inProgress: sortModel }))
                }
              />
            ),
            toolbar: actionButton(),
          },
          {
            label: "Complete",
            key: "complete",
            content: (
              <LoadingTable
                loadingData={loadingData}
                sortModel={tableSortModel.complete}
                onSortModelChange={(sortModel: GridSortModel) =>
                  setTableSortModel(rest => ({ ...rest, inProgress: sortModel }))
                }
              />
            ),
            toolbar: actionButton(),
          },
        ]}
      />
    </PortalPageContent>
  );
}

const LoadingTable = ({
  loadingData,
  ...rest
}: {
  loadingData: LoadingValue<StockMovementTableRow[]>;
  sortModel: GridSortModel;
  onSortModelChange: (sortModel: GridSortModel) => void;
}) => {
  return (
    <Loader loadingStates={[loadingData]}>
      {isLoaded(loadingData) && (
        <GridTable {...rest} inputRowsField={loadingData.value} columns={PRODUCT_TABLE_COLUMNS} tableHeight={800} />
      )}
    </Loader>
  );
};
const actionButton = () => <Button href={AppRoutes.CreateStockMovement}>Create new stock movement request</Button>;

const buildTableRows = (
  stockMovements: WithDbId<Workflow>[],
  transportWfs: WithDbId<Workflow>[],
  storageLocationViews: Map<DbKey<StorageLocation>, StorageLocationView>,
): StockMovementTableRow[] => {
  return stockMovements.reduce((acc, wf) => {
    if (wf.value.state.kind != "stock_movement" || wf.value.data.kind != "stock_movement") return acc;
    const smReq = wf.value.data.value.request;

    const transportWf = transportWfs.find(transportWf => transportWf.value.parentId === wf.id);
    const deliveryStatus = extractDeliveryStatusFromTransportWf(transportWf);

    const view = {
      stockMovementId: wf.id,
      reference: wf.value.reference,
      dateRequested: wf.value.createdAt,
      startingLocationName: storageLocationViews.get(smReq.startingLocationId)?.storageLocationName ?? "Unknown",
      destinationLocationName: storageLocationViews.get(smReq.destinationLocationId)?.storageLocationName ?? "Unknown",
      state: wf.value.state.value,
      deliveryStatus: deliveryStatus,
    };
    return [...acc, view];
  }, [] as StockMovementTableRow[]);
};

const extractDeliveryStatusFromTransportWf = (transportWf?: WithDbId<Workflow>): string => {
  if (!transportWf) return "-";
  return transportWf.value.data.kind === "transport"
    ? extractDeliveryStatus(transportWf.value.data.value.deliveryStatuses)
    : "-";
};
