import { ProductIdQuantity } from "adl-gen/ferovinum/app/common";
import React from "react";
import { assertNotUndefined } from "utils/hx/util/types";
import { numberOfUnitsForUnitType } from "utils/model-utils";
import { AppRoutes } from "../../../../app/app-routes";
import { useAppService } from "../../../../hooks/use-app-service";
import { useSelectedOrgId } from "../../../layouts/portal-page-layout/portal-page";
import { WizardPage } from "../../../layouts/wizard-page";
import { StockMovementRequestWizardConfig } from "../wizard/stock-movement-request-wizard-config";
import { StockMovementWizardContext, StockMovementWizardData } from "../wizard/stock-movement-wizard-types";

export const StockMovementCreatePage = () => {
  const service = useAppService();
  const organisationId = assertNotUndefined(useSelectedOrgId());

  const submitStockMovement = async (finalData: StockMovementWizardData, contextData: StockMovementWizardContext) => {
    const stockToMove: ProductIdQuantity[] = finalData.productSelection.products.map(product => ({
      productId: product.productId,
      quantity: numberOfUnitsForUnitType(
        contextData.productSelection.productInfos[product.productId].unitType,
        product.quantity,
      ),
    }));

    await service.createWorkflow({
      action: {
        kind: "stock_movement",
        value: {
          kind: "request",
          value: {
            orgId: organisationId,
            startingLocationId: finalData.productSelection.startLocation,
            destinationLocationId: finalData.productSelection.destinationLocation,
            stockToMove: stockToMove,
            stockMovementDeliveryOption: finalData.deliveryDetails.stockMovementDeliveryOption,
          },
        },
      },
      organisationId: organisationId,
    });
  };

  return (
    <WizardPage<StockMovementWizardData, StockMovementWizardContext>
      title={"New Stock Movement Request"}
      config={StockMovementRequestWizardConfig}
      onCancel={{ route: AppRoutes.StockMovement }}
      onSubmit={{ submitData: submitStockMovement, route: AppRoutes.StockMovement }}
    />
  );
};
