import React, { useState } from "react";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { Button, Grid, Stack } from "@mui/material";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { formatDate } from "utils/date-utils";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { StockAdjustmentFlowStepper } from "../../../../widgets/flow-stepper/stock-adjustment-flow-stepper";
import { StockAdjustmentsTable } from "../../../../widgets/stock-adjustments/stock-adjustments-table/stock-adjustments-table";
import { StickyNote } from "../../../../widgets/sticky-note/sticky-note";
import { StockAdjustmentDetailsView } from "../../../../../hooks/use-stock-adjustment";
import { useSettlementCurrency } from "../../../../layouts/portal-page-layout/portal-page";

export interface StorageLocStockAdjustmentConfirmationPageViewProps {
  stockAdjustmentData: StockAdjustmentDetailsView;
  navigateBackToSettings: () => void;
  navigateBackToSetup: () => void;
  onConfirm: () => Promise<void>;
  confirmedStockAdjustment: boolean;
}

export const StorageLocStockAdjustmentConfirmationPageView = (
  props: StorageLocStockAdjustmentConfirmationPageViewProps,
) => {
  const { confirmedStockAdjustment, stockAdjustmentData, navigateBackToSetup, navigateBackToSettings } = props;
  const settlementCurrency = useSettlementCurrency();
  // User is only required to accept the terms if they are making a reimbursed negative stock adjustment
  const termsAreApplicable =
    stockAdjustmentData.kind === "negativeStockAdjustment" &&
    stockAdjustmentData.stockAdjustments.kind === "reimbursement";
  const [acceptedTerms, setAcceptedTerms] = useState(!termsAreApplicable);
  return (
    <PortalPageContent
      header={confirmedStockAdjustment ? <StockAdjustmentConfirmedHeader /> : <StockAdjustmentConfirmationHeader />}>
      <Stack spacing={7}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StockAdjustmentsTable
              variant={stockAdjustmentData}
              topLevelUnitType={stockAdjustmentData.topLevelUnitType}
              currency={settlementCurrency}
              acknowledgementNotFinal={
                !confirmedStockAdjustment &&
                stockAdjustmentData.kind === "negativeStockAdjustment" &&
                stockAdjustmentData.stockAdjustments.kind === "reimbursement"
                  ? {
                      setValue: setAcceptedTerms,
                      value: acceptedTerms,
                    }
                  : undefined
              }
              action={
                confirmedStockAdjustment
                  ? undefined
                  : {
                      label: "Change product selection",
                      onClick: () => navigateBackToSetup(),
                    }
              }
            />
          </Grid>
          {stockAdjustmentData.reason && (
            <Grid item xs={12}>
              <StickyNote
                title="Reason for stock adjustment"
                line1={stockAdjustmentData.reason}
                action={
                  confirmedStockAdjustment
                    ? undefined
                    : {
                        label: "Change",
                        onClick: () => navigateBackToSettings(),
                      }
                }
              />
            </Grid>
          )}
          {!confirmedStockAdjustment && (
            <Grid item xs={12}>
              <ActionButtons {...props} acceptedTerms={acceptedTerms} />
            </Grid>
          )}
        </Grid>
      </Stack>
    </PortalPageContent>
  );
};

const ActionButtons = ({
  navigateBackToSettings,
  onConfirm,
  acceptedTerms,
}: {
  navigateBackToSettings: () => void;
  onConfirm: () => Promise<void>;
  acceptedTerms: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Stack direction="row" spacing={2} sx={{ mt: 7 }}>
      <Button variant="outlined" onClick={() => navigateBackToSettings()} disabled={loading}>
        Back
      </Button>
      <LoadingActionButton
        disabled={!acceptedTerms}
        onClick={async () => {
          setLoading(true);
          await onConfirm();
          setLoading(false);
        }}>
        Confirm
      </LoadingActionButton>
    </Stack>
  );
};

const StockAdjustmentConfirmationHeader = () => {
  return (
    <PortalPageContentHeader
      variant="split"
      title="Confirmation"
      right={<StockAdjustmentFlowStepper activeStep={2} />}
    />
  );
};

const StockAdjustmentConfirmedHeader = ({}) => {
  return (
    <PortalPageContentHeader
      useConfirmationTitle
      title="Your stock adjustment has successfully been recorded"
      subtitles={[
        {
          text: `Report date: ${formatDate(new Date())}`,
          icon: "date",
        },
      ]}
    />
  );
};
