import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

interface Props {
  open: boolean;
}

export const LoadingBackdrop = ({ open }: Props) => {
  return (
    <Backdrop
      sx={{ zIndex: theme => theme.zIndex.drawer + 1, color: "common.white" }}
      open={open}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
