import { Stack, Typography } from "@mui/material";
import { SaleOrderDetailsView } from "adl-gen/ferovinum/app/api";
import {
  DeliveryOption_InWarehouseTransfer,
  DeliveryOption_InWarehouseTransferWithAccountCode,
  ShippingDelivery,
} from "adl-gen/ferovinum/app/db";
import { LabelledCard } from "components/widgets/labelled-card/labelled-card";
import React from "react";
import { CardGrid } from "components/library/widgets/card/card-widgets";
import {
  makeCollectionLocationCard,
  makeDeliveryDetailsNoAddressCard,
  makeDeliveryLocationCard,
  makePaymentMethodCard,
  makeTradeTermsCard,
} from "components/library/widgets/card/card-makers";

export const RepurchaseSummaryCardGrid = ({ saleOrderDetailsView }: { saleOrderDetailsView: SaleOrderDetailsView }) => {
  const {
    deliveryOption: delivery,
    repurchaseStorageLocationName,
    destinationStorageLocationName,
    usesSettlementCredit,
  } = saleOrderDetailsView;
  return (
    <>
      {delivery !== null && (delivery.kind === "shippedDelivery" || delivery.kind === "orgLocationDelivery") && (
        <DeliveryCardGrid
          shippingDelivery={delivery.value}
          usesSettlementCredit={usesSettlementCredit}
          storageLocationName={repurchaseStorageLocationName ?? ""}
        />
      )}
      {delivery !== null &&
        (delivery.kind === "inWarehouseTransfer" || delivery.kind === "inWarehouseTransferWithAccountCode") && (
          <InWarehouseCardGrid
            inWarehouseDelivery={delivery}
            storageLocationName={repurchaseStorageLocationName ?? ""}
            destinationLocationName={destinationStorageLocationName}
            usesSettlementCredit={usesSettlementCredit}
          />
        )}
    </>
  );
};

const InWarehouseCardGrid = ({
  inWarehouseDelivery,
  storageLocationName,
  destinationLocationName,
  usesSettlementCredit,
}: {
  inWarehouseDelivery: DeliveryOption_InWarehouseTransfer | DeliveryOption_InWarehouseTransferWithAccountCode;
  storageLocationName: string;
  destinationLocationName: string | null;
  usesSettlementCredit: boolean;
}) => {
  return (
    <Stack spacing={5}>
      <Stack direction="row" spacing={3}>
        <LabelledCard label="Internal Transfer">
          <Typography>Location: {storageLocationName}</Typography>
          {destinationLocationName && <Typography>Destination: {destinationLocationName}</Typography>}
        </LabelledCard>
        <LabelledCard label="Payment Method">
          <Typography>{`${usesSettlementCredit ? "Credit Settlement" : "Direct Transfer"}`}</Typography>
        </LabelledCard>
        {inWarehouseDelivery.kind === "inWarehouseTransferWithAccountCode" && (
          <LabelledCard label="Account Code">
            <Typography>{inWarehouseDelivery.value.accountCode}</Typography>
          </LabelledCard>
        )}
      </Stack>
    </Stack>
  );
};

const DeliveryCardGrid = (deliveryCardGridProps: {
  shippingDelivery: ShippingDelivery;
  usesSettlementCredit: boolean;
  storageLocationName: string;
}) => {
  const { shippingDelivery, usesSettlementCredit, storageLocationName } = deliveryCardGridProps;
  return (
    <CardGrid
      spacing={4}
      direction="column"
      cardsGrid={[
        [
          // Column 1
          makeCollectionLocationCard(storageLocationName),
          makeDeliveryLocationCard(shippingDelivery.deliveryPointName, shippingDelivery),
        ],
        [
          // Column 2
          makeDeliveryDetailsNoAddressCard(shippingDelivery),
        ],
        [
          // Column 3
          makePaymentMethodCard(usesSettlementCredit),
          makeTradeTermsCard(shippingDelivery.bonded, ""),
        ],
      ]}
    />
  );
};
