import { BigDecimal } from "adl-gen/common";
import { WithDbId } from "adl-gen/common/db";
import {
  Country,
  Product,
  ProductType,
  UnitType,
  valuesCountry,
  valuesProductType,
  valuesUnitType,
} from "adl-gen/ferovinum/app/db";
import { pick } from "lodash";
import { countryCodeToCountryName, stringToCountryCode } from "utils/conversion-utils";
import { CsvRowParser } from "utils/csv-row-parser";
import {
  enumField,
  numberField,
  requiredErrorHandler,
  staticValue,
  stringField,
  typeErrorHandler,
} from "utils/data-field/data-field-builder";
import { makeObjectDef, makeObjectSchema } from "utils/data-field/object-field-def";
import { assertNever } from "utils/hx/util/types";
import {
  TankUnit,
  isCase,
  isSinglesUnitType,
  numberOfUnitsForUnitType,
  vesselSizeForUnitType,
} from "utils/model-utils";
import { mapOptional } from "utils/type-utils";
import {
  NewProductData,
  ProductLineItemData,
} from "../ui/page/organisation/new-deal-requests/organisation-create-new-deal-request/organisation-create-new-deal-request-page";
import {
  CsvDataField,
  CsvProductLineItemData,
  ProductCsvParser,
  createCsvDataFields,
} from "./csv-product-data-processing-types";
import { DUPLICATE_PRODUCT_MESSAGE } from "./csv-product-data-yup-schemas";

export type SimpleProductData = {
  code: string;
  name: string;
  producerName: string;
  productType: ProductType;
  unitType: UnitType;
  unitSize?: number;
  vintageYear?: number;
  alcoholByVolumePc: number;
  countryOfOrigin: Country;
  regionOrigin?: string;
  numberOfSingles?: number;
};

type TankUnitInput = "hL" | "LPA";
const valuesTankUnitInput: Readonly<TankUnitInput[]> = Object.freeze(["hL", "LPA"]);

export const SIMPLE_PRODUCT_DATA_DEF = makeObjectDef<SimpleProductData>({
  code: stringField("Product code").required(),
  name: stringField("Product name").required(),
  producerName: stringField("Producer name").required(),
  productType: enumField("Product type", valuesProductType).required(),
  unitType: enumField("Unit type", valuesUnitType).required(),
  unitSize: numberField("Unit size", { range: "positive" })
    .updateSchemaWithNonTrivialValidation(schema =>
      schema.when(["unitType"], {
        is: (type?: UnitType) => !type || isSinglesUnitType(type),
        then: schema => schema.required(requiredErrorHandler),
      }),
    )
    .optional(),
  vintageYear: numberField("Vintage")
    .updateSchemaWithNonTrivialValidation(schema =>
      schema.typeError(params => typeErrorHandler(params) + " Please leave blank if the product is non-vintage."),
    )
    .optional(),
  alcoholByVolumePc: numberField("ABV", { range: { min: 0, max: 100 }, precision: 1 }).required(),
  countryOfOrigin: enumField("Country of Origin", valuesCountry, {
    parse: stringToCountryCode,
    toString: countryCodeToCountryName,
  }).required(),
  regionOrigin: stringField("Region of origin").optional(),
  numberOfSingles: numberField("Singles per case")
    .updateSchemaWithNonTrivialValidation(schema =>
      schema.when(["unitType"], {
        is: (type?: UnitType) => !type || isCase(type),
        then: schema => schema.required(requiredErrorHandler),
      }),
    )
    .optional(),
});

type DealInfo = {
  clientSpecifiedPurchasePrice?: number;
  numberOfUnits?: number;
  numberOfFreeUnits?: number;
  individualDiscountPct?: number;
  tankUnitInput?: TankUnitInput;
};

const PROCUREMENT_DEAL_INFO_DEF = makeObjectDef<DealInfo>({
  clientSpecifiedPurchasePrice: numberField("Price", { range: "positive", precision: 2 }).optional(),
  numberOfUnits: numberField("Quantity", { range: "positive" }).optional(),
  numberOfFreeUnits: numberField("Free stock", { range: { min: 0 } }).optional(),
  individualDiscountPct: numberField("Discount", { range: { min: 0, max: 100 } }).optional(),
  tankUnitInput: enumField("Tank unit", valuesTankUnitInput).optional(),
});

const EXISTING_STOCK_DEAL_INFO_DEF = makeObjectDef<DealInfo>({
  ...pick(PROCUREMENT_DEAL_INFO_DEF, "clientSpecifiedPurchasePrice", "numberOfUnits", "tankUnitInput"),
  numberOfFreeUnits: staticValue<number | undefined>(undefined),
  individualDiscountPct: staticValue<number | undefined>(undefined),
});

export class SimpleProductCsvParser implements ProductCsvParser {
  private readonly simpleProductDataParser: CsvRowParser<SimpleProductData>;
  private readonly dealInfoParser: CsvRowParser<DealInfo>;

  constructor(headers: string[], isProcurement: boolean) {
    this.simpleProductDataParser = CsvRowParser.make(SIMPLE_PRODUCT_DATA_DEF, headers, ({ code }, labels) => {
      if (code && code.length > 30) {
        return `${labels.code} cannot exceed 30 characters.`;
      }
    });
    const dealInfoDef = isProcurement ? PROCUREMENT_DEAL_INFO_DEF : EXISTING_STOCK_DEAL_INFO_DEF;
    this.dealInfoParser = CsvRowParser.make(
      dealInfoDef,
      headers,
      ({ clientSpecifiedPurchasePrice: price, numberOfUnits: paidUnits, numberOfFreeUnits: freeUnits }, labels) => {
        const errors: string[] = [];
        if (!price && (paidUnits || !freeUnits)) {
          errors.push(`${labels.clientSpecifiedPurchasePrice} is missing.`);
        }
        if (!paidUnits && (price || !freeUnits)) {
          errors.push(`${labels.numberOfUnits} is missing.`);
        }
        if (isProcurement && !freeUnits && !paidUnits && !price) {
          errors.push(`${labels.numberOfFreeUnits} is missing.`);
        }
        return errors;
      },
    );
  }

  public getProductCode(rowData: Readonly<string[]>): string {
    return this.simpleProductDataParser.castData(rowData, "code") ?? "";
  }

  public createNewProduct(rowData: Readonly<string[]>): CsvProductLineItemData {
    const {
      code,
      name,
      producerName,
      productType,
      unitType,
      unitSize,
      vintageYear,
      alcoholByVolumePc,
      countryOfOrigin,
      regionOrigin,
      numberOfSingles,
    } = this.simpleProductDataParser.parse(rowData);
    const dealData = this.createDealData(rowData, unitType);

    const productData: NewProductData = {
      code,
      name,
      producerName,
      productType,
      unitType,
      alcoholByVolumePc,
      countryOfOrigin,
      regionOrigin: regionOrigin ?? "",
      productDate: vintageYear === undefined ? { kind: "nonVintage" } : { kind: "vintageYear", value: vintageYear },
      vesselSize: vesselSizeForUnitType(
        unitType,
        isCase(unitType) ? { numberOfSingles: numberOfSingles || 0, centilitresPerSingle: unitSize || 0 } : unitSize,
      ),
      alcoholDetail: { kind: "unknown", value: {} },
      vesselType: { kind: "unknown", value: {} },
    };
    return {
      kind: "new",
      productCode: code,
      ...dealData,
      value: productData,
    };
  }

  public createExistingProduct(
    rowData: Readonly<string[]>,
    existingProduct: WithDbId<Product>,
  ): CsvProductLineItemData {
    const productCode = this.getProductCode(rowData);
    const dealData = this.createDealData(rowData, existingProduct.value.unitType);
    const csvDataFields = this.getCsvDataFieldsForExistingProduct(rowData, existingProduct.value);
    const hasMismatchedDetails = csvDataFields.some(field => field.isMismatched);
    return {
      kind: "existing",
      productCode,
      ...dealData,
      value: existingProduct,
      csvDataFields,
      hasMismatchedDetails,
    };
  }

  private createDealData(
    rowData: Readonly<string[]>,
    unitType: UnitType,
  ): Pick<
    ProductLineItemData,
    "clientSpecifiedPurchasePrice" | "numberOfUnits" | "numberOfFreeUnits" | "individualDiscountPct"
  > {
    const { clientSpecifiedPurchasePrice, numberOfUnits, numberOfFreeUnits, individualDiscountPct, tankUnitInput } =
      this.dealInfoParser.parse(rowData);
    const tankUnit = tankUnitInput ? tankUnitInputToTankUnit(tankUnitInput) : undefined;
    return {
      clientSpecifiedPurchasePrice: clientSpecifiedPurchasePrice ?? 0,
      numberOfUnits: numberOfUnitsForUnitType(unitType, numberOfUnits ?? 0, tankUnit),
      numberOfFreeUnits: mapOptional(numberOfFreeUnits, n => numberOfUnitsForUnitType(unitType, n, tankUnit)),
      individualDiscountPct: mapOptional(individualDiscountPct, n => n.toString() as BigDecimal),
    };
  }

  // Perform a string-compare for the fields from the CSV and the existing product's fields.
  // This function will only look at columns that are filled in, because we assume that if the user leaves
  // a column empty, they meant to use the system's details.
  private getCsvDataFieldsForExistingProduct(rowData: Readonly<string[]>, existingProduct: Product): CsvDataField[] {
    const productDataParser = this.simpleProductDataParser.omit(
      "code",
      "clientSpecifiedPurchasePrice",
      "quantity",
      "numberOfFreeUnits",
    );
    const {
      code,
      name,
      producerName,
      productType,
      unitType,
      vesselSize,
      productDate,
      alcoholByVolumePc,
      countryOfOrigin,
      regionOrigin,
    } = existingProduct;
    const existingData: SimpleProductData = {
      code,
      name,
      producerName,
      productType,
      unitType,
      alcoholByVolumePc,
      countryOfOrigin,
      regionOrigin,
      unitSize: vesselSize?.kind === "centilitres" ? vesselSize.value : 1,
      vintageYear: productDate.kind === "vintageYear" ? productDate.value : undefined,
    };
    return createCsvDataFields(rowData, existingData, productDataParser);
  }

  public getErrorMessagesForRow(
    isExistingProduct: boolean,
    rowData: Readonly<string[]>,
    uniqueProductCodes: Set<string>,
  ): string[] {
    const productCode = this.getProductCode(rowData);
    if (uniqueProductCodes.has(productCode)) {
      return [DUPLICATE_PRODUCT_MESSAGE];
    }

    const dealInfoErrors = this.dealInfoParser.checkForErrors(rowData);

    if (isExistingProduct) {
      return dealInfoErrors;
    }

    const productErrors = this.simpleProductDataParser.checkForErrors(rowData);

    return [...dealInfoErrors, ...productErrors];
  }
}

export function getSimpleProductLineItemQuantityAndPriceSchema(isProcurement?: boolean) {
  const dealInfoDef = isProcurement ? PROCUREMENT_DEAL_INFO_DEF : EXISTING_STOCK_DEAL_INFO_DEF;
  return makeObjectSchema(dealInfoDef);
}

function tankUnitInputToTankUnit(input: TankUnitInput): TankUnit {
  switch (input) {
    case "LPA":
      return "litresOfPureAlcohol";
    case "hL":
      return "hectolitres";
    default:
      assertNever(input);
  }
}
