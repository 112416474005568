import { PurchaserPurchaseRequestDetailsPageView } from "./purchaser-purchase-request-details-page-view";
import React, { useCallback, useMemo } from "react";
import { Loader } from "components/widgets/loader/loader";
import { isLoaded } from "utils/utility-types";
import { useParams } from "react-router-dom";
import { PurchaseRequestService, usePurchaserPurchaseRequest } from "../../../../hooks/use-purchase-request";
import { useAppService } from "../../../../hooks/use-app-service";
import { makeApiServices } from "adl-service/api-utils";
import { CollectionDetails, makeActionLinkConstants } from "adl-gen/ferovinum/app/api";

export const PurchaserPurchaseRequestDetailsPage = () => {
  const { purchaseRequestId } = useParams<{
    purchaseRequestId: string;
  }>();
  const service = useAppService();
  return <PurchaserPurchaseRequestDetailsPageImpl service={service} purchaseRequestId={purchaseRequestId} />;
};

const { PurchaserActionJwtParam, PurchaserActionRequestIdParam } = makeActionLinkConstants({});

export const ActionLinkPurchaserPurchaseRequestDetailsPage = () => {
  const { purchaseRequestId, service } = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const purchaseRequestId = searchParams.get(PurchaserActionRequestIdParam);
    const jwt = searchParams.get(PurchaserActionJwtParam);
    const apiService = jwt ? makeApiServices(jwt).app : undefined;
    const actionLinkService =
      apiService &&
      ({
        getPurchaseRequestTimeline: reqId => apiService.actionLinkGetPurchaserPurchaseRequestTimeline(reqId),
        getPurchaseRequestDetailsForPurchaser: reqId =>
          apiService.actionLinkGetPurchaseRequestDetailsForPurchaser(reqId),
        getPurchaseRequestBreakdownForPurchaser: req =>
          apiService.actionLinkGetPurchaseRequestBreakdownForPurchaser(req),
        getNominatedPurchaserTermsForPurchaser: req => apiService.actionLinkGetNominatedPurchaserTermsForPurchaser(req),
        purchaserRejectPurchaseRequest: req => apiService.actionLinkPurchaserRejectPurchaseRequest(req),
        purchaserAcceptPurchaseRequest: req => apiService.actionLinkPurchaserAcceptPurchaseRequest(req),
      } as PurchaseRequestService);
    return { purchaseRequestId, service: actionLinkService };
  }, []);
  if (purchaseRequestId && service) {
    return <PurchaserPurchaseRequestDetailsPageImpl service={service} purchaseRequestId={purchaseRequestId} />;
  } else {
    return <div>Invalid action link</div>;
  }
};

function PurchaserPurchaseRequestDetailsPageImpl(props: {
  service: PurchaseRequestService;
  purchaseRequestId: string;
}) {
  const { purchaseRequestId, service } = props;
  const { loadingPurchaseRequest, refresh } = usePurchaserPurchaseRequest(service, purchaseRequestId);

  const onTermsAccepted = useCallback(
    async (collectionDetails: CollectionDetails | null) => {
      await service.purchaserAcceptPurchaseRequest({
        purchaseRequestId,
        collectionDetails: collectionDetails,
      });
      await refresh();
    },
    [purchaseRequestId, refresh, service],
  );

  const onTermsRejected = useCallback(async () => {
    await service.purchaserRejectPurchaseRequest({
      purchaseRequestId: purchaseRequestId,
    });
    await refresh();
  }, [purchaseRequestId, refresh, service]);
  return (
    <Loader loadingStates={[loadingPurchaseRequest]} fullScreen>
      {isLoaded(loadingPurchaseRequest) && (
        <PurchaserPurchaseRequestDetailsPageView
          purchaseRequestData={loadingPurchaseRequest.value}
          onTermsAccepted={onTermsAccepted}
          onTermsRejected={onTermsRejected}
        />
      )}
    </Loader>
  );
}
