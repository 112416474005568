import { Stack } from "@mui/material";
import React from "react";
import { RemovableFileItem, FilePicker } from "../file-picker";
import { BaseWizardCard } from "./base-wizard-card";

export function DeliveryInstructionDocUploadCard({
  fileValues,
  onFilesAccepted,
  removeFileAt,
}: {
  fileValues: File[];
  onFilesAccepted: (files: File[]) => void;
  removeFileAt: (idx: number) => void;
}) {
  const MAX_FILE_COUNT = 20;
  return (
    <BaseWizardCard
      title="Delivery Instruction Documents"
      subtitle="If uploaded, files will be emailed to the Carrier"
      inputElement={
        <Stack spacing={2} width={"100%"}>
          <FilePicker maxFileCount={MAX_FILE_COUNT} onFilesAccepted={onFilesAccepted} />
          {fileValues.map((file, idx) => (
            <RemovableFileItem key={idx} file={file} onClick={() => removeFileAt(idx)} />
          ))}
        </Stack>
      }
    />
  );
}
