import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useSelectedCarrier } from "../../../layouts/portal-page-layout/portal-page";
import { isErrored, isLoaded, isLoading, LoadingValue } from "utils/utility-types";
import { NewDealRequestCarrierView } from "adl-gen/ferovinum/app/procurement";
import { useParams } from "react-router-dom";
import { Paginated } from "adl-gen/common";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { useAppService } from "../../../../hooks/use-app-service";
import { NewDealRequestsForCarrierFilterType } from "adl-gen/ferovinum/app/api";
import { CarrierCollectionOrdersPageView } from "./carrier-collection-orders-page-view";
import { assertNever } from "utils/hx/util/types";

export const CarrierCollectionOrdersPage = () => {
  const appService = useAppService();
  const carrier = useSelectedCarrier();
  const [paginatedDeals, setPaginatedDeals] = useState<LoadingValue<Paginated<NewDealRequestCarrierView>>>({
    state: "loading",
  });
  const [showAlert] = useAlert();
  const { filter } = useParams<{ filter: NewDealRequestsForCarrierFilterType }>();

  const fetchDealRequestsForCarrier = useCallback(() => {
    if (carrier) {
      appService
        .getNewDealRequestsForCarrier({
          carrierId: carrier.id,
          filter,
          offset: 0,
          // TODO(Barry): Pagination
          count: -1,
        })
        .then(result => setPaginatedDeals({ state: "success", value: result }))
        .catch(_ => showAlert({ title: "Error loading requests", body: "Could not load requests" }));
    } else {
      setPaginatedDeals({ state: "error", error: new Error("No carrier selected") });
    }
  }, [appService, carrier, filter, setPaginatedDeals, showAlert]);

  useEffect(() => {
    fetchDealRequestsForCarrier();
  }, [fetchDealRequestsForCarrier]);

  const loadingDeals = useMemo<LoadingValue<NewDealRequestCarrierView[]>>(() => {
    if (isLoaded(paginatedDeals)) {
      return { state: "success", value: paginatedDeals.value.items };
    } else if (isErrored(paginatedDeals)) {
      return paginatedDeals;
    } else if (isLoading(paginatedDeals)) {
      return paginatedDeals;
    } else {
      assertNever(paginatedDeals);
    }
  }, [paginatedDeals]);

  return (
    <CarrierCollectionOrdersPageView
      filter={filter}
      loadingDeals={loadingDeals}
      refreshDeals={() => fetchDealRequestsForCarrier()}
    />
  );
};
