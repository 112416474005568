import { Typography, TypographyProps } from "@mui/material";
import { BigDecimal } from "adl-gen/common";
import { Currency } from "adl-gen/ferovinum/app/db";
import { Decimal } from "adl-gen/ferovinum/app/types";
import Big from "big.js";
import React, { useMemo } from "react";

export type CurrencyValue = Big | Decimal | BigDecimal | string | number | null | undefined;
export interface CurrencyRendererProps extends TypographyProps {
  /**
   * The currency to be displayed.
   */
  currency: Currency;
  /**
   * The value to be rendered.
   */
  value: CurrencyValue;
  /**
   * The minimum number of fraction digits to display.
   */
  minimumFractionDigits?: number;
  /**
   * The maximum number of fraction digits to display.
   */
  maximumFractionDigits?: number;
  /**
   * Determines whether to show a tooltip for the currency value.
   */
  showTooltip?: boolean;
  /**
   * The value to be displayed when the main value is empty.
   */
  emptyValue?: string;
}

/**
 * Renders a currency value with formatting options.
 */
export const CurrencyRenderer: React.FC<CurrencyRendererProps> = ({
  currency,
  value,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  showTooltip = true,
  emptyValue,
  ...rest
}) => {
  const formatter = useMemo(() => {
    return makeCurrencyFormatter({ currency, minimumFractionDigits, maximumFractionDigits });
  }, [currency, minimumFractionDigits, maximumFractionDigits]);

  // If the value is empty the return "0" or the provided empty value prop
  if (value === null || value === undefined) {
    return <Typography {...rest}>{emptyValue ?? "-"}</Typography>;
  }

  // Convert the value to a number and format it.
  const numericValue = parseValue(value);
  const formattedValue = formatter.format(numericValue);

  // Check if precision is greater than maximumFractionDigits
  const hasMorePrecision = numericValue.toString().split(".")[1]?.length > maximumFractionDigits;

  return (
    <Typography
      {...rest}
      title={showTooltip && hasMorePrecision ? numericValue.toString() : undefined}
      style={{ cursor: showTooltip && hasMorePrecision ? "help" : "default" }}>
      {formattedValue}
    </Typography>
  );
};

export function formatCurrencyValue({
  currency,
  value,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  emptyValue = "-",
}: {
  currency: Currency;
  value: CurrencyValue;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  emptyValue?: string;
}): string {
  const formatter = makeCurrencyFormatter({ currency, minimumFractionDigits, maximumFractionDigits });
  return value != null ? formatter.format(parseValue(value)) : emptyValue;
}

function makeCurrencyFormatter(args: {
  currency: Currency;
  minimumFractionDigits: number;
  maximumFractionDigits: number;
}) {
  return new Intl.NumberFormat("en-GB", { style: "currency", ...args });
}

const parseValue = (value: Big | Decimal | BigDecimal | string | number): number => {
  switch (typeof value) {
    case "string":
      return parseFloat(value);
    case "number":
      return value;
    case "bigint":
      return Number(value);
    case "object":
      if (value instanceof Big) return parseFloat(value.toString());
      throw new Error(`Unsupported value type: ${value}`);
    default:
      throw new Error(`Unsupported value type: ${value}`);
  }
};
