import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { DbKey } from "adl-gen/common/db";
import { PurchaseRequest } from "adl-gen/ferovinum/app/db";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { Loader } from "components/widgets/loader/loader";
import { isLoaded } from "utils/utility-types";
import { OutgoingOrderDetailPageView } from "./outgoing-order-detail-page-view";
import { Alert } from "@mui/material";
import { useMarkAsCollected, useMarkAsDelivered } from "../../../../../hooks/use-mark-as-delivered";
import { LocalDate } from "adl-gen/common";

export const OutgoingOrdersDetailPage = () => {
  const { purchaseRequestId } = useParams<{ purchaseRequestId: DbKey<PurchaseRequest> }>();
  const appService = useAppService();

  const loadOrder = useCallback(async () => {
    const resp = await appService.getOutgoingOrdersForStorageLocation({
      kind: "singleOrder",
      value: purchaseRequestId,
    });

    return resp.orders[0];
  }, [appService, purchaseRequestId]);

  const [loadingOrder, refresh] = useLoadingDataState(loadOrder, true);

  const markAsCollected = useMarkAsCollected(appService, refresh);
  const markAsDelivered = useMarkAsDelivered(appService, refresh);
  const handleClickMarkAsCollected = useCallback(
    async (date: LocalDate) => {
      await markAsCollected(purchaseRequestId, date);
    },
    [markAsCollected, purchaseRequestId],
  );

  const handleClickMarkAsDelivered = useCallback(
    async (date: LocalDate) => {
      await markAsDelivered(purchaseRequestId, date);
    },
    [markAsDelivered, purchaseRequestId],
  );

  return (
    <Loader loadingStates={[loadingOrder]}>
      {isLoaded(loadingOrder) &&
        (loadingOrder.value ? (
          <OutgoingOrderDetailPageView
            markAsCollected={handleClickMarkAsCollected}
            markAsDelivered={handleClickMarkAsDelivered}
            {...loadingOrder.value}
          />
        ) : (
          <Alert severity={"error"}>This order could not be found</Alert>
        ))}
    </Loader>
  );
};
