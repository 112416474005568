import React from "react";
import { TextFieldProps } from "@mui/material";
import { Currency } from "adl-gen/ferovinum/app/db";
import { getCurrencySymbol } from "utils/conversion-utils";
import { RoundingInput } from "../rounding-input/rounding-input";

export type CurrencyInputProps = CurrencyProps & Omit<TextFieldProps, "type">;

interface CurrencyProps {
  /** Currency to be displayed  next to the value */
  currency?: Currency;
  /** makes this currency input debounce its callback */
  debounced?: boolean;
  /** Overrides default precision */
  precision?: number;
}

const DECIMAL_PRECISION = 2;

export const CurrencyInput = ({ currency, value, debounced, precision, ...props }: CurrencyInputProps) => {
  return (
    <RoundingInput
      {...props}
      value={value}
      precision={precision ?? DECIMAL_PRECISION}
      debounced={debounced}
      startAdornment={currency ? getCurrencySymbol(currency) : "?"}
      inputProps={{
        ...props.inputProps,
        style: {
          ...props.inputProps?.style,
          border: "none", // remove border
          backgroundColor: "transparent", // make background transparent
          // add any other styles to make it look like a text field
        },
      }}
    />
  );
};
