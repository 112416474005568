import { useUiConfig } from "adl-service/hooks/use-ui-config";

/** This custom hook abstract the fact that ferovinum apps are handled in different ways in development and production
 * - development: the apps are served on different ports
 * - production: the apps are served on the same port and nginx routes the requests to the correct app
 * */
export const useAppRootRoute = (appRouterBasename: string, port: number) => {
  const uiConfig = useUiConfig();
  /// Note: releaseName will be "fastdev" in development (fastdev and integration)
  /// [dnit prodsim]: environment will be "dev" and releaseName will be "prodsim"
  const isDevelopment = uiConfig.environment === "dev" && uiConfig.releaseName === "fastdev";
  if (isDevelopment) {
    // In local dev the adminui app is served on port 3002
    return `http://localhost:${port}${appRouterBasename}`;
  }
  // Note: In prod we need to trigger a full browser refresh to the admin ui by using an external URL
  // Prod sim does not have SSL enabled
  return `${window.location.origin}${appRouterBasename}`;
};
