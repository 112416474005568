import { AppService } from "adl-gen/app-service";
import { makePaginated } from "adl-gen/common";
import { DbKey } from "adl-gen/common/db";
import {
  DealLegAvailabilityOption,
  OrganisationProductInventoryView,
  makeOrganisationStockReq,
  makeProductSearchQueryReq,
} from "adl-gen/ferovinum/app/api";
import { Organisation, Product, valuesTopLevelUnitType } from "adl-gen/ferovinum/app/db";
import { Loader } from "components/widgets/loader/loader";
import React, { FC, useCallback, useMemo } from "react";
import { getTopLevelForUnitType } from "utils/adl-utils";
import { downloadCSVFile } from "utils/csv-utils";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { assertNotUndefined } from "utils/hx/util/types";
import { isLoaded } from "utils/utility-types";
import { useAppService } from "../../../../hooks/use-app-service";
import { useSelectedOrgFeatures, useSelectedOrgId } from "../../../layouts/portal-page-layout/portal-page";
import {
  AvailabilitySection,
  OrganisationInventoryPageView,
  PaginatedProductMapping,
} from "./organisation-inventory-page-view";

const PAGINATED_LOAD_COUNT = 200;

export function getFilteredProductsByStockType(
  products: OrganisationProductInventoryView[],
  availability: AvailabilitySection,
): PaginatedProductMapping {
  const sectionFilterFn = (p: OrganisationProductInventoryView) =>
    availability === "available"
      ? p.totalQuantityAvailable.value + p.totalPendingQuantity.value > 0
      : p.totalQuantityAvailable.value + p.totalPendingQuantity.value === 0;
  const filteredBySectionProducts = products.filter(sectionFilterFn);

  const result: PaginatedProductMapping = new Map();
  for (const unitType of valuesTopLevelUnitType) {
    const items = filteredBySectionProducts.filter(p => getTopLevelForUnitType(p.unitType) === unitType);
    if (items.length > 0) {
      result.set(unitType, makePaginated({ items, current_offset: 0, total_size: items.length }));
    }
  }
  return result;
}

export const OrganisationInventoryPage = () => {
  const organisationId = assertNotUndefined(useSelectedOrgId());
  const service = useAppService();

  const orgFeatures = useSelectedOrgFeatures();

  if (orgFeatures.includes("inventoryPagination")) {
    return <LoaderPaginatedOrganisationInventoryPage organisationId={organisationId} service={service} />;
  } else {
    return <LoaderOrganisationInventoryPage organisationId={organisationId} service={service} />;
  }
};

function LoaderPaginatedOrganisationInventoryPage(props: { organisationId: DbKey<Organisation>; service: AppService }) {
  const { organisationId, service } = props;

  const loadInventory = useCallback(async () => {
    const topLevelUnitTypes = await service.getStockTopLevelUnitTypes({ organisationId });
    const availabilityOptions: DealLegAvailabilityOption[] = ["availableAndPending", "depleted"];
    const params = availabilityOptions.flatMap(availabilityOption => {
      return topLevelUnitTypes.flatMap(topLevelUnitType => ({ availabilityOption, topLevelUnitType }));
    });
    const inventoryEntries = await Promise.all(
      params.map(async param => {
        const inventory = await service.getOrganisationInventory(
          makeOrganisationStockReq({
            organisationId,
            topLevelUnitTypeOption: { kind: "byUnitType", value: param.topLevelUnitType },
            availabilityOption: param.availabilityOption,
            offset: 0,
            count: PAGINATED_LOAD_COUNT,
          }),
        );
        return { ...param, inventory };
      }),
    );
    const availableProducts: PaginatedProductMapping = new Map();
    const depletedProducts: PaginatedProductMapping = new Map();
    inventoryEntries.forEach(({ topLevelUnitType, availabilityOption, inventory }) => {
      const { items, current_offset, total_size } = inventory;
      if (inventory.items.length > 0) {
        const productsMap = availabilityOption === "availableAndPending" ? availableProducts : depletedProducts;
        productsMap.set(topLevelUnitType, makePaginated({ items, current_offset, total_size }));
      }
    });
    return { availableProducts, depletedProducts };
  }, [service, organisationId]);
  const [loadingInventory] = useLoadingDataState(loadInventory);

  return (
    <Loader loadingStates={[loadingInventory]} fullScreen>
      {isLoaded(loadingInventory) && (
        <PartiallyLoadedPaginatedOrganisationInventoryPage
          organisationId={organisationId}
          availableProducts={loadingInventory.value.availableProducts}
          depletedProducts={loadingInventory.value.depletedProducts}
        />
      )}
    </Loader>
  );
}

function LoaderOrganisationInventoryPage(props: { organisationId: DbKey<Organisation>; service: AppService }) {
  const { organisationId, service } = props;

  const loadInventory = useCallback(
    () => service.getOrganisationInventory(makeOrganisationStockReq({ organisationId })),
    [service, organisationId],
  );
  const [loadingInventory] = useLoadingDataState(loadInventory);

  return (
    <Loader loadingStates={[loadingInventory]} fullScreen>
      {isLoaded(loadingInventory) && (
        <LoadedOrganisationInventoryPage inventory={loadingInventory.value.items} organisationId={organisationId} />
      )}
    </Loader>
  );
}

interface LoadedOrganisationInventoryPageProps {
  inventory: OrganisationProductInventoryView[];
  organisationId: DbKey<Organisation>;
}
const LoadedOrganisationInventoryPage: FC<LoadedOrganisationInventoryPageProps> = ({ organisationId, inventory }) => {
  const service: AppService = useAppService();

  const availableProducts = useMemo(() => getFilteredProductsByStockType(inventory, "available"), [inventory]);
  const depletedProducts = useMemo(() => getFilteredProductsByStockType(inventory, "depleted"), [inventory]);
  const onClickDownloadCsv = useCallback(async () => {
    const csvData = await service.getOrganisationAvailableStockCSV({ organisationId });
    downloadCSVFile(csvData, "Available Stock at Ferovinum ");
  }, [service, organisationId]);

  const searchProducts = useCallback(
    async (searchTerm: string, availability: AvailabilitySection) => {
      const searchResult = await service.productSearch(makeProductSearchQueryReq({ organisationId, searchTerm }));
      const productIds = new Set(searchResult.map(p => p.id));
      if (availability === "available") {
        return filterInventoryByProductIds(availableProducts, productIds);
      } else {
        return filterInventoryByProductIds(depletedProducts, productIds);
      }
    },
    [service, availableProducts, depletedProducts, organisationId],
  );

  return (
    <OrganisationInventoryPageView
      availableProducts={availableProducts}
      depletedProducts={depletedProducts}
      onClickDownloadCSV={onClickDownloadCsv}
      searchProducts={searchProducts}
    />
  );
};

function filterInventoryByProductIds(
  inventory: PaginatedProductMapping,
  productIds: Set<DbKey<Product>>,
): OrganisationProductInventoryView[] {
  return valuesTopLevelUnitType.flatMap(unitType => {
    const views = inventory.get(unitType);
    return views?.items.filter(p => productIds.has(p.productId)) ?? [];
  });
}

function PartiallyLoadedPaginatedOrganisationInventoryPage(props: {
  organisationId: DbKey<Organisation>;
  availableProducts: PaginatedProductMapping;
  depletedProducts: PaginatedProductMapping;
}) {
  const { organisationId, availableProducts, depletedProducts } = props;

  const service: AppService = useAppService();

  const onClickDownloadCsv = useCallback(async () => {
    const csvData = await service.getOrganisationAvailableStockCSV({ organisationId });
    downloadCSVFile(csvData, "Available Stock at Ferovinum ");
  }, [service, organisationId]);

  const loadMoreProducts = useCallback(
    async (topLevelUnitType, availability, offset) => {
      const products = await service.getOrganisationInventory(
        makeOrganisationStockReq({
          organisationId: organisationId,
          topLevelUnitTypeOption: { kind: "byUnitType", value: topLevelUnitType },
          availabilityOption: availability === "available" ? "availableAndPending" : "depleted",
          offset,
          count: PAGINATED_LOAD_COUNT,
        }),
      );
      return products.items;
    },
    [organisationId, service],
  );

  const searchProducts = useCallback(
    async (searchTerm: string, availability: AvailabilitySection) => {
      const searchResult = await service.productSearch(makeProductSearchQueryReq({ organisationId, searchTerm }));
      const productIds = searchResult.map(p => p.id);
      const availabilityOption = availability === "available" ? "availableAndPending" : "depleted";
      return service.getOrganisationInventoryByProductIds({ organisationId, productIds, availabilityOption });
    },
    [organisationId, service],
  );

  return (
    <OrganisationInventoryPageView
      availableProducts={availableProducts}
      depletedProducts={depletedProducts}
      onClickDownloadCSV={onClickDownloadCsv}
      loadMoreProducts={loadMoreProducts}
      searchProducts={searchProducts}
    />
  );
}
