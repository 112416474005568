import { Document, Image, PDFViewer, Page, View } from "@react-pdf/renderer";
import React from "react";
import { ContractNotePlainTextProductTable, ContractNoteProductTable } from "./contract-note-tables";
import {
  BlankRow,
  ColumnLayout,
  DocSection,
  FlexSpread,
  MultiLineText,
  NamedField,
  Row,
  TextBlock,
  rightAlign,
} from "./pdf-components";
import { LoadingValue } from "utils/utility-types";
import { Loader } from "../loader/loader";
import { ContractNoteView } from "adl-gen/ferovinum/app/views";

const HEADER_HEIGHT = 120;
const BANNER_URL = "/doc-logo.png";

const PAYMENT_DATE_TERMS =
  "Within two Business Days of confirmed receipt by Ferovinum of the Approved Product" +
  " in Ferovinum's Account held for the Storage Location with the Storage Provider.";

const USE_PLAIN_TEXT_PRODUCT_TABLE_THRESHOLD = 100;

interface ContractNoteStorageLocationDetails {
  name: string;
  storageAccountCode: string | null | undefined;
}
interface ContractNoteDocumentProps {
  contractNote: ContractNoteView;
  supplierName?: string;
  storageLocationDetails: ContractNoteStorageLocationDetails;
}

export const ContractNoteDocumentView = (props: ContractNoteDocumentProps) => {
  const [doc, setDoc] = React.useState<LoadingValue<React.ReactElement>>({ state: "loading" });

  // Show loader spinner while the contract note PDF is being generated
  React.useEffect(() => {
    setTimeout(() => {
      setDoc({ state: "success", value: <ContractNoteDocument {...props} /> });
    });
  }, [props]);
  return (
    <Loader loadingStates={[doc]} fullScreen={true}>
      {doc.state === "success" && (
        <PDFViewer style={{ width: "100%", height: "calc(100vh - 200px)" }}>{doc.value}</PDFViewer>
      )}
    </Loader>
  );
};

const ContractNoteDocument = ({ contractNote, supplierName, storageLocationDetails }: ContractNoteDocumentProps) => {
  if (!contractNote) {
    throw new Error("Missing product details"); // this shouldn't happen
  }

  const { compulsorySaleDate, compulsorySalePc } = contractNote?.lineItems[0];

  return (
    <Document>
      <DocPage>
        <DocSection>
          <FlexSpread>
            <NamedField name="Client" value={contractNote.organisation.businessName} />
            <NamedField name="Date of Master Agreement" value={contractNote.masterAgreementSignedDate} />
          </FlexSpread>
          <TextBlock>{contractNote.dealTerms.general.content}</TextBlock>
          <FlexSpread>
            <NamedField name="Deal Number" value={contractNote.dealNumber} />
            <NamedField name="Execution date" value={contractNote.executionDate} />
          </FlexSpread>
        </DocSection>

        <DocSection name={"Purchase Details"}>
          <ColumnLayout columnSpacing={[26, 40, 12, 12]}>
            <Row cells={["Buyer:", "Ferovinum", "Approved Product", rightAlign("Per table below")]} />
          </ColumnLayout>
          <ColumnLayout columnSpacing={[26, 40, 24]}>
            <Row cells={["Seller:", "Client"]} />
            {supplierName && <Row cells={["Supplier:", supplierName]} />}
            <Row cells={["Storage Location:", storageLocationDetails.name]} />
            <Row cells={["Storage Provider:", storageLocationDetails.name]} />
            <Row cells={["Delivery Date:", "N/A"]} />
            <Row cells={["Ferovinum Receiving Account:", storageLocationDetails.storageAccountCode || "N/A"]} />
            <Row cells={["Payment Date:", PAYMENT_DATE_TERMS]} />
          </ColumnLayout>
        </DocSection>

        <DocSection name={"Sale Details"}>
          <ColumnLayout columnSpacing={[26, 40, 12, 12]}>
            <Row cells={["Buyer:", "Client", "Approved Product", rightAlign("Per table below")]} />
          </ColumnLayout>
          <ColumnLayout columnSpacing={[26, 40, 24]}>
            <Row cells={["Seller:", "Ferovinum"]} />
            <Row cells={["Start Date:", contractNote.startDate]} />
            <Row cells={["Interim Compulsory Sale Date:", compulsorySaleDate]} />
            <Row cells={["End Date:", contractNote.endDate]} />
            <Row cells={["Interim Compulsory Sale Obligation:", `${compulsorySalePc}%`]} />
            <Row cells={["Final Compulsory Sale Obligation:", "100%"]} />
            <Row
              cells={["Forward Sale Price:", <MultiLineText key={1} texts={[contractNote.dealTerms.fsp.content]} />]}
              cellSpans={[1, 2]}
            />
            <BlankRow />
            <Row cells={["Initial Fee:", `${contractNote.throughputFeePc}%`]} />
            <Row
              cells={[
                "Monthly Fee:",
                `${
                  contractNote.monthlyFee.kind == "fixed"
                    ? contractNote.monthlyFee.value
                    : contractNote.monthlyFee.value.base
                }%`,
              ]}
            />
            <Row cells={["Additional Terms", contractNote.additionalTermsText]} />
          </ColumnLayout>
        </DocSection>
      </DocPage>

      <DocPage>
        <DocSection name="Approved Products" fixSectionHeader={true}>
          {contractNote.lineItems.length > USE_PLAIN_TEXT_PRODUCT_TABLE_THRESHOLD ? (
            // when the number of products is large, use the plain text table to avoid performance issues.
            <ContractNotePlainTextProductTable contractNote={contractNote} />
          ) : (
            <ContractNoteProductTable contractNote={contractNote} />
          )}
        </DocSection>
      </DocPage>
    </Document>
  );
};

function DocPage(props: React.PropsWithChildren<unknown>) {
  return (
    <Page
      size="A4"
      style={{
        paddingTop: HEADER_HEIGHT,
        paddingBottom: 35,
        paddingHorizontal: 30,
        fontSize: 6,
        lineHeight: 2,
      }}>
      <DocHeader bannerUrl={BANNER_URL} height={HEADER_HEIGHT} />
      {props.children}
    </Page>
  );
}

function DocHeader(props: { height: number; bannerUrl: string }) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 30,
        right: 30,
        height: props.height,
        paddingTop: 30,
      }}
      fixed>
      <Image
        src={props.bannerUrl}
        style={{
          width: "40%",
        }}
      />
    </View>
  );
}
