import { AppService } from "adl-gen/app-service";
import { UploadCategory } from "adl-gen/ferovinum/app/api";

export type UploadFileResult =
  | {
      kind: "success";
      fileName: string;
    }
  | {
      kind: "error";
    };

export const UploadFile = async (
  isDevelopment: boolean,
  service: AppService,
  file: File,
  uploadCategory: UploadCategory,
  authToken: string,
): Promise<UploadFileResult> => {
  const uploadDetails = await service.getUploadUrl({ uploadCategory, filename: file.name });
  const arrayBuffer = await file.arrayBuffer();

  const resp = await fetch(uploadDetails.uploadUrl, {
    method: uploadDetails.method,
    body: arrayBuffer,
    ...(isDevelopment && { headers: { "X-Auth-Token": authToken } }),
  });

  if (resp.ok) {
    return { kind: "success", fileName: uploadDetails.key };
  } else {
    return { kind: "error" };
  }
};

export const downloadFileAs = async (url: string, fileName: string) => {
  const resp = await fetch(url);
  if (!resp.ok) {
    throw new Error("Failed to download file");
  }
  const blob = await resp.blob();
  const aLink = document.createElement("a");
  aLink.href = URL.createObjectURL(blob);
  aLink.download = fileName;
  document.body.appendChild(aLink);
  aLink.click();
  document.body.removeChild(aLink);
  URL.revokeObjectURL(aLink.href);
};
