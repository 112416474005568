/* @generated from adl module common.config.flyway */

import * as ADL from './../../runtime/adl';

export interface FlywayConfig {
  dbMigrationDir: string;
  autoBaseline: boolean;
}

export function makeFlywayConfig(
  input: {
    dbMigrationDir?: string,
    autoBaseline?: boolean,
  }
): FlywayConfig {
  return {
    dbMigrationDir: input.dbMigrationDir === undefined ? "/app/sql/migrations" : input.dbMigrationDir,
    autoBaseline: input.autoBaseline === undefined ? false : input.autoBaseline,
  };
}

const FlywayConfig_AST : ADL.ScopedDecl =
  {"moduleName":"common.config.flyway","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"dbMigrationDir","default":{"kind":"just","value":"/app/sql/migrations"},"name":"dbMigrationDir","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[],"serializedName":"autoBaseline","default":{"kind":"just","value":false},"name":"autoBaseline","typeExpr":{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}}]}},"name":"FlywayConfig","version":{"kind":"nothing"}}};

export const snFlywayConfig: ADL.ScopedName = {moduleName:"common.config.flyway", name:"FlywayConfig"};

export function texprFlywayConfig(): ADL.ATypeExpr<FlywayConfig> {
  return {value : {typeRef : {kind: "reference", value : snFlywayConfig}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "common.config.flyway.FlywayConfig" : FlywayConfig_AST
};
