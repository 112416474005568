// This function is used to correct the precision of floating point numbers
// Decimal points cannot be represented exactly by floating point numbers.
// Even simple additions of 2 numbers can result in a number with a long decimal part.
// e.g. 9.12 + 2.28 = 11.399999999999999
// While this gives rise to a range of issues, the practical solution for most
// cases (for us) is to round to a sensible number of decimal places.
export function correctFloatPrecision(n: number, precision = 8): number {
  const factor = Math.pow(10, precision);
  return Math.round(n * factor) / factor;
}

function countDecimalPlaces(numStr: string): number {
  const parts = numStr.split(".");
  return parts[1]?.length ?? 0;
}

export function limitPrecision(val: string, precision: number): string;
export function limitPrecision(val: number, precision: number): number;
export function limitPrecision(val: string | number, precision: number) {
  if (typeof val === "number") {
    return correctFloatPrecision(val, precision);
  } else {
    return countDecimalPlaces(val) <= precision ? val : limitPrecision(Number(val), precision).toString();
  }
}

const DECIMAL_SEPARATOR = ".";
export function truncateDecimals(numS: string, digits: number) {
  const decPos = numS.indexOf(DECIMAL_SEPARATOR);
  const substrLength = decPos == -1 ? numS.length : 1 + decPos + digits;
  return numS.substring(0, substrLength);
}

export function toPrecision(v: string | number, digits: number) {
  const num = typeof v === "string" ? Number(v) : v;
  const r = Math.pow(10, digits);
  return (Math.round(num * r) / r).toFixed(digits);
}

export function doCalc(
  s1: string | number | undefined,
  s2: string | number | undefined,
  op: (a: number, b: number) => number | undefined,
): number | undefined {
  const n1 = parseNumber(s1);
  const n2 = parseNumber(s2);
  return n1 !== undefined && n2 !== undefined ? op(n1, n2) : undefined;
}

export function parseNumber(v?: string | number): number | undefined {
  const num = typeof v === "number" ? v : v?.trim() ? Number(v) : NaN;
  return isNaN(num) ? undefined : num;
}
