import { Box, Grid, Stack, Typography } from "@mui/material";
import { Country, ProductType, UnitType } from "adl-gen/ferovinum/app/db";
import { assertNever } from "assert-never";
import { InputUiBuilder } from "components/library/helpers/input-ui-builder";
import { FormikForm } from "components/types/formik-types";
import { ExpandableRadio } from "components/widgets/inputs/expandable-radio/expandable-radio";
import { pick } from "lodash";
import React, { useMemo } from "react";
import { unitTypeToString } from "utils/conversion-utils";
import { booleanField, requiredErrorHandler } from "utils/data-field/data-field-builder";
import { makeObjectDef, makeObjectSchema, pickData } from "utils/data-field/object-field-def";
import { assertNotUndefined } from "utils/hx/util/types";
import { isCase, isSinglesUnitType, vesselSizeForUnitType, vesselSizeMetricForUnitType } from "utils/model-utils";
import { mapOptional } from "utils/type-utils";
import { ObjectSchema, Schema } from "yup";
import { updateProductCodeSchema } from "../../../service/csv-product-data-yup-schemas";
import { SIMPLE_PRODUCT_DATA_DEF, SimpleProductData } from "../../../service/simple-product-csv-parser";
import { NewProductData } from "../../page/organisation/new-deal-requests/organisation-create-new-deal-request/organisation-create-new-deal-request-page";
import { ProductFormHandler } from "./product-editor-form-types";

const PARTIAL_PRODUCT_DEF = pick(SIMPLE_PRODUCT_DATA_DEF, [
  "code",
  "name",
  "producerName",
  "productType",
  "unitType",
  "alcoholByVolumePc",
  "countryOfOrigin",
  "regionOrigin",
]);

export type SimpleProductFormData = SimpleProductData & {
  isProductVintage: boolean;
};

const SIMPLE_PRODUCT_FORM_PROPS_DEF = makeObjectDef<SimpleProductFormData>({
  ...SIMPLE_PRODUCT_DATA_DEF,
  isProductVintage: booleanField("Is Product Vintage").required(),
});

export class SimpleProductFormHandler implements ProductFormHandler<SimpleProductFormData> {
  createValidationSchema(
    localProductCodes: Set<string>,
    validateProductCode: (productCode: string) => Promise<boolean>,
  ): ObjectSchema<SimpleProductFormData> {
    return makeObjectSchema(SIMPLE_PRODUCT_FORM_PROPS_DEF, (def, key) => {
      // TODO Zhi: SIMPLIFY do these validations ouside yup
      switch (key) {
        case "code":
          return updateProductCodeSchema(def.getSchema() as Schema<string>, localProductCodes, validateProductCode);
        case "vintageYear":
          return def.getSchema().when(["isProductVintage"], {
            is: (isProductVintage: boolean) => isProductVintage,
            then: schema => schema.required(requiredErrorHandler),
          });
        default:
          return def.getSchema();
      }
    });
  }

  fromProductData(productData?: NewProductData): Partial<SimpleProductFormData> {
    const unitSize = mapOptional(productData?.vesselSize, vesselSize => {
      // Default to 1 for non singles (cask, tank, etc...), return the centilitres for per single for cases
      if (vesselSize === null) {
        return 1;
      } else {
        switch (vesselSize.kind) {
          case "centilitres":
            return vesselSize.value;
          case "case":
            return vesselSize.value.centilitresPerSingle;
          default:
            assertNever(vesselSize);
        }
      }
    });
    const numberOfSingles = mapOptional(productData?.vesselSize, vesselSize =>
      // return the number of singles for cases, return undefined for everything else
      vesselSize?.kind === "case" ? vesselSize.value.numberOfSingles : undefined,
    );

    return {
      ...(productData && pickData(PARTIAL_PRODUCT_DEF, productData)),
      vintageYear: productData?.productDate?.kind === "vintageYear" ? productData.productDate.value : undefined,
      isProductVintage: productData?.productDate?.kind === "vintageYear",
      productType: productData?.productType,
      unitSize,
      numberOfSingles,
    };
  }

  toProductData(values: SimpleProductFormData): NewProductData {
    const productData = pickData(PARTIAL_PRODUCT_DEF, values);
    const unitType = assertNotUndefined(values.unitType);
    return {
      ...productData,
      vesselSize: vesselSizeForUnitType(
        unitType,
        unitType === "case"
          ? { numberOfSingles: values.numberOfSingles ?? 0, centilitresPerSingle: values.unitSize ?? 0 }
          : values.unitSize,
      ),
      regionOrigin: values.regionOrigin || "",
      productDate: values.isProductVintage
        ? { kind: "vintageYear", value: assertNotUndefined(values.vintageYear) }
        : { kind: "nonVintage" },
      alcoholDetail: { kind: "unknown", value: {} },
      vesselType: { kind: "unknown", value: {} },
    };
  }
}

export function SimpleProductEditorForm(props: { form: FormikForm<SimpleProductFormData> }) {
  const { form } = props;
  const isSingles = form.values.unitType && isSinglesUnitType(form.values.unitType);
  const isCases = form.values.unitType && isCase(form.values.unitType);
  const unitTypeIsSelected = form.values.unitType !== undefined;

  const uiBuilder = useMemo(() => {
    return new InputUiBuilder<SimpleProductFormData>(SIMPLE_PRODUCT_FORM_PROPS_DEF, form);
  }, [form]);

  return (
    <Grid container spacing={3} sx={{ pt: 2 }}>
      {uiBuilder.createTextField("code", { grid: { xs: 6 } })}
      {uiBuilder.createTextField("name", { grid: { xs: 6 } })}
      {uiBuilder.createTextField("producerName", { grid: { xs: 6 } })}
      {uiBuilder.createEnumField<ProductType>("productType", { grid: { xs: 6 } })}
      {uiBuilder.createEnumField<UnitType>("unitType", {
        grid: { xs: isSingles && unitTypeIsSelected ? 4 : 6 },
        onChange: unitType => {
          form.setFieldValue("unitSize", !(isSinglesUnitType(unitType) || isCase(unitType)) ? "1" : undefined);
        },
        renderOption: (props, unitType) => <UnitTypeOptionItem {...props} unitType={unitType} />,
      })}
      {isCases && unitTypeIsSelected && (
        <Grid item xs={6}>
          {uiBuilder.createNumberField("numberOfSingles")},
          {uiBuilder.createNumberField("unitSize", {
            label: "Unit size of singles",
            suffix: vesselSizeMetricForUnitType(form.values.unitType),
          })}
          ,
        </Grid>
      )}
      {isSingles &&
        unitTypeIsSelected &&
        uiBuilder.createNumberField("unitSize", {
          grid: { xs: 4 },
          suffix: vesselSizeMetricForUnitType(form.values.unitType),
        })}
      {uiBuilder.createNumberField("alcoholByVolumePc", {
        grid: { xs: isSingles && unitTypeIsSelected ? 4 : 6 },
        step: 0.1,
        suffix: "%",
      })}
      {uiBuilder.createEnumField<Country>("countryOfOrigin", { grid: { xs: 6 } })}
      {uiBuilder.createTextField("regionOrigin", { grid: { xs: 6 } })}
      {unitTypeIsSelected && (
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography variant="body2">Year of production</Typography>
            <ExpandableRadio
              options={[
                {
                  value: "true",
                  label: "Yes",
                  children: uiBuilder.createNumberField("vintageYear", { label: "Vintage / Year of distillation" }),
                },
                {
                  value: "false",
                  label: "Non-vintage / Blend",
                },
              ]}
              value={form.values.isProductVintage?.toString() ?? ""}
              onChange={value => form.setFieldValue("isProductVintage", value === "true")}
            />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}

function UnitTypeOptionItem(
  props: React.HTMLAttributes<HTMLElement> & {
    unitType: UnitType;
  },
) {
  const { unitType } = props;
  return (
    <Box component="li" {...props}>
      {unitTypeToString(unitType)}
    </Box>
  );
}
