import { AppService } from "adl-gen/app-service";
import { OrganisationProductResp, OrganisationProductResp_ExistingProduct } from "adl-gen/ferovinum/app/api";
import { ParseResult } from "papaparse";
import { CaskedWhiskyCsvParser } from "./casked-whisky-product-csv-parser";
import { CsvProductLineItemData, ProcessedCsvProductData, ProductCsvParser } from "./csv-product-data-processing-types";
import { SimpleProductCsvParser } from "./simple-product-csv-parser";

function createMatchingCsvParser(csvHeader: string[], isNewStock?: boolean): ProductCsvParser {
  if (CaskedWhiskyCsvParser.matchHeaders(csvHeader)) {
    return new CaskedWhiskyCsvParser(csvHeader);
  } else {
    return new SimpleProductCsvParser(csvHeader, isNewStock ?? false);
  }
}

export class CsvProductDataProcessor {
  private appService: AppService;
  private readonly orgId: string;

  constructor({ appService, orgId }: { appService: AppService; orgId: string }) {
    this.appService = appService;
    this.orgId = orgId;
  }

  public async parseCSVToProductLineItemData(
    parseResult: ParseResult<string[]>,
    isNewStock?: boolean,
  ): Promise<ProcessedCsvProductData> {
    const { data, errors: parseErrors } = parseResult;
    const rowsWithoutHeader = data.slice(1);
    const localUniqueProductCodes: Set<string> = new Set();

    const result: ProcessedCsvProductData = {
      products: [],
      errors: [],
    };

    const csvParser = createMatchingCsvParser(parseResult.data[0], isNewStock);
    const productCodes: string[] = rowsWithoutHeader.map(row => csvParser.getProductCode(row));
    const existingProducts = await this.getProductCodesToProductsMap(productCodes);

    for (let index = 0; index < rowsWithoutHeader.length; index++) {
      // Note(Berto): rowNumber represents the row in the CSV file, including the header row
      // index is the array index without header row. i.e: array index = 0 => row number = 2
      const row = rowsWithoutHeader[index];
      const rowNumber = index + 2;

      const productCode = csvParser.getProductCode(row);
      if (productCode.length === 0) {
        result.errors.push({
          value: { rowIdentifier: undefined, errors: ["Product Code is missing."] },
          rowNumber,
        });
        continue;
      }

      const productResp = existingProducts.get(productCode);

      let errors: string[] = [];
      const parseError = parseErrors.find(e => e.row === rowNumber);

      if (parseError) {
        errors = [parseError.message];
      } else if (productResp?.kind === "unauthorisedProduct") {
        errors = ["This product code already exists"];
      } else {
        errors = csvParser.getErrorMessagesForRow(productResp !== undefined, row, localUniqueProductCodes);
      }

      if (errors.length > 0) {
        this.handleDuplicateProductCodes(productCode, localUniqueProductCodes, result);
        result.errors.push({ value: { rowIdentifier: productCode, errors }, rowNumber });
      } else {
        const value: CsvProductLineItemData = productResp
          ? csvParser.createExistingProduct(row, assertExistingProduct(productResp).value)
          : csvParser.createNewProduct(row);

        result.products.push({ value, rowNumber });
      }

      localUniqueProductCodes.add(productCode);
    }

    return result;
  }

  private async getProductCodesToProductsMap(productCodes: string[]) {
    const resp = await this.appService.getProductCodeToProductMap({ organisationId: this.orgId, productCodes });
    return new Map(resp.map(r => [r.key, r.value]));
  }

  private handleDuplicateProductCodes(
    currentProductCode: string,
    localUniqueProductCodes: Set<string>,
    previousResults: ProcessedCsvProductData,
  ): void {
    // If product code is entered more than once in the CSV, it is no longer valid and should be removed
    // from the array of successfully uploaded products.
    if (localUniqueProductCodes.has(currentProductCode)) {
      const previouslySuccessfulProduct = previousResults.products.find(
        p => p.value.productCode === currentProductCode,
      );
      if (previouslySuccessfulProduct) {
        previousResults.products = previousResults.products.filter(p => p !== previouslySuccessfulProduct);
        previousResults.errors.push({
          value: {
            rowIdentifier: previouslySuccessfulProduct.value.productCode,
            errors: ["Product Code is entered more than once."],
          },
          rowNumber: previouslySuccessfulProduct.rowNumber,
        });
      }
    }
  }
}

function assertExistingProduct(v: OrganisationProductResp): OrganisationProductResp_ExistingProduct {
  if (v.kind === "existingProduct") {
    return v;
  }
  throw new Error("Expected value type to be existing product");
}
