import { AppService } from "adl-gen/app-service";
import { SaleOrderDetailsView } from "adl-gen/ferovinum/app/api";
import { HttpServiceError } from "adl-service/http-service-error";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { Loader } from "components/widgets/loader/loader";
import React, { useCallback, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { assertNotUndefined } from "utils/hx/util/types";
import { isLoaded } from "utils/utility-types";
import { AppRoutes } from "../../../../../app/app-routes";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { RepurchaseFlowState } from "../organisation-repurchase-delivery/organisation-repurchase-delivery-page";
import { OrganisationRepurchaseConfirmationPageView } from "./organisation-repurchase-confirmation-page-view";
import { ensure } from "utils/null-and-undefined-utils";

export type PaymentMethodOption = "settlementCredit" | "directTransfer";

export const OrganisationRepurchaseConfirmationPage = () => {
  const location = useLocation();
  const history = useHistory();
  const repurchaseFlowState = location.state as RepurchaseFlowState | undefined;
  const service: AppService = useAppService();
  const selectedOrgId = assertNotUndefined(useSelectedOrgId());
  const [showAlert] = useAlert();
  const [confirmedSaleOrder, setConfirmedSaleOrder] = useState<SaleOrderDetailsView>();

  const getSaleOrderPreview = useCallback(async () => {
    if (repurchaseFlowState?.deliveryOptionRequest) {
      return await service.getSaleOrderPreview({
        organisationId: selectedOrgId,
        storageLocationId: repurchaseFlowState.repurchaseRequest.storageLocationId,
        deliveryOption: repurchaseFlowState.deliveryOptionRequest,
        productsSaleOrders: repurchaseFlowState.repurchaseRequest.productsSaleOrders,
      });
    }
  }, [repurchaseFlowState, selectedOrgId, service]);
  const [loadingSaleOrderPreview] = useLoadingDataState(getSaleOrderPreview);

  const getSelectedOrganisationDeliveryLocation = useCallback(async () => {
    if (
      repurchaseFlowState?.deliveryOptionRequest &&
      repurchaseFlowState.deliveryOptionRequest.kind === "orgLocationDelivery"
    ) {
      const allDeliveryLocations = await service.getOrganisationDeliveryLocations({ organisationId: selectedOrgId });
      return allDeliveryLocations.find(loc => loc.id === repurchaseFlowState.deliveryOptionRequest?.value);
    }
  }, [repurchaseFlowState?.deliveryOptionRequest, selectedOrgId, service]);
  const [loadingSelectedOrganisationDeliveryLocations] = useLoadingDataState(getSelectedOrganisationDeliveryLocation);

  const getStorageLocationViews = useCallback(async () => {
    const { repurchaseRequest, destinationLocationId } = repurchaseFlowState || {};
    const ids: string[] = [repurchaseRequest?.storageLocationId, destinationLocationId].filter((id): id is string =>
      Boolean(id),
    );

    if (ids.length === 0) {
      throw new Error("Unable to find the source or destination storage location.");
    }

    const locations = await service.getStorageLocations({
      orgId: selectedOrgId,
      selector: {
        kind: "byIds",
        value: ids,
      },
    });

    return {
      storageLocation: ensure(locations.find(loc => loc.id === repurchaseRequest?.storageLocationId)),
      destinationLocation: locations.find(loc => loc.id === destinationLocationId),
    };
  }, [selectedOrgId, repurchaseFlowState, service]);
  const [loadingStorageLocationViews] = useLoadingDataState(getStorageLocationViews);

  const onConfirm = useCallback(
    async (paymentMethod: PaymentMethodOption) => {
      //Note: These are safe to assume as defined on this callback, the page will have redirected to the index otherwise
      const state = assertNotUndefined(repurchaseFlowState);
      const deliveryOption = assertNotUndefined(repurchaseFlowState?.deliveryOptionRequest);
      try {
        const resp = await service.createSaleOrder({
          organisationId: selectedOrgId,
          storageLocationId: state.repurchaseRequest.storageLocationId,
          deliveryOption,
          productsSaleOrders: state.repurchaseRequest.productsSaleOrders,
          useCredit: paymentMethod === "settlementCredit",
        });

        if (resp.kind === "created") {
          // Note: if the users manually reloads the page it will take them to the correct sale order page
          // (use native window.location to avoid react-router redirect)
          const saleOrderId = resp.value;
          window.history.replaceState(
            null,
            "Sale order details",
            `${AppRoutes.SaleOrder}/${encodeURIComponent(saleOrderId)}`,
          );
          const createdSaleOrder = await service.getSaleOrder({ saleOrderId });
          setConfirmedSaleOrder(createdSaleOrder);
        } else if (resp.kind === "error") {
          void showAlert({
            title: "Error creating Sale Order, please try again.",
            body: `
              Error: ${resp.value}.
              
              If the issue persists, please contact support.
            `,
          });
        }
      } catch (e: unknown) {
        const body = e instanceof HttpServiceError ? e.publicMessage : String(e);
        void showAlert({ title: "Error creating a sale order, please try again.", body });
      }
    },
    [repurchaseFlowState, selectedOrgId, service, showAlert],
  );

  const navigateBackToDeliveryOptions = useCallback(() => {
    if (repurchaseFlowState) {
      history.push(AppRoutes.RepurchaseDelivery, repurchaseFlowState);
    } else {
      history.push(AppRoutes.Repurchase);
    }
  }, [repurchaseFlowState, history]);

  const loadSettlementCreditDetails = useCallback(
    async () => await service.getOrganisationCreditLimitDetails({ orgId: selectedOrgId }),
    [selectedOrgId, service],
  );
  const [loadingSettlementCreditDetails] = useLoadingDataState(loadSettlementCreditDetails);

  if (repurchaseFlowState === undefined || repurchaseFlowState.deliveryOptionRequest === undefined) {
    return <Redirect to={AppRoutes.Index} />;
  } else {
    return (
      <Loader
        loadingStates={[
          loadingSaleOrderPreview,
          loadingSelectedOrganisationDeliveryLocations,
          loadingSettlementCreditDetails,
        ]}
        fullScreen>
        {isLoaded(loadingSelectedOrganisationDeliveryLocations) &&
          isLoaded(loadingSaleOrderPreview) &&
          isLoaded(loadingStorageLocationViews) &&
          isLoaded(loadingSettlementCreditDetails) &&
          loadingSaleOrderPreview.value !== undefined &&
          loadingStorageLocationViews.value !== undefined && (
            <OrganisationRepurchaseConfirmationPageView
              storageLocation={loadingStorageLocationViews.value.storageLocation}
              destinationLocation={loadingStorageLocationViews.value.destinationLocation}
              saleOrderPreview={loadingSaleOrderPreview.value}
              deliveryOptionReq={repurchaseFlowState.deliveryOptionRequest}
              selectedOrganisationDeliveryLocation={loadingSelectedOrganisationDeliveryLocations?.value}
              navigateBackToDeliveryOptions={navigateBackToDeliveryOptions}
              onConfirm={onConfirm}
              confirmedSaleOrder={confirmedSaleOrder}
              settlementCreditDetails={loadingSettlementCreditDetails.value}
            />
          )}
      </Loader>
    );
  }
};
