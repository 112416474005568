import { Stack, Typography } from "@mui/material";
import React from "react";
import { InfoTooltip } from "../../../widgets/info-tooltip/info-tooltip";

interface BaseWizardCardProps {
  title: string;
  titleTooltip?: string;
  subtitle?: string;
  inputElement: React.JSX.Element;
}

export const BaseWizardCard = ({ title, titleTooltip, subtitle, inputElement }: BaseWizardCardProps) => {
  return (
    <Stack spacing={4} sx={{ backgroundColor: "white", borderRadius: 1 }} padding={4} width={"100%"}>
      <Stack spacing={2} width={"100%"}>
        {titleTooltip ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h6">{title}</Typography>
            <InfoTooltip title={titleTooltip} />
          </Stack>
        ) : (
          <Typography variant="h6">{title}</Typography>
        )}
        {subtitle && <Typography variant="body1">{subtitle}</Typography>}
      </Stack>
      <Stack direction="row" alignItems="center">
        {inputElement}
      </Stack>
    </Stack>
  );
};
