import { assertNotUndefined } from "utils/hx/util/types";
import React, { useCallback, useState } from "react";
import { DbKey } from "adl-gen/common/db";
import {
  CollectionDetails,
  NominatedPurchasersForPurchaser,
  UpdatePurchaseRequestResp,
} from "adl-gen/ferovinum/app/api";
import { Organisation, PurchaseRequest } from "adl-gen/ferovinum/app/db";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { useAppService } from "../../../../hooks/use-app-service";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { useSelectedPurchaser } from "../../../layouts/portal-page-layout/portal-page";
import { Loader } from "components/widgets/loader/loader";
import { isLoaded } from "utils/utility-types";
import { PurchaserPurchaseRequestsPageView } from "./purchaser-purchase-requests-page-view";

export const PurchaserPurchaseRequestsPage = () => {
  const service = useAppService();
  const selectedPurchaser = assertNotUndefined(useSelectedPurchaser());
  const [showAlert] = useAlert();

  const [activeNominatedPurchaser, setActiveNominatedPurchaser] = useState<NominatedPurchasersForPurchaser>();

  const loadNominatedPurchasers = useCallback(async () => {
    const resp = await service.getNominatedPurchasersForPurchaser({ purchaserId: selectedPurchaser.id });
    if (resp.length > 0 && activeNominatedPurchaser === undefined) {
      setActiveNominatedPurchaser(resp[0]);
    }
    return resp;
  }, [activeNominatedPurchaser, selectedPurchaser, service]);
  const [loadingOrganisationsForPurchaser] = useLoadingDataState(loadNominatedPurchasers);

  const loadPurchaseRequests = useCallback(async () => {
    // TODO: Implement endpoint to get purchase requests by org ID
    const purchaseRequests = await service.getPurchaseRequestsForPurchaser({
      purchaserId: selectedPurchaser.id,
    });

    if (activeNominatedPurchaser) {
      return purchaseRequests.filter(pr => pr.organisationId === activeNominatedPurchaser.organisationId);
    }
    return [];
  }, [activeNominatedPurchaser, selectedPurchaser.id, service]);
  const [loadingPurchaseRequests, refreshPurchaseRequests] = useLoadingDataState(loadPurchaseRequests);

  const onUpdatePurchaseRequestState = useCallback(
    async (resp: UpdatePurchaseRequestResp) => {
      if (resp !== "success") {
        await showAlert({
          title: "Error occured",
          body: "Purchase request is not in a valid state.",
        });
      } else {
        refreshPurchaseRequests();
      }
    },
    [refreshPurchaseRequests, showAlert],
  );

  const getPurchaseRequestBreakdown = useCallback(
    async (id: DbKey<PurchaseRequest>) => {
      return await service.getPurchaseRequestBreakdownForPurchaser({
        purchaseRequestId: id,
      });
    },
    [service],
  );

  const onTermsAccepted = useCallback(
    async (purchaseRequestId: DbKey<PurchaseRequest>, collectionDetails: CollectionDetails | null) => {
      await onUpdatePurchaseRequestState(
        await service.purchaserAcceptPurchaseRequest({
          purchaseRequestId: purchaseRequestId,
          collectionDetails: collectionDetails,
        }),
      );
    },
    [service, onUpdatePurchaseRequestState],
  );

  const onTermsRejected = useCallback(
    async (purchaseRequestId: DbKey<PurchaseRequest>) => {
      await onUpdatePurchaseRequestState(
        await service.purchaserRejectPurchaseRequest({
          purchaseRequestId: purchaseRequestId,
        }),
      );
    },
    [service, onUpdatePurchaseRequestState],
  );

  const onFetchTerms = useCallback(
    async (purchaseRequestId: DbKey<PurchaseRequest>) =>
      await service.getNominatedPurchaserTermsForPurchaser(purchaseRequestId),
    [service],
  );

  const onChangeActiveOrganisation = useCallback(
    (id: DbKey<Organisation>) => {
      if (isLoaded(loadingOrganisationsForPurchaser)) {
        setActiveNominatedPurchaser(loadingOrganisationsForPurchaser.value.find(org => org.organisationId === id));
      }
    },
    [loadingOrganisationsForPurchaser],
  );

  return (
    <Loader loadingStates={[loadingOrganisationsForPurchaser, loadingPurchaseRequests]} fullScreen>
      {isLoaded(loadingOrganisationsForPurchaser) && isLoaded(loadingPurchaseRequests) && (
        <PurchaserPurchaseRequestsPageView
          activeOrganisation={activeNominatedPurchaser?.organisationId}
          purchaseRequests={loadingPurchaseRequests.value}
          organisations={loadingOrganisationsForPurchaser.value}
          onChangeOrganisation={onChangeActiveOrganisation}
          getPurchaseRequestBreakdown={getPurchaseRequestBreakdown}
          onTermsAccepted={onTermsAccepted}
          onTermsRejected={onTermsRejected}
          onFetchTerms={onFetchTerms}
        />
      )}
    </Loader>
  );
};
