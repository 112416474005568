import React from "react";
import { Instant, LocalDate } from "adl-gen/common";
import { PurchaseRequestState, valuesPurchaseRequestState } from "adl-gen/ferovinum/app/db";
import { assertNever } from "assert-never";
import { BaseProgressStepper } from "components/widgets/base-progress-stepper/base-progress-stepper";

type DisplayablePurchaseRequestState = Exclude<PurchaseRequestState, "purchaserRejected" | "expired">;

function getProgressStepperLabelFromState(state: DisplayablePurchaseRequestState) {
  switch (state) {
    case "new":
      return "Created";
    case "purchaserAccepted":
      return "Purchaser accepted";
    case "productionOrderComplete":
      return "Prod order complete";
    case "readyForCollection":
      return "Ready for collection";
    case "collected":
      return "Collected";
    case "delivered":
      return "Delivered";
    case "purchaserInvoiced":
      return "Purchaser invoiced";
    case "purchaserPaid":
      return "Purchaser paid";
    case "organisationPaid":
      return "Org paid";
    default:
      assertNever(state);
  }
}
const STATES = valuesPurchaseRequestState.filter(s => s !== "purchaserRejected" && s !== "expired");

export interface PurchaseRequestProgressStepperProps {
  currentState: PurchaseRequestState;
  lastStateSet: Instant;
  upcomingStateDeadline?: LocalDate;
}
export const PurchaseRequestProgressStepper = ({
  currentState,
  lastStateSet,
  upcomingStateDeadline,
}: PurchaseRequestProgressStepperProps) => {
  return (
    <BaseProgressStepper<PurchaseRequestState>
      currentState={currentState}
      states={STATES}
      lastStateSet={lastStateSet}
      upcomingStateDeadline={upcomingStateDeadline}
      getHumanReadableState={state => getProgressStepperLabelFromState(state as DisplayablePurchaseRequestState)}
    />
  );
};
