import React from "react";
import { Incoterms } from "adl-gen/ferovinum/app/db";
import { StickyNote, StickyNoteAction } from "../sticky-note";
import { incotermsToString } from "utils/conversion-utils";

export interface IncotermsStickyNoteProps {
  incoterms: Incoterms;
  action?: StickyNoteAction;
}
export const IncotermsStickyNote = ({ incoterms, action }: IncotermsStickyNoteProps) => {
  return <StickyNote title="Incoterms" line1={incotermsToString(incoterms)} action={action} />;
};
