import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Grid,
  Paper,
  Stack,
  TableBodyProps,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { WithDbId } from "adl-gen/common/db";
import { OrganisationImportantReminders, ProductDealLeg, StockListing } from "adl-gen/ferovinum/app/api";
import { NumberOfUnits, Product, TopLevelUnitType, snTopLevelUnitType } from "adl-gen/ferovinum/app/db";
import { useUiConfig } from "adl-service/hooks/use-ui-config";
import { PaginatedTable } from "components/widgets/paginated-table/paginated-table";
import _ from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { getFormLabelForUnionField } from "utils/adl-utils";
import { assertNotUndefined } from "utils/hx/util/types";
import { add, getVesselCapacity, isSingles } from "utils/model-utils";
import { LoadingValue, isLoaded } from "utils/utility-types";
import { AppRoutes } from "../../../../app/app-routes";
import { LoadedFspValues, useFspState } from "../../../../hooks/use-fsp-state";
import { PortalPageContent } from "../../../layouts/portal-page-content/portal-page-content";
import { useSettlementCurrency } from "../../../layouts/portal-page-layout/portal-page";
import { IconStockCard, IconStockCardProps } from "../../../widgets/common/icon-stock-card/icon-stock-card";

import { OrganisationProductSummary } from "../../../widgets/organisation-product-summary/organisation-product-summary";
import { RepurchaseDateRange } from "../../../widgets/repurchase-date-range/repurchase-date-range";
import { StockTypeToggle } from "../../../widgets/stock-type-toggle/stock-type-toggle";
import { CurrencyRange } from "components/widgets/currency-renderer/currency-range";
import { FSP } from "adl-gen/ferovinum/app/views";
import { FspRange } from "../../../widgets/fsp-range/fsp-range";
import { PortalPageContentHeader } from "../../../layouts/portal-page-content-header/portal-page-content-header";
export interface StockDataDisplayInfo {
  stockDataMap: Map<string, StockListing[]>;
  totalRowCount: number;
}

export interface OrganisationDashboardPageViewProps {
  // Stock data by top level unit type (cans, bottles, cask) and by product code
  stockDataLookup: Map<TopLevelUnitType, StockDataDisplayInfo>;
  availableStockTypes: Set<TopLevelUnitType>;
  loadingImportantReminders: LoadingValue<OrganisationImportantReminders>;
  loadMoreStockDataMap: (
    topLevelUnitType: TopLevelUnitType,
    offset: number,
  ) => Promise<StockDataDisplayInfo["stockDataMap"] | undefined>;
}
export const OrganisationDashboardPageView = ({
  stockDataLookup,
  availableStockTypes,
  loadingImportantReminders,
  loadMoreStockDataMap,
}: OrganisationDashboardPageViewProps) => {
  const hasAnyStock = useMemo<boolean>(() => availableStockTypes.size > 0, [availableStockTypes.size]);
  const hasMoreThanOneTypeOfStock = useMemo<boolean>(() => availableStockTypes.size > 1, [availableStockTypes]);
  const [stockDisplayed, setStockDisplayed] = useState<TopLevelUnitType | undefined>(
    availableStockTypes.size > 0 ? [...availableStockTypes].sort()[0] : undefined,
  );

  useEffect(() => {
    const firstAvailableStockType = [...availableStockTypes].sort()[0];
    setStockDisplayed(firstAvailableStockType);
  }, [availableStockTypes]);

  const selectedStockListing = stockDisplayed ? stockDataLookup.get(stockDisplayed) : undefined;

  const loadMore = useCallback(
    async (offset: number) => {
      return stockDisplayed && (await loadMoreStockDataMap(stockDisplayed, offset));
    },
    [loadMoreStockDataMap, stockDisplayed],
  );

  return (
    <PortalPageContent header={<PortalPageContentHeader title="Dashboard" />}>
      <Stack spacing={4}>
        <Collapse in={isLoaded(loadingImportantReminders)}>
          {isLoaded(loadingImportantReminders) && loadingImportantReminders.value.expiringStock !== null && (
            <Grid container>
              <Grid item xs={3}>
                <ReminderCard
                  title={"Review Expiring Stock"}
                  body="You have stock expiring soon. Let us know how you would like to manage it."
                  buttonProps={{
                    label: "Review now",
                    route: AppRoutes.OrganisationExpiringStock,
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Collapse>
        {hasAnyStock ? (
          <>
            {hasMoreThanOneTypeOfStock && (
              <>
                <StockTypeToggle
                  topLevelUnitType={stockDisplayed}
                  onChange={setStockDisplayed}
                  availableTypes={availableStockTypes}
                />
                <Divider />
              </>
            )}
            <Stack spacing={8}>
              {selectedStockListing !== undefined && stockDisplayed !== undefined && (
                <IconStockCards stockType={stockDisplayed} stockData={selectedStockListing} />
              )}
              {selectedStockListing !== undefined && (
                <StockListingTable
                  key={stockDisplayed}
                  loadMoreStockDataMap={loadMore}
                  initStockListings={selectedStockListing}
                />
              )}
            </Stack>
          </>
        ) : (
          <Alert severity="info">You currently have no available products held on the platform.</Alert>
        )}
      </Stack>
    </PortalPageContent>
  );
};

interface ReminderCardProps {
  title: string;
  body: string;
  buttonProps: { label: string; route: AppRoutes };
}
const ReminderCard = ({ title, body, buttonProps }: ReminderCardProps) => {
  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2} alignItems="center">
        <Paper
          elevation={0}
          sx={{
            backgroundColor: "warning.light",
            p: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <WarningRoundedIcon sx={{ color: "warning.main" }} />
        </Paper>
        <Stack spacing={1} alignItems="center">
          <Typography textAlign="center" variant="body1Bold">
            {title}
          </Typography>
          <Typography textAlign="center">{body}</Typography>
        </Stack>

        <Stack width="100%" spacing={2}>
          <Divider />
          <Button href={buttonProps.route} disableElevation color="warning">
            {buttonProps.label}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

interface IconStockCardsProps {
  stockType: TopLevelUnitType;
  stockData: StockDataDisplayInfo;
}

const IconStockCards = ({ stockType, stockData }: IconStockCardsProps) => {
  const { dashboardPagination } = useUiConfig();
  const getSinglesQuantities = useCallback(() => {
    let total = 0;
    let approachingDeadlines = 0;
    let closeToDeadlines = 0;

    for (const stockListing of stockData.stockDataMap.values()) {
      stockListing.forEach(listing => {
        const finalStageProgress = listing.productDealLeg.finalStage.stageProgress;
        const compulsoryStageProgress = listing.productDealLeg.compulsorySaleStage.stageProgress;

        if (compulsoryStageProgress === "secondHalf") {
          approachingDeadlines += listing.productDealLeg.compulsorySaleStage.quantityAvailable.value;
        } else if (compulsoryStageProgress === "ending" || compulsoryStageProgress === "ended") {
          closeToDeadlines += listing.productDealLeg.compulsorySaleStage.quantityAvailable.value;
        }

        if (finalStageProgress === "secondHalf") {
          approachingDeadlines += listing.productDealLeg.finalStage.quantityAvailable.value;
        } else if (finalStageProgress === "ending" || finalStageProgress === "ended") {
          closeToDeadlines += listing.productDealLeg.finalStage.quantityAvailable.value;
        }

        total += listing.productDealLeg.totalQuantityAvailable.value;
      });
    }

    return {
      total,
      approachingDeadlines,
      closeToDeadlines,
    };
  }, [stockData]);

  //TODO(Berto): Revisit this logic would like to avoid this split calculation
  const getCaskQuantities = useCallback(() => {
    let total = 0;
    let approachingDeadlines = 0;
    let closeToDeadlines = 0;

    for (const stockListing of stockData.stockDataMap.values()) {
      stockListing.forEach(listing => {
        const finalStageProgress = listing.productDealLeg.finalStage.stageProgress;

        if (finalStageProgress === "secondHalf") {
          approachingDeadlines++;
        } else if (finalStageProgress === "ending" || finalStageProgress === "ended") {
          closeToDeadlines++;
        }

        listing.productDealLeg.totalQuantityAvailable.value > 0 && total++;
      });
    }

    return {
      // when we have paginated data, we need to use the total override since stockData will only contain the current page
      total: dashboardPagination ? stockData.totalRowCount : total,
      approachingDeadlines,
      closeToDeadlines,
    };
  }, [stockData, dashboardPagination]);

  const stockQuantities = useMemo(
    () => (stockType === "cask" ? getCaskQuantities() : getSinglesQuantities()),
    [getSinglesQuantities, getCaskQuantities, stockType],
  );

  // TODO(Zhi): when paginating data, the total number of stocks for casks is the number of rows,
  // we currently don't support server side pagination for singles.
  // We also do not have all the rows to derive the total number of stocks approaching deadlines
  // and close to deadlines. In the short term we hope the first 100 rows we load will be enough
  // to give a good indication of the total number of stocks approaching deadlines and close to
  // deadlines. This is ok because we know which orgs will have pagination enabled.
  // Next step should be removing the 'warning' and 'error' cards as they are superceded by the
  // expiring stock page.
  const stockCards: IconStockCardProps[] = useMemo(() => {
    const stockTypeLabel = getFormLabelForUnionField(snTopLevelUnitType, stockType);
    return [
      {
        state: "info",
        title: `Total ${stockTypeLabel}`,
        number: stockQuantities.total,
      },
      {
        state: "warning",
        title: `${stockTypeLabel} approaching repurchase deadlines`,
        number: stockQuantities.approachingDeadlines,
      },
      {
        state: "error",
        title: `${stockTypeLabel} very close to repurchase deadlines`,
        number: stockQuantities.closeToDeadlines,
      },
    ];
  }, [stockQuantities.approachingDeadlines, stockQuantities.closeToDeadlines, stockQuantities.total, stockType]);

  return (
    <Stack spacing={12} direction="row">
      {stockCards
        .filter(card => card.number > 0)
        .map(card => (
          <React.Fragment key={card.title}>
            <IconStockCard {...card} />
          </React.Fragment>
        ))}
    </Stack>
  );
};

interface StockListingTableProps {
  loadMoreStockDataMap: (offset: number) => Promise<Map<string, StockListing[]> | undefined>;
  initStockListings: StockDataDisplayInfo;
}

const StockListingTable: FC<StockListingTableProps> = ({ loadMoreStockDataMap, initStockListings }) => {
  const initDealLegs = useMemo(() => {
    return extractDealLegs(initStockListings.stockDataMap);
  }, [initStockListings]);

  const { fspValues, fetchFspForDealLegs } = useFspState(initDealLegs.map(dl => dl.dealLegWithDbId.id));
  const [stockDataMap, setStockDataMap] = useState(initStockListings.stockDataMap);

  // we need to build a map of fsp values for each product code, so that we can display the fsp range for
  // each product. This value is updated whenever we page in more data.
  const fspValuesMap = useMemo(() => {
    return buildFspValuesMap(stockDataMap, fspValues);
  }, [stockDataMap, fspValues]);

  /// We only display the quantity column for singles
  const displayQuantityColumn: boolean = useMemo(() => {
    return initDealLegs.some(dl => isSingles(dl.totalQuantityAvailable));
  }, [initDealLegs]);

  const tableRows = useMemo(() => makeStockRowData(initStockListings.stockDataMap), [initStockListings]);

  // this function is called by the PaginatedTable component when it needs more data to display.
  // we use it to fetch FSP values for the new deal legs that are being loaded.
  const loadMoreData = useCallback(
    async (offset: number) => {
      const newStockDataMap = await loadMoreStockDataMap(offset);

      if (newStockDataMap) {
        // calling `fetchFspForDealLegs` causes FspFetcher in useFspState() to immediately return
        // an updated fspValues state with entries for each new deal leg in 'loading' state.
        const dealLegIds = extractDealLegs(newStockDataMap).map(dl => dl.dealLegWithDbId.id);
        fetchFspForDealLegs(dealLegIds);
        // update the stockDataMap with the new data.
        setStockDataMap(new Map([...stockDataMap, ...newStockDataMap]));
        // the combined effect will trigger a new `fspValuesMap` to be created with the new data.
      }
      return newStockDataMap && makeStockRowData(newStockDataMap);
    },
    [loadMoreStockDataMap, fetchFspForDealLegs, stockDataMap],
  );

  return (
    <PaginatedTable
      initialRows={tableRows}
      loadMoreData={loadMoreData}
      totalRowCount={initStockListings.totalRowCount}
      HeaderContent={
        <TableRow>
          <TableCell>Product</TableCell>
          <TableCell align="right">Due Date</TableCell>
          {displayQuantityColumn && <TableCell align="right">Quantity</TableCell>}
          <TableCell align="right">Forward Sale Price</TableCell>
          <TableCell align="right">Deposit Paid</TableCell>
        </TableRow>
      }
      BodyContent={<StockListingTableBody rows={tableRows} fspValuesMap={fspValuesMap} />}
    />
  );
};

function extractDealLegs(stockDataMap: Map<string, StockListing[]>) {
  return [...stockDataMap.values()].flatMap(sls => sls.map(sl => sl.productDealLeg));
}

function buildFspValuesMap(stockDataMap: Map<string, StockListing[]>, fspValues: LoadedFspValues) {
  // map by product code
  const result: Record<string, LoadedFspValues> = {};

  for (const entry of stockDataMap.entries()) {
    const productFspValues: LoadedFspValues = {};
    const code = entry[0];
    const stockListing = entry[1];
    stockListing.forEach(
      listing =>
        (productFspValues[listing.productDealLeg.dealLegWithDbId.id] =
          fspValues[listing.productDealLeg.dealLegWithDbId.id]),
    );

    result[code] = productFspValues;
  }
  return result;
}

function makeStockRowData(stockListings: Map<string, StockListing[]>) {
  return [...stockListings.keys()].map(code => {
    const listings = assertNotUndefined(stockListings.get(code));
    return { code, listings };
  });
}

interface StockListingTableBodyProps extends TableBodyProps {
  rows: { code: string; listings: StockListing[] }[];
  fspValuesMap: Record<string, LoadedFspValues>;
}

const StockListingTableBody = ({ rows, fspValuesMap }: StockListingTableBodyProps) => {
  return (
    <>
      {rows.map(row => {
        const listings = assertNotUndefined(row.listings);
        const product = listings[0].product;
        const fspValues = fspValuesMap[row.code] ?? { state: "loading" };
        return (
          fspValues && (
            <ProductRow
              key={row.code}
              fspValues={fspValues}
              productWithId={product}
              productDealLegs={listings.map(sl => sl.productDealLeg)}
            />
          )
        );
      })}
    </>
  );
};

interface ProductRowProps {
  fspValues: LoadedFspValues;
  productWithId: WithDbId<Product>;
  productDealLegs: ProductDealLeg[];
}
const ProductRow = ({ fspValues, productWithId, productDealLegs }: ProductRowProps) => {
  //TODO(Berto): Should come from the API as NumberOfUnits
  const totalQuantityAvailable: NumberOfUnits = useMemo(
    () =>
      productDealLegs.reduce((partialSum, dl) => add(partialSum, dl.totalQuantityAvailable), {
        kind: productDealLegs[0].totalQuantityAvailable.kind,
        value: 0,
      }),
    [productDealLegs],
  );

  const fspTransformation = (fsp: number) => fsp * totalQuantityAvailable.value;
  const deposits = useMemo(() => {
    const deposits = productDealLegs.map(dl => {
      const deposit = _.toNumber(dl.dealLegWithDbId.value.depositPricePerUnit);
      return isSingles(dl.dealLegWithDbId.value.numberOfUnits)
        ? deposit
        : deposit * dl.dealLegWithDbId.value.numberOfUnits.value;
    });
    return deposits;
  }, [productDealLegs]);
  const currency = useSettlementCurrency();
  return (
    <TableRow>
      <TableCell>
        <OrganisationProductSummary
          {...productWithId.value}
          productId={productWithId.id}
          vesselCapacity={getVesselCapacity(productWithId.value.vesselSize, totalQuantityAvailable)}
        />
      </TableCell>
      <TableCell align="right">
        <Stack direction="row" justifyContent="flex-end">
          <RepurchaseDateRange
            repurchaseStages={productDealLegs.flatMap(dl => [dl.compulsorySaleStage, dl.finalStage])}
          />
        </Stack>
      </TableCell>
      {isSingles(totalQuantityAvailable) && <TableCell align="right">{totalQuantityAvailable.value}</TableCell>}
      <TableCell align="right">
        <FspRange<FSP>
          values={fspValues}
          transformation={!isSingles(totalQuantityAvailable) ? fspTransformation : undefined}
          currency={currency}
          accessor={(fsp: FSP) => Number(fsp.roundedFsp)}
        />
      </TableCell>
      <TableCell align="right">
        <CurrencyRange currency={currency} values={deposits} />
      </TableCell>
    </TableRow>
  );
};
