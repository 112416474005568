import { useMemo } from "react";
import { addDays, addMonths, endOfMonth, set } from "date-fns";
import { assertNever } from "utils/hx/util/types";
import { PurchaserPurchaseRequestData } from "../../../../hooks/use-purchase-request";
import { now } from "utils/date-utils";

export const useIsPaymentOverdue = (
  purchaseRequestData: Pick<PurchaserPurchaseRequestData, "paymentTermsPeriod" | "stateEvents">,
) => {
  return useMemo(() => {
    const { state, time } = purchaseRequestData.stateEvents[purchaseRequestData.stateEvents.length - 1];
    if (state !== "purchaserInvoiced") {
      return false;
    }
    const paymentTermsPeriod = purchaseRequestData.paymentTermsPeriod;
    let overdueDate: Date;
    if (paymentTermsPeriod.kind === "daysAfterCollection") {
      overdueDate = addDays(time, paymentTermsPeriod.value);
    } else if (paymentTermsPeriod.kind === "endOfMonth") {
      overdueDate = endOfMonth(addMonths(time, paymentTermsPeriod.value + 1));
    } else {
      assertNever(paymentTermsPeriod);
    }

    // The payment becomes overdue paymentTermsDays days later at 23:59:59
    const overdueInstant = set(overdueDate, {
      hours: 23,
      minutes: 59,
      seconds: 59,
    }).getTime();

    return state === "purchaserInvoiced" && now() > overdueInstant;
  }, [purchaseRequestData.paymentTermsPeriod, purchaseRequestData.stateEvents]);
};
