import React, { useCallback, useMemo } from "react";
import { useFormik } from "formik";
import { AccessTime } from "@mui/icons-material";
import { Divider, Stack, Typography } from "@mui/material";
import { addDays } from "date-fns";
import { formatLocalDate, formatDateToLocalDate } from "utils/date-utils";
import { ConfirmationDialog } from "components/widgets/confirmation-dialog/confirmation-dialog";
import { PurchaserStorageLocationView } from "adl-gen/ferovinum/app/api";
import { countryCodeToCountryName } from "utils/conversion-utils";
import { AddressTypographyCompressed } from "components/library/widgets/address/address-typography-compressed";
import { InputUiBuilder } from "components/library/helpers/input-ui-builder";
import { CollectionDetails } from "adl-gen/ferovinum/app/api";
import { makeObjectDef, makeObjectSchema } from "utils/data-field/object-field-def";
import { formatDateToLocalTime } from "utils/date-utils";
import { stringField, localDateField, localTimeField } from "utils/data-field/data-field-builder";
import { NullToUndef, mapUndefToNull } from "utils/null-and-undefined-utils";

export interface AcceptPurchaseRequestCollectionDialogProps {
  open: boolean;
  collectionDays: number;
  stockPreparationDays: number;
  storageLocation: PurchaserStorageLocationView;
  onAccept: (collectionDetails: CollectionDetails | null) => Promise<void>;
  onCancel: () => void;
}

type CollectionDetailsUiData = NullToUndef<CollectionDetails>;

const TODAY = new Date();
export const AcceptPurchaseRequestCollectionDialog = ({
  open,
  collectionDays,
  stockPreparationDays,
  storageLocation,
  onAccept,
  onCancel,
}: AcceptPurchaseRequestCollectionDialogProps) => {
  const maxCollectionDate = formatDateToLocalDate(addDays(TODAY, stockPreparationDays + collectionDays));
  const minCollectionDate = formatDateToLocalDate(addDays(TODAY, stockPreparationDays));

  const collectionDetailsDef = makeObjectDef<CollectionDetailsUiData>({
    collectionDate: localDateField("Collection Date", {
      range: { min: minCollectionDate, max: maxCollectionDate },
    }).required(),
    collectionTime: localTimeField("Collection Time").required(),
    collectionName: stringField("Collection Name").required(),
    vehicleRegistration: stringField("Vehicle Registration").optional(),
    contactName: stringField("Contact Name").optional(),
    contactNumber: stringField("Contact Number").optional(),
  });

  const acceptPurchaseRequestValidationSchema = makeObjectSchema(collectionDetailsDef);

  const onSubmit = useCallback(
    async (collectionDetails: CollectionDetailsUiData) => {
      return await onAccept(mapUndefToNull(collectionDetails));
    },
    [onAccept],
  );

  const form = useFormik<CollectionDetailsUiData>({
    initialValues: {
      collectionName: "",
      collectionDate: minCollectionDate,
      collectionTime: formatDateToLocalTime(TODAY),
      vehicleRegistration: undefined,
      contactName: undefined,
      contactNumber: undefined,
    },
    validationSchema: acceptPurchaseRequestValidationSchema,
    validateOnMount: true,
    onSubmit,
  });

  const uiBuilder = useMemo(() => {
    return new InputUiBuilder<CollectionDetailsUiData>(collectionDetailsDef, form);
  }, [collectionDetailsDef, form]);

  const clearForm = useCallback(() => {
    form.resetForm();
  }, [form]);

  const onClickConfirm = useCallback(async () => {
    await form.submitForm();
    clearForm();
  }, [clearForm, form]);

  return (
    <ConfirmationDialog
      title="Accept purchase request"
      open={open}
      maxWidth="xs"
      fullWidth
      acceptAction={{
        title: "Confirm",
        disabled: !form.isValid,
        onClick: onClickConfirm,
      }}
      cancelAction={{ title: "Back", onClick: onCancel }}>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography color="common.grey6" variant="subtitle1Bold">
            Collection Address:
          </Typography>
          <AddressTypographyCompressed
            {...storageLocation}
            country={storageLocation.country && countryCodeToCountryName(storageLocation.country)}
          />
        </Stack>
        <Divider />
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <AccessTime sx={{ color: "common.grey5" }} />
          <Stack spacing={1}>
            <Typography variant={"body2"}>Earliest collection date: {formatLocalDate(minCollectionDate)}</Typography>
            <Typography variant={"body2"}>Latest collection date: {formatLocalDate(maxCollectionDate)}</Typography>
          </Stack>
        </Stack>
        {uiBuilder.createTextField("collectionName")}
        {uiBuilder.createLocalDateField("collectionDate")}
        {uiBuilder.createLocalTimeField("collectionTime")}
        {uiBuilder.createTextField("vehicleRegistration")}
        {uiBuilder.createTextField("contactName")}
        {uiBuilder.createTextField("contactNumber")}
      </Stack>
    </ConfirmationDialog>
  );
};
