import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Box,
  Button,
  ButtonProps,
  Divider,
  Fab,
  FabProps,
  Stack,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DbKey, WithDbId } from "adl-gen/common/db";
import { ProductDealLeg, ProductListing } from "adl-gen/ferovinum/app/api";
import { ProductSaleOrder } from "adl-gen/ferovinum/app/productsaleorder";
import { Currency, DealLeg, NumberOfUnits, Product, StorageLocation, TopLevelUnitType } from "adl-gen/ferovinum/app/db";
import { useConfirmationDialog } from "components/context/global-dialog/use-dialog";
import { ConfirmationDialog } from "components/widgets/confirmation-dialog/confirmation-dialog";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import { Dropdown } from "components/widgets/dropdown/dropdown";
import { SearchInput } from "components/widgets/inputs/search-input/search-input";
import { UnitsInput } from "components/widgets/inputs/units-input/units-input";
import { Link } from "components/widgets/link/link";
import { Loader } from "components/widgets/loader/loader";
import { FormikProps, useFormik } from "formik";
import _ from "lodash";
import { ParseResult } from "papaparse";
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useCSVReader } from "react-papaparse";
import { assertNotUndefined } from "utils/hx/util/types";
import { add, getVesselCapacity, isCase, isSinglesUnitType, isTank, numberOfUnitsForUnitType } from "utils/model-utils";
import { LoadingValue, isLoaded } from "utils/utility-types";
import { array, lazy, number, object } from "yup";
import { LoadedFspValues, useFspState } from "../../../../../hooks/use-fsp-state";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { useSettlementCurrency } from "../../../../layouts/portal-page-layout/portal-page";
import { ClientSidePaginatedTable } from "../../../../widgets/common/client-side-paginated-table/client-side-paginated-table";
import { RepurchaseFlowStepper } from "../../../../widgets/flow-stepper/repurchase-flow-stepper";
import { FspRange } from "../../../../widgets/fsp-range/fsp-range";
import { OrganisationProductSummary } from "../../../../widgets/organisation-product-summary/organisation-product-summary";
import {
  RepurchaseDate,
  RepurchaseDateRange,
  RepurchaseStage,
} from "../../../../widgets/repurchase-date-range/repurchase-date-range";
import { StockTypeToggle } from "../../../../widgets/stock-type-toggle/stock-type-toggle";
import { RepurchaseFlowState } from "../organisation-repurchase-delivery/organisation-repurchase-delivery-page";
import { DealLegRepurchaseStage } from "./organisation-repurchase-stock-page";

export interface OrganisationRepurchaseStockPageViewProps {
  activeStorageLocation?: { loc: WithDbId<StorageLocation>; availableStockTypes: Set<TopLevelUnitType> };
  storageLocations: WithDbId<StorageLocation>[];
  stockInActiveStorageLocation: Map<TopLevelUnitType, ProductListing[]>;
  getBottleRepurchaseOrderStages(productId: DbKey<Product>): Promise<DealLegRepurchaseStage[]>;
  onChangeActiveStorageLocation(loc: WithDbId<StorageLocation>): void;
  onSubmit(topLevelUnitType: TopLevelUnitType, productsSaleOrders: ProductSaleOrder[]): void;
  onProductSearch(searchTerm: string): void;
  adminView: boolean;
  initialFlowState?: RepurchaseFlowState;
}

interface SelectedProductSale {
  product: WithDbId<Product>;
  unitsOrdered: number;
  totalUnitsAvailable: NumberOfUnits;
}
interface RepurchaseFormValues {
  selectedProducts: SelectedProductSale[];
}

type NetPayableValues = Record<DbKey<DealLeg>, LoadingValue<number>>;

const productSchema = lazy((selectedProduct: SelectedProductSale) => {
  return object().shape({
    product: object().required(),
    unitsOrdered: number().min(0).max(selectedProduct.totalUnitsAvailable.value).positive().required(),
  });
});
const validationSchema = object().shape({
  selectedProducts: array()
    // Note: (typescript bug): https://github.com/jquense/yup/issues/1283
    // @ts-ignore
    .of(productSchema)
    .min(1)
    .required(),
});

const getInitialFormValues = (
  stockInActiveStorageLocation: Map<TopLevelUnitType, ProductListing[]>,
  initialFlowState?: RepurchaseFlowState,
): RepurchaseFormValues => {
  if (!initialFlowState) {
    return { selectedProducts: [] };
  }

  const type = initialFlowState.repurchaseRequest.type;
  if (!stockInActiveStorageLocation.has(type)) {
    return { selectedProducts: [] };
  }
  const productListings = assertNotUndefined(stockInActiveStorageLocation.get(type));
  const selectedProducts: SelectedProductSale[] = [];
  for (const selectedProduct of initialFlowState.repurchaseRequest.productsSaleOrders) {
    const productListing = productListings.find(
      productListing => productListing.productWithId.id === selectedProduct.productId,
    );
    if (productListing) {
      // TODO (Berto): Update the stock listing endpoints to not return deal legs and instead return total qty available and
      // the deadline date ranges as needed by the UI
      const totalUnitsAvailable = numberOfUnitsForUnitType(
        productListing.productWithId.value.unitType,
        calcTotalQuantity(productListing.dealLegs),
      );
      selectedProducts.push({
        product: productListing.productWithId,
        unitsOrdered: selectedProduct.unitsOrdered.value,
        totalUnitsAvailable,
      });
    }
  }
  return { selectedProducts };
};

export const OrganisationRepurchaseStockPageView = ({
  activeStorageLocation,
  storageLocations,
  stockInActiveStorageLocation,
  onChangeActiveStorageLocation,
  getBottleRepurchaseOrderStages,
  onSubmit,
  onProductSearch,
  initialFlowState,
}: OrganisationRepurchaseStockPageViewProps) => {
  const { showConfirmationDialog } = useConfirmationDialog();
  const [stockType, setStockType] = useState<TopLevelUnitType | undefined>(initialFlowState?.repurchaseRequest.type);

  // Using a useEffect for change the stock type as switching the active storage location may lead to a location that
  // only has products of a different stock type.
  useEffect(() => {
    setStockType(currentStockType => {
      if (activeStorageLocation) {
        const hasCurrent = currentStockType ? activeStorageLocation.availableStockTypes.has(currentStockType) : false;
        if (hasCurrent) {
          return currentStockType;
        } else {
          return activeStorageLocation.availableStockTypes.size > 0
            ? [...activeStorageLocation.availableStockTypes][0]
            : undefined;
        }
      } else {
        return undefined;
      }
    });
  }, [activeStorageLocation, setStockType]);
  const onSubmitForm = (values: RepurchaseFormValues) => {
    const productSaleOrders: ProductSaleOrder[] = values.selectedProducts
      .filter(sp => sp.unitsOrdered > 0)
      .map(sp => ({
        productId: sp.product.id,
        unitsOrdered: numberOfUnitsForUnitType(sp.product.value.unitType, sp.unitsOrdered),
      }));
    onSubmit(assertNotUndefined(stockType), productSaleOrders);
  };
  const initialValues = useMemo(
    () => getInitialFormValues(stockInActiveStorageLocation, initialFlowState),
    [stockInActiveStorageLocation, initialFlowState],
  );
  const form = useFormik<RepurchaseFormValues>({
    initialValues,
    validationSchema,
    onSubmit: onSubmitForm,
    validateOnMount: true,
  });
  const onAddSelectedProduct = useCallback(
    (product: WithDbId<Product>, unitsOrdered: number, totalUnitsAvailable: number) => {
      if (form.values.selectedProducts.findIndex(p => p.product.id === product.id) === -1) {
        const selectedProduct: SelectedProductSale = {
          product,
          unitsOrdered,
          totalUnitsAvailable: numberOfUnitsForUnitType(product.value.unitType, totalUnitsAvailable),
        };
        form.setFieldValue("selectedProducts", [...form.values.selectedProducts, selectedProduct]);
      }
    },
    [form],
  );
  const resetSelectedProducts = useCallback(async () => form.setFieldValue("selectedProducts", []), [form]);
  const onChangeLocation = useCallback(
    async (loc?: WithDbId<StorageLocation>) => {
      if (form.values.selectedProducts.length === 0 && loc) {
        onChangeActiveStorageLocation(loc);
        return;
      }

      const userResponse = await showConfirmationDialog({
        title: "Are you sure you want to change the storage location?",
        body: "You will lose your current product selections.",
      });
      if (userResponse.kind === "ok" && loc) {
        await resetSelectedProducts();
        onChangeActiveStorageLocation(loc);
      }
    },
    [form.values.selectedProducts.length, onChangeActiveStorageLocation, resetSelectedProducts, showConfirmationDialog],
  );
  const onChangeStockType = useCallback(
    async (stockType?: TopLevelUnitType) => {
      if (form.values.selectedProducts.length === 0 && stockType) {
        setStockType(stockType);
        return;
      }

      const userResponse = await showConfirmationDialog({
        title: "Are you sure you want to change the unit type?",
        body: "You will lose your current product selections.",
      });
      if (userResponse.kind === "ok") {
        await resetSelectedProducts();
        setStockType(stockType);
      }
    },
    [form.values.selectedProducts.length, resetSelectedProducts, showConfirmationDialog],
  );

  const noStock = useMemo(
    () => (activeStorageLocation ? activeStorageLocation.availableStockTypes.size === 0 : true),
    [activeStorageLocation],
  );

  const nonSelectedStock: Map<TopLevelUnitType, ProductListing[]> = useMemo(() => {
    const selectedProductIds = new Set(form.values.selectedProducts.map(p => p.product.id));
    if (activeStorageLocation) {
      const result = new Map<TopLevelUnitType, ProductListing[]>();
      for (const topLevelUnitType of stockInActiveStorageLocation.keys()) {
        result.set(topLevelUnitType, [
          ...assertNotUndefined(stockInActiveStorageLocation.get(topLevelUnitType)).filter(
            p => !selectedProductIds.has(p.productWithId.id),
          ),
        ]);
      }
      return result;
    }
    return new Map();
  }, [form.values.selectedProducts, activeStorageLocation, stockInActiveStorageLocation]);

  const settlementCurrency = useSettlementCurrency();
  const hasMoreThanOneStockType: boolean = useMemo(
    () => (activeStorageLocation?.availableStockTypes.size ?? 0) > 1,
    [activeStorageLocation?.availableStockTypes.size],
  );

  const bulkAddProducts = useCallback(
    (entries: { code: string; quantity?: number }[]) => {
      const productLookup: Record<string, ProductListing> = {};
      Array.from(nonSelectedStock.keys())
        .flatMap(stockType => nonSelectedStock.get(stockType) ?? [])
        .forEach(productListing => {
          productLookup[productListing.productWithId.value.code] = productListing;
        });

      const newSelectedProducts: SelectedProductSale[] = [];

      for (const { code, quantity } of entries) {
        if (productLookup[code]) {
          const { productWithId: product, dealLegs } = productLookup[code];
          const totalQuantity = calcTotalQuantity(dealLegs);
          if (form.values.selectedProducts.findIndex(p => p.product.id === product.id) === -1) {
            const selectQty = quantity != undefined ? Math.min(quantity, totalQuantity) : totalQuantity;
            if (selectQty > 0) {
              const totalUnitsAvailable = numberOfUnitsForUnitType(product.value.unitType, totalQuantity);
              newSelectedProducts.push({ product, unitsOrdered: selectQty, totalUnitsAvailable });
            }
          }
        }
      }

      if (newSelectedProducts.length > 0) {
        form.setFieldValue("selectedProducts", [...form.values.selectedProducts, ...newSelectedProducts]);
      }
    },
    [form, nonSelectedStock],
  );

  const isSingles = isSinglesUnitType(stockType) || isCase(stockType) || isTank(stockType);

  return (
    <PortalPageContent
      header={<OrganisationRepurchaseStockHeader />}
      sidePanel={
        noStock ? undefined : <OrganisationRepurchaseSideCart bulkAddProducts={bulkAddProducts} form={form} />
      }>
      <Stack spacing={2}>
        {noStock ? (
          <Alert severity="info">You currently have no available products held on the platform.</Alert>
        ) : (
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                {hasMoreThanOneStockType && (
                  <StockTypeToggle
                    topLevelUnitType={stockType}
                    onChange={onChangeStockType}
                    availableTypes={activeStorageLocation?.availableStockTypes ?? new Set()}
                  />
                )}
              </Box>
              <Stack spacing={2} direction="row" alignItems="center">
                {storageLocations.length > 0 && activeStorageLocation && (
                  <Dropdown
                    inputLabel="Storage location"
                    defaultValue={{
                      label: activeStorageLocation?.loc.value.locationName,
                      value: activeStorageLocation?.loc,
                    }}
                    menuItems={storageLocations.map(loc => ({ label: loc.value.locationName, value: loc }))}
                    onChange={onChangeLocation}
                  />
                )}
                <SearchInput label="Product search" onChange={onProductSearch} />
              </Stack>
            </Stack>
            {stockType && isSingles && (
              <SinglesListingTable
                onAddRepurchaseProduct={onAddSelectedProduct}
                disabled={false}
                productListings={nonSelectedStock.get(stockType) || []}
                getSinglesRepurchaseOrderStages={getBottleRepurchaseOrderStages}
                settlementCurrency={settlementCurrency}
              />
            )}
            {stockType && !isSingles && (
              <NonSinglesListingTable
                onAddRepurchaseProduct={onAddSelectedProduct}
                disabled={false}
                productListings={nonSelectedStock.get(stockType) || []}
                settlementCurrency={settlementCurrency}
              />
            )}
          </>
        )}
      </Stack>
    </PortalPageContent>
  );
};

function CsvReaderButton({
  onCsvLoaded,
  children,
}: PropsWithChildren<{
  onCsvLoaded(parseResult: ParseResult<string[]>): Promise<void>;
}>) {
  const [loading, setLoading] = useState<boolean>(false);
  const { CSVReader } = useCSVReader();

  const handleUpload = useCallback(
    async (parseResult: ParseResult<string[]>) => {
      setLoading(true);
      await onCsvLoaded(parseResult);
      setLoading(false);
    },
    [onCsvLoaded],
  );

  return (
    <Stack spacing={2}>
      <CSVReader
        onUploadAccepted={handleUpload}
        config={{
          skipEmptyLines: "greedy",
        }}>
        {({ getRootProps }: { getRootProps: () => ButtonProps }) => (
          <Button {...getRootProps()} startIcon={<AddIcon />}>
            {children}
          </Button>
        )}
      </CSVReader>
      <Link
        variant="big"
        color="black"
        onClick={() =>
          downloadTextFile({
            fileName: "repurchase-stock-template.csv",
            content: "Product Code, Quantity (Optional)\nXBW2913,117,",
          })
        }
        download>
        Download CSV template
      </Link>
      <ConfirmationDialog
        open={loading}
        maxWidth="md"
        fullWidth
        title=""
        acceptAction={{
          title: "Got it",
          onClick: async () => {
            return;
          },
        }}>
        <Loader loadingStates={[{ state: "loading" }]} />
      </ConfirmationDialog>
    </Stack>
  );
}

function downloadTextFile({ fileName, content }: { fileName: string; content: string }) {
  const blob = new Blob([content], { type: "text/plain" }); // Create a Blob with the file content
  const url = URL.createObjectURL(blob); // Create a URL for the Blob
  const a = document.createElement("a"); // Create an <a> element
  a.href = url; // Set the href attribute of the <a> element to the Blob URL
  a.download = fileName; // Set the download attribute of the <a> element
  document.body.appendChild(a); // Append the <a> element to the document body
  a.click(); // Simulate a click on the <a> element
  document.body.removeChild(a); // Remove the <a> element from the document body
  URL.revokeObjectURL(url); // Revoke the Blob URL
}

const OrganisationRepurchaseStockHeader = () => {
  return (
    <PortalPageContentHeader
      variant="split"
      title="Repurchase Stock"
      right={<RepurchaseFlowStepper activeStep={0} />}
    />
  );
};

interface OrganisationRepurchaseSideCartProps {
  bulkAddProducts(entries: { code: string; quantity?: number }[]): void;
  form: FormikProps<RepurchaseFormValues>;
}

const OrganisationRepurchaseSideCart = ({ bulkAddProducts, form }: OrganisationRepurchaseSideCartProps) => {
  const onRemoveSelectedProduct = useCallback(
    (productId: string) => {
      const index = form.values.selectedProducts.findIndex(p => p.product.id === productId);
      if (index > -1) {
        const newSelectedProducts = [...form.values.selectedProducts];
        newSelectedProducts.splice(index, 1);
        form.setFieldValue("selectedProducts", newSelectedProducts);
      }
    },
    [form],
  );
  const onUpdateSelectedProduct = useCallback(
    (productId: string, unitsOrdered: number) => {
      const index = form.values.selectedProducts.findIndex(p => p.product.id === productId);
      if (index > -1) {
        const newSelectedProducts = [...form.values.selectedProducts];
        const oldProduct = form.values.selectedProducts[index];
        newSelectedProducts.splice(index, 1, {
          ...oldProduct,
          unitsOrdered,
        });
        form.setFieldValue("selectedProducts", newSelectedProducts);
      }
    },
    [form],
  );
  return (
    <Stack spacing={2} divider={<Divider flexItem />} paddingBottom={2}>
      <Typography variant="caption" color="common.grey6">
        Selected products
      </Typography>
      {form.values.selectedProducts.length === 0 && (
        <Stack spacing={2}>
          <Typography variant="caption" color="common.grey7">
            You have not selected any products.
          </Typography>
          <CsvReaderButton
            onCsvLoaded={async csvResult => {
              const entries: { code: string; quantity?: number }[] = csvResult.data
                .slice(1)
                .map(row => {
                  const [codeRaw, qtyRaw] = row;
                  return { code: codeRaw?.trim() ?? "", quantity: qtyRaw ? Number(qtyRaw) : undefined };
                })
                .filter(e => !!e.code);
              bulkAddProducts(entries);
            }}>
            Add by CSV
          </CsvReaderButton>
        </Stack>
      )}
      {form.values.selectedProducts.map(p => {
        const showNumberOfSingles = isSinglesUnitType(p.product.value.unitType);
        return (
          <Stack spacing={2} key={p.product.id}>
            <OrganisationProductSummary
              {...p.product.value}
              vesselCapacity={getVesselCapacity(p.product.value.vesselSize, p.totalUnitsAvailable)}
            />
            {showNumberOfSingles && (
              <UnitsInput
                unitType={p.product.value.unitType}
                label="Qty of units"
                size="small"
                variant="outlined"
                value={p.unitsOrdered || ""}
                onChange={e => {
                  const n = Number(e.target.value);
                  if (n <= p.totalUnitsAvailable.value) {
                    onUpdateSelectedProduct(p.product.id, n);
                  }
                }}
              />
            )}
            <Link onClick={() => onRemoveSelectedProduct(p.product.id)}>Remove</Link>
          </Stack>
        );
      })}
      <Stack
        alignItems="flex-end"
        position="fixed"
        bottom={theme => theme.spacing(2)}
        right={theme => theme.spacing(2)}>
        <Button onClick={form.submitForm} disabled={!form.isValid}>
          Next
        </Button>
      </Stack>
    </Stack>
  );
};

interface SinglesListingTableProps {
  onAddRepurchaseProduct: (product: WithDbId<Product>, unitsOrdered: number, totalAvailable: number) => void;
  productListings: ProductListing[];
  getSinglesRepurchaseOrderStages(productId: DbKey<Product>): Promise<DealLegRepurchaseStage[]>;
  disabled: boolean;
  settlementCurrency: Currency;
}

const SinglesListingTable = React.memo(function SinglesListingTable({
  onAddRepurchaseProduct,
  productListings,
  getSinglesRepurchaseOrderStages,
  disabled,
  settlementCurrency,
}: SinglesListingTableProps) {
  const dealLegIds = useMemo<DbKey<DealLeg>[]>(
    () => productListings.flatMap(pl => pl.dealLegs.map(dl => dl.dealLegWithDbId.id)),
    [productListings],
  );
  const { fspValues } = useFspState(dealLegIds);
  const netPayableValues = useMemo(() => calculateNetPayable(productListings, fspValues), [fspValues, productListings]);
  return (
    <ClientSidePaginatedTable
      Header={
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Due date</TableCell>
            <TableCell align="right">Net Payable (per unit)</TableCell>
            <TableCell align="right">Available</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
      }
      Body={
        <SinglesListingTableBody
          rows={productListings}
          onAddRepurchaseProduct={onAddRepurchaseProduct}
          disabled={disabled}
          getDealLegRepurchaseOrderStages={getSinglesRepurchaseOrderStages}
          netPayableValues={netPayableValues}
          settlementCurrency={settlementCurrency}
        />
      }
      rows={productListings}
    />
  );
});

type SinglesListingTableBodyProps = {
  rows: ProductListing[];
  netPayableValues: NetPayableValues;
  onAddRepurchaseProduct: (product: WithDbId<Product>, unitsOrdered: number, totalAvailable: number) => void;
  getDealLegRepurchaseOrderStages(productId: DbKey<Product>): Promise<DealLegRepurchaseStage[]>;
  disabled: boolean;
  settlementCurrency: Currency;
};
const SinglesListingTableBody = ({
  rows,
  disabled,
  onAddRepurchaseProduct,
  getDealLegRepurchaseOrderStages,
  netPayableValues,
  settlementCurrency,
}: SinglesListingTableBodyProps) => {
  const getProductNetPayableValues = useCallback(
    (productListing: ProductListing) =>
      productListing.dealLegs.reduce<NetPayableValues>((m, dl) => {
        m[dl.dealLegWithDbId.id] = netPayableValues[dl.dealLegWithDbId.id];
        return m;
      }, {}),
    [netPayableValues],
  );

  return (
    <TableBody>
      {rows.length === 0 && <NoResultsRow colSpan={6} />}
      {rows.map(productListing => {
        const productNetPayableValues = getProductNetPayableValues(productListing);
        return (
          <SinglesProductRow
            key={productListing.productWithId.id}
            disabled={disabled}
            onAddRepurchaseProduct={(unitsOrdered, totalAvailable) => {
              onAddRepurchaseProduct(productListing.productWithId, unitsOrdered, totalAvailable);
            }}
            getBottleRepurchaseOrderStages={getDealLegRepurchaseOrderStages}
            productListing={productListing}
            netPayableValues={productNetPayableValues}
            settlementCurrency={settlementCurrency}
          />
        );
      })}
    </TableBody>
  );
};

const ClickableRow = styled(TableRow, { shouldForwardProp: prop => prop !== "expandable" && prop !== "open" })<{
  open: boolean;
  expandable?: boolean;
}>(({ expandable }) => ({
  ...(expandable && {
    cursor: "pointer",
  }),
}));

interface BottlesProductRowProps {
  productListing: ProductListing;
  getBottleRepurchaseOrderStages(productId: DbKey<Product>): Promise<DealLegRepurchaseStage[]>;
  disabled: boolean;
  onAddRepurchaseProduct(unitsOrdered: number, totalAvailable: number): void;
  netPayableValues: NetPayableValues;
  settlementCurrency: Currency;
}

type DealLegRepurchaseStageWithNetPayable = DealLegRepurchaseStage & {
  netPayable: number | string;
};
const SinglesProductRow = ({
  productListing,
  disabled,
  onAddRepurchaseProduct,
  getBottleRepurchaseOrderStages,
  netPayableValues,
  settlementCurrency,
}: BottlesProductRowProps) => {
  const [unitsOrdered, setUnitsOrdered] = useState(0);
  const [opened, setOpened] = useState(false);
  const [repurchaseStages, setRepurchaseStages] = useState<DealLegRepurchaseStageWithNetPayable[]>([]);

  const hasValidNetPayables = useMemo(() => {
    return Object.values(netPayableValues).every(npv => npv.state === "success");
  }, [netPayableValues]);

  const toggle = useCallback(async () => {
    if (hasValidNetPayables && repurchaseStages.length === 0) {
      const resp = await getBottleRepurchaseOrderStages(productListing.productWithId.id);
      const repurchaseStages: DealLegRepurchaseStageWithNetPayable[] = resp.map(s => {
        const netPayable = netPayableValues[s.dealLegId];
        return {
          ...s,
          // this will only ever be success as we have already checked for valid net payables
          netPayable: netPayable.state === "success" ? netPayable.value : 0,
        };
      });
      // merges into a single stage those stages with the same deadline and same price
      const stagesByDeadlineAndPrice: Map<string, DealLegRepurchaseStageWithNetPayable> = new Map();
      repurchaseStages.forEach(stage => {
        const key = `${stage.repurchaseByDate}-${stage.netPayable}`;
        if (stagesByDeadlineAndPrice.has(key)) {
          const mergedStage: DealLegRepurchaseStageWithNetPayable = {
            ...stage,
            unitsRemaining: add(
              assertNotUndefined(stagesByDeadlineAndPrice.get(key)).unitsRemaining,
              stage.unitsRemaining,
            ),
          };
          stagesByDeadlineAndPrice.set(key, mergedStage);
        } else {
          stagesByDeadlineAndPrice.set(key, stage);
        }
      });
      const mergedRepurchaseStages = [...stagesByDeadlineAndPrice.values()];
      setRepurchaseStages(mergedRepurchaseStages);
      if (mergedRepurchaseStages.length > 1) {
        setOpened(true);
      }
    }
    if (hasValidNetPayables && repurchaseStages.length > 1) {
      setOpened(!opened);
    }
  }, [
    hasValidNetPayables,
    repurchaseStages,
    getBottleRepurchaseOrderStages,
    productListing.productWithId.id,
    opened,
    netPayableValues,
  ]);

  const product = productListing.productWithId.value;

  const inputDisabled = useMemo<boolean>(() => {
    return disabled || !hasValidNetPayables;
  }, [disabled, hasValidNetPayables]);

  // TODO (Berto): Update the stock listing endpoints to not return deal legs and instead return total qty available and
  // the deadline date ranges as needed by the UI
  const totalQuantity = useMemo(() => calcTotalQuantity(productListing.dealLegs), [productListing.dealLegs]);
  const repurchaseStagesFromDealLegs = useMemo(
    () => productListing.dealLegs.flatMap(stagesFromDealLeg),
    [productListing.dealLegs],
  );

  const expandable = hasValidNetPayables && repurchaseStagesFromDealLegs.length > 1;

  return (
    <>
      <ClickableRow
        onClick={expandable ? () => toggle() : undefined}
        expandable={expandable}
        open={opened}
        hover
        sx={{ ...(unitsOrdered && { backgroundColor: "common.grey3" }) }}>
        <TableCell>
          <OrganisationProductSummary
            {...product}
            vesselCapacity={getVesselCapacity(
              product.vesselSize,
              numberOfUnitsForUnitType(product.unitType, totalQuantity),
            )}
          />
        </TableCell>
        <TableCell>
          <RepurchaseDateRange repurchaseStages={repurchaseStagesFromDealLegs} />
        </TableCell>
        <TableCell align="right">
          <FspRange<number> values={netPayableValues} currency={settlementCurrency} accessor={(v: number) => v} />
        </TableCell>
        <TableCell align="right">{totalQuantity}</TableCell>
        <TableCell align="right">
          <UnitsInput
            hideUnitsLabel
            unitType={product.unitType}
            size="small"
            onFocus={() => {
              if (!opened && expandable) {
                void toggle();
              }
            }}
            disabled={inputDisabled}
            variant="outlined"
            value={unitsOrdered || ""}
            onClick={e => {
              e.stopPropagation();
            }}
            onChange={e => {
              const n = Number(e.target.value);
              if (n <= totalQuantity) {
                setUnitsOrdered(n);
              }
            }}
            sx={{ backgroundColor: theme => theme.palette.common.white }}
          />
        </TableCell>
        <TableCell align="center">
          <AddToCartButton
            disabled={!unitsOrdered}
            onClick={e => {
              onAddRepurchaseProduct(unitsOrdered, totalQuantity);
              e.stopPropagation();
            }}
          />
        </TableCell>
      </ClickableRow>
      {/* TODO:(Berto) add opening animation */}
      {opened && (
        <ProductRepurchaseStageRowsView
          repurchaseStages={repurchaseStages}
          totalRepurchase={unitsOrdered}
          settlementCurrency={settlementCurrency}
        />
      )}
    </>
  );
};

function calcTotalQuantity(dealLegs: ProductDealLeg[]) {
  return _.sum(dealLegs.filter(dl => dl.availabilityStatus === "available").map(dl => dl.totalQuantityAvailable.value));
}

type ProductRepurchaseStageRowProps = {
  repurchaseStages: DealLegRepurchaseStageWithNetPayable[];
  totalRepurchase: number;
  settlementCurrency: Currency;
};

const ProductStageRowCell: React.FC<TableCellProps & { allowRepurchase: boolean }> = ({
  allowRepurchase,
  ...props
}) => (
  <TableCell sx={{ ...props.sx, ...(!allowRepurchase && { color: theme => theme.palette.common.grey5 }) }} {...props}>
    {props.children}
  </TableCell>
);

const ProductRepurchaseStageRowsView = ({
  repurchaseStages,
  totalRepurchase,
  settlementCurrency,
}: ProductRepurchaseStageRowProps) => {
  let quantityRemaining = totalRepurchase;

  return (
    <>
      {repurchaseStages.map((repurchaseStage, idx) => {
        let repurchaseQuantity = 0;
        if (quantityRemaining > repurchaseStage.unitsRemaining.value) {
          repurchaseQuantity = repurchaseStage.unitsRemaining.value;
          quantityRemaining -= repurchaseStage.unitsRemaining.value;
        } else if (quantityRemaining > 0) {
          repurchaseQuantity = quantityRemaining;
          quantityRemaining = 0;
        }
        const isLastRow = idx === repurchaseStages.length - 1;

        const ViewableCell: React.FC<TableCellProps> = props => (
          <ProductStageRowCell allowRepurchase={true} {...props} />
        );

        const stage: RepurchaseStage = {
          stageProgress: repurchaseStage.stageProgress,
          quantityAvailable: repurchaseStage.unitsRemaining,
          deadline: repurchaseStage.repurchaseByDate,
        };

        return (
          <TableRow key={`repurchase-stage-sub-row-${repurchaseStage.dealLegId}-${idx}`}>
            <ViewableCell sx={{ ...(!isLastRow && { borderBottom: 0 }) }} />
            <ViewableCell>
              <RepurchaseDate stage={stage} />
            </ViewableCell>
            <ViewableCell align="right">
              <CurrencyRenderer
                maximumFractionDigits={4}
                value={repurchaseStage.netPayable}
                currency={settlementCurrency}
              />
            </ViewableCell>
            <ViewableCell align="right">{repurchaseStage.unitsRemaining.value}</ViewableCell>
            <ViewableCell align="right">{repurchaseQuantity}</ViewableCell>
            <ViewableCell />
          </TableRow>
        );
      })}
    </>
  );
};

interface NonSinglesListingsTableProps {
  onAddRepurchaseProduct(product: WithDbId<Product>, unitsOrdered: number, totalAvailable: number): void;
  productListings: ProductListing[];
  disabled: boolean;
  settlementCurrency: Currency;
}

const NonSinglesListingTable = React.memo(function NSinglesListingTable({
  onAddRepurchaseProduct,
  productListings,
  disabled,
  settlementCurrency,
}: NonSinglesListingsTableProps) {
  const dealLegIds = useMemo<DbKey<DealLeg>[]>(
    () => productListings.flatMap(cl => cl.dealLegs.map(dl => dl.dealLegWithDbId.id)),
    [productListings],
  );
  const { fspValues } = useFspState(dealLegIds);
  const netPayableValues = useMemo(() => calculateNetPayable(productListings, fspValues), [fspValues, productListings]);

  return (
    <ClientSidePaginatedTable
      Header={
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Due date</TableCell>
            <TableCell align="right">Net Payable</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
      }
      Body={
        <NonSinglesTableBody
          rows={productListings}
          onAddRepurchaseProduct={onAddRepurchaseProduct}
          disabled={disabled}
          netPayableValues={netPayableValues}
          settlementCurrency={settlementCurrency}
        />
      }
      rows={productListings}
    />
  );
});

interface NonSinglesListingTableBodyProps {
  rows: ProductListing[];
  onAddRepurchaseProduct(product: WithDbId<Product>, unitsOrdered: number, totalAvailable?: number): void;
  disabled: boolean;
  netPayableValues: NetPayableValues;
  settlementCurrency: Currency;
}
const NonSinglesTableBody = ({
  rows,
  onAddRepurchaseProduct,
  disabled,
  netPayableValues,
  settlementCurrency,
}: NonSinglesListingTableBodyProps) => {
  return (
    <TableBody>
      {rows.length === 0 && <NoResultsRow colSpan={5} />}
      {rows.map(productListing => {
        const productNetPayableValues: Record<string, LoadingValue<number>> = {};
        productListing.dealLegs.forEach(
          dl => (productNetPayableValues[dl.dealLegWithDbId.id] = netPayableValues[dl.dealLegWithDbId.id]),
        );
        return (
          <NonSinglesProductRow
            key={productListing.productWithId.id}
            disabled={disabled}
            productListing={productListing}
            netPayableValues={productNetPayableValues}
            onAddRepurchaseProduct={(totalAvailable, unitsOrdered) =>
              onAddRepurchaseProduct(productListing.productWithId, totalAvailable, unitsOrdered)
            }
            settlementCurrency={settlementCurrency}
          />
        );
      })}
    </TableBody>
  );
};

interface NonSinglesProductRowProps {
  productListing: ProductListing;
  disabled: boolean;
  onAddRepurchaseProduct(totalAvailable: number, unitsOrdered?: number): void;
  netPayableValues: NetPayableValues;
  settlementCurrency: Currency;
}

const NonSinglesProductRow = ({
  productListing,
  disabled,
  onAddRepurchaseProduct,
  netPayableValues,
  settlementCurrency,
}: NonSinglesProductRowProps) => {
  const dealLeg = productListing.dealLegs[0];
  const totalQuantityAvailable = dealLeg.totalQuantityAvailable;
  const product = productListing.productWithId.value;
  const inputDisabled = disabled || netPayableValues[dealLeg.dealLegWithDbId.id].state !== "success";
  const netPaybleTransformation = (netPayable: number) => netPayable * totalQuantityAvailable.value;

  return (
    <TableRow hover>
      <TableCell>
        <OrganisationProductSummary
          {...product}
          vesselCapacity={getVesselCapacity(product.vesselSize, totalQuantityAvailable)}
        />
      </TableCell>
      <TableCell>
        <RepurchaseDateRange repurchaseStages={[dealLeg.finalStage]} />
      </TableCell>
      <TableCell align="right">
        <FspRange<number>
          values={netPayableValues}
          currency={settlementCurrency}
          transformation={netPaybleTransformation}
          accessor={(v: number) => v}
        />
      </TableCell>
      <TableCell align="center">
        <AddToCartButton
          disabled={inputDisabled}
          onClick={e => {
            onAddRepurchaseProduct(totalQuantityAvailable.value, totalQuantityAvailable.value);
            e.stopPropagation();
          }}
        />
      </TableCell>
    </TableRow>
  );
};

function stagesFromDealLeg(dl: ProductDealLeg): RepurchaseStage[] {
  const result: RepurchaseStage[] = [];
  if (dl.compulsorySaleStage.quantityAvailable.value > 0) {
    result.push({
      ...dl.compulsorySaleStage,
    });
  }
  if (dl.finalStage.quantityAvailable.value > 0) {
    result.push({
      ...dl.finalStage,
    });
  }
  return result;
}

const NoResultsRow = ({ colSpan }: { colSpan: number }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Alert severity="info">No products</Alert>
      </TableCell>
    </TableRow>
  );
};

const AddToCartButton = (props: FabProps) => {
  return (
    <Fab
      color="secondary"
      size="small"
      sx={{
        "&.Mui-disabled": { backgroundColor: "transparent" },
      }}
      {...props}>
      <Tooltip title="Add to selected products" placement="top" arrow describeChild>
        <AddIcon />
      </Tooltip>
    </Fab>
  );
};

const calculateNetPayable = (productListings: ProductListing[], fspValues: LoadedFspValues) => {
  const result: Record<string, LoadingValue<number>> = {};
  productListings.forEach(productListing => {
    productListing.dealLegs.forEach(dl => {
      const fsp = fspValues[dl.dealLegWithDbId.id];
      if (fsp && isLoaded(fsp)) {
        const deposit = dl.dealLegWithDbId.value.depositPricePerUnit;
        result[dl.dealLegWithDbId.id] = {
          state: "success",
          value: Number(fsp.value.roundedFsp) - Number(deposit),
        };
      } else {
        result[dl.dealLegWithDbId.id] = {
          state: "loading",
        };
      }
    });
  });
  return result;
};
