import ArrowDropDownCircleRoundedIcon from "@mui/icons-material/ArrowDropDownCircleRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Chip, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LocalDate } from "adl-gen/common";
import { Currency } from "adl-gen/ferovinum/app/db";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import React, { useState } from "react";
import { formatLocalDate } from "utils/date-utils";
import { assertNever } from "utils/hx/util/types";

interface TitleProps {
  title: string;
  caption?: string;
  tooltip?: string;
  beforeTitle?: React.ReactNode;
  afterTitle?: React.ReactNode;
}
interface ValueProps {
  value: string | number | null;
  valueDp?: number;
  currency: Currency;
  emptyValue?: string;
  inError?: boolean;
  afterValue?: React.ReactNode;
}

export const FinancialValueRow = ({
  title,
  caption,
  tooltip,
  beforeTitle,
  afterTitle,
  value,
  valueDp,
  currency,
  emptyValue,
  inError,
  afterValue,
  weak,
  ifNull,
}: TitleProps &
  ValueProps & {
    weak?: boolean;
    ifNull?: "hide" | "fade";
  }) => {
  const titleNode = (
    <TitleWidget
      title={title}
      caption={caption}
      tooltip={tooltip}
      weak={weak}
      before={beforeTitle}
      after={afterTitle}
    />
  );
  const valueNode = (
    <ValueWidget
      value={value}
      dp={valueDp}
      currency={currency}
      emptyValue={emptyValue}
      inError={inError}
      after={afterValue}
    />
  );
  return (
    <RowStructure titleNode={titleNode} valueNode={valueNode} weak={weak} effect={value == null ? ifNull : undefined} />
  );
};

export const FinancialValueRowWithCustomValue = ({
  title,
  caption,
  tooltip,
  beforeTitle,
  afterTitle,
  valueNode,
  weak,
  ifNull,
}: TitleProps & {
  valueNode: React.ReactNode;
  weak?: boolean;
  ifNull?: "hide" | "fade";
}) => {
  const titleNode = (
    <TitleWidget
      title={title}
      caption={caption}
      tooltip={tooltip}
      weak={weak}
      before={beforeTitle}
      after={afterTitle}
    />
  );
  return <RowStructure titleNode={titleNode} valueNode={valueNode} weak={weak} effect={ifNull} />;
};

const RowStructure = ({
  titleNode,
  valueNode,
  weak,
  effect,
}: {
  titleNode: React.ReactNode;
  valueNode: React.ReactNode;
  weak?: boolean;
  effect?: "hide" | "fade";
}) => {
  if (effect === "hide") {
    return null;
  }
  return (
    <TableRow sx={{ opacity: (effect === "fade" ? 0.5 : 1) * (weak ? 0.8 : 1) }}>
      <ValuationCell>{titleNode}</ValuationCell>
      <ValuationCell paddingRight>{valueNode}</ValuationCell>
    </TableRow>
  );
};

const TitleWidget = ({
  title,
  caption,
  tooltip,
  weak,
  before,
  after,
}: {
  title: string;
  caption?: string;
  tooltip?: string;
  weak?: boolean;
  before?: React.ReactNode;
  after?: React.ReactNode;
}) => {
  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
      {before}
      <Stack spacing={0}>
        <Typography variant={weak ? "subtitle1" : "subtitle1Bold"}>{title}</Typography>
        <Typography variant="caption">{caption}</Typography>
      </Stack>
      {tooltip && <TitleTooltip tooltip={tooltip} />}
      {after}
    </Stack>
  );
};

const TitleTooltip = ({ tooltip }: { tooltip: string }) => (
  <Tooltip title={tooltip} arrow describeChild>
    <InfoOutlinedIcon sx={{ color: "common.grey5" }} fontSize="small" />
  </Tooltip>
);

const ValueWidget = ({
  value,
  dp,
  currency,
  inError,
  after,
  emptyValue,
}: {
  value: string | number | null;
  dp?: number;
  currency: Currency;
  emptyValue?: string;
  inError?: boolean;
  after?: React.ReactNode;
}) => {
  const valueNum = typeof value == "string" ? Number(value) : value;
  const currencyNode = (
    <CurrencyRenderer
      variant="subtitle1"
      noWrap
      color={inError ? "common.red" : undefined}
      value={valueNum}
      currency={currency}
      emptyValue={emptyValue}
      minimumFractionDigits={dp}
      maximumFractionDigits={dp}
    />
  );
  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} position={"relative"}>
      {currencyNode}
      {after}
    </Stack>
  );
};

export const ExpandButton = ({ initState, setState }: { initState: boolean; setState: (v: boolean) => void }) => {
  const [open, setOpen] = useState(initState);
  return (
    <IconButton
      onClick={() => {
        setOpen(!open);
        setState(!open);
      }}
      sx={{
        "padding": 0,
        "position": "absolute",
        "right": -25,
        "transition": "transform 0.3s",
        "transform": open ? "rotateX(180deg)" : "rotateX(0deg)",
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}>
      <ArrowDropDownCircleRoundedIcon sx={{ color: "black", fontSize: 20 }} />
    </IconButton>
  );
};

export const DividerRow = ({ text }: { text: string }) => {
  return (
    <DividedTableRow>
      <ValuationCell colSpan={2} paddingRight>
        <Typography variant="caption">{text}</Typography>
      </ValuationCell>
    </DividedTableRow>
  );
};

const DividedTableRow = styled(TableRow)({
  "root": {
    height: 10,
    padding: 0,
  },
  "&.MuiTableRow-root": {
    position: "relative",
  },
  "&.MuiTableRow-root::after": {
    content: "''",
    display: "block",
    borderBottom: "1px solid #e0e0e0",
    width: "25%",
    position: "absolute",
    top: "100%",
    right: 0,
  },
});

export const PaymentStatusChip = ({
  paymentStatus,
}: {
  paymentStatus: { kind: "pending" } | { kind: "paidOn"; value: LocalDate };
}) => {
  switch (paymentStatus.kind) {
    case "pending":
      return <Chip size="small" color="default" label="Pending" />;
    case "paidOn":
      return <Chip size="small" color="success" label={`Paid on ${formatLocalDate(paymentStatus.value)}`} />;
    default:
      assertNever(paymentStatus);
  }
};

export const ValuationCell = styled(TableCell, { shouldForwardProp: prop => prop !== "paddingRight" })<{
  paddingRight?: boolean;
}>(({ theme, paddingRight }) => ({
  textAlign: "right",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingRight: paddingRight ? theme.spacing(4) : 0,
  borderBottom: "none",
}));
