import { AppService } from "adl-gen/app-service";
import { WithDbId } from "adl-gen/common/db";
import { Carrier } from "adl-gen/ferovinum/app/db";
import { WizardConfig, WizardContextLoader, makeWizardStep } from "components/library/components/wizard/wizard";
import { SelectorInfo } from "components/library/widgets/db-key-selector";
import { useMemo } from "react";
import { StockMovementData, fetchStockMovementSummary } from "utils/hooks/use-stock-movement-summary";
import { assertNotUndefined } from "utils/hx/util/types";
import { useAppService } from "../../../../hooks/use-app-service";
import { useSelectedOrg, useSelectedOrgId } from "../../../layouts/portal-page-layout/portal-page";
import { StockMovementConfirmationSectionStep } from "./stock-movement-confirmation-section-step";
import { StockMovementDeliverySectionStep } from "./stock-movement-delivery-section-step";
import {
  StockMovementProductSelectionStep,
  useStockMovementProductSelectionContext,
} from "./stock-movement-product-selection-step";
import {
  CONFIRMATION_FIELD_DEF,
  DELIVERY_METHOD_FIELD_DEF,
  STOCK_MOVEMENT_PRODUCT_SELECTION_FIELD_DEF,
  StockMovementWizardContext,
  StockMovementWizardData,
} from "./stock-movement-wizard-types";

const mapCarrierInfo = (carriers: WithDbId<Carrier>[]): SelectorInfo<Carrier>[] =>
  carriers.map(carrier => ({ id: carrier.id, displayValue: carrier.value.name }));

export const useLoadDeliveryDetailsContext: WizardContextLoader<
  StockMovementWizardData,
  StockMovementWizardContext,
  "deliveryDetails"
> = async ({ stepData }, { wizardData }) => {
  const appService: AppService = useAppService();
  const orgId = assertNotUndefined(useSelectedOrgId());
  const startLocationId = assertNotUndefined(wizardData.productSelection?.startLocation);
  const destLocationId = assertNotUndefined(wizardData.productSelection?.destinationLocation);
  const locs = await appService.getStorageLocations({
    orgId,
    selector: { kind: "byIds", value: [startLocationId, destLocationId] },
  });

  // Delivery is only enabled if the starting location is a vision endpoint (LCB location) and dest is within UK.
  const startLoc = locs.filter(l => l.id === startLocationId)[0];
  const destLoc = locs.filter(l => l.id === destLocationId)[0];
  const isLcb = startLoc.endpoint?.kind === "vision" && startLoc.endpoint?.value.includes("lcb");
  const isDestUk = destLoc.country === "GB";
  const deliveryEnabled = isLcb && isDestUk;

  // Only support LCB for now. Change this to support multiple carriers.
  const lcbCarrier = await appService
    .getCarriers({
      kind: "name",
      value: "London City Bond",
    })
    .then(resp => assertNotUndefined(resp[0]));

  return {
    stepData: stepData ?? {},
    stepContext: {
      deliveryEnabled: deliveryEnabled,
      carrierOptions: mapCarrierInfo([lcbCarrier]),
    },
  };
};

const useLoadConfirmationContext: WizardContextLoader<
  StockMovementWizardData,
  StockMovementWizardContext,
  "confirmation"
> = async ({ stepData }, { wizardData }) => {
  const service: AppService = useAppService();
  const organisation = assertNotUndefined(useSelectedOrg());
  const { productSelection, deliveryDetails } = wizardData as StockMovementWizardData;
  const stockMovementData: StockMovementData = useMemo(
    () => ({
      kind: "withRaw",
      startLocation: productSelection.startLocation,
      destinationLocation: productSelection.destinationLocation,
      deliveryOption: deliveryDetails.stockMovementDeliveryOption,
      products: productSelection.products.map(product => ({
        productId: product.productId,
        quantity: product.quantity,
      })),
    }),
    [productSelection, deliveryDetails],
  );
  const stockMovementSummary = await fetchStockMovementSummary(service, organisation.id, stockMovementData);
  return {
    stepData: stepData ?? {},
    stepContext: {
      stockMovementSummary,
    },
  };
};

export const StockMovementRequestWizardConfig: WizardConfig<StockMovementWizardData, StockMovementWizardContext> = {
  steps: {
    productSelection: makeWizardStep(STOCK_MOVEMENT_PRODUCT_SELECTION_FIELD_DEF, StockMovementProductSelectionStep, {
      loadContext: useStockMovementProductSelectionContext,
    }),
    deliveryDetails: makeWizardStep(DELIVERY_METHOD_FIELD_DEF, StockMovementDeliverySectionStep, {
      loadContext: useLoadDeliveryDetailsContext,
      cacheStepContext: false,
    }),
    confirmation: makeWizardStep(CONFIRMATION_FIELD_DEF, StockMovementConfirmationSectionStep, {
      loadContext: useLoadConfirmationContext,
      cacheStepContext: false,
    }),
  },
};
