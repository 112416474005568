import { Button, ButtonProps } from "@mui/material";
import { useCSVDownloader } from "react-papaparse";
import React from "react";

type CsvDownloadButtonProps = ButtonProps & {
  /**
   * The name of the generated file downloaded onto the user's machine
   */
  outputFileName: string;
  /**
   * Function that returns a data object.
   *
   * @example 
   * A function that returns a single row of data:
   * ```
   * () => [
      {
        "Column 1": "1-1",
        "Column 2": "1-2",
        "Column 3": "1-3",
        "Column 4": "1-4",
      }
    ]
   * ```
   */
  getCsvData(): Record<string, string>[];
};

/**
 * A MUI Button that uses the CSVDownloader component from papaparse.
 * @link https://react-papaparse.js.org/docs#csv-downloader
 */
export const CsvDownloadButton = ({ outputFileName, getCsvData, ...buttonProps }: CsvDownloadButtonProps) => {
  const { CSVDownloader, Type } = useCSVDownloader();

  const WrappedCsvDownloader = React.forwardRef((props, _) => <CSVDownloader {...props} />);
  WrappedCsvDownloader.displayName = "CsvDownloadButton";

  return (
    <Button
      type={Type.Button}
      component={WrappedCsvDownloader as any}
      filename={outputFileName}
      bom={true} // bom is used to indicate Unicode encoding of a text file
      config={{ delimiter: "," }}
      data={getCsvData}
      {...buttonProps}>
      {buttonProps.children}
    </Button>
  );
};
