import React, { useCallback, useMemo, useState } from "react";
import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { AppRoutes } from "../../../../../app/app-routes";
import { DbKey } from "adl-gen/common/db";
import { ProductionOrder } from "adl-gen/ferovinum/app/db";
import {
  ProductionOrderStatusTypeToggle,
  ProductionOrderStatusType,
} from "../../../../widgets/production-orders/production-order-status-type-toggle/production-order-status-type-toggle";
import { useConfirmationDialog } from "components/context/global-dialog/use-dialog";
import { ProductionOrderListingView } from "adl-gen/ferovinum/app/api";
import { ProductionOrderListingsTable } from "../../../../widgets/production-orders/production-order-listings-table/production-order-listings-table";
import { assertNever } from "utils/hx/util/types";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";

type Section = "New" | "Existing";
export interface StorageLocProductionOrdersPageViewProps {
  productionOrders: ProductionOrderListingView[];
  onActionProductionOrder: (props: ProductionOrderAction) => Promise<void>;
}

export const StorageLocProductionOrdersPageView = ({
  productionOrders,
  onActionProductionOrder,
}: StorageLocProductionOrdersPageViewProps) => {
  const newProductionOrders = productionOrders.filter(po => po.status === null);
  const existingProductionOrders = productionOrders.filter(po => po.status !== null);
  const hasNewProductionOrders = newProductionOrders.length > 0;

  const [selectedSection, setSelectedSection] = useState<Section>(hasNewProductionOrders ? "New" : "Existing");
  return (
    <PortalPageContent header={<PortalPageContentHeader title="Production Orders" />}>
      {productionOrders.length > 0 ? (
        <Stack spacing={2}>
          <Tabs value={selectedSection} onChange={(_, v) => setSelectedSection(v)}>
            {hasNewProductionOrders && <Tab label="New Orders" value="New" />}
            {existingProductionOrders.length > 0 && <Tab label="Existing Orders" value="Existing" />}
          </Tabs>

          {selectedSection === "New" && (
            <NewProductionOrdersSection productionOrders={newProductionOrders} onAction={onActionProductionOrder} />
          )}

          {selectedSection === "Existing" && <ExistingProductsSection productionOrders={existingProductionOrders} />}
        </Stack>
      ) : (
        <Alert severity="info">You have no production orders</Alert>
      )}
    </PortalPageContent>
  );
};

interface RejectProductionOrderProps {
  action: "reject";
  productionOrderId: DbKey<ProductionOrder>;
  rejectionReason: string | null;
}
interface AcceptProductionOrderProps {
  action: "accept";
  productionOrderId: DbKey<ProductionOrder>;
}
export type ProductionOrderAction = RejectProductionOrderProps | AcceptProductionOrderProps;

interface NewProductionOrdersSectionProps {
  productionOrders: ProductionOrderListingView[];
  onAction: (props: ProductionOrderAction) => Promise<void>;
}

const NewProductionOrdersSection = ({ productionOrders, onAction }: NewProductionOrdersSectionProps) => {
  const [selectedProductionOrder, setSelectedProductionOrder] = useState<{
    productionOrderId: DbKey<ProductionOrder>;
    popoverAnchor: HTMLButtonElement;
  } | null>(null);

  const handleClickActionsButton = (event: React.MouseEvent<HTMLButtonElement>, orderId: DbKey<ProductionOrder>) => {
    setSelectedProductionOrder({
      productionOrderId: orderId,
      popoverAnchor: event.currentTarget,
    });
  };

  const handleClosePopover = useCallback(() => {
    setSelectedProductionOrder(null);
  }, []);

  const handleAction = useCallback(
    async (
      action:
        | Omit<AcceptProductionOrderProps, "productionOrderId">
        | Omit<RejectProductionOrderProps, "productionOrderId">,
    ) => {
      if (selectedProductionOrder) {
        const productionOrderId = selectedProductionOrder.productionOrderId;

        await onAction({ ...action, productionOrderId });
      }
      handleClosePopover();
    },
    [handleClosePopover, onAction, selectedProductionOrder],
  );

  return (
    <>
      <ProductionOrderListingsTable
        productionOrders={productionOrders}
        productionOrderDetailsLink={AppRoutes.LocProductionOrderDetails}
        includeActionButton={{ onClickActionButton: handleClickActionsButton }}
      />

      <ActionPopover
        open={selectedProductionOrder !== null}
        anchor={selectedProductionOrder !== null ? selectedProductionOrder.popoverAnchor : undefined}
        onClose={handleClosePopover}
        onAccept={async () => await handleAction({ action: "accept" })}
        onReject={async (rejectionReason: string) => await handleAction({ action: "reject", rejectionReason })}
      />
    </>
  );
};

interface ActionPopoverProps {
  open: boolean;
  onClose(): void;
  anchor?: Element;
  onAccept(): Promise<void>;
  onReject(rejectionReason: string): Promise<void>;
}
const ActionPopover = ({ open, onClose, anchor, onAccept, onReject }: ActionPopoverProps) => {
  const [rejectionReason, setRejectionReason] = useState<string>("");
  const { showConfirmationDialog } = useConfirmationDialog();

  const handleAccept = useCallback(() => {
    showConfirmationDialog({
      title: "Accept order?",
      cancelAction: { onClick: onClose },
      confirmAction: { onClick: onAccept },
    });
  }, [onAccept, onClose, showConfirmationDialog]);

  const handleReject = useCallback(() => {
    showConfirmationDialog({
      title: "Reject order?",
      body: (
        <TextField
          multiline
          minRows={5}
          label="Rejection reason"
          helperText="Optional"
          value={rejectionReason}
          onChange={e => setRejectionReason(e.target.value)}
          fullWidth
          sx={{ mt: 1 }}
        />
      ),
      cancelAction: { onClick: onClose },
      confirmAction: { onClick: async () => await onReject(rejectionReason) },
    });
  }, [onClose, onReject, rejectionReason, showConfirmationDialog]);

  return (
    <Popover
      open={open}
      anchorEl={open ? anchor : undefined}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: -70,
      }}>
      <List sx={{ width: "112px" }}>
        <ListItem disablePadding>
          <ListItemButton onClick={handleAccept}>
            <ListItemText
              primary="Accept"
              primaryTypographyProps={{ variant: "subtitle1Bold", color: theme => theme.palette.success.main }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={handleReject}>
            <ListItemText primary="Reject" primaryTypographyProps={{ variant: "subtitle1Bold", color: "error" }} />
          </ListItemButton>
        </ListItem>
      </List>
    </Popover>
  );
};

interface ExistingProductionOrdersSectionProps {
  productionOrders: ProductionOrderListingView[];
}
const ExistingProductsSection = ({ productionOrders }: ExistingProductionOrdersSectionProps) => {
  const availableFilters: ProductionOrderStatusType[] = useMemo(() => {
    const types: ProductionOrderStatusType[] = [];

    const availableStatuses = productionOrders.map(po => po.status);
    if (availableStatuses.includes(null)) {
      types.push("Pending");
    }
    if (availableStatuses.includes("accepted")) {
      types.push("In progress");
    }
    if (availableStatuses.includes("completed")) {
      types.push("Completed");
    }
    if (availableStatuses.includes("rejected")) {
      types.push("Rejected");
    }

    return types;
  }, [productionOrders]);
  const getProductionOrdersWithFilter = useCallback(
    (filter: ProductionOrderStatusType) => {
      switch (filter) {
        case "Pending":
          return productionOrders.filter(po => po.status === null);
        case "In progress":
          return productionOrders.filter(po => po.status === "accepted");
        case "Completed":
          return productionOrders.filter(po => po.status === "completed");
        case "Rejected":
          return productionOrders.filter(po => po.status === "rejected");
        default:
          assertNever(filter);
      }
    },
    [productionOrders],
  );

  const [selectedFilter, setSelectedFilter] = useState<ProductionOrderStatusType>(availableFilters[0]);
  const [productionOrdersToDisplay, setProductionOrdersToDisplay] = useState<ProductionOrderListingView[]>(
    getProductionOrdersWithFilter(selectedFilter),
  );

  const handleChangeFilter = useCallback(
    (filter: ProductionOrderStatusType) => {
      setSelectedFilter(filter);
      setProductionOrdersToDisplay(getProductionOrdersWithFilter(filter));
    },
    [getProductionOrdersWithFilter],
  );

  return (
    <>
      <Box>
        <ProductionOrderStatusTypeToggle
          selectedFilter={selectedFilter}
          setSelectedFilter={handleChangeFilter}
          availableTypes={availableFilters}
        />
      </Box>

      <ProductionOrderListingsTable
        productionOrders={productionOrdersToDisplay}
        productionOrderDetailsLink={AppRoutes.LocProductionOrderDetails}
      />
    </>
  );
};
