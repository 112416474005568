import React from "react";
import { Tooltip, TooltipProps } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const InfoTooltip = ({ title }: { title: TooltipProps["title"] }) => {
  return (
    <Tooltip title={title} arrow describeChild>
      <InfoOutlinedIcon sx={{ color: "common.grey5" }} fontSize="small" />
    </Tooltip>
  );
};
