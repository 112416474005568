import React, { useMemo } from "react";
import { NewDealRequestType } from "adl-gen/ferovinum/app/db";
import { FlowStepper } from "components/library/widgets/flow-stepper";

export interface NewDealRequestFlowStepperProps {
  variant: NewDealRequestType;
  activeStep: number;
}

export const NewDealRequestFlowStepper = ({ variant, activeStep }: NewDealRequestFlowStepperProps) => {
  const steps = useMemo(() => {
    return variant === "newStock"
      ? ["Stock type", "Order details", "Delivery options", "Confirmation"]
      : ["Stock type", "Order details", "Confirmation"];
  }, [variant]);

  return <FlowStepper steps={steps} activeStep={activeStep} />;
};
