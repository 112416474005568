import { Box, Paper, Stack, Theme, Typography } from "@mui/material";
import React from "react";
import { SxProps } from "@mui/system";
import { LiquorOutlined, AccessTime, Error, ErrorOutline } from "@mui/icons-material";
import { correctFloatPrecision } from "utils/numeric-utils";

type IconStockCardState = "error" | "warning" | "alert" | "info";

export interface IconStockCardProps {
  state: IconStockCardState;
  title: string;
  number: number;
}

export const IconStockCard = ({ state, title, number }: IconStockCardProps) => {
  return (
    <Box
      sx={{
        position: "relative",
        height: "153px",
        width: "320px",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
      }}>
      <Paper
        sx={{
          width: "100%",
          height: "136px",
          borderRadius: "7px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          ml: theme => theme.spacing(2),
        }}>
        <Stack spacing="11px" paddingTop="13px" paddingRight="24px" alignItems="flex-end" justifyContent="flex-start">
          <Typography
            align="right"
            variant="caption"
            sx={{ maxWidth: "120px", height: "28px" }}
            lineHeight="14px"
            color={theme => theme.palette.common.grey6}>
            {title}
          </Typography>
          <Typography align="right" variant="h3" color={theme => theme.palette.common.grey6}>
            {correctFloatPrecision(number)}
          </Typography>
        </Stack>
      </Paper>
      <IconCard state={state} />
    </Box>
  );
};

interface IconCardProps {
  state: IconStockCardState;
}
const IconCard = ({ state }: IconCardProps) => {
  const iconStyle: SxProps<Theme> = { width: "92px", height: "auto", p: "23px" };
  return (
    <Paper
      sx={{
        width: "92px",
        height: "92px",
        borderRadius: "7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        left: 0,
      }}>
      {state === "error" && <Error sx={{ ...iconStyle, color: theme => theme.palette.warning.main }} />}
      {state === "warning" && <ErrorOutline sx={{ ...iconStyle, color: theme => theme.palette.warning.main }} />}
      {state === "alert" && <AccessTime sx={{ ...iconStyle, color: theme => theme.palette.common.lightPurple }} />}
      {state === "info" && <LiquorOutlined sx={{ ...iconStyle, color: theme => theme.palette.common.lightPurple }} />}
    </Paper>
  );
};
