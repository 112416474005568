import { UnionType } from "./utility-types";

export const getKey = <T>(key: keyof T) => key;

export function keysOf<T extends object>(obj: T): [keyof T] {
  return Object.keys(obj) as [keyof T];
}

export function valuesOf<V>(obj: Record<string, V>): V[] {
  return Object.values(obj);
}

export function entriesOf<K extends string, V>(obj: Record<K, V>): [K, V][] {
  return Object.entries(obj) as [K, V][];
}

export function mapRecord<K extends string, V, R>(obj: Record<K, V>, fn: (k: K, v: V) => R): Record<K, R> {
  return entriesOf(obj).reduce((acc, [k, v]) => ({ ...acc, [k]: fn(k, v) }), {} as Record<K, R>);
}

export function keysToRecord<K extends string, V>(keys: readonly K[], fn: (k: K) => V): Record<K, V> {
  return keys.reduce((acc, k) => ({ ...acc, [k]: fn(k) }), {} as Record<K, V>);
}

export function mapOptional<T, R>(v: T | undefined, fn: (v: T) => R): R | undefined {
  return v === undefined ? undefined : fn(v);
}

// objects with string based keys
export function isObject(o: unknown): o is { [k: string]: unknown } {
  return o != null && typeof o === "object" && !Array.isArray(o);
}

export function filterUnionByKind<T extends UnionType, K extends T["kind"]>(arr: T[], kind: K): (T & { kind: K })[] {
  return arr.filter((v): v is T & { kind: K } => v.kind === kind);
}

export function collectToRecord<T, V>(
  arr: T[],
  keyFn: (data: T, idx: number) => string | undefined,
  valueFn: (data: T, idx: number) => V | undefined,
  mergeFn?: (oldVal: V, newVal: V, idx: number) => V,
): Record<string, V> {
  const result: Record<string, V> = {};
  for (let idx = 0; idx < arr.length; idx++) {
    const data = arr[idx];
    const key = keyFn(data, idx);
    const newVal = valueFn(data, idx);
    if (key !== undefined && newVal !== undefined) {
      const oldVal: V | undefined = result[key];
      if (!oldVal) {
        result[key] = newVal;
      } else if (mergeFn) {
        result[key] = mergeFn(oldVal, newVal, idx);
      } else {
        throw new Error(`Duplicate key '${key}' found during collectToRecord`);
      }
    }
  }
  return result;
}
