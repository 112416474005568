import React, { FC } from "react";
import { NewDealRequestState, NewDealRequestStateEvent } from "adl-gen/ferovinum/app/db";
import { Extends } from "utils/ts-utils";
import { LocalDate } from "adl-gen/common";
import { formatLocalDate } from "utils/date-utils";
import {
  NewDealRequestStep,
  NewDealRequestStateStepData,
  NewDealRequestStateStepper,
  NewDealRequestStateStepperProps,
} from "components/widgets/new-deal-requests/new-deal-request-state-stepper/new-deal-request-state-stepper";

export type SupplierNewDealRequestState =
  | Extends<
      NewDealRequestState,
      "poCreated" | "poAccepted" | "collectionRequested" | "collectionAccepted" | "collectionConfirmed"
    >
  | "supplierIsPaid";
const SUPPLIER_VISIBLE_STATES = [
  "poAccepted",
  "collectionRequested",
  "collectionAccepted",
  "collectionConfirmed",
  "supplierIsPaid",
] as const;

/** Utility function to transform events from a procurement flow into steps data used in this widget */
export function newDealRequestStateEventsToSupplierStepsData(
  events: NewDealRequestStateEvent[],
  supplierIsPaidTimestamp: number | null,
  supplierEarliestCollectionDate: LocalDate | null,
  carrierEarliestCollectionDate: LocalDate | null,
): Partial<Record<SupplierNewDealRequestState, NewDealRequestStateStepData>> {
  const result = events.reduce(
    (m, e) => {
      if (SUPPLIER_VISIBLE_STATES.findIndex(s => s === e.state) > -1) {
        switch (e.state) {
          case "collectionRequested": {
            m[e.state] = {
              instant: e.time,
              description: supplierEarliestCollectionDate
                ? `Earliest collection ${formatLocalDate(supplierEarliestCollectionDate)}`
                : undefined,
            };
            break;
          }
          case "collectionAccepted": {
            m[e.state] = {
              instant: e.time,
              description: carrierEarliestCollectionDate
                ? `Confirmed collection ${formatLocalDate(carrierEarliestCollectionDate)}`
                : undefined,
            };
            break;
          }
          default: {
            // @ts-ignore
            m[e.state] = { instant: e.time };
            break;
          }
        }
      }
      return m;
    },
    {} as Partial<Record<SupplierNewDealRequestState, NewDealRequestStateStepData>>,
  );
  if (supplierIsPaidTimestamp != null) {
    result.supplierIsPaid = { instant: supplierIsPaidTimestamp };
  }
  return result;
}

const SUPPLIER_STEPS_BY_STATE: Record<SupplierNewDealRequestState, NewDealRequestStep<SupplierNewDealRequestState>> = {
  poCreated: {
    state: "poCreated",
    label: "PO created",
  },
  poAccepted: {
    state: "poAccepted",
    label: "PO accepted",
  },
  collectionRequested: {
    state: "collectionRequested",
    label: "Collection date requested from Carrier",
  },
  collectionAccepted: {
    state: "collectionAccepted",
    label: "Collection date confirmed by carrier",
  },
  collectionConfirmed: {
    state: "collectionConfirmed",
    label: "Products collected by carrier",
  },
  supplierIsPaid: {
    state: "supplierIsPaid",
    label: "Commercial invoice paid by Ferovinum",
  },
};

function getNewDealRequestStateSteps(): NewDealRequestStep<SupplierNewDealRequestState>[] {
  return SUPPLIER_VISIBLE_STATES.map(s => SUPPLIER_STEPS_BY_STATE[s]);
}

export type SupplierNewDealRequestStateStepperProps = Omit<
  NewDealRequestStateStepperProps<SupplierNewDealRequestState>,
  "getNewDealRequestStateSteps"
>;
export const SupplierNewDealRequestStateStepper: FC<SupplierNewDealRequestStateStepperProps> = props => {
  return <NewDealRequestStateStepper {...props} getNewDealRequestStateSteps={getNewDealRequestStateSteps} />;
};
