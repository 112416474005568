import { Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { FEROVINUM_CONTACT_URL } from "components/assets/url";
import { useFormik } from "formik";
import React, { useCallback, useMemo } from "react";
import { DbKey, WithDbId } from "adl-gen/common/db";
import { _AST_MAP, Carrier, Incoterms, snIncoterms, StorageLocation } from "adl-gen/ferovinum/app/db";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { Link } from "components/widgets/link/link";
import { NewDealRequestFlowStepper } from "../../../../widgets/flow-stepper/new-deal-request-flow-stepper";
import { getFormDescriptionForUnionField, getFormLabelForUnionField } from "utils/adl-utils";
import { mixed, object } from "yup";
import { NewDealRequestFlowState } from "../organisation-create-new-deal-request/organisation-create-new-deal-request-page";
import { QuestionContainer } from "../../../../widgets/common/question-container/question-container";
import { IncotermSelect, IncotermsInfo } from "../../../../widgets/incoterms/incoterms-select";
import { declResolver } from "adl-gen/runtime/adl";
import { StorageLocationView } from "adl-gen/ferovinum/app/api";

export interface NewDealRequestDeliveryOptionsFormValues {
  selectedCarrier?: WithDbId<Carrier>;
  selectedStorageLocation?: StorageLocationView;
  selectedIncoterms?: Incoterms;
}

export interface OrganisationNewDealRequestDeliveryOptionsPageViewProps {
  carriers: WithDbId<Carrier>[];
  storageLocations: StorageLocationView[];
  onBack(formValues: NewDealRequestDeliveryOptionsFormValues): void;
  onNext(formValues: NewDealRequestDeliveryOptionsFormValues): void;
  initialValues: NewDealRequestFlowState;
}

const displayedIncoterms: Set<Incoterms> = new Set(["EXW", "FCA", "FOB", "DAP"]);
export function getIncotermsToDescriptionMap(): Map<Incoterms, IncotermsInfo<Incoterms>> {
  const incotermAST = declResolver(_AST_MAP)(snIncoterms);
  if (incotermAST.decl.type_.kind === "union_") {
    return incotermAST.decl.type_.value.fields.reduce((map, field) => {
      const code = field.name as Incoterms;
      if (displayedIncoterms.has(code)) {
        const label = getFormLabelForUnionField(snIncoterms, code);
        const description = getFormDescriptionForUnionField(snIncoterms, code);
        map.set(code, { code, label, description });
      }
      return map;
    }, new Map<Incoterms, IncotermsInfo<Incoterms>>());
  }
  throw new Error("Incoterm decl is not expected type");
}

const incotermsToDescriptionMap = getIncotermsToDescriptionMap();
export const OrganisationNewDealRequestDeliveryOptionsPageView = ({
  carriers,
  storageLocations,
  onBack,
  onNext,
  initialValues,
}: OrganisationNewDealRequestDeliveryOptionsPageViewProps) => {
  const validationSchema = useMemo(
    () =>
      object().shape({
        selectedCarrier: mixed<WithDbId<Carrier>>().required(),
        selectedStorageLocation: mixed<WithDbId<StorageLocation>>().required(),
        selectedIncoterms: mixed<Incoterms>().required(),
      }),
    [],
  );

  const initialFormValues = useMemo((): NewDealRequestDeliveryOptionsFormValues => {
    return initialValues.variant === "newStock"
      ? {
          selectedCarrier: initialValues.carrier,
          selectedStorageLocation: initialValues.storageLocation,
          selectedIncoterms: initialValues.incoterms,
        }
      : {
          selectedStorageLocation: initialValues.storageLocation,
        };
  }, [initialValues]);

  const form = useFormik<NewDealRequestDeliveryOptionsFormValues>({
    initialValues: initialFormValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: onNext,
  });

  const handleChangeCarrier = useCallback(
    (v: DbKey<Carrier>) => {
      const foundCarrier = carriers.find(c => c.id === v);
      if (foundCarrier) {
        form.setFieldValue("selectedCarrier", foundCarrier);
      }
    },
    [carriers, form],
  );

  const handleChangeStorageLoc = useCallback(
    (v: DbKey<StorageLocation>) => {
      const foundLoc = storageLocations.find(sl => sl.id === v);
      if (foundLoc) {
        form.setFieldValue("selectedStorageLocation", foundLoc);
      }
    },
    [storageLocations, form],
  );

  const handleChangeIncoterm = useCallback(
    (v: string) => {
      form.setFieldValue("selectedIncoterms", v);
    },
    [form],
  );

  return (
    <PortalPageContent header={<OrganisationPurchaseOrderDeliveryOptionsHeader />}>
      <Stack spacing={6} justifyContent="space-between">
        <Stack spacing={5}>
          <DeliveryOptionsSelect<DbKey<Carrier>>
            texts={{
              question: "A. Which carrier would you like to use?",
              inputLabel: "Select carrier",
              cannotFindLabel: "Can't find the carrier?",
            }}
            selected={form.values.selectedCarrier?.id}
            options={carriers.map(c => ({ id: c.id, label: c.value.name }))}
            onChange={handleChangeCarrier}
          />
          <DeliveryOptionsSelect<DbKey<StorageLocation>>
            texts={{
              question: "B. What's the destination warehouse?",
              inputLabel: "Select destination warehouse",
              cannotFindLabel: "Can't find the warehouse?",
            }}
            selected={form.values.selectedStorageLocation?.id}
            options={storageLocations.map(sl => ({ id: sl.id, label: sl.storageLocationName }))}
            onChange={handleChangeStorageLoc}
          />
          <IncotermSelect
            question={"C. Which Incoterms will be used?"}
            value={form.values.selectedIncoterms}
            onChange={handleChangeIncoterm}
            incotermsToDescriptionMap={incotermsToDescriptionMap}
          />
        </Stack>

        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={() => onBack(form.values)}>
            Back
          </Button>
          <Button variant="contained" onClick={() => form.submitForm()} disabled={!form.isValid}>
            Next
          </Button>
        </Stack>
      </Stack>
    </PortalPageContent>
  );
};

const OrganisationPurchaseOrderDeliveryOptionsHeader = () => {
  return (
    <PortalPageContentHeader
      variant="split"
      title="Delivery options"
      right={<NewDealRequestFlowStepper activeStep={2} variant="newStock" />}
    />
  );
};

function DeliveryOptionsSelect<K extends string>({
  texts,
  selected,
  options,
  onChange,
}: {
  texts: {
    question: string;
    inputLabel: string;
    cannotFindLabel: string;
  };
  selected?: K;
  options: { id: K; label: string }[];
  onChange(value: K): void;
}) {
  return (
    <QuestionContainer
      question={texts.question}
      Input={
        <FormControl fullWidth>
          <InputLabel>{texts.inputLabel}</InputLabel>
          <Select
            value={selected ?? ""}
            label={texts.inputLabel}
            onChange={e => e.target.value && onChange(e.target.value as K)}>
            {options.map(o => (
              <MenuItem key={o.id} value={o.id}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
      Feedback={
        <>
          <Typography variant="body2" color="common.darkGrey">
            {texts.cannotFindLabel}
          </Typography>
          <Link variant="big" color="grey5" href={FEROVINUM_CONTACT_URL} target="_blank">
            Contact Ferovinum
          </Link>
        </>
      }
    />
  );
}
