import { NewStockSupplierType } from "adl-gen/ferovinum/app/procurement";
import { _AST_MAP, NewSupplier, snUnitType, Supplier, TopLevelUnitType, UnitType } from "adl-gen/ferovinum/app/db";
import { ScopedName } from "adl-gen/sys/adlast";
import { declResolver } from "adl-gen/runtime/adl";
import { Map as AdlMap } from "adl-gen/sys/types";

//TODO(Berto): Move this somewhere else
export function getNewStockNewDealRequestSupplier(supplier: NewStockSupplierType): Supplier | NewSupplier {
  if (supplier.kind === "supplierWithId") {
    return supplier.value.value;
  }
  return supplier.value;
}

function getAnnotationForUnionField(scopedName: ScopedName, fieldName: string, annotationName: string) {
  const unionAST = declResolver(_AST_MAP)(scopedName);
  if (unionAST.decl.type_.kind === "union_") {
    const field = unionAST.decl.type_.value.fields.find(f => f.name === fieldName);
    if (field) {
      const formLabelAnnotation = field.annotations.find(a => a.key.name === annotationName);
      if (formLabelAnnotation && formLabelAnnotation.value !== null) {
        return formLabelAnnotation.value.toString();
      }
    }
  }
  return "?";
}
export function getTopLevelForUnitType(unitType: UnitType): TopLevelUnitType {
  return getAnnotationForUnionField(snUnitType, unitType, "TopLevelUnitType") as TopLevelUnitType;
}

export function getFormLabelForUnionField(scopedName: ScopedName, fieldName: string) {
  return getAnnotationForUnionField(scopedName, fieldName, "FormLabel");
}

export function getFormDescriptionForUnionField(scopedName: ScopedName, fieldName: string) {
  return getAnnotationForUnionField(scopedName, fieldName, "FormDescription");
}

export function getFormLabelToUnionFieldMap<T>(scopedName: ScopedName, values: T[]): Map<T, string> {
  const resultMap: Map<T, string> = new Map();
  const unionAST = declResolver(_AST_MAP)(scopedName);
  if (unionAST.decl.type_.kind === "union_") {
    unionAST.decl.type_.value.fields.forEach(field => {
      const formLabelAnnotation = field.annotations.find(a => a.key.name === "FormLabel");
      const typedField = values.find(v => v === (field.name as unknown));

      if (typedField) {
        resultMap.set(typedField as T, (formLabelAnnotation?.value ?? `${typedField} ?`).toString());
      }
    });
  }
  return resultMap;
}

export function getDefaultValueForField<T>(scopedName: ScopedName, fieldName: string): T {
  const AST = declResolver(_AST_MAP)(scopedName);
  if (AST.decl.type_.kind === "struct_" || AST.decl.type_.kind === "union_") {
    const field = AST.decl.type_.value.fields.find(f => f.name === fieldName);
    if (field) {
      const defaultValue = field.default;
      if (defaultValue.kind === "just") {
        return defaultValue.value as T;
      }
    }
  }
  throw new Error(`Default value not found for ${scopedName.name}.${fieldName}`);
}

export function adlMapToMap<K, V>(adlMap: AdlMap<K, V>): Map<K, V> {
  return new Map(adlMap.map(e => [e.key, e.value]));
}
