import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { WithDbId } from "adl-gen/common/db";
import {
  Currency,
  NewDealRequestType,
  NumberOfUnits,
  Product,
  TopLevelUnitType,
  snTopLevelUnitType,
} from "adl-gen/ferovinum/app/db";
import { NewDealRequestViewNetSubtotal, NewDealRequestViewTotalDiscount } from "adl-gen/ferovinum/app/procurement";
import { Decimal, MonetaryValue } from "adl-gen/ferovinum/app/types";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import { Link } from "components/widgets/link/link";
import { PaginatedTable } from "components/widgets/paginated-table/paginated-table";
import React, { useMemo } from "react";
import { getFormLabelForUnionField, getTopLevelForUnitType } from "utils/adl-utils";
import { assertNotUndefined } from "utils/hx/util/types";
import {
  BulkQuantityUnitType,
  formatNumberOfUnits,
  getVesselCapacity,
  isSinglesUnitType,
  unitsLabelForUnitType,
} from "utils/model-utils";
import { isZeroNumber } from "utils/ts-utils";
import { NewProductData } from "../../../page/organisation/new-deal-requests/organisation-create-new-deal-request/organisation-create-new-deal-request-page";
import { isNewProductData } from "../../../page/organisation/new-deal-requests/organisation-new-deal-request-setup/organisation-new-deal-request-setup-page";
import { InvisibleCell } from "../../common/table/table-cell/invisible-cell";
import {
  OrganisationProductSummary,
  OrganisationProductSummaryProps,
} from "../../organisation-product-summary/organisation-product-summary";
import { upperFirst } from "lodash";

export interface MinimalProductRequest {
  product: WithDbId<Product> | NewProductData;
  numberOfUnits: NumberOfUnits;
  numberOfFreeUnits: NumberOfUnits;
  individualDiscountPct: Decimal;
  clientSpecifiedPurchasePrice: string;
  purchasePrice: string;
  // valuation without including the free stock units of this product
  netSubtotal: string;
}

export interface OrganisationNewDealRequestNetSubtotalTableProps {
  variant: NewDealRequestType;
  products: MinimalProductRequest[];
  purchaseCurrency: Currency;
  settlementCurrency: Currency;
  netSubtotal: NewDealRequestViewNetSubtotal;
  orgIsWholesaler: boolean;
  discount?: NewDealRequestViewTotalDiscount;
  action?: {
    label: string;
    onClick(): void;
  };
}
export const OrganisationNewDealRequestNetSubtotalTable = ({
  variant,
  products,
  discount,
  ...otherProps
}: OrganisationNewDealRequestNetSubtotalTableProps) => {
  const singles = products.filter(p => isSingles(p));
  const nonSingles = useMemo(() => {
    const filteredProducts = products.filter(p => !isSingles(p));
    const result = new Map<TopLevelUnitType, MinimalProductRequest[]>();
    filteredProducts.forEach(p => {
      const topLevelUnitType = getTopLevelForUnitType(
        isNewProductData(p.product) ? p.product.unitType : p.product.value.unitType,
      );
      if (result.has(topLevelUnitType)) {
        assertNotUndefined(result.get(topLevelUnitType)).push(p);
      } else {
        result.set(topLevelUnitType, [p]);
      }
    });
    return result;
  }, [products]);

  return (
    <>
      {variant === "newStock" ? (
        <OrganisationNewDealRequestFreeStockTable
          singles={singles}
          nonSingles={nonSingles}
          discount={discount}
          {...otherProps}
        />
      ) : (
        <ExistingStockProductsTable singles={singles} nonSingles={nonSingles} {...otherProps} />
      )}
    </>
  );
};

interface ProductsTableProps {
  singles: MinimalProductRequest[];
  nonSingles: Map<TopLevelUnitType, MinimalProductRequest[]>;
  purchaseCurrency: Currency;
  settlementCurrency: Currency;
  netSubtotal: NewDealRequestViewNetSubtotal;
  discount?: NewDealRequestViewTotalDiscount | undefined;
  action?: {
    label: string;
    onClick(): void;
  };
  orgIsWholesaler?: boolean;
}

export const formatAsPercentage = (value = "0") => {
  if (value === null || value === undefined || isNaN(Number(value))) {
    return "0.00 %";
  }
  return `${Number(value).toFixed(2)} %`;
};

function checkIfAnyZero(units: NumberOfUnits): boolean {
  if (
    (units.kind == "singles" && units.value === 0) ||
    (units.kind == "hectolitres" && units.value === 0) ||
    (units.kind == "litresOfPureAlcohol" && units.value === 0)
  ) {
    return true;
  }
  return false;
}

const OrganisationNewDealRequestFreeStockTable = ({
  singles,
  nonSingles,
  purchaseCurrency,
  settlementCurrency,
  netSubtotal,
  discount,
  action,
}: ProductsTableProps) => {
  const hasFreeStock = singles.some(p => !isZeroNumber(p.numberOfFreeUnits) && !checkIfAnyZero(p.numberOfFreeUnits));
  const hasDiscount = singles.some(p => !isZeroNumber(p.individualDiscountPct) && p.individualDiscountPct != "");
  const dynamicColSpan = 2 + (hasDiscount ? 1 : 0);
  const dynamicColSpanRow = 2 + (hasFreeStock ? 1 : 0) + (hasDiscount ? 1 : 0);

  return (
    <Stack spacing={5}>
      {singles.length > 0 && (
        <TableContainer>
          <Table sx={{ backgroundColor: "transparent" }}>
            <TableHead>
              <TableRow>
                <InvisibleCell />
                <GroupHeaderCell colSpan={dynamicColSpan}>Paid stock</GroupHeaderCell>
                {hasFreeStock && <GroupHeaderCell>Free Stock</GroupHeaderCell>}
                <InvisibleCell />
              </TableRow>
              <TableRow>
                <SummaryTableCell align="left" emphasizeRightBorder>
                  Product
                </SummaryTableCell>
                <SummaryTableCell hideRightBorder>Purchase Price Per Unit</SummaryTableCell>
                <SummaryTableCell emphasizeRightBorder>Quantity</SummaryTableCell>
                {hasDiscount && <SummaryTableCell emphasizeRightBorder>Discount</SummaryTableCell>}
                {hasFreeStock && <SummaryTableCell emphasizeRightBorder>Number of units</SummaryTableCell>}
                <SummaryTableCell hideRightBorder>Subtotal</SummaryTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {singles.map(p => {
                const key = isNewProductData(p.product) ? p.product.code : p.product.id;
                return (
                  <NewDealRequestFreeStockTableRow
                    key={key}
                    pReq={p}
                    purchaseCurrency={purchaseCurrency}
                    settlementCurrency={settlementCurrency}
                    showFreeUnits={hasFreeStock}
                    showDiscount={hasDiscount}
                  />
                );
              })}
              <NetSubTotalRow
                netSubTotal={netSubtotal.singles}
                purchaseCurrency={purchaseCurrency}
                settlementCurrency={settlementCurrency}
                leftColSpan={dynamicColSpanRow}
                action={action}
              />
              {/* Discount Row */}
              {discount &&
                discount.discountPct !== "0" &&
                discount.discountPct != "" &&
                discount.discountPct != "0.00" && (
                  <>
                    <TableRow>
                      <SummaryTableCell colSpan={dynamicColSpanRow} hideRightBorder align="left"></SummaryTableCell>
                      <SummaryTableCell hideRightBorder>
                        <Typography variant="subtitle1Bold">Discount Applied</Typography>
                      </SummaryTableCell>
                      <SummaryTableCell hideRightBorder>
                        <Typography variant="subtitle1Bold">
                          {formatAsPercentage(discount?.discountPct ? discount.discountPct : "0")}
                        </Typography>
                      </SummaryTableCell>
                    </TableRow>
                    {/* Discounted Total Row */}
                    <TableRow sx={{ backgroundColor: "common.grey3" }}>
                      <SummaryTableCell colSpan={dynamicColSpanRow} hideRightBorder align="left"></SummaryTableCell>
                      <SummaryTableCell hideRightBorder>
                        <Typography variant="subtitle1Bold">Discounted Net Subtotal</Typography>
                      </SummaryTableCell>
                      <SummaryTableCell hideRightBorder>
                        <CurrencyRenderer
                          variant="subtitle1Bold"
                          value={discount?.discountedNetSubtotal}
                          currency={purchaseCurrency}
                        />
                      </SummaryTableCell>
                    </TableRow>
                  </>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {nonSingles.size > 0 && (
        <>
          {[...nonSingles.keys()].map(topLevelUnitType => {
            const netSubtotalForUnitType = assertNotUndefined(
              netSubtotal.nonSingles.filter(ns => ns.key === topLevelUnitType)[0].value,
            );
            return (
              <OrganisationNewDealRequestNonSinglesFreeStockTable
                key={topLevelUnitType}
                topLevelUnitType={topLevelUnitType}
                products={assertNotUndefined(nonSingles.get(topLevelUnitType))}
                settlementCurrency={settlementCurrency}
                purchaseCurrency={purchaseCurrency}
                netSubtotal={netSubtotalForUnitType}
                action={action}
              />
            );
          })}
        </>
      )}
    </Stack>
  );
};

interface NonSinglesProductsTableProps {
  products: MinimalProductRequest[];
  purchaseCurrency: Currency;
  settlementCurrency: Currency;
  netSubtotal: MonetaryValue;
  action?: {
    label: string;
    onClick(): void;
  };
  orgIsWholesaler?: boolean;
  topLevelUnitType: TopLevelUnitType;
}
const OrganisationNewDealRequestNonSinglesFreeStockTable = ({
  products,
  purchaseCurrency,
  settlementCurrency,
  netSubtotal,
  action,
  topLevelUnitType,
}: NonSinglesProductsTableProps) => {
  const unitsLabel = unitsLabelForUnitType(topLevelUnitType);
  const nonSingleLabel = getFormLabelForUnionField(snTopLevelUnitType, topLevelUnitType);
  return (
    <PaginatedTable
      initialRows={products}
      HeaderContent={
        <TableRow>
          <SummaryTableCell align="left">{`Product (${nonSingleLabel})`}</SummaryTableCell>
          <SummaryTableCell hideRightBorder>{`Purchase price per ${unitsLabel}`}</SummaryTableCell>
          <SummaryTableCell>{unitsLabel}</SummaryTableCell>
          <SummaryTableCell hideRightBorder>Subtotal</SummaryTableCell>
        </TableRow>
      }
      BodyContent={
        <NonSingleFreeStockTableRows
          rows={products}
          purchaseCurrency={purchaseCurrency}
          settlementCurrency={settlementCurrency}
        />
      }
      BodyBaseline={
        <NetSubTotalRow
          netSubTotal={netSubtotal}
          purchaseCurrency={purchaseCurrency}
          settlementCurrency={settlementCurrency}
          leftColSpan={2}
          action={action}
        />
      }
    />
  );
};

function NonSingleFreeStockTableRows(props: {
  rows: MinimalProductRequest[];
  purchaseCurrency: Currency;
  settlementCurrency: Currency;
}) {
  const { rows, purchaseCurrency, settlementCurrency } = props;
  return (
    <>
      {rows.map(p => {
        const key = isNewProductData(p.product) ? p.product.code : p.product.id;
        return (
          <NewDealRequestSummaryTableRow
            key={key}
            pReq={p}
            purchaseCurrency={purchaseCurrency}
            settlementCurrency={settlementCurrency}
            showFreeUnits={false}
          />
        );
      })}
    </>
  );
}

interface SummaryTableRowProps {
  pReq: MinimalProductRequest;
  purchaseCurrency: Currency;
  settlementCurrency: Currency;
  showFreeUnits?: boolean;
  showDiscount?: boolean;
}

const NewDealRequestFreeStockTableRow = ({
  pReq,
  purchaseCurrency,
  showFreeUnits,
  showDiscount,
}: SummaryTableRowProps) => {
  const pInfo = isNewProductData(pReq.product) ? pReq.product : pReq.product.value;
  const { product, clientSpecifiedPurchasePrice, numberOfUnits, numberOfFreeUnits, individualDiscountPct } = pReq;
  const summaryProps: OrganisationProductSummaryProps = {
    ...pInfo,
    vesselCapacity: getVesselCapacity(pInfo.vesselSize, numberOfUnits),
  };

  return (
    <TableRow sx={{ backgroundColor: "common.white" }}>
      <SummaryTableCell align="left" emphasizeRightBorder>
        <OrganisationProductSummary {...summaryProps} />
      </SummaryTableCell>
      <SummaryTableCell hideRightBorder>
        {isZeroNumber(clientSpecifiedPurchasePrice) ? (
          "-"
        ) : (
          <CurrencyRenderer
            maximumFractionDigits={4}
            value={clientSpecifiedPurchasePrice}
            currency={purchaseCurrency}
          />
        )}
      </SummaryTableCell>
      <SummaryTableCell emphasizeRightBorder>
        {isZeroNumber(numberOfUnits) ? "-" : formatUnits(product, numberOfUnits)}
      </SummaryTableCell>
      {showDiscount && (
        <SummaryTableCell emphasizeRightBorder>
          {isZeroNumber(individualDiscountPct) ? "0.00 %" : formatAsPercentage(individualDiscountPct)}
        </SummaryTableCell>
      )}
      {showFreeUnits && (
        <SummaryTableCell emphasizeRightBorder>
          {isZeroNumber(numberOfFreeUnits) ? "0" : formatUnits(product, numberOfFreeUnits)}
        </SummaryTableCell>
      )}

      <SummaryTableCell hideRightBorder>
        <CurrencyRenderer maximumFractionDigits={4} value={pReq.netSubtotal} currency={purchaseCurrency} />
      </SummaryTableCell>
    </TableRow>
  );
};

const NewDealRequestSummaryTableRow = ({ pReq, purchaseCurrency }: SummaryTableRowProps) => {
  const pInfo = isNewProductData(pReq.product) ? pReq.product : pReq.product.value;
  const { product, purchasePrice, numberOfUnits } = pReq;
  const summaryProps: OrganisationProductSummaryProps = {
    ...pInfo,
    vesselCapacity: getVesselCapacity(pInfo.vesselSize, numberOfUnits),
  };

  return (
    <TableRow sx={{ backgroundColor: "common.white" }}>
      <SummaryTableCell align="left">
        <OrganisationProductSummary {...summaryProps} />
      </SummaryTableCell>
      <SummaryTableCell hideRightBorder>
        {isZeroNumber(purchasePrice) ? (
          "-"
        ) : (
          <CurrencyRenderer maximumFractionDigits={4} value={purchasePrice} currency={purchaseCurrency} />
        )}
      </SummaryTableCell>
      <SummaryTableCell>{isZeroNumber(numberOfUnits) ? "-" : formatUnits(product, numberOfUnits)}</SummaryTableCell>
      <SummaryTableCell hideRightBorder>
        <CurrencyRenderer maximumFractionDigits={4} value={pReq.netSubtotal} currency={purchaseCurrency} />
      </SummaryTableCell>
    </TableRow>
  );
};

interface NetSubTotalRowProps {
  netSubTotal: MonetaryValue;
  purchaseCurrency: Currency;
  settlementCurrency: Currency;
  leftColSpan: number;
  action?: {
    label: string;
    onClick(): void;
  };
}
const NetSubTotalRow = ({ netSubTotal, purchaseCurrency, leftColSpan, action }: NetSubTotalRowProps) => {
  return (
    <TableRow sx={{ backgroundColor: "common.grey3" }}>
      <SummaryTableCell colSpan={leftColSpan} hideRightBorder align="left">
        {action ? (
          <Link onClick={action.onClick} color="grey6">
            {action.label}
          </Link>
        ) : undefined}
      </SummaryTableCell>
      <SummaryTableCell hideRightBorder>
        <Typography variant="subtitle1Bold">Net subtotal</Typography>
      </SummaryTableCell>
      <SummaryTableCell hideRightBorder>
        <Typography variant="subtitle1Bold">
          <CurrencyRenderer value={netSubTotal} currency={purchaseCurrency} />
        </Typography>
      </SummaryTableCell>
    </TableRow>
  );
};

function formatUnits(product: WithDbId<Product> | NewProductData, numberOfUnits: NumberOfUnits): string {
  const { unitType } = isNewProductData(product) ? product : product.value;
  //Note: Special case because non singles display the units label in the header
  if (!isSinglesUnitType(unitType)) {
    return `${numberOfUnits.value}`;
  }
  return formatNumberOfUnits(numberOfUnits, unitType);
}

const GroupHeaderCell = styled(TableCell)(({ theme }) => ({
  "textAlign": "center",
  "background": `
  linear-gradient(to right,
  transparent,
  transparent 10%,
  ${theme.palette.common.grey3} 10%,
  ${theme.palette.common.grey3} 90%,
  transparent 90%)`,
  "&:before": {
    position: "absolute",
    top: 0,
    left: "10px",
    content: "''",
    width: "10%",
    height: "100%",
    backgroundColor: theme.palette.common.grey3,
    transform: "skew(-20deg)",
  },
  "&:after": {
    position: "absolute",
    top: 0,
    right: "10px",
    content: "''",
    width: "10%",
    height: "100%",
    backgroundColor: theme.palette.common.grey3,
    transform: "skew(20deg)",
  },
}));

const SummaryTableCell = styled(TableCell, {
  shouldForwardProp: propName => propName !== "hideRightBorder" && propName !== "emphasizeRightBorder",
})<{
  hideRightBorder?: boolean;
  emphasizeRightBorder?: boolean;
}>(({ theme, align, hideRightBorder, emphasizeRightBorder }) => ({
  textAlign: align ? align : "center",
  borderRight: hideRightBorder
    ? undefined
    : emphasizeRightBorder
    ? `2px solid ${theme.palette.common.grey4}`
    : `1px solid ${theme.palette.divider}`,
}));

const ExistingStockProductsTable = ({
  singles,
  nonSingles,
  purchaseCurrency,
  settlementCurrency,
  netSubtotal,
  action,
  orgIsWholesaler,
}: ProductsTableProps) => {
  return (
    <Stack spacing={5}>
      {singles.length > 0 && (
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <SummaryTableCell align="left">Product</SummaryTableCell>
                <SummaryTableCell hideRightBorder>{`${
                  orgIsWholesaler ? "Purchase" : "Lowest wholesale"
                } price per unit`}</SummaryTableCell>
                <SummaryTableCell>Number of units</SummaryTableCell>
                <SummaryTableCell hideRightBorder>Subtotal</SummaryTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {singles.map(mp => (
                <ExistingStockRow
                  key={isNewProductData(mp.product) ? mp.product.code : mp.product.id}
                  pReq={mp}
                  purchaseCurrency={purchaseCurrency}
                  settlementCurrency={settlementCurrency}
                />
              ))}
              <NetSubTotalRow
                netSubTotal={netSubtotal.singles}
                purchaseCurrency={purchaseCurrency}
                settlementCurrency={settlementCurrency}
                leftColSpan={2}
                action={action}
              />
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {nonSingles.size > 0 && (
        <>
          {[...nonSingles.keys()].map(topLevelUnitType => {
            const netSubtotalForTopLevelUnitType = assertNotUndefined(
              netSubtotal.nonSingles.filter(ns => ns.key === topLevelUnitType)[0].value,
            );
            return (
              <ExistingStockNonSingleProductsTable
                key={topLevelUnitType}
                topLevelUnitType={topLevelUnitType}
                products={assertNotUndefined(nonSingles.get(topLevelUnitType))}
                settlementCurrency={settlementCurrency}
                purchaseCurrency={purchaseCurrency}
                netSubtotal={netSubtotalForTopLevelUnitType}
                action={action}
                orgIsWholesaler={orgIsWholesaler}
              />
            );
          })}
        </>
      )}
    </Stack>
  );
};

const ExistingStockNonSingleProductsTable = ({
  products,
  purchaseCurrency,
  settlementCurrency,
  netSubtotal,
  action,
  topLevelUnitType,
  orgIsWholesaler,
}: NonSinglesProductsTableProps) => {
  const quantityUnitType = products[0].numberOfUnits.kind as BulkQuantityUnitType;
  const unitsLabel = upperFirst(unitsLabelForUnitType(topLevelUnitType, quantityUnitType));
  const nonSingleLabel = getFormLabelForUnionField(snTopLevelUnitType, topLevelUnitType);
  return (
    <PaginatedTable
      initialRows={products}
      HeaderContent={
        <TableRow>
          <SummaryTableCell align="left">{`Product (${nonSingleLabel})`}</SummaryTableCell>
          <SummaryTableCell hideRightBorder>{`${
            orgIsWholesaler ? "Purchase" : "Lowest wholesale"
          } price per ${unitsLabel}`}</SummaryTableCell>
          <SummaryTableCell>{unitsLabel}</SummaryTableCell>
          <SummaryTableCell hideRightBorder>Subtotal</SummaryTableCell>
        </TableRow>
      }
      BodyContent={
        <ExistingStockNonSingleProductsRows
          rows={products}
          purchaseCurrency={purchaseCurrency}
          settlementCurrency={settlementCurrency}
        />
      }
      BodyBaseline={
        <NetSubTotalRow
          netSubTotal={netSubtotal}
          purchaseCurrency={purchaseCurrency}
          settlementCurrency={settlementCurrency}
          leftColSpan={2}
          action={action}
        />
      }
    />
  );
};

function ExistingStockNonSingleProductsRows(props: {
  rows: MinimalProductRequest[];
  purchaseCurrency: Currency;
  settlementCurrency: Currency;
}) {
  const { rows, purchaseCurrency, settlementCurrency } = props;
  return (
    <>
      {rows.map(mp => (
        <ExistingStockRow
          key={isNewProductData(mp.product) ? mp.product.code : mp.product.id}
          pReq={mp}
          purchaseCurrency={purchaseCurrency}
          settlementCurrency={settlementCurrency}
        />
      ))}
    </>
  );
}

const ExistingStockRow = ({ pReq, purchaseCurrency }: SummaryTableRowProps) => {
  const pInfo = isNewProductData(pReq.product) ? pReq.product : pReq.product.value;
  const numUnits = pReq.numberOfUnits;
  const { purchasePrice, numberOfUnits, netSubtotal } = pReq;
  const summaryProps: OrganisationProductSummaryProps = {
    ...pInfo,
    vesselCapacity: getVesselCapacity(pInfo.vesselSize, numberOfUnits),
  };

  return (
    <TableRow>
      <SummaryTableCell align="left">
        <OrganisationProductSummary {...summaryProps} />
      </SummaryTableCell>
      <SummaryTableCell hideRightBorder>
        <CurrencyRenderer maximumFractionDigits={4} value={purchasePrice} currency={purchaseCurrency} />
      </SummaryTableCell>
      <SummaryTableCell>{numUnits.value}</SummaryTableCell>

      <SummaryTableCell hideRightBorder>
        <CurrencyRenderer maximumFractionDigits={4} value={netSubtotal} currency={purchaseCurrency} />
      </SummaryTableCell>
    </TableRow>
  );
};

function isSingles(pReq: MinimalProductRequest) {
  return isNewProductData(pReq.product)
    ? isSinglesUnitType(pReq.product.unitType)
    : isSinglesUnitType(pReq.product.value.unitType);
}
