import React from "react";

import { PortalPageContentHeader } from "../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../layouts/portal-page-content/portal-page-content";
import { ResetPasswordForm, ResetPasswordFormProps } from "../../widgets/reset-password/reset-password-form";
import { FerovinumLogo } from "components/logos/logos";
import { Stack } from "@mui/material";

/**
 * Reset password page
 */
export const ResetPasswordPage = (props: ResetPasswordFormProps) => {
  const activateAccountPage = location.pathname.includes("activate-account");
  return (
    <PortalPageContent
      header={
        <Stack spacing={2}>
          <FerovinumLogo color="purple" />
          <PortalPageContentHeader title={activateAccountPage ? "Set Password" : "Change Password"} />
        </Stack>
      }>
      <ResetPasswordForm {...props} />
    </PortalPageContent>
  );
};
