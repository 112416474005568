import { Wizard, WizardConfig } from "components/library/components/wizard/wizard";
import { FlowStepper } from "components/library/widgets/flow-stepper";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { keysOf } from "utils/type-utils";
import { MapKeys } from "utils/utility-types";
import { AppRoutes } from "../../app/app-routes";
import { PortalPageContentHeader } from "./portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "./portal-page-content/portal-page-content";

export function WizardPage<WizardData extends object, ContextData extends MapKeys<WizardData, ContextData>>({
  title,
  initialData,
  config,
  onCancel,
  onSubmit,
}: {
  title: string;
  initialData?: WizardData;
  config: WizardConfig<WizardData, ContextData>;
  onCancel: { callback?(): void; route?: AppRoutes };
  onSubmit: {
    submitData: (finalData: WizardData, contextData: ContextData) => Promise<void>;
    callback?(): void;
    route?: AppRoutes;
  };
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const stepKeys = keysOf(config.steps);
  const stepNames = stepKeys.map(k => config.steps[k].stepDataDef.label);
  const stepper = <FlowStepper steps={stepNames} activeStep={activeStep} />;
  const header = <PortalPageContentHeader title={title} variant="split" right={stepper} />;
  const history = useHistory();

  const callAndRoute = useCallback(
    (arg: { callback?(): void; route?: AppRoutes }) => {
      arg.callback && arg.callback();
      arg.route && history.push(arg.route);
    },
    [history],
  );

  const submitForm = useCallback(
    async (finalData: WizardData, contextData: ContextData) => {
      await onSubmit.submitData(finalData, contextData);
      callAndRoute(onSubmit);
    },
    [onSubmit, callAndRoute],
  );

  return (
    <PortalPageContent header={header}>
      <Wizard
        inputWizardData={initialData}
        config={config}
        onStepChange={key => {
          setTimeout(() => setActiveStep(stepKeys.indexOf(key)));
        }}
        onCancel={() => callAndRoute(onCancel)}
        onSubmit={(finalData: WizardData, contextData: ContextData) => submitForm(finalData, contextData)}
      />
    </PortalPageContent>
  );
}
