import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { assertNever, assertNotUndefined } from "utils/hx/util/types";
import { LoginState } from "../../../app/identity-state";
import {
  Carrier,
  Currency,
  GuardedFeature,
  Organisation,
  Purchaser,
  StorageLocation,
  Supplier,
} from "adl-gen/ferovinum/app/db";
import { DbKey, WithDbId } from "adl-gen/common/db";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../app/app-routes";
import { PortalPageLayout } from "./portal-page-layout";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { UserMembership } from "adl-gen/ferovinum/app/api";
import { isLoaded, LoadingValue } from "utils/utility-types";
import { useAppService } from "../../../hooks/use-app-service";

export interface PortalPageProps {
  children?: React.ReactNode;
  loginState: LoginState;
}

/** Local storage keys */
const SELECTED_ENTITY_TYPE_KEY = "selected-entity-type";

const LOCAL_STORAGE_KEYS: Record<EntityKind, string> = {
  org: "selected-org-id",
  storageLoc: "selected-loc-id",
  carrier: "selected-carrier-id",
  supplier: "selected-supplier-id",
  purchaser: "selected-purchaser-id",
};

interface OrgIdToStore {
  kind: "org";
  value: DbKey<Organisation>;
}

interface StorageLocationIdToStore {
  kind: "storageLoc";
  value: DbKey<StorageLocation>;
}

interface CarrierIdToStore {
  kind: "carrier";
  value: DbKey<Carrier>;
}

interface SupplierIdToStore {
  kind: "supplier";
  value: DbKey<Supplier>;
}

interface PurchaserIdToStore {
  kind: "purchaser";
  value: DbKey<Purchaser>;
}

export type IdToStore =
  | OrgIdToStore
  | StorageLocationIdToStore
  | CarrierIdToStore
  | SupplierIdToStore
  | PurchaserIdToStore;

interface SelectedEntites {
  org?: DbKey<Organisation>;
  storageLoc?: DbKey<StorageLocation>;
  carrier?: DbKey<Carrier>;
  supplier?: DbKey<Supplier>;
  purchaser?: DbKey<Purchaser>;
}

const storeSelectedId = (id: IdToStore) => {
  const key = assertNotUndefined(LOCAL_STORAGE_KEYS[id.kind]);
  localStorage.setItem(key, id.value);
};

const removeSelectedId = (type: EntityKind) => {
  const key = assertNotUndefined(LOCAL_STORAGE_KEYS[type]);
  localStorage.removeItem(key);
};

const getStoredSelectedId = (type: EntityKind) => {
  const key = assertNotUndefined(LOCAL_STORAGE_KEYS[type]);
  return localStorage.getItem(key);
};

const storeSelectedEntityType = (entityType: EntityKind) => {
  localStorage.setItem(SELECTED_ENTITY_TYPE_KEY, entityType);
};

const getStoredSelectedEntityType = (): EntityKind | null => {
  const val = localStorage.getItem(SELECTED_ENTITY_TYPE_KEY);
  if (val === "storageLoc") {
    return "storageLoc";
  } else if (val === "org") {
    return "org";
  } else if (val === "carrier") {
    return "carrier";
  } else if (val === "supplier") {
    return "supplier";
  } else if (val === "purchaser") {
    return "purchaser";
  }
  return null;
};

export type EntityKind = "storageLoc" | "org" | "carrier" | "supplier" | "purchaser";
export type SelectedEntity = { membership: LoadingValue<UserMembership> } & (
  | {
      type: "org";
      value: WithDbId<Organisation>;
    }
  | {
      type: "storageLoc";
      value: WithDbId<StorageLocation>;
    }
  | {
      type: "carrier";
      value: WithDbId<Carrier>;
    }
  | {
      type: "supplier";
      value: WithDbId<Supplier>;
    }
  | {
      type: "purchaser";
      value: WithDbId<Purchaser>;
    }
  | {
      type: undefined;
    }
);

function getMembershipList(membership: UserMembership, entity: EntityKind): WithDbId<unknown>[] {
  if (entity === "org") {
    return membership.organisations;
  } else if (entity === "storageLoc") {
    return membership.storageLocations;
  } else if (entity === "carrier") {
    return membership.carriers;
  } else if (entity === "supplier") {
    return membership.suppliers;
  } else if (entity === "purchaser") {
    return membership.purchasers;
  } else {
    assertNever(entity);
  }
}

export const SelectedEntityContext = React.createContext<SelectedEntity>({
  type: undefined,
  membership: { state: "loading" },
});

const DEFAULT_SELECTED_ENTITIES: SelectedEntites = {
  org: undefined,
  storageLoc: undefined,
  supplier: undefined,
  carrier: undefined,
  purchaser: undefined,
};

function getStoredSelectedEntites() {
  const entities = { ...DEFAULT_SELECTED_ENTITIES };
  const keys = Object.keys(entities) as Array<keyof typeof entities>;
  keys.forEach((entity: EntityKind) => {
    entities[entity] = getStoredSelectedId(entity) || undefined;
  });
  return entities;
}

/**
 * Layout for portal pages (logged-in users).
 */
export const PortalPage = ({ children, loginState }: PortalPageProps) => {
  const history = useHistory();
  const service = useAppService();
  const [selectedEntities, setSelectedEntities] = useState<SelectedEntites>(getStoredSelectedEntites());
  const [selectedEntityType, setSelectedEntityType] = useState<EntityKind | undefined>(
    getStoredSelectedEntityType() ?? undefined,
  );

  const getMemberships = useCallback(async () => {
    return await service.getMemberships();
  }, [service]);
  const [loadingMemberships] = useLoadingDataState<UserMembership>(getMemberships);

  const onEntityChange = useCallback(
    (entityUpdate: IdToStore) => {
      const oldType = selectedEntityType;
      setSelectedEntityType(entityUpdate.kind);
      setSelectedEntities(existingEntities => ({
        ...existingEntities,
        [entityUpdate.kind]: entityUpdate.value,
      }));
      if (oldType !== entityUpdate.kind) {
        history.push(AppRoutes.Index);
      } else {
        //Note(Berto): Force reload to avoid having to individually manage page states (affects admins with access to multiple organisations, locations...)
        history.go(0);
      }
    },
    [selectedEntityType, history],
  );

  useEffect(() => {
    if (isLoaded(loadingMemberships)) {
      const memberships = loadingMemberships.value;
      const newSelectedEntities = getStoredSelectedEntites();
      const keys = Object.keys(newSelectedEntities) as Array<keyof typeof newSelectedEntities>;
      keys.forEach((entityType: EntityKind) => {
        const previouslySelectedEntityId = getStoredSelectedId(entityType);
        const membershipList = getMembershipList(memberships, entityType);
        if (previouslySelectedEntityId) {
          const hasMembership = membershipList.some(entity => entity.id === previouslySelectedEntityId);
          if (!hasMembership) {
            removeSelectedId(entityType);
            newSelectedEntities[entityType] = membershipList.length > 0 ? membershipList[0].id : undefined;
          } else {
            newSelectedEntities[entityType] = previouslySelectedEntityId;
          }
        } else if (membershipList.length > 0) {
          newSelectedEntities[entityType] = membershipList[0].id;
        }
      });
      setSelectedEntities(newSelectedEntities);
      const previouslySelectedEntityType = getStoredSelectedEntityType();
      if (!previouslySelectedEntityType || newSelectedEntities[previouslySelectedEntityType] === undefined) {
        const keys = Object.keys(newSelectedEntities) as Array<keyof typeof newSelectedEntities>;
        const firstValidEntity = keys.find(entity => newSelectedEntities[entity] !== undefined) as EntityKind;
        setSelectedEntityType(firstValidEntity);
      }
    }
  }, [loadingMemberships]);

  useEffect(() => {
    const keys = Object.keys(selectedEntities) as Array<keyof typeof selectedEntities>;
    keys.forEach((entity: EntityKind) => {
      const value = selectedEntities[entity];
      if (value) {
        storeSelectedId({ kind: entity, value });
      } else {
        removeSelectedId(entity);
      }
    });
  }, [selectedEntities]);

  useEffect(() => {
    if (selectedEntityType) {
      storeSelectedEntityType(selectedEntityType);
    }
  }, [selectedEntityType]);

  const selectedEntity = useMemo<SelectedEntity>(() => {
    if (!selectedEntityType || loadingMemberships.state !== "success") {
      return { type: undefined, membership: { state: "loading" } };
    } else {
      const memberships = loadingMemberships.value;
      const type = selectedEntityType;
      let selectedEntity: Partial<SelectedEntity>;
      if (type === "org" && selectedEntities.org) {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        selectedEntity = {
          type,
          value: memberships.organisations.find(o => o.id === selectedEntities.org),
          membership: loadingMemberships,
        };
      } else if (type === "storageLoc" && selectedEntities.storageLoc) {
        selectedEntity = {
          type,
          value: memberships.storageLocations.find(o => o.id === selectedEntities.storageLoc)!,
          membership: loadingMemberships,
        };
      } else if (type === "carrier" && selectedEntities.carrier) {
        selectedEntity = {
          type,
          value: memberships.carriers.find(o => o.id === selectedEntities.carrier),
          membership: loadingMemberships,
        };
      } else if (type === "supplier" && selectedEntities.supplier) {
        selectedEntity = {
          type,
          value: memberships.suppliers.find(o => o.id === selectedEntities.supplier)!,
          membership: loadingMemberships,
        };
      } else if (type === "purchaser" && selectedEntities.purchaser) {
        selectedEntity = {
          type,
          value: memberships.purchasers.find(o => o.id === selectedEntities.purchaser)!,
          membership: loadingMemberships,
        };
      } else {
        return { type: undefined, membership: loadingMemberships };
      }

      if (!selectedEntity.value || !selectedEntity.type) {
        return { type: undefined, membership: loadingMemberships };
      }

      return selectedEntity as SelectedEntity;
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    }
  }, [selectedEntityType, selectedEntities, loadingMemberships]);

  return (
    <SelectedEntityContext.Provider value={selectedEntity}>
      <PortalPageLayout loginState={loginState} loadingMembership={loadingMemberships} onEntityChange={onEntityChange}>
        {children}
      </PortalPageLayout>
    </SelectedEntityContext.Provider>
  );
};

export const useSelectedOrgId = (): DbKey<Organisation> | undefined => {
  const selectedEntities = useContext(SelectedEntityContext);
  if (selectedEntities.type !== "org") {
    return undefined;
  }
  return selectedEntities.value.id;
};

export const useSelectedOrg = (): WithDbId<Organisation> | undefined => {
  const selectedEntities = useContext(SelectedEntityContext);
  if (selectedEntities.type !== "org") {
    return undefined;
  }
  return selectedEntities.value;
};

export const useSelectedOrgFeatures = (): GuardedFeature[] => {
  const selectedEntities = useContext(SelectedEntityContext);
  if (selectedEntities.type === "org" && selectedEntities.membership.state === "success") {
    const orgId = selectedEntities.value.id;
    return selectedEntities.membership.value.orgFeatures.find(e => e.key === orgId)?.value ?? [];
  }
  return [];
};

export const useSelectedCarrier = (): WithDbId<Carrier> | undefined => {
  const selectedEntities = useContext(SelectedEntityContext);
  if (selectedEntities.type !== "carrier") {
    return undefined;
  }
  return selectedEntities.value;
};

export const useSelectedStorageLocation = (): WithDbId<StorageLocation> | undefined => {
  const selectedEntities = useContext(SelectedEntityContext);
  if (selectedEntities.type !== "storageLoc") {
    return undefined;
  }
  return selectedEntities.value;
};

export const useSelectedSupplier = (): WithDbId<Supplier> | undefined => {
  const selectedEntities = useContext(SelectedEntityContext);
  if (selectedEntities.type !== "supplier") {
    return undefined;
  }
  return selectedEntities.value;
};

export const useSelectedPurchaser = (): WithDbId<Purchaser> | undefined => {
  const selectedEntities = useContext(SelectedEntityContext);
  if (selectedEntities.type !== "purchaser") {
    return undefined;
  }
  return selectedEntities.value;
};

export const useMembership = (): LoadingValue<UserMembership> => {
  const selectedEntities = useContext(SelectedEntityContext);
  return selectedEntities.membership;
};

export const useSelectedEntity = () => useContext(SelectedEntityContext);

export const useSettlementCurrency = (): Currency => {
  const selectedEntity = useSelectedEntity();
  if (selectedEntity.type === "org" || selectedEntity.type === "purchaser") {
    return selectedEntity.value.value.currency;
  } else if (selectedEntity.type === "storageLoc") {
    // TODO(barry): Add currency to storage location
    return "GBP";
  } else if (selectedEntity.type === "carrier" || selectedEntity.type === "supplier") {
    throw new Error(`Entity type ${selectedEntity.type} does not have a settlement currency`);
  } else if (!selectedEntity.type) {
    throw new Error(`Cannot retrieve settlement currency in this state`);
  } else {
    assertNever(selectedEntity);
  }
};
