import { BigDecimal, LocalDate } from "adl-gen/common";
import { PurchaseRequestReportData } from "adl-gen/ferovinum/app/api";
import { Currency, PurchaseRequest } from "adl-gen/ferovinum/app/db";
import { camelCaseToReadableFormat, downloadObjectsToCSV, CSVDownloadRowData } from "utils/csv-utils";
import { createCurrencyFormatter } from "utils/currency-utils";
import { daysOverdue, formatInstant, formatLocalDate } from "utils/date-utils";
import { correctFloatPrecision } from "utils/numeric-utils";

export interface PurchaseRequestCSVData {
  tradeSaleReferenceNo: CSVDownloadRowData;
  currentStatus: CSVDownloadRowData;
  purchaser: CSVDownloadRowData;
  goodsValueExcludingVatAndDuty: CSVDownloadRowData;
  vat: CSVDownloadRowData;
  duty: CSVDownloadRowData;
  totalInvoiceValue: CSVDownloadRowData;
  dateRaised: CSVDownloadRowData;
  datePurchaserAccepted: CSVDownloadRowData;
  collectedDate: CSVDownloadRowData;
  deliveredDate: CSVDownloadRowData;
  advanceUponDeliveryAmount: CSVDownloadRowData;
  advanceUponDeliveryPaymentDate: CSVDownloadRowData;
  invoiceDueDate: CSVDownloadRowData;
  invoicePaidAmount: CSVDownloadRowData;
  invoiceLastPaymentDate: CSVDownloadRowData;
  invoiceOutstandingAmount: CSVDownloadRowData;
  invoiceOverdueBalance: CSVDownloadRowData;
  overdueInvoiceDays: CSVDownloadRowData;
  finalAdvanceUponDeliveryAmount: CSVDownloadRowData;
  finalAdvanceUponDeliveryPaymentDate: CSVDownloadRowData;
  finalAdvanceUponDeliveryPaymentActualAmount: CSVDownloadRowData;
}

const findEventDate = (pr: PurchaseRequest, eventType: string): string | undefined => {
  const eventTimestamp = pr.stateEvents.find(event => event.state === eventType)?.time;
  return eventTimestamp ? formatInstant(eventTimestamp) : undefined;
};

const formatDate = (date: LocalDate | null | undefined): string => {
  return date ? formatLocalDate(date) : "";
};

const processPurchaseRequestViewData = (reportData: PurchaseRequestReportData): PurchaseRequestCSVData => {
  const pr = reportData.purchaseRequest;
  const { purchaserCurrency, settlementCurrency } = pr;
  const overdueDays = daysOverdue(reportData.invoiceDueDate);

  const formatCurrencyValue = (value: BigDecimal | null | undefined, currency: Currency): string => {
    const formatter = createCurrencyFormatter(currency);
    return value ? formatter().format(correctFloatPrecision(Number(value))) : "";
  };

  return {
    tradeSaleReferenceNo: { value: pr.purchaseRequestNumber },
    currentStatus: { value: camelCaseToReadableFormat(pr.state) },
    purchaser: { value: reportData.purchaserName },
    goodsValueExcludingVatAndDuty: { value: formatCurrencyValue(reportData.goodsValueExVatExDuty, settlementCurrency) },
    vat: { value: formatCurrencyValue(pr.dutyAndVat?.vat, settlementCurrency) },
    duty: { value: formatCurrencyValue(pr.dutyAndVat?.duty, settlementCurrency) },
    totalInvoiceValue: { value: formatCurrencyValue(reportData.purchaserInvoiceAmount, purchaserCurrency) },
    dateRaised: { value: findEventDate(pr, "new") ?? "" },
    datePurchaserAccepted: { value: findEventDate(pr, "purchaserAccepted") ?? "" },
    collectedDate: { value: formatDate(pr.collectedDate) },
    deliveredDate: { value: formatDate(pr.deliveredDate) },
    advanceUponDeliveryAmount: { value: formatCurrencyValue(reportData.advanceUponDeliveryAmount, settlementCurrency) },
    advanceUponDeliveryPaymentDate: {
      value: reportData.advanceUponDeliveryAmount ? formatDate(pr.advanceUponDeliveryPaymentDate) : "",
    },
    invoiceDueDate: { value: formatDate(reportData.invoiceDueDate) },
    invoicePaidAmount: { value: formatCurrencyValue(reportData.invoicePaidAmount, purchaserCurrency) },
    invoiceLastPaymentDate: { value: pr.paidAt ? formatInstant(pr.paidAt) : "" },
    invoiceOutstandingAmount: { value: formatCurrencyValue(reportData.invoiceOutstandingAmount, purchaserCurrency) },
    invoiceOverdueBalance: { value: formatCurrencyValue(reportData.invoiceOverdueBalance, purchaserCurrency) },
    overdueInvoiceDays: { value: overdueDays > 0 ? overdueDays.toString() : "" },
    finalAdvanceUponDeliveryAmount: {
      value: reportData.advanceUponDeliveryAmount
        ? formatCurrencyValue(reportData.finalAdvanceUponDeliveryAmount, settlementCurrency)
        : "",
      header: "Final balance due from Ferovinum upon invoice payment: Expected Amount",
    },
    finalAdvanceUponDeliveryPaymentDate: {
      value: reportData.advanceUponDeliveryAmount ? formatDate(pr.advanceUponDeliveryPaymentDate) : "",
      header: "Final balance due from Ferovinum upon invoice payment: Payment Date",
    },
    finalAdvanceUponDeliveryPaymentActualAmount: {
      value: reportData.advanceUponDeliveryAmount
        ? formatCurrencyValue(reportData.finalAdvanceUponDeliveryPaymentActualAmount, settlementCurrency)
        : "",
      header: "Final balance due from Ferovinum upon invoice payment: Payment Actual Amount",
    },
  };
};

export function downloadTPSDataToCSV(purchaseRequests: PurchaseRequestReportData[], filePrefix: string) {
  downloadObjectsToCSV<PurchaseRequestReportData, PurchaseRequestCSVData>(
    purchaseRequests,
    processPurchaseRequestViewData,
    filePrefix,
  );
}
