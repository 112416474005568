import React, { useMemo } from "react";
import { Card, CardText, stackStrings } from "components/library/widgets/card/card";
import { Typography } from "@mui/material";
export interface PurchaseRequestCollectionProps {
  locationName: string;
  addressLine1: string | null;
  addressLine2: string | null;
  town: string | null;
  postCode: string | null;
  bonded: boolean;
}

export const PurchaseRequestCollection = ({
  locationName,
  addressLine1,
  addressLine2,
  town,
  postCode,
  bonded,
}: PurchaseRequestCollectionProps) => {
  const addressLine = [addressLine1, addressLine2]
    .map(v => (v ? v.trim() : ""))
    .filter(Boolean)
    .join(", ");

  const address = useMemo(() => stackStrings([addressLine, town, postCode]), [addressLine, town, postCode]);
  return (
    <Card
      header={{
        title: "Collection Details",
      }}
      body={
        <>
          <Typography>{locationName}</Typography>
          <CardText title="Address" content={address} lineNumbers={3} />
          <CardText title="Delivery Type" content={bonded === true ? "In-Bond" : "Out-of-Bond"} />
        </>
      }
    />
  );
};
