import { ISchema, ObjectSchema, ObjectShape, Schema, object } from "yup";
import { STATIC_FIELD_TYPE } from "./data-field-builder";
import { DataFieldDef } from "./data-field-def";

// ObjectFields is a convenience type for mapping an object to a set of DataFieldDef representing
// each property in the object.
export type ObjectFields<T extends object> = {
  [P in keyof T]: DataFieldDef<T[P]>;
};

export function makeObjectDef<T extends object>(fields: ObjectFields<T>): Readonly<ObjectFields<T>> {
  return Object.freeze(fields);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapObjectDef<T extends object, R extends { [P in keyof T]: any }>(
  objectDef: Readonly<ObjectFields<T>>,
  mapFn: <K extends keyof T>(def: DataFieldDef<T[K]>, key: K) => R[K],
): R {
  const result: Partial<R> = {};
  for (const key in objectDef) {
    const fieldDef = objectDef[key];
    result[key] = mapFn(fieldDef, key);
  }
  return result as R;
}

export function mapObjectDefToArray<T extends object, R>(
  objectDef: Readonly<ObjectFields<T>>,
  mapFn: <S>(def: DataFieldDef<S>, key: keyof T) => R,
): R[] {
  const result: R[] = [];
  forEachFieldInObjectDef(objectDef, (def, key) => {
    result.push(mapFn(def, key));
  });
  return result;
}

export function forEachFieldInObjectDef<T extends object>(
  objectDef: Readonly<ObjectFields<T>>,
  dataFieldDefFn: <S>(def: DataFieldDef<S>, key: keyof T) => void,
): void {
  mapObjectDef(objectDef, (def, key) => {
    dataFieldDefFn(def, key);
    return null;
  });
}

// Create a yup object schema using the provided object definition and optional schema override.
export function makeObjectSchema<T extends object>(
  objectDef: Readonly<ObjectFields<T>>,
  schemaOverride?: <K extends keyof T>(def: DataFieldDef<T[K]>, key: K) => Schema<T[K]>,
  excludes?: readonly [string, string][],
): ObjectSchema<T> {
  const shape: { [P in keyof T]: ISchema<T[P]> } = mapObjectDef(objectDef, (def, key): Schema => {
    const schema = def.getSchema();
    if (schemaOverride && schema.type !== STATIC_FIELD_TYPE) {
      // only override schema of non-static fields
      return schemaOverride(def, key);
    } else {
      return schema;
    }
  });
  return object().shape(shape as ObjectShape, excludes) as ObjectSchema<T>;
}

export function makeObjectBaseSchema<T extends object>(objectDef: Readonly<ObjectFields<T>>): ObjectSchema<T> {
  const shape: { [P in keyof T]: ISchema<T[P]> } = mapObjectDef(objectDef, def => def.getBaseSchema());
  return object().shape(shape as ObjectShape) as ObjectSchema<T>;
}

export function validateWithObjectDef<T extends object>(
  data: Partial<T>,
  objectDef: Readonly<ObjectFields<T>>,
  schemaOverride?: <K extends keyof T>(def: DataFieldDef<T[K]>, key: K) => Schema<T[K]>,
  excludes?: readonly [string, string][],
) {
  return makeObjectSchema(objectDef, schemaOverride, excludes).isValidSync(data);
}

// A utility function that checks the headers of a CSV file against the provided object definition.
export function checkHeadersMatch<T extends object>(objectDef: Readonly<ObjectFields<T>>, headers: string[]): boolean {
  return mapObjectDefToArray(objectDef, def => {
    return def.isValid(" ") || headers.findIndex(h => h.trim().toLowerCase().startsWith(def.label.toLowerCase())) >= 0;
  }).every(v => v);
}

export function pickData<T extends object, S extends T>(objectDef: Readonly<ObjectFields<T>>, data: S): T {
  return mapObjectDef(objectDef, (_, key) => data[key]);
}
