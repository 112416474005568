import React from "react";
import { FastField } from "formik";
import { UnitsInput, UnitsInputProps } from "./units-input";

type MetaProps = { meta: { unitType: UnitsInputProps["unitType"]; error: UnitsInputProps["error"] } };
// Note: FastField only re-renders if one of the formik managed values changed
// Because we also display the unit as a endAdornment we want to force trigger re-render of this field if the unitType changes
// https://github.com/jaredpalmer/formik/issues/1188
function shouldUpdate(nextProps: MetaProps, prevProps: MetaProps) {
  return nextProps.meta.unitType !== prevProps.meta.unitType || nextProps.meta.error !== prevProps.meta.error;
}
export const UnitsInputFastField = (props: UnitsInputProps) => (
  <FastField name={props.name} meta={{ unitType: props.unitType, error: props.error }} shouldUpdate={shouldUpdate}>
    {() => <UnitsInput {...props} />}
  </FastField>
);
