import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useRef } from "react";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import _ from "lodash";
import { isBigDecimal, isNumber, isString, isValidNumber } from "utils/ts-utils";
import { BigDecimal } from "adl-gen/common";
import { DebouncedTextField } from "../text-input/debounced-text-field";
import { assertNever } from "assert-never";
import { assertNotUndefined } from "utils/hx/util/types";
import { toPrecision, truncateDecimals } from "utils/numeric-utils";

export type RoundingInputProps = RoundingProps & Omit<TextFieldProps, "type">;
interface RoundingProps {
  // Note: onChange is always required as this is an always controlled input
  // we cannot make it required here to keep compatibility with formik expected type
  // onChange: Required<TextFieldProps["onChange"]>;

  /** Precision to be used in this round input */
  precision: number;

  /** Optional start/end adornments for the input */
  startAdornment?: string;
  endAdornment?: string;

  /** makes this rounding input debounce its callback */
  debounced?: boolean;
}
const regex = new RegExp(/^\d*\.?\d*$/);

const sanitize = (n: unknown, precision: number): string | null => {
  if (isString(n)) {
    const trimmed = n.trim();
    if (regex.test(trimmed)) {
      if (trimmed === ".") {
        return "0.";
      }
      return truncateDecimals(trimmed, precision);
    } else {
      return null;
    }
  }
  if (isNumber(n)) {
    return round(n, precision);
  }
  return null;
};

const round = (n: BigDecimal | number, precision: number): BigDecimal => {
  if (isBigDecimal(n)) {
    return _.round(Number(n), precision).toString();
  }
  if (isNumber(n)) {
    return _.round(n, precision).toString();
  }
  assertNever(n);
};

/**
 *  Numeric input that truncates the user numeric input value to match the desired precision passed as a prop
 *  Note: If precision is zero - this input will behave as an only integers input
 *  onBlur - the input value will be correctly formatted into the desired precision
 *  */
export const RoundingInput = ({
  value,
  precision,
  startAdornment,
  endAdornment,
  debounced,
  ...props
}: RoundingInputProps) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>();

  // Triggers a native on change event when this component is initially rendered to make sure the format of the initial value is correct
  //Note: https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-change-or-input-event-in-react-js-from-jquery-or
  useEffect(() => {
    if (inputRef.current) {
      let roundedValue = sanitize(value, precision);
      if (roundedValue == null) return;
      if (isValidNumber(roundedValue)) {
        roundedValue = toPrecision(roundedValue, precision);
      }
      const nativeInputValueSetter = assertNotUndefined(
        Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set,
      );
      nativeInputValueSetter.call(inputRef.current, roundedValue);

      inputRef.current.dispatchEvent(new Event("input", { bubbles: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (regex.test(e.target.value)) {
        const sanitisedValue = sanitize(e.target.value, precision);
        if (sanitisedValue == null) return;
        e.target.value = sanitisedValue;
        if (props.onChange) {
          props.onChange(e);
        }
      }
    },
    [precision, props],
  );

  const onBlur = useCallback(
    (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (e.target.value.trim() !== "") {
        // business requirement:
        // - formats the value that was input by the user to the precision specified when losing focus
        const sanitisedValue = sanitize(e.target.value, precision);
        if (sanitisedValue !== null) {
          const expectedFormatValue = toPrecision(sanitisedValue, precision);
          if (expectedFormatValue != e.target.value) {
            e.target.value = expectedFormatValue;
            if (props.onChange) {
              props.onChange(e);
            }
          }
        }
      }
      if (props.onBlur) {
        props.onBlur(e);
      }
    },
    [precision, props],
  );

  const textFieldProps = {
    ...props,
    type: "text",
    onChange,
    onBlur,
    inputRef,
    value: value ?? "",
    InputProps: {
      ...(startAdornment && { startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment> }),
      ...(endAdornment && { endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment> }),
    },
  };

  if (debounced) {
    return (
      <DebouncedTextField
        {...textFieldProps}
        sanitize={value => sanitize(value, precision)}
        inputProps={{
          ...props.inputProps,
          style: { textAlign: startAdornment ? "left" : "right", ...props.inputProps?.style },
        }}
      />
    );
  }

  /**  Not using type=number here to avoid sanitization from browsers as it is not consistent between browsers
   * I.e: Caret in chrome will jump to the beginning of the input when deleting the decimal dot
   * Also avoid mistakes by number inputs using up and down arrow keys or the mouse wheel
   * */
  return (
    <TextField
      {...textFieldProps}
      inputProps={{
        ...props.inputProps,
        style: { textAlign: startAdornment ? "left" : "right", ...props.inputProps?.style },
      }}
    />
  );
};
