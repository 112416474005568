import { CSSProperties } from "react";
import { createTheme } from "@mui/material/styles";
import { FerovinumGlobalStyles } from "./global-styles";
import { ENV_APP_BAR_HEADER_COLORS, BRAND_COLORS, COMMON_COLORS, GREY_COLORS, SIGNALS_COLORS } from "./color-palette";
import { DEFAULT_BORDER_RADIUS, DEFAULT_SPACING } from "./metrics";
import { LinkProps } from "@mui/material";
import { LinkBehavior } from "../widgets/link/link";
import { UiConfig } from "adl-gen/ferovinum/app/uiconfig";
import { parseUiConfig } from "adl-service/ui-config";

const CHECK_ICON = (color: string) =>
  `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
    color,
  )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`;

const UI_CONFIG: UiConfig = parseUiConfig();

export const AppTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
  spacing: DEFAULT_SPACING,
  shape: {
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
  zIndex: {
    mobileStepper: 1000, // unused
    speedDial: 1050,
    drawer: 1050,
    appBar: 1100,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
    fontSize: 16,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: 96,
      fontWeight: 300,
    },
    h2: {
      fontSize: 60,
      fontWeight: 300,
    },
    h3: {
      fontSize: 48,
    },
    h4: {
      fontSize: 34,
    },
    h5: {
      fontSize: 24,
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 16,
    },
    subtitle1Bold: {
      fontSize: 16,
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
    },
    body1Bold: {
      fontSize: 16,
      fontWeight: 700,
    },
    body2: {
      fontSize: 14,
    },
    body2Medium: {
      fontSize: 14,
      fontWeight: 500,
    },
    body2Bold: {
      fontSize: 14,
      fontWeight: 700,
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
    },
    caption: {
      fontSize: 12,
    },
    captionMedium: {
      fontSize: 12,
      fontWeight: 500,
    },
    overline: {
      fontSize: 11,
    },
    hyperLinkBig: {
      cursor: "pointer",
      fontSize: "16px !important", //Note: due to material-ui not picking the right styles when using <Link/>
      fontWeight: 700,
      color: COMMON_COLORS.hyperLink,
    },
    hyperLinkSmall: {
      cursor: "pointer",
      fontSize: "12px !important",
      fontWeight: 700,
      textDecoration: "underline !important",
      color: COMMON_COLORS.hyperLink,
    },
    graphLabel: {
      fontSize: 11,
    },
  },
  palette: {
    common: {
      /** Brand Colors*/
      purple: BRAND_COLORS.purple,
      lightPurple: BRAND_COLORS.lightPurple,
      veryLightPurple: BRAND_COLORS.veryLightPurple,
      yellow: BRAND_COLORS.yellow,
      lightYellow: BRAND_COLORS.lightYellow,
      grey: BRAND_COLORS.grey,
      darkGrey: BRAND_COLORS.darkGrey,
      /** Grey colors */
      white: GREY_COLORS.white,
      grey1: GREY_COLORS.grey1,
      grey2: GREY_COLORS.grey2,
      grey3: GREY_COLORS.grey3,
      grey4: GREY_COLORS.grey4,
      grey5: GREY_COLORS.grey5,
      grey6: GREY_COLORS.grey6,
      grey7: GREY_COLORS.grey7,
      /** Signal colors */
      error: SIGNALS_COLORS.error,
      warning: SIGNALS_COLORS.warning,
      success: SIGNALS_COLORS.success,
      /** Common colors */
      hyperLink: COMMON_COLORS.hyperLink,
      lightBackground: COMMON_COLORS.lightBackground,
      /** TODO:(Berto) @deprecated - remove once new design is live */
      pink: "rgba(253, 67, 135, 0.5)",
      red: "#f51010",
    },
    error: {
      main: SIGNALS_COLORS.error,
      light: SIGNALS_COLORS.errorLight,
      dark: SIGNALS_COLORS.errorDark,
    },
    success: {
      main: SIGNALS_COLORS.success,
      light: SIGNALS_COLORS.successLight,
      dark: SIGNALS_COLORS.successDark,
    },
    warning: {
      main: SIGNALS_COLORS.warning,
      light: SIGNALS_COLORS.warningLight,
      dark: SIGNALS_COLORS.warningDark,
    },
    primary: {
      main: BRAND_COLORS.purple,
    },
    secondary: {
      main: BRAND_COLORS.yellow,
    },
    background: {
      default: COMMON_COLORS.lightBackground,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: FerovinumGlobalStyles,
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: ENV_APP_BAR_HEADER_COLORS[UI_CONFIG.environment],
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        root: {
          cursor: "pointer",
          fontSize: 14,
          fontWeight: 700,
          textDecoration: "underline",
        },
      },
    },
    MuiListItemButton: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiIconButton: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ":not(.MuiFormControl-fullWidth)": {
            width: "100%",
            maxWidth: 248,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
          color: GREY_COLORS.grey6,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          color: BRAND_COLORS.grey,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        LinkComponent: LinkBehavior,
      },
      styleOverrides: {
        root: {
          borderRadius: 100,
          padding: "10px 24px",
        },
        containedPrimary: {
          "backgroundColor": BRAND_COLORS.yellow,
          "color": BRAND_COLORS.purple,
          "&:hover": {
            backgroundColor: BRAND_COLORS.yellow,
          },
          "&:disabled": {
            backgroundColor: GREY_COLORS.grey3,
          },
        },
        containedSecondary: {
          "backgroundColor": BRAND_COLORS.purple,
          "color": GREY_COLORS.white,
          "&:hover": {
            backgroundColor: BRAND_COLORS.purple,
          },
          "&:disabled": {
            backgroundColor: GREY_COLORS.grey3,
          },
        },
        outlinedPrimary: {
          "backgroundColor": "unset",
          "&:hover": {
            backgroundColor: BRAND_COLORS.lightYellow,
          },
          "&:disabled": {
            backgroundColor: "unset",
          },
        },
      },
    },
    MuiTable: {
      defaultProps: {
        stickyHeader: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: GREY_COLORS.white,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "10px 16px",
          borderColor: GREY_COLORS.grey3,
        },
        head: {
          padding: "16px",
          backgroundColor: GREY_COLORS.white,
          fontSize: 16, //Subtitle1Bold
          fontWeight: 700, //Subtitle1Bold
        },
        body: {
          fontSize: 16, //Subtitle1
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: GREY_COLORS.grey6,
          fontSize: 14, // body2
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: "20px 25px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          "> :not(:first-of-type)": {
            marginLeft: 20,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: GREY_COLORS.grey3,
          backgroundColor: GREY_COLORS.white,
          height: "40px",
        },
        grouped: {
          "border": 0,
          "margin": "4px",
          "padding": "6px 12px",
          ":not(:first-of-type)": {
            borderRadius: DEFAULT_BORDER_RADIUS,
          },
          ":not(:last-of-type)": {
            borderRadius: DEFAULT_BORDER_RADIUS,
          },
          ":first-of-type": {
            borderRadius: DEFAULT_BORDER_RADIUS,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "textTransform": "none",
          "&.Mui-selected": {
            "backgroundColor": BRAND_COLORS.purple,
            "color": GREY_COLORS.white,
            "> p": {
              fontWeight: 700, //fontWeightBold
            },
            ":hover": {
              backgroundColor: BRAND_COLORS.lightPurple,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        standard: {
          padding: "20px 16px",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 52,
          height: 32,
          padding: 0,
          borderRadius: 32 / 2,
        },
        track: {
          opacity: 1,
          backgroundColor: GREY_COLORS.grey4,
        },
        thumb: {
          width: 24,
          height: 24,
          boxShadow: "none",
        },
        switchBase: {
          "right": "28%",
          "bottom": 0,
          "top": 0,
          "left": "auto",
          "&.Mui-checked": {
            "color": GREY_COLORS.white,
            "& .MuiSwitch-thumb:before": {
              content: "''",
              position: "absolute",
              width: "100%",
              height: "100%",
              left: 0,
              top: 0,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundImage: CHECK_ICON(GREY_COLORS.grey6),
            },
            "& + .MuiSwitch-track": {
              opacity: 1,
              backgroundColor: GREY_COLORS.grey6,
            },
          },
          ":hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          // Use existing space / prevents shifting content below field
          marginTop: 0,
          height: 0,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-colorWarning": {
            backgroundColor: SIGNALS_COLORS.warningLight,
            color: SIGNALS_COLORS.warningDark,
          },
          "&.MuiChip-colorSuccess": {
            backgroundColor: SIGNALS_COLORS.successLight,
            color: SIGNALS_COLORS.successDark,
          },
          "&.MuiChip-colorError": {
            backgroundColor: SIGNALS_COLORS.errorLight,
            color: SIGNALS_COLORS.errorDark,
          },
          "&.Mui-disabled": {
            backgroundColor: GREY_COLORS.grey3,
            color: GREY_COLORS.grey5,
            opacity: 1,
          },
        },
      },
    },
  },
});

declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    purple: string;
    lightPurple: string;
    veryLightPurple: string;
    lightYellow: string;
    grey: string;
    darkGrey: string;
    yellow: string;
    white: string;
    grey1: string;
    grey2: string;
    grey3: string;
    grey4: string;
    grey5: string;
    grey6: string;
    grey7: string;
    error: string;
    warning: string;
    success: string;
    hyperLink: string;
    lightBackground: string;
    pink: string;
    red: string;
  }
  interface CommonColorsOptions {
    purple?: string;
    lightPurple?: string;
    veryLightPurple?: string;
    lightYellow?: string;
    grey?: string;
    darkGrey?: string;
    yellow?: string;
    white?: string;
    grey1?: string;
    grey2?: string;
    grey3?: string;
    grey4?: string;
    grey5?: string;
    grey6?: string;
    grey7?: string;
    error?: string;
    warning?: string;
    success?: string;
    hyperLink?: string;
    lightBackground?: string;
    pink?: string;
    red?: string;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    subtitle1Bold: CSSProperties;
    body1Bold?: CSSProperties;
    body2Medium: CSSProperties;
    body2Bold: CSSProperties;
    captionMedium: CSSProperties;
    hyperLinkBig: CSSProperties;
    hyperLinkSmall: CSSProperties;
    graphLabel: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subtitle1Bold?: CSSProperties;
    body1Bold?: CSSProperties;
    body2Medium?: CSSProperties;
    body2Bold?: CSSProperties;
    captionMedium?: CSSProperties;
    hyperLinkBig?: CSSProperties;
    hyperLinkSmall?: CSSProperties;
    graphLabel?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    subtitle1Bold: true;
    body1Bold: true;
    body2Medium: true;
    body2Bold: true;
    captionMedium: true;
    hyperLinkBig: true;
    hyperLinkSmall: true;
    graphLabel: true;
  }
}
