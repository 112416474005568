export const FEROVINUM_CONTACT_URL = "https://www.ferovinum.com/contact";
export const EXISTING_STOCK_NEW_DEAL_REQUEST_CSV_TEMPLATE_URL =
  "https://com-hx-ferovinum-assets.s3.eu-west-2.amazonaws.com/files/existing-stock-new-deal-request-products-csv-template.csv";
export const NEW_STOCK_NEW_DEAL_REQUEST_CSV_TEMPLATE_URL =
  "https://com-hx-ferovinum-assets.s3.eu-west-2.amazonaws.com/files/new-stock-new-deal-request-products-csv-template.csv";
export const STOCK_SYNC_CSV_TEMPLATE_URL =
  "https://com-hx-ferovinum-assets.s3.eu-west-2.amazonaws.com/files/stock-sync-csv-template.csv";
export const PURCHASER_UPLOAD_TEMPLATE =
  "https://com-hx-ferovinum-assets.s3.eu-west-2.amazonaws.com/files/purchaser-upload-template.csv";
export const PURCHASER_UPLOAD_INSTRUCTIONS =
  "https://com-hx-ferovinum-assets.s3.eu-west-2.amazonaws.com/files/purchaser-upload-instructions.txt";
export const PRODUCT_PRICE_UPLOAD_CSV_TEMPLATE_URL =
  "https://com-hx-ferovinum-assets.s3.eu-west-2.amazonaws.com/files/product-prices-upload-template.csv";
