import React, { useCallback, useState } from "react";
import { Loader } from "components/widgets/loader/loader";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../../../app/app-routes";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { StorageLocStockAdjustmentConfirmationPageView } from "./storage-loc-stock-adjustment-confirmation-page-view";
import { StockAdjustmentsFlowState } from "../storage-loc-stock-adjustment-setup/storage-loc-stock-adjustment-setup-page";
import { assertNotUndefined } from "utils/hx/util/types";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useSelectedStorageLocation } from "../../../../layouts/portal-page-layout/portal-page";
import {
  NegativeStockAdjustmentPreview_WithoutReimbursementView,
  PreviewNegativeStockAdjustmentSalesResp,
} from "adl-gen/ferovinum/app/api";
import { StockAdjustmentToCreate } from "adl-gen/ferovinum/app/workflow";
import { ProductId } from "adl-gen/ferovinum/app/db";
import {
  NonReimbursedStockAdjustment,
  NonReimbursedStockAdjustments,
  StockAdjustmentDetailsView,
} from "../../../../../hooks/use-stock-adjustment";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { isLoaded } from "utils/utility-types";
import { ts } from "utils/date-utils";
import { groupBy } from "lodash";
import { add, numberOfUnitsForUnitType } from "utils/model-utils";
import { adlMapToMap } from "utils/adl-utils";
import { titleCase } from "utils/conversion-utils";

export const StorageLocStockAdjustmentConfirmationPage = () => {
  const location = useLocation();
  const history = useHistory();
  const stockAdjustmentFlowState = location.state as StockAdjustmentsFlowState | undefined;
  const service = useAppService();
  const selectedStorageLocationId = assertNotUndefined(useSelectedStorageLocation()).id;
  const [showAlert] = useAlert();
  const [confirmedStockAdjustment, setConfirmedStockAdjustment] = useState<boolean>(false);

  const onConfirm = useCallback(async () => {
    //Note: These are safe to assume as defined on this callback, the page will have redirected to the index otherwise
    const state = assertNotUndefined(stockAdjustmentFlowState);
    try {
      let stockAdjustmentId: string;
      if (state.variant === "positiveStockAdjustment") {
        const adjustmentsToCreate = [...state.stockAdjustments.entries()].flatMap(([product, adjustments]) =>
          adjustments.map(adjustment => ({
            productId: product.id,
            quantity: adjustment.numberOfUnits,
          })),
        );
        const resp = await service.createPositiveStockAdjustment({
          storageLocationId: selectedStorageLocationId,
          adjustmentsToCreate: adjustmentsToCreate,
          comments: state.reason || null,
          referenceNumber: state.referenceNumber || null,
        });
        if (resp.kind === "success") {
          stockAdjustmentId = resp.value;
        } else {
          throw new Error(resp.kind);
        }
      } else {
        const adjustmentsToCreate = [...state.stockAdjustments.entries()].flatMap(([product, adjustments]) =>
          toStockAdjustmentsToCreate(product.id, adjustments),
        );
        const resp = await service.createNegativeStockAdjustment({
          storageLocationId: selectedStorageLocationId,
          adjustmentsToCreate,
          comments: state.reason || null,
          referenceNumber: state.referenceNumber || null,
          reimbursingCosts: assertNotUndefined(state.reimbursed),
        });
        if (resp.kind === "success") {
          stockAdjustmentId = resp.value;
        } else {
          throw new Error(resp.kind);
        }
      }
      setConfirmedStockAdjustment(true);

      // Note: if the users manually reloads the page it will take them to the correct sale order page
      // (use native window.location to avoid react-router redirect)
      window.history.replaceState(
        null,
        "Stock adjustment details",
        `${AppRoutes.StorageLocStockAdjustmentDetails}/${state.variant}/${encodeURIComponent(stockAdjustmentId)}`,
      );
    } catch (e: unknown) {
      void showAlert({
        title: "Error creating stock adjustment, please try again or contact Ferovinum.",
        body: e instanceof Error ? titleCase(e.message) : String(e),
      });
    }
  }, [selectedStorageLocationId, service, showAlert, stockAdjustmentFlowState]);

  const loadStockAdjustmentData = useCallback(async (): Promise<StockAdjustmentDetailsView> => {
    const state = assertNotUndefined(stockAdjustmentFlowState);
    if (state.variant === "positiveStockAdjustment") {
      return {
        kind: "positiveStockAdjustment",
        stockAdjustments: state.stockAdjustments,
        referenceNumber: state.referenceNumber || "",
        reason: state.reason || "",
        createdAt: ts(),
        topLevelUnitType: state.topLevelUnitType,
        id: "",
      };
    }

    const preview: PreviewNegativeStockAdjustmentSalesResp = await service.previewNegativeStockAdjustment({
      storageLocationId: selectedStorageLocationId,
      reimbursingCosts: state.reimbursed || false,
      adjustmentsToCreate: [...state.stockAdjustments.entries()].flatMap(([product, adjustments]) =>
        toStockAdjustmentsToCreate(product.id, adjustments),
      ),
    });

    if (preview.kind !== "success") {
      throw new Error(preview.kind);
    }

    return {
      kind: "negativeStockAdjustment",
      stockAdjustments:
        preview.value.kind === "reimburseView"
          ? {
              kind: "reimbursement",
              value: {
                adjustments: adlMapToMap(preview.value.value.previews),
                grossReimbursementCost: preview.value.value.grossReimbursementCost,
                state: "created",
              },
            }
          : {
              kind: "noReimbursement",
              value: toNonReimbursedStockAdjustments(preview.value.value.stockAdjustments),
            },
      referenceNumber: state.referenceNumber || "",
      reason: state.reason || "",
      createdAt: ts(),
      topLevelUnitType: state.topLevelUnitType,
      id: "",
    };
  }, [selectedStorageLocationId, service, stockAdjustmentFlowState]);

  const [loadingStockAdjustmentData] = useLoadingDataState(loadStockAdjustmentData);

  const navigateBackToSettings = useCallback(() => {
    const state = assertNotUndefined(stockAdjustmentFlowState);
    history.push(AppRoutes.StorageLocStockAdjustmentSettings, state);
  }, [history, stockAdjustmentFlowState]);

  const navigateBackToSetup = useCallback(() => {
    const state = assertNotUndefined(stockAdjustmentFlowState);
    history.push(AppRoutes.StorageLocStockAdjustmentSetup, state);
  }, [history, stockAdjustmentFlowState]);

  if (stockAdjustmentFlowState === undefined) {
    return <Redirect to={AppRoutes.Index} />;
  } else {
    return (
      <Loader loadingStates={[loadingStockAdjustmentData]} fullScreen>
        {isLoaded(loadingStockAdjustmentData) && (
          <StorageLocStockAdjustmentConfirmationPageView
            stockAdjustmentData={loadingStockAdjustmentData.value}
            navigateBackToSettings={navigateBackToSettings}
            navigateBackToSetup={navigateBackToSetup}
            onConfirm={onConfirm}
            confirmedStockAdjustment={confirmedStockAdjustment}
          />
        )}
      </Loader>
    );
  }
};

function toStockAdjustmentsToCreate(
  productId: ProductId,
  stockAdjustments: NonReimbursedStockAdjustment[],
): StockAdjustmentToCreate[] {
  return stockAdjustments.map(stockAdjustment => ({
    stockAdjustmentEntity:
      stockAdjustment.rotationNumber === null
        ? {
            kind: "productId",
            value: productId,
          }
        : {
            kind: "rotationNumber",
            value: stockAdjustment.rotationNumber,
          },
    numberOfUnits: stockAdjustment.numberOfUnits,
  }));
}

function toNonReimbursedStockAdjustments(
  previews: NegativeStockAdjustmentPreview_WithoutReimbursementView["value"]["stockAdjustments"],
): NonReimbursedStockAdjustments {
  return new Map(
    previews.map(({ key: product, value }) => {
      const groupedByRotationNumber = groupBy(value, adjustment => adjustment.rotationNumber);
      const zeroUnits = numberOfUnitsForUnitType(product.value.unitType, 0);
      const simpleStockAdjustments: NonReimbursedStockAdjustment[] = Object.entries(groupedByRotationNumber).map(
        ([rotationNumber, adjustments]) => ({
          rotationNumber: rotationNumber === null ? null : rotationNumber,
          numberOfUnits: adjustments.reduce((acc, adjustment) => add(acc, adjustment.units), zeroUnits),
        }),
      );

      return [product, simpleStockAdjustments];
    }),
  );
}
