/* @generated from adl */
import { declResolver, ScopedDecl } from "./runtime/adl";
import { _AST_MAP as adminui_api } from "./adminui/api";
import { _AST_MAP as adminui_db } from "./adminui/db";
import { _AST_MAP as common } from "./common";
import { _AST_MAP as common_config_aws } from "./common/config/aws";
import { _AST_MAP as common_config_db } from "./common/config/db";
import { _AST_MAP as common_config_flyway } from "./common/config/flyway";
import { _AST_MAP as common_config_frontend } from "./common/config/frontend";
import { _AST_MAP as common_config_log } from "./common/config/log";
import { _AST_MAP as common_db } from "./common/db";
import { _AST_MAP as common_http } from "./common/http";
import { _AST_MAP as common_strings } from "./common/strings";
import { _AST_MAP as common_tabular } from "./common/tabular";
import { _AST_MAP as common_ui } from "./common/ui";
import { _AST_MAP as ferovinum_app_adminapi } from "./ferovinum/app/adminapi";
import { _AST_MAP as ferovinum_app_annotations } from "./ferovinum/app/annotations";
import { _AST_MAP as ferovinum_app_api } from "./ferovinum/app/api";
import { _AST_MAP as ferovinum_app_common } from "./ferovinum/app/common";
import { _AST_MAP as ferovinum_app_config } from "./ferovinum/app/config";
import { _AST_MAP as ferovinum_app_db } from "./ferovinum/app/db";
import { _AST_MAP as ferovinum_app_dealextensionrequests } from "./ferovinum/app/dealextensionrequests";
import { _AST_MAP as ferovinum_app_deliveryoptions } from "./ferovinum/app/deliveryoptions";
import { _AST_MAP as ferovinum_app_invoicing } from "./ferovinum/app/invoicing";
import { _AST_MAP as ferovinum_app_messaging } from "./ferovinum/app/messaging";
import { _AST_MAP as ferovinum_app_nompurchaser } from "./ferovinum/app/nompurchaser";
import { _AST_MAP as ferovinum_app_procurement } from "./ferovinum/app/procurement";
import { _AST_MAP as ferovinum_app_productsaleorder } from "./ferovinum/app/productsaleorder";
import { _AST_MAP as ferovinum_app_reporting } from "./ferovinum/app/reporting";
import { _AST_MAP as ferovinum_app_stocksync } from "./ferovinum/app/stocksync";
import { _AST_MAP as ferovinum_app_systemcommand } from "./ferovinum/app/systemcommand";
import { _AST_MAP as ferovinum_app_types } from "./ferovinum/app/types";
import { _AST_MAP as ferovinum_app_uiconfig } from "./ferovinum/app/uiconfig";
import { _AST_MAP as ferovinum_app_views } from "./ferovinum/app/views";
import { _AST_MAP as ferovinum_app_workflow } from "./ferovinum/app/workflow";
import { _AST_MAP as sys_adlast } from "./sys/adlast";
import { _AST_MAP as sys_annotations } from "./sys/annotations";
import { _AST_MAP as sys_types } from "./sys/types";

export const ADL: { [key: string]: ScopedDecl } = {
  ...adminui_api,
  ...adminui_db,
  ...common,
  ...common_config_aws,
  ...common_config_db,
  ...common_config_flyway,
  ...common_config_frontend,
  ...common_config_log,
  ...common_db,
  ...common_http,
  ...common_strings,
  ...common_tabular,
  ...common_ui,
  ...ferovinum_app_adminapi,
  ...ferovinum_app_annotations,
  ...ferovinum_app_api,
  ...ferovinum_app_common,
  ...ferovinum_app_config,
  ...ferovinum_app_db,
  ...ferovinum_app_dealextensionrequests,
  ...ferovinum_app_deliveryoptions,
  ...ferovinum_app_invoicing,
  ...ferovinum_app_messaging,
  ...ferovinum_app_nompurchaser,
  ...ferovinum_app_procurement,
  ...ferovinum_app_productsaleorder,
  ...ferovinum_app_reporting,
  ...ferovinum_app_stocksync,
  ...ferovinum_app_systemcommand,
  ...ferovinum_app_types,
  ...ferovinum_app_uiconfig,
  ...ferovinum_app_views,
  ...ferovinum_app_workflow,
  ...sys_adlast,
  ...sys_annotations,
  ...sys_types,
};

export const RESOLVER = declResolver(ADL);
