import { AppService } from "adl-gen/app-service";
import { WithDbId } from "adl-gen/common/db";
import { SaleOrder } from "adl-gen/ferovinum/app/db";
import assertNever from "assert-never";
import { Loader } from "components/widgets/loader/loader";
import React, { useCallback, useState } from "react";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { assertNotUndefined } from "utils/hx/util/types";
import { isLoaded } from "utils/utility-types";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { OrganisationSaleOrdersPageView } from "./organisation-sale-orders-page-view";

const saleOrdersFilters = ["In Progress", "Completed"] as const;
export type SaleOrdersFilter = (typeof saleOrdersFilters)[number];

export const getSaleOrderStatus = (saleOrder: SaleOrder) => {
  if (saleOrder.orderStatusEvents.length === 0) {
    return "To be invoiced";
  } else {
    const latestState = saleOrder.orderStatusEvents[saleOrder.orderStatusEvents.length - 1];

    switch (latestState.status) {
      case "canceled":
        return "Cancelled";
      case "deliveryOrderRequested":
        return "Delivery order requested";
      case "deliveryOrderSigned":
        return "Delivery order signed";
      case "draftInvoiced":
        return "Preparing invoice";
      case "invoiced":
        return saleOrder.usesSettlementCredit ? "Preparing to release stock" : "Awaiting payment";
      case "paid":
        return "Payment received";
      case "released":
        return saleOrder.usesSettlementCredit ? "Awaiting payment" : "Released";
      default:
        assertNever(latestState.status);
    }
  }
};

export function saleOrderIsComplete(saleOrderWithId: WithDbId<SaleOrder>) {
  const saleOrder = saleOrderWithId.value;
  const currentStatus = getSaleOrderStatus(saleOrder);

  // TODO: Update to include only delivered lcb orders
  if (saleOrder.usesSettlementCredit) {
    return currentStatus === "Payment received" || currentStatus === "Cancelled";
  }
  return currentStatus === "Released" || currentStatus === "Cancelled";
}

export const OrganisationSaleOrdersPage = () => {
  const [filter, setFilter] = useState<SaleOrdersFilter>("In Progress");

  const service: AppService = useAppService();
  const selectedOrgId = assertNotUndefined(useSelectedOrgId());

  const loadSaleOrders = useCallback(async () => {
    const saleOrders = await service.getRepurchaseSaleOrders({ organisationId: selectedOrgId });
    switch (filter) {
      case "In Progress":
        return saleOrders.filter(so => !saleOrderIsComplete(so));
      case "Completed":
        return saleOrders.filter(saleOrderIsComplete);
    }
  }, [filter, selectedOrgId, service]);

  const [loadingSaleOrders] = useLoadingDataState(loadSaleOrders);

  const handleChangeTab = (value: SaleOrdersFilter) => {
    if (value !== null) {
      setFilter(value);
    }
  };
  return (
    <Loader loadingStates={[loadingSaleOrders]} fullScreen>
      {isLoaded(loadingSaleOrders) && (
        <OrganisationSaleOrdersPageView saleOrders={loadingSaleOrders.value} setFilter={handleChangeTab} />
      )}
    </Loader>
  );
};
