import React, { FC } from "react";
import { NewDealRequestState, NewDealRequestStateEvent, NewDealRequestType } from "adl-gen/ferovinum/app/db";
import { LocalDate } from "adl-gen/common";
import { formatLocalDate } from "utils/date-utils";
import {
  NewDealRequestStep,
  NewDealRequestStateStepData,
  NewDealRequestStateStepperProps,
  NewDealRequestStateStepper,
} from "components/widgets/new-deal-requests/new-deal-request-state-stepper/new-deal-request-state-stepper";

export type OrgNewDealRequestState = NewDealRequestState | "dealCreated";

/// Steps labelled in time from the organisation point of view
export const ORGANISATION_STEPS_BY_STATE: Record<OrgNewDealRequestState, NewDealRequestStep<OrgNewDealRequestState>> = {
  newDeal: {
    state: "newDeal",
    label: "Purchase order requested",
  },
  feroAccepted: {
    state: "feroAccepted",
    label: "Contract note provided by Ferovinum",
  },
  feroRejected: {
    state: "feroRejected",
    label: "Purchase order rejected",
  },
  orgRejected: {
    state: "orgRejected",
    label: "Contract note rejected",
  },
  cancelled: {
    state: "cancelled",
    label: "Purchase order cancelled",
  },
  orgAccepted: {
    state: "orgAccepted",
    label: "Contract note accepted",
  },
  depositInvoiced: {
    state: "depositInvoiced",
    label: "Deposit invoice sent",
  },
  depositPaid: {
    state: "depositPaid",
    label: "Deposit paid",
  },
  depositPaymentReceived: {
    state: "depositPaymentReceived",
    label: "Deposit received",
  },
  poCreated: {
    state: "poCreated",
    label: "Purchase order sent to supplier",
  },
  poAccepted: {
    state: "poAccepted",
    label: "Purchase order accepted by supplier",
  },
  collectionRequested: {
    state: "collectionRequested",
    label: "Earliest collection date provided by supplier",
  },
  collectionAccepted: {
    state: "collectionAccepted",
    label: "Collection date confirmed by carrier",
  },
  collectionConfirmed: {
    state: "collectionConfirmed",
    label: "Products collected by carrier",
  },
  carrierDeliveryConfirmed: {
    state: "carrierDeliveryConfirmed",
    label: "Delivery to warehouse confirmed by carrier",
  },
  warehouseDeliveryConfirmed: {
    state: "warehouseDeliveryConfirmed",
    label: "Landing completed by warehouse",
  },
  dealCreated: {
    state: "dealCreated",
    label: "Products are ready for purchasing",
  },
  stockTransferred: {
    state: "stockTransferred",
    label: "Warehouse transferred stock ownership to Ferovinum",
  },
  paymentTransferred: {
    state: "paymentTransferred",
    label: "Payment transferred to the client",
  },
};

export function newDealRequestStateEventsToOrgStepsData(
  events: NewDealRequestStateEvent[],
  dealCreatedTimestamp: number | null,
  supplierEarliestCollectionDate: LocalDate | null,
  carrierEarliestCollectionDate: LocalDate | null,
): Partial<Record<OrgNewDealRequestState, NewDealRequestStateStepData>> {
  const result = events.reduce(
    (m, e) => {
      switch (e.state) {
        case "collectionRequested": {
          m[e.state] = {
            instant: e.time,
            description: supplierEarliestCollectionDate
              ? `Earliest collection ${formatLocalDate(supplierEarliestCollectionDate)}`
              : undefined,
          };
          break;
        }
        case "collectionAccepted": {
          m[e.state] = {
            instant: e.time,
            description: carrierEarliestCollectionDate
              ? `Confirmed collection ${formatLocalDate(carrierEarliestCollectionDate)}`
              : undefined,
          };
          break;
        }
        default: {
          m[e.state] = { instant: e.time };
          break;
        }
      }
      return m;
    },
    {} as Partial<Record<OrgNewDealRequestState, NewDealRequestStateStepData>>,
  );
  if (dealCreatedTimestamp !== null) {
    result.dealCreated = { instant: dealCreatedTimestamp };
  }
  return result;
}

const FERO_REJECTED_STEPS = () => getOrgStepsByStates(["newDeal", "feroRejected"]);
const ORG_REJECTED_STEPS = () => getOrgStepsByStates(["newDeal", "feroAccepted", "orgRejected"]);
const NEW_STOCK_STEPS = () =>
  getOrgStepsByStates([
    "newDeal",
    "feroAccepted",
    "orgAccepted",
    "depositInvoiced",
    "depositPaid",
    "depositPaymentReceived",
    "poCreated",
    "poAccepted",
    "collectionRequested",
    "collectionAccepted",
    "collectionConfirmed",
    "carrierDeliveryConfirmed",
    "warehouseDeliveryConfirmed",
    "dealCreated",
  ]);

const EXISTING_STOCK_STEPS = () =>
  getOrgStepsByStates([
    "newDeal",
    "feroAccepted",
    "orgAccepted",
    "stockTransferred",
    "paymentTransferred",
    "dealCreated",
  ]);
const getOrgStepsByStates = (states: OrgNewDealRequestState[]) => {
  return states.map(s => ORGANISATION_STEPS_BY_STATE[s]);
};

function getProcurementSteps(variant: NewDealRequestType) {
  return (activeState: NewDealRequestState) => {
    switch (activeState) {
      case "feroRejected":
        return FERO_REJECTED_STEPS();
      case "orgRejected":
        return ORG_REJECTED_STEPS();
      default:
        if (variant === "newStock") {
          return NEW_STOCK_STEPS();
        } else {
          return EXISTING_STOCK_STEPS();
        }
    }
  };
}

export type OrganisationNewDealRequestStateStepperProps = Omit<
  NewDealRequestStateStepperProps<OrgNewDealRequestState>,
  "getNewDealRequestStateSteps"
> & {
  variant: NewDealRequestType;
};
export const OrganisationNewDealRequestStateStepper: FC<OrganisationNewDealRequestStateStepperProps> = ({
  variant,
  ...props
}) => {
  // @ts-ignore
  return <NewDealRequestStateStepper {...props} getNewDealRequestStateSteps={getProcurementSteps(variant)} />;
};
