import React from "react";
import { Link } from "components/widgets/link/link";
import { useTermsDialog } from "components/context/global-dialog/use-dialog";
import { DealTermsView } from "adl-gen/ferovinum/app/views";

export interface TermsLinkProps {
  dealTermsView: DealTermsView;
  clickText?: string;
}

export const TermsLink: React.FC<TermsLinkProps> = ({ dealTermsView, clickText }) => {
  const { showTermsDialog } = useTermsDialog();
  const additionalTerms = dealTermsView.additionalTerms ? dealTermsView.additionalTerms : undefined;
  const linkText = clickText ? clickText : "Terms & Conditions";
  return (
    dealTermsView && (
      <Link
        variant="body2"
        style={{ color: "#2D2940" }}
        onClick={() =>
          showTermsDialog({
            terms: [dealTermsView.dealTerms.value.general, dealTermsView.dealTerms.value.fsp],
            markdownAdditionalTerms: additionalTerms,
            masterAgreementDate: dealTermsView.masterAgreementDate,
          })
        }>
        {linkText}
      </Link>
    )
  );
};
