import React, { useCallback, useMemo, useState } from "react";
import { assertNotUndefined } from "utils/hx/util/types";
import { CarrierAdvanceNewDealAction, NewDealRequestsForCarrierFilterType } from "adl-gen/ferovinum/app/api";
import { NewDealRequestCarrierView, ProductLineItemView } from "adl-gen/ferovinum/app/procurement";
import { isLoaded, LoadingValue } from "utils/utility-types";
import { Loader } from "components/widgets/loader/loader";
import {
  Alert,
  Box,
  Card,
  CardContent,
  List,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../../app/app-routes";
import { useAppService } from "../../../../hooks/use-app-service";
import {
  CarrierNewDealRequestState,
  CarrierNewDealRequestStateStepper,
  CarrierNewDealRequestStateStepperProps,
  procurementEventsToCarrierStepsData,
} from "../../../widgets/new-deal-requests/procurement-stepper/carrier-new-deal-request-state-stepper";
import { formatDateToISO8601, now } from "utils/date-utils";
import { DateAction } from "../../../widgets/new-deal-requests/date-action/date-action";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { isNull, negate } from "lodash";
import { InfoField } from "components/widgets/info-field/info-field";
import { PortalPageContent } from "../../../layouts/portal-page-content/portal-page-content";
import { Currency } from "adl-gen/ferovinum/app/db";
import { formatNumberOfUnits } from "utils/model-utils";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import { PortalPageContentHeader } from "../../../layouts/portal-page-content-header/portal-page-content-header";
import { TabbedPage } from "components/library/widgets/tabbed-page";

export interface CarrierCollectionOrdersPageViewProps {
  filter: NewDealRequestsForCarrierFilterType;
  loadingDeals: LoadingValue<NewDealRequestCarrierView[]>;
  refreshDeals(): void;
}

export const CarrierCollectionOrdersPageView: React.FC<CarrierCollectionOrdersPageViewProps> = ({
  filter,
  loadingDeals,
  refreshDeals,
}) => {
  const history = useHistory();

  const handleTabChange = ({ key }: { key?: string }) => {
    history.push(`${AppRoutes.CarrierRequests}/${key}`);
  };

  return (
    <PortalPageContent header={<PortalPageContentHeader title="Collection Orders" />}>
      <TabbedPage
        defaultTab={filter}
        handleTabChange={handleTabChange}
        tabs={[
          {
            label: "In Progress",
            key: "inProgress",
            content: (
              <CollectionOrderSection type={"in progress"} loadingDeals={loadingDeals} refreshDeals={refreshDeals} />
            ),
          },
          {
            label: "Completed",
            key: "completed",
            content: (
              <CollectionOrderSection type={"completed"} loadingDeals={loadingDeals} refreshDeals={refreshDeals} />
            ),
          },
        ]}
      />
    </PortalPageContent>
  );
};

const CollectionOrderSection = ({
  loadingDeals,
  type,
  refreshDeals,
}: {
  type: "completed" | "in progress";
  loadingDeals: LoadingValue<NewDealRequestCarrierView[]>;
  refreshDeals(): void;
}) => {
  return (
    <Loader loadingStates={[loadingDeals]} fullScreen>
      {isLoaded(loadingDeals) && loadingDeals.value.length > 0 && (
        <Stack spacing={2}>
          {loadingDeals.value.map(deal => (
            <NewDealRequestCarrierActionCard deal={deal} key={deal.newDealRequestId} refreshDeals={refreshDeals} />
          ))}
        </Stack>
      )}
      {isLoaded(loadingDeals) && loadingDeals.value.length === 0 && (
        <Alert severity="info">You currently have no {type} orders.</Alert>
      )}
    </Loader>
  );
};

const TabPanel: React.FC<{ tabIndex: number; value: number }> = ({ tabIndex, value, children }) => {
  if (value === tabIndex) {
    return <Box>{children}</Box>;
  }

  return null;
};

const InfoLabelCell: React.FC = ({ children }) => <TableCell sx={{ width: "150px" }}>{children}</TableCell>;

const NewDealRequestCarrierActionCard: React.FC<{
  deal: NewDealRequestCarrierView;
  refreshDeals(): void;
}> = ({ deal, refreshDeals }) => {
  const [tab, setTab] = useState<number>(0);
  const theme = useTheme();
  const [showDateError, setShowDateError] = useState<boolean>(false);

  const handleTabChange = useCallback((_, value) => setTab(value), [setTab]);
  const appService = useAppService();

  const dealState = useMemo<CarrierNewDealRequestState>(() => {
    if (
      deal.state === "collectionRequested" ||
      deal.state === "collectionAccepted" ||
      deal.state === "collectionConfirmed"
    ) {
      return deal.state;
    } else if (deal.state === "carrierDeliveryConfirmed" || deal.state === "warehouseDeliveryConfirmed") {
      return deal.carrierPaid ? "carrierIsPaid" : "carrierDeliveryConfirmed";
    } else {
      throw new Error(`Unhandled carrier state: ${deal.state}`);
    }
  }, [deal]);

  const onClick = useCallback(
    async (date?: Date) => {
      let action: CarrierAdvanceNewDealAction;
      if (deal.state === "collectionRequested") {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        action = { kind: "acceptOrder", value: { earliestCollectionDate: formatDateToISO8601(date!) } };
      } else if (deal.state === "collectionAccepted") {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        action = { kind: "confirmCollection", value: { deliveryDate: formatDateToISO8601(date!) } };
      } else if (deal.state === "collectionConfirmed") {
        action = { kind: "confirmDelivery" };
      } else {
        throw new Error("Unhandled action: " + deal.state);
      }

      try {
        await appService.carrierAdvanceNewDeal({
          newDealRequestId: deal.newDealRequestId,
          action,
          actionTime: now(),
        });
        refreshDeals();
      } catch (e) {}
    },
    [deal, appService, refreshDeals],
  );

  const CarrierAction = () => {
    if (dealState === "collectionRequested") {
      return (
        <DateAction
          fieldLabel={"Earliest date of collection"}
          buttonLabel={"Accept Order"}
          onSubmit={onClick}
          minDate={new Date(deal.supplierCollectionEta)}
          showError={setShowDateError}
        />
      );
    } else if (dealState === "collectionAccepted") {
      return (
        <DateAction
          fieldLabel={"Estimated delivery date"}
          buttonLabel={"Confirm Collection"}
          minDate={new Date(assertNotUndefined(deal.carrierCollectionEta ?? undefined))}
          onSubmit={onClick}
        />
      );
    } else if (dealState === "collectionConfirmed") {
      return <LoadingActionButton onClick={async () => await onClick()}>Confirm Delivery</LoadingActionButton>;
    }

    return null;
  };

  const stepsData: CarrierNewDealRequestStateStepperProps["stepsData"] = procurementEventsToCarrierStepsData(
    deal.newDealRequestEvents,
    deal.carrierPaid,
    deal.supplierCollectionEta,
    deal.carrierCollectionEta,
  );

  const TabView = () => (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <InfoLabelCell>
                <Typography fontWeight={"bold"}>Deal Number:</Typography>
              </InfoLabelCell>
              <TableCell>
                <Typography align={"left"}>{deal.dealNumber}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <InfoLabelCell>
                <Typography fontWeight={"bold"}>Supplier:</Typography>
              </InfoLabelCell>
              <TableCell>
                <Typography align={"left"}>{deal.supplier.name}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <InfoLabelCell>
                <Typography fontWeight={"bold"}>Destination:</Typography>
              </InfoLabelCell>
              <TableCell>
                <Typography align={"left"}>{deal.storageLocationName}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label={"Progress"} tabIndex={0} />
          <Tab label={"Details"} tabIndex={1} />
        </Tabs>
      </Box>
      <Box>
        <TabPanel tabIndex={0} value={tab}>
          <CarrierNewDealRequestStateStepper currentState={dealState} stepsData={stepsData} />
        </TabPanel>
        <TabPanel tabIndex={1} value={tab}>
          <NewDealRequestCarrierDetails deal={deal} />
        </TabPanel>
      </Box>
    </>
  );

  return (
    <Card>
      <CardContent>
        <Stack spacing={3}>
          <Box sx={{ paddingBottom: theme.spacing(3), borderBottom: 1, borderColor: "divider" }}>
            {deal.state === "collectionRequested" ? <NewDealRequestCarrierDetails deal={deal} /> : <TabView />}
          </Box>
          <Box>
            <CarrierAction />
          </Box>
          {showDateError && (
            <Typography fontWeight={"normal"} color={"red"}>
              Collection date must be on or after supplier earliest collection date
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

interface NewDealRequestCarrierDetailsProps {
  deal: NewDealRequestCarrierView;
}

const NewDealRequestCarrierDetails: React.FC<NewDealRequestCarrierDetailsProps> = ({ deal }) => {
  const { supplier } = deal;
  const streetAddress = [supplier.addressLine1, supplier.addressLine2].filter(negate(isNull)).join(", ");

  return (
    <Box>
      <Stack spacing={3}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography fontWeight="bold">Supplier + Contact:</Typography>
                </TableCell>
                <TableCell>
                  <List>
                    <InfoField label={"Name"} value={supplier.name} />
                    <InfoField
                      label={"Contact"}
                      value={`${supplier.contactName} - M: ${supplier.contactMobile} E: ${supplier.contactEmail}`}
                    />
                    <InfoField
                      label={"Address"}
                      value={`${streetAddress}, ${supplier.town}, ${supplier.country} ${supplier.postCode}`}
                    />
                  </List>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography fontWeight={"bold"}>Earliest Pick-Up</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={"normal"}>{deal.supplierCollectionEta}</Typography>
                </TableCell>
              </TableRow>
              {deal.state !== "collectionRequested" && (
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Specified Pick-Up</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={"normal"}>{deal.carrierCollectionEta}</Typography>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>
                  <Typography fontWeight={"bold"}>Destination</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={"normal"}>{deal.storageLocationName}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <ShipmentProductListing productsInShipment={deal.productsInShipment} purchaseCurrency={deal.purchaseCurrency} />
      </Stack>
    </Box>
  );
};

interface ShipmentProductListingProps {
  productsInShipment: ProductLineItemView[];
  purchaseCurrency: Currency;
}

const ShipmentProductListing: React.FC<ShipmentProductListingProps> = ({ productsInShipment, purchaseCurrency }) => {
  return (
    <Stack spacing={3}>
      <Typography variant="h6">Products to be delivered</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productsInShipment.map(p => (
              <TableRow key={p.productLineItemId}>
                <TableCell>{p.product.code}</TableCell>
                <TableCell>{`${p.product.name} - ${p.product.producerName}`}</TableCell>
                <TableCell>{formatNumberOfUnits(p.qty, p.product.unitType)}</TableCell>
                <TableCell>
                  <CurrencyRenderer value={p.price} currency={purchaseCurrency} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
