import React, { useRef } from "react";
import { StorageLocationOutgoingOrderView, valuesStorageLocationOutgoingOrderStatus } from "adl-gen/ferovinum/app/api";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { formatDateToLocalDate, formatLocalDate } from "utils/date-utils";
import { Grid, Stack, useMediaQuery } from "@mui/material";
import { StatusStep, StatusStepper, StatusStepperProps } from "../../../../widgets/status-stepper/status-stepper";
import { assertNever, assertNotUndefined } from "utils/hx/util/types";
import { StickyNote } from "../../../../widgets/sticky-note/sticky-note";
import { useSelectedStorageLocation } from "../../../../layouts/portal-page-layout/portal-page";
import { DeliveryContactStickyNote } from "../../../../widgets/sticky-note/delivery-contact-sticky-note/delivery-contact-sticky-note";
import { DeliveryAddressStickyNote } from "../../../../widgets/sticky-note/delivery-address-sticky-note/delivery-address-sticky-note";
import { CollectionPointStickyNote } from "../../../../widgets/sticky-note/collection-point-sticky-note/collection-point-sticky-note";
import { useRefDimensions } from "utils/hooks/use-ref-dimensions";
import { OutgoingOrderProductTable } from "../../../../widgets/purchase-requests/outgoing-order-product-table/outgoing-order-product-table";
import { Theme } from "@mui/system";
import { ButtonWithDatePickerDialog } from "components/widgets/buttons/button-with-date-picker-dialog";
import { LocalDate } from "adl-gen/common";

export interface OutgoingOrderDetailPageViewProps extends StorageLocationOutgoingOrderView {
  markAsCollected(collectedDate: LocalDate): Promise<void>;
  markAsDelivered(deliveredDate: LocalDate): Promise<void>;
}

const OutgoingOrderDetailPageViewHeader = ({
  purchaseRequestNumber,
  createdAt,
}: Pick<OutgoingOrderDetailPageViewProps, "purchaseRequestNumber" | "createdAt">) => (
  <PortalPageContentHeader
    title={`Reference number: ${purchaseRequestNumber}`}
    subtitles={[
      {
        text: `Created on ${formatLocalDate(createdAt)}`,
        icon: "date",
      },
    ]}
  />
);
function getSteps(props: OutgoingOrderDetailPageViewProps): StatusStepperProps {
  const steps: StatusStep[] = [
    {
      label: `Ready for collection`,
      timestamp: `on ${formatLocalDate(props.readyForCollectionAt)}`,
    },
  ];
  steps.push({
    label: "Collection",
    timestamp: `${props.status === "collected" ? "Collected on" : "To be collected by"} ${formatLocalDate(
      props.collectedAt,
    )}`,
  });
  if (props.purchaseRequestDeliveryOption.kind === "deliveryToNominatedPurchaser" && props.deliveredAt !== null) {
    steps.push({
      label: "Delivery",
      timestamp: `${props.status === "delivered" ? "Delivered on" : "To be delivered by"} ${formatLocalDate(
        props.deliveredAt,
      )}`,
    });
  }

  return {
    steps: steps,
    activeStep: valuesStorageLocationOutgoingOrderStatus.indexOf(props.status) || 0,
  };
}

const OutgoingOrderDeliveryDetails = (props: OutgoingOrderDetailPageViewProps) => {
  const storageLocation = assertNotUndefined(useSelectedStorageLocation());
  if (props.purchaseRequestDeliveryOption.kind === "collectFromStorageLocation") {
    return (
      <>
        <CollectionPointStickyNote
          locationName={storageLocation.value.locationName}
          incoterms={props.purchaseRequestDeliveryOption.value.incoterms}
        />
      </>
    );
  } else if (props.purchaseRequestDeliveryOption.kind === "deliveryToNominatedPurchaser") {
    const deliveryDetails = props.purchaseRequestDeliveryOption.value.deliveryDetails;
    return (
      <>
        <DeliveryContactStickyNote {...deliveryDetails} />
        <DeliveryAddressStickyNote {...deliveryDetails} />
        {deliveryDetails.deliveryInstructions && (
          <StickyNote line1={deliveryDetails.deliveryInstructions} title={"Delivery instructions"} />
        )}
      </>
    );
  } else {
    assertNever(props.purchaseRequestDeliveryOption);
  }
};
export const OutgoingOrderDetailPageView = (props: OutgoingOrderDetailPageViewProps) => {
  const sideStackRef = useRef(null);
  const { height: sideStackHeight } = useRefDimensions(sideStackRef);
  const isLarge = useMediaQuery<Theme>(theme => theme.breakpoints.up("lg"));

  return (
    <PortalPageContent header={<OutgoingOrderDetailPageViewHeader {...props} />}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <OutgoingOrderProductTable lineItems={props.lineItems} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <StatusStepper {...getSteps(props)} sx={isLarge ? { height: sideStackHeight } : {}} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Stack spacing={2} direction={"column"} ref={sideStackRef}>
            <OutgoingOrderDeliveryDetails {...props} />
            <StickyNote line1={props.orgName} title={"Client"} />
          </Stack>
        </Grid>

        {props.status === "readyForCollection" && (
          <Grid item xs={6} lg={2}>
            <ButtonWithDatePickerDialog
              buttonLabel="Mark as collected"
              dateFieldLabel="Collected at"
              minDate={new Date(props.readyForCollectionAt)}
              maxDate={new Date()}
              onConfirm={date => props.markAsCollected(formatDateToLocalDate(date))}
            />
          </Grid>
        )}
        {props.status === "collected" &&
          props.purchaseRequestDeliveryOption.kind === "deliveryToNominatedPurchaser" && (
            <Grid item xs={6} lg={2}>
              <ButtonWithDatePickerDialog
                buttonLabel="Mark as delivered"
                dialogTitle={"Mark as delivered"}
                dateFieldLabel="Delivered at"
                minDate={new Date(props.collectedAt)}
                maxDate={new Date()}
                onConfirm={date => props.markAsDelivered(formatDateToLocalDate(date))}
              />
            </Grid>
          )}
      </Grid>
    </PortalPageContent>
  );
};
