import { Stack, Typography } from "@mui/material";
import { SaleOrderDetailsView } from "adl-gen/ferovinum/app/api";
import { DeliveryOption, DeliveryStatus, SaleOrderStatus, SaleOrderStatusEvent } from "adl-gen/ferovinum/app/db";
import { assertNever } from "assert-never";
import { DownloadPdfButton } from "components/widgets/buttons/download-pdf-button";
import { LoadingActionButtonWithConfirmation } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { default as React, useMemo } from "react";
import { formatInstant, formatLocalDate } from "utils/date-utils";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { useSettlementCurrency } from "../../../../layouts/portal-page-layout/portal-page";
import { DeliveryUpdatesCard } from "../../../../widgets/card/delivery-updates-card/delivery-updates-card";
import { RepurchaseProductsTable } from "../../../../widgets/repurchase/repurchase-products-table/repurchase-products-table";
import { RepurchaseSummaryCardGrid } from "../../../../widgets/repurchase/repurchase-summary-card-grid/repurchase-summary-card-grid";
import { StatusStepper } from "../../../../widgets/status-stepper/status-stepper";
import { TabbedPage } from "components/library/widgets/tabbed-page";

export interface OrganisationRepurchaseDetailsPageViewProps {
  adminView: boolean;
  saleOrderDetailsView: SaleOrderDetailsView;
  organisationName: string;
  onConfirmClientPaid(invoiceNumber: string): Promise<void>;
  onAdminCancel(invoiceNumber: string): Promise<void>;
  onAdminRelease(invoiceNumber: string): Promise<void>;
  pdfDownloadActions: {
    getInvoiceDownloadUrl?: () => Promise<string>;
    getDeliveryOrderDownloadUrl?: () => Promise<string>;
  };
}
export const OrganisationRepurchaseDetailsPageView = ({
  pdfDownloadActions,
  adminView,
  saleOrderDetailsView,
  organisationName,
  ...adminActions
}: OrganisationRepurchaseDetailsPageViewProps) => {
  const {
    orderStatusEvents,
    saleDate,
    invoiceNumber,
    usesSettlementCredit,
    deliveryOption,
    deliveryStatuses,
    requiresDeliveryOrder,
  } = saleOrderDetailsView;
  const latestStatusEvent = useMemo(() => orderStatusEvents[orderStatusEvents.length - 1], [orderStatusEvents]);
  const { activeStep, steps } = useMemo(
    () =>
      getSteps({
        events: orderStatusEvents,
        saleDate,
        organisationName,
        paidWithCredit: usesSettlementCredit,
        requiresDeliveryOrder,
      }),
    [orderStatusEvents, organisationName, requiresDeliveryOrder, saleDate, usesSettlementCredit],
  );

  return (
    <PortalPageContent header={<OrganisationRepurchaseDetailsHeader invoiceNumber={invoiceNumber} />}>
      <Stack spacing={4}>
        <TabbedPage
          tabs={[
            {
              label: "Progress",
              key: "Progress",
              toolbar: <ToolbarButtons {...pdfDownloadActions} />,
              content: (
                <OrganisationRepurchaseProgressSection
                  deliveryOption={deliveryOption}
                  deliveryStatuses={deliveryStatuses}
                  activeStep={activeStep}
                  steps={steps}
                />
              ),
            },
            {
              label: "Order Summary",
              key: "Order Summary",
              toolbar: <ToolbarButtons {...pdfDownloadActions} />,
              content: <OrganisationRepurchaseOrderSummarySection saleOrderDetailsView={saleOrderDetailsView} />,
            },
          ]}
        />
        {adminView && (
          <ActionButtons
            invoiceNumber={invoiceNumber}
            status={latestStatusEvent?.status}
            usesSettlementCredit={usesSettlementCredit}
            requiresDeliveryOrder={requiresDeliveryOrder}
            {...adminActions}
          />
        )}
      </Stack>
    </PortalPageContent>
  );
};

const ToolbarButtons = ({
  getInvoiceDownloadUrl,
  getDeliveryOrderDownloadUrl,
}: {
  getInvoiceDownloadUrl?: () => Promise<string>;
  getDeliveryOrderDownloadUrl?: () => Promise<string>;
}) => {
  return (
    <Stack direction="row" spacing={2}>
      {getDeliveryOrderDownloadUrl && (
        <DownloadPdfButton getDownloadUrl={getDeliveryOrderDownloadUrl} buttonText="Download Delivery Order" />
      )}
      {getInvoiceDownloadUrl && (
        <DownloadPdfButton getDownloadUrl={getInvoiceDownloadUrl} buttonText="Download Invoice" />
      )}
    </Stack>
  );
};

const OrganisationRepurchaseDetailsHeader = ({ invoiceNumber }: { invoiceNumber: string }) => {
  const title = "Sale Order";
  return (
    <PortalPageContentHeader
      variant="split"
      title={title}
      right={
        <Stack width={"100%"} textAlign={"right"}>
          <Typography sx={{ fontSize: 12, color: "common.grey5" }}>Reference Number</Typography>
          <Typography variant="subtitle1Bold">{invoiceNumber}</Typography>
        </Stack>
      }
    />
  );
};

const OrganisationRepurchaseProgressSection = ({
  deliveryOption,
  deliveryStatuses,
  activeStep,
  steps,
}: {
  deliveryOption: DeliveryOption | null;
  deliveryStatuses: DeliveryStatus[];
  activeStep: number;
  steps: StatusStep[];
}) => {
  return (
    <Stack spacing={5}>
      {(deliveryOption?.kind === "shippedDelivery" || deliveryOption?.kind === "orgLocationDelivery") && (
        <DeliveryUpdatesCard deliveryStatuses={deliveryStatuses} />
      )}
      <StatusStepper activeStep={activeStep} steps={steps} sx={{ flex: 1 }} />
    </Stack>
  );
};

const OrganisationRepurchaseOrderSummarySection = ({
  saleOrderDetailsView,
}: {
  saleOrderDetailsView: SaleOrderDetailsView;
}) => {
  const settlementCurrency = useSettlementCurrency();
  return (
    <Stack spacing={5}>
      <RepurchaseSummaryCardGrid saleOrderDetailsView={saleOrderDetailsView} />
      <RepurchaseProductsTable
        {...saleOrderDetailsView.saleOrderTotalsView}
        settlementCurrency={settlementCurrency}
        dealLegSaleOrders={saleOrderDetailsView.dealLegSaleOrders}
        currency={useSettlementCurrency()}
      />
    </Stack>
  );
};

function checkCanMarkAsCancelled(usesSettlementCredit: boolean, status?: SaleOrderStatus) {
  return !usesSettlementCredit && (status === "invoiced" || status === "draftInvoiced");
}

function checkCanMarkAsPaid(usesSettlementCredit: boolean, status?: SaleOrderStatus) {
  return usesSettlementCredit ? status === "released" : status === "invoiced";
}

function checkCanMarkAsReleased(
  usesSettlementCredit: boolean,
  requiresDeliveryOrder: boolean,
  status?: SaleOrderStatus,
) {
  if (usesSettlementCredit) {
    // The flow for using credit is fully automated.
    // When an DO is required, the signing of that document will automatically trigger the release of the stock.
    // If no DO is required, the stock will be released as soon as the sale order is invoiced
    return false;
  } else {
    // If no DO is required the stock can be released as soon as the sale order is paid
    // If an DO is required the stock can be released after the DO is signed
    return requiresDeliveryOrder ? status === "deliveryOrderSigned" : status === "paid";
  }
}

const ActionButtons = ({
  invoiceNumber,
  onConfirmClientPaid,
  onAdminCancel,
  onAdminRelease,
  status,
  usesSettlementCredit,
  requiresDeliveryOrder,
}: Pick<OrganisationRepurchaseDetailsPageViewProps, "onConfirmClientPaid" | "onAdminCancel" | "onAdminRelease"> & {
  invoiceNumber: string;
  status?: SaleOrderStatus;
  usesSettlementCredit: boolean;
  requiresDeliveryOrder: boolean;
}) => {
  const canMarkAsCancelled = checkCanMarkAsCancelled(usesSettlementCredit, status);
  const canMarkAsPaid = checkCanMarkAsPaid(usesSettlementCredit, status);
  const canMarkAsReleased = checkCanMarkAsReleased(usesSettlementCredit, requiresDeliveryOrder, status);
  return (
    <Stack direction="row" spacing={2} justifyContent="flex-end">
      {canMarkAsCancelled && (
        <LoadingActionButtonWithConfirmation
          variant="outlined"
          confirmationTitle="Please confirm that you want to CANCEL this Sale Order"
          onConfirm={async () => onAdminCancel(invoiceNumber)}>
          Cancel Sale Order
        </LoadingActionButtonWithConfirmation>
      )}
      {canMarkAsPaid && (
        <LoadingActionButtonWithConfirmation
          confirmationTitle="Please confirm that you want to CONFIRM the client has paid"
          onConfirm={async () => await onConfirmClientPaid(invoiceNumber)}>
          Confirm Client Paid
        </LoadingActionButtonWithConfirmation>
      )}
      {canMarkAsReleased && (
        <LoadingActionButtonWithConfirmation
          confirmationTitle="Please confirm that you want to RELEASE this Sale Order"
          onConfirm={async () => await onAdminRelease(invoiceNumber)}>
          Release Sale Order
        </LoadingActionButtonWithConfirmation>
      )}
    </Stack>
  );
};

type SaleOrderViewStatus = SaleOrderStatus | "requested";

interface StatusStep {
  status: SaleOrderViewStatus;
  label: string;
  timestamp?: string;
}
interface StatusSteps {
  activeStep: number;
  steps: StatusStep[];
}
const getSteps = ({
  organisationName,
  events,
  saleDate,
  paidWithCredit,
  requiresDeliveryOrder,
}: {
  organisationName: string;
  events: SaleOrderStatusEvent[];
  saleDate: string;
  paidWithCredit?: boolean;
  requiresDeliveryOrder: boolean;
}): StatusSteps => {
  let activeStep = 0;
  const sortedEvents = [...events].sort((x, y) => x.createdAt - y.createdAt);

  let steps: StatusStep[] = sortedEvents.map(e => {
    return {
      status: e.status,
      label: getLabelForStatus(e.status, organisationName),
      timestamp: formatInstant(e.createdAt),
    };
  });

  steps = [
    { status: "requested", label: `Requested by ${organisationName}`, timestamp: formatLocalDate(saleDate) },
    ...steps,
  ];
  activeStep = steps.length;

  const lastStepStatus = steps[activeStep - 1].status;
  if (lastStepStatus === "canceled") {
    return { activeStep, steps };
  }

  const statusSteps: StatusStep[] = getStatusSteps(requiresDeliveryOrder, organisationName, paidWithCredit);

  /// Add follow-up steps
  const stepIndex = statusSteps.findIndex(s => s.status === lastStepStatus);
  const missingDefaultSteps: StatusStep[] = statusSteps.slice(stepIndex + 1);
  steps = [...steps, ...missingDefaultSteps];

  return { activeStep, steps };
};

function getLabelForStatus(status: SaleOrderViewStatus, organisationName?: string) {
  switch (status) {
    case "canceled":
      return "Cancelled";
    case "draftInvoiced":
      return "Preparing invoice";
    case "invoiced":
      return "Invoice sent";
    case "paid":
      return "Payment received by Ferovinum";
    case "deliveryOrderRequested":
      return "Delivery order requested";
    case "deliveryOrderSigned":
      return "Delivery order signed";
    case "released":
      return `Released stock to ${organisationName}`;
    case "requested":
      return `Requested by ${organisationName}`;
    default:
      // @ts-ignore
      assertNever(status);
  }
}

const statusMapping: Record<string, SaleOrderViewStatus[]> = {
  creditDeliveryOrder: [
    "draftInvoiced",
    "invoiced",
    "released",
    "deliveryOrderRequested",
    "deliveryOrderSigned",
    "paid",
  ],
  creditNoDeliveryOrder: ["draftInvoiced", "invoiced", "released", "paid"],
  noCreditDeliveryOrder: [
    "draftInvoiced",
    "invoiced",
    "paid",
    "deliveryOrderRequested",
    "deliveryOrderSigned",
    "released",
  ],
  noCreditNoDeliveryOrder: ["draftInvoiced", "invoiced", "paid", "released"],
};

function getStatusSteps(
  requiresDeliveryOrder: boolean,
  organisationName: string,
  paidWithCredit?: boolean,
): StatusStep[] {
  const key = `${paidWithCredit ? "credit" : "noCredit"}${requiresDeliveryOrder ? "DeliveryOrder" : "NoDeliveryOrder"}`;
  const statuses = statusMapping[key];

  return statuses.map(status => {
    return { status, label: getLabelForStatus(status, organisationName) };
  });
}
