import React, { useCallback, useMemo } from "react";
import { StorageLocation, Product, FinishingService } from "adl-gen/ferovinum/app/db";
import { WithDbId } from "adl-gen/common/db";
import {
  Alert,
  Stack,
  Typography,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Button,
  styled,
} from "@mui/material";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { ProductionOrderFlowStepper } from "../../../../widgets/flow-stepper/production-order-flow-stepper";
import { Dropdown } from "components/widgets/dropdown/dropdown";
import { Link } from "components/widgets/link/link";
import { unitTypeToString } from "utils/conversion-utils";
import { startCase } from "lodash";
import { OrganisationProductSummary } from "../../../../widgets/organisation-product-summary/organisation-product-summary";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { OrganisationProductInventoryView, OrgStockAtStorageLoc } from "adl-gen/ferovinum/app/api";
import { FEROVINUM_CONTACT_URL } from "components/assets/url";
import { DbKey } from "adl-gen/common/db";
import { assertNotUndefined } from "utils/hx/util/types";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { AppRoutes } from "../../../../../app/app-routes";
import { getVesselCapacity } from "utils/model-utils";

type StorageLocationDetails = WithDbId<Pick<StorageLocation, "locationName">>;

function storageLocationCompareFn(sl1: StorageLocationDetails, sl2: StorageLocationDetails): number {
  return sl1.value.locationName.localeCompare(sl2.value.locationName);
}

export interface OrganisationProductSelectionPageViewProps {
  activeStorageLocId?: DbKey<StorageLocation>;
  bottles: OrganisationProductInventoryView[];
  onChangeActiveStorageLocation(locWithId: DbKey<StorageLocation>): void;
  onSelectProduct(productWithId: DbKey<Product>): Promise<boolean>;
  productToFinishingServicesMap: Map<DbKey<Product>, WithDbId<FinishingService>[]>;
}

export const OrganisationProductSelectionPageView = ({
  activeStorageLocId,
  bottles,
  onChangeActiveStorageLocation,
  onSelectProduct,
  productToFinishingServicesMap,
}: OrganisationProductSelectionPageViewProps) => {
  const storageLocations = useMemo<StorageLocationDetails[]>(() => {
    const referencedLocations: Map<DbKey<StorageLocation>, string> = bottles
      .flatMap(product => product.storageLocStocks)
      .reduce((locationMap, storageLocation) => {
        if (!locationMap.has(storageLocation.storageLocationId)) {
          locationMap.set(storageLocation.storageLocationId, storageLocation.storageLocationName);
        }

        return locationMap;
      }, new Map());

    return Array.from(referencedLocations.entries())
      .map(([id, locationName]) => ({
        id,
        value: {
          locationName,
        },
      }))
      .sort(storageLocationCompareFn);
  }, [bottles]);

  const onLocationChange = useCallback(
    (loc: StorageLocationDetails | undefined) => {
      if (loc) {
        onChangeActiveStorageLocation(loc.id);
      }
    },
    [onChangeActiveStorageLocation],
  );

  const activeStorageLoc = useMemo<StorageLocationDetails | undefined>(
    () => storageLocations.find(sl => sl.id === activeStorageLocId),
    [activeStorageLocId, storageLocations],
  );
  const hasBottles = useMemo(() => {
    return activeStorageLoc
      ? bottles.some(b =>
          b.storageLocStocks
            .filter(s => s.storageLocationId === activeStorageLoc.id)
            .some(s => s.totalQuantityAvailable.value > 0),
        )
      : false;
  }, [activeStorageLoc, bottles]);

  return (
    <PortalPageContent header={<OrganisationProductSelectionHeader />}>
      <Stack spacing={7}>
        <Stack spacing={2}>
          {activeStorageLoc && (
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Dropdown<StorageLocationDetails>
                inputLabel="Storage location"
                menuItems={storageLocations.map(loc => ({ label: loc.value.locationName, value: loc }))}
                onChange={onLocationChange}
                defaultValue={{
                  label: activeStorageLoc?.value.locationName || "Not Selected",
                  value: activeStorageLoc,
                }}
              />
            </Stack>
          )}
          {hasBottles && activeStorageLoc ? (
            <>
              <BottlesSelectionTable
                activeStorageLoc={activeStorageLoc}
                productListings={bottles}
                onSelectProduct={onSelectProduct}
                productToFinishingServicesMap={productToFinishingServicesMap}
              />
              <Box alignSelf="flex-end">
                <Button variant="outlined" href={AppRoutes.Dashboard}>
                  Cancel
                </Button>
              </Box>
            </>
          ) : (
            <Alert severity="info">
              {`You currently have no available products held on the platform ${
                activeStorageLoc ? `on ${activeStorageLoc.value.locationName}.` : "."
              }`}
              <br />
              <br />
              {"Note: Casks are not supported. To place an order on casks please "}
              <Link href={FEROVINUM_CONTACT_URL} target="_blank" typographyProps={{ sx: { display: "inline" } }}>
                contact Ferovinum
              </Link>
            </Alert>
          )}
        </Stack>
      </Stack>
    </PortalPageContent>
  );
};

const OrganisationProductSelectionHeader = () => {
  return (
    <PortalPageContentHeader
      variant="split"
      title="Select product"
      subtitles={[{ text: "What product would you like to process?" }]}
      right={<ProductionOrderFlowStepper activeStep={0} />}
    />
  );
};

interface BottlesSelectionTableProps {
  activeStorageLoc: StorageLocationDetails;
  productListings: OrganisationProductInventoryView[];
  onSelectProduct(product: DbKey<Product>): Promise<boolean>;
  productToFinishingServicesMap: Map<DbKey<Product>, WithDbId<FinishingService>[]>;
}

export const BottlesSelectionTable = React.memo(function BottlesSelectionTable({
  productListings,
  onSelectProduct,
  productToFinishingServicesMap,
  activeStorageLoc,
}: BottlesSelectionTableProps) {
  const noServicesAvailable = useCallback(
    (listing: OrganisationProductInventoryView) => {
      return !productToFinishingServicesMap.get(listing.productId);
    },
    [productToFinishingServicesMap],
  );

  const productsAtStorageLocation = useMemo<OrganisationProductInventoryView[]>(() => {
    return productListings.filter(product =>
      product.storageLocStocks.some(stock => stock.storageLocationId === activeStorageLoc.id),
    );
  }, [productListings, activeStorageLoc]);

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Unit type</TableCell>
            <TableCell>Available service(s)</TableCell>
            <TableCell align="right">Available (bottles)</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productsAtStorageLocation.map(productListing => {
            const disabled = noServicesAvailable(productListing);
            const stockAtStorageLocation = assertNotUndefined(
              productListing.storageLocStocks.find(stock => stock.storageLocationId === activeStorageLoc.id),
            );
            return (
              <BottlesSelectionRow
                key={productListing.productId}
                productListing={productListing}
                stockAtStorageLocation={stockAtStorageLocation}
                disabled={disabled}
                onSelectProduct={() => onSelectProduct(productListing.productId)}
                finishingServices={productToFinishingServicesMap.get(productListing.productId) ?? []}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

interface BottlesSelectionRowProps {
  productListing: Omit<
    OrganisationProductInventoryView,
    "totalPendingQuantity" | "totalQuantityAvailable" | "storageLocStocks"
  >;
  stockAtStorageLocation: OrgStockAtStorageLoc;
  disabled: boolean;
  onSelectProduct(): void;
  finishingServices: WithDbId<FinishingService>[];
}
export const BottlesSelectionRow = ({
  productListing,
  stockAtStorageLocation,
  disabled,
  onSelectProduct,
  finishingServices,
}: BottlesSelectionRowProps) => {
  const GreyableCell = styled(TableCell)(({ theme }) => ({
    ...(disabled && {
      color: theme.palette.common.grey5,
    }),
  }));

  return (
    <TableRow
      hover={!disabled}
      sx={{ cursor: disabled ? "default" : "pointer" }}
      onClick={disabled ? undefined : onSelectProduct}>
      <GreyableCell>
        <OrganisationProductSummary
          name={productListing.productName}
          code={productListing.productCode}
          producerName={productListing.producerName}
          productDate={productListing.productDate}
          vesselCapacity={getVesselCapacity(productListing.vesselSize)}
          disabled={disabled}
        />
      </GreyableCell>
      <GreyableCell>{unitTypeToString(productListing.unitType)}</GreyableCell>
      <GreyableCell>
        <Stack
          // Note: This is a hack. The height should take ref to the text or another proper way
          sx={{ minHeight: theme => theme.spacing(15) }}
          spacing={2}
          justifyContent="center">
          {disabled ? (
            <>No services available</>
          ) : (
            finishingServices.map(finishingService => (
              <Typography key={finishingService.id}>{startCase(finishingService.value.name)}</Typography>
            ))
          )}
        </Stack>
      </GreyableCell>
      <GreyableCell align="right">{stockAtStorageLocation.totalQuantityAvailable.value}</GreyableCell>
      <GreyableCell align="center">
        <IconButton onClick={onSelectProduct} disabled={disabled}>
          <ArrowForwardIosIcon />
        </IconButton>
      </GreyableCell>
    </TableRow>
  );
};
