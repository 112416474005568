import { Card, CardContent, CardHeader, CardProps, Divider } from "@mui/material";
import React from "react";

export interface LabelledCardProps {
  label: string;
  children: React.ReactNode;
  disablePadding?: boolean;
}
export const LabelledCard = ({ label, children, disablePadding, ...otherProps }: LabelledCardProps & CardProps) => {
  return (
    <Card sx={{ width: "100%", ...otherProps?.sx }} {...otherProps}>
      <CardHeader title={label} titleTypographyProps={{ variant: "caption", color: "common.grey6" }} />
      <Divider />
      {disablePadding ? children : <CardContent>{children}</CardContent>}
    </Card>
  );
};
