import { TextFieldProps } from "@mui/material";
import { unitsLabelForUnitType } from "utils/model-utils";
import React from "react";
import { UnitType } from "adl-gen/ferovinum/app/db";
import { RoundingInput } from "../rounding-input/rounding-input";

export type UnitsInputProps = Omit<TextFieldProps, "type"> & {
  /** unit type: Affects both the label shown and the precision used in this input */
  unitType: UnitType;
  /** makes this currency input debounce its callback */
  debounced?: boolean;
  /** Hides the units label at the end of the input */
  hideUnitsLabel?: boolean;
};

const CASK_DECIMAL_PRECISION = 1;
const TANK_DECIMAL_PRECISION = 2;

function getDecimalPrecisionForUnitType(UnitType: UnitType): number {
  switch (UnitType) {
    case "cask":
      return CASK_DECIMAL_PRECISION;
    case "tank":
      return TANK_DECIMAL_PRECISION;
    default:
      return 0;
  }
}

export const UnitsInput = ({ value, unitType, debounced, hideUnitsLabel, ...props }: UnitsInputProps) => {
  return (
    <RoundingInput
      {...props}
      value={value}
      precision={getDecimalPrecisionForUnitType(unitType)}
      debounced={debounced}
      endAdornment={hideUnitsLabel ? undefined : unitsLabelForUnitType(unitType)}
    />
  );
};
