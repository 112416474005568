import React from "react";

export function ColGroup({ widths }: { widths: number[] }) {
  const totalWidths = widths.reduce((acc, width) => acc + width, 0);
  const normalizedColWidths = widths.map(width => (width / totalWidths) * 100);

  return (
    <colgroup>
      {normalizedColWidths.map((width, idx) => (
        <col key={idx} style={{ width: `${width}%` }} />
      ))}
    </colgroup>
  );
}
