import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import _ from "lodash";

export type DebouncedTextFieldProps = TextFieldProps &
  Required<Pick<TextFieldProps, "onChange" | "value">> & { sanitize?: (v: string) => string | null };

export const DebouncedTextField = ({ onChange, value, sanitize, ...props }: DebouncedTextFieldProps) => {
  const [innerValue, setInnerValue] = useState<TextFieldProps["value"]>(value ?? "");
  const debouncedOnChange = useMemo(() => {
    return _.debounce(onChange, 150);
  }, [onChange]);

  const handleOnChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback(
    e => {
      let value = e.target.value;
      if (sanitize) {
        const sanitisedValue = sanitize(value);
        // If sanitisation fails then leave the value as the previously valid value
        if (sanitisedValue == null) return;
        value = sanitisedValue;
      }
      setInnerValue(value);
      // We need to persist the event in order to be used in the debounced call
      e.persist();
      debouncedOnChange(e);
    },
    [debouncedOnChange, sanitize],
  );

  // if value prop is changed from outside we also need to update the stored innerValue
  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  useEffect(() => {
    return debouncedOnChange.cancel;
  }, [debouncedOnChange]);

  return <TextField {...props} value={innerValue} onChange={handleOnChange} />;
};
