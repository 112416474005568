import { format } from "date-fns";

export type CsvUploadResult<T> =
  | {
      kind: "successes";
      value: WithCsvRowNumber<T>[];
    }
  | {
      kind: "errors";
      value: WithCsvRowNumber<RowIdentifierAndErrors>[];
    };
export interface WithCsvRowNumber<T> {
  value: T;
  rowNumber: number;
}
export interface RowIdentifierAndErrors {
  rowIdentifier?: string;
  errors: string[];
}

export const downloadCSVFile = (csvData: string, fileNamePrefix: string) => {
  const blob = new Blob([csvData], { type: "text/csv" });
  const a = document.createElement("a");
  a.download = `${fileNamePrefix}(${format(new Date(), "d MMM yy, h_mm aaa")}).csv`;
  const csvURL = window.URL.createObjectURL(blob);
  a.href = csvURL;
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
  URL.revokeObjectURL(csvURL);
};

export const camelCaseToReadableFormat = (key: string): string =>
  key.replace(/([A-Z])/g, " $1").replace(/^./, str => str.toUpperCase());

export type ProcessorFunction<TInput, TOutput> = (rowData: TInput) => TOutput;

export type CSVDownloadRowData = {
  value: string;
  header?: string;
};

export function downloadObjectsToCSV<TInput extends object, TOutput extends object>(
  rowsData: TInput[],
  rowProcessorFn: ProcessorFunction<TInput, TOutput>,
  fileNamePrefix: string,
) {
  const csvDataArray = rowsData.map(rowData => rowProcessorFn(rowData));
  if (csvDataArray.length === 0) {
    throw new Error("No data available to download.");
  }
  const headerKeys = Object.keys(csvDataArray[0]) as Array<keyof TOutput>;

  const readableHeader = headerKeys
    .map(key => {
      const cell = csvDataArray[0][key] as CSVDownloadRowData;
      return cell.header ? cell.header : camelCaseToReadableFormat(key as string);
    })
    .join(",");

  const rows = csvDataArray.map(row =>
    headerKeys
      .map(key => {
        const cell = row[key] as CSVDownloadRowData;
        return `"${cell.value}"`;
      })
      .join(","),
  );

  const csvString = [readableHeader, ...rows].join("\n");
  downloadCSVFile(csvString, fileNamePrefix);
}
