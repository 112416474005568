import { HttpServiceBase } from "./http-service-base";
import { HttpFetch } from "utils/hx/service/http";
import { HttpServiceError } from "./http-service-error";
import { texprBool } from "adl-gen/runtime/adl";
import { createJsonBinding } from "adl-gen/runtime/json";
import { RESOLVER } from "adl-gen/resolver";

export class AccountingService extends HttpServiceBase {
  constructor(
    /** Fetcher over HTTP */
    http: HttpFetch,
    /** Base URL of the API endpoints */
    baseUrl: string,
    /** The authentication token (if any) */
    authToken: string | undefined,
    /** Error handler to allow for cross cutting concerns, e.g. authorization errors */
    handleError: (error: HttpServiceError) => void,
  ) {
    super(http, baseUrl, authToken, handleError, () => null);
  }

  async getConnectionStatus(): Promise<boolean> {
    return await this.makeRequestWithJsonResponse<boolean>(
      "get",
      "/connection-status",
      undefined,
      createJsonBinding(RESOLVER, texprBool()),
      "get-connection-status",
    );
  }

  async getXeroConnectionRedirectUrl(): Promise<string> {
    const resp = await this.makeRequestWithTextResponse(
      "get",
      "/authorization",
      undefined,
      "get-redirect-url",
    );
    return resp;
  }
}
