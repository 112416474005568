import { Grid, Stack } from "@mui/material";

import { WizardStepComponent, useWizardStepSetup } from "components/library/components/wizard/wizard";

import { FormField } from "components/types/formik-types";
import { default as React, useMemo } from "react";
import { ObjectFields } from "utils/data-field/object-field-def";

import { DbKey } from "adl-gen/common/db";
import { Carrier } from "adl-gen/ferovinum/app/db";
import {
  StockMovementCollection,
  StockMovementDelivery,
  StockMovementDeliveryOption,
} from "adl-gen/ferovinum/app/workflow";
import { InputUiBuilder } from "components/library/helpers/input-ui-builder";
import { DbKeySelector, SelectorInfo } from "components/library/widgets/db-key-selector";
import { BaseWizardCard } from "components/library/widgets/wizard-card/base-wizard-card";
import { DataFieldDef } from "utils/data-field/data-field-def";
import { formatDateToLocalDate, formatDateToLocalTime } from "utils/date-utils";
import {
  DELIVERY_DATA_DEF,
  DELIVERY_METHOD_FIELD_DEF,
  StockMovementWizardContext,
  StockMovementWizardData,
} from "./stock-movement-wizard-types";

const TODAY = new Date();
export const StockMovementDeliverySectionStep: WizardStepComponent<
  StockMovementWizardData,
  StockMovementWizardContext,
  "deliveryDetails"
> = ({ stepContext, stepData, registerDataGetter }) => {
  const initStepData = {
    stockMovementDeliveryOption: stepData?.stockMovementDeliveryOption ?? {
      kind: "collectionByOrg",
      value: {
        carrierName: "",
        collectionName: "",
        contactName: "",
        contactEmail: "",
        contactNumber: "",
        deliveryDate: formatDateToLocalDate(TODAY),
        collectionDate: formatDateToLocalDate(TODAY),
        collectionTime: formatDateToLocalTime(TODAY),
        vehicleRegistration: null,
        movementGuaranteeNumber: null,
      },
    },
  };
  const { uiBuilder } = useWizardStepSetup(initStepData, DELIVERY_METHOD_FIELD_DEF, registerDataGetter);

  return (
    <Stack spacing={7}>
      <BaseWizardCard
        title="Delivery Method"
        subtitle="Please select your preferred delivery option."
        inputElement={uiBuilder.createUnionField<StockMovementDeliveryOption>("stockMovementDeliveryOption", {
          collectionByOrg: {
            label: "I will collect and deliver the stock myself / arrange a carrier myself.",
            ui: (fieldDef, field) => <StockMovementCollectionComponent {...{ fieldDef, field }} />,
          },
          organisedDelivery: {
            label: "I will select a carrier to collect and deliver the stock (LCB Only).",
            disabled: stepContext.deliveryEnabled === false,
            ui: (fieldDef, field, displayed) => (
              <StockMovementDeliveryComponent
                {...{ fieldDef, field, displayed, carrierOptions: stepContext.carrierOptions }}
              />
            ),
          },
        })}
      />
    </Stack>
  );
};

function StockMovementDeliveryComponent({
  fieldDef,
  field,
  displayed,
  carrierOptions,
}: {
  fieldDef: DataFieldDef<StockMovementDelivery>;
  field: FormField<StockMovementDelivery>;
  displayed: boolean;
  carrierOptions: SelectorInfo<Carrier>[];
}) {
  const { metaData } = fieldDef;
  const uiBuilder = useMemo(() => {
    if (metaData.kind == "object") {
      const fieldDefs = metaData.fieldDefs as Readonly<ObjectFields<StockMovementDelivery>>;
      return new InputUiBuilder(fieldDefs, field);
    }
  }, [field, metaData]);

  /**
   *
   */
  if (uiBuilder) {
    return (
      <Grid container spacing={3} sx={{ pt: 2 }}>
        <Grid item xs={12}>
          <DbKeySelector
            fieldInfo={{
              field: field.getSubField<DbKey<Carrier>>("carrier"),
            }}
            fieldDef={DELIVERY_DATA_DEF.carrier}
            options={carrierOptions}
            label="Carrier"
            helperText="Can't find the carrier?"
            // Note below props are set to default the field to LCB - this is a temporary solution until we have more carriers
            // Note: Only set initial carrier value when rendered  union-input-widget will clear the field values
            initialValue={displayed ? carrierOptions[0].id : undefined}
            // Disable this as they can only select LCB atm
            disabled={true}
          />
        </Grid>
        {uiBuilder.createLocalDateField("deliveryDate", { grid: { xs: 6 } })}
        <Grid item xs={6} />
        {uiBuilder.createLocalTimeField("deliveryTimeEarliest", { grid: { xs: 6 } })}
        {uiBuilder.createLocalTimeField("deliveryTimeLatest", { grid: { xs: 6 } })}
        {uiBuilder.createTextAreaField("deliveryInstructions", { grid: { xs: 12 } })}
      </Grid>
    );
  } else {
    throw new Error(`Invalid definition type, needs to be 'object' found '${metaData.kind}'`);
  }
}

function StockMovementCollectionComponent({
  fieldDef,
  field,
}: {
  fieldDef: DataFieldDef<StockMovementCollection>;
  field: FormField<StockMovementCollection>;
}) {
  const { metaData } = fieldDef;
  const uiBuilder = useMemo(() => {
    if (metaData.kind == "object") {
      const fieldDefs = metaData.fieldDefs as Readonly<ObjectFields<StockMovementCollection>>;
      return new InputUiBuilder(fieldDefs, field);
    }
  }, [field, metaData]);
  if (uiBuilder) {
    return (
      <Grid container spacing={3} sx={{ pt: 2 }}>
        {uiBuilder.createTextField("carrierName", { grid: { xs: 6 } })}
        {uiBuilder.createTextField("contactName", { grid: { xs: 6 } })}
        {uiBuilder.createTextField("contactEmail", { grid: { xs: 6 } })}
        {uiBuilder.createTextField("contactNumber", { grid: { xs: 6 } })}
        {uiBuilder.createLocalDateField("collectionDate", { grid: { xs: 6 } })}
        {uiBuilder.createLocalTimeField("collectionTime", { grid: { xs: 6 } })}
        {uiBuilder.createLocalDateField("deliveryDate", { grid: { xs: 6 } })}
        {uiBuilder.createTextField("vehicleRegistration", { grid: { xs: 6 } })}
        {uiBuilder.createTextField("movementGuaranteeNumber", { grid: { xs: 6 } })}
      </Grid>
    );
  } else {
    throw new Error(`Invalid definition type, needs to be 'object' found '${metaData.kind}'`);
  }
}
