import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import { Header, HEADER_HEIGHT } from "../header/header";
import { LoadingBackdrop } from "components/widgets/loader/loading-backdrop";
import { Alert, Box, Button, Modal, Paper } from "@mui/material";
import { isLoaded, isLoading, LoadingValue } from "utils/utility-types";
import { UserMembership } from "adl-gen/ferovinum/app/api";
import { NAVIGATION_DRAWER_WIDTH, NavigationDrawer } from "../navigation-drawer/navigation-drawer";
import { LoginState } from "../../../app/identity-state";
import { IdToStore } from "./portal-page";
import { ActionsSpeedDial } from "../../widgets/common/actions-speed-dial/actions-speed-dial";
import { InfoDrawer } from "../info-drawer/info-drawer";
import { FEROVINUM_CONTACT_URL } from "components/assets/url";
import { Link } from "components/widgets/link/link";
import { useLayoutInfo } from "../layout-info";

const Page = styled(Box)(({}) => ({
  display: "flex",
  flexDirection: "column",
}));

export interface PortalPageLayoutProps {
  loadingMembership: LoadingValue<UserMembership>;
  loginState: LoginState;
  onEntityChange(entityUpdate: IdToStore): void;
}

export const PortalPageLayout: React.FC<PortalPageLayoutProps> = ({
  children,
  loginState,
  loadingMembership,
  ...headerProps
}) => {
  const { isMobile } = useLayoutInfo();
  const [navDrawerIsOpen, setNavDrawerIsOpen] = React.useState(!isMobile);
  const toggleDrawer = () => {
    setNavDrawerIsOpen(!navDrawerIsOpen);
  };

  const accountIsNotYetSetup = useMemo<boolean>(() => {
    return (
      !isLoaded(loadingMembership) ||
      !Object.values(loadingMembership.value).some(entityMembership => entityMembership.length > 0)
    );
  }, [loadingMembership]);

  if (isLoading(loadingMembership)) {
    return <LoadingBackdrop open={true} />;
  }

  if (accountIsNotYetSetup) {
    return (
      <Modal open={true}>
        <Paper
          sx={{
            position: "absolute",
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            width: 500,
            backgroundColor: theme => theme.palette.background.paper,
            boxShadow: theme => theme.shadows[5],
            padding: theme => theme.spacing(2, 2, 2),
          }}>
          <Alert severity="error">
            Your account is not yet setup.
            <Link href={FEROVINUM_CONTACT_URL}>Please contact Ferovinum for help.</Link>
          </Alert>

          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 2 }}
            onClick={() => {
              loginState?.onLogout();
            }}>
            Logout
          </Button>
        </Paper>
      </Modal>
    );
  }

  return (
    <Page sx={{ mt: `${HEADER_HEIGHT}px` }}>
      <Header loginState={loginState} toggleDrawer={toggleDrawer} />
      {loginState.user !== undefined && <ActionsSpeedDial />}
      <Box>
        {isLoaded(loadingMembership) && (
          <NavigationDrawer
            membership={loadingMembership.value}
            open={navDrawerIsOpen}
            close={() => setNavDrawerIsOpen(false)}
            onEntityChange={headerProps.onEntityChange}
          />
        )}
        {isMobile ? <div>{children}</div> : <DesktopMain open={navDrawerIsOpen}>{children}</DesktopMain>}
        <InfoDrawer />
      </Box>
    </Page>
  );
};

const DesktopMain = styled("main", { shouldForwardProp: prop => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: NAVIGATION_DRAWER_WIDTH,
  }),
}));
