import * as CSS from "csstype";
import {
  PurchaseRequest,
  PurchaseRequestDeliveryOption,
  PurchaseRequestPaymentTermsPeriod,
  PurchaseRequestState,
  PurchaseRequestStateEvent,
} from "adl-gen/ferovinum/app/db";
import React, { useCallback, useEffect, useMemo } from "react";
import { formatDate, formatInstant, formatLocalDate } from "utils/date-utils";
import { Box, capitalize, Step, StepContent, StepLabel, stepLabelClasses, Stepper, Typography } from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { Extends } from "utils/ts-utils";
import { differenceInDays } from "date-fns";
import { toLower } from "lodash";
import { getPurchaseRequestShortExpiryReason } from "../../../../utils/purchase-request-utils";
import { MONTHS_AFTER_DELIVERY_FIELD_DESCRIPTIONS } from "components/library/widgets/payment-terms-input";

import { titleCase } from "utils/conversion-utils";
import {
  CollectedPurchaseRequestTimeline,
  DeliveredPurchaseRequestTimeline,
  PurchaseRequestTimeline,
} from "adl-gen/ferovinum/app/nompurchaser";
import { LocalDate } from "adl-gen/common";

export type PurchaseRequestStepperState = Extends<
  PurchaseRequestState,
  | "new"
  | "purchaserAccepted"
  | "purchaserRejected"
  | "productionOrderComplete"
  | "readyForCollection"
  | "collected"
  | "delivered"
  | "purchaserPaid"
  | "expired"
  | "purchaserInvoiced"
>;

const DELIVERY_PURCHASE_REQUEST_STEPS: PurchaseRequestStepperState[] = [
  "new",
  "purchaserAccepted",
  "readyForCollection",
  "collected",
  "delivered",
  "purchaserInvoiced",
  "purchaserPaid",
];

const COLLECTION_PURCHASE_REQUEST_STEPS: PurchaseRequestStepperState[] = [
  "new",
  "purchaserAccepted",
  "readyForCollection",
  "collected",
  "purchaserInvoiced",
  "purchaserPaid",
];

// instant when the step happened or an estimate message
type PurchaseRequestStepTimestamp =
  | { kind: "time"; value: number }
  | { kind: "estimate"; value: string }
  | { kind: "actual"; value: string };

type TimeToNextStep = { kind: "days"; value: number } | { kind: "months"; value: string };
export interface PurchaseRequestStepData {
  label: string;
  // The state linked to this step data
  state: PurchaseRequestStepperState;
  // The instant where this step happened
  instant?: PurchaseRequestStepTimestamp;
  // Range of days for this step to compulsory move into the next step
  timeToNextStep?: TimeToNextStep;
}

function getLabel(
  state: PurchaseRequestStepperState,
  purchaserName: string,
  deliveryOption: PurchaseRequestDeliveryOption,
) {
  const isCollection = deliveryOption.kind === "collectFromStorageLocation";
  switch (state) {
    case "new":
      return "Request made";
    case "purchaserAccepted":
      return `Accepted by ${purchaserName}`;
    case "purchaserRejected":
      return `Rejected by ${purchaserName}`;
    case "delivered":
      return isCollection ? "Collected" : "Delivered";
    default:
      return capitalize(toLower(titleCase(state)));
  }
}

function getStepperStateFromPurchaseRequestState(
  state: PurchaseRequestState,
  purchaserInvoiced?: boolean,
): PurchaseRequestStepperState {
  if (purchaserInvoiced) {
    return "purchaserInvoiced";
  }
  switch (state) {
    case "organisationPaid":
      return "purchaserPaid";
  }
  return state;
}

function getExpiredRequestSteps(
  events: PurchaseRequestStateEvent[],
  collectionDays: number,
  getLabel: (state: PurchaseRequestStepperState) => string,
): PurchaseRequestStepData[] {
  return events.map(e => {
    const state = getStepperStateFromPurchaseRequestState(e.state);
    return {
      state,
      label: state !== "expired" ? getLabel(state) : getPurchaseRequestShortExpiryReason(events, collectionDays),
      instant: { kind: "time", value: e.time },
    };
  });
}

function getRejectedRequestSteps(
  events: PurchaseRequestStateEvent[],
  getLabel: (state: PurchaseRequestStepperState) => string,
): PurchaseRequestStepData[] {
  return events.map(e => {
    const state = getStepperStateFromPurchaseRequestState(e.state);
    return {
      state,
      label: getLabel(state),
      instant: { kind: "time", value: e.time },
    };
  });
}

// Given a state and timeline, return number of days till next state
const getDaysBetweenDeliveredTimelineStates = (
  state: PurchaseRequestState,
  timeline: DeliveredPurchaseRequestTimeline,
  paymentTermsPeriod: PurchaseRequestPaymentTermsPeriod,
): TimeToNextStep | undefined => {
  switch (state) {
    case "new":
      return daysBetweenDates(timeline.creationDate, timeline.purchaserAcceptedDate);
    case "purchaserAccepted":
      return daysBetweenDates(timeline.purchaserAcceptedDate, timeline.readyForCollectionDate);
    case "readyForCollection":
      return daysBetweenDates(timeline.readyForCollectionDate, timeline.collectedDate);
    case "collected":
      return daysBetweenDates(timeline.collectedDate, timeline.deliveredDate);
    case "delivered":
      return daysBetweenDates(timeline.deliveredDate, timeline.purchaserInvoicedDate);
    case "purchaserInvoiced":
      return paymentTermsPeriod.kind === "daysAfterCollection"
        ? daysBetweenDates(timeline.purchaserInvoicedDate, timeline.purchaserPaidDate)
        : {
            kind: "months",
            value: MONTHS_AFTER_DELIVERY_FIELD_DESCRIPTIONS[paymentTermsPeriod.value],
          };
    default:
      return undefined;
  }
};

const getDaysBetweenCollectedTimelineStates = (
  state: PurchaseRequestState,
  timeline: CollectedPurchaseRequestTimeline,
  paymentTermsPeriod: PurchaseRequestPaymentTermsPeriod,
): TimeToNextStep | undefined => {
  switch (state) {
    case "new":
      return daysBetweenDates(timeline.creationDate, timeline.purchaserAcceptedDate);
    case "purchaserAccepted":
      return daysBetweenDates(timeline.purchaserAcceptedDate, timeline.readyForCollectionDate);
    case "readyForCollection":
      return daysBetweenDates(timeline.readyForCollectionDate, timeline.collectedDate);
    case "collected":
      return daysBetweenDates(timeline.collectedDate, timeline.purchaserInvoicedDate);
    case "purchaserInvoiced":
      return paymentTermsPeriod.kind === "daysAfterCollection"
        ? daysBetweenDates(timeline.purchaserInvoicedDate, timeline.purchaserPaidDate)
        : {
            kind: "months",
            value: MONTHS_AFTER_DELIVERY_FIELD_DESCRIPTIONS[paymentTermsPeriod.value],
          };
    default:
      return undefined;
  }
};

function mapDeliveryTimelinePurchaseRequestStep({
  state,
  data,
  timeline,
  eventsByState,
  paymentTermsPeriod,
  collectedDate,
  deliveredDate,
}: {
  state: PurchaseRequestStepperState;
  data: PurchaseRequestStepData;
  timeline: DeliveredPurchaseRequestTimeline;
  eventsByState: Map<PurchaseRequestState, number>;
  paymentTermsPeriod: PurchaseRequestPaymentTermsPeriod;
  collectedDate: LocalDate | null;
  deliveredDate: LocalDate | null;
}): PurchaseRequestStepData {
  const daysBetweenStates = getDaysBetweenDeliveredTimelineStates(state, timeline, paymentTermsPeriod);
  switch (state) {
    case "new":
      return {
        ...data,
        timeToNextStep: !eventsByState.has("new") ? daysBetweenStates : undefined,
      };
    case "purchaserAccepted":
      const requiredAcceptanceDate = eventsByState.has("new")
        ? formatLocalDate(timeline.purchaserAcceptedDate)
        : undefined;
      return {
        ...data,
        instant:
          data.instant ??
          (requiredAcceptanceDate
            ? { kind: "estimate", value: `Purchaser acceptance required by: ${requiredAcceptanceDate}` }
            : undefined),
        timeToNextStep: !eventsByState.has("new") ? daysBetweenStates : undefined,
      };
    case "productionOrderComplete":
      return data;
    case "readyForCollection":
      const requiredReadyDate = eventsByState.has("purchaserAccepted")
        ? formatLocalDate(timeline.readyForCollectionDate)
        : undefined;
      return {
        ...data,
        instant:
          data.instant ??
          (requiredReadyDate
            ? { kind: "estimate", value: `Must be ready for collection by: ${requiredReadyDate}` }
            : undefined),
        timeToNextStep: !eventsByState.has("purchaserAccepted") ? daysBetweenStates : undefined,
      };
    case "collected":
      const requiredCollectionDate = eventsByState.has("readyForCollection")
        ? formatLocalDate(timeline.collectedDate)
        : undefined;
      return {
        ...data,
        instant: collectedDate
          ? { kind: "actual", value: `on ${formatLocalDate(collectedDate)}` }
          : data.instant ??
            (requiredCollectionDate
              ? { kind: "estimate", value: `Must be collected by: ${requiredCollectionDate}` }
              : undefined),
        timeToNextStep: !eventsByState.has("purchaserAccepted") ? daysBetweenStates : undefined,
      };
    case "delivered":
      const requiredDeliveryDate = eventsByState.has("collected") ? formatLocalDate(timeline.deliveredDate) : undefined;
      return {
        ...data,
        instant: deliveredDate
          ? { kind: "actual", value: `on ${formatLocalDate(deliveredDate)}` }
          : data.instant ??
            (requiredDeliveryDate
              ? { kind: "estimate", value: `Must be delivered by: ${requiredDeliveryDate}` }
              : undefined),
        timeToNextStep: !eventsByState.has("readyForCollection") ? daysBetweenStates : undefined,
      };
    case "purchaserInvoiced":
      return {
        ...data,
        timeToNextStep: !eventsByState.has("delivered") ? daysBetweenStates : undefined,
      };
    case "purchaserPaid":
      const requiredPaidDate: Date | undefined = eventsByState.has("delivered")
        ? new Date(timeline.purchaserPaidDate)
        : undefined;

      return {
        ...data,
        instant:
          data.instant ??
          (requiredPaidDate
            ? { kind: "estimate", value: `Must be paid by: ${formatDate(requiredPaidDate)}` }
            : undefined),
      };
    default:
      return data;
  }
}

function mapCollectionTimelinePurchaseRequestStep({
  state,
  data,
  timeline,
  eventsByState,
  paymentTermsPeriod,
  collectedDate,
}: {
  state: PurchaseRequestStepperState;
  data: PurchaseRequestStepData;
  timeline: CollectedPurchaseRequestTimeline;
  eventsByState: Map<PurchaseRequestState, number>;
  paymentTermsPeriod: PurchaseRequestPaymentTermsPeriod;
  collectedDate: LocalDate | null;
}): PurchaseRequestStepData {
  const daysBetweenStates = getDaysBetweenCollectedTimelineStates(state, timeline, paymentTermsPeriod);
  switch (state) {
    case "new":
      return {
        ...data,
        timeToNextStep: !eventsByState.has("new") ? daysBetweenStates : undefined,
      };
    case "purchaserAccepted":
      const requiredAcceptanceDate = eventsByState.has("new")
        ? formatLocalDate(timeline.purchaserAcceptedDate)
        : undefined;
      return {
        ...data,
        instant:
          data.instant ??
          (requiredAcceptanceDate
            ? { kind: "estimate", value: `Purchaser acceptance required by: ${requiredAcceptanceDate}` }
            : undefined),
        timeToNextStep: !eventsByState.has("new") ? daysBetweenStates : undefined,
      };
    case "productionOrderComplete":
      return data;
    case "readyForCollection":
      const requiredReadyDate = eventsByState.has("purchaserAccepted")
        ? formatLocalDate(timeline.readyForCollectionDate)
        : undefined;
      return {
        ...data,
        instant:
          data.instant ??
          (requiredReadyDate
            ? { kind: "estimate", value: `Must be ready for collection by: ${requiredReadyDate}` }
            : undefined),
        timeToNextStep: !eventsByState.has("purchaserAccepted") ? daysBetweenStates : undefined,
      };
    case "collected":
      const requiredCollectionDate = eventsByState.has("readyForCollection")
        ? formatLocalDate(timeline.collectedDate)
        : undefined;
      return {
        ...data,
        label: "Collected by customer",
        instant: collectedDate
          ? { kind: "actual", value: `on ${formatLocalDate(collectedDate)}` }
          : data.instant ??
            (requiredCollectionDate
              ? { kind: "estimate", value: `Must be collected by: ${requiredCollectionDate}` }
              : undefined),
        timeToNextStep: !eventsByState.has("purchaserAccepted") ? daysBetweenStates : undefined,
      };
    case "purchaserInvoiced":
      return {
        ...data,
        timeToNextStep: !eventsByState.has("delivered") ? daysBetweenStates : undefined,
      };
    case "purchaserPaid":
      const requiredPaidDate: Date | undefined = eventsByState.has("delivered")
        ? new Date(timeline.purchaserPaidDate)
        : undefined;

      return {
        ...data,
        instant:
          data.instant ??
          (requiredPaidDate
            ? { kind: "estimate", value: `Must be paid by: ${formatDate(requiredPaidDate)}` }
            : undefined),
      };
    default:
      return data;
  }
}

function getPurchaseRequestSteps(
  purchaseRequest: PurchaseRequestStepperData,
  timeline: PurchaseRequestTimeline,
  withProductionOrder?: boolean,
): PurchaseRequestStepData[] {
  const {
    collectionDays,
    paymentTermsPeriod,
    stateEvents: events,
    state,
    purchaserName,
    purchaseRequestDeliveryOption,
    collectedDate,
    deliveredDate,
  } = purchaseRequest;
  const eventsByState: Map<PurchaseRequestState, number> = events.reduce((m, e) => {
    m.set(e.state, e.time);
    return m;
  }, new Map());

  const getLabelFn = (state: PurchaseRequestStepperState) =>
    getLabel(state, purchaserName, purchaseRequestDeliveryOption);
  if (state === "expired") {
    return getExpiredRequestSteps(events, collectionDays, getLabelFn);
  }
  if (state === "purchaserRejected") {
    return getRejectedRequestSteps(events, getLabelFn);
  }
  const steps =
    timeline.kind === "delivered" ? [...DELIVERY_PURCHASE_REQUEST_STEPS] : [...COLLECTION_PURCHASE_REQUEST_STEPS];
  if (withProductionOrder) {
    steps.splice(2, 0, "productionOrderComplete");
  }

  return steps.map(state => {
    const stateTimestamp = eventsByState.get(state);
    const data: PurchaseRequestStepData = {
      state,
      label: getLabelFn(state),
      instant: stateTimestamp ? { kind: "time", value: stateTimestamp } : undefined,
    };

    if (timeline.kind === "delivered") {
      return mapDeliveryTimelinePurchaseRequestStep({
        state,
        data,
        timeline: timeline.value,
        eventsByState,
        paymentTermsPeriod,
        collectedDate,
        deliveredDate,
      });
    } else if (timeline.kind === "collected") {
      return mapCollectionTimelinePurchaseRequestStep({
        state,
        data,
        timeline: timeline.value,
        eventsByState,
        paymentTermsPeriod,
        collectedDate,
      });
    } else {
      throw Error("unsupported timeline kind");
    }
  });
}

const daysBetweenDates = (startDateStr: string, endDateStr: string): TimeToNextStep => ({
  kind: "days",
  value: differenceInDays(new Date(endDateStr), new Date(startDateStr)),
});

type PurchaseRequestStepperData = Pick<
  PurchaseRequest,
  | "stateEvents"
  | "paymentTermsPeriod"
  | "collectionDays"
  | "state"
  | "purchaseRequestDeliveryOption"
  | "collectedDate"
  | "deliveredDate"
> & { withProductionOrder?: boolean; purchaserName: string };
export interface PurchaseRequestStepperProps {
  /// purchase request
  purchaseRequest: PurchaseRequestStepperData;
  /// If this prop is set the stepper will be scrollable and auto-scroll to the active step
  maxHeight?: CSS.Property.Height;
  timeline: PurchaseRequestTimeline;
}

export const PurchaseRequestStepper = ({ purchaseRequest, maxHeight, timeline }: PurchaseRequestStepperProps) => {
  const steps: PurchaseRequestStepData[] = getPurchaseRequestSteps(
    purchaseRequest,
    timeline,
    purchaseRequest.withProductionOrder,
  );
  const currentStepperState: PurchaseRequestStepperState | undefined =
    purchaseRequest.stateEvents.length > 0 ? getStepperStateFromPurchaseRequestState(purchaseRequest.state) : undefined;
  return <BasePurchaseRequestStepper currentState={currentStepperState} steps={steps} maxHeight={maxHeight} />;
};

interface BasePurchaseRequestStepperProps {
  // current purchase request stepper state
  currentState?: PurchaseRequestStepperState;
  // data attached to each step of the purchase request flow
  steps: PurchaseRequestStepData[];
  /// If this prop is set the stepper will be scrollable and auto-scroll to the active step
  maxHeight?: CSS.Property.Height;
}

const BasePurchaseRequestStepper = ({ currentState, steps, maxHeight }: BasePurchaseRequestStepperProps) => {
  /** The active step in the stepper */
  const activeStep: number = useMemo(() => {
    const currentStateIndex = steps.findIndex(v => v.state === currentState);
    /** The active step is the following step from the current state in the procurement flow (hence the +1) */
    return currentStateIndex + 1;
  }, [currentState, steps]);
  /** The step being scrolled to */
  const lastStepIndex = steps.length - 1;
  const focusedStep: number = useMemo(() => {
    return activeStep > lastStepIndex ? lastStepIndex : activeStep;
  }, [activeStep, lastStepIndex]);
  const getStepTimestampMessage = useCallback((step: PurchaseRequestStepData) => {
    if (step.instant === undefined) {
      return "";
    }
    return step.instant.kind === "time" ? `on ${formatInstant(step.instant.value)}.` : step.instant.value;
  }, []);

  const containerRef = React.createRef<HTMLDivElement>();
  const focusedStepRef = React.createRef<HTMLDivElement>();
  useEffect(() => {
    if (focusedStepRef.current && containerRef.current) {
      containerRef.current.scrollTop =
        focusedStepRef.current.offsetTop -
        containerRef.current.clientHeight / 2 +
        focusedStepRef.current.clientHeight / 2;
    }
  }, [focusedStepRef, containerRef]);

  return (
    <Box ref={containerRef} maxHeight={maxHeight} overflow={maxHeight ? "auto" : undefined} position={"relative"}>
      <Stepper orientation="vertical" activeStep={activeStep} connector={null}>
        {steps.map((step, idx) => {
          const timestamp = getStepTimestampMessage(step);

          return (
            <Step key={step.label} ref={focusedStep === idx ? focusedStepRef : undefined} expanded>
              <StepLabel
                icon={
                  idx >= activeStep ? (
                    <Box color="common.grey">
                      <CircleOutlinedIcon color="inherit" />
                    </Box>
                  ) : undefined
                }
                optional={timestamp}
                sx={{ [`& .${stepLabelClasses.labelContainer}`]: { ml: 4 } }}>
                <Typography color="common.grey7">{step.label}</Typography>
              </StepLabel>
              {idx !== lastStepIndex && (
                <StepContent sx={{ minHeight: 32, borderLeftStyle: "dashed" }}>
                  {
                    <Typography variant="caption" color="common.grey6" paddingLeft={4}>
                      {step.timeToNextStep !== undefined ? renderTimeToNextStep(step.timeToNextStep) : ""}
                    </Typography>
                  }
                </StepContent>
              )}
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

const renderTimeToNextStep = (timeToNextStep: TimeToNextStep) =>
  timeToNextStep.kind === "days" ? `${timeToNextStep.value} days` : timeToNextStep.value;
