import { useMemo } from "react";
import { useSelectedEntity } from "../ui/layouts/portal-page-layout/portal-page";

export interface NominatedPurchaserIsConfiguredValues {
  isConfigured: boolean;
  nominatedPurchaserFee: boolean;
  defaultMonthlyFee: boolean;
}

export const useNominatedPurchaserIsConfigured = (): NominatedPurchaserIsConfiguredValues => {
  const selectedEntity = useSelectedEntity();

  const nominatedPurchaserFee = useMemo(() => {
    if (selectedEntity.type === "org") {
      return selectedEntity.value.value.nominatedPurchaserFee !== null;
    }
    return true;
  }, [selectedEntity]);

  const defaultMonthlyFee = useMemo(() => {
    if (selectedEntity.type === "org") {
      return selectedEntity.value.value.defaultMonthlyFee !== null;
    }
    return true;
  }, [selectedEntity]);

  const isConfigured = useMemo(
    () => nominatedPurchaserFee && defaultMonthlyFee,
    [nominatedPurchaserFee, defaultMonthlyFee],
  );

  return { isConfigured, nominatedPurchaserFee, defaultMonthlyFee };
};
