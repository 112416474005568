import { assertNotUndefined } from "utils/hx/util/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AppService } from "adl-gen/app-service";
import { WithDbId } from "adl-gen/common/db";
import {
  Carrier,
  Currency,
  Incoterms,
  NewDealRequestId,
  NewDealRequestType,
  NewSupplier,
  NumberOfUnits,
  Product,
  Supplier,
} from "adl-gen/ferovinum/app/db";
import _ from "lodash";
import { AppRoutes } from "../../../../../app/app-routes";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { useSelectedOrgId, useSettlementCurrency } from "../../../../layouts/portal-page-layout/portal-page";
import { Loader } from "components/widgets/loader/loader";
import { LoadingValue, isLoaded } from "utils/utility-types";
import {
  CreateNewDealRequestFormValues,
  OrganisationCreateNewDealRequestPageView,
} from "./organisation-create-new-deal-request-page-view";
import { useUiConfig } from "adl-service/hooks/use-ui-config";
import { Decimal } from "adl-gen/ferovinum/app/types";
import { StorageLocationView } from "adl-gen/ferovinum/app/api";
import { BulkQuantityUnitType } from "utils/model-utils";

export type NewDealRequestFlowState = (
  | {
      variant: "newStock";
      supplier?: WithDbId<Supplier> | NewSupplier;
      carrier?: WithDbId<Carrier>;
      incoterms?: Incoterms;
    }
  | {
      variant: "existingStock";
    }
) & {
  selectedProducts: ProductLineItemData[];
  currency: Currency;
  storageLocation?: StorageLocationView;
  discount: Decimal;
  existingNdrId?: NewDealRequestId;
};

export type NewProductData = Omit<Product, "updatedAt" | "alcoholByVolumePc" | "ownerId"> & {
  alcoholByVolumePc: number;
};
export interface ExistingProduct {
  kind: "existing";
  productCode: string;
  value: WithDbId<Product>;
}
export interface NewProduct {
  kind: "new";
  productCode: string;
  value: NewProductData;
}

export type ProductLineItemData = (ExistingProduct | NewProduct) & {
  numberOfUnits?: NumberOfUnits;
  numberOfFreeUnits?: NumberOfUnits;
  clientSpecifiedPurchasePrice?: number;
  purchasePrice?: number;
  individualDiscountPct?: Decimal;
};

export type ProductLineItemFormData = (ExistingProduct | NewProduct) & {
  numberOfUnits?: number;
  numberOfFreeUnits?: number;
  clientSpecifiedPurchasePrice?: number;
  purchasePrice?: number;
  individualDiscountPct?: number;
  bulkQuantityUnitType?: BulkQuantityUnitType;
};

export function isNewSupplier(value: WithDbId<Supplier> | NewSupplier): value is NewSupplier {
  // @ts-ignore
  return value["id"] === undefined;
}

export const OrganisationCreateNewDealRequestPage = () => {
  const { existingNdrId } = useParams<{ existingNdrId: string }>();
  const history = useHistory();
  const location = useLocation();
  const service: AppService = useAppService();
  const organisationId = assertNotUndefined(useSelectedOrgId());
  const { showNewStockProcurement, showExistingStockProcurement } = useUiConfig();
  const settlementCurrency = useSettlementCurrency();
  const [loadingPurchaseOrderFlowState, setLoadingPurchaseOrderFlowState] = useState<
    LoadingValue<NewDealRequestFlowState | undefined>
  >({
    state: "loading",
  });

  useEffect(() => {
    if (existingNdrId === undefined) {
      const locationState: NewDealRequestFlowState | undefined = location.state as NewDealRequestFlowState | undefined;
      setLoadingPurchaseOrderFlowState({ state: "success", value: locationState });
    }
  }, [location.state, existingNdrId]);

  useEffect(() => {
    if (existingNdrId !== undefined) {
      const loadNdrData = async () => {
        const ndrView = await service.getNewDealRequestDetails({
          newDealsRequestId: existingNdrId,
        });
        const orgId = ndrView.organisation.id;
        const locId = ndrView.storageLocation.id;
        const ndr = ndrView.newDealRequest.value;
        const locationViews = await service.getStorageLocations({
          orgId: orgId,
          selector: { kind: "byIds", value: [locId] },
        });
        const locView = locationViews[0];
        const products: ProductLineItemData[] = ndrView.productLineItems.map(li => {
          const pli = li.productLineItem.value;

          return {
            kind: "existing",
            value: li.product,
            productCode: li.product.value.code,
            numberOfUnits: pli.totalNumberOfUnits,
            numberOfFreeUnits: pli.numberOfFreeUnits,
            clientSpecifiedPurchasePrice:
              pli.clientSpecifiedPurchasePrice !== null ? _.toNumber(pli.clientSpecifiedPurchasePrice) : undefined,
            individualDiscountPct: pli.individualDiscountPct ?? undefined,
          };
        });

        if (ndrView.newDealRequestType.kind === "existingStockNewDealRequest") {
          // existing stock
          setLoadingPurchaseOrderFlowState({
            state: "success",
            value: {
              variant: "existingStock",
              currency: ndr.settlementCurrency,
              storageLocation: locView,
              discount: ndrView.discount.discountedNetSubtotal,
              selectedProducts: products,
              existingNdrId: existingNdrId,
            },
          });
        } else {
          // new stock
          const supplier = ndr.supplierId !== null ? await service.getOrgSupplier(ndr.supplierId) : undefined;
          const carrier = ndr.carrierId !== null ? await service.getCarrier(ndr.carrierId) : undefined;
          setLoadingPurchaseOrderFlowState({
            state: "success",
            value: {
              variant: "newStock",
              currency: ndr.settlementCurrency,
              storageLocation: locView,
              discount: ndrView.discount.discountPct,
              selectedProducts: products,
              supplier: supplier,
              carrier: carrier,
              incoterms: ndr.incoterms ?? undefined,
              existingNdrId: existingNdrId,
            },
          });
        }
      };
      loadNdrData();
    }
  }, [existingNdrId, service]);

  const availableNewDealRequestTypes: Set<NewDealRequestType> = useMemo(() => {
    const result = new Set<NewDealRequestType>();
    if (showNewStockProcurement) {
      result.add("newStock");
    }
    if (showExistingStockProcurement) {
      result.add("existingStock");
    }
    return result;
  }, [showExistingStockProcurement, showNewStockProcurement]);

  const loadStorageLocations = useCallback(async () => {
    return await service.getStorageLocations({ orgId: organisationId, selector: { kind: "associated" } });
  }, [organisationId, service]);

  const [loadingStorageLocations] = useLoadingDataState(loadStorageLocations);

  const onSupplierSearch = useCallback(
    async (searchTerm: string) => {
      return await service.supplierSearch({ organisationId, searchTerm });
    },
    [organisationId, service],
  );

  const onNext = useCallback(
    (formValues: CreateNewDealRequestFormValues) => {
      let state: NewDealRequestFlowState;

      if (loadingPurchaseOrderFlowState.state === "success") {
        const purchaseOrderFlowState = loadingPurchaseOrderFlowState.value;
        if (formValues.variant === "newStock") {
          const supplier = assertNotUndefined(formValues.selectedSupplier);
          const approvedSupplierCurrencies = isNewSupplier(supplier)
            ? supplier.approvedCurrencies
            : supplier.value.approvedCurrencies;

          const userSelectedASupplierWithIncompatibleCurrency =
            approvedSupplierCurrencies.findIndex(c => c === purchaseOrderFlowState?.currency) < 0;

          const currency =
            purchaseOrderFlowState === undefined || userSelectedASupplierWithIncompatibleCurrency
              ? approvedSupplierCurrencies[0]
              : purchaseOrderFlowState.currency;

          const discount = purchaseOrderFlowState?.discount || "";

          state = {
            ...purchaseOrderFlowState,
            variant: "newStock",
            supplier: assertNotUndefined(formValues.selectedSupplier),
            currency,
            selectedProducts: purchaseOrderFlowState?.selectedProducts ?? [],
            discount,
          };
        } else {
          state = {
            ...purchaseOrderFlowState,
            variant: "existingStock",
            storageLocation: assertNotUndefined(formValues.selectedStorageLocation),
            currency: settlementCurrency,
            selectedProducts: purchaseOrderFlowState?.selectedProducts ?? [],
            discount: purchaseOrderFlowState?.discount || "0",
          };
        }
        history.push(AppRoutes.NewDealRequestSetup, state);
      }
    },
    [history, loadingPurchaseOrderFlowState, settlementCurrency],
  );

  return (
    <Loader loadingStates={[loadingStorageLocations, loadingPurchaseOrderFlowState]} fullScreen>
      {isLoaded(loadingStorageLocations) && isLoaded(loadingPurchaseOrderFlowState) && (
        <OrganisationCreateNewDealRequestPageView
          availableNewDealRequestTypes={availableNewDealRequestTypes}
          storageLocations={loadingStorageLocations.value}
          onNext={onNext}
          onSupplierSearch={onSupplierSearch}
          initialValues={loadingPurchaseOrderFlowState.value}
        />
      )}
    </Loader>
  );
};
