import { Button, Grid, Stack } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { LocalDate } from "adl-gen/common";
import { PortalPageContent } from "../../../../../layouts/portal-page-content/portal-page-content";
import { FinishingServiceSummaryCard } from "../../../../../widgets/production-orders/finishing-service-summary-card/finishing-service-summary-card";
import { ProductionOrderTotalPriceCard } from "../../../../../widgets/production-orders/production-order-total-price-card/production-order-total-price-card";
import { AcceptOrRejectProps, StorageLocProductionOrderTaskView } from "../storage-loc-production-order-details-page";
import { ConfirmationDialog } from "components/widgets/confirmation-dialog/confirmation-dialog";
import { createBigDecimalCurrencyFormatter } from "utils/currency-utils";
import { useSettlementCurrency } from "../../../../../layouts/portal-page-layout/portal-page";
import { useConfirmationDialog } from "components/context/global-dialog/use-dialog";
import { NotesStickyNote } from "../../../../../widgets/sticky-note/notes-sticky-note/notes-sticky-note";
import { TextArea } from "components/widgets/inputs/text-area/text-area";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";
import {
  PortalPageContentHeader,
  SubtitleProps,
} from "../../../../../layouts/portal-page-content-header/portal-page-content-header";
import { formatLocalDate } from "utils/date-utils";

export interface StorageLocNewProductionOrderDetailsView {
  orderNumber: string;
  organisationName: string;
  createdAt: LocalDate;
  requestedCompletionDate: LocalDate | null;
  comments: string | null;
  productionOrderTasks: StorageLocProductionOrderTaskView[];
  subtotalCost: MonetaryValue;
  totalSetupCost: MonetaryValue;
  totalCost: MonetaryValue;
}

export interface StorageLocProductionOrderCreatedPageViewProps {
  productionOrder: StorageLocNewProductionOrderDetailsView;
  onAction(action: AcceptOrRejectProps): Promise<void>;
}

export const StorageLocProductionOrderCreatedPageView = ({
  productionOrder,
  onAction,
}: StorageLocProductionOrderCreatedPageViewProps) => {
  const { showConfirmationDialog } = useConfirmationDialog();
  const [openRejectDialog, setOpenRejectDialog] = useState<boolean>(false);
  const { comments, productionOrderTasks } = productionOrder;

  const currency = useSettlementCurrency();
  const currencyFormatter = createBigDecimalCurrencyFormatter(currency)();

  return (
    <PortalPageContent header={<StorageLocProductionOrderDetailsPageHeader {...productionOrder} />}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Stack spacing={2}>
            {productionOrderTasks.map(poTask => {
              const {
                targetQuantity,
                budgetQuantity,
                finishingService,
                sourceProduct,
                finishingProduct,
                pricePerUnit,
                setupCost,
              } = poTask;

              return (
                <FinishingServiceSummaryCard
                  key={`${poTask.finishingService.code}-${sourceProduct.id}-${finishingProduct.code}`}
                  targetQuantity={targetQuantity}
                  budgetQuantity={budgetQuantity}
                  serviceName={finishingService.name}
                  sourceProduct={sourceProduct.value}
                  finishingProduct={finishingProduct}
                  latestStatus={null}
                  additionalHeaderLabels={[
                    {
                      label: "Price per unit",
                      value: currencyFormatter.format(pricePerUnit),
                      textAlign: "right",
                      mr: 5,
                    },
                    { label: "Setup cost", value: currencyFormatter.format(setupCost), textAlign: "right" },
                  ]}
                />
              );
            })}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ProductionOrderTotalPriceCard
            {...productionOrder}
            currency={currency}
            setupCost={productionOrder.totalSetupCost}
            productionOrderTasksCosts={productionOrderTasks}
          />
        </Grid>
        {comments && (
          <Grid item xs={12} lg={6}>
            <NotesStickyNote notes={comments} />
          </Grid>
        )}
      </Grid>

      <Stack sx={{ mt: 7 }} direction="row" spacing={2}>
        <Button variant="outlined" color="error" onClick={() => setOpenRejectDialog(true)}>
          Reject
        </Button>
        <Button
          variant="outlined"
          color="success"
          onClick={async () =>
            await showConfirmationDialog({
              title: "Accept production order",
              confirmAction: {
                onClick: async () => await onAction({ kind: "accepted" }),
              },
            })
          }>
          Accept
        </Button>

        {/** TODO:(Berto): Replace with programmatic dialog */}
        <RejectDialog open={openRejectDialog} onAction={onAction} onCancel={() => setOpenRejectDialog(false)} />
      </Stack>
    </PortalPageContent>
  );
};

interface StorageLocProductionOrderDetailsPageHeaderProps {
  orderNumber: string;
  createdAt: LocalDate;
  requestedCompletionDate: LocalDate | null;
}
const StorageLocProductionOrderDetailsPageHeader = ({
  orderNumber,
  createdAt,
  requestedCompletionDate,
}: StorageLocProductionOrderDetailsPageHeaderProps) => {
  const subtitles: SubtitleProps[] = useMemo(() => {
    const defaultSubtitles: SubtitleProps[] = [
      {
        text: `Ordered on ${formatLocalDate(createdAt)}`,
        icon: "receipt",
      },
    ];

    if (requestedCompletionDate) {
      defaultSubtitles.push({
        text: `Requested completion date: ${formatLocalDate(requestedCompletionDate)}`,
        icon: "date",
      });
    }

    return defaultSubtitles.concat({
      text: "Pending acceptance",
      component: { type: "chip", color: "warning" },
    });
  }, [createdAt, requestedCompletionDate]);

  return <PortalPageContentHeader title={`Production order reference number: ${orderNumber}`} subtitles={subtitles} />;
};
interface RejectDialogProps {
  open: boolean;
  onCancel(): void;
  onAction(props: AcceptOrRejectProps): Promise<void>;
}

const RejectDialog = ({ open, onCancel, onAction }: RejectDialogProps) => {
  const [reason, setReason] = useState<string>("");

  const handleConfirm = useCallback(async () => {
    const trimmedReason = reason.trim();
    await onAction({
      kind: "rejected",
      reason: trimmedReason.length > 0 ? trimmedReason : undefined,
    });
  }, [onAction, reason]);

  return (
    <ConfirmationDialog
      open={open}
      title="Reject with comments"
      acceptAction={{ onClick: handleConfirm }}
      cancelAction={{ onClick: onCancel }}
      maxWidth="sm"
      fullWidth
      onClose={onCancel}>
      <TextArea value={reason} onChange={e => setReason(e.target.value)} rows={5} fullWidth />
    </ConfirmationDialog>
  );
};
