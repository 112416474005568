import React, { useCallback, useState } from "react";
import { Grid, Stack, TextField, Typography } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";

/** TODO(Nicole): @deprecated - remove this */
export const DateAction: React.FC<{
  fieldLabel: string;
  buttonLabel: string;
  value?: Date;
  onSubmit(dateVal: Date): Promise<void>;
  minDate?: Date;
  showError?(val: boolean): void;
  onChange?(val: Date): void;
}> = ({ fieldLabel, buttonLabel, onSubmit, value, minDate = new Date(), showError, onChange }) => {
  const [dateVal, setDateVal] = useState<Date>(value || minDate);
  const [hasError, setHasError] = useState<boolean>(false);

  const onError = (e: unknown) => {
    const isErr = e !== null;
    setHasError(isErr);
    if (showError) {
      showError(isErr);
    }
  };

  const handleButtonClick = useCallback(
    async _ev => {
      return await onSubmit(dateVal);
    },
    [onSubmit, dateVal],
  );

  return (
    <Stack spacing={3}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={2}>
          <Typography>{fieldLabel}</Typography>
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            value={dateVal}
            onChange={value => {
              if (value !== null) {
                onChange ? onChange(value) : setDateVal(value);
              }
            }}
            onError={onError}
            inputFormat="dd/MM/yyyy"
            renderInput={params => <TextField {...params} margin="dense" fullWidth variant="outlined" />}
            minDate={minDate}
          />
        </Grid>
      </Grid>
      <Grid item>
        <LoadingActionButton onClick={handleButtonClick} disabled={hasError}>
          {buttonLabel}
        </LoadingActionButton>
      </Grid>
    </Stack>
  );
};
