import React, { useState } from "react";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { Button, Grid, Stack } from "@mui/material";
import { ProductionOrderFlowStepper } from "../../../../widgets/flow-stepper/production-order-flow-stepper";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { ProductionOrderTotalPriceCard } from "../../../../widgets/production-orders/production-order-total-price-card/production-order-total-price-card";
import { FinishingServiceSummaryCard } from "../../../../widgets/production-orders/finishing-service-summary-card/finishing-service-summary-card";
import {
  PortalPageContentHeader,
  SubtitleProps,
} from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { useSettlementCurrency } from "../../../../layouts/portal-page-layout/portal-page";
import { NotesStickyNote } from "../../../../widgets/sticky-note/notes-sticky-note/notes-sticky-note";
import { ProductionOrderFlowState } from "../organisation-production-order-setup/organisation-production-order-setup-page";
import { assertNotUndefined } from "utils/hx/util/types";
import { ProductionOrderTotals } from "adl-gen/ferovinum/app/api";
import { formatDate, formatLocalDate } from "utils/date-utils";
import { AppRoutes } from "../../../../../app/app-routes";

export interface ConfirmedProductionOrderDetails {
  storageLocationName: string;
  orderDate: string;
  requestedCompletionDate?: string;
}
export interface OrganisationProductionOrderConfirmationPageViewProps {
  flowState: ProductionOrderFlowState;
  totals: ProductionOrderTotals;
  navigateBackToProductionOrderSetup: () => void;
  onConfirm: () => Promise<void>;
  confirmedProductionOrderDetails?: ConfirmedProductionOrderDetails;
}
export const OrganisationProductionOrderConfirmationPageView = ({
  flowState,
  totals,
  navigateBackToProductionOrderSetup,
  onConfirm,
  confirmedProductionOrderDetails,
}: OrganisationProductionOrderConfirmationPageViewProps) => {
  const {
    quantity: targetQuantity,
    finishingService,
    finishingProduct,
    requestedCompletionDate,
    notes,
    setupCost,
  } = assertNotUndefined(flowState.setup);
  return (
    <PortalPageContent
      header={
        <OrganisationProductionOrderConfirmationHeader
          confirmedProductionOrderDetails={confirmedProductionOrderDetails}
        />
      }>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FinishingServiceSummaryCard
            serviceName={finishingService.value.name}
            targetQuantity={targetQuantity.value}
            sourceProduct={flowState.sourceProduct}
            finishingProduct={finishingProduct.value}
            latestStatus={null}
            additionalHeaderLabels={
              requestedCompletionDate
                ? [
                    {
                      label: "Requested completion date",
                      value: formatLocalDate(requestedCompletionDate),
                      sx: { textAlign: "right" },
                    },
                  ]
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ProductionOrderTotalPriceCard
            productionOrderTasksCosts={[
              {
                targetQuantity: targetQuantity.value,
                // Note (Nicole): For now, the budget quantity and target quantity will always be the same.
                // This will change when users are allowed to input budget quantity.
                budgetQuantity: targetQuantity.value,
                pricePerUnit: assertNotUndefined(flowState.setup).pricePerUnit,
                setupCost: assertNotUndefined(flowState.setup).setupCost,
              },
            ]}
            currency={useSettlementCurrency()}
            setupCost={setupCost}
            subtotalCost={totals.subtotal}
            totalCost={totals.total}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {notes && (
            <NotesStickyNote
              notes={notes}
              action={{
                onClick: () => navigateBackToProductionOrderSetup(),
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {confirmedProductionOrderDetails ? (
            <Button variant="contained" href={`${AppRoutes.OrgProductionOrderProductSelection}`}>
              Create another production order
            </Button>
          ) : (
            <ActionButtons
              onConfirm={onConfirm}
              navigateBackToProductionOrderSetup={navigateBackToProductionOrderSetup}
            />
          )}
        </Grid>
      </Grid>
    </PortalPageContent>
  );
};

const OrganisationProductionOrderConfirmationHeader = ({
  confirmedProductionOrderDetails,
}: {
  confirmedProductionOrderDetails?: ConfirmedProductionOrderDetails;
}) => {
  if (confirmedProductionOrderDetails) {
    const { storageLocationName, orderDate, requestedCompletionDate } = confirmedProductionOrderDetails;

    const getSubtitles = (): SubtitleProps[] => {
      const defaultSubtitles: SubtitleProps[] = [
        { text: `Ordered from ${storageLocationName} on ${formatDate(new Date(orderDate))}`, icon: "receipt" },
      ];
      if (requestedCompletionDate) {
        defaultSubtitles.push({
          text: `Requested completion date: ${formatDate(new Date(requestedCompletionDate))}`,
          icon: "date",
        });
      }
      return defaultSubtitles;
    };

    return (
      <PortalPageContentHeader
        useConfirmationTitle
        title={`Your order has been submitted to ${storageLocationName} for acceptance`}
        subtitles={getSubtitles()}
      />
    );
  }

  return (
    <PortalPageContentHeader
      variant="split"
      title="Confirmation"
      right={<ProductionOrderFlowStepper activeStep={2} />}
    />
  );
};

const ActionButtons = ({
  navigateBackToProductionOrderSetup,
  onConfirm,
}: {
  navigateBackToProductionOrderSetup: () => void;
  onConfirm: () => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Stack direction="row" spacing={2}>
      <Button variant="outlined" onClick={() => navigateBackToProductionOrderSetup()} disabled={loading}>
        Back
      </Button>
      <LoadingActionButton
        onClick={async () => {
          setLoading(true);
          await onConfirm();
          setLoading(false);
        }}>
        Confirm
      </LoadingActionButton>
    </Stack>
  );
};
