import { Stack, StackProps, Typography } from "@mui/material";
import React from "react";
import { CurrencyRenderer, CurrencyRendererProps } from "./currency-renderer";
import { PriceRange } from "adl-gen/ferovinum/app/api";

export interface CurrencyRangeProps extends Omit<CurrencyRendererProps, "value"> {
  values:
    | number[]
    | PriceRange
    | {
        max: number;
        min: number;
      };
  justify?: StackProps["justifyContent"];
}

/**
 * Given an array of values, or a max and min. Render the currency in the shape "min - max" -> "$10.50 - $12.50"
 */
export const CurrencyRange = ({ values, justify = "flex-end", ...rest }: CurrencyRangeProps) => {
  let max: number;
  let min: number;

  // If values is an array, find the min and max
  if (Array.isArray(values)) {
    if (values.length === 0) {
      return null;
    }
    min = Math.min(...values);
    max = Math.max(...values);
  } else if ("min" in values) {
    // If values is an object, use the min and max properties
    min = values.min;
    max = values.max;
  } else {
    min = Number(values.from);
    max = Number(values.to);
  }

  return (
    <Stack direction="row" justifyContent={justify} spacing={0.5}>
      <CurrencyRenderer value={min} {...rest} />
      {min !== max && <Typography>-</Typography>}
      {min !== max && <CurrencyRenderer value={max} {...rest} />}
    </Stack>
  );
};
