import React, { useCallback, useRef } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ProductionOrderListingView } from "adl-gen/ferovinum/app/api";

export type ProductionOrderStatusType = (typeof stasuses)[number];
const stasuses = ["Pending", "In progress", "Completed", "Rejected"] as const;

export function getAvailableFilters(productionOrders: ProductionOrderListingView[]): ProductionOrderStatusType[] {
  const available: ProductionOrderStatusType[] = ["Pending"];

  if (productionOrders.some(p => p.status === "accepted")) {
    available.push("In progress");
  }
  if (productionOrders.some(p => p.status === "completed")) {
    available.push("Completed");
  }
  if (productionOrders.some(p => p.status === "rejected")) {
    available.push("Rejected");
  }

  return available;
}

export interface ProductionOrderStatusTypeToggleProps {
  selectedFilter: ProductionOrderStatusType;
  setSelectedFilter: (filter: ProductionOrderStatusType) => void;
  availableTypes: ProductionOrderStatusType[];
}
export const ProductionOrderStatusTypeToggle = ({
  selectedFilter,
  setSelectedFilter,
  availableTypes,
}: ProductionOrderStatusTypeToggleProps) => {
  const availableFilters = useRef<ProductionOrderStatusType[]>(availableTypes);

  const handleChangeFilter = useCallback(
    (_, filter: ProductionOrderStatusType) => {
      // Note (Nicole): This is to enforce a value being set
      // Ref: https://mui.com/material-ui/react-toggle-button/#enforce-value-set
      if (filter !== null) {
        setSelectedFilter(filter);
      }
    },
    [setSelectedFilter],
  );

  return (
    <>
      {availableFilters.current !== undefined && (
        <ToggleButtonGroup value={selectedFilter} onChange={handleChangeFilter} exclusive>
          {availableFilters.current.map(filter => (
            <ToggleButton key={filter} value={filter}>
              {filter}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </>
  );
};
