/* @generated from adl module ferovinum.app.systemcommand */

import * as ADL from './../../runtime/adl';

export interface SystemCmd_Accounting {
  kind: 'accounting';
  value: AccountingCmd;
}
export interface SystemCmd_Email {
  kind: 'email';
  value: EmailCmd;
}
export interface SystemCmd_Logistics {
  kind: 'logistics';
  value: LogisticsCmd;
}

export type SystemCmd = SystemCmd_Accounting | SystemCmd_Email | SystemCmd_Logistics;

export interface SystemCmdOpts {
  accounting: AccountingCmd;
  email: EmailCmd;
  logistics: LogisticsCmd;
}

export function makeSystemCmd<K extends keyof SystemCmdOpts>(kind: K, value: SystemCmdOpts[K]) { return {kind, value}; }

const SystemCmd_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.systemcommand","decl":{"annotations":[],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"accounting","default":{"kind":"nothing"},"name":"accounting","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"ferovinum.app.systemcommand","name":"AccountingCmd"}},"parameters":[]}},{"annotations":[],"serializedName":"email","default":{"kind":"nothing"},"name":"email","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"ferovinum.app.systemcommand","name":"EmailCmd"}},"parameters":[]}},{"annotations":[],"serializedName":"logistics","default":{"kind":"nothing"},"name":"logistics","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"ferovinum.app.systemcommand","name":"LogisticsCmd"}},"parameters":[]}}]}},"name":"SystemCmd","version":{"kind":"nothing"}}};

export const snSystemCmd: ADL.ScopedName = {moduleName:"ferovinum.app.systemcommand", name:"SystemCmd"};

export function texprSystemCmd(): ADL.ATypeExpr<SystemCmd> {
  return {value : {typeRef : {kind: "reference", value : snSystemCmd}, parameters : []}};
}

export type AccountingCmd = 'sendInvoice';
export const valuesAccountingCmd : AccountingCmd[] = ['sendInvoice'];

const AccountingCmd_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.systemcommand","decl":{"annotations":[],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"sendInvoice","default":{"kind":"nothing"},"name":"sendInvoice","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}}]}},"name":"AccountingCmd","version":{"kind":"nothing"}}};

export const snAccountingCmd: ADL.ScopedName = {moduleName:"ferovinum.app.systemcommand", name:"AccountingCmd"};

export function texprAccountingCmd(): ADL.ATypeExpr<AccountingCmd> {
  return {value : {typeRef : {kind: "reference", value : snAccountingCmd}, parameters : []}};
}

export type EmailCmd = 'sendEmail';
export const valuesEmailCmd : EmailCmd[] = ['sendEmail'];

const EmailCmd_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.systemcommand","decl":{"annotations":[],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"sendEmail","default":{"kind":"nothing"},"name":"sendEmail","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}}]}},"name":"EmailCmd","version":{"kind":"nothing"}}};

export const snEmailCmd: ADL.ScopedName = {moduleName:"ferovinum.app.systemcommand", name:"EmailCmd"};

export function texprEmailCmd(): ADL.ATypeExpr<EmailCmd> {
  return {value : {typeRef : {kind: "reference", value : snEmailCmd}, parameters : []}};
}

export type LogisticsCmd = 'releaseOrder';
export const valuesLogisticsCmd : LogisticsCmd[] = ['releaseOrder'];

const LogisticsCmd_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.systemcommand","decl":{"annotations":[],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"releaseOrder","default":{"kind":"nothing"},"name":"releaseOrder","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}}]}},"name":"LogisticsCmd","version":{"kind":"nothing"}}};

export const snLogisticsCmd: ADL.ScopedName = {moduleName:"ferovinum.app.systemcommand", name:"LogisticsCmd"};

export function texprLogisticsCmd(): ADL.ATypeExpr<LogisticsCmd> {
  return {value : {typeRef : {kind: "reference", value : snLogisticsCmd}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "ferovinum.app.systemcommand.SystemCmd" : SystemCmd_AST,
  "ferovinum.app.systemcommand.AccountingCmd" : AccountingCmd_AST,
  "ferovinum.app.systemcommand.EmailCmd" : EmailCmd_AST,
  "ferovinum.app.systemcommand.LogisticsCmd" : LogisticsCmd_AST
};
