import React, { useCallback, useMemo, useState } from "react";
import { Alert, Button } from "@mui/material";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { ProductionOrderStatusType } from "../../../../widgets/production-orders/production-order-status-type-toggle/production-order-status-type-toggle";
import { AppRoutes } from "../../../../../app/app-routes";
import { ProductionOrderListingView } from "adl-gen/ferovinum/app/api";
import { ProductionOrderListingsTable } from "../../../../widgets/production-orders/production-order-listings-table/production-order-listings-table";
import { assertNever } from "assert-never";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { TabbedPage } from "components/library/widgets/tabbed-page";

export interface ProductionOrdersPageViewProps {
  productionOrderViews: ProductionOrderListingView[];
}
export const OrganisationProductionOrdersPageView = ({ productionOrderViews }: ProductionOrdersPageViewProps) => {
  const noProductionOrders = productionOrderViews.length === 0;

  const availableTypes: ProductionOrderStatusType[] = useMemo(() => {
    const types: ProductionOrderStatusType[] = [];

    const availableStatuses = productionOrderViews.map(po => po.status);
    if (availableStatuses.includes(null) || availableStatuses.includes("pending")) {
      types.push("Pending");
    }
    if (availableStatuses.includes("accepted")) {
      types.push("In progress");
    }
    if (availableStatuses.includes("completed")) {
      types.push("Completed");
    }
    if (availableStatuses.includes("rejected")) {
      types.push("Rejected");
    }

    return types;
  }, [productionOrderViews]);
  const getProductionOrdersWithFilter = useCallback(
    (filter: ProductionOrderStatusType) => {
      switch (filter) {
        case "Pending":
          return productionOrderViews.filter(po => po.status === null || po.status === "pending");
        case "In progress":
          return productionOrderViews.filter(po => po.status === "accepted");
        case "Completed":
          return productionOrderViews.filter(po => po.status === "completed");
        case "Rejected":
          return productionOrderViews.filter(po => po.status === "rejected");
        default:
          assertNever(filter);
      }
    },
    [productionOrderViews],
  );

  const [, setSelectedFilter] = useState<ProductionOrderStatusType>(noProductionOrders ? "Pending" : availableTypes[0]);
  const [productionOrdersToDisplay, setProductionOrdersToDisplay] = useState<ProductionOrderListingView[]>(
    noProductionOrders ? [] : getProductionOrdersWithFilter(availableTypes[0]),
  );

  const handleChangeFilter = useCallback(
    ({ label }) => {
      setSelectedFilter(label);
      setProductionOrdersToDisplay(getProductionOrdersWithFilter(label));
    },
    [getProductionOrdersWithFilter],
  );

  return (
    <PortalPageContent header={<PortalPageContentHeader title="Production Orders" />}>
      <TabbedPage
        tabs={[
          ...availableTypes.map(type => ({
            label: type,
            key: type,
            content: <ProductionOrderTable productionOrders={productionOrdersToDisplay} />,
            toolbar: <CreateProductionOrderButton />,
          })),
        ]}
        handleTabChange={handleChangeFilter}
      />
    </PortalPageContent>
  );
};

const CreateProductionOrderButton = () => (
  <Button href={AppRoutes.OrgProductionOrderSetup}>Create new production order</Button>
);

const ProductionOrderTable = ({ productionOrders }: { productionOrders: ProductionOrderListingView[] }) => {
  return (
    <>
      {productionOrders.length === 0 ? (
        <Alert severity="info">{"You haven't placed any production orders yet."}</Alert>
      ) : (
        <ProductionOrderListingsTable
          productionOrders={productionOrders}
          productionOrderDetailsLink={AppRoutes.OrgProductionOrderDetails}
        />
      )}
    </>
  );
};
