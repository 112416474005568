import React, { useCallback, useMemo } from "react";
import {
  StockAdjustmentSettingsFormValues,
  StorageLocStockAdjustmentSettingsPageView,
} from "./storage-loc-stock-adjustment-settings-page-view";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../../../app/app-routes";
import { assertNotUndefined } from "utils/hx/util/types";
import { StockAdjustmentsFlowState } from "../storage-loc-stock-adjustment-setup/storage-loc-stock-adjustment-setup-page";

export const StorageLocStockAdjustmentSettingsPage = () => {
  const location = useLocation();
  const history = useHistory();
  const stockAdjustmentFlowState = location.state as StockAdjustmentsFlowState | undefined;

  const onNext = async (values: StockAdjustmentSettingsFormValues) => {
    const oldState = assertNotUndefined(stockAdjustmentFlowState);
    const newState: StockAdjustmentsFlowState = {
      ...oldState,
      reason: values.reason,
      referenceNumber: values.referenceNumber,
    };
    if (oldState.variant === "negativeStockAdjustment") {
      // Workaround: typescript not being able to infer that the variant is "negativeStockAdjustment" here
      // @ts-ignore
      // Material UI checkboxes are not strongly typed so they will always return strings when on change instead of booleans...
      newState["reimbursed"] = values.reimbursed === "true";
    }
    history.push(AppRoutes.StorageLocStockAdjustmentConfirmation, newState);
  };

  const onBack = useCallback(() => {
    const state = assertNotUndefined(stockAdjustmentFlowState);
    history.push(`${AppRoutes.StorageLocStockAdjustmentSetup}/${state.variant}`, state);
  }, [history, stockAdjustmentFlowState]);

  const initialValues: StockAdjustmentSettingsFormValues = useMemo(() => {
    return {
      reason: stockAdjustmentFlowState?.reason,
      referenceNumber: stockAdjustmentFlowState?.referenceNumber,
      reimbursed:
        stockAdjustmentFlowState?.variant === "negativeStockAdjustment" ? stockAdjustmentFlowState.reimbursed : false,
    };
  }, [stockAdjustmentFlowState]);

  if (stockAdjustmentFlowState === undefined) {
    return <Redirect to={AppRoutes.Index} />;
  } else {
    return (
      <StorageLocStockAdjustmentSettingsPageView
        variant={stockAdjustmentFlowState.variant}
        initialValues={initialValues}
        onNext={onNext}
        onBack={onBack}
      />
    );
  }
};
