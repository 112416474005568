import { ArrowDropDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionSummary,
  accordionSummaryClasses,
  Card,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { isValidElement, ReactNode, useLayoutEffect, useState } from "react";
import { Link } from "components/widgets/link/link";

export interface ActionableSummaryCardProps {
  title: string;
  subtitle?: React.ReactNode;
  titleHref: string;
  headerContent?: ReactNode;
  expandText: string;
  isInitiallyExpanded: boolean;
  isLoading: boolean;
  cardContent: ReactNode;
  cardActions?: ReactNode;
  onExpandStateChange?: (expanded: boolean) => void;
}
export const ActionableSummaryCard = (props: ActionableSummaryCardProps) => {
  const { expandText, isInitiallyExpanded, isLoading, cardContent, cardActions, onExpandStateChange } = props;
  const [expanded, setExpanded] = useState(false);

  useLayoutEffect(() => {
    if (isInitiallyExpanded) {
      setExpanded(true);
    }
  }, [isInitiallyExpanded]);
  return (
    <Card>
      <Stack>
        <SummaryHeader {...props} />
        <StyledAccordion
          disableGutters
          expanded={expanded}
          onChange={(_, expanded) => {
            setExpanded(expanded);
            onExpandStateChange?.(expanded);
          }}>
          <AccordionSummary
            expandIcon={expanded && isLoading ? <CircularProgress color="inherit" size={14} /> : <ArrowDropDown />}>
            <Typography variant="body2">{expandText}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!isLoading && (
              <>
                {cardContent}
                {cardActions && (
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                      p: 2,
                      backgroundColor: "common.grey3",
                    }}>
                    {cardActions}
                  </Stack>
                )}
              </>
            )}
          </AccordionDetails>
        </StyledAccordion>
      </Stack>
    </Card>
  );
};

interface SummaryHeaderProps {
  title: string;
  subtitle?: React.ReactNode;
  titleHref: string;
  headerContent?: ReactNode;
}
const SummaryHeader = ({ title, subtitle, titleHref, headerContent }: SummaryHeaderProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        p: 4,
        pl: 2,
        pr: 2,
        backgroundColor: "common.grey3",
      }}>
      <Stack>
        <Link typographyProps={{ variant: "h6", sx: { wordBreak: "break-all" } }} href={titleHref}>
          {title}
        </Link>
        {subtitle && (
          <>
            {isValidElement(subtitle) ? (
              subtitle
            ) : (
              <Typography variant="body2" color="common.grey6" noWrap>
                {subtitle}
              </Typography>
            )}
          </>
        )}
      </Stack>

      {headerContent}
    </Stack>
  );
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.common.grey3,
  [`& .${accordionSummaryClasses.root}`]: {
    padding: 0,
  },
  [`& .${accordionSummaryClasses.content}`]: {
    flexGrow: 0,
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    marginLeft: theme.spacing(1),
  },
  [`& .${accordionDetailsClasses.root}`]: {
    padding: 0,
  },
}));
