import React, { useCallback } from "react";
import { Loader } from "components/widgets/loader/loader";
import { useNewDealRequest } from "../../../../../hooks/use-new-deal-request";
import { useHistory, useParams } from "react-router-dom";
import { isLoaded } from "utils/utility-types";
import { OrganisationNewDealRequestDetailsPageView } from "./organisation-new-deal-request-details-page-view";
import { OrganisationAdvanceNewDealRequestResp } from "adl-gen/ferovinum/app/api";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { now } from "utils/date-utils";
import { AppRoutes } from "../../../../../app/app-routes";

export const OrganisationNewDealRequestDetailsPage = () => {
  const { purchaseOrderId } = useParams<{
    purchaseOrderId: string;
  }>();
  const [showAlert] = useAlert();
  const appService = useAppService();
  const { newDealRequest, refresh } = useNewDealRequest(purchaseOrderId);
  const history = useHistory();

  const handleOrgAction = useCallback(
    async (action: () => Promise<OrganisationAdvanceNewDealRequestResp>) => {
      const resp = await action();
      if (resp.kind !== "success") {
        switch (resp.kind) {
          case "invalidDealId":
            await showAlert({ title: "Invalid Deal Id", body: "This deal id was not found" });
            break;
          case "invalidAction":
            await showAlert({ title: "Invalid Action", body: resp.value });
        }
      } else {
        await refresh();
      }
    },
    [refresh, showAlert],
  );

  const markDepositAsPaid = useCallback(
    async (newDealRequestId: string) => {
      await handleOrgAction(async () =>
        appService.organisationAdvanceNewStockNewDealRequest({
          action: "depositPaid",
          newDealRequestId,
          actionTime: now(),
        }),
      );
    },
    [handleOrgAction, appService],
  );
  const acknowledgeDealTerms = useCallback(
    async (newDealRequestId: string, newStock: boolean, accept: boolean) => {
      if (newStock) {
        await handleOrgAction(async () =>
          appService.organisationAdvanceNewStockNewDealRequest({
            action: accept ? "confirmTerms" : "rejectTerms",
            newDealRequestId,
            actionTime: now(),
          }),
        );
      } else {
        await handleOrgAction(async () =>
          appService.organisationAdvanceExistingStockNewDealRequest({
            action: accept ? "confirmTerms" : "rejectTerms",
            newDealRequestId,
            actionTime: now(),
          }),
        );
      }
    },
    [handleOrgAction, appService],
  );
  return (
    <Loader loadingStates={[newDealRequest]} fullScreen>
      {isLoaded(newDealRequest) && (
        <OrganisationNewDealRequestDetailsPageView
          variant={
            newDealRequest.value.newDealRequestType.kind === "newStockNewDealRequest" ? "newStock" : "existingStock"
          }
          newDealRequest={newDealRequest.value}
          acknowledgeDealTerms={acceptOrReject =>
            acknowledgeDealTerms(
              newDealRequest.value.id,
              newDealRequest.value.newDealRequestType.kind === "newStockNewDealRequest",
              acceptOrReject,
            )
          }
          onCancel={async () => {
            await appService.cancelNewDealRequest(purchaseOrderId);
            history.push(AppRoutes.OrganisationNewDealRequests);
          }}
          onEdit={() => {
            history.push(AppRoutes.CreateNewDealRequest + "/" + purchaseOrderId);
          }}
          markDepositAsPaid={() => markDepositAsPaid(newDealRequest.value.id)}
          getDownloadUrl={
            newDealRequest.value.contractNote?.pdfUrl
              ? async () => {
                  if (newDealRequest.value.contractNote?.pdfUrl) {
                    const getDownloadUrlResp = await appService.getDownloadUrl({
                      downloadCategory: "ContractNote",
                      path: newDealRequest.value.contractNote.pdfUrl,
                    });
                    return getDownloadUrlResp.downloadUrl;
                  } else {
                    return undefined;
                  }
                }
              : undefined
          }
        />
      )}
    </Loader>
  );
};
