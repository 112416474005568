import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grow,
  IconButton,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { ProductBalance, ProductionOrderReservation, StockAdjustment } from "adl-gen/ferovinum/app/api";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import { PortalPageContent } from "../../../layouts/portal-page-content/portal-page-content";
import { OrganisationProductSummary } from "../../../widgets/organisation-product-summary/organisation-product-summary";
import { Product, TopLevelUnitType } from "adl-gen/ferovinum/app/db";
import { StockTypeToggle } from "../../../widgets/stock-type-toggle/stock-type-toggle";
import { styled } from "@mui/material/styles";
import { formatInstant, formatLocalDate } from "utils/date-utils";
import { Link } from "components/widgets/link/link";
import { AppRoutes } from "../../../../app/app-routes";
import { getVesselCapacity, isSinglesUnitType } from "utils/model-utils";
import { assertNotUndefined } from "utils/hx/util/types";
import {
  StorageLocProductBalancePageTab,
  StorageLocProductBalancesPageTypes,
} from "./storage-loc-product-balances-page";
import { isLoaded, LoadingValue } from "utils/utility-types";
import { Loader } from "components/widgets/loader/loader";
import { InfoTooltip } from "components/widgets/info-tooltip/info-tooltip";
import { PortalPageContentHeader } from "../../../../../../../apps/client/src/ui/layouts/portal-page-content-header/portal-page-content-header";

export interface StorageLocProductBalancesPageViewProps {
  selectedTab: StorageLocProductBalancePageTab;
  onChangeTab: (val: StorageLocProductBalancePageTab) => void;
  productBalances: LoadingValue<Map<TopLevelUnitType, ProductBalance[]>>;
  stockAdjustments: LoadingValue<Map<TopLevelUnitType, StockAdjustment[]>>;
  downloadAsCSV: (type: TopLevelUnitType) => Promise<void>;
  getProductionOrderReservations: (productId: string) => Promise<ProductionOrderReservation[]>;
}

export const StorageLocProductBalancesPageView = ({
  selectedTab,
  onChangeTab,
  productBalances,
  stockAdjustments,
  downloadAsCSV,
  getProductionOrderReservations,
}: StorageLocProductBalancesPageViewProps) => {
  return (
    <PortalPageContent header={<PortalPageContentHeader title="Product Balances" />}>
      <Stack spacing={4}>
        <Tabs value={selectedTab} onChange={(_, val: StorageLocProductBalancePageTab) => onChangeTab(val)}>
          {StorageLocProductBalancesPageTypes.map(t => (
            <Tab key={t} label={t} value={t} />
          ))}
        </Tabs>
        {selectedTab === "Current balance" && (
          <Loader loadingStates={[productBalances]}>
            {isLoaded(productBalances) && (
              <CurrentBalanceSection
                productBalances={productBalances.value}
                downloadAsCSV={downloadAsCSV}
                getProductionOrderReservations={getProductionOrderReservations}
              />
            )}
          </Loader>
        )}
        {selectedTab === "Stock adjustments" && (
          <Loader loadingStates={[stockAdjustments]}>
            {isLoaded(stockAdjustments) && <StockAdjustmentsSection stockAdjustments={stockAdjustments.value} />}
          </Loader>
        )}
      </Stack>
    </PortalPageContent>
  );
};

interface CurrentBalanceSectionProps {
  productBalances: Map<TopLevelUnitType, ProductBalance[]>;
  downloadAsCSV: (type: TopLevelUnitType) => Promise<void>;
  getProductionOrderReservations: (productId: string) => Promise<ProductionOrderReservation[]>;
}

const CurrentBalanceSection = ({
  productBalances,
  downloadAsCSV,
  getProductionOrderReservations,
}: CurrentBalanceSectionProps) => {
  const availableStockTypes = useMemo<Set<TopLevelUnitType>>(() => {
    return new Set([...productBalances.keys()].filter(type => productBalances.get(type)?.length ?? 0 > 0));
  }, [productBalances]);
  const [stockType, setStockType] = useState<TopLevelUnitType | undefined>(
    availableStockTypes.size > 0 ? [...availableStockTypes].sort()[0] : undefined,
  );

  useEffect(() => {
    // Force a refresh of the tab selection when admin users navigate between storage locations
    setStockType(availableStockTypes.size > 0 ? [...availableStockTypes].sort()[0] : undefined);
  }, [availableStockTypes]);

  return (
    <Stack spacing={2}>
      {availableStockTypes.size > 0 ? (
        <>
          <Typography variant="body1">
            These balances on Ferovinum should match the physical stock balances in your warehouse. Any discrepancies
            should be recorded in the ‘stock adjustments’ tab.
          </Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              {availableStockTypes.size > 1 && (
                <StockTypeToggle
                  topLevelUnitType={stockType}
                  onChange={setStockType}
                  availableTypes={availableStockTypes}
                />
              )}
            </Box>

            <LoadingActionButton
              disabled={stockType === undefined}
              variant="outlined"
              onClick={async () => stockType && (await downloadAsCSV(stockType))}>
              Download CSV
            </LoadingActionButton>
          </Stack>

          {stockType && (
            <>
              {isSinglesUnitType(stockType) ? (
                <SinglesProductBalancesView
                  productBalances={assertNotUndefined(productBalances.get(stockType))}
                  getProductionOrderReservations={getProductionOrderReservations}
                />
              ) : (
                <NonSinglesProductBalancesView productBalances={assertNotUndefined(productBalances.get(stockType))} />
              )}
            </>
          )}
        </>
      ) : (
        <Alert severity="info">{"You currently don't hold any products for Ferovinum Ltd."}</Alert>
      )}
    </Stack>
  );
};

interface StockAdjustmentsSectionProps {
  stockAdjustments: Map<TopLevelUnitType, StockAdjustment[]>;
}

const StockAdjustmentsSection = ({ stockAdjustments }: StockAdjustmentsSectionProps) => {
  const availableStockTypes = useMemo<Set<TopLevelUnitType>>(() => {
    return new Set([...stockAdjustments.keys()].filter(type => stockAdjustments.get(type)?.length ?? 0 > 0));
  }, [stockAdjustments]);
  const [stockType, setStockType] = useState<TopLevelUnitType | undefined>(
    availableStockTypes.size > 0 ? [...availableStockTypes].sort()[0] : undefined,
  );

  useEffect(() => {
    // Force a refresh of the tab selection when admin users navigate between storage locations
    setStockType(availableStockTypes.size > 0 ? [...availableStockTypes].sort()[0] : undefined);
  }, [availableStockTypes]);

  return (
    <>
      <Stack direction="row" spacing={3}>
        <Button href={`${AppRoutes.StorageLocStockAdjustmentSetup}/positiveStockAdjustment`}>
          Report additional stock
        </Button>
        <Button href={`${AppRoutes.StorageLocStockAdjustmentSetup}/negativeStockAdjustment`}>
          Report negative stock adjustment
        </Button>
      </Stack>
      <Stack spacing={2}>
        {availableStockTypes.size > 0 && (
          <>
            {availableStockTypes.size > 1 && (
              <Stack direction="row">
                <Box>
                  <StockTypeToggle
                    topLevelUnitType={stockType}
                    onChange={setStockType}
                    availableTypes={availableStockTypes}
                  />
                </Box>
              </Stack>
            )}

            {stockType && <AdjustmentsTable adjustments={assertNotUndefined(stockAdjustments.get(stockType))} />}
          </>
        )}
      </Stack>
    </>
  );
};

interface AdjustmentsTableProps {
  adjustments: StockAdjustment[];
}

const AdjustmentsTable = ({ adjustments }: AdjustmentsTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Reference number</TableCell>
            <TableCell>Stock adjustment type</TableCell>
            <TableCell>Report date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {adjustments.map(adjustment => (
            <TableRow key={adjustment.value.value.referenceNumber}>
              <TableCell align="left">
                <Link href={`${AppRoutes.StorageLocStockAdjustmentDetails}/${adjustment.kind}/${adjustment.value.id}`}>
                  {adjustment.value.value.referenceNumber}
                </Link>
              </TableCell>
              <TableCell>{adjustment.kind === "positiveStockAdjustment" ? "Positive" : "Negative"}</TableCell>
              <TableCell>{formatInstant(adjustment.value.value.createdAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface SinglesProductBalancesProps {
  productBalances: ProductBalance[];
  getProductionOrderReservations: (productId: string) => Promise<ProductionOrderReservation[]>;
}

const SinglesProductBalancesView = ({
  productBalances,
  getProductionOrderReservations,
}: SinglesProductBalancesProps) => {
  const [openRows, setOpenRows] = useState<Map<string, ProductionOrderReservation[]>>(new Map());
  const toggleOpenRow = useCallback(
    async (productId: string, hasReservations: boolean) => {
      const newOpenRows = new Map(openRows);
      if (openRows.has(productId)) {
        newOpenRows.delete(productId);
      } else {
        // fetch product production order reservations if any
        const productionOrderReservations = hasReservations
          ? await (async () => getProductionOrderReservations(productId))()
          : [];
        newOpenRows.set(productId, productionOrderReservations);
      }
      setOpenRows(newOpenRows);
    },
    [getProductionOrderReservations, openRows],
  );
  const isAnyRowOpened = useMemo(() => openRows.size > 0, [openRows.size]);
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="right">
              <Stack spacing={1} direction="row" justifyContent="flex-end">
                <Typography noWrap variant="inherit">
                  Total quantity
                </Typography>
                <InfoTooltip title="Stock that is currently physically present at your warehouse including stock that is reserved for production." />
              </Stack>
            </TableCell>
            <BottlesProductBalanceHeader showHiddenColumns={isAnyRowOpened} />
            <TableCell align="right">
              <Stack spacing={1} direction="row" justifyContent="flex-end">
                <Typography noWrap variant="inherit">
                  Unreconciled
                </Typography>
                <InfoTooltip title="Extra stock that was found at your location currently being onboarded onto Ferovinum. This quantity is not included in the ‘total quantity’ of stock." />
              </Stack>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {productBalances.map(pb => (
            <SinglesProductBalanceRow
              key={pb.product.id}
              open={openRows.has(pb.product.id)}
              showHiddenColumns={isAnyRowOpened}
              toggleOpen={() => toggleOpenRow(pb.product.id, pb.unitsReservedForProductionOrders.value > 0)}
              product={pb.product.value}
              totalUnitsAvailable={pb.unitsAvailable.value}
              totalUnitsReserved={pb.unitsReservedForProductionOrders.value}
              productReservations={openRows.get(pb.product.id) ?? []}
              unitsUnreconciled={pb.unitsUnreconciled.value}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BottlesProductBalanceHeader = (props: { showHiddenColumns: boolean }) => {
  return (
    <>
      {props.showHiddenColumns && (
        <>
          <TableCell align="left">
            <Typography noWrap variant="inherit">
              Order number
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography noWrap variant="inherit">
              Service
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography noWrap variant="inherit">
              Order date
            </Typography>
          </TableCell>
        </>
      )}
      <TableCell align="right">
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <Typography noWrap variant="inherit">
            Reserved for production
          </Typography>
          <InfoTooltip title="Stock that has been reserved for production orders." />
        </Stack>
      </TableCell>
    </>
  );
};

interface SinglesProductBalanceRowProps {
  product: Product;
  open: boolean;
  showHiddenColumns: boolean;
  toggleOpen: () => Promise<void>;
  totalUnitsAvailable: number;
  totalUnitsReserved: number;
  productReservations: ProductionOrderReservation[];
  unitsUnreconciled: number;
}
const SinglesProductBalanceRow = ({
  product,
  totalUnitsAvailable,
  totalUnitsReserved,
  toggleOpen,
  open,
  showHiddenColumns,
  productReservations,
  unitsUnreconciled,
}: SinglesProductBalanceRowProps) => {
  const [opening, setOpening] = useState(false);
  const toggleOpening = useCallback(async () => {
    if (totalUnitsReserved > 0 && !open) {
      setOpening(true);
    }
    await toggleOpen();
    setOpening(false);
  }, [open, toggleOpen, totalUnitsReserved]);

  return (
    <TableRow key={product.code}>
      <TableCell>
        <OrganisationProductSummary {...product} vesselCapacity={getVesselCapacity(product.vesselSize)} />
      </TableCell>
      <TableCell align="right">{totalUnitsAvailable + totalUnitsReserved}</TableCell>
      <BottlesProductBalanceExpandableCells
        open={open}
        showHiddenColumns={showHiddenColumns}
        totalUnitsReserved={totalUnitsReserved}
        productReservations={productReservations}
      />
      <TableCell align="right">{unitsUnreconciled}</TableCell>

      <TableCell align="center">
        {totalUnitsReserved !== 0 && (
          <IconButton onClick={toggleOpening}>
            {!open && !opening ? (
              <UnfoldMoreIcon />
            ) : opening ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <UnfoldLessIcon />
            )}
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

interface BottlesProductBalanceExpandableCellsProps {
  open: boolean;
  showHiddenColumns: boolean;
  totalUnitsReserved: number;
  productReservations: ProductionOrderReservation[];
}
const BottlesProductBalanceExpandableCells = ({
  open,
  showHiddenColumns,
  totalUnitsReserved,
  productReservations,
}: BottlesProductBalanceExpandableCellsProps) => {
  const ExpandedInnerCells = useCallback(
    (renderProperty: (pr: ProductionOrderReservation) => string, isLink = false) => (
      <Stack divider={<Divider flexItem />}>
        {productReservations.map(pr => (
          <React.Fragment key={pr.productionOrderId}>
            {isLink ? (
              <Link
                typographyProps={{ padding: 2 }}
                href={`${AppRoutes.LocProductionOrderDetails}/${pr.productionOrderId}`}>
                {renderProperty(pr)}
              </Link>
            ) : (
              <Typography padding={2}>{renderProperty(pr)}</Typography>
            )}
          </React.Fragment>
        ))}
      </Stack>
    ),
    [productReservations],
  );

  return (
    <>
      {showHiddenColumns && (
        <>
          <ExpandableCell open={open}>
            <Grow in={open}>{ExpandedInnerCells(pr => pr.orderNumber, true)}</Grow>
          </ExpandableCell>
          <ExpandableCell open={open}>
            <Grow in={open}>{ExpandedInnerCells(pr => pr.finishingServiceName)}</Grow>
          </ExpandableCell>
          <ExpandableCell open={open}>
            <Grow in={open}>{ExpandedInnerCells(pr => formatLocalDate(pr.orderedAt))}</Grow>
          </ExpandableCell>
        </>
      )}
      <ExpandableCell align="right" open={open}>
        <Grow in={open}>{ExpandedInnerCells(pr => pr.unitsReserved.value.toString())}</Grow>
        {!open && <Typography variant="inherit">{totalUnitsReserved}</Typography>}
      </ExpandableCell>
    </>
  );
};

const ExpandableCell = styled(TableCell, { shouldForwardProp: prop => prop !== "open" })<{
  open: boolean;
}>(({ open }) => ({ ...(open && { padding: 0 }) }));
ExpandableCell.defaultProps = {
  align: "left",
};

interface NonSinglesProductBalancesProps {
  productBalances: ProductBalance[];
}

const NonSinglesProductBalancesView = ({ productBalances }: NonSinglesProductBalancesProps) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productBalances.map((row, i) => (
            <TableRow key={i}>
              <TableCell>
                <OrganisationProductSummary
                  {...row.product.value}
                  vesselCapacity={getVesselCapacity(row.product.value.vesselSize, row.unitsAvailable)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
