import React, { useState } from "react";
import { Box, Stack, Tab, Tabs } from "@mui/material";

export const TabbedPage: React.FC<{
  defaultTab?: string;
  tabs: {
    label: string;
    key: string;
    content: React.ReactNode;
    toolbar?: React.ReactNode;
  }[];
  handleTabChange?: ({ index, key, label }: { index: number; key?: string; label: string }) => void;
}> = ({ defaultTab, tabs, handleTabChange }) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab ? tabs.findIndex(tab => tab.key === defaultTab) ?? 0 : 0);

  if (tabs.length === 0) {
    return null;
  }

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    if (handleTabChange) {
      handleTabChange({ index: newValue, key: tabs[newValue].key, label: tabs[newValue].label });
    }
  };

  return (
    <Stack spacing={4}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Tabs value={selectedTab} onChange={handleChange}>
          {tabs.map((tab, index) => (
            <Tab label={tab.label} value={index} key={index} />
          ))}
        </Tabs>
        {tabs[selectedTab]?.toolbar && <Box>{tabs[selectedTab].toolbar}</Box>}
      </Stack>
      <Box>{tabs[selectedTab].content}</Box>
    </Stack>
  );
};
