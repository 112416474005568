import { useMemo } from "react";
import { DbKey } from "adl-gen/common/db";
import { Organisation, StorageLocation } from "adl-gen/ferovinum/app/db";
import { useMembership } from "../ui/layouts/portal-page-layout/portal-page";
import { isLoaded, LoadingValue } from "utils/utility-types";

interface ProducerProps {
  organisationId?: DbKey<Organisation>;
  storageLocationId?: DbKey<StorageLocation>;
}
export const useIsProducer = ({ organisationId, storageLocationId }: ProducerProps): LoadingValue<boolean> => {
  const membership = useMembership();
  return useMemo(
    () =>
      isLoaded(membership)
        ? {
            state: "success",
            value:
              membership.value.organisations.some(o => o.id === organisationId) &&
              membership.value.storageLocations.some(sl => sl.id === storageLocationId),
          }
        : { state: "loading" },
    [membership, organisationId, storageLocationId],
  );
};
