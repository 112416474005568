import React from "react";
import { BRAND_COLORS, GREY_COLORS } from "../theme/color-palette";

export interface FerovinumLogoProps {
  color?: "purple" | "white";
}

export const FerovinumIcon = ({ color = "purple" }: FerovinumLogoProps) => {
  const fill = color === "purple" ? BRAND_COLORS.purple : GREY_COLORS.white;
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg" fill={fill}>
      <path d="M0.00134218 2.62301C0.00134218 2.05871 0.00103266 1.49479 0.00134218 0.930498C0.00165171 0.530091 0.227297 0.287612 0.559726 0.324487C0.947561 0.367694 1.33601 0.444795 1.72385 0.442933C2.92914 0.436601 4.13443 0.393394 5.33972 0.377006C6.78798 0.357265 8.23625 0.346835 9.68421 0.341248C10.1473 0.339386 10.5453 0.594901 10.931 0.867923C11.1566 1.02734 11.3714 1.20948 11.59 1.38342C11.8097 1.55849 12.049 1.6408 12.3139 1.649C12.9779 1.66948 13.6415 1.70673 14.3054 1.72982C14.4107 1.73355 14.5168 1.69667 14.623 1.68289C14.6939 1.67358 14.7666 1.6598 14.8369 1.66874C15.0074 1.69034 15.1777 1.72126 15.347 1.75441C15.4565 1.77601 15.5225 1.85944 15.5234 1.99428C15.5265 2.42187 15.5277 2.84984 15.5231 3.27744C15.5212 3.43872 15.4383 3.51508 15.3061 3.53035C15.165 3.54674 15.0251 3.57728 14.8839 3.59665C14.8226 3.60522 14.7564 3.61825 14.6991 3.59963C14.2104 3.43835 13.7176 3.56946 13.2273 3.5702C12.8373 3.57095 12.4452 3.60075 12.0576 3.65662C11.6862 3.71025 11.4213 4.03654 11.122 4.26896C10.597 4.67682 10.0516 4.9774 9.40007 4.94872C8.64823 4.91557 7.89485 4.93196 7.14239 4.92414C6.0962 4.91334 5.05 4.90328 4.00381 4.88652C3.11795 4.87236 2.2324 4.83959 1.34654 4.83661C1.06642 4.83586 0.786608 4.9219 0.50525 4.93829C0.182106 4.95692 0.00103175 4.72636 0.0004127 4.33377C-0.000515875 3.76352 0.0004127 3.19326 0.0004127 2.62264L0.00134218 2.62301Z" />
      <path d="M0.00103098 7.55242C0.00103098 7.00956 0.000721463 6.46633 0.00103098 5.92346C0.00103098 5.54476 0.195098 5.315 0.522258 5.3266C0.696207 5.33276 0.868611 5.38603 1.04256 5.39256C1.45329 5.40741 1.86464 5.40959 2.27568 5.4114C2.51277 5.41249 2.74986 5.40053 2.98695 5.39799C4.28569 5.38422 5.58442 5.37262 6.88316 5.35849C7.66655 5.35015 8.45056 5.36393 9.2324 5.32044C9.93965 5.2813 10.555 5.52881 11.1251 5.98724C11.2817 6.11299 11.4405 6.23548 11.5962 6.36268C11.8002 6.52975 12.0314 6.61092 12.274 6.62252C12.9686 6.65513 13.6635 6.67579 14.3583 6.69645C14.4434 6.69898 14.5295 6.67253 14.6149 6.6584C14.6703 6.64897 14.7263 6.62361 14.7805 6.62904C14.973 6.64825 15.1659 6.66891 15.3562 6.70623C15.4615 6.72689 15.5252 6.81568 15.5252 6.95049C15.5252 7.36688 15.528 7.78327 15.524 8.19929C15.5224 8.36672 15.4262 8.4421 15.2757 8.44319C15.1625 8.44391 15.0495 8.47725 14.9362 8.49356C14.865 8.50371 14.7932 8.51748 14.7217 8.51603C14.1782 8.50697 13.6344 8.48667 13.0909 8.48667C12.7823 8.48667 12.4731 8.51204 12.1651 8.53813C11.9069 8.55988 11.6869 8.70012 11.4783 8.87081C11.0069 9.25676 10.533 9.63836 9.95389 9.77607C9.88858 9.79165 9.82142 9.80144 9.75487 9.80108C8.13052 9.79673 6.50617 9.7931 4.88182 9.78332C4.24793 9.77933 3.61403 9.76484 2.98045 9.7478C2.56384 9.73657 2.14692 9.69055 1.73093 9.70105C1.36229 9.71048 0.994583 9.77353 0.627185 9.81847C0.244312 9.86558 -0.00577932 9.62858 0.00010152 9.19914C0.00752995 8.65011 0.00196044 8.10109 0.00196044 7.55206L0.00103098 7.55242Z" />
      <path d="M0.000137559 12.3801C0.000137559 11.8442 -0.000171949 11.3085 0.000137559 10.7726C0.000447066 10.4015 0.179342 10.1903 0.507111 10.1994C0.706124 10.2051 0.904209 10.2585 1.10322 10.2642C1.5192 10.2759 1.93549 10.2745 2.35146 10.2706C3.51614 10.2596 4.68082 10.244 5.8458 10.2316C7.03586 10.2189 8.22653 10.2224 9.41628 10.1903C9.98175 10.175 10.4736 10.3785 10.9394 10.7071C11.1709 10.8706 11.3953 11.0467 11.6197 11.2226C11.8976 11.4409 12.2179 11.4476 12.5339 11.4656C13.0685 11.496 13.6033 11.5222 14.1384 11.535C14.3093 11.5389 14.4814 11.473 14.6519 11.4776C14.9001 11.4847 15.1493 11.5155 15.3938 11.5636C15.4461 11.5739 15.5154 11.6998 15.5176 11.7748C15.5297 12.1806 15.5272 12.5874 15.521 12.9936C15.5191 13.1195 15.465 13.2136 15.3365 13.2281C15.1858 13.2451 15.0363 13.2763 14.8856 13.2943C14.8091 13.3035 14.7311 13.2971 14.6538 13.2968C14.0642 13.2943 13.4745 13.2869 12.8849 13.2915C12.6175 13.2936 12.3482 13.3028 12.0833 13.3399C11.7329 13.3891 11.4764 13.6626 11.1947 13.8738C10.7824 14.1833 10.3594 14.471 9.85826 14.5491C9.71619 14.5714 9.57042 14.569 9.42619 14.5682C8.66325 14.5647 7.90031 14.559 7.13738 14.552C5.93649 14.5414 4.7356 14.5329 3.53502 14.5155C2.96367 14.5074 2.39232 14.4635 1.82128 14.4699C1.41706 14.4741 1.01347 14.5353 0.60956 14.5714C0.198843 14.6082 0.000757178 14.4016 0.000757178 13.9346C0.000757178 13.4163 0.000757178 12.8984 0.000757178 12.3801H0.000137559Z" />
    </svg>
  );
};

export const FerovinumLogo = ({ color = "purple" }: FerovinumLogoProps) => {
  const fill = color === "purple" ? BRAND_COLORS.purple : GREY_COLORS.white;
  return (
    <svg width="156" height="15" viewBox="0 0 156 15" xmlns="http://www.w3.org/2000/svg" fill={fill}>
      <path d="M0.00134218 2.62301C0.00134218 2.05871 0.00103266 1.49479 0.00134218 0.930498C0.00165171 0.530091 0.227297 0.287612 0.559726 0.324487C0.947561 0.367694 1.33601 0.444795 1.72385 0.442933C2.92914 0.436601 4.13443 0.393394 5.33972 0.377006C6.78798 0.357265 8.23625 0.346835 9.68421 0.341248C10.1473 0.339386 10.5453 0.594901 10.931 0.867923C11.1566 1.02734 11.3714 1.20948 11.59 1.38342C11.8097 1.55849 12.049 1.6408 12.3139 1.649C12.9779 1.66948 13.6415 1.70673 14.3054 1.72982C14.4107 1.73355 14.5168 1.69667 14.623 1.68289C14.6939 1.67358 14.7666 1.6598 14.8369 1.66874C15.0074 1.69034 15.1777 1.72126 15.347 1.75441C15.4565 1.77601 15.5225 1.85944 15.5234 1.99428C15.5265 2.42187 15.5277 2.84984 15.5231 3.27744C15.5212 3.43872 15.4383 3.51508 15.3061 3.53035C15.165 3.54674 15.0251 3.57728 14.8839 3.59665C14.8226 3.60522 14.7564 3.61825 14.6991 3.59963C14.2104 3.43835 13.7176 3.56946 13.2273 3.5702C12.8373 3.57095 12.4452 3.60075 12.0576 3.65662C11.6862 3.71025 11.4213 4.03654 11.122 4.26896C10.597 4.67682 10.0516 4.9774 9.40007 4.94872C8.64823 4.91557 7.89485 4.93196 7.14239 4.92414C6.0962 4.91334 5.05 4.90328 4.00381 4.88652C3.11795 4.87236 2.2324 4.83959 1.34654 4.83661C1.06642 4.83586 0.786608 4.9219 0.50525 4.93829C0.182106 4.95692 0.00103175 4.72636 0.0004127 4.33377C-0.000515875 3.76352 0.0004127 3.19326 0.0004127 2.62264L0.00134218 2.62301Z" />
      <path d="M0.00103098 7.55242C0.00103098 7.00956 0.000721463 6.46633 0.00103098 5.92346C0.00103098 5.54476 0.195098 5.315 0.522258 5.3266C0.696207 5.33276 0.868611 5.38603 1.04256 5.39256C1.45329 5.40741 1.86464 5.40959 2.27568 5.4114C2.51277 5.41249 2.74986 5.40053 2.98695 5.39799C4.28569 5.38422 5.58442 5.37262 6.88316 5.35849C7.66655 5.35015 8.45056 5.36393 9.2324 5.32044C9.93965 5.2813 10.555 5.52881 11.1251 5.98724C11.2817 6.11299 11.4405 6.23548 11.5962 6.36268C11.8002 6.52975 12.0314 6.61092 12.274 6.62252C12.9686 6.65513 13.6635 6.67579 14.3583 6.69645C14.4434 6.69898 14.5295 6.67253 14.6149 6.6584C14.6703 6.64897 14.7263 6.62361 14.7805 6.62904C14.973 6.64825 15.1659 6.66891 15.3562 6.70623C15.4615 6.72689 15.5252 6.81568 15.5252 6.95049C15.5252 7.36688 15.528 7.78327 15.524 8.19929C15.5224 8.36672 15.4262 8.4421 15.2757 8.44319C15.1625 8.44391 15.0495 8.47725 14.9362 8.49356C14.865 8.50371 14.7932 8.51748 14.7217 8.51603C14.1782 8.50697 13.6344 8.48667 13.0909 8.48667C12.7823 8.48667 12.4731 8.51204 12.1651 8.53813C11.9069 8.55988 11.6869 8.70012 11.4783 8.87081C11.0069 9.25676 10.533 9.63836 9.95389 9.77607C9.88858 9.79165 9.82142 9.80144 9.75487 9.80108C8.13052 9.79673 6.50617 9.7931 4.88182 9.78332C4.24793 9.77933 3.61403 9.76484 2.98045 9.7478C2.56384 9.73657 2.14692 9.69055 1.73093 9.70105C1.36229 9.71048 0.994583 9.77353 0.627185 9.81847C0.244312 9.86558 -0.00577932 9.62858 0.00010152 9.19914C0.00752995 8.65011 0.00196044 8.10109 0.00196044 7.55206L0.00103098 7.55242Z" />
      <path d="M0.000137559 12.3801C0.000137559 11.8442 -0.000171949 11.3085 0.000137559 10.7726C0.000447066 10.4015 0.179342 10.1903 0.507111 10.1994C0.706124 10.2051 0.904209 10.2585 1.10322 10.2642C1.5192 10.2759 1.93549 10.2745 2.35146 10.2706C3.51614 10.2596 4.68082 10.244 5.8458 10.2316C7.03586 10.2189 8.22653 10.2224 9.41628 10.1903C9.98175 10.175 10.4736 10.3785 10.9394 10.7071C11.1709 10.8706 11.3953 11.0467 11.6197 11.2226C11.8976 11.4409 12.2179 11.4476 12.5339 11.4656C13.0685 11.496 13.6033 11.5222 14.1384 11.535C14.3093 11.5389 14.4814 11.473 14.6519 11.4776C14.9001 11.4847 15.1493 11.5155 15.3938 11.5636C15.4461 11.5739 15.5154 11.6998 15.5176 11.7748C15.5297 12.1806 15.5272 12.5874 15.521 12.9936C15.5191 13.1195 15.465 13.2136 15.3365 13.2281C15.1858 13.2451 15.0363 13.2763 14.8856 13.2943C14.8091 13.3035 14.7311 13.2971 14.6538 13.2968C14.0642 13.2943 13.4745 13.2869 12.8849 13.2915C12.6175 13.2936 12.3482 13.3028 12.0833 13.3399C11.7329 13.3891 11.4764 13.6626 11.1947 13.8738C10.7824 14.1833 10.3594 14.471 9.85826 14.5491C9.71619 14.5714 9.57042 14.569 9.42619 14.5682C8.66325 14.5647 7.90031 14.559 7.13738 14.552C5.93649 14.5414 4.7356 14.5329 3.53502 14.5155C2.96367 14.5074 2.39232 14.4635 1.82128 14.4699C1.41706 14.4741 1.01347 14.5353 0.60956 14.5714C0.198843 14.6082 0.000757178 14.4016 0.000757178 13.9346C0.000757178 13.4163 0.000757178 12.8984 0.000757178 12.3801H0.000137559Z" />
      <path d="M25.1523 0.360444C25.2151 0.346072 25.2779 0.318867 25.3407 0.318867C27.6646 0.316814 29.988 0.31733 32.3118 0.317843C32.3402 0.317843 32.3685 0.324001 32.4117 0.329134V1.52255H26.3276V6.60318H31.9804V7.793H26.3136V14.5629H25.1523C25.1523 9.82875 25.1523 5.0946 25.1523 0.360444Z" />
      <path d="M154.757 2.1553C154.535 2.75997 154.313 3.36463 154.09 3.9693C153.399 5.8454 152.705 7.721 152.015 9.59761C151.439 11.1621 150.866 12.7272 150.294 14.2938C150.171 14.631 150.048 14.7214 149.746 14.6243C149.68 14.6033 149.617 14.4986 149.585 14.4149C149.207 13.4145 148.834 12.411 148.46 11.408C147.956 10.056 147.454 8.70293 146.95 7.35091C146.339 5.71041 145.729 4.07042 145.117 2.43094C145.077 2.32623 145.029 2.22665 144.945 2.13528V14.6146H143.832V0.367998C144.364 0.367998 144.892 0.363892 145.419 0.374671C145.469 0.375698 145.538 0.460391 145.562 0.524553C146.015 1.74518 146.461 2.96888 146.913 4.19053C147.468 5.69244 148.028 7.19179 148.584 8.6937C148.999 9.81423 149.41 10.9363 149.824 12.0573C149.849 12.1256 149.882 12.1903 149.931 12.3017C150.112 11.8166 150.28 11.3731 150.444 10.9286C151.109 9.13103 151.774 7.33346 152.437 5.53486C153.025 3.93953 153.618 2.34625 154.194 0.744757C154.294 0.46655 154.401 0.334634 154.678 0.358759C155.095 0.39469 155.516 0.367998 155.962 0.367998V0.669304C155.962 5.22329 155.96 9.77727 155.967 14.3307C155.967 14.5858 155.9 14.6562 155.695 14.6397C155.456 14.6208 155.215 14.6315 154.976 14.6362C154.858 14.6382 154.798 14.5894 154.809 14.4421C154.815 14.3744 154.81 14.3056 154.81 14.2373C154.81 10.3157 154.81 6.39412 154.81 2.47252C154.81 2.37089 154.81 2.26925 154.81 2.16762C154.792 2.16403 154.774 2.15992 154.756 2.15633L154.757 2.1553Z" />
      <path d="M119.017 12.7926V0.382595H120.125V14.6318C119.666 14.6318 119.217 14.6379 118.77 14.6246C118.709 14.6225 118.635 14.5342 118.593 14.4644C117.471 12.5724 116.352 10.6778 115.233 8.78375C113.957 6.62431 112.682 4.46434 111.406 2.30438C111.379 2.25921 111.348 2.21867 111.284 2.12627V14.6312C110.922 14.6312 110.583 14.6354 110.244 14.6256C110.209 14.6246 110.161 14.5476 110.147 14.4957C110.129 14.4341 110.14 14.3607 110.14 14.2925C110.14 9.76364 110.14 5.23429 110.14 0.705462V0.369763C110.619 0.369763 111.075 0.36463 111.529 0.376436C111.584 0.377976 111.651 0.457026 111.689 0.519648C112.988 2.71195 114.284 4.90681 115.581 7.10065C116.689 8.97316 117.797 10.8452 118.906 12.7172C118.925 12.7505 118.949 12.7798 118.971 12.8111C118.987 12.8054 119.002 12.7998 119.017 12.7942L119.017 12.7926Z" />
      <path d="M73.6142 0C75.0676 0.04825 76.3152 0.433736 77.4119 1.36845C78.5619 2.34783 79.2749 3.68702 79.6272 5.28287C80.038 7.14409 79.987 8.99042 79.3289 10.7752C78.8235 12.1462 77.9989 13.1969 76.917 13.9715C75.914 14.6896 74.8073 15.0129 73.6495 14.9996C71.0583 14.9693 69.0999 13.6655 67.9587 10.8676C67.4014 9.50064 67.2671 8.03876 67.3935 6.53531C67.4725 5.59546 67.6778 4.69976 68.0407 3.86051C68.4641 2.88114 69.058 2.06448 69.8155 1.41567C70.6754 0.679091 71.6344 0.245355 72.691 0.110357C73.0359 0.0662136 73.3817 0.0282288 73.6137 0.000510667L73.6142 0ZM73.588 13.7672C74.2574 13.7656 74.915 13.6758 75.5442 13.3909C77.0142 12.7262 77.9505 11.4681 78.4441 9.73778C78.8069 8.46634 78.8375 7.16205 78.5981 5.85879C78.2697 4.07097 77.4446 2.67326 76.0322 1.88072C74.413 0.972185 72.742 0.939847 71.1233 1.90331C69.9481 2.60293 69.1897 3.7635 68.7811 5.23359C68.458 6.39621 68.3991 7.58501 68.5674 8.78921C68.707 9.78706 69.0231 10.7012 69.553 11.5025C70.5756 13.0486 71.9418 13.7554 73.588 13.7677V13.7672Z" />
      <path d="M58.0215 7.81445C59.1867 10.076 60.3462 12.3274 61.5328 14.63C61.0962 14.63 60.7016 14.6372 60.3074 14.6218C60.2524 14.6198 60.1866 14.5135 60.1491 14.4391C59.5168 13.1897 58.8871 11.9383 58.2583 10.6869C57.8244 9.82401 57.39 8.96115 56.9614 8.09471C56.8807 7.93148 56.7909 7.85602 56.6186 7.86116C56.0739 7.87707 55.5293 7.8668 54.9842 7.86732C54.9127 7.86732 54.8412 7.8745 54.7426 7.87963C54.7426 7.99359 54.7426 8.09317 54.7426 8.19275C54.7426 10.2264 54.7426 12.2596 54.7426 14.2933C54.7426 14.5205 54.6425 14.6343 54.4422 14.6346C54.2171 14.6346 53.9917 14.629 53.7667 14.6362C53.6345 14.6408 53.5783 14.5802 53.5918 14.4252C53.5962 14.3744 53.5922 14.3226 53.5922 14.2717C53.5922 9.75215 53.5922 5.23205 53.5922 0.712453V0.381375C53.6729 0.375215 53.7226 0.367515 53.7723 0.367515C54.9275 0.368541 56.0827 0.349551 57.2374 0.378295C58.0498 0.398827 58.8513 0.541524 59.5883 0.98758C60.494 1.53527 60.9571 2.42687 61.0736 3.60489C61.1394 4.27064 61.0967 4.91483 60.9026 5.54002C60.5908 6.54403 59.9446 7.13741 59.1125 7.47259C58.7658 7.61221 58.403 7.6969 58.0219 7.81393L58.0215 7.81445ZM54.7526 6.62052C54.8037 6.62976 54.8455 6.64464 54.8878 6.64464C55.6793 6.64464 56.4712 6.6431 57.2627 6.64207C57.8928 6.64156 58.4964 6.53069 59.048 6.13134C60.0575 5.4004 60.2904 3.60438 59.5033 2.55981C58.9433 1.81656 58.1902 1.6292 57.4053 1.59327C56.5929 1.55631 55.7787 1.58455 54.965 1.58455C54.8957 1.58455 54.8263 1.58455 54.7526 1.58455V6.62052Z" />
      <path d="M39.084 0.330566H46.4655V1.52399H40.1942C40.1942 1.64461 40.1942 1.74265 40.1942 1.84069C40.1942 3.31848 40.1942 4.79626 40.1942 6.27456C40.1942 6.50007 40.2871 6.61299 40.4729 6.61334C42.2451 6.61334 44.0173 6.61334 45.7895 6.61334C45.8759 6.61334 45.9622 6.61334 46.0673 6.61334V7.79752C45.9788 7.79752 45.8942 7.79752 45.8096 7.79752C44.023 7.79752 42.2359 7.80059 40.4493 7.7929C40.257 7.79187 40.1877 7.84012 40.189 8.0865C40.1986 9.75216 40.1977 11.4183 40.1899 13.084C40.1886 13.3165 40.2396 13.3873 40.4432 13.3863C42.4553 13.3776 44.4669 13.3812 46.479 13.3812H46.752V14.5417H39.0844V0.330566H39.084Z" />
      <path d="M135.32 0.38091H136.447C136.452 0.484083 136.461 0.582635 136.461 0.681189C136.462 3.43247 136.464 6.18324 136.46 8.93451C136.458 10.0201 136.366 11.0883 136.006 12.1072C135.477 13.6035 134.549 14.5356 133.2 14.8544C132.22 15.0854 131.241 15.052 130.289 14.6619C129.269 14.2441 128.567 13.4136 128.125 12.2653C127.732 11.2444 127.617 10.1598 127.614 9.05617C127.606 6.25356 127.611 3.45146 127.611 0.648851C127.611 0.565183 127.618 0.481002 127.622 0.382449H128.761C128.761 0.498967 128.761 0.607276 128.761 0.715582C128.763 3.62906 128.756 6.54254 128.774 9.45603C128.779 10.3286 128.934 11.1756 129.285 11.9625C129.638 12.7555 130.163 13.3088 130.893 13.5691C131.665 13.8452 132.45 13.8596 133.22 13.5701C134.161 13.217 134.706 12.3931 135.006 11.3326C135.221 10.5709 135.316 9.77684 135.317 8.9725C135.32 6.22122 135.319 3.47045 135.32 0.719172C135.32 0.617026 135.32 0.51488 135.32 0.379883V0.38091Z" />
      <path d="M95.334 0.367243C95.0475 1.25987 94.7749 2.11605 94.4984 2.97018C93.7318 5.34008 92.9626 7.70844 92.1964 10.0788C91.725 11.5371 91.2593 12.998 90.7826 14.4537C90.7569 14.5317 90.657 14.6215 90.5877 14.6262C90.3056 14.6457 90.0208 14.6457 89.7387 14.6262C89.6711 14.6215 89.5738 14.5297 89.549 14.4522C88.8194 12.1839 88.096 9.91254 87.3743 7.64068C86.6303 5.29799 85.889 2.95376 85.1472 0.610036C85.125 0.540227 85.1097 0.467337 85.084 0.367757C85.469 0.367757 85.8305 0.361086 86.1916 0.375458C86.2453 0.377511 86.3216 0.468877 86.3434 0.538173C86.7834 1.92921 87.2168 3.32282 87.6512 4.71642C88.4823 7.38249 89.3135 10.0481 90.1451 12.7141C90.1521 12.7362 90.1647 12.7562 90.1992 12.827C90.289 12.5622 90.3701 12.3363 90.4425 12.1059C91.6142 8.37829 92.786 4.65021 93.9555 0.921094C94.1286 0.369812 94.1239 0.367757 94.624 0.367757H95.334V0.367243Z" />
      <path d="M102.419 14.6168H101.326V0.388672H102.419V14.6168Z" />
    </svg>
  );
};
