import React from "react";

import { Stack, Typography } from "@mui/material";
import { PortalPageContentHeader } from "../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../layouts/portal-page-content/portal-page-content";

export const AlreadyLoggedInPage = () => {
  return (
    <PortalPageContent
      header={
        <Stack spacing={2}>
          <PortalPageContentHeader title={"Already logged in"} />
        </Stack>
      }>
      <Stack>
        <Typography variant="body1">You are already logged in, to use this link, log out and try again</Typography>
      </Stack>
    </PortalPageContent>
  );
};
