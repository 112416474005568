import React, { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { DebouncedTextField } from "../text-input/debounced-text-field";

export interface SearchInputProps {
  label?: string;
  onChange: (val: string) => Promise<void> | void;
}
export const SearchInput = ({ label, onChange }: SearchInputProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const onClear = () => {
    if (searchTerm) {
      setSearchTerm("");
      onChange("");
    }
  };

  return (
    <DebouncedTextField
      label={label ?? "Search"}
      variant="outlined"
      value={searchTerm}
      onChange={e => {
        const value = e.target.value;
        setSearchTerm(value);
        onChange(value.trim());
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton onClick={onClear}>
            <CancelOutlinedIcon sx={{ color: theme => theme.palette.common.grey4 }} fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
};
