import React from "react";
import { Grid, Stack, Typography } from "@mui/material";

export interface QuestionContainerProps {
  question?: string;
  Input: React.ReactNode;
  Feedback: React.ReactNode;
  direction?: "column" | "row";
}
export const QuestionContainer = ({ question, Input, Feedback, direction = "row" }: QuestionContainerProps) => {
  return (
    <Stack spacing={1}>
      {question && <Typography>{question}</Typography>}
      <Grid container spacing={2} alignItems={direction === "column" ? "" : "center"} direction={direction}>
        <Grid item xs={12} lg={4}>
          {Input}
        </Grid>

        <Grid item xs={12} lg={8}>
          {Feedback}
        </Grid>
      </Grid>
    </Stack>
  );
};
