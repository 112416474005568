import React from "react";
import { Alert, Box } from "@mui/material";
import { List, Stack, Typography } from "@mui/material";
import { InfoField } from "components/widgets/info-field/info-field";
import { ProductDate } from "adl-gen/ferovinum/app/db";
import { assertNever } from "utils/hx/util/types";
import { QrProductInfoResp } from "adl-gen/ferovinum/app/api";
import { unitTypeToString } from "utils/conversion-utils";
import { formatNumberOfUnits, getVesselCapacity } from "utils/model-utils";

export interface QrProductInfoPageViewProps {
  info: QrProductInfoResp | null;
}

function formatProductDate(productDate: ProductDate): string {
  if (productDate.kind === "vintageYear") {
    return `${productDate.value}`;
  } else if (productDate.kind === "nonVintage") {
    return "Non-vintage";
  } else {
    assertNever(productDate);
  }
}

export const QrProductInfoPageView: React.FC<QrProductInfoPageViewProps> = ({ info }) => {
  if (info === null) {
    return (
      <Box>
        <Alert severity={"warning"}>{"No product found for QR code"}</Alert>
      </Box>
    );
  } else {
    const vesselCapacity = getVesselCapacity(info.vesselSize);
    return (
      <Stack spacing={2}>
        <Typography variant={"h3"}>{info.productName}</Typography>
        <Typography variant={"h4"}>{info.productCode}</Typography>
        <List>
          <InfoField label={"Location"} value={info.storageLocationName} />
          <InfoField label={"Producer"} value={info.producerName} />
          <InfoField label={"Year"} value={formatProductDate(info.productDate)} />
          <InfoField
            label={"Unit Size"}
            value={vesselCapacity ? `${vesselCapacity.value} ${vesselCapacity.unitLabel}` : ""}
          />
          <InfoField label={"Unit Type"} value={unitTypeToString(info.unitType)} />
          <InfoField label={"Product Type"} value={info.productType} />
          <InfoField label={"Leg Identifier"} value={info.legIdentifier} />
          <InfoField label={"Rotation Number"} value={info.rotationNumber} />
          <InfoField label={"Units Remaining"} value={formatNumberOfUnits(info.unitsRemaining, info.unitType)} />
        </List>
      </Stack>
    );
  }
};
