import React from "react";
import {
  FormControl,
  Radio,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  FormControlLabel,
} from "@mui/material";

export interface RadioOption {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface RadioGroupProps extends Omit<MuiRadioGroupProps, "onChange"> {
  options: RadioOption[];
  onChange: (value: string) => void;
}

export const RadioGroup: React.FC<RadioGroupProps> = ({ options, value, onChange }) => {
  const handleRadioChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    onChange(value);
  };
  return (
    <FormControl>
      <MuiRadioGroup value={value} onChange={handleRadioChange}>
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
            disabled={option.disabled}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};
