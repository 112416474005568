import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useUiConfig } from "adl-service/hooks/use-ui-config";
import {
  PurchaseRequestFlowState,
  StorageLocationProductSale,
  extractNonFinishedProducts,
} from "../organisation-purchase-request-setup/organisation-purchase-request-setup-page";
import { AppRoutes } from "../../../../../app/app-routes";
import React, { useCallback, useMemo } from "react";
import {
  OrganisationPurchaseRequestProductionPlanPageView,
  ProductionPlanPurchaseRequest,
} from "./organisation-purchase-request-production-plan-page-view";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { assertNotUndefined } from "utils/hx/util/types";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { Loader } from "components/widgets/loader/loader";
import { isLoaded } from "utils/utility-types";
import { singles } from "utils/model-utils";
import { FinishedProductId, ProductionOptionsForFinishedProduct } from "adl-gen/ferovinum/app/api";
import { WithDbId } from "adl-gen/common/db";
import { Product } from "adl-gen/ferovinum/app/db";
import { useIsProducer } from "../../../../../hooks/use-is-producer";

export const OrganisationPurchaseRequestProductionPlanPage = () => {
  const location = useLocation();
  const history = useHistory();
  const service = useAppService();
  const organisationId = assertNotUndefined(useSelectedOrgId());
  const { showProductionOrder } = useUiConfig();
  const purchaseRequestFlowState = location.state as PurchaseRequestFlowState | undefined;
  const isProducer = useIsProducer({
    storageLocationId: purchaseRequestFlowState?.setup?.storageLocation?.id,
    organisationId,
  });

  const loadProductionOrderMappings = useCallback(async () => {
    const flowState = assertNotUndefined(purchaseRequestFlowState?.setup);
    const resp = await service.getProductionOptionsForFinishedProducts({
      organisationId,
      storageLocationId: assertNotUndefined(flowState?.storageLocation?.id),
      finishedProducts: extractNonFinishedProducts(flowState.products).map(p => p.product.id),
    });
    const result = new Map<FinishedProductId, Map<WithDbId<Product>, ProductionOptionsForFinishedProduct[]>>();
    resp.forEach(({ key: finishedProductId, value: productionOptions }) => {
      const mappings = new Map<WithDbId<Product>, ProductionOptionsForFinishedProduct[]>();
      productionOptions.forEach(po => {
        const alreadyAddedSrcProduct = [...mappings.keys()].find(p => p.id === po.sourceProduct.id);
        if (!alreadyAddedSrcProduct) {
          mappings.set(po.sourceProduct, [po]);
        } else {
          mappings.get(alreadyAddedSrcProduct)?.push(po);
        }
      });
      result.set(finishedProductId, mappings);
    });
    return result;
  }, [organisationId, purchaseRequestFlowState?.setup, service]);
  const [loadingProductionOrderMappings] = useLoadingDataState(loadProductionOrderMappings);

  const onNext = async (productionPlan: ProductionPlanPurchaseRequest) => {
    const newState: PurchaseRequestFlowState = {
      ...purchaseRequestFlowState,
      productionPlan,
    };
    history.push(AppRoutes.OrganisationPurchaseRequestDeliveryOptions, newState);
  };

  const onBack = useCallback(() => {
    history.push(AppRoutes.OrganisationCreatePurchaseRequest, purchaseRequestFlowState);
  }, [history, purchaseRequestFlowState]);

  const initialProductionPlan = useMemo(() => {
    return getInitialProductionPlan(
      extractNonFinishedProducts(purchaseRequestFlowState?.setup?.products ?? []),
      purchaseRequestFlowState?.productionPlan,
    );
  }, [purchaseRequestFlowState?.productionPlan, purchaseRequestFlowState?.setup?.products]);

  if (
    !showProductionOrder ||
    purchaseRequestFlowState?.setup === undefined ||
    extractNonFinishedProducts(purchaseRequestFlowState?.setup.products ?? []).length === 0
  ) {
    return <Redirect to={AppRoutes.Index} />;
  } else {
    return (
      <Loader loadingStates={[loadingProductionOrderMappings, isProducer]} fullScreen>
        {isLoaded(loadingProductionOrderMappings) && isLoaded(isProducer) && (
          <OrganisationPurchaseRequestProductionPlanPageView
            initialProductionPlan={initialProductionPlan}
            productionOrderMappings={loadingProductionOrderMappings.value}
            onNext={onNext}
            onBack={onBack}
            isProducer={isProducer.value}
          />
        )}
      </Loader>
    );
  }
};

function getInitialProductionPlan(
  nonFinishedProducts: StorageLocationProductSale[],
  previousPlan?: ProductionPlanPurchaseRequest,
): ProductionPlanPurchaseRequest {
  if (previousPlan === undefined) {
    return nonFinishedProducts.map(sale => ({
      finishedProduct: { product: sale.product, targetQuantity: singles(sale.paidUnits + (sale.freeUnits ?? 0)) },
      productionOrders: [],
    }));
  } else {
    // Remove those products in the previous plan that are not in the current plan
    return previousPlan.filter(plan =>
      nonFinishedProducts.some(sale => sale.product.id === plan.finishedProduct.product.id),
    );
  }
}
