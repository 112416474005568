import {
  Alert,
  Checkbox,
  inputBaseClasses,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { WithDbId } from "adl-gen/common/db";
import {
  PriceRange,
  ThirdPartySaleDealLegStage,
  ThirdPartySaleFinishableProductListing,
  ThirdPartySaleFinishedProductListing,
} from "adl-gen/ferovinum/app/api";
import { Currency, Product, PurchaseRequestSalePriceType } from "adl-gen/ferovinum/app/db";
import { CurrencyRange } from "components/widgets/currency-renderer/currency-range";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import { GroupHeaderCell } from "components/widgets/group-header-cell";
import { InfoTooltip } from "components/widgets/info-tooltip/info-tooltip";
import { CurrencyInput } from "components/widgets/inputs/currency-input/currency-input";
import { RoundingInput } from "components/widgets/inputs/rounding-input/rounding-input";
import { UnitsInput } from "components/widgets/inputs/units-input/units-input";
import { FieldArray, FormikErrors, FormikProps, FormikProvider } from "formik";
import React, {
  createContext,
  memo,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { assertNever } from "utils/hx/util/types";
import { getVesselCapacity, unitsLabelForUnitType } from "utils/model-utils";
import { doCalc, limitPrecision, parseNumber } from "utils/numeric-utils";
import { noop } from "utils/ts-utils";
import { collectToRecord, filterUnionByKind } from "utils/type-utils";
import { useSettlementCurrency } from "../../../layouts/portal-page-layout/portal-page";
import {
  FormStorageLocProductSale,
  SetupPurchaserRequestFormValues,
} from "../../../page/organisation/purchase-requests/organisation-purchase-request-setup/organisation-purchase-request-setup-page-view";
import { ScrollToFieldError } from "../../common/form/scroll-to-field-error/scroll-to-field-error";
import { InvisibleCell } from "../../common/table/table-cell/invisible-cell";
import { OrganisationProductSummary } from "../../organisation-product-summary/organisation-product-summary";
import { BigDecimal } from "adl-gen/common";

const ProductsTableContext = createContext<ProductsTableContext>({
  bonded: true,
  salePriceType: "exDutyAndVat",
  productListings: [],
  features: {},
  form: {} as FormikProps<SetupPurchaserRequestFormValues>,
  purchaserCurrency: "GBP",
  settlementCurrency: "GBP",
  onAddSelectedProduct: noop,
  onRemoveSelectedProduct: noop,
  onUpdateSelectedProduct: noop,
  calculateRemainingCredit: noop,
  errors: new Map(),
});

type UnitLimits = { available: number; producible: number };
interface ProductSaleInfo {
  unitPrice?: string;
  priceDiscount?: string;
  paidUnits?: string;
  freeUnits?: string;
}

interface ProductsTableContext extends OrganisationProductSelectionTableProps {
  purchaserCurrency: Currency;
  settlementCurrency: Currency;
  bonded: boolean;
  salePriceType: PurchaseRequestSalePriceType;
  onAddSelectedProduct: (product: WithDbId<Product>, totalUnits: UnitLimits, saleInfo: ProductSaleInfo) => void;
  onRemoveSelectedProduct: (product: string) => void;
  onUpdateSelectedProduct: (productId: string, saleInfo: ProductSaleInfo) => void;
  calculateRemainingCredit: () => void;
  errors: Map<string, string>;
}

export type OrganisationProductSelectionTableFeature = "freeStock" | "productDiscount" | "dealDiscount";

export interface OrganisationProductSelectionTableProps {
  purchaserCurrency: Currency;
  productListings: OrganisationSelectableProductListing[];
  features: { [key in OrganisationProductSelectionTableFeature]?: boolean };
  form: FormikProps<SetupPurchaserRequestFormValues>;
  calculateRemainingCredit: () => void;
}

export type OrganisationSelectableProductStage =
  | { kind: "deal-leg"; value: ThirdPartySaleDealLegStage }
  | { kind: "producible"; value: Omit<ThirdPartySaleFinishableProductListing, "productWithId"> };

export type OrganisationSelectableProductListing = {
  productWithId: WithDbId<Product>;
  stages: OrganisationSelectableProductStage[];
  defaultSalePrice?: BigDecimal;
};

export const OrganisationProductSelectionTable = memo(function OrganisationProductSelectionTableMemo({
  productListings,
  features,
  form,
  purchaserCurrency,
  ...rest
}: OrganisationProductSelectionTableProps) {
  const [errors, setErrors] = useState<Map<string, string>>(new Map());
  const prevFormErrorsRef = useRef<FormikErrors<SetupPurchaserRequestFormValues>>({});

  useEffect(() => {
    // This will prevent calculation being run every time the form updates, and only when the form submits
    // We only want to run on form submit as thats when the form errors are updated
    if (form.errors === prevFormErrorsRef.current) return;

    const errorMap = new Map<string, string>();
    form.values.products.forEach((p, idx) => {
      const id = p.product.id;
      const productErrors = form.errors.products?.[idx] as FormikErrors<FormStorageLocProductSale>;
      if (productErrors?.unitPrice) {
        errorMap.set(`products.${id}.unitPrice`, productErrors.unitPrice);
      }
      if (productErrors?.priceDiscount) {
        errorMap.set(`products.${id}.priceDiscount`, productErrors.priceDiscount);
      }
      if (productErrors?.paidUnits) {
        errorMap.set(`products.${id}.paidUnits`, productErrors.paidUnits);
      }
      if (productErrors?.freeUnits) {
        errorMap.set(`products.${id}.freeUnits`, productErrors.freeUnits);
      }
    });

    setErrors(errorMap);
    prevFormErrorsRef.current = form.errors;
  }, [errors, form, form.errors, form.values.products]);

  const onAddSelectedProduct = useCallback(
    (product: WithDbId<Product>, totalUnits: UnitLimits, saleInfo: ProductSaleInfo) => {
      if (form.values.products.findIndex(p => p.product.id === product.id) === -1) {
        const selectedProduct = {
          product,
          totalAvailable: totalUnits.available,
          totalProducible: totalUnits.producible,
          ...saleInfo,
        } as FormStorageLocProductSale;
        form.setFieldValue("products", [...form.values.products, selectedProduct]);
      }
    },
    [form],
  );

  const onRemoveSelectedProduct = useCallback(
    (productId: string) => {
      const index = form.values.products.findIndex(p => p.product.id === productId);
      if (index > -1) {
        const newSelectedProducts = [...form.values.products];
        newSelectedProducts.splice(index, 1);
        form.setFieldValue("products", newSelectedProducts);
      }
    },
    [form],
  );
  const onUpdateSelectedProduct = useCallback(
    (productId: string, saleInfo: ProductSaleInfo) => {
      const index = form.values.products.findIndex(p => p.product.id === productId);
      if (index > -1) {
        const newSelectedProducts = [...form.values.products];
        const oldProduct = form.values.products[index];
        newSelectedProducts.splice(index, 1, {
          ...oldProduct,
          ...saleInfo,
        });
        form.setFieldValue("products", newSelectedProducts);
      }
    },
    [form],
  );

  return (
    <ProductsTableContext.Provider
      value={{
        ...rest,
        productListings,
        features,
        form,
        bonded: form.values.bondedSale,
        salePriceType: form.values.salePriceType,
        purchaserCurrency: purchaserCurrency,
        settlementCurrency: useSettlementCurrency(),
        onAddSelectedProduct,
        onRemoveSelectedProduct,
        onUpdateSelectedProduct,
        errors,
      }}>
      <TableInner />
    </ProductsTableContext.Provider>
  );
});

interface ProductTableColumn {
  width: number;
  align?: "left" | "right" | "center";
  visible?: (features: OrganisationProductSelectionTableProps["features"]) => boolean;
  HeaderCell: (props: ProductsTableContext) => JSX.Element;
  BodyCell: (props: TableRowInnerProp & ProductsTableContext) => JSX.Element;
}

interface ProductTableColumnGroup {
  name: string;
  columns: ({ key: string } & ProductTableColumn)[];
}

const PRODUCT_TABLE_COLUMNS = {
  select: {
    width: 1,
    HeaderCell: () => <></>,
    BodyCell: ({ productWithId, handleFocus, totalUnits, isSelected, handleSelectionChange }) => (
      <Checkbox
        name={`products.${productWithId.id}.selected`}
        checked={isSelected}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          if (e.target.checked) {
            handleFocus?.();
          }
          handleSelectionChange(e.target.checked, totalUnits);
        }}
      />
    ),
  } as ProductTableColumn,
  product: {
    width: 25,
    align: "left",
    HeaderCell: () => <>Product</>,
    BodyCell: ({ productWithId }) => (
      <OrganisationProductSummary
        {...productWithId.value}
        productId={productWithId.id}
        vesselCapacity={getVesselCapacity(productWithId.value.vesselSize)}
      />
    ),
  } as ProductTableColumn,
  available: {
    width: 10,
    HeaderCell: () => (
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
        <Typography fontWeight="bold">Available</Typography>
        <InfoTooltip
          title={
            <p>
              Prices represent Net Payable Per Unit of <u>available</u> products. They are subject to change.
            </p>
          }
        />
      </Stack>
    ),
    BodyCell: ({ totalUnits, priceRanges, productWithId, settlementCurrency }) => (
      <>
        <Typography>
          {totalUnits.available
            ? `${totalUnits.available} ${unitsLabelForUnitType(productWithId.value.unitType)}`
            : "-"}
        </Typography>
        {priceRanges.dealLeg && (
          <CurrencyRange values={priceRanges.dealLeg} currency={settlementCurrency} maximumFractionDigits={4} />
        )}
      </>
    ),
  } as ProductTableColumn,
  producible: {
    width: 10,
    HeaderCell: () => (
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
        <Typography fontWeight="bold">Producible</Typography>
        <InfoTooltip
          title={
            <p>
              Prices represent Net Payable Per Unit of <u>unfinished</u> products. They are subject to change and do not
              include production costs.
            </p>
          }
        />
      </Stack>
    ),
    BodyCell: ({ totalUnits, priceRanges, productWithId, settlementCurrency }) => (
      <>
        <Typography>
          {totalUnits.producible ? `${totalUnits.producible} ${productWithId.value.unitType}` : "-"}
        </Typography>
        {priceRanges.producible && (
          <CurrencyRange values={priceRanges.producible} currency={settlementCurrency} maximumFractionDigits={4} />
        )}
      </>
    ),
  } as ProductTableColumn,
  paidUnits: {
    width: 12,
    HeaderCell: () => <>Purchase Quantity</>,
    BodyCell: ({
      productWithId,
      freeUnits,
      paidUnits,
      onPaidQuantityChange,
      totalUnits,
      commonInputProps,
      handleFocus,
      errors,
    }) => {
      const fieldPrefix = `products.${productWithId.id}`;
      const totalQuantity = totalUnits.available + totalUnits.producible;
      return (
        <UnitsInput
          name={`products.${productWithId.id}}.paidUnits`}
          unitType={productWithId.value.unitType}
          value={paidUnits}
          onFocus={handleFocus}
          InputProps={{ inputProps: { min: 0, max: totalQuantity - (parseNumber(freeUnits) ?? 0) } }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onPaidQuantityChange(e, totalUnits)}
          error={errors.has(`${fieldPrefix}.paidUnits`)}
          helperText={errors.get(`${fieldPrefix}.paidUnits`)}
          {...commonInputProps}
        />
      );
    },
  } as ProductTableColumn,
  unitPrice: {
    width: 10,
    HeaderCell: ({ salePriceType, features }) => {
      if (features.productDiscount) {
        return <>Unit Price</>;
      } else {
        return (
          <>
            Sale Price
            <br />({buildSalePriceSuffix(salePriceType)})
          </>
        );
      }
    },
    BodyCell: ({
      productWithId,
      unitPrice,
      onSalePriceChange,
      totalUnits,
      commonInputProps,
      errors,
      purchaserCurrency,
      handleFocus,
    }) => {
      const fieldPrefix = `products.${productWithId.id}`;
      return (
        <CurrencyInput
          name={`${fieldPrefix}.price`}
          currency={purchaserCurrency}
          value={unitPrice}
          onFocus={handleFocus}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSalePriceChange(e, totalUnits)}
          error={errors.has(`${fieldPrefix}.price`)}
          helperText={errors.get(`${fieldPrefix}.price`)}
          {...commonInputProps}
        />
      );
    },
  } as ProductTableColumn,
  discountPct: {
    width: 10,
    visible: features => features.productDiscount,
    HeaderCell: () => <>Discount %</>,
    BodyCell: ({
      productWithId,
      discountPct,
      handleFocus,
      totalUnits,
      onDiscountPctChange,
      errors,
      commonInputProps,
    }) => {
      const fieldPrefix = `products.${productWithId.id}`;
      return (
        <RoundingInput
          name={`${fieldPrefix}.discountPct`}
          value={discountPct}
          precision={2}
          endAdornment="%"
          onFocus={handleFocus}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onDiscountPctChange(e, totalUnits)}
          error={errors.has(`${fieldPrefix}.discountPct`)}
          helperText={errors.get(`${fieldPrefix}.discountPct`)}
          {...commonInputProps}
        />
      );
    },
  } as ProductTableColumn,
  discountedPrice: {
    width: 10,
    visible: features => features.productDiscount,
    HeaderCell: ({ bonded, salePriceType }) => {
      return (
        <>
          Sale Price
          <br />({buildSalePriceSuffix(bonded ? "exDutyAndVat" : salePriceType)})
        </>
      );
    },
    BodyCell: ({
      productWithId,
      discountedPrice,
      purchaserCurrency,
      handleFocus,
      errors,
      totalUnits,
      commonInputProps,
      onDiscountedPriceChange,
      features,
    }) => {
      if (features.productDiscount) {
        const fieldPrefix = `products.${productWithId.id}`;
        return (
          <CurrencyInput
            name={`${fieldPrefix}.discountedPrice`}
            currency={purchaserCurrency}
            value={discountedPrice}
            precision={4}
            onFocus={handleFocus}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onDiscountedPriceChange(e, totalUnits)}
            error={errors.has(`${fieldPrefix}.discountedPrice`)}
            helperText={errors.get(`${fieldPrefix}.discountedPrice`)}
            {...commonInputProps}
          />
        );
      } else {
        return <CurrencyRenderer currency={purchaserCurrency} value={discountedPrice} maximumFractionDigits={4} />;
      }
    },
  } as ProductTableColumn,
  subtotal: {
    width: 10,
    align: "left",
    HeaderCell: () => <>Subtotal</>,
    BodyCell: ({ paidUnits, discountedPrice, purchaserCurrency }) => {
      const subtotal = doCalc(paidUnits, discountedPrice, (a, b) => a * b);
      return <CurrencyRenderer currency={purchaserCurrency} value={subtotal} maximumFractionDigits={4} />;
    },
  } as ProductTableColumn,
  freeUnits: {
    width: 12,
    visible: features => features.freeStock,
    HeaderCell: () => <>Free Quantity</>,
    BodyCell: ({
      productWithId,
      freeUnits,
      paidUnits,
      totalUnits,
      onFreeQuantityChange,
      commonInputProps,
      errors,
      handleFocus,
    }) => {
      const fieldPrefix = `products.${productWithId.id}`;
      const totalQuantity = totalUnits.available + totalUnits.producible;
      return (
        <UnitsInput
          name={`${fieldPrefix}.freeUnits`}
          unitType={productWithId.value.unitType}
          value={freeUnits}
          onFocus={handleFocus}
          InputProps={{ inputProps: { min: 0, max: totalQuantity - (parseNumber(paidUnits) ?? 0) } }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFreeQuantityChange(e, totalUnits)}
          error={errors.has(`${fieldPrefix}.freeUnits`)}
          helperText={errors.get(`${fieldPrefix}.freeUnits`)}
          {...commonInputProps}
        />
      );
    },
  } as ProductTableColumn,
};

const PRODUCT_TABLE_GROUPS: ProductTableColumnGroup[] = [
  {
    name: "",
    columns: pickColumns(PRODUCT_TABLE_COLUMNS, ["select", "product", "available", "producible"]),
  },
  {
    name: "Paid Stock",
    columns: pickColumns(PRODUCT_TABLE_COLUMNS, ["paidUnits", "unitPrice", "discountPct", "discountedPrice"]),
  },
  {
    name: "Free Stock",
    columns: pickColumns(PRODUCT_TABLE_COLUMNS, ["freeUnits"]),
  },
  {
    name: "",
    columns: pickColumns(PRODUCT_TABLE_COLUMNS, ["subtotal"]),
  },
];

export function buildSalePriceSuffix(salePriceType: PurchaseRequestSalePriceType) {
  let salePriceSuffix = "";
  switch (salePriceType) {
    case "incDutyExVat":
      salePriceSuffix = "Inc Duty, Ex VAT";
      break;
    case "incDutyAndVat":
      salePriceSuffix = "Inc Duty, Inc VAT";
      break;
    case "exDutyAndVat":
      salePriceSuffix = "Ex Duty, Ex VAT";
      break;
    default:
      assertNever(salePriceType);
  }
  return salePriceSuffix;
}

function pickColumns<T extends { [key: string]: ProductTableColumn }>(
  columns: T,
  keys: (keyof T)[],
): ({ key: keyof T } & ProductTableColumn)[] {
  return keys.map(key => ({ key, ...columns[key] }));
}

function getGroupedColumns(features: OrganisationProductSelectionTableProps["features"]) {
  return PRODUCT_TABLE_GROUPS.map(group => ({
    ...group,
    columns: group.columns.filter(c => (c.visible ? c.visible(features) : true)),
  })).filter(group => group.columns.length > 0);
}

function getColumns(features: OrganisationProductSelectionTableProps["features"]) {
  return getGroupedColumns(features).flatMap(group => group.columns);
}

const ProductTableHeader = () => {
  const context = useContext(ProductsTableContext);

  const { columnGroups, columns } = useMemo(() => {
    const columnGroups = getGroupedColumns(context.features);
    const columns = getColumns(context.features);
    return { columnGroups, columns };
  }, [context.features]);

  const groupHeaders = useMemo(
    () =>
      columnGroups.flatMap(({ name, columns }, idx) => {
        if (name) {
          return (
            <GroupHeaderCell key={idx} colSpan={columns.length}>
              {name}
            </GroupHeaderCell>
          );
        } else {
          return columns.map(({ key }) => <InvisibleCell key={key} />);
        }
      }),
    [columnGroups],
  );

  const columnHeaders = useMemo(
    () =>
      columns.map(({ key, align, HeaderCell }) => {
        return (
          <TableCell key={key} align={align ?? "right"}>
            <HeaderCell {...context} />
          </TableCell>
        );
      }),
    [columns, context],
  );

  return (
    <TableHead>
      <TableRow>{groupHeaders}</TableRow>
      <TableRow>{columnHeaders}</TableRow>
    </TableHead>
  );
};

const TableInner = () => {
  const { productListings, form, errors, features, purchaserCurrency, salePriceType, calculateRemainingCredit } =
    useContext(ProductsTableContext);
  const [loadedRowCount, setLoadedRowCount] = useState(productListings.length < 10 ? productListings.length : 10); // Initial number of rows
  const observerTarget = useRef<HTMLDivElement | null>(null);
  /**
   * Note (Dyl):
   * The below is used to lazy load the table rows as the user scrolls down
   * the table. The rootMargin is used to trigger the intersection observer
   * when the bottom of the table is 20% from the bottom of the viewport.
   * This will trigger the observer to load more rows before the user reaches
   * the bottom of the table.
   *
   * This isn't a good solution, ideally we would use a paginated table + (search, sort, filter)...
   * but this was a quick adaption from the previous implementation.
   */
  const rootMargin = useMemo(() => {
    const fractionOfViewportHeight = 0.5;
    const viewportHeight = window.innerHeight;
    return `${Math.floor(viewportHeight * fractionOfViewportHeight)}px`;
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && loadedRowCount < productListings.length) {
          setLoadedRowCount(loadedRowCount => {
            const rowsToAdd =
              loadedRowCount + 16 > productListings.length ? productListings.length - loadedRowCount : 16;
            return loadedRowCount + rowsToAdd;
          });
        }
      },
      {
        threshold: 0,
        rootMargin: rootMargin,
      },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        // eslint-disable-next-line
        observer.unobserve(observerTarget.current);
      }
    };
  }, [loadedRowCount, observerTarget, productListings.length, rootMargin]);

  const errorFieldNames = useMemo(() => {
    if (!form.errors) return [];
    return Array.from(errors.keys());
  }, [errors, form.errors]);

  const initialValueLookup: Record<string, InitialRowValues> = useMemo(
    () =>
      collectToRecord(
        productListings,
        p => p.productWithId.id,
        p => {
          const selectedProduct = form.values.products.find(f => f.product.id === p.productWithId.id);
          return {
            paidUnits: selectedProduct?.paidUnits,
            freeUnits: selectedProduct?.freeUnits,
            unitPrice: selectedProduct?.unitPrice ?? p.defaultSalePrice,
            priceDiscount: selectedProduct?.priceDiscount,
            selected: !!selectedProduct,
          };
        },
      ),
    // Only want to run this once on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const defaultPriceLookup: Record<string, BigDecimal | undefined> = useMemo(
    () =>
      collectToRecord<OrganisationSelectableProductListing, BigDecimal>(
        productListings,
        p => p.productWithId.id,
        p => p.defaultSalePrice,
      ),
    [productListings],
  );

  const colGroup = useMemo(() => {
    const widths = getColumns(features).map(({ key, width }) => ({ key, width }));
    const totalWidth = widths.reduce((sum, { width }) => sum + width, 0);
    return (
      <colgroup>
        {widths.map(({ key, width }) => (
          <col key={key} style={{ width: `${(width / totalWidth) * 100}%` }} />
        ))}
      </colgroup>
    );
  }, [features]);

  const columnCount = useMemo(() => getColumns(features).length, [features]);

  const [dealDiscountAmount, setDealDiscountAmount] = useState(form.values.dealDiscountAmount);
  const [dealDiscountPct, setDealDiscountPct] = useState<string | undefined>(undefined);
  const [totalWithDiscount, setDiscountedAmount] = useState<string | undefined>(undefined);

  const getTotalAmountVal = useCallback(() => {
    return form.values.products.reduce((acc, p) => {
      const subTotal = Number(p.paidUnits ?? 0) * (Number(p.unitPrice ?? 0) - Number(p.priceDiscount ?? 0));
      return acc + +subTotal;
    }, 0);
  }, [form]);

  const discountCalc = useMemo(() => {
    return new DiscountCalc({
      baseAmountVal: getTotalAmountVal(),
      discountAmount: dealDiscountAmount,
      setDiscountAmount: setDealDiscountAmount,
      setDiscountPct: setDealDiscountPct,
      setDiscountedAmount,
      discountAmountPrecision: 2,
      discountPctPrecision: 2,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getTotalAmountVal,
    dealDiscountAmount,
    totalWithDiscount,
    setDealDiscountAmount,
    setDealDiscountPct,
    setDiscountedAmount,
  ]);

  // When user turns "Deal Discount" off, all discounts on the line items are cleared, we need to recalculate total amount
  // and update DiscountCalc.
  //
  useEffect(() => {
    discountCalc.updateBaseValue(getTotalAmountVal());
  }, [features.productDiscount, features.dealDiscount, getTotalAmountVal, discountCalc]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => discountCalc.initOnce(), []); // only init once

  useEffect(() => {
    if (!features.dealDiscount && dealDiscountPct !== undefined) {
      discountCalc.updateDiscountPct(undefined);
      form.setFieldValue("dealDiscountAmount", undefined);
      calculateRemainingCredit();
    }
  }, [form, discountCalc, dealDiscountPct, features.dealDiscount, calculateRemainingCredit]);

  const commonInputProps: Required<Pick<TextFieldProps, "onBlur" | "sx" | "onClick">> = useMemo(
    () => ({
      onBlur: e => {
        form.handleBlur(e);
        calculateRemainingCredit();
      },
      onClick: (e: SyntheticEvent) => {
        e.stopPropagation();
      },
      sx: {
        borderRadius: 10,
        backgroundColor: theme => theme.palette.common.white,
        [`> .${inputBaseClasses.root}`]: { backgroundColor: "white" },
      },
    }),
    [calculateRemainingCredit, form],
  );

  return (
    // Make salePriceType the key to force re-render when salePriceType changes
    // this is need to update the default sale price which depends on salePriceType
    <TableContainer key={salePriceType} style={{ overflowY: "hidden" }}>
      <Table>
        {colGroup}
        <ProductTableHeader />
        <TableBody>
          {productListings.length === 0 ? (
            <NoResultsRow colSpan={columnCount} />
          ) : (
            <>
              <ScrollToFieldError
                isValid={errorFieldNames.length === 0}
                submitCount={form.submitCount}
                errors={{}}
                fieldNames={errorFieldNames}
              />
              <FormikProvider value={form}>
                <FieldArray
                  name="products"
                  render={({}) =>
                    productListings.slice(0, loadedRowCount).map(pl => (
                      <ProductTableRow
                        key={pl.productWithId.id}
                        row={pl}
                        // @ts-ignore This will always be defined
                        initialValues={initialValueLookup[pl.productWithId.id]}
                        defaultPrice={defaultPriceLookup[pl.productWithId.id]}
                      />
                    ))
                  }
                />
              </FormikProvider>
            </>
          )}
        </TableBody>
        <TableFooter>
          <FooterRow
            label="Net Subtotal"
            component={
              <CurrencyRenderer
                fontWeight="bold"
                sx={{ paddingY: 2 }}
                currency={purchaserCurrency}
                value={discountCalc.getTotalAmount()}
                maximumFractionDigits={features.dealDiscount ? 4 : 2}
              />
            }
          />
          {features.dealDiscount && (
            <>
              <FooterRow
                label="Discount %"
                component={
                  <RoundingInput
                    name={`dealDiscountPct`}
                    value={dealDiscountPct}
                    precision={2}
                    endAdornment="%"
                    // onFocus={handleFocus}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { discountAmount: dealDiscountAmount } = discountCalc.updateDiscountPct(e.target.value);
                      form.setFieldValue("dealDiscountAmount", dealDiscountAmount);
                      errors.delete(`dealDiscountPct`);
                    }}
                    error={errors.has(`dealDiscountPct`)}
                    helperText={errors.get(`dealDiscountPct`)}
                    {...commonInputProps}
                  />
                }
              />
              <FooterRow
                label="Total Price with Discount"
                component={
                  <CurrencyInput
                    name={`totalWithDiscount`}
                    currency={purchaserCurrency}
                    value={totalWithDiscount}
                    // precision={2}
                    // onFocus={handleFocus}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { discountAmount: dealDiscountAmount } = discountCalc.updateDiscountedAmount(
                        e.target.value,
                      );
                      form.setFieldValue("dealDiscountAmount", dealDiscountAmount);
                      errors.delete(`totalWithDiscount`);
                    }}
                    error={errors.has(`totalWithDiscount`)}
                    helperText={errors.get(`totalWithDiscount`)}
                    {...commonInputProps}
                  />
                }
              />
            </>
          )}
        </TableFooter>
      </Table>
      <div ref={observerTarget} />
    </TableContainer>
  );
};

const FooterRow = ({ label, component }: { label: string; component: JSX.Element }) => {
  const { features } = useContext(ProductsTableContext);
  const colCount = getColumns(features).length;
  return (
    <TableRow sx={{ backgroundColor: "common.grey1", borderTop: 0 }}>
      <TableCell colSpan={colCount - 3} />
      <TableCell colSpan={2} align="right">
        <Typography variant={"subtitle1Bold"}>{label}</Typography>
      </TableCell>
      <TableCell>{component}</TableCell>
    </TableRow>
  );
};

const NoResultsRow = ({ colSpan }: { colSpan: number }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Alert severity="info">No products</Alert>
      </TableCell>
    </TableRow>
  );
};

interface InitialRowValues {
  paidUnits?: string;
  freeUnits?: string;
  unitPrice?: string;
  priceDiscount?: string;
  selected: boolean;
}

const ProductTableRow = memo(function ProductTableRowMemo({
  row,
  initialValues,
  defaultPrice,
}: {
  row: OrganisationSelectableProductListing;
  initialValues: InitialRowValues;
  defaultPrice?: BigDecimal;
}) {
  const {
    form,
    features,
    onAddSelectedProduct,
    onRemoveSelectedProduct,
    onUpdateSelectedProduct,
    calculateRemainingCredit,
    errors,
  } = useContext(ProductsTableContext);

  const [unitPrice, setSalePrice] = useState(initialValues.unitPrice ?? defaultPrice);
  const [paidUnits, setPaidUnits] = useState<string | undefined>(initialValues.paidUnits);
  const [freeUnits, setFreeUnits] = useState<string | undefined>(initialValues.freeUnits);
  const [priceDiscount, setPriceDiscount] = useState(initialValues.priceDiscount);
  const [isSelected, setIsSelected] = useState<boolean>(initialValues.selected);
  const [unitPriceModified, setUnitPriceModified] = useState<boolean>(false); // prevents rows with existing Default-Sale-Price to be auto-selected
  const initialDerivedValues = useMemo(() => {
    return {
      discountedPrice: doCalc(initialValues.unitPrice, initialValues.priceDiscount, (a, b) => a - b)?.toString(),
      discountPct: doCalc(initialValues.unitPrice, initialValues.priceDiscount, (a, b) => (b / a) * 100)?.toString(),
      salePrice: unitPrice?.toString(),
    };
  }, [initialValues.priceDiscount, initialValues.unitPrice, unitPrice]);

  const [discountedPrice, setDiscountedPrice] = useState(initialDerivedValues.discountedPrice);
  const [discountPct, setDiscountPct] = useState(initialDerivedValues.discountPct);

  const discountCalc = useMemo(() => {
    return new DiscountCalc({
      baseAmountVal: parseNumber(unitPrice),
      discountAmount: priceDiscount,
      setDiscountAmount: setPriceDiscount,
      setDiscountPct,
      setDiscountedAmount: setDiscountedPrice,
      discountAmountPrecision: 4,
      discountPctPrecision: 2,
    });
  }, [priceDiscount, unitPrice]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => discountCalc.initOnce(), []); // only init once

  useEffect(() => {
    if (!features.productDiscount && discountPct !== undefined) {
      discountCalc.updateDiscountPct(undefined);
      const productIdx = form.values.products.findIndex(p => p.product.id === row.productWithId.id);
      if (productIdx >= 0) {
        form.setFieldValue(`products[${productIdx}].priceDiscount`, undefined);
      }
    }
    if (!features.freeStock && freeUnits !== undefined) {
      setFreeUnits(undefined);
    }
    calculateRemainingCredit();
  }, [
    calculateRemainingCredit,
    discountCalc,
    discountPct,
    features.freeStock,
    features.productDiscount,
    form,
    freeUnits,
    row.productWithId.id,
  ]);

  const commonInputProps: Required<Pick<TextFieldProps, "onBlur" | "sx" | "onClick">> = useMemo(
    () => ({
      onBlur: e => {
        form.handleBlur(e);
        if (isSelected) {
          onUpdateSelectedProduct(row.productWithId.id, {
            unitPrice,
            priceDiscount,
            paidUnits,
            freeUnits,
          });
          calculateRemainingCredit();
        }
      },
      onClick: (e: SyntheticEvent) => {
        e.stopPropagation();
      },
      sx: {
        borderRadius: 10,
        backgroundColor: theme => theme.palette.common.white,
        [`> .${inputBaseClasses.root}`]: { backgroundColor: "white" },
      },
    }),
    [
      form,
      isSelected,
      onUpdateSelectedProduct,
      row.productWithId.id,
      unitPrice,
      priceDiscount,
      paidUnits,
      freeUnits,
      calculateRemainingCredit,
    ],
  );

  const handleSelectionChange = useCallback(
    (checked: boolean, totalUnits: UnitLimits) => {
      if (checked) {
        onAddSelectedProduct(row.productWithId, totalUnits, {
          unitPrice,
          priceDiscount,
          paidUnits,
          freeUnits,
        });
      } else {
        errors.delete(`products.${row.productWithId.id}.quantity`);
        errors.delete(`products.${row.productWithId.id}.price`);
        onRemoveSelectedProduct(row.productWithId.id);
      }
      // If deselecting a product (currently selected), clear all values and set default price
      if (isSelected) {
        setPaidUnits(undefined);
        setFreeUnits(undefined);
        setSalePrice(defaultPrice);
        setPriceDiscount(undefined);
        setDiscountedPrice(undefined);
        setDiscountPct(undefined);
      }
      setIsSelected(selected => !selected);
    },
    [
      defaultPrice,
      errors,
      freeUnits,
      isSelected,
      onAddSelectedProduct,
      onRemoveSelectedProduct,
      paidUnits,
      priceDiscount,
      row.productWithId,
      unitPrice,
    ],
  );

  const onPaidQuantityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, totalUnits: UnitLimits) => {
      const n = Number(e.target.value);
      const freeQtyNum = freeUnits ? Number(freeUnits) : 0;
      if (!n || n <= totalUnits.available + totalUnits.producible - freeQtyNum) {
        errors.delete(`products.${row.productWithId.id}.paidUnits`);
        setPaidUnits(e.target.value);
        if (!isSelected) {
          handleSelectionChange(true, totalUnits);
        }
      }
    },
    [errors, freeUnits, handleSelectionChange, isSelected, row.productWithId.id],
  );

  const onSalePriceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, totalUnits: UnitLimits) => {
      errors.delete(`products.${row.productWithId.id}.unitPrice`);
      setSalePrice(e.target.value);
      discountCalc.updateBaseValue(e.target.value);
      setUnitPriceModified(true);
      if (!isSelected && unitPriceModified) {
        handleSelectionChange(true, totalUnits);
      }
    },
    [discountCalc, errors, handleSelectionChange, isSelected, row.productWithId.id, setSalePrice, unitPriceModified],
  );

  const onDiscountedPriceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, totalUnits: UnitLimits) => {
      discountCalc.updateDiscountedAmount(e.target.value);
      errors.delete(`products.${row.productWithId.id}.discountedPrice`);
      if (!isSelected) {
        handleSelectionChange(true, totalUnits);
      }
    },
    [discountCalc, errors, handleSelectionChange, isSelected, row.productWithId.id],
  );

  const onDiscountPctChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, totalUnits: UnitLimits) => {
      discountCalc.updateDiscountPct(e.target.value);
      errors.delete(`products.${row.productWithId.id}.discountPct`);
      if (!isSelected) {
        handleSelectionChange(true, totalUnits);
      }
    },
    [discountCalc, errors, handleSelectionChange, isSelected, row.productWithId.id],
  );

  const onFreeQuantityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, totalUnits: UnitLimits) => {
      const n = Number(e.target.value);
      const paidQtyNum = paidUnits ? Number(paidUnits) : 0;
      if (!n || n <= totalUnits.available + totalUnits.producible - paidQtyNum) {
        errors.delete(`products.${row.productWithId.id}.freeUnits`);
        setFreeUnits(e.target.value);
        if (!isSelected) {
          handleSelectionChange(true, totalUnits);
        }
      }
    },
    [errors, handleSelectionChange, isSelected, paidUnits, row.productWithId.id],
  );

  const rowProps: TableRowProps = {
    paidUnits,
    onPaidQuantityChange,
    freeUnits,
    onFreeQuantityChange,
    unitPrice,
    onSalePriceChange,
    discountedPrice,
    onDiscountedPriceChange,
    discountPct,
    onDiscountPctChange,
    commonInputProps,
    isSelected,
    handleSelectionChange,
  };
  return <ProductSelectionRow row={row} {...rowProps} />;
});

type TableRowProps = ProductSaleInfo & {
  discountedPrice: string | undefined;
  discountPct: string | undefined;
  onPaidQuantityChange: (e: React.ChangeEvent<HTMLInputElement>, totalUnits: UnitLimits) => void;
  onFreeQuantityChange: (e: React.ChangeEvent<HTMLInputElement>, totalUnits: UnitLimits) => void;
  onSalePriceChange: (e: React.ChangeEvent<HTMLInputElement>, totalUnits: UnitLimits) => void;
  onDiscountedPriceChange: (e: React.ChangeEvent<HTMLInputElement>, totalUnits: UnitLimits) => void;
  onDiscountPctChange: (e: React.ChangeEvent<HTMLInputElement>, totalUnits: UnitLimits) => void;
  commonInputProps: Required<Pick<TextFieldProps, "onBlur" | "sx">>;
  isSelected: boolean;
  handleSelectionChange: (checked: boolean, totalUnits: UnitLimits) => void;
};
interface ProductTableFinishedProductRowProps extends TableRowProps {
  row: OrganisationSelectableProductListing;
}

const ProductSelectionRow = function ProductsTableRowMemo({
  row,
  isSelected,
  ...rest
}: ProductTableFinishedProductRowProps) {
  const totalUnits: UnitLimits = useMemo(() => {
    const available =
      filterUnionByKind(row.stages ?? [], "deal-leg").reduce(
        (sum, stage) => sum + stage.value.quantityAvailable.value,
        0,
      ) || 0;
    const producible =
      filterUnionByKind(row.stages ?? [], "producible").reduce(
        (sum, stage) => sum + stage.value.quantityProducible.value,
        0,
      ) || 0;
    return { available, producible };
  }, [row.stages]);

  const priceRanges = useMemo(() => calculateNetPayableRange(row.stages), [row.stages]);

  return (
    <>
      <TableRow hover sx={{ backgroundColor: isSelected ? "common.grey3" : undefined }}>
        <TableRowInner
          productWithId={row.productWithId}
          priceRanges={priceRanges}
          totalUnits={totalUnits}
          isSelected={isSelected}
          {...rest}
        />
      </TableRow>
    </>
  );
};

type TableRowInnerProp = TableRowProps & {
  productWithId: ThirdPartySaleFinishedProductListing["productWithId"];
  priceRanges: { dealLeg?: PriceRange; producible?: PriceRange };
  totalUnits: UnitLimits;
  handleFocus?: () => void;
};

const TableRowInner = (props: TableRowInnerProp) => {
  const context = useContext(ProductsTableContext);
  return (
    <>
      {getColumns(context.features).map(({ key, align, BodyCell }) => {
        return (
          <TableCell key={key} align={align ?? "right"}>
            <BodyCell {...{ ...props, ...context }} />
          </TableCell>
        );
      })}
    </>
  );
};

const calculateNetPayableRange = (
  stages: OrganisationSelectableProductStage[],
): { dealLeg?: PriceRange; producible?: PriceRange } => {
  const dealLegStages = filterUnionByKind(stages, "deal-leg");
  const deaLegRage =
    dealLegStages.length === 0
      ? undefined
      : {
          from: dealLegStages.reduce((min, { value: { netPayablePerUnit } }) => {
            return Number(netPayablePerUnit) < Number(min) ? netPayablePerUnit : min;
          }, dealLegStages[0].value.netPayablePerUnit),
          to: dealLegStages.reduce((max, { value: { netPayablePerUnit } }) => {
            return Number(netPayablePerUnit) > Number(max) ? netPayablePerUnit : max;
          }, dealLegStages[0].value.netPayablePerUnit),
        };
  const producibleStages = filterUnionByKind(stages, "producible");
  const producibleRange: PriceRange | undefined = producibleStages[0]?.value.estimatedNetPayableRange;
  return { dealLeg: deaLegRage, producible: producibleRange };
};

class DiscountCalc {
  private baseAmountVal: number | undefined;
  private discountValue: number | undefined;
  private readonly setDiscountAmount: (v?: string) => string | undefined;
  private readonly setDiscountPct: (v?: string) => string | undefined;
  private readonly setDiscountedAmount: (v?: string) => string | undefined;

  constructor(args: {
    baseAmountVal: number | undefined;
    discountAmount: string | undefined;
    setDiscountAmount: (v?: string) => void;
    setDiscountPct: (v?: string) => void;
    setDiscountedAmount: (v?: string) => void;
    discountAmountPrecision: number; // totalWithDiscout will have the same precision
    discountPctPrecision: number;
  }) {
    this.baseAmountVal = args.baseAmountVal;
    this.discountValue = parseNumber(args.discountAmount);

    this.setDiscountAmount = DiscountCalc.wrapSetter(args.setDiscountAmount, args.discountAmountPrecision);
    this.setDiscountPct = DiscountCalc.wrapSetter(args.setDiscountPct, args.discountPctPrecision);
    this.setDiscountedAmount = DiscountCalc.wrapSetter(args.setDiscountedAmount, args.discountAmountPrecision);
  }

  private static wrapSetter(setter: (v?: string) => void, precision: number) {
    return (v?: string) => {
      const transformedV = v ? limitPrecision(v, precision) : undefined;
      setter(transformedV);
      return transformedV;
    };
  }

  // this should only be called once to initialize discount% and total with discount
  // these values will be kept up to date when other methods are called.
  initOnce() {
    const discountPctNum = doCalc(this.baseAmountVal, this.discountValue, (a, b) => (b / a) * 100);
    const totalWithDiscountNum = doCalc(this.baseAmountVal, this.discountValue ?? 0, (a, b) => a - b);
    this.setDiscountPct(discountPctNum?.toString());
    this.setDiscountedAmount(totalWithDiscountNum?.toString());
  }

  getTotalAmount() {
    return this.baseAmountVal;
  }

  updateBaseValue(baseValue?: number | string) {
    const oldBaseAmountVal = this.baseAmountVal;
    this.baseAmountVal = typeof baseValue === "string" ? parseNumber(baseValue) : baseValue;
    const discountRatio = doCalc(this.discountValue, oldBaseAmountVal, (a, b) => a / b);
    this.discountValue = doCalc(baseValue, discountRatio, (a, b) => limitPrecision(a * b, 4));
    if (this.discountValue !== undefined) {
      this.setDiscountAmount(this.discountValue.toString());
    }
    const totalWithDiscount = doCalc(baseValue, this.discountValue ?? 0, (a, b) => a - b)?.toString();
    this.setDiscountedAmount(totalWithDiscount);
  }

  updateDiscountPct(discountPctInput: string | undefined) {
    const checkedDiscountPct = doCalc(
      this.baseAmountVal, // check total amount is set, otherwise
      discountPctInput && limitPrecision(discountPctInput, 2),
      (_total, discountPct) => Math.max(0, Math.min(100, discountPct)),
    );
    const discountValue = doCalc(this.baseAmountVal, checkedDiscountPct, (a, b) => (a * b) / 100);
    const discountAmount = this.setDiscountAmount(discountValue?.toString());
    const discountPct = this.setDiscountPct(
      parseNumber(discountPctInput) == checkedDiscountPct ? discountPctInput : checkedDiscountPct?.toString(),
    );
    const totalWithDiscount = this.setDiscountedAmount(
      doCalc(this.baseAmountVal, discountValue ?? 0, (a, b) => a - b)?.toString(),
    );
    return { discountAmount, discountPct, totalWithDiscount };
  }

  updateDiscountedAmount(discountedValueInput: string | undefined) {
    const discountValue =
      doCalc(this.baseAmountVal, discountedValueInput, (totalAmt, discountedTotal) => {
        return discountedTotal >= 0 ? Math.max(0, totalAmt - discountedTotal) : undefined;
      }) ?? 0;
    const discountAmount = this.setDiscountAmount(discountValue > 0 ? discountValue.toString() : undefined);
    const totalWithDiscountValue = doCalc(this.baseAmountVal, discountValue, (a, b) => a - b);
    const totalWithDiscount = this.setDiscountedAmount(
      parseNumber(discountedValueInput) === totalWithDiscountValue
        ? discountedValueInput
        : totalWithDiscountValue?.toString(),
    );
    const discountPct = this.setDiscountPct(
      doCalc(discountAmount, this.baseAmountVal, (a, b) => (a / b) * 100)?.toString(),
    );
    return { discountAmount, discountPct, totalWithDiscount };
  }
}
