import { WorkflowId } from "adl-gen/ferovinum/app/workflow";
import React, { useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StockMovementData, useStockMovementSummary } from "utils/hooks/use-stock-movement-summary";
import { assertNotUndefined } from "utils/hx/util/types";
import { AppRoutes } from "../../../../app/app-routes";
import { useAppService } from "../../../../hooks/use-app-service";
import { useSelectedOrg } from "../../../layouts/portal-page-layout/portal-page";
import { StockMovementDetailsPageView } from "./stock-movement-details-page-view";

export const StockMovementDetailsPage = () => {
  const history = useHistory();
  const { stockMovementId } = useParams<{ stockMovementId: string }>();
  const service = useAppService();
  const organisation = assertNotUndefined(useSelectedOrg());
  const stockMovementData: StockMovementData = useMemo(
    () => ({ kind: "withId", stockMovementId: stockMovementId }),
    [stockMovementId],
  );
  const stockMovementSummary = useStockMovementSummary({
    service,
    organisationId: organisation.id,
    stockMovementData,
  });

  const handleCancel = useCallback(
    async (crWorkflowId: WorkflowId) => {
      await service.transitionWorkflow({
        workflowId: crWorkflowId,
        action: {
          kind: "stock_movement",
          value: {
            kind: "cancel",
          },
        },
      });
      history.push(AppRoutes.StockMovement);
    },
    [history, service],
  );
  return (
    <StockMovementDetailsPageView
      stockMovementSummary={stockMovementSummary}
      organisation={organisation.value}
      handleCancel={handleCancel}
    />
  );
};
