import * as common from "adl-gen/common";
import { format, isAfter, isToday, isTomorrow, isYesterday, differenceInDays } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { Instant } from "adl-gen/common";
import { LocalDate, LocalTime } from "adl-gen/common";
import { PartialDate } from "adl-gen/ferovinum/app/db";

export function todayYesterdayOrDateFormat(date: Date): string {
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else {
    return format(date, "d LLL yyyy");
  }
}

export function formatLocalDate(date: common.LocalDate): string {
  return formatDate(new Date(date));
}

// •••••••••••••••••••••••••••••••••••••••••
// todo: Rick and Zhi  this is a temporary solution to fix the timezone issue in the app.
function toLondonZonedTime(date: Date): Date {
  return utcToZonedTime(date, "Europe/London");
}

function toLondonZonedTimeInstant(instant: Instant): Date {
  return utcToZonedTime(instant, "Europe/London");
}
// •••••••••••••••••••••••••••••••••••••••••

export function formatDate(date: Date): string {
  // We need to make sure dates are formatted for London time.
  return format(toLondonZonedTime(date), "d LLL yyyy");
}

export function formatInstant(instant: Instant): string {
  // We need to make sure dates are formatted for London time.
  return format(toLondonZonedTimeInstant(instant), "d LLL yyyy");
}

export function formatDateToISO8601(date: Date): LocalDate {
  // We need to make sure dates are formatted for London time.
  return format(toLondonZonedTime(date), "yyyy-MM-dd");
}

// Functionally identical to formatDateToISO8601, but with a more descriptive name
export function formatDateToLocalDate(date: Date): LocalDate {
  // We need to make sure dates are formatted for London time.
  return format(toLondonZonedTime(date), "yyyy-MM-dd");
}

export function safeFormatDateToLocalDate(date?: Date | null): LocalDate | undefined {
  return date && !isNaN(date.getTime()) ? formatDateToLocalDate(date) : undefined;
}

export function parseLocalTimeToDate(time: common.LocalTime): Date {
  // split incoming time string on ":"
  const arr: number[] = time.split(":").map(x => parseInt(x));
  // ensure trailing minutes, seconds, millis are overriden with 0's
  while (arr.length < 4) {
    arr.push(0);
  }
  // create a new date object
  const date = new Date();
  // set the constituent date properties
  date.setHours(arr[0], arr[1], arr[2], arr[3]);
  return date;
}

export function formatDateToLocalTime(date: Date) {
  // We need to make sure dates are formatted for London time.
  return format(toLondonZonedTime(date), "HH:mm");
}

export function safeFormatDateToLocalTime(date?: Date | null): LocalTime | undefined {
  return date && !isNaN(date.getTime()) ? formatDateToLocalTime(date) : undefined;
}

export function formatInstantToLocalDateTime(instant: Instant): string {
  // We need to make sure dates are formatted for London time.
  // Todo: make this better
  return format(toLondonZonedTimeInstant(instant), "d LLL yyyy HH:mm:ss");
}

export function tomorrowOrDateFormat(date: Date): string {
  // We need to make sure dates are formatted for London time.
  date = toLondonZonedTime(date);

  if (isTomorrow(date)) {
    return "Tomorrow";
  } else {
    return format(date, "d LLL yyyy");
  }
}
// converts a date string into a timestamp
export function ts(dateStr?: string): Instant {
  return dateStr ? new Date(dateStr).getTime() : now();
}

// Transforms an instant into a UTC LocalDate (displayed date is always in UTC ignoring user locale)
// Useful for those cases where we need to display a London date (UTC) based on an instant
export function tsToUTCLocalDate(instant: Instant): LocalDate {
  return formatDateToISO8601(utcToZonedTime(instant, "UTC"));
}

export function isAfterToday(date: Date): boolean {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return isAfter(today, new Date(date).setHours(0, 0, 0, 0));
}
export const offsetHours = (date: Date, offset: number) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + offset);
  return newDate;
};

/**
 * Returns the current Instant
 */
export function now(): Instant {
  return new Date().getTime();
}

/**
 * Returns the date at the start of the day
 */
export function toDateAtStartOfDay(instant: Instant) {
  const date = new Date(instant);
  date.setHours(0, 0, 0);

  return date;
}

/**
 * Returns the instant at the end of the day
 */
export function toInstantAtEndOfDay(date: Date): Instant {
  date.setHours(23, 59, 59);

  return date.getTime();
}

/**
 * Returns now() if the given date is today, otherwise returns the end of the day instant.
 */
export function getNowOrEndOfDate(date: Date): Instant {
  return isToday(date) ? now() : toInstantAtEndOfDay(date);
}

/**
 * Converts the time of the given date to be 23:59:59
 */
export function toEndOfDate(date: Date) {
  date.setHours(23, 59, 59);
  return date;
}

// Converts string that may contain partial date (i.e. with only year + month or year only) to ISO8601 format
export function parsePartialDate(str: string): PartialDate | undefined {
  const date = new Date(str);
  if (isNaN(date.getTime())) {
    return undefined;
  } else if (str.indexOf(`${date.getFullYear()}`) === -1) {
    // if the year is not in the string, then it was not parsed correctly
    return undefined;
  } else if (date.getFullYear() < 1000) {
    // if the year is less than 1000, then it's not a proper year
    return undefined;
  }
  const isoStr = formatDateToISO8601(date);
  // parse the input string to determine how many date parts it contains and
  // cut the iso string accordingly
  switch (countDateParts(str)) {
    case 3:
      return {
        kind: "fullDate",
        value: isoStr.substring(0, 10),
      };
    case 2:
      return {
        kind: "yearMonth",
        value: isoStr.substring(0, 7),
      };
    case 1:
      return {
        kind: "yearOnly",
        value: isoStr.substring(0, 4),
      };
    default:
      return undefined;
  }
}

export function parseLocalDate(str?: string): LocalDate | undefined {
  const date = str && isFullDateString(str) ? new Date(str) : undefined;
  return date && !isNaN(date.getTime()) ? formatDateToISO8601(date) : undefined;
}

const monthMatchRegx = /(?<=\d\s*|\s|^)(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-zA-Z]*(?=\s*\d|\s|$)/gi;

function countDateParts(str: string): number {
  const monthStr = str.match(monthMatchRegx)?.[0];
  // the date string contains month in text format
  if (monthStr) {
    return (
      str
        .trim()
        .split(monthStr)
        .flatMap(str => str.split(/-|\/|\s|\./))
        .map(x => x.match(/\d+/g))
        .filter(s => s).length + 1
    );
  } else {
    return str.split(/-|\/|\s|\./).filter(s => !!s?.trim()).length;
  }
}

export function isFullDateString(str: string): boolean {
  return countDateParts(str) === 3;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPartialDate(val: any): val is PartialDate {
  if (val != null && typeof val === "object") {
    const { kind: maybeKind, value: maybeValue } = val as PartialDate;
    switch (maybeKind) {
      case "fullDate":
        return maybeValue?.match(/^\d{4}-\d{2}-\d{2}$/) != null;
      case "yearMonth":
        return maybeValue?.match(/^\d{4}-\d{2}$/) != null;
      case "yearOnly":
        return maybeValue?.match(/^\d{4}$/) != null;
    }
  }
  return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isLocalDate(val: any): val is LocalDate {
  return typeof val === "string" && /^((19|20)\d\d)-(0?[1-9]|1[0-2])-(0?[1-9]|1\d|2\d|3[01])$/g.test(val);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isLocalTime(val: any): val is LocalTime {
  return typeof val === "string" && /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/g.test(val);
}

export function daysOverdue(dueDate: string): number {
  const today = toLondonZonedTime(new Date());
  const due = toLondonZonedTime(new Date(dueDate));
  return differenceInDays(today, due);
}
