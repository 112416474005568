/** All routes in the app */
export const enum AppRoutes {
  Login = "/login",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
  ActivateAccount = "/activate-account",
  Logout = "/logout",
  Index = "/",
  NotFound = "/not-found",
  Dashboard = "/dashboard",
  ProductBalances = "/product-balances",
  Inventory = "/inventory",
  Qr = "/qr",

  // Repurchase
  SaleOrder = "/sale-order",
  Repurchase = "/repurchase",
  RepurchaseDelivery = "/repurchase/delivery",
  RepurchaseConfirmation = "/repurchase/confirmation",
  RepurchaseSaleOrders = "/sale-orders/repurchase",

  // Production Orders
  OrgProductionOrders = "/org-production-orders",
  OrgProductionOrderDetails = "/org-production-order",
  OrgProductionOrderProductSelection = "/org-production-orders/product-selection",
  OrgProductionOrderSetup = "/org-production-orders/setup",
  OrgProductionOrderConfirmation = "/org-production-orders/confirmation",
  LocExistingProductionOrders = "/loc-production-orders/existing",
  LocProductionOrders = "/loc-production-orders",
  LocProductionOrderDetails = "/loc-production-order",

  // New deal requests
  OrganisationNewDealRequests = "/organisation/new-deal-requests",
  OrganisationNewDealRequestDetails = "/organisation/new-deal-request/details",
  LocNewDealRequests = "/loc/new-deal-requests",
  LocNewDealRequestsIncoming = "/loc/new-deal-requests/incoming",
  SupplierNewDealRequests = "/supplier/new-deal-requests",
  CarrierRequests = "/carrier-requests",
  CreateNewDealRequest = "/new-deal-request/create",
  NewDealRequestSetup = "/new-deal-request/setup",
  NewDealRequestDeliveryOptions = "/new-deal-request/delivery-options",
  NewDealRequestConfirmation = "/new-deal-request/confirmation",

  // Nominated Purchasers
  OrganisationPurchaseRequests = "/org-purchase-requests",
  OrganisationCreatePurchaseRequest = "/org-purchase-request/setup",
  OrganisationPurchaseRequestProductionPlan = "/org-purchase-request/production-plan",
  OrganisationPurchaseRequestDeliveryOptions = "/org-purchase-request/delivery-options",
  OrganisationPurchaseRequestConfirmation = "/org-purchase-request/confirmation",
  OrganisationPurchaseRequestDetails = "/org-purchase-request/details",
  OrganisationPurchaseRequestByPurchaser = "/org-purchase-request/by-purchaser",

  // Purchaser Purchase Requests
  PurchaserPurchaseRequests = "/purchaser/purchase-requests",
  PurchaserPurchaseRequestDetails = "/purchaser-purchase-requests/details",
  StorageLocationOutgoingOrders = "/outgoing-orders",
  StorageLocationOutgoingOrderDetails = "/outgoing-orders/details",

  //Stock adjustments
  StorageLocStockAdjustmentSetup = "/stock-adjustments/setup",
  StorageLocStockAdjustmentSettings = "/stock-adjustments/settings",
  StorageLocStockAdjustmentConfirmation = "/stock-adjustments/confirmation",
  StorageLocStockAdjustmentDetails = "/stock-adjustments/details",

  // Expiring stock
  OrganisationExpiringStock = "/expiring-stock",
  OrganisationDealExtensionRequestDetails = "/deal-extension-request",
  OrganisationCompletedDealExtensionRequests = "/completed-renewal-requests",

  // Stock movement
  StockMovement = "/stock-movement",
  CreateStockMovement = "/stock-movement/create",
  StockMovementDetails = "/stock-movement/details",

  // Stock Revaluation
  StockRevaluation = "/stock-revaluation",
  StockRevaluationDetails = "/stock-revaluation/details",

  // Help
  HelpUserManual = "/help/user-manual",
}
