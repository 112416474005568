import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PurchaseRequestFlowStepper } from "../../../../widgets/flow-stepper/purchase-request-flow-stepper";
import { PurchaseRequestConfirmationData } from "./organisation-purchase-request-confirmation-page";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { Link } from "components/widgets/link/link";
import { FEROVINUM_CONTACT_URL } from "components/assets/url";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { OrganisationPurchaseRequestProductionPlanCard } from "../../../../widgets/purchase-requests/organisation-purchase-request-production-plan-card/organisation-purchase-request-production-plan-card";
import { ScrollToTop } from "components/utils/scroll-to-top/scroll-to-top";
import { OrganisationPurchaserSummarySection } from "../../../../widgets/purchase-requests/organisation-purchaser-summary-section/organisation-purchaser-summary-section";
import { OrganisationPurchaseRequestOverviewSection } from "../../../../widgets/purchase-requests/organisation-purchase-request-overview-section/organisation-purchase-request-overview-section";
import { useInfoDialog } from "components/context/global-dialog/use-dialog";

export interface OrganisationPurchaseRequestConfirmationPageViewProps {
  purchaseRequestData: PurchaseRequestConfirmationData;
  poFileAdded: boolean;
  onCreateAnother: () => void;
  onBack: () => void;
  onConfirm: (advanceCashUponDelivery: boolean) => Promise<void>;
}

type Sections = "Purchase request overview" | "Production plan" | "Purchaser Summary";
export const OrganisationPurchaseRequestConfirmationPageView = ({
  purchaseRequestData,
  poFileAdded,
  onCreateAnother,
  onBack,
  onConfirm,
}: OrganisationPurchaseRequestConfirmationPageViewProps) => {
  const [selectedTab, setSelectedTab] = useState<Sections>("Purchase request overview");
  const { validEstimateNetReceivable, validRemainingCredit, isConfirmed } = purchaseRequestData;
  const hasProductionPlanAttached = purchaseRequestData.productionPlan !== undefined;
  // Default this to true, will only be passed to api as true when discounted receivable object is not null
  const [advanceCashUponDelivery, setAdvanceCashUponDelivery] = useState(true);

  return (
    <PortalPageContent
      header={
        <OrganisationPurchaseRequestConfirmationHeader
          purchaseRequest={isConfirmed ? purchaseRequestData : undefined}
          hasProductionPlanAttached={hasProductionPlanAttached}
          purchaserName={purchaseRequestData.purchaserName}
        />
      }>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Tabs value={selectedTab} onChange={(_e, v) => setSelectedTab(v)}>
            <Tab label="Purchase request overview" value="Purchase request overview" />
            {hasProductionPlanAttached && <Tab label="Production plan" value="Production plan" />}
            <Tab label="Purchaser Summary" value="Purchaser Summary" />
          </Tabs>
        </Grid>
        {selectedTab === "Purchase request overview" && (
          <Grid item xs={12}>
            <OverviewSection
              purchaseRequestData={purchaseRequestData}
              advanceCashUponDelivery={advanceCashUponDelivery}
              setAdvanceCashUponDelivery={setAdvanceCashUponDelivery}
            />
          </Grid>
        )}
        {selectedTab === "Production plan" && (
          <Grid item xs={12}>
            <ProductionPlanSection purchaseRequestData={purchaseRequestData} />
          </Grid>
        )}
        {selectedTab === "Purchaser Summary" && (
          <Grid item xs={12}>
            <OrganisationPurchaserSummarySection purchaseRequestData={purchaseRequestData} />
          </Grid>
        )}
        <Grid item xs={12}>
          <ActionButtons
            isValid={validEstimateNetReceivable && validRemainingCredit}
            onBack={onBack}
            onConfirm={() =>
              onConfirm(advanceCashUponDelivery && purchaseRequestData.summary.discountedReceivable !== null)
            }
            isConfirmed={isConfirmed}
            purchaserName={purchaseRequestData.purchaserName}
            poFileAdded={poFileAdded}
            onCreateAnother={onCreateAnother}
          />
        </Grid>
      </Grid>
    </PortalPageContent>
  );
};

interface SectionProps {
  purchaseRequestData: PurchaseRequestConfirmationData;
}

interface OverviewSectionProps extends SectionProps {
  advanceCashUponDelivery: boolean;
  setAdvanceCashUponDelivery: (advanceUponDelivery: boolean) => void;
}

export const OverviewSection = ({
  purchaseRequestData,
  advanceCashUponDelivery,
  setAdvanceCashUponDelivery,
}: OverviewSectionProps) => {
  const { validEstimateNetReceivable, validRemainingCredit, isConfirmed } = purchaseRequestData;

  const netReceivableAmount = useMemo(() => {
    if (advanceCashUponDelivery && purchaseRequestData.summary.discountedReceivable) {
      return purchaseRequestData.summary.discountedReceivable.netReceivable;
    }
    return purchaseRequestData.summary.netReceivable;
  }, [
    advanceCashUponDelivery,
    purchaseRequestData.summary.discountedReceivable,
    purchaseRequestData.summary.netReceivable,
  ]);

  const additionalMonthlyFeeAmount = useMemo(() => {
    if (advanceCashUponDelivery && purchaseRequestData.summary.discountedReceivable) {
      return purchaseRequestData.summary.discountedReceivable.additionalMonthlyFees;
    }
    return purchaseRequestData.summary.additionalMonthlyFees;
  }, [
    advanceCashUponDelivery,
    purchaseRequestData.summary.discountedReceivable,
    purchaseRequestData.summary.additionalMonthlyFees,
  ]);

  const state = useMemo(() => {
    if (purchaseRequestData.poFileUrl) {
      return "purchaserAccepted";
    } else {
      return "new";
    }
  }, [purchaseRequestData.poFileUrl]);

  return (
    <Grid container spacing={5}>
      {!validEstimateNetReceivable && (
        <Grid item xs={12}>
          <Stack direction="row" spacing={1} alignItems="center">
            <WarningAmberOutlinedIcon color="warning" />
            <Typography color="common.error">
              Estimated net receivable amount cannot be 0 or negative. Please
            </Typography>
            <Link color="error" href={FEROVINUM_CONTACT_URL}>
              contact Ferovinum
            </Link>
          </Stack>
        </Grid>
      )}
      {!validRemainingCredit && (
        <Grid item xs={12}>
          <Stack direction="row" spacing={1} alignItems="center">
            <WarningAmberOutlinedIcon color="warning" />
            <Typography color="common.error">Not enough remaining credit available. Please</Typography>
            <Link color="error" href={FEROVINUM_CONTACT_URL}>
              contact Ferovinum
            </Link>
          </Stack>
        </Grid>
      )}
      <OrganisationPurchaseRequestOverviewSection
        {...purchaseRequestData}
        summary={{
          ...purchaseRequestData.summary,
          netReceivable: netReceivableAmount,
          additionalMonthlyFees: additionalMonthlyFeeAmount,
          cashAmountToAdvanceUponDelivery:
            purchaseRequestData.summary.discountedReceivable?.amountAdvancedUponDelivery ?? null,
          purchaserCoversDeliveryCost: purchaseRequestData.purchaserCoversDeliveryCost,
          totalAdvanceAmountUponDelivery: "0",
          customerPoRef: purchaseRequestData.customerPoRef ?? null,
        }}
        state={state}
        previewProps={{
          isPreview: !isConfirmed,
          advanceCashUponDelivery,
          setAdvanceCashUponDelivery,
        }}
        poFileUrl={purchaseRequestData.poFileUrl ?? null}
        advanceUponDeliveryPaymentDate={null}
        orgNetReceivablePaymentDate={null}
      />
    </Grid>
  );
};

export const ProductionPlanSection = ({ purchaseRequestData }: SectionProps) => {
  return (
    <Stack spacing={5}>
      {purchaseRequestData.productionPlan?.map(productionPlan => (
        <OrganisationPurchaseRequestProductionPlanCard
          key={productionPlan.finishedProduct.product.id}
          productionPlan={productionPlan}
          currency={purchaseRequestData.settlementCurrency}
        />
      ))}
    </Stack>
  );
};

const OrganisationPurchaseRequestConfirmationHeader = ({
  purchaseRequest,
  hasProductionPlanAttached,
  purchaserName,
}: {
  purchaseRequest?: PurchaseRequestConfirmationData;
  hasProductionPlanAttached: boolean;
  purchaserName: string;
}) => {
  const title = (
    <Stack>
      <Typography variant={"h4"}>{purchaseRequest ? "" : "New"} Third Party Sale Order</Typography>
      <Typography>from {purchaserName}</Typography>
    </Stack>
  );

  if (purchaseRequest) {
    return (
      <>
        <PortalPageContentHeader
          useConfirmationTitle
          variant="split"
          title={title}
          right={
            <Stack width={"100%"} textAlign={"right"}>
              <Typography sx={{ fontSize: 12, color: "common.grey5" }}>Reference Number</Typography>
              <Typography variant="subtitle1Bold">{purchaseRequest.purchaseRequestNumber}</Typography>
            </Stack>
          }
        />
        <ScrollToTop />
      </>
    );
  }

  return (
    <PortalPageContentHeader
      variant="split"
      title={title}
      right={
        <PurchaseRequestFlowStepper
          activeStep={hasProductionPlanAttached ? 3 : 2}
          withProductionPlan={hasProductionPlanAttached}
        />
      }
    />
  );
};

interface ActionButtonsProps {
  onBack: () => void;
  onConfirm: () => Promise<void>;
  isValid: boolean;
  isConfirmed: boolean;
  purchaserName: string;
  poFileAdded: boolean;
  onCreateAnother: () => void;
}

const ActionButtons = ({
  onBack,
  onConfirm,
  isConfirmed,
  isValid,
  purchaserName,
  poFileAdded,
  onCreateAnother,
}: ActionButtonsProps) => {
  const [loading, setLoading] = useState(false);
  const [acceptDetails, setAcceptDetails] = useState(false);
  const { showInfoDialog } = useInfoDialog();
  return (
    <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
      {isConfirmed ? (
        <>
          <Button onClick={onCreateAnother}>Create another sale order for {purchaserName}</Button>
        </>
      ) : (
        <>
          {poFileAdded && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox value={acceptDetails} onClick={() => setAcceptDetails(!acceptDetails)} disabled={loading} />
                }
                label={
                  <Stack direction="row" spacing={0.5}>
                    <Typography>Select to confirm the</Typography>
                    <Link
                      onClick={async e => {
                        e.preventDefault();
                        await showInfoDialog({
                          body: (
                            <Stack spacing={2}>
                              <ol>
                                <li>The attached purchase order has been made out to Ferovinum</li>
                                <li>
                                  All details entered in this sale order match what is shown on the uploaded purchase
                                  order
                                </li>
                              </ol>
                            </Stack>
                          ),
                          size: "md",
                        });
                      }}>
                      details
                    </Link>
                    <Typography>are correct</Typography>
                  </Stack>
                }
              />
            </FormGroup>
          )}
          <Button variant="outlined" onClick={onBack} disabled={loading}>
            Back
          </Button>
          <LoadingActionButton
            variant="contained"
            disabled={!isValid || (poFileAdded && !acceptDetails)}
            onClick={async () => {
              setLoading(true);
              await onConfirm();
              setLoading(false);
            }}>
            Confirm
          </LoadingActionButton>
        </>
      )}
    </Stack>
  );
};
