import { DealLegSaleOrder, SaleOrderTotalsView } from "adl-gen/ferovinum/app/api";
import React, { useMemo } from "react";
import {
  Box,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TypographyProps,
} from "@mui/material";
import { OrganisationProductSummary } from "../../organisation-product-summary/organisation-product-summary";
import { Currency, ProductId, TopLevelUnitType, valuesTopLevelUnitType } from "adl-gen/ferovinum/app/db";
import {
  add,
  getVesselCapacity,
  isSinglesUnitType,
  numberOfUnitsForUnitType,
  unitsLabelForUnitType,
} from "utils/model-utils";
import { DbKey } from "adl-gen/common/db";
import { getTopLevelForUnitType } from "utils/adl-utils";
import { assertNotUndefined } from "utils/hx/util/types";
import { styled } from "@mui/material/styles";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";

export type RepurchaseProductsTableProps = SaleOrderTotalsView & {
  dealLegSaleOrders: DealLegSaleOrder[];
  currency: Currency;
  settlementCurrency: Currency;
};
type DealLegSaleOrdersByProductId = Map<DbKey<ProductId>, DealLegSaleOrder[]>;
type DealLegSaleOrdersByTopLevelUnitType = Map<TopLevelUnitType, DealLegSaleOrdersByProductId>;
export const RepurchaseProductsTable = ({
  dealLegSaleOrders,
  currency,
  ...totalsView
}: RepurchaseProductsTableProps) => {
  const dealLegSaleOrderByType: DealLegSaleOrdersByTopLevelUnitType = useMemo(() => {
    const result: DealLegSaleOrdersByTopLevelUnitType = new Map(
      valuesTopLevelUnitType.map(top => {
        return [top, new Map<DbKey<ProductId>, DealLegSaleOrder[]>()];
      }),
    );

    dealLegSaleOrders.forEach(saleOrder => {
      const mapForUnitType = assertNotUndefined(
        result.get(getTopLevelForUnitType(saleOrder.productWithId.value.unitType)),
      );
      const productId = saleOrder.productWithId.id;
      if (mapForUnitType.has(productId)) {
        assertNotUndefined(mapForUnitType.get(productId)).push(saleOrder);
      } else {
        mapForUnitType.set(productId, [saleOrder]);
      }
    });

    return result;
  }, [dealLegSaleOrders]);

  return (
    <>
      {valuesTopLevelUnitType
        .filter(type => dealLegSaleOrderByType.get(type)?.size ?? 0 > 0)
        .map(topLevelUnitType => {
          const saleOrdersByProduct = assertNotUndefined(dealLegSaleOrderByType.get(topLevelUnitType));
          return (
            <RepurchaseStockTable
              key={topLevelUnitType}
              saleOrdersByProduct={saleOrdersByProduct}
              stockType={topLevelUnitType}
              currency={currency}
              {...totalsView}
            />
          );
        })}
    </>
  );
};

const calculateNetPayable = (s: DealLegSaleOrder): number => {
  return Number(s.forwardSalePrice) - Number(s.dealLegWithId.value.depositPricePerUnit);
};

const calculateSubTotal = (s: DealLegSaleOrder): number => {
  return calculateNetPayable(s) * s.unitsOrdered.value;
};

type RepurchaseStockTableProps = SaleOrderTotalsView & {
  settlementCurrency: Currency;
  saleOrdersByProduct: DealLegSaleOrdersByProductId;
  stockType: TopLevelUnitType;
  currency: Currency;
};
const RepurchaseStockTable = ({
  saleOrdersByProduct,
  stockType,
  currency,
  ...totalsView
}: RepurchaseStockTableProps) => {
  // Note: Repurchases are always of a single type of product (E.g all bottles, all casks, etc...)
  const unitsLabel = `${unitsLabelForUnitType(stockType)}`;
  const perUnits = !isSinglesUnitType(stockType) ? `(per ${unitsLabel})` : undefined;
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="right" sx={{ pr: 0 }}>
              Forward sale price
              {perUnits && ` ${perUnits}`}
            </TableCell>
            <TableCell align="right" sx={{ pr: 0 }}>
              Deposit paid
              {perUnits && ` ${perUnits}`}
            </TableCell>
            <TableCell align="right" sx={{ pr: 0 }}>
              Net payable
              {perUnits && ` ${perUnits}`}
            </TableCell>
            <TableCell align="right" sx={{ pr: 0 }}>
              Quantity
              {perUnits && ` (${unitsLabel})`}
            </TableCell>
            <TableCell align="right">Subtotal</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...saleOrdersByProduct.values()].map(saleOrders => (
            <RepurchaseProductsTableRow
              key={saleOrders[0].productWithId.id}
              saleOrders={saleOrders}
              currency={currency}
            />
          ))}
          <TotalPriceSummary {...totalsView} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

/** Displays a row splitted by each sale order */
const RepurchaseProductsTableRow = ({
  saleOrders,
  currency,
}: {
  saleOrders: DealLegSaleOrder[];
  currency: Currency;
}) => {
  const productId = saleOrders[0].productWithId.id;
  const product = saleOrders[0].productWithId.value;

  const forwardSalePrices = useMemo(
    () =>
      saleOrders.map(s => (
        <CurrencyRenderer key={""} currency={currency} value={s.forwardSalePrice} maximumFractionDigits={4} />
      )),
    [saleOrders, currency],
  );
  const depositPaidPrices = useMemo(
    () =>
      saleOrders.map(s => (
        <CurrencyRenderer
          key={""}
          currency={currency}
          value={s.dealLegWithId.value.depositPricePerUnit}
          maximumFractionDigits={4}
        />
      )),
    [saleOrders, currency],
  );
  const netPayablePrices = useMemo(
    () =>
      saleOrders.map(s => (
        <CurrencyRenderer key={""} currency={currency} value={calculateNetPayable(s)} maximumFractionDigits={4} />
      )),
    [saleOrders, currency],
  );
  const zeroUnits = useMemo(() => numberOfUnitsForUnitType(product.unitType, 0), [product.unitType]);
  const quantities = useMemo(() => saleOrders.map(s => s.unitsOrdered.value.toString()), [saleOrders]);
  const totalQuantity = useMemo(
    () => saleOrders.reduce((sum, val) => add(sum, val.unitsOrdered), zeroUnits),
    [saleOrders, zeroUnits],
  );
  const totalPrices = useMemo(
    () =>
      saleOrders.map(s => (
        <CurrencyRenderer key={""} currency={currency} value={calculateSubTotal(s)} maximumFractionDigits={4} />
      )),
    [saleOrders, currency],
  );

  return (
    <TableRow>
      <TableCell>
        <OrganisationProductSummary
          {...product}
          productId={productId}
          vesselCapacity={getVesselCapacity(product.vesselSize, totalQuantity)}
        />
      </TableCell>
      <TableCell align="right" sx={{ pl: 0, pr: 0 }}>
        <StackedValues values={forwardSalePrices} keyPrefix={`${productId}-fsp`} />
      </TableCell>
      <TableCell align="right" sx={{ pl: 0, pr: 0 }}>
        <StackedValues values={depositPaidPrices} keyPrefix={`${productId}-deposit`} />
      </TableCell>
      <TableCell align="right" sx={{ pl: 0, pr: 0 }}>
        <StackedValues values={netPayablePrices} keyPrefix={`${productId}-net`} />
      </TableCell>
      <TableCell align="right" sx={{ pl: 0, pr: 0 }}>
        <StackedValues values={quantities} keyPrefix={`${productId}-quantities`} />
      </TableCell>
      <TableCell align="right" sx={{ pl: 0, pr: 0 }}>
        <StackedValues values={totalPrices} keyPrefix={`${productId}-total`} pr />
      </TableCell>
    </TableRow>
  );
};

const StackedValues = ({
  values,
  keyPrefix,
  pr,
}: {
  values: string[] | React.ReactNode[];
  keyPrefix: string;
  pr?: boolean;
}) => {
  return (
    <Stack divider={<Divider flexItem />} spacing={1} sx={{ ...(pr && { pr: 2 }) }}>
      {values.map((val, idx) => (
        <Box key={`${keyPrefix}-value-${idx}`}>{val}</Box>
      ))}
    </Stack>
  );
};

type TotalPriceSummaryProps = SaleOrderTotalsView & {
  settlementCurrency: Currency;
};
const TotalPriceSummary = ({
  duty,
  vat,
  fulfilmentFee,
  totalWithVat,
  deliveryFee,
  netSubtotal,
  settlementCurrency,
}: TotalPriceSummaryProps) => {
  const displayNetSubtotalAndTotal = vat !== null || duty !== null || deliveryFee !== null || fulfilmentFee !== null;

  return (
    <>
      <TotalRowInfoField
        title={displayNetSubtotalAndTotal ? "Net subtotal" : "Total"}
        currency={settlementCurrency}
        value={netSubtotal}
        noBottom
      />
      {fulfilmentFee !== null && (
        <TotalRowInfoField title="Fulfilment fee" currency={settlementCurrency} value={fulfilmentFee} noBottom />
      )}
      {duty !== null && <TotalRowInfoField title="Duty" value={duty} currency={settlementCurrency} noBottom />}
      {vat !== null && <TotalRowInfoField title="VAT" value={vat} currency={settlementCurrency} />}
      {deliveryFee !== null && (
        <>
          <TotalRowInfoField
            title="Delivery Fee"
            value={deliveryFee.totalDeliveryFee}
            currency={settlementCurrency}
            noBottom
          />
          <TotalRowInfoField title="VAT" value={deliveryFee.vat} currency={settlementCurrency} />
        </>
      )}

      {displayNetSubtotalAndTotal && (
        <TotalRowInfoField title="Total with VAT" value={totalWithVat} currency={settlementCurrency} noBottom />
      )}
    </>
  );
};

const TotalRowInfoField = ({
  title,
  value,
  currency,
  noBottom,
  typographyProps,
}: {
  title: React.ReactNode;
  value: string;
  currency: Currency;
  noBottom?: boolean;
  typographyProps?: { value?: TypographyProps };
}) => {
  return (
    <TableRow sx={{ backgroundColor: "common.grey1" }}>
      <SummaryTableCell colSpan={2} noBottom />
      <SummaryTableCell colSpan={3} align="right" noBottom={noBottom}>
        <Typography variant={"subtitle1Bold"}>{title}</Typography>
      </SummaryTableCell>
      <SummaryTableCell align="right" noBottom={noBottom}>
        <CurrencyRenderer variant={"subtitle1"} {...typographyProps?.value} value={value} currency={currency} />
      </SummaryTableCell>
    </TableRow>
  );
};

const SummaryTableCell = styled(TableCell, { shouldForwardProp: propName => propName !== "noBottom" })<{
  noBottom?: boolean;
}>(({ noBottom }) => ({
  textAlign: "right",
  ...(noBottom && { borderBottom: "unset" }),
}));
