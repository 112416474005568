import { Box, Button, Grid, Stack } from "@mui/material";
import React from "react";
import { NewDealRequestType, NewSupplier, Supplier } from "adl-gen/ferovinum/app/db";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { StickyNote } from "../../../../widgets/sticky-note/sticky-note";
import { formatDate } from "utils/date-utils";
import { NewDealRequestFlowStepper } from "../../../../widgets/flow-stepper/new-deal-request-flow-stepper";
import { assertNotUndefined } from "utils/hx/util/types";
import {
  isNewSupplier,
  NewDealRequestFlowState,
} from "../organisation-create-new-deal-request/organisation-create-new-deal-request-page";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { useSettlementCurrency } from "../../../../layouts/portal-page-layout/portal-page";
import { WithDbId } from "adl-gen/common/db";
import { IncotermsStickyNote } from "../../../../widgets/sticky-note/incoterms-sticky-note/incoterms-sticky-note";
import { DeliveryContactStickyNote } from "../../../../widgets/sticky-note/delivery-contact-sticky-note/delivery-contact-sticky-note";
import { OrganisationNewDealRequestNetSubtotalTable } from "../../../../widgets/new-deal-requests/organisation-new-deal-request-net-subtotal-table/organisation-new-deal-request-net-subtotal-table";
import { PreviewNewDealRequest } from "./organisation-new-deal-request-confirmation-page";
import { ScrollToTop } from "components/utils/scroll-to-top/scroll-to-top";
import { useSelectedEntityIsWholesaler } from "../../../../../utils/procurement-ui-utils";

export interface OrganisationNewDealRequestConfirmationPageViewProps {
  purchaseOrderFlowState: NewDealRequestFlowState;
  previewNewDealRequest: PreviewNewDealRequest;
  navigateToPreviousStep: () => void;
  navigateToCreatePurchaseOrder: () => void;
  onChangeSupplier: () => void;
  onChangeCarrierOrIncoterms: () => void;
  onChangeStorageLocation: () => void;
  onChangeProductsSelection: () => void;
  onConfirm: () => Promise<void>;
  confirmedDealNumber?: string;
  isUpdate: boolean;
}
export const OrganisationNewDealRequestConfirmationPageView = ({
  purchaseOrderFlowState,
  previewNewDealRequest,
  navigateToPreviousStep,
  navigateToCreatePurchaseOrder,
  onChangeSupplier,
  onChangeCarrierOrIncoterms,
  onChangeStorageLocation,
  onChangeProductsSelection,
  onConfirm,
  confirmedDealNumber,
  isUpdate,
}: OrganisationNewDealRequestConfirmationPageViewProps) => {
  const { variant, selectedProducts, currency, storageLocation } = purchaseOrderFlowState;
  const settlementCurrency = useSettlementCurrency();
  const orgIsWholesaler = useSelectedEntityIsWholesaler();

  return (
    <PortalPageContent
      header={
        confirmedDealNumber ? (
          <OrganisationPurchaseOrderConfirmedHeader dealNumber={confirmedDealNumber} />
        ) : (
          <OrganisationPurchaseOrderConfirmationHeader variant={variant} />
        )
      }>
      <Stack spacing={7}>
        <Grid container spacing={2}>
          {purchaseOrderFlowState.variant === "newStock" && (
            <NewStockStickyNotes
              supplier={assertNotUndefined(purchaseOrderFlowState.supplier)}
              carrierName={assertNotUndefined(purchaseOrderFlowState.carrier).value.name}
              onChangeSupplier={!confirmedDealNumber ? onChangeSupplier : undefined}
              onChangeCarrier={!confirmedDealNumber ? onChangeCarrierOrIncoterms : undefined}
            />
          )}
          <Grid item xs={6} lg={3}>
            <StickyNote
              title={variant === "newStock" ? "Destination" : "Storage location"}
              action={!confirmedDealNumber ? { label: "Change", onClick: onChangeStorageLocation } : undefined}
              line1={assertNotUndefined(storageLocation).storageLocationName}
            />
          </Grid>
          {purchaseOrderFlowState.variant === "newStock" && purchaseOrderFlowState.incoterms && (
            <Grid item xs={6} lg={3}>
              <IncotermsStickyNote
                incoterms={purchaseOrderFlowState.incoterms}
                action={!confirmedDealNumber ? { label: "Change", onClick: onChangeCarrierOrIncoterms } : undefined}
              />
            </Grid>
          )}
        </Grid>
        {selectedProducts.length > 0 && (
          <OrganisationNewDealRequestNetSubtotalTable
            variant={purchaseOrderFlowState.variant}
            products={previewNewDealRequest.lineItems}
            purchaseCurrency={currency}
            settlementCurrency={settlementCurrency}
            netSubtotal={previewNewDealRequest.netSubtotal}
            discount={previewNewDealRequest.discount}
            action={
              !confirmedDealNumber
                ? { label: "Change product selection", onClick: onChangeProductsSelection }
                : undefined
            }
            orgIsWholesaler={orgIsWholesaler}
          />
        )}

        {!confirmedDealNumber ? (
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={navigateToPreviousStep}>
              Back
            </Button>
            <LoadingActionButton onClick={onConfirm}>{isUpdate ? "Update" : "Confirm"}</LoadingActionButton>
          </Stack>
        ) : isUpdate ? (
          <></>
        ) : (
          <Box>
            <Button variant="contained" onClick={navigateToCreatePurchaseOrder}>
              Create another purchase order
            </Button>
          </Box>
        )}
      </Stack>
    </PortalPageContent>
  );
};

interface OrganisationPurchaseOrderConfirmationHeaderProps {
  variant: NewDealRequestType;
}
const OrganisationPurchaseOrderConfirmationHeader = ({ variant }: OrganisationPurchaseOrderConfirmationHeaderProps) => {
  return (
    <PortalPageContentHeader
      variant="split"
      title="Confirmation"
      right={<NewDealRequestFlowStepper activeStep={variant === "existingStock" ? 2 : 3} variant={variant} />}
    />
  );
};
interface OrganisationPurchaseOrderConfirmedHeaderProps {
  dealNumber: string;
}
const OrganisationPurchaseOrderConfirmedHeader = ({ dealNumber }: OrganisationPurchaseOrderConfirmedHeaderProps) => {
  return (
    <>
      <PortalPageContentHeader
        useConfirmationTitle
        title="Your order has successfully been submitted for Ferovinum to review."
        subtitles={[
          {
            icon: "receipt",
            text: `New deal request order number: ${dealNumber}`,
          },
          {
            icon: "date",
            text: `Order date: ${formatDate(new Date())}`,
          },
        ]}
      />
      <ScrollToTop />
    </>
  );
};

interface NewStockStickyNoteProps {
  supplier: WithDbId<Supplier> | NewSupplier;
  carrierName: string;
  onChangeSupplier?: () => void;
  onChangeCarrier?: () => void;
}
const NewStockStickyNotes = ({ supplier, carrierName, onChangeSupplier, onChangeCarrier }: NewStockStickyNoteProps) => {
  const supplierData = isNewSupplier(supplier) ? supplier : supplier.value;
  return (
    <>
      <Grid item xs={6} lg={3}>
        <DeliveryContactStickyNote
          title="Supplier"
          action={onChangeSupplier ? { label: "Change", onClick: onChangeSupplier } : undefined}
          companyName={supplierData.name}
          deliveryContact={supplierData.contactName}
          deliveryContactEmail={supplierData.contactEmail}
          deliveryContactNumber={supplierData.contactMobile}
        />
      </Grid>

      <Grid item xs={6} lg={3}>
        <StickyNote
          title="Carrier"
          action={onChangeCarrier ? { label: "Change", onClick: onChangeCarrier } : undefined}
          line1={carrierName}
        />
      </Grid>
    </>
  );
};
