import { Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { UnionFieldDefMapping } from "utils/data-field/data-field-builder";
import { DataFieldDef } from "utils/data-field/data-field-def";
import { keysOf, mapRecord } from "utils/type-utils";
import { UnionType } from "utils/utility-types";
import { FormField } from "../../types/formik-types";
import { ExpandableRadio, ExpandableRadioOption } from "../../widgets/inputs/expandable-radio/expandable-radio";

type UiSupplier<T> = (def: DataFieldDef<T>, field: FormField<T>, displayed: boolean) => JSX.Element;

type UnionUiEntry<E> = { label: string } & (E extends { value: unknown }
  ? { ui: UiSupplier<E["value"]> }
  : { ui?: JSX.Element }) & { disabled?: boolean };

export type UnionUiMapping<U extends UnionType> = {
  [E in U as E["kind"]]: UnionUiEntry<E>;
};

export function UnionInputWidget<U extends UnionType>(props: {
  field: FormField<U>;
  fieldDef: DataFieldDef<U>;
  uiMapping: UnionUiMapping<U>;
}) {
  const { field, fieldDef, uiMapping } = props;
  if (fieldDef.metaData.kind != "union") {
    throw new Error(`Incompatible DataFieldDef, require 'union' but got ${fieldDef.metaData.kind}`);
  }
  const defMapping = fieldDef.metaData.defMapping as UnionFieldDefMapping<U>;
  const unionKindField = useMemo(() => field.getSubField("kind") as FormField<string>, [field]);
  const unionValueField = useMemo(() => field.getSubField("value"), [field]);

  const options: ExpandableRadioOption[] = useMemo(
    () =>
      keysOf(uiMapping).map(kind => {
        const { label, ui, disabled } = uiMapping[kind] as UnionUiEntry<unknown>;
        if (typeof ui === "function" && defMapping[kind] !== "no-value") {
          const valueDef = defMapping[kind] as DataFieldDef<unknown>;
          const displayed = unionKindField.get() === kind;
          const children = (ui as UiSupplier<unknown>)(valueDef as DataFieldDef<unknown>, unionValueField, displayed);
          return { value: kind, label, disabled, children };
        } else {
          return { value: kind, label, disabled };
        }
      }),
    [defMapping, uiMapping, unionKindField, unionValueField],
  );

  const cachedStates = useMemo(() => {
    const { kind, value } = (field.get() as (U & { value: unknown }) | undefined) ?? {};
    return mapRecord(uiMapping, (k, v) => {
      if (kind == k) {
        return value;
      } else if (typeof v.ui == "function") {
        return {};
      } else {
        return undefined;
      }
    }) as Record<U["kind"], unknown>;
  }, [uiMapping, field]);

  const error = field.getError(true);
  // error is a string means it's related to the overal union field
  const unionError = typeof error === "string" ? error : undefined;

  return (
    <Stack spacing={3}>
      <ExpandableRadio
        options={options}
        value={unionKindField.get()}
        onChange={value => {
          const prevKind = unionKindField.get() as U["kind"] | undefined;
          if (prevKind) {
            cachedStates[prevKind] = unionValueField.get();
          }
          unionKindField.setValidateAndTouch(value);
          unionValueField.set(cachedStates[value as U["kind"]]);
        }}
      />
      <Typography color="error">{unionError}</Typography>
    </Stack>
  );
}
