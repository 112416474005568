/* ---------------------------------------------------------------------------------

Ferovinum global styles (used in material ui theme as overrides)
- https://mui.com/material-ui/customization/how-to-customize/#4-global-css-override

--------------------------------------------------------------------------------- */

import { COMMON_COLORS, GREY_COLORS } from "./color-palette";

export const FerovinumGlobalStyles = `
  html, body {
      height: initial // override for semantic css
  }

  html,
  html > body,
  select,
  button {
    font-family: "Roboto", "Arial", sans-serif;
    font-size: 16px;
    color: ${GREY_COLORS.grey7}
  }

  h1,h2,h3,h4,h5,h6 {
    line-height: 1.15;
  }
  
  h1 {
    font-size: 96px;
    font-weight: 300;
  }
  
  h2 {
    font-size: 60px;
    font-weight: 300;
  }
  
  h3 {
    font-size: 48px;
  }
  
  h4 {
    font-size: 34px;
  }

  h5 {
    font-size: 24px;
  }

  h6 {
    font-size: 20px;
    font-weight: 500;
  }

  b {
    font-weight: 700;  
  }

  a {
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    color: ${COMMON_COLORS.hyperLink}
  }

  a:hover {
    text-decoration: underline;
  }

  p {
    // @override for semantic-css styles
    margin: 0;
  }
`;
