import React from "react";
import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Link } from "components/widgets/link/link";
import { FEROVINUM_CONTACT_URL } from "components/assets/url";
import { useInfoDialog } from "components/context/global-dialog/use-dialog";
import { QuestionContainer } from "../common/question-container/question-container";

export interface IncotermsInfo<T> {
  code: T;
  label: string;
  description: string;
  disabled?: boolean;
}
interface IncotermSelectProps<T> {
  value?: T;
  incotermsToDescriptionMap: Map<T, IncotermsInfo<T>>;
  question?: string;
  onChange(v: string): void;
  overwriteInfo?: React.ReactNode;
  direction?: "column" | "row";
}
export const IncotermSelect = <T extends string>({
  question,
  value,
  onChange,
  incotermsToDescriptionMap,
  overwriteInfo,
  direction = "row",
}: IncotermSelectProps<T>) => {
  const { showInfoDialog } = useInfoDialog();

  const incoTermsOptions = [...incotermsToDescriptionMap.values()].sort((a, b) => a.code.localeCompare(b.code));

  return (
    <QuestionContainer
      question={question}
      direction={direction}
      Input={
        <FormControl fullWidth>
          <InputLabel>Select Incoterms</InputLabel>
          <Select
            value={value ?? ""}
            label="Select Incoterms"
            onChange={e => e.target.value && onChange(e.target.value)}>
            {incoTermsOptions.map(({ code, label, disabled }) => (
              <MenuItem key={code} value={code} disabled={disabled}>
                {`${code} - ${label}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
      Feedback={
        <>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body2" color="common.darkGrey">
              Not sure what these incoterms mean?
            </Typography>
            <Link
              variant="big"
              color="grey5"
              onClick={async () => {
                await showInfoDialog({
                  body: overwriteInfo ?? (
                    <Stack spacing={2}>
                      <Typography variant="h5">What do these Incoterms mean?</Typography>
                      {incoTermsOptions.map(({ code, label, description }) => {
                        return (
                          <Stack key={code}>
                            <Typography variant="subtitle1Bold">{`${code} - ${label}`}</Typography>
                            <Typography variant="body1">{description}</Typography>
                          </Stack>
                        );
                      })}
                      {overwriteInfo}
                    </Stack>
                  ),
                });
              }}>
              Learn more
            </Link>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body2" color="common.darkGrey">
              {"Can't find the appropirate incoterms?"}
            </Typography>
            <Link variant="big" color="grey5" href={FEROVINUM_CONTACT_URL} target="_blank">
              Contact Ferovinum
            </Link>
          </Stack>
        </>
      }
    />
  );
};
