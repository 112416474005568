/* @generated from adl module ferovinum.app.uiconfig */

import * as ADL from './../../runtime/adl';

/**
 * Configuration for the web frontend.
 */
export interface UiConfig {
  /**
   * Application title
   */
  title: string;
  /**
   * Environment name, e.g. dev, uat, prod
   */
  environment: string;
  /**
   * Name of the release / code version
   */
  releaseName: string;
  hotjarConfig: HotjarConfig;
}

export function makeUiConfig(
  input: {
    title?: string,
    environment: string,
    releaseName: string,
    hotjarConfig: HotjarConfig,
  }
): UiConfig {
  return {
    title: input.title === undefined ? "" : input.title,
    environment: input.environment,
    releaseName: input.releaseName,
    hotjarConfig: input.hotjarConfig,
  };
}

const UiConfig_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.uiconfig","decl":{"annotations":[{"value":"Configuration for the web frontend.\n","key":{"moduleName":"sys.annotations","name":"Doc"}}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[{"value":"Application title\n","key":{"moduleName":"sys.annotations","name":"Doc"}}],"serializedName":"title","default":{"kind":"just","value":""},"name":"title","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[{"value":"Environment name, e.g. dev, uat, prod\n","key":{"moduleName":"sys.annotations","name":"Doc"}}],"serializedName":"environment","default":{"kind":"nothing"},"name":"environment","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[{"value":"Name of the release / code version\n","key":{"moduleName":"sys.annotations","name":"Doc"}}],"serializedName":"releaseName","default":{"kind":"nothing"},"name":"releaseName","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[],"serializedName":"hotjarConfig","default":{"kind":"nothing"},"name":"hotjarConfig","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"ferovinum.app.uiconfig","name":"HotjarConfig"}},"parameters":[]}}]}},"name":"UiConfig","version":{"kind":"nothing"}}};

export const snUiConfig: ADL.ScopedName = {moduleName:"ferovinum.app.uiconfig", name:"UiConfig"};

export function texprUiConfig(): ADL.ATypeExpr<UiConfig> {
  return {value : {typeRef : {kind: "reference", value : snUiConfig}, parameters : []}};
}

export interface HotjarConfig {
  siteId: string;
  version: string;
}

export function makeHotjarConfig(
  input: {
    siteId: string,
    version: string,
  }
): HotjarConfig {
  return {
    siteId: input.siteId,
    version: input.version,
  };
}

const HotjarConfig_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.uiconfig","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"siteId","default":{"kind":"nothing"},"name":"siteId","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[],"serializedName":"version","default":{"kind":"nothing"},"name":"version","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}]}},"name":"HotjarConfig","version":{"kind":"nothing"}}};

export const snHotjarConfig: ADL.ScopedName = {moduleName:"ferovinum.app.uiconfig", name:"HotjarConfig"};

export function texprHotjarConfig(): ADL.ATypeExpr<HotjarConfig> {
  return {value : {typeRef : {kind: "reference", value : snHotjarConfig}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "ferovinum.app.uiconfig.UiConfig" : UiConfig_AST,
  "ferovinum.app.uiconfig.HotjarConfig" : HotjarConfig_AST
};
