import React, { useEffect, useMemo } from "react";
import {
  Paper,
  Table,
  TableBodyProps,
  TableContainer,
  TableFooter,
  TableHead,
  TableHeadProps,
  TablePagination,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export interface ClientSidePaginatedTableProps<T> {
  Header: React.ReactElement<TableHeadProps>;
  Body: React.ReactElement<TableBodyProps & { rows: T[] }>;
  rows: T[];
  initialRowsPerPage?: 10 | 25 | 50 | 100;
}
export const ClientSidePaginatedTable = <T,>({
  Header,
  Body,
  rows,
  initialRowsPerPage,
}: ClientSidePaginatedTableProps<T>) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(initialRowsPerPage ?? 10);

  const handleChangePage = (_e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const paginatedRows = useMemo(
    () => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rows, rowsPerPage],
  );

  useEffect(() => {
    // This will reset the page to 0 whenever rows changes
    setPage(0);
  }, [rows]);

  const paginationCtrl = (
    <TableRow>
      <StickyTablePagination
        count={rows.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableRow>
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>{paginationCtrl}</TableHead>
        {Header}
        {React.cloneElement(Body, { rows: paginatedRows })}
        <TableFooter>{paginationCtrl}</TableFooter>
      </Table>
    </TableContainer>
  );
};

const StickyTablePagination = styled(TablePagination)(({ theme }) => ({
  alignItems: "baseline",
  position: "sticky",
  // Note: Negative bottom due to rendering issue in chrome
  // bottom: 0
  bottom: "-1px",
  backgroundColor: theme.palette.common.white,
  border: "none",
  borderTop: `1px solid ${theme.palette.divider}`,
}));
