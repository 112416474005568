import React, { useCallback, useState } from "react";
import { TextField, TextFieldProps } from "@mui/material";

export type TextAreaProps = {
  maxCharacters?: number;
} & Omit<TextFieldProps, "multiline">;

/**
 *  Textarea is expected to be a controlled input
 *  this means that you should always pass value and onChange props to it
 * */
export const TextArea = ({ maxCharacters, onChange, value, ...otherProps }: TextAreaProps) => {
  const [characterCount, setCharacterCount] = useState(0);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newValue = e.target.value;

      if (!maxCharacters || (maxCharacters && newValue.length <= maxCharacters)) {
        // Only update the character count if the newValue has a valid length
        setCharacterCount(newValue.length);

        if (onChange) {
          onChange(e);
        }
      }
    },
    [maxCharacters, onChange],
  );

  return (
    <TextField
      multiline
      value={value ?? ""}
      onChange={handleChange}
      helperText={maxCharacters ? `${characterCount}/${maxCharacters}` : undefined}
      FormHelperTextProps={{
        sx: { mr: 0, textAlign: "right" },
      }}
      {...otherProps}
    />
  );
};
