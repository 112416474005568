import React from "react";
import { isLoaded, LoadingValue } from "utils/utility-types";
import {
  StorageLocIncomingShipmentsPageView,
  StorageLocIncomingShipmentsViewTabType,
} from "./storage-loc-incoming-shipments-page-view";
import { Loader } from "components/widgets/loader/loader";
import { assertNotUndefined } from "utils/hx/util/types";
import { AppService } from "adl-gen/app-service";
import { LoggedInContext } from "../../../../app/app";
import { useSelectedStorageLocation } from "../../../layouts/portal-page-layout/portal-page";
import { StorageLocNewDealRequestShipmentResp, StorageLocConfirmDeliveryReq } from "adl-gen/ferovinum/app/api";
import { useParams } from "react-router-dom";

export const StorageLocIncomingShipmentsPage = () => {
  const service: AppService = assertNotUndefined(React.useContext(LoggedInContext).loginState?.user?.apis.app);

  const [loadingShipments, setLoadingShipments] = React.useState<LoadingValue<StorageLocNewDealRequestShipmentResp>>({
    state: "loading",
  });

  const selectedLocId = assertNotUndefined(useSelectedStorageLocation());

  const { filter } = useParams<{ filter: StorageLocIncomingShipmentsViewTabType }>();

  const fetchShipments = React.useCallback(() => {
    service
      .getStorageLocNewDealRequestShipments({ storageLocationId: selectedLocId.id })
      .then(shipments => {
        setLoadingShipments({
          state: "success",
          value: shipments,
        });
      })
      .catch(err => {
        setLoadingShipments({
          state: "error",
          error: err,
        });
      });
  }, [selectedLocId, service]);

  const onConfirmDelivery = React.useCallback(
    async (shipment: StorageLocConfirmDeliveryReq) => {
      await service.storageLocConfirmDelivery(shipment);

      fetchShipments();
    },
    [fetchShipments, service],
  );

  React.useEffect(() => {
    fetchShipments();
  }, [fetchShipments]);

  return (
    <Loader loadingStates={[loadingShipments]} fullScreen>
      {isLoaded(loadingShipments) && (
        <StorageLocIncomingShipmentsPageView
          filter={filter}
          incomingShipments={loadingShipments.value.incomingShipments}
          deliveredShipments={loadingShipments.value.deliveredShipments}
          onConfirmDelivery={onConfirmDelivery}
        />
      )}
    </Loader>
  );
};
