import { Loader } from "components/widgets/loader/loader";
import React, { useCallback } from "react";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { assertNotUndefined } from "utils/hx/util/types";
import { isLoaded } from "utils/utility-types";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { OrganisationCompletedDealRenewalsPageView } from "./organisation-completed-deal-renewals-page-view";

export const OrganisationCompletedDealRenewalsPage = () => {
  const service = useAppService();
  const organisationId = assertNotUndefined(useSelectedOrgId());

  const loadCompletedDealRenewals = useCallback(
    async () => await service.getOrganisationCompletedDealExtensionRequests({ organisationId }),
    [organisationId, service],
  );
  const [loadingCompletedDealRenewals] = useLoadingDataState(loadCompletedDealRenewals);

  return (
    <Loader fullScreen loadingStates={[loadingCompletedDealRenewals]}>
      {isLoaded(loadingCompletedDealRenewals) && (
        <OrganisationCompletedDealRenewalsPageView dealRenewals={loadingCompletedDealRenewals.value} />
      )}
    </Loader>
  );
};
