import {
  Box,
  Step,
  StepConnector,
  stepIconClasses,
  StepLabel,
  stepLabelClasses,
  Stepper,
  Typography,
} from "@mui/material";
import React from "react";

export interface FlowStepperProps {
  steps: string[];
  activeStep: number;
}

// TODO(Berto): Revisit the flow stepper so it doesn't have surrounding empty space
export const FlowStepper = ({ steps, activeStep }: FlowStepperProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<StepConnector sx={{ marginX: theme => theme.spacing(4) }} />}>
        {steps.map(step => (
          <Step key={step} completed={false}>
            <StepLabel
              sx={{
                display: "flex",
                [`& .${stepLabelClasses.label}`]: {
                  marginTop: 1,
                  [`&:not(.${stepLabelClasses.active})`]: {
                    color: theme => theme.palette.common.grey,
                  },
                },
              }}
              StepIconProps={{
                sx: {
                  width: 30,
                  height: "auto",
                  fontSize: theme => theme.typography.subtitle1Bold.fontSize,
                  fontWeight: theme => theme.typography.subtitle1Bold.fontWeight,
                  color: theme => theme.palette.common.grey3,
                  [`& .${stepIconClasses.text}`]: {
                    fill: theme => theme.palette.common.grey,
                  },
                  [`&.${stepLabelClasses.active}`]: {
                    [`& .${stepIconClasses.text}`]: {
                      fill: theme => theme.palette.common.white,
                    },
                  },
                },
              }}>
              <Typography variant="body2">{step}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
