import { Stack } from "@mui/material";
import { DbKey } from "adl-gen/common/db";
import { StorageLocationView } from "adl-gen/ferovinum/app/api";
import { Organisation, Product, UnitType } from "adl-gen/ferovinum/app/db";
import { StockMovementCardGrid } from "components/library/widgets/stock-movement-card-grid";
import { TabbedPage } from "components/library/widgets/tabbed-page";
import { GridTable, GridTableColumnFactory } from "components/widgets/grid-table/grid-table";
import React, { useMemo } from "react";
import { StockMovementDeliveryDetails, StockMovementProgress } from "utils/hooks/use-stock-movement-summary";
import { formatNumberOfUnits2 } from "utils/model-utils";
import { DeliveryUpdatesCard } from "../../../../apps/client/src/ui/widgets/card/delivery-updates-card/delivery-updates-card";
import { StatusStepper } from "../../../../apps/client/src/ui/widgets/status-stepper/status-stepper";

interface SummaryProductData {
  productId: DbKey<Product>;
  product: Product;
  quantity: number;
  unitType: UnitType;
}
const SUMMARY_TABLE_COLUMNS = (() => {
  const factory = new GridTableColumnFactory<SummaryProductData>();
  return [
    factory.makeProductSummaryColumn({
      header: "Product",
      name: "productSummary",
      getter: row => ({ ...row.product }),
    }),
    factory.makeNumberColumn({
      key: "quantity",
      header: "Quantity to Relocate",
      displayFormatter: row => formatNumberOfUnits2(row.quantity, row.product.unitType),
    }),
  ];
})();
interface StockMovementSummaryProps {
  organisation: Organisation;
  startingLocation: StorageLocationView;
  destinationLocation: StorageLocationView;
  earliestCollectionDate: string;
  deliveryDetails: StockMovementDeliveryDetails;
  productData: SummaryProductData[];
  progress: StockMovementProgress;
}

export const StockMovementSummary = ({
  organisation,
  progress,
  startingLocation,
  destinationLocation,
  ...rest
}: StockMovementSummaryProps) => {
  // If the SM has been created, show the progress tab
  if (progress.activeState) {
    return (
      <TabbedPage
        tabs={[
          {
            key: "progress",
            label: "Progress",
            content: (
              <ProgressTab
                orgName={organisation.businessName}
                sourceLocationName={startingLocation.storageLocationName}
                destLocationName={destinationLocation.storageLocationName}
                progress={progress}
              />
            ),
          },
          {
            key: "summary",
            label: "Summary",
            content: (
              <SummaryTab startingLocation={startingLocation} destinationLocation={destinationLocation} {...rest} />
            ),
          },
        ]}
      />
    );
  }
  return <SummaryTab startingLocation={startingLocation} destinationLocation={destinationLocation} {...rest} />;
};

const SummaryTab = ({
  startingLocation,
  destinationLocation,
  earliestCollectionDate,
  deliveryDetails,
  productData,
}: Omit<StockMovementSummaryProps, "progress" | "organisation">) => {
  return (
    <Stack spacing={7}>
      <StockMovementCardGrid
        startingLocation={startingLocation}
        destinationLocation={destinationLocation}
        earliestCollectionDate={earliestCollectionDate}
        deliveryDetails={deliveryDetails}
      />
      <Stack>
        <GridTable
          columns={SUMMARY_TABLE_COLUMNS}
          inputRowsField={productData}
          // Collapse table if there are less than 6 rows to save space
          tableHeight={productData.length < 6 ? "auto" : 600}
        />
      </Stack>
    </Stack>
  );
};

const ProgressTab = ({
  orgName,
  sourceLocationName,
  destLocationName,
  progress,
}: {
  orgName: string;
  sourceLocationName: string;
  destLocationName: string;
  progress: StockMovementProgress;
}) => {
  const displayStates = useMemo(() => {
    const labelMap: { [key: string]: string } = {
      "Approved": "Request approved by Ferovinum",
      "Rejected": "Request rejected by Ferovinum",
      "Requested": `Stock movement requested by ${orgName}`,
      "Collected": `Stock collected from ${sourceLocationName}`,
      "Delivered": `Stock delivered to ${destLocationName}`,
      "Delivery Confirmed": `Landing complete at ${destLocationName}`,
      "Cancelled": `Request cancelled by ${orgName}`,
    };

    return progress.states.map(state => ({
      label: labelMap[state.label] || state.label,
      timestamp: state.timestamp,
    }));
  }, [progress.states, orgName, destLocationName, sourceLocationName]);

  return (
    <Stack spacing={7}>
      <DeliveryUpdatesCard deliveryStatuses={progress.deliveryStatuses} />
      <StatusStepper activeStep={progress.nextStateIndex} steps={displayStates} sx={{ flex: 1 }} />
    </Stack>
  );
};
