import { Stack } from "@mui/material";
import { WithDbId } from "adl-gen/common/db";
import { ProductListing, StorageLocationView, makeProductDealLegSearchQueryReq } from "adl-gen/ferovinum/app/api";
import { Product, StorageLocation } from "adl-gen/ferovinum/app/db";
import {
  WizardContextLoader,
  WizardStepComponent,
  WizardStepState,
  useWizardStepSetup,
} from "components/library/components/wizard/wizard";
import { SelectorInfo } from "components/library/widgets/db-key-selector";
import { DestinationWarehouseCard } from "components/library/widgets/wizard-card/destination-warehouse-card";
import { ProductLookupTableCard } from "components/library/widgets/wizard-card/product-lookup-table-card";
import { StartingStorageLocationCard } from "components/library/widgets/wizard-card/starting-storage-location-card";
import { FormField } from "components/types/formik-types";
import { GridTableColumnFactory } from "components/widgets/grid-table/grid-table";
import { default as React, useCallback, useMemo } from "react";
import { arrayField, objectField } from "utils/data-field/data-field-builder";
import { ObjectFields, makeObjectDef } from "utils/data-field/object-field-def";
import { assertNotUndefined } from "utils/hx/util/types";
import { canSellPartialQuantity, formatNumberOfUnits2, unitsLabelForUnitType } from "utils/model-utils";
import { useAppService } from "../../../../hooks/use-app-service";
import { useSelectedOrgId } from "../../../layouts/portal-page-layout/portal-page";
import {
  PRODUCT_ROW_DEF,
  ProductRow,
  STOCK_MOVEMENT_PRODUCT_SELECTION_DATA_DEF,
  StockMovementProductSelectionContext,
  StockMovementProductSelectionData,
  StockMovementWizardContext,
  StockMovementWizardData,
} from "./stock-movement-wizard-types";

const ENRICHED_PRODUCT_ROW_DEF = makeObjectDef<ProductRow>({
  productId: PRODUCT_ROW_DEF.productId,
  quantity: PRODUCT_ROW_DEF.quantity.withUpdatedSchema(schema =>
    schema.test(
      "max-quantity",
      "Quantity to relocate cannot be more than available quantity",
      (quantity, ctx) => quantity <= ctx.parent.maxQuantity,
    ),
  ),
  maxQuantity: PRODUCT_ROW_DEF.maxQuantity,
});

const ENRICHED_DATA_DEF: Readonly<ObjectFields<StockMovementProductSelectionData>> = Object.freeze({
  startLocation: STOCK_MOVEMENT_PRODUCT_SELECTION_DATA_DEF.startLocation,
  destinationLocation: STOCK_MOVEMENT_PRODUCT_SELECTION_DATA_DEF.destinationLocation.withUpdatedSchema(schema =>
    schema.test(
      "not-equal-from-loc",
      "Cannot be the same as Starting Location",
      (toLoc, ctx) => ctx.parent.startLocation !== toLoc,
    ),
  ),
  products: arrayField<ProductRow>("Products", objectField("Product", ENRICHED_PRODUCT_ROW_DEF).required())
    .updateSchemaWithNonTrivialValidation(s =>
      s.test("not-empty", "Needs at least 1 product entry.", products => products && products.length > 0),
    )
    .required(),
});

type ProductSelectionStepState = WizardStepState<
  StockMovementWizardData,
  StockMovementWizardContext,
  "productSelection"
>;

export const useStockMovementProductSelectionContext: WizardContextLoader<
  StockMovementWizardData,
  StockMovementWizardContext,
  "productSelection"
> = async ({ stepData: initData, stepContext: initContext }): Promise<ProductSelectionStepState> => {
  const appService = useAppService();
  const orgId = assertNotUndefined(useSelectedOrgId());

  const productIds = initData?.products?.map(p => p.productId) ?? [];
  if (initContext && productIds.every(id => initContext.productInfos[id])) {
    return { stepData: initData ?? {}, stepContext: initContext };
  }
  // otherwise load stepContext
  const hasStockLocations = await appService.getStorageLocations({ orgId, selector: { kind: "hasStock" } });
  const associatedAndPublicLocations = await appService.getStorageLocations({
    orgId,
    selector: { kind: "associatedAndPublic" },
  });

  const startLocation = initData?.startLocation;
  const listings =
    startLocation && productIds.length > 0
      ? await appService.getOrgProductListingsAtLocation({
          organisationId: orgId,
          storageLocationId: startLocation,
          productIds,
        })
      : [];
  const stepContext: StockMovementProductSelectionContext = {
    hasStockLocations,
    associatedAndPublicLocations,
    productInfos: listings.reduce((acc, listing) => {
      return { ...acc, [listing.productWithId.id]: extractProductInfo(listing) };
    }, {}),
  };
  return { stepData: initData ?? {}, stepContext };
};

export const StockMovementProductSelectionStep: WizardStepComponent<
  StockMovementWizardData,
  StockMovementWizardContext,
  "productSelection"
> = ({ stepData, stepContext, registerDataGetter }) => {
  const appService = useAppService();
  const organisationId = assertNotUndefined(useSelectedOrgId());
  const [isLoading, setIsLoading] = React.useState(false);

  const productInfos = useMemo(() => stepContext.productInfos, [stepContext]);

  const initData: StockMovementProductSelectionData = useMemo(() => {
    const startLocation = stepData?.startLocation ?? "";
    const destinationLocation = stepData?.destinationLocation ?? "";
    const cachedProducts = stepData?.products ?? [];
    const products = startLocation ? cachedProducts : [];
    return { startLocation, destinationLocation, products };
  }, [stepData]);

  const enrichedDataFieldDef = objectField("", ENRICHED_DATA_DEF).required();

  const { form } = useWizardStepSetup<StockMovementProductSelectionData, StockMovementProductSelectionContext>(
    initData,
    enrichedDataFieldDef,
    registerDataGetter,
  );

  const prductTableColumns = (() => {
    const factory = new GridTableColumnFactory<ProductRow>();
    return [
      factory.makeRemovalColumn({
        match: (r1, r2) => r1.productId === r2.productId,
      }),
      factory.makeProductSummaryColumn({
        header: "Product",
        name: "productSummary",
        getter: row => productInfos[row.productId].productSummary,
      }),
      factory.makeTextColumn({
        header: "Available",
        name: "available",
        getter: row => formatNumberOfUnits2(row.maxQuantity, productInfos[row.productId].unitType),
      }),
      factory.makeEditableNumberColumn({
        key: "quantity",
        header: "Quantity to Relocate",
        displayFormatter: row => formatNumberOfUnits2(row.quantity, productInfos[row.productId].unitType),
        editableCheck: row => canSellPartialQuantity(productInfos[row.productId].unitType),
        endAdornmentTextProvider: row => unitsLabelForUnitType(productInfos[row.productId].unitType),
      }),
    ];
  })();

  const onAddProduct = useCallback(
    async (product: WithDbId<Product>) => {
      setIsLoading(true);
      const [listing] = await appService.getOrgProductListingsAtLocation({
        organisationId,
        storageLocationId: form.values.startLocation,
        productIds: [product.id],
      });
      productInfos[product.id] = extractProductInfo(listing);
      const maxQuantity = listing.dealLegs.reduce((acc, leg) => acc + leg.totalQuantityAvailable.value, 0);
      const newRow = {
        productId: product.id,
        quantity: maxQuantity,
        maxQuantity,
      };
      form.setFieldValue("products", [...form.values.products, newRow]);
      setIsLoading(false);
    },
    [form, appService, organisationId, productInfos],
  );

  const searchProducts = useCallback(
    // todo search by location
    async (searchTerm: string) => {
      if (!form.values.startLocation) return [];
      return await appService.productDealLegSearch(
        makeProductDealLegSearchQueryReq({
          organisationId,
          searchTerm,
          storageLocationId: form.values.startLocation,
          availabilityOption: "availableAndPending",
        }),
      );
    },
    [appService, form.values.startLocation, organisationId],
  );

  const productsField = useMemo(() => new FormField<ProductRow[]>(form, "products"), [form]);

  return (
    <Stack spacing={7} justifyContent="space-between">
      <StartingStorageLocationCard
        fieldInfo={{ form, fieldKey: "startLocation" }}
        fieldDef={STOCK_MOVEMENT_PRODUCT_SELECTION_DATA_DEF.startLocation}
        options={mapToStorageLocationInfo(stepContext.hasStockLocations)}
        onValueChange={() => {
          form.setFieldValue("products", []);
        }}
      />
      <ProductLookupTableCard
        title={"Stock Selection"}
        subtitle={"Please select the stock you would like to move to a new location."}
        productTableColumns={prductTableColumns}
        inputRowsField={productsField}
        searchProducts={searchProducts}
        onAddProduct={onAddProduct}
        uniqueProductCodes={new Set(form.values.products.map(row => productInfos[row.productId].productSummary.code))}
        isLoading={isLoading}
      />
      <DestinationWarehouseCard
        fieldInfo={{ form, fieldKey: "destinationLocation" }}
        fieldDef={STOCK_MOVEMENT_PRODUCT_SELECTION_DATA_DEF.startLocation}
        options={mapToStorageLocationInfo(stepContext.associatedAndPublicLocations)}
      />
    </Stack>
  );
};

const mapToStorageLocationInfo = (locs: StorageLocationView[]): SelectorInfo<StorageLocation>[] =>
  locs.map(loc => ({ id: loc.id, displayValue: loc.storageLocationName, disabled: loc.country !== "GB" }));

function extractProductInfo(listing: ProductListing) {
  return {
    productSummary: listing.productWithId.value,
    unitType: listing.productWithId.value.unitType,
  };
}
