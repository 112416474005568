import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { MinimalDealExtensionRequestView } from "adl-gen/ferovinum/app/api";
import { DealExtensionRequestStateEvent } from "adl-gen/ferovinum/app/db";
import { Link } from "components/widgets/link/link";
import React from "react";
import { formatInstant } from "utils/date-utils";
import { AppRoutes } from "../../../../../app/app-routes";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";

export interface OrganisationCompletedDealRenewalsPageViewProps {
  dealRenewals: MinimalDealExtensionRequestView[];
}
export const OrganisationCompletedDealRenewalsPageView = ({
  dealRenewals,
}: OrganisationCompletedDealRenewalsPageViewProps) => {
  const hasCompletedDealRenewals = dealRenewals.length > 0;

  return (
    <PortalPageContent header={<Header />}>
      <Stack spacing={5}>
        {hasCompletedDealRenewals ? (
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Request No.</TableCell>
                  <TableCell>Outcome</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {dealRenewals.map(deal => (
                  <TableRow key={deal.dealId}>
                    <TableCell>
                      <Link href={`${AppRoutes.OrganisationDealExtensionRequestDetails}/${deal.dealId}`}>
                        {deal.dealNumber}
                      </Link>
                    </TableCell>
                    <TableCell>{getOutcomeString(deal.currentStateEvent)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>You have no completed deal renewals.</Typography>
        )}
      </Stack>
    </PortalPageContent>
  );
};

const Header = () => (
  <PortalPageContentHeader
    variant={"split"}
    title={"Completed Deal Renewals"}
    right={
      <Link color="grey7" href={AppRoutes.OrganisationExpiringStock}>
        Stock Expiring Within 30 Days
      </Link>
    }
  />
);

function getOutcomeString(stateEvent: DealExtensionRequestStateEvent) {
  switch (stateEvent.state) {
    case "paymentReceived":
      return `Deal renewal successfully completed on ${formatInstant(stateEvent.time)}.`;
    case "orgRejected":
      return `You rejected the new deal contract note on ${formatInstant(stateEvent.time)}.`;
    default:
      return "-";
  }
}
