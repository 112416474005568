import React, { useCallback } from "react";
import { AlertContext, OpenAlertProps, DEFAULT_ALERT_MANAGER_VALUES } from "./use-alert-context";
import { AlertColor } from "@mui/material";

export interface AlertManagerProps {
  isShown: boolean;
  title: string;
  body: React.ReactNode;
  severity?: AlertColor;
  showDismissButton?: boolean;
  noClose?: boolean;
}

export const AlertManager = ({ children }: { children: React.ReactNode }) => {
  const [alert, setAlert] = React.useState<AlertManagerProps>(DEFAULT_ALERT_MANAGER_VALUES);

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
  }>();

  const openAlert = useCallback(
    async (props: OpenAlertProps) => {
      setAlert({ ...props, isShown: true });
      return new Promise<void>(resolve => {
        awaitingPromiseRef.current = { resolve };
      });
    },
    [setAlert],
  );

  const closeAlert = useCallback(async () => {
    setAlert({ ...DEFAULT_ALERT_MANAGER_VALUES });
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
  }, [setAlert]);

  return <AlertContext.Provider value={{ ...alert, openAlert, closeAlert }}>{children}</AlertContext.Provider>;
};
