import React from "react";
import { Redirect } from "react-router-dom";
import { AppRoutes } from "./app-routes";
import { useSelectedEntity } from "../ui/layouts/portal-page-layout/portal-page";

/**
 * Component that redirects user to either organisation or storage location "index" pages depending on selected entity id.
 * See page-layout.tsx for more details
 */
export const IndexRedirect = () => {
  const selectedEntity = useSelectedEntity();

  if (selectedEntity.type === "org") {
    return <Redirect to={AppRoutes.Dashboard} />;
  } else if (selectedEntity.type === "storageLoc") {
    return <Redirect to={AppRoutes.LocProductionOrders} />;
  } else if (selectedEntity.type === "supplier") {
    return <Redirect to={AppRoutes.SupplierNewDealRequests} />;
  } else if (selectedEntity.type === "carrier") {
    return <Redirect to={AppRoutes.CarrierRequests} />;
  } else if (selectedEntity.type === "purchaser") {
    return <Redirect to={AppRoutes.PurchaserPurchaseRequests} />;
  } else {
    return null;
  }
};
