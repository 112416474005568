import React, { useCallback, useMemo } from "react";
import { Loader } from "components/widgets/loader/loader";
import { OrganisationProductionOrderSetupPageView } from "./organisation-production-order-setup-page-view";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../../../app/app-routes";
import { DbKey, WithDbId } from "adl-gen/common/db";
import { FinishingService, NumberOfUnits, Product, StorageLocation } from "adl-gen/ferovinum/app/db";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { useAppService } from "../../../../../hooks/use-app-service";
import { assertNotUndefined } from "utils/hx/util/types";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { isLoaded } from "utils/utility-types";
import { ProductDetails } from "../../../../../models/product";
import { useIsProducer } from "../../../../../hooks/use-is-producer";

export interface SetupValues {
  quantity: NumberOfUnits;
  finishingService: WithDbId<FinishingService>;
  finishingProduct: WithDbId<Product>;
  requestedCompletionDate?: string;
  notes?: string;
  pricePerUnit: string;
  setupCost: string;
}
export interface ProductionOrderFlowState {
  sourceProduct: ProductDetails & { id: DbKey<Product> };
  quantityAvailable: NumberOfUnits;
  storageLocationId: DbKey<StorageLocation>;
  setup?: SetupValues;
}
export const OrganisationProductionOrderSetupPage = () => {
  const location = useLocation();
  const history = useHistory();
  const appService = useAppService();
  const productionOrderFlowState = location.state as ProductionOrderFlowState | undefined;
  const selectedOrgId = assertNotUndefined(useSelectedOrgId());
  const isProducer = useIsProducer({
    storageLocationId: productionOrderFlowState?.storageLocationId,
    organisationId: selectedOrgId,
  });

  const initialSetupValues: SetupValues | undefined = useMemo(() => {
    const state = productionOrderFlowState;
    if (state && state.setup) {
      return {
        quantity: state.setup.quantity,
        notes: state.setup.notes,
        finishingProduct: state.setup.finishingProduct,
        requestedCompletionDate: state.setup.requestedCompletionDate,
        finishingService: state.setup.finishingService,
        pricePerUnit: state.setup.pricePerUnit,
        setupCost: state.setup.setupCost,
      };
    }
  }, [productionOrderFlowState]);

  const getProductFinishingServices = useCallback(async () => {
    const productId = assertNotUndefined(productionOrderFlowState?.sourceProduct.id);
    const storageLocationId = assertNotUndefined(productionOrderFlowState?.storageLocationId);
    const finishingServices = await appService.getProductFinishingServices({
      organisationId: selectedOrgId,
      productId,
      storageLocationId,
    });
    return new Map(finishingServices.map(e => [e.key, e.value]));
  }, [
    appService,
    productionOrderFlowState?.sourceProduct.id,
    productionOrderFlowState?.storageLocationId,
    selectedOrgId,
  ]);
  const [loadingProductsFinishingServices] = useLoadingDataState(getProductFinishingServices);

  const onNext = useCallback(
    (values: SetupValues) => {
      const state: ProductionOrderFlowState = {
        ...assertNotUndefined(productionOrderFlowState),
        setup: {
          ...values,
        },
      };
      history.push(AppRoutes.OrgProductionOrderConfirmation, state);
    },
    [history, productionOrderFlowState],
  );

  if (productionOrderFlowState === undefined) {
    return <Redirect to={AppRoutes.OrgProductionOrderProductSelection} />;
  } else {
    return (
      <Loader loadingStates={[loadingProductsFinishingServices, isProducer]} fullScreen>
        {isLoaded(loadingProductsFinishingServices) && isLoaded(isProducer) && (
          <OrganisationProductionOrderSetupPageView
            sourceProduct={productionOrderFlowState.sourceProduct}
            quantityAvailable={productionOrderFlowState.quantityAvailable}
            finishingServices={loadingProductsFinishingServices.value}
            onNext={onNext}
            initialSetupValues={initialSetupValues}
            isProducer={isProducer.value}
          />
        )}
      </Loader>
    );
  }
};
