/* @generated from adl module ferovinum.app.annotations */

import * as ADL from './../../runtime/adl';

export interface DbAudit {
  auditRows: boolean;
  auditQueryText: boolean;
}

export function makeDbAudit(
  input: {
    auditRows: boolean,
    auditQueryText: boolean,
  }
): DbAudit {
  return {
    auditRows: input.auditRows,
    auditQueryText: input.auditQueryText,
  };
}

const DbAudit_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.annotations","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"auditRows","default":{"kind":"nothing"},"name":"auditRows","typeExpr":{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}},{"annotations":[],"serializedName":"auditQueryText","default":{"kind":"nothing"},"name":"auditQueryText","typeExpr":{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}}]}},"name":"DbAudit","version":{"kind":"nothing"}}};

export const snDbAudit: ADL.ScopedName = {moduleName:"ferovinum.app.annotations", name:"DbAudit"};

export function texprDbAudit(): ADL.ATypeExpr<DbAudit> {
  return {value : {typeRef : {kind: "reference", value : snDbAudit}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "ferovinum.app.annotations.DbAudit" : DbAudit_AST
};
