import React from "react";
import { FlowStepper } from "components/library/widgets/flow-stepper";

export interface ProductionOrderFlowStepperProps {
  activeStep: number;
}

export const ProductionOrderFlowStepper = ({ activeStep }: ProductionOrderFlowStepperProps) => {
  const steps = ["Select product", "Order details", "Confirmation"];

  return <FlowStepper steps={steps} activeStep={activeStep} />;
};
