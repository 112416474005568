import React, { useCallback } from "react";
import { ButtonProps, DialogActionsProps, DialogContentProps, DialogProps, DialogTitleProps } from "@mui/material";
import { noop } from "utils/ts-utils";

export type DialogResp<T> =
  | {
      kind: "ok";
      value?: T;
    }
  | { kind: "cancel" };

interface DialogButtonAction {
  title?: string;
  color?: ButtonProps["color"];
  onClick: () => Promise<void> | void;
  disabled?: boolean;
}

export interface GenericDialogButtonAction<T = void> extends Omit<DialogButtonAction, "onClick"> {
  onClick: () => Promise<T> | T;
}

export interface DialogManagerProps {
  isShown: boolean;
  handleClose: () => void;
  title?: React.ReactNode;
  body?: React.ReactNode;
  confirmAction?: DialogButtonAction;
  cancelAction?: DialogButtonAction;
  showCloseIcon?: boolean;
  customProps?: {
    dialog?: Partial<DialogProps>;
    title?: Partial<DialogTitleProps>;
    content?: Partial<DialogContentProps>;
    actions?: Partial<DialogActionsProps>;
  };
}

const DEFAULT_DIALOG_MANAGER_VALUES: DialogManagerProps = {
  isShown: false,
  handleClose: noop,
};

export interface OpenDialogProps<T> {
  title?: React.ReactNode;
  body?: React.ReactNode;
  confirmAction?: GenericDialogButtonAction<T>;
  cancelAction?: GenericDialogButtonAction;
  showCloseIcon?: boolean;
  customProps?: {
    dialog?: Partial<DialogProps>;
    title?: Partial<DialogTitleProps>;
    content?: Partial<DialogContentProps>;
    actions?: Partial<DialogActionsProps>;
  };
}
export interface DialogContextProps extends DialogManagerProps {
  showDialog: <T>(props: OpenDialogProps<T>) => Promise<DialogResp<T>>;
}
export const DialogContext = React.createContext<DialogContextProps>({
  ...DEFAULT_DIALOG_MANAGER_VALUES,
  showDialog: () => Promise.resolve({ kind: "ok" }),
});

export const DialogManager = ({ children }: { children: React.ReactNode }) => {
  const [dialog, setDialog] = React.useState<DialogManagerProps>(DEFAULT_DIALOG_MANAGER_VALUES);

  const awaitingPromiseRef = React.useRef<{
    resolve: <T>(value: DialogResp<T> | PromiseLike<DialogResp<T>>) => void;
  }>();

  const handleClose = useCallback(() => {
    setDialog({ ...DEFAULT_DIALOG_MANAGER_VALUES });
  }, []);

  const showDialog = useCallback(
    async <T,>(props: OpenDialogProps<T>) => {
      const { title, body, confirmAction, cancelAction, customProps, showCloseIcon } = props;

      const dialogPromise = new Promise<DialogResp<T>>(resolve => {
        // @ts-ignore - Note I couldn't figure out typescript typing acrobatics here
        awaitingPromiseRef.current = { resolve };
      });

      const onAccept = async () => {
        const resp: DialogResp<T> = {
          kind: "ok",
          value: confirmAction ? await confirmAction.onClick() : undefined,
        };
        if (awaitingPromiseRef.current) {
          awaitingPromiseRef.current.resolve(resp);
          awaitingPromiseRef.current = undefined;
        }
        handleClose();
      };

      const onClose = async () => {
        if (cancelAction) {
          await cancelAction.onClick();
        }
        const resp: DialogResp<T> = {
          kind: "cancel",
        };
        if (awaitingPromiseRef.current) {
          awaitingPromiseRef.current.resolve(resp);
          awaitingPromiseRef.current = undefined;
        }
        handleClose();
      };

      const handleDialogClose = async () => {
        if (awaitingPromiseRef.current) {
          awaitingPromiseRef.current.resolve({ kind: "ok" });
        }
        handleClose();
      };

      setDialog({
        title,
        body,
        confirmAction: confirmAction ? { ...confirmAction, onClick: onAccept } : undefined,
        cancelAction: cancelAction ? { ...cancelAction, onClick: onClose } : undefined,
        showCloseIcon: showCloseIcon,
        handleClose: handleDialogClose,
        isShown: true,
        customProps,
      });

      return dialogPromise;
    },
    [handleClose],
  );

  return <DialogContext.Provider value={{ ...dialog, showDialog }}>{children}</DialogContext.Provider>;
};
