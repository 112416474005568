import { Divider, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { formatDate } from "utils/date-utils";
import { LocalDate, LocalTime } from "adl-gen/common";
import { Card, CardText, stackStrings } from "components/library/widgets/card/card";
export interface DeliveryDetailsCardProps {
  deliveryPointName: string;
  streetName: string;
  town: string;
  postCode: string;
  deliveryContact: string | null;
  deliveryContactEmail: string | null;
  deliveryContactNumber: string | null;
  deliveryInstructions: string | null;
  bonded: boolean;
  deliveryDate: LocalDate | null;
  deliveryTimeEarliest: LocalTime | null;
  deliveryTimeLatest: LocalTime | null;
}

export const DeliveryDetailsCard = ({
  deliveryPointName,
  streetName,
  town,
  postCode,
  deliveryContact,
  deliveryContactEmail,
  deliveryContactNumber,
  deliveryInstructions,
  bonded,
  deliveryDate,
  deliveryTimeEarliest,
  deliveryTimeLatest,
}: DeliveryDetailsCardProps) => {
  const contact = useMemo(
    () => stackStrings([deliveryContact, deliveryContactEmail, deliveryContactNumber]),
    [deliveryContact, deliveryContactEmail, deliveryContactNumber],
  );

  const address = useMemo(() => stackStrings([streetName, town, postCode]), [streetName, town, postCode]);
  const time = useMemo(() => {
    if (deliveryDate && deliveryTimeEarliest && deliveryTimeLatest) {
      return stackStrings([formatDate(new Date(deliveryDate)), `${deliveryTimeEarliest} - ${deliveryTimeLatest}`]);
    }
    return null;
  }, [deliveryDate, deliveryTimeEarliest, deliveryTimeLatest]);

  return (
    <Card
      header={{
        title: "Delivery Details",
      }}
      body={
        <>
          <Typography>{deliveryPointName}</Typography>
          <CardText title="Contact" content={contact} lineNumbers={3} />
          <CardText title="Address" content={address} lineNumbers={3} />
          <Divider />
          <CardText title="Delivery Type" content={bonded === true ? "In-Bond" : "Out-of-Bond"} />
          <CardText title="Delivery Time" content={time} lineNumbers={2} />
          <CardText title="Delivery instructions" content={deliveryInstructions} lineNumbers={3} />
        </>
      }
    />
  );
};
