import React, { useCallback, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useAppService } from "../../../../../hooks/use-app-service";
import { assertNotUndefined } from "utils/hx/util/types";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { ProductionOrderFlowState } from "../organisation-production-order-setup/organisation-production-order-setup-page";
import { AppRoutes } from "../../../../../app/app-routes";
import {
  ConfirmedProductionOrderDetails,
  OrganisationProductionOrderConfirmationPageView,
} from "./organisation-production-order-confirmation-page-view";
import { formatDateToISO8601 } from "utils/date-utils";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { Loader } from "components/widgets/loader/loader";
import { isLoaded } from "utils/utility-types";

export const OrganisationProductionOrderConfirmationPage = () => {
  const location = useLocation();
  const history = useHistory();
  const appService = useAppService();
  const [showAlert] = useAlert();
  const productionOrderFlowState = location.state as ProductionOrderFlowState | undefined;
  const selectedOrgId = assertNotUndefined(useSelectedOrgId());
  const [confirmedProductionOrderDetails, setConfirmedProductionOrderDetails] =
    useState<ConfirmedProductionOrderDetails>();

  const navigateBackToProductionOrderSetup = useCallback(() => {
    const state = assertNotUndefined(productionOrderFlowState);
    history.push(AppRoutes.OrgProductionOrderSetup, state);
  }, [history, productionOrderFlowState]);

  const onConfirm = useCallback(async () => {
    const state = assertNotUndefined(productionOrderFlowState);
    const setup = assertNotUndefined(productionOrderFlowState?.setup);
    try {
      const resp = await appService.createProductionOrder({
        comments: setup.notes ?? null,
        finishingProductId: setup.finishingProduct.id,
        finishingServiceId: setup.finishingService.id,
        organisationId: selectedOrgId,
        quantity: setup.quantity,
        requestedCompletionDate: setup.requestedCompletionDate
          ? formatDateToISO8601(new Date(setup.requestedCompletionDate))
          : null,
        sourceProductId: state.sourceProduct.id,
        pricePerUnit: setup.pricePerUnit.toString(),
        setupCost: setup.setupCost.toString(),
      });
      switch (resp.kind) {
        case "insufficientQuantityAvailable":
          return await showAlert({
            title: "Error",
            body: "Production order could not be confirmed due to insufficient quantity available",
          });
        case "success":
          const productionOrderId = resp.value;

          const productionOrder = await appService.getProductionOrder({ productionOrderId });
          setConfirmedProductionOrderDetails({
            storageLocationName: productionOrder.storageLocation.value.locationName,
            orderDate: productionOrder.createdAt,
            requestedCompletionDate: productionOrder.requestedCompletionDate ?? undefined,
          });
      }
    } catch (e) {
      await showAlert({ title: "Error", body: "Production order could not be confirmed. Please try again later." });
    }
  }, [appService, productionOrderFlowState, selectedOrgId, showAlert]);

  const loadProductionOrderTotals = useCallback(async () => {
    if (productionOrderFlowState?.setup) {
      return await appService.previewProductionOrderTotals({ ...productionOrderFlowState.setup });
    }
  }, [appService, productionOrderFlowState]);

  const [loadingProductionOrderTotals] = useLoadingDataState(loadProductionOrderTotals);

  if (productionOrderFlowState?.setup === undefined) {
    return <Redirect to={AppRoutes.OrgProductionOrderProductSelection} />;
  } else {
    return (
      <Loader loadingStates={[loadingProductionOrderTotals]}>
        {isLoaded(loadingProductionOrderTotals) && (
          <OrganisationProductionOrderConfirmationPageView
            flowState={productionOrderFlowState}
            totals={assertNotUndefined(loadingProductionOrderTotals.value)}
            navigateBackToProductionOrderSetup={navigateBackToProductionOrderSetup}
            onConfirm={onConfirm}
            confirmedProductionOrderDetails={confirmedProductionOrderDetails}
          />
        )}
      </Loader>
    );
  }
};
