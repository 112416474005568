import React from "react";
import { isLoaded } from "utils/utility-types";
import { Loader } from "components/widgets/loader/loader";
import { StorageLocAdjustmentDetailsPageView } from "./storage-loc-adjustment-details-page-view";
import { useStorageLocationStockAdjustmentDetails } from "../../../../../hooks/use-stock-adjustment";
import { useParams } from "react-router-dom";
import { StockAdjustment } from "adl-gen/ferovinum/app/api";

export const StorageLocStockAdjustmentDetailsPage = () => {
  const { stockAdjustmentId, variant } = useParams<{ stockAdjustmentId: string; variant: StockAdjustment["kind"] }>();
  const { loadingDetails } = useStorageLocationStockAdjustmentDetails(stockAdjustmentId, variant);

  return (
    <Loader loadingStates={[loadingDetails]} fullScreen>
      {isLoaded(loadingDetails) && <StorageLocAdjustmentDetailsPageView details={loadingDetails.value} />}
    </Loader>
  );
};
