import React, { useCallback } from "react";
import { assertNotUndefined } from "utils/hx/util/types";
import { AppService } from "adl-gen/app-service";
import { isLoaded } from "utils/utility-types";
import { OrganisationProductionOrdersPageView } from "./organisation-production-orders-page-view";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { Loader } from "components/widgets/loader/loader";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useLoadingDataState } from "utils/hooks/use-loading-data";

export const OrganisationProductionOrdersPage = () => {
  const service: AppService = useAppService();
  const selectedOrgId = assertNotUndefined(useSelectedOrgId());

  const loadProductionOrders = useCallback(async () => {
    return await service.getOrganisationProductionOrders({ organisationId: selectedOrgId });
  }, [selectedOrgId, service]);

  const [loadingProductionOrders] = useLoadingDataState(loadProductionOrders);

  return (
    <Loader loadingStates={[loadingProductionOrders]} fullScreen>
      {isLoaded(loadingProductionOrders) && (
        <OrganisationProductionOrdersPageView productionOrderViews={loadingProductionOrders.value} />
      )}
    </Loader>
  );
};
