import React, { useContext, useState, useCallback, useRef, useMemo, useEffect } from "react";
import { assertNotUndefined } from "utils/hx/util/types";
import { StorageLocation, StorageLocationId, TopLevelUnitType } from "adl-gen/ferovinum/app/db";
import { isLoaded, LoadingValue } from "utils/utility-types";
import { AppService } from "adl-gen/app-service";
import { LoggedInContext } from "../../../../../app/app";
import { useHistory, useLocation } from "react-router-dom";
import { OrganisationRepurchaseStockPageView } from "./organisation-repurchase-stock-page-view";
import { WithDbId } from "adl-gen/common/db";
import { ProductListing, DealLegRepurchaseAmount } from "adl-gen/ferovinum/app/api";
import { ProductSaleOrder } from "adl-gen/ferovinum/app/productsaleorder";
import { LoginState } from "../../../../../app/identity-state";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { Loader } from "components/widgets/loader/loader";
import { AppRoutes } from "../../../../../app/app-routes";
import { RepurchaseFlowState } from "../organisation-repurchase-delivery/organisation-repurchase-delivery-page";
import { useAppService } from "../../../../../hooks/use-app-service";
import { DbKey } from "adl-gen/common/db";
import { getTopLevelForUnitType } from "utils/adl-utils";
import { isAdmin } from "utils/user-utils";

export interface RepurchaseRequest {
  type: TopLevelUnitType;
  storageLocationId: StorageLocationId;
  productsSaleOrders: ProductSaleOrder[];
}

function storageLocationCompareFn(sl1: StorageLocation, sl2: StorageLocation): number {
  return sl1.locationName.localeCompare(sl2.locationName);
}

export type DealLegRepurchaseStage = DealLegRepurchaseAmount;
``;
function hasAvailableBalance(productListing: ProductListing) {
  return productListing.dealLegs.some(
    dealLeg => dealLeg.availabilityStatus === "available" && dealLeg.totalQuantityAvailable.value > 0,
  );
}

export const OrganisationRepurchaseStockPage = () => {
  const loginState: LoginState | undefined = useContext(LoggedInContext).loginState;
  const service: AppService = useAppService();

  const history = useHistory();
  const selectedOrgId = assertNotUndefined(useSelectedOrgId());
  const location = useLocation();
  const repurchaseFlowState = location.state as RepurchaseFlowState | undefined;

  const [activeStorageLocation, setActiveStorageLocation] = useState<{
    loc: WithDbId<StorageLocation>;
    availableStockTypes: Set<TopLevelUnitType>;
  }>();
  const [storageLocations, setStorageLocations] = useState<WithDbId<StorageLocation>[]>([]);
  // Available stock types indexed by location
  const availableStockTypesByLoc = useRef<Map<DbKey<StorageLocation>, Set<TopLevelUnitType>>>();
  const updateActiveStorageLocation = useCallback(
    (newLoc: WithDbId<StorageLocation>) => {
      let availableStockTypes: Set<TopLevelUnitType> = new Set();
      if (availableStockTypesByLoc?.current && availableStockTypesByLoc.current.has(newLoc.id)) {
        availableStockTypes = availableStockTypesByLoc.current.get(newLoc.id) ?? availableStockTypes;
      }
      setActiveStorageLocation({ loc: newLoc, availableStockTypes });
    },
    [availableStockTypesByLoc],
  );

  const loadLocationsProductListings = useCallback(
    async (searchTerm?: string) => {
      const locationsProductListings = await service.getLocationsProductListings({
        organisationId: selectedOrgId,
        searchTerm: searchTerm || null,
      });

      const locationProductsMap: Map<DbKey<StorageLocation>, Map<TopLevelUnitType, ProductListing[]>> = new Map();
      const locations: WithDbId<StorageLocation>[] = [];
      if (locationsProductListings.length > 0) {
        locationsProductListings.forEach(entry => {
          // NOTE(Barry): We're not going to show products that only have deal legs that are not available.
          locationProductsMap.set(entry.key.id, new Map());
          const availableProducts: ProductListing[] = entry.value.filter(hasAvailableBalance);
          availableProducts.forEach((pl: ProductListing) => {
            if (locationProductsMap.has(entry.key.id)) {
              const topLevelUnitType = getTopLevelForUnitType(pl.productWithId.value.unitType);
              const mapByUnit: Map<TopLevelUnitType, ProductListing[]> = assertNotUndefined(
                locationProductsMap.get(entry.key.id),
              );
              if (!mapByUnit.has(topLevelUnitType)) {
                mapByUnit.set(topLevelUnitType, []);
              }
              assertNotUndefined(mapByUnit.get(topLevelUnitType)).push({
                ...pl,
                dealLegs: pl.dealLegs.filter(dl => dl.availabilityStatus === "available"),
              });
            }
          });
          if (availableProducts.length > 0) {
            locations.push(entry.key);
          }
        });

        // First fetch initializations
        // TODO: Move into a separate fetch
        if (!availableStockTypesByLoc.current) {
          setStorageLocations(locations);
          const stockTypesByLoc = new Map<DbKey<StorageLocation>, Set<TopLevelUnitType>>();
          if (locations.length > 0) {
            for (const loc of locations) {
              const productsInLocation: Map<TopLevelUnitType, ProductListing[]> =
                locationProductsMap.get(loc.id) ?? new Map();
              stockTypesByLoc.set(loc.id, new Set(productsInLocation.keys()));
            }
          }
          availableStockTypesByLoc.current = stockTypesByLoc;
        }

        // Initialize selected storage location
        if (!activeStorageLocation && locationProductsMap.size) {
          // If the initial location was selected through the repurchase flow state, use that
          const initialLoc = locations.find(loc => loc.id === repurchaseFlowState?.repurchaseRequest.storageLocationId);
          if (initialLoc) {
            updateActiveStorageLocation(initialLoc);
          } else {
            const defaultInitialLocation = locations.sort((withId1, withId2) => {
              return storageLocationCompareFn(withId1.value, withId2.value);
            })[0];
            updateActiveStorageLocation(defaultInitialLocation);
          }
        }
      }

      return locationProductsMap;
    },
    [
      activeStorageLocation,
      repurchaseFlowState?.repurchaseRequest.storageLocationId,
      selectedOrgId,
      service,
      updateActiveStorageLocation,
    ],
  );

  const [loadingLocationsProductListings, setLoadingLocationsProductListings] = useState<
    LoadingValue<Map<string, Map<TopLevelUnitType, ProductListing[]>>>
  >({ state: "loading" });

  const onProductSearch = async (searchTerm: string) => {
    const value = await loadLocationsProductListings(searchTerm);
    setLoadingLocationsProductListings({ state: "success", value });
  };

  // We just want this use effect to fire once - initially
  useEffect(() => {
    loadLocationsProductListings().then(
      locationProductsMap => {
        setLoadingLocationsProductListings({ state: "success", value: locationProductsMap });
      },
      () => {
        setLoadingLocationsProductListings({ state: "error", error: new Error("Failed to load product listings") });
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBottleRepurchaseOrderStages = useCallback(
    async productId => {
      if (!activeStorageLocation) {
        return [];
      }
      const resp = await service.getDealLegRepurchaseOrder({
        productId,
        orgId: selectedOrgId,
        storageLocationId: activeStorageLocation.loc.id,
      });
      if (resp.kind === "invalidProduct") {
        throw new Error("Invalid product");
      }
      return isLoaded(loadingLocationsProductListings) && activeStorageLocation ? resp.value : [];
    },
    [activeStorageLocation, loadingLocationsProductListings, selectedOrgId, service],
  );

  const onSubmitRepurchaseRequest = useCallback(
    (type: TopLevelUnitType, productsSaleOrders: ProductSaleOrder[]) => {
      if (activeStorageLocation !== undefined) {
        const state: RepurchaseFlowState = {
          repurchaseRequest: {
            type,
            productsSaleOrders,
            storageLocationId: activeStorageLocation.loc.id,
          },
        };
        history.push(AppRoutes.RepurchaseDelivery, state);
      }
    },
    [activeStorageLocation, history],
  );

  const stockInActiveStorageLocation: Map<TopLevelUnitType, ProductListing[]> = useMemo(() => {
    if (
      isLoaded(loadingLocationsProductListings) &&
      activeStorageLocation &&
      loadingLocationsProductListings.value.get(activeStorageLocation.loc.id) !== undefined
    ) {
      return assertNotUndefined(loadingLocationsProductListings.value.get(activeStorageLocation.loc.id));
    }
    return new Map();
  }, [activeStorageLocation, loadingLocationsProductListings]);

  const userProfile = loginState?.user?.profile;

  return (
    <Loader loadingStates={[loadingLocationsProductListings]} fullScreen>
      {isLoaded(loadingLocationsProductListings) && (
        <OrganisationRepurchaseStockPageView
          adminView={userProfile !== undefined && isAdmin(userProfile)}
          activeStorageLocation={activeStorageLocation}
          onChangeActiveStorageLocation={updateActiveStorageLocation}
          storageLocations={storageLocations}
          stockInActiveStorageLocation={stockInActiveStorageLocation}
          getBottleRepurchaseOrderStages={getBottleRepurchaseOrderStages}
          onSubmit={onSubmitRepurchaseRequest}
          onProductSearch={onProductSearch}
          initialFlowState={repurchaseFlowState}
        />
      )}
    </Loader>
  );
};
