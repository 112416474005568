import React from "react";
import { Card, CardText } from "components/library/widgets/card/card";
import { PurchaseRequestIncoterms, purchaseRequestIncotermsToString } from "utils/conversion-utils";
import { PurchaseRequestPaymentTermsPeriod } from "adl-gen/ferovinum/app/db";
import { assertNever } from "utils/hx/util/types";
import { MONTHS_AFTER_DELIVERY_FIELD_DESCRIPTIONS } from "components/library/widgets/payment-terms-input";
export interface PurchaseRequestTermsCardProps {
  incoterms: PurchaseRequestIncoterms;
  paymentTermsPeriod: PurchaseRequestPaymentTermsPeriod;
  showTerms(): void | Promise<void>;
}

export const PurchaseRequestTermsCard = ({
  incoterms,
  paymentTermsPeriod,
  showTerms,
}: PurchaseRequestTermsCardProps) => {
  const incotermsDescription = `${incoterms} - ${purchaseRequestIncotermsToString(incoterms)}`;
  const paymentTermsDescription =
    paymentTermsPeriod.kind === "daysAfterCollection"
      ? `${paymentTermsPeriod.value} days from invoice`
      : paymentTermsPeriod.kind === "endOfMonth"
      ? `${MONTHS_AFTER_DELIVERY_FIELD_DESCRIPTIONS[paymentTermsPeriod.value]} after delivery`
      : (assertNever(paymentTermsPeriod), "");

  return (
    <Card
      header={{
        title: "Terms",
        action: {
          label: "Terms and Conditions",
          onClick: showTerms,
        },
      }}
      body={
        <>
          <CardText title="Incoterms" content={incotermsDescription} />
          <CardText title="Payment terms" content={paymentTermsDescription} />
        </>
      }
    />
  );
};
