import React, { useCallback, useEffect, useMemo, useState } from "react";
import { assertNever, assertNotUndefined } from "utils/hx/util/types";
import { useSelectedSupplier } from "../../../layouts/portal-page-layout/portal-page";
import { SupplierPOView } from "adl-gen/ferovinum/app/procurement";
import { Paginated } from "adl-gen/common";
import { useAppService } from "../../../../hooks/use-app-service";
import { useParams } from "react-router-dom";
import {
  SupplierAcceptNewDealRequestReq,
  SupplierPurchaseOrdersListingType,
  SupplierUpdateNewDealRequestReq,
} from "adl-gen/ferovinum/app/api";
import { isErrored, isLoaded, isLoading, LoadingValue } from "utils/utility-types";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { SupplierNewDealRequestsPageView } from "./supplier-new-deal-requests-page-view";

export type SupplierNewDealRequestAction =
  | { kind: "accept"; value: SupplierAcceptNewDealRequestReq }
  | { kind: "update"; value: SupplierUpdateNewDealRequestReq };

export const SupplierNewDealRequestsPage = () => {
  const service = useAppService();
  const supplier = assertNotUndefined(useSelectedSupplier());
  const { filter } = useParams<{ filter: SupplierPurchaseOrdersListingType }>();
  const [paginatedPos, setPaginatedPos] = useState<LoadingValue<Paginated<SupplierPOView>>>({ state: "loading" });
  const [showAlert] = useAlert();

  const fetchNewDealRequestsForSupplier = useCallback(() => {
    if (supplier) {
      service
        .supplierPurchaseOrders({
          supplierId: supplier.id,
          filter,
          offset: 0,
          // TODO(Barry): Pagination
          count: -1,
        })
        .then(result => setPaginatedPos({ state: "success", value: result }))
        .catch(_ => showAlert({ title: "Error loading requests", body: "Could not load requests" }));
    } else {
      setPaginatedPos({ state: "error", error: new Error("No supplier selected") });
    }
  }, [service, supplier, filter, setPaginatedPos, showAlert]);

  useEffect(() => {
    fetchNewDealRequestsForSupplier();
  }, [fetchNewDealRequestsForSupplier]);

  const loadingNewDealRequests = useMemo<LoadingValue<SupplierPOView[]>>(() => {
    if (isLoaded(paginatedPos)) {
      return { state: "success", value: paginatedPos.value.items };
    } else if (isErrored(paginatedPos)) {
      return paginatedPos;
    } else if (isLoading(paginatedPos)) {
      return paginatedPos;
    } else {
      assertNever(paginatedPos);
    }
  }, [paginatedPos]);

  const onNewDealRequestAction = useCallback(
    async (action: SupplierNewDealRequestAction) => {
      if (action.kind === "accept") {
        await service.supplierAcceptNewDealRequest(action.value);
      } else {
        await service.supplierUpdateNewDealRequest(action.value);
      }
    },
    [service],
  );

  return (
    <SupplierNewDealRequestsPageView
      filter={filter}
      loadingNewDealRequests={loadingNewDealRequests}
      refreshNewDealRequests={() => fetchNewDealRequestsForSupplier()}
      onNewDealRequestAction={onNewDealRequestAction}
    />
  );
};
