import React, { useCallback, useMemo, useState } from "react";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { Box, Button, Card, Divider, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { formatInstant } from "utils/date-utils";
import { NewDealRequestDetailsView } from "utils/hooks/use-new-deal-request-impl";
import { Instant } from "adl-gen/common";
import { OrganisationNewDealRequestSummaryTable } from "../../../../widgets/new-deal-requests/organisation-new-deal-request-summary-table/organisation-new-deal-request-summary-table";
import {
  OrganisationNewDealRequestStateStepper,
  OrgNewDealRequestState,
  newDealRequestStateEventsToOrgStepsData,
} from "../../../../widgets/new-deal-requests/procurement-stepper/organisation-new-deal-request-stepper";
import { StickyNote } from "../../../../widgets/sticky-note/sticky-note";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { getNewStockNewDealRequestSupplier } from "utils/adl-utils";
import { useSettlementCurrency } from "../../../../layouts/portal-page-layout/portal-page";
import { IncotermsStickyNote } from "../../../../widgets/sticky-note/incoterms-sticky-note/incoterms-sticky-note";
import { DeliveryContactStickyNote } from "../../../../widgets/sticky-note/delivery-contact-sticky-note/delivery-contact-sticky-note";
import { OrganisationNewDealRequestNetSubtotalTable } from "../../../../widgets/new-deal-requests/organisation-new-deal-request-net-subtotal-table/organisation-new-deal-request-net-subtotal-table";
import { useSelectedEntityIsWholesaler } from "../../../../../utils/procurement-ui-utils";
import { NewDealRequestType } from "adl-gen/ferovinum/app/db";
import { useConfirmationDialog } from "components/context/global-dialog/use-dialog";

type Sections = "Contract note and progress" | "Order summary" | "Stock adjustment summary";
export interface OrganisationNewDealRequestDetailsPageViewProps {
  variant: NewDealRequestType;
  newDealRequest: NewDealRequestDetailsView;
  acknowledgeDealTerms: (acceptOrReject: boolean) => Promise<void>;
  markDepositAsPaid: () => Promise<void>;
  getDownloadUrl?: () => Promise<string | undefined>;
  onEdit: () => void;
  onCancel: () => Promise<void>;
}
export const OrganisationNewDealRequestDetailsPageView = (props: OrganisationNewDealRequestDetailsPageViewProps) => {
  const [selectedSection, setSelectedSection] = useState<Sections>("Contract note and progress");
  const { newDealRequest } = props;
  const isResultOfStockAdjustment = useMemo(
    () => newDealRequest.stockAdjustmentDetails !== undefined,
    [newDealRequest.stockAdjustmentDetails],
  );

  const { showConfirmationDialog } = useConfirmationDialog();

  const handleCancellationRequest = useCallback(async () => {
    await showConfirmationDialog({
      title: "Cancel this request?",
      confirmAction: {
        title: "Yes, cancel",
        onClick: props.onCancel,
      },
      cancelAction: { title: "Keep it for now" },
    });
  }, [showConfirmationDialog, props]);

  const isCancelled = newDealRequest.currentState.state === "cancelled";

  const header = isCancelled ? (
    <CancelledOrganisationPurchaseOrderDetailsPageHeader
      newDealRequestOrderNumber={newDealRequest.dealNumber}
      cancellationDate={newDealRequest.currentState.time}
    />
  ) : (
    <OrganisationPurchaseOrderDetailsPageHeader
      newDealRequestOrderNumber={newDealRequest.dealNumber}
      orderDate={newDealRequest.createdAt}
      isResultOfStockAdjustment={isResultOfStockAdjustment}
    />
  );

  if (isCancelled) {
    return (
      <PortalPageContent header={header}>
        <OrderSummarySection {...props} />
      </PortalPageContent>
    );
  } else {
    return (
      <PortalPageContent header={header}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Tabs value={selectedSection} onChange={(_, v) => setSelectedSection(v)}>
            <Tab label="Contract note and progress" value="Contract note and progress" />
            {isResultOfStockAdjustment ? (
              <Tab label="Stock adjustment summary" value="Stock adjustment summary" />
            ) : (
              <Tab label="Order summary" value="Order summary" />
            )}
          </Tabs>
          {(newDealRequest.currentState.state === "newDeal" ||
            newDealRequest.currentState.state === "feroAccepted") && (
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={props.onEdit}>
                Edit request
              </Button>
              <Button onClick={handleCancellationRequest}>Cancel request</Button>
            </Stack>
          )}
        </Stack>

        <Stack spacing={4} paddingTop={5}>
          {selectedSection === "Contract note and progress" && <ContractNoteAndProgressSection {...props} />}
          {selectedSection === "Order summary" && <OrderSummarySection {...props} />}
          {selectedSection === "Stock adjustment summary" && <StockAdjustmentSummary {...props} />}
        </Stack>
      </PortalPageContent>
    );
  }
};

interface ContractNoteAndProgressSectionProps {
  variant: NewDealRequestType;
  newDealRequest: NewDealRequestDetailsView;
  acknowledgeDealTerms: (acceptOrReject: boolean) => Promise<void>;
  markDepositAsPaid: () => Promise<void>;
  getDownloadUrl?: () => Promise<string | undefined>;
}

const ContractNoteAndProgressSection = ({
  variant,
  newDealRequest,
  getDownloadUrl,
  ...actionCallbacks
}: ContractNoteAndProgressSectionProps) => {
  const settlementCurrency = useSettlementCurrency();
  const currentState = newDealRequest.currentState.state;

  return (
    <Grid container spacing={4}>
      <Grid item lg={12}>
        <OrganisationNewDealRequestSummaryTable
          variantTotalsDetails={{
            kind: variant,
            ...newDealRequest,
            additionalDetails: { ...newDealRequest },
          }}
          products={newDealRequest.products}
          purchaseCurrency={newDealRequest.purchaseCurrency}
          settlementCurrency={settlementCurrency}
          actionDetails={{
            ...newDealRequest,
            ...actionCallbacks,
            dealTerms: newDealRequest.dealTermsView,
            currentState: currentState,
          }}
          contractNoteDetails={{
            newDealRequest: newDealRequest,
            stateEvents: newDealRequest.newDealRequestEvents,
            getDownloadUrl: getDownloadUrl,
          }}
        />
      </Grid>
      <Grid item xs={12} lg={newDealRequest.rejectionReason ? 6 : 12}>
        <ProgressStepperCard variant={variant} purchaseOrder={newDealRequest} />
      </Grid>
      {newDealRequest.rejectionReason && (
        <Grid item xs={12} lg={6}>
          <RejectionReasonCard rejectionReason={newDealRequest.rejectionReason} />
        </Grid>
      )}
    </Grid>
  );
};

interface OrderSummarySectionProps {
  variant: NewDealRequestType;
  newDealRequest: NewDealRequestDetailsView;
}
const OrderSummarySection = ({ variant, newDealRequest }: OrderSummarySectionProps) => {
  const settlementCurrency = useSettlementCurrency();
  const orgIsWholesaler = useSelectedEntityIsWholesaler();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <StickyNotes variant={variant} newDealRequest={newDealRequest} />
      </Grid>
      <Grid item xs={12}>
        <OrganisationNewDealRequestNetSubtotalTable
          variant={variant}
          products={newDealRequest.products}
          purchaseCurrency={newDealRequest.purchaseCurrency}
          settlementCurrency={settlementCurrency}
          netSubtotal={newDealRequest.netSubtotal}
          discount={newDealRequest.discount}
          orgIsWholesaler={orgIsWholesaler}
        />
      </Grid>
    </Grid>
  );
};

interface StockAdjustmentSummaryProps {
  newDealRequest: NewDealRequestDetailsView;
}
const StockAdjustmentSummary = ({ newDealRequest }: StockAdjustmentSummaryProps) => {
  const { stockAdjustmentDetails, storageLocationName } = newDealRequest;
  const settlementCurrency = useSettlementCurrency();
  const orgIsWholesaler = useSelectedEntityIsWholesaler();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={3}>
            <StickyNote title="Storage location" line1={storageLocationName} />
          </Grid>
          {stockAdjustmentDetails?.reasonForStockAdjustment && (
            <Grid item xs={6} lg={3}>
              <StickyNote title="Reason for stock adjustment" line1={stockAdjustmentDetails.reasonForStockAdjustment} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <OrganisationNewDealRequestNetSubtotalTable
          variant="existingStock"
          {...newDealRequest}
          settlementCurrency={settlementCurrency}
          orgIsWholesaler={orgIsWholesaler}
        />
      </Grid>
    </Grid>
  );
};

const OrganisationPurchaseOrderDetailsPageHeader = ({
  newDealRequestOrderNumber,
  orderDate,
  isResultOfStockAdjustment,
}: {
  newDealRequestOrderNumber: string;
  orderDate: Instant;
  isResultOfStockAdjustment: boolean;
}) => {
  return (
    <PortalPageContentHeader
      title={`${
        isResultOfStockAdjustment ? "Positive stock adjustment" : "New deal request order"
      } number: ${newDealRequestOrderNumber}`}
      subtitles={[
        {
          text: `${isResultOfStockAdjustment ? "Creation" : "Order"} date: ${formatInstant(orderDate)}`,
          icon: "date",
        },
      ]}
    />
  );
};

const CancelledOrganisationPurchaseOrderDetailsPageHeader = ({
  newDealRequestOrderNumber,
  cancellationDate,
}: {
  newDealRequestOrderNumber: string;
  cancellationDate: Instant;
}) => {
  return (
    <PortalPageContentHeader
      title="Cancelled new deal request"
      subtitles={[
        {
          text: `Request number: ${newDealRequestOrderNumber}`,
        },
        {
          text: `Cancellation date: ${formatInstant(cancellationDate)}`,
          icon: "date",
        },
      ]}
    />
  );
};

interface ProgressStepperCardProps {
  variant: NewDealRequestType;
  purchaseOrder: NewDealRequestDetailsView;
}
const ProgressStepperCard = ({ variant, purchaseOrder }: ProgressStepperCardProps) => {
  const finalConfirmationEvent = purchaseOrder.newDealRequestEvents.find(
    e => e.state === "warehouseDeliveryConfirmed" || e.state === "paymentTransferred",
  );
  const dealCreatedTimestamp =
    purchaseOrder.dealId !== null && finalConfirmationEvent !== undefined ? finalConfirmationEvent.time : null;
  const currentState: OrgNewDealRequestState =
    dealCreatedTimestamp !== null ? "dealCreated" : purchaseOrder.currentState.state;
  const stepsData = newDealRequestStateEventsToOrgStepsData(
    purchaseOrder.newDealRequestEvents,
    dealCreatedTimestamp,
    purchaseOrder.supplierEarliestCollectionDate,
    purchaseOrder.carrierEarliestCollectionDate,
  );

  return (
    <Card sx={{ minHeight: "200px" }}>
      <Stack divider={<Divider />} height="100%">
        <Typography variant="caption" padding={2} paddingTop={1} paddingBottom={1} color="common.grey5">
          Progress
        </Typography>
        <Box paddingLeft={2} minHeight={0}>
          <OrganisationNewDealRequestStateStepper
            variant={variant}
            currentState={currentState}
            stepsData={stepsData}
            maxHeight="100%"
          />
        </Box>
      </Stack>
    </Card>
  );
};

interface RejectionReasonCardProps {
  rejectionReason: string;
}
const RejectionReasonCard = ({ rejectionReason }: RejectionReasonCardProps) => {
  return (
    <Card sx={{ minHeight: "200px" }}>
      <Stack divider={<Divider />} height="100%">
        <Typography variant="caption" padding={2} paddingTop={1} paddingBottom={1} color="common.grey5">
          Reason for rejection
        </Typography>
        <Box padding={2}>
          <Typography>{rejectionReason}</Typography>
        </Box>
      </Stack>
    </Card>
  );
};

interface StickyNotesProps {
  variant: NewDealRequestType;
  newDealRequest: NewDealRequestDetailsView;
}
const StickyNotes = ({ variant, newDealRequest }: StickyNotesProps) => {
  const { storageLocationName, newDealRequestType } = newDealRequest;

  return (
    <Grid container spacing={2}>
      {newDealRequestType.kind === "newStockNewDealRequest" &&
        [newDealRequestType].map(type => {
          const {
            carrier: {
              value: { name: carrierName },
            },
          } = type.value;
          const { contactName, contactMobile, contactEmail, name } = getNewStockNewDealRequestSupplier(
            newDealRequestType.value.supplier,
          );
          return (
            <React.Fragment key="new-stock-sticky-notes">
              <Grid item xs={6} lg={3}>
                <DeliveryContactStickyNote
                  title="Supplier"
                  companyName={name}
                  deliveryContact={contactName}
                  deliveryContactNumber={contactMobile}
                  deliveryContactEmail={contactEmail}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <StickyNote title="Carrier" line1={carrierName} />
              </Grid>
            </React.Fragment>
          );
        })}
      <Grid item xs={6} lg={3}>
        <StickyNote title={variant === "newStock" ? "Destination" : "Storage location"} line1={storageLocationName} />
      </Grid>
      {newDealRequest.newDealRequestType.kind === "newStockNewDealRequest" &&
        newDealRequest.newDealRequestType.value.incoterms && (
          <Grid item xs={6} lg={3}>
            <IncotermsStickyNote incoterms={newDealRequest.newDealRequestType.value.incoterms} />
          </Grid>
        )}
    </Grid>
  );
};
