import assertNever from "assert-never";
import { ConfirmationDialog } from "components/widgets/confirmation-dialog/confirmation-dialog";
import { FormikConfig, FormikValues, useFormik } from "formik";
import React, { useMemo } from "react";
import { CaskedWhiskyProductInfo } from "../../../service/casked-whisky-product-csv-parser";
import { NewProductData } from "../../page/organisation/new-deal-requests/organisation-create-new-deal-request/organisation-create-new-deal-request-page";
import { SimpleProductEditorForm, SimpleProductFormData, SimpleProductFormHandler } from "./simple-product-editor-form";
import { CaskedWhikyEditorForm, CaskedWhiskyFormHandler } from "./casked-whisky-editor-form";
import { ProductFormHandler } from "./product-editor-form-types";
import { FormikForm } from "components/types/formik-types";

interface ProductEditorDialogProps {
  initialValues?: NewProductData;
  localUniqueProductCodes: string[];
  onValidateProductCode(productCode: string): Promise<boolean>;
  onAdd: (productData: NewProductData) => void;
  onUpdate: (code: string, productData: NewProductData) => void;
  onClose: () => void;
}

export const ProductEditorDialog = (props: ProductEditorDialogProps) => {
  const { initialValues, onClose } = props;

  const formWithType = useFormikWithFormHandler(props);

  return (
    <ConfirmationDialog
      disableEnforceFocus // Disabled as it was not letting users type into the search input when this dialog was opened through the add new product link
      title={`${initialValues ? "Edit" : "Add"} Product`}
      open={true}
      cancelAction={{
        onClick: () => {
          onClose();
        },
      }}
      acceptAction={{
        onClick: formWithType.form.submitForm,
        title: initialValues ? "UPDATE" : "ADD",
      }}
      maxWidth={"md"}>
      {formWithType.type === "unknown" ? (
        <SimpleProductEditorForm form={formWithType.form} />
      ) : formWithType.type === "caskedWhisky" ? (
        <CaskedWhikyEditorForm form={formWithType.form} />
      ) : (
        assertNever(formWithType)
      )}
    </ConfirmationDialog>
  );
};

function useFormikWithFormHandler(props: ProductEditorDialogProps) {
  const { initialValues, localUniqueProductCodes, onValidateProductCode, onAdd, onUpdate } = props;

  const alcoholDetailType = initialValues?.alcoholDetail.kind ?? "unknown";

  const handler: ProductFormHandler<FormikValues> = useMemo(() => {
    switch (alcoholDetailType) {
      case "unknown":
        return new SimpleProductFormHandler();
      case "caskedWhisky":
        return new CaskedWhiskyFormHandler();
      default:
        assertNever(alcoholDetailType);
    }
  }, [alcoholDetailType]);

  const formikConfig = useMemo(() => {
    const uniqueProductCodes = new Set(localUniqueProductCodes.filter(code => code !== initialValues?.code));
    return {
      initialValues: handler.fromProductData(initialValues),
      // TODO Zhi: SIMPLIFY check product code validity outside of yup schema
      validationSchema: handler.createValidationSchema(uniqueProductCodes, onValidateProductCode),
      onSubmit: values => {
        const productData: NewProductData = handler.toProductData(values);
        if (initialValues !== undefined) {
          onUpdate(initialValues.code, productData);
        } else {
          onAdd(productData);
        }
      },
      validateOnBlur: false,
      validateOnChange: false,
    } as FormikConfig<FormikValues>;
  }, [handler, initialValues, localUniqueProductCodes, onValidateProductCode, onAdd, onUpdate]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const form: FormikForm<any> = useFormik(formikConfig);

  switch (alcoholDetailType) {
    case "unknown": {
      return { type: alcoholDetailType, form: form as FormikForm<SimpleProductFormData> };
    }
    case "caskedWhisky":
      return { type: alcoholDetailType, form: form as FormikForm<CaskedWhiskyProductInfo> };
    default:
      assertNever(alcoholDetailType);
  }
}
