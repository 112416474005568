// ADL creates optional properties as 'T | null' whereas Formik forms expect optional properties to be 'T | undefined'.
// This utility types and functions converts all 'null' properties to 'undefined' properties and vice versa.

import { UnionType } from "./utility-types";

export type NullToUndef<T> = T extends object
  ? T extends UnionType
    ? UnionNullToUndef<T>
    : ObjectNullToUndef<T>
  : ValueNullToUndef<T>;
export type UndefToNull<T> = T extends object
  ? T extends UnionType
    ? UnionUndefToNull<T>
    : ObjectUndefToNull<T>
  : ValueUndefToNull<T>;

type ObjectNullToUndef<T> = { [P in keyof T]: NullToUndef<T[P]> };
type ObjectUndefToNull<T> = { [P in keyof T]: UndefToNull<T[P]> };

type UnionNullToUndef<T extends UnionType> = T extends { value: unknown }
  ? { kind: T["kind"]; value: NullToUndef<T["value"]> }
  : { kind: T["kind"] };
type UnionUndefToNull<T extends UnionType> = T extends { value: unknown }
  ? { kind: T["kind"]; value: UndefToNull<T["value"]> }
  : { kind: T["kind"] };

type ValueNullToUndef<T> = null extends T ? Exclude<T, null> | undefined : T;
type ValueUndefToNull<T> = undefined extends T ? Exclude<T, undefined> | null : T;

export function mapNullToUndef<T extends object>(data: T): NullToUndef<T> {
  if (data == null) {
    return undefined as NullToUndef<T>;
  }
  const result: Partial<NullToUndef<T>> = {};
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] === null) {
        (result as Record<keyof T, undefined>)[key] = undefined;
      } else {
        (result as T)[key] = data[key];
      }
    }
  }
  return result as NullToUndef<T>;
}

export function mapUndefToNull<T extends object>(data: T): UndefToNull<T> {
  const result: Partial<UndefToNull<T>> = {};
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] === undefined) {
        (result as Record<keyof T, null>)[key] = null;
      } else {
        (result as T)[key] = data[key];
      }
    }
  }
  return result as UndefToNull<T>;
}

/*
 * @param x - The value to check.
 * @param condition - Optional condition that the value must satisfy.
 * @param msg - Optional message for the exception.
 * @returns The value if it is valid.
 * @throws Error if the value is invalid.
 */
export function ensure<T>(
  x: T | undefined | null,
  options: { condition?: (value: T) => boolean; msg?: string } = {},
): T {
  const { condition, msg } = options;

  if (x === undefined || x === null) {
    throw new Error(`${msg || "value is invalid"}`);
  }

  if (condition && !condition(x)) {
    throw new Error(`${msg || "value does not meet the condition"}`);
  }

  return x;
}

/**
 * Filters a list based on the provided condition.
 *
 * @param list - The list to filter.
 * @param condition - Optional condition that each value must satisfy.
 * @returns A list of values that are valid.
 */
export function filterValid<T>(list: (T | undefined | null)[], condition?: (value: T) => boolean): T[] {
  return list.filter((item): item is T => {
    if (item === undefined || item === null) {
      return false;
    }

    return !(condition && !condition(item));
  });
}
