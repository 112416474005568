import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Card, CardActionArea, Chip, IconButton, Stack, Typography } from "@mui/material";
import React, { DragEvent, MouseEvent, useCallback, useRef } from "react";
import { downloadFileAs } from "utils/file-fetch-utils";
import { useAlert } from "../../context/global-alert/use-alert-context";
import { Link } from "../../widgets/link/link";

export const FilePicker = ({
  accept,
  maxFileCount,
  onFilesAccepted,
  addExtraPadding,
}: {
  accept?: string;
  maxFileCount?: number;
  onFilesAccepted(files: File[]): void;
  addExtraPadding?: boolean;
}) => {
  const [showAlert] = useAlert();
  const uploadRef = useRef<HTMLInputElement>(null);
  const FILE_SIZE_LIMIT_MB = 25;

  const verifyFileTypeAndSize = useCallback(
    async (file: File) => {
      if (accept && file.type !== accept) {
        await showAlert({
          title: `${file.name} has the wrong file type: "${file.type || "unknown"}"`,
          body: `We only accept ${accept} files, please contact operations.`,
        });
        return false;
      } else if (file.size > FILE_SIZE_LIMIT_MB * 1e6) {
        await showAlert({
          title: `${file.name} is too big: ${Math.round((file.size / 1e6) * 10) / 10}MB`,
          body: `We cannot accept files larger than ${FILE_SIZE_LIMIT_MB}MB, please contact operations.`,
        });
        return false;
      } else {
        return true;
      }
    },
    [accept, showAlert],
  );

  const verifyAndAcceptFiles = useCallback(
    async (files: FileList) => {
      const acceptedFiles: File[] = [];
      const fileCount = Math.min(files.length, maxFileCount ?? 1);
      for (let i = 0; i < files.length; i++) {
        if (acceptedFiles.length >= fileCount) {
          await showAlert({
            title: "Too many files",
            body: `You can only select ${fileCount} files at a time.`,
          });
          break;
        }
        if (await verifyFileTypeAndSize(files[i])) {
          acceptedFiles.push(files[i]);
        }
      }
      if (acceptedFiles.length > 0) {
        onFilesAccepted(acceptedFiles);
      }
    },
    [maxFileCount, showAlert, verifyFileTypeAndSize, onFilesAccepted],
  );

  const handleDrag = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      e.dataTransfer?.files && verifyAndAcceptFiles(e.dataTransfer.files);
    },
    [verifyAndAcceptFiles],
  );

  const fileType = accept?.split("/")[1].toUpperCase();

  return (
    <Card variant="outlined" sx={{ border: theme => `2px dashed ${theme.palette.common.grey4}` }}>
      <CardActionArea
        sx={{ p: addExtraPadding ? 12 : 4 }}
        onClick={() => uploadRef.current?.click()}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}>
        <Stack spacing={2} alignItems="center" justifyContent="center">
          <UploadFileIcon />
          <Typography textAlign="center">Drag and drop or click to upload</Typography>
          <Stack>
            <Typography variant="caption" textAlign="center">
              {fileType} (max. {FILE_SIZE_LIMIT_MB}MB)
            </Typography>
          </Stack>
        </Stack>
      </CardActionArea>
      <input
        type="file"
        multiple={(maxFileCount ?? 1) > 1}
        ref={uploadRef}
        onChange={e => {
          e.target.files && verifyAndAcceptFiles(e.target.files);
          // reset file input value to ensure onChange is triggered even if the same file is selected
          // calling on setTimeout to ensure the value is reset after the onChange event is handled
          setTimeout(() => {
            if (uploadRef.current) {
              uploadRef.current.value = "";
            }
          });
        }}
        style={{ display: "none" }}
        accept={accept}
      />
    </Card>
  );
};

export const BasicFileItem = ({ fileName }: { fileName: string }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <InsertDriveFileOutlinedIcon />
      {fileName}
    </Stack>
  );
};

export const RemovableFileItem = ({ file, onClick }: { file: File; onClick?: () => void }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <IconButton onClick={onClick}>
        <Chip size="small" color="default" label="Remove" />
      </IconButton>
      <Box fontWeight="fontWeightMedium" display="inline">
        File selected:{" "}
      </Box>{" "}
      <Typography>{file.name}</Typography>
    </Stack>
  );
};

export const DownloadableFileItem = ({
  fileName,
  urlGetter,
}: {
  fileName: string;
  urlGetter: () => Promise<string>;
}) => {
  return (
    <Link display="flex" alignItems="center" onClick={async () => downloadFileAs(await urlGetter(), fileName)}>
      <DownloadIcon /> {fileName}
    </Link>
  );
};

export const OpenUrlItem = ({ displayName, urlGetter }: { displayName: string; urlGetter: () => Promise<string> }) => {
  return (
    <Link
      display="flex"
      alignItems="center"
      onClick={async () => {
        window.open(await urlGetter(), "_blank");
      }}>
      {displayName}
      <OpenInNewIcon fontSize="small" />
    </Link>
  );
};

export function isSameFile(a: File, b: File) {
  return a.name === b.name && a.size === b.size && a.type === b.type;
}
