import {
  Alert,
  Button,
  Stack,
  TableBody,
  TableBodyProps,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { WithDbId } from "adl-gen/common/db";
import { SaleOrder } from "adl-gen/ferovinum/app/db";
import { TabbedPage } from "components/library/widgets/tabbed-page";
import { Link } from "components/widgets/link/link";
import React from "react";
import { extractDeliveryStatus } from "utils/conversion-utils";
import { todayYesterdayOrDateFormat } from "utils/date-utils";
import { AppRoutes } from "../../../../../app/app-routes";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { ClientSidePaginatedTable } from "../../../../widgets/common/client-side-paginated-table/client-side-paginated-table";
import { SaleOrdersFilter, getSaleOrderStatus } from "./organisation-sale-orders-page";

export interface OrganisationSaleOrdersPageViewProps {
  saleOrders: WithDbId<SaleOrder>[];
  setFilter: (filter: SaleOrdersFilter) => void;
}

const NewSaleOrderButton = () => <Button href={AppRoutes.Repurchase}>Repurchase stock</Button>;
export const OrganisationSaleOrdersPageView = ({ saleOrders, setFilter }: OrganisationSaleOrdersPageViewProps) => {
  return (
    <PortalPageContent header={<PortalPageContentHeader title="Sale Orders" />}>
      <TabbedPage
        tabs={[
          {
            label: "In Progress",
            key: "inProgress",
            content: <SaleOrdersTable saleOrders={saleOrders} />,
            toolbar: <NewSaleOrderButton />,
          },
          {
            label: "Completed",
            key: "completed",
            content: <SaleOrdersTable saleOrders={saleOrders} />,
            toolbar: <NewSaleOrderButton />,
          },
        ]}
        handleTabChange={({ label }) => {
          setFilter(label as SaleOrdersFilter);
        }}
      />
    </PortalPageContent>
  );
};

const SaleOrdersTable = ({ saleOrders }: { saleOrders: WithDbId<SaleOrder>[] }) => {
  return (
    <Stack spacing={2}>
      {saleOrders.length === 0 ? (
        <Alert severity="info">{"You haven't placed any sale orders yet."}</Alert>
      ) : (
        <ClientSidePaginatedTable
          initialRowsPerPage={50}
          rows={saleOrders}
          Header={
            <TableHead>
              <TableRow>
                <TableCell>Invoice Number</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>Paid by</TableCell>
                <TableCell>Progress</TableCell>
                <TableCell>Delivery Status</TableCell>
              </TableRow>
            </TableHead>
          }
          Body={<SaleOrdersTableBody rows={saleOrders} />}
        />
      )}
    </Stack>
  );
};

function SaleOrderStatus({ saleOrder }: { saleOrder: SaleOrder }) {
  return <Typography>{getSaleOrderStatus(saleOrder)}</Typography>;
}

function DeliveryStatus({ saleOrder }: { saleOrder: SaleOrder }) {
  return <Typography>{extractDeliveryStatus(saleOrder.deliveryStatuses)}</Typography>;
}

interface SaleOrdersTableBodyProps extends TableBodyProps {
  rows: WithDbId<SaleOrder>[];
}

const SaleOrdersTableBody = ({ rows: saleOrders }: SaleOrdersTableBodyProps) => {
  return (
    <TableBody>
      {saleOrders.map((row, i) => (
        <TableRow key={i}>
          <TableCell>
            <Link variant="big" href={`${AppRoutes.SaleOrder}/${row.id}`}>
              {row.value.invoiceNumber}
            </Link>
          </TableCell>
          <TableCell>{todayYesterdayOrDateFormat(new Date(row.value.soldAt))}</TableCell>
          <TableCell>{row.value.usesSettlementCredit ? "Settlement credit" : "Direct transfer"}</TableCell>
          <TableCell>
            <SaleOrderStatus saleOrder={row.value} />
          </TableCell>
          <TableCell>
            <DeliveryStatus saleOrder={row.value} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
