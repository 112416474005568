import React, { FC, useCallback, useMemo } from "react";
import _, { sortBy } from "lodash";
import { UserMembership } from "adl-gen/ferovinum/app/api";
import { EntityKind, IdToStore, SelectedEntity, useSelectedEntity } from "../portal-page-layout/portal-page";
import {
  Box,
  Collapse,
  Drawer,
  DrawerProps,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Carrier, Organisation, Purchaser, StorageLocation, Supplier } from "adl-gen/ferovinum/app/db";
import ListSubheader from "@mui/material/ListSubheader";
import { WithDbId } from "adl-gen/common/db";
import { AppRoutes } from "../../../app/app-routes";
import { UiConfigValues, useUiConfig } from "adl-service/hooks/use-ui-config";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import RightArrowIcon from "@mui/icons-material/ArrowRightAlt";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { useLocation } from "react-router-dom";
import { HEADER_HEIGHT } from "../header/header";
import { LinkBehavior } from "components/widgets/link/link";
import StorefrontIcon from "@mui/icons-material/Storefront";
import StockRevaluationIcon from "@mui/icons-material/PriceChangeOutlined";
import { useLayoutInfo } from "../layout-info";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { useHistory } from "react-router-dom";

export interface NavigationDrawerProps extends Omit<DrawerHeaderProps, "selectedEntity"> {
  membership: UserMembership;
  open: boolean;
  close?(): void;
}

export const NAVIGATION_DRAWER_WIDTH = 220;
export const NavigationDrawer: FC<NavigationDrawerProps> = ({ membership, onEntityChange, open, close }) => {
  const selectedEntity = useSelectedEntity();
  const { isMobile } = useLayoutInfo();
  const variant: DrawerProps["variant"] = isMobile ? "temporary" : "persistent";
  const width = isMobile ? "100%" : NAVIGATION_DRAWER_WIDTH;
  const onItemClicked = isMobile ? close : undefined;

  return (
    <Drawer
      variant={variant}
      anchor="left"
      open={open}
      sx={{
        "width": width,
        "flexShrink": 0,
        "& .MuiDrawer-paper": {
          top: HEADER_HEIGHT,
          width,
          boxSizing: "border-box",
        },
      }}>
      <DrawerHeader membership={membership} selectedEntity={selectedEntity} onEntityChange={onEntityChange} />
      <NavMenuItems selectedEntity={selectedEntity} onItemClicked={onItemClicked} />
    </Drawer>
  );
};

function encodeEntity(type: EntityKind, id: string) {
  return type + ":" + id;
}

interface SelectedId {
  type: EntityKind;
  id: string;
}

function decodeSelectedEntity(encoded: string): SelectedId | undefined {
  const parts = _.split(encoded, ":");
  if (parts.length === 2) {
    const type = parts[0];
    if (type === "org" || type === "storageLoc" || type === "carrier" || type === "supplier" || type === "purchaser") {
      return { type, id: parts[1] };
    }
  }
  return undefined;
}

interface MenuItemsProps<T> {
  membershipList: WithDbId<T>[];
  type: EntityKind;
  subHeaderName: string;
  getName(val: WithDbId<T>): string;
}

interface DrawerHeaderProps {
  membership: UserMembership;
  selectedEntity: SelectedEntity;
  onEntityChange(entityUpdate: IdToStore): void;
}

const DrawerHeader: FC<DrawerHeaderProps> = ({ membership, selectedEntity, onEntityChange }) => {
  const selectedEntityOption = useMemo<string>(() => {
    return selectedEntity.type ? encodeEntity(selectedEntity.type, selectedEntity.value.id) : "";
  }, [selectedEntity]);

  const displaySubheaders = useMemo<boolean>(() => {
    const numMembershipTypes = [
      membership.organisations,
      membership.storageLocations,
      membership.carriers,
      membership.suppliers,
    ].filter(m => m.length > 0).length;
    return numMembershipTypes > 1;
  }, [membership]);

  const menuItems = useCallback(
    function menuItems<T>({ membershipList, getName, type, subHeaderName }: MenuItemsProps<T>): JSX.Element[] {
      if (membershipList.length === 0) {
        return [];
      }
      const components: JSX.Element[] = [];
      if (displaySubheaders) {
        components.push(
          <ListSubheader key={`${type}-subheader`} sx={{ color: "black", paddingY: 1 }}>
            <Typography variant="h5">{subHeaderName}</Typography>{" "}
          </ListSubheader>,
        );
      }

      const sortedMembership = sortBy(membershipList, val => getName(val).toLowerCase());
      sortedMembership.forEach((val, i) =>
        components.push(
          <MenuItem value={encodeEntity(type, val.id)} key={`${type}-${i}`}>
            {getName(val)}
          </MenuItem>,
        ),
      );
      return components;
    },
    [displaySubheaders],
  );

  const singleMembershipName = useMemo<string | undefined>(() => {
    const { organisations, storageLocations, carriers, suppliers, purchasers } = membership;
    const hasSingleMembership =
      organisations.length + storageLocations.length + carriers.length + suppliers.length + purchasers.length === 1;
    if (hasSingleMembership) {
      if (organisations.length > 0) {
        return organisations[0].value.businessName;
      } else if (storageLocations.length > 0) {
        return storageLocations[0].value.locationName;
      } else if (carriers.length > 0) {
        return carriers[0].value.name;
      } else if (suppliers.length > 0) {
        return suppliers[0].value.name;
      } else if (purchasers.length > 0) {
        return purchasers[0].value.name;
      } else {
        throw new Error("Unexpected lack of single membership");
      }
    }

    return undefined;
  }, [membership]);

  const handleSelectOptionChange = useCallback(
    (ev: SelectChangeEvent<string>) => {
      const entity = decodeSelectedEntity(ev.target.value);
      if (!entity) {
        return;
      }
      onEntityChange({ kind: entity.type, value: entity.id });
    },
    [onEntityChange],
  );

  return (
    <>
      {singleMembershipName ? (
        <Box sx={{ padding: "20px 16px", borderBottom: theme => `1px solid ${theme.palette.common.grey3}` }}>
          {singleMembershipName}
        </Box>
      ) : (
        <FormControl fullWidth variant="standard">
          <Select
            value={selectedEntityOption}
            onChange={handleSelectOptionChange}
            // Note: Popover menu set as left: 0 !important as material UI keeps adding an inline 16px margin
            MenuProps={{ PaperProps: { sx: { left: "0 !important" } } }}>
            {[
              ...menuItems<Organisation>({
                membershipList: membership.organisations,
                type: "org",
                subHeaderName: "Organisations",
                getName: o => o.value.businessName,
              }),
              ...menuItems<StorageLocation>({
                membershipList: membership.storageLocations,
                type: "storageLoc",
                subHeaderName: "Storage locations",
                getName: o => o.value.locationName,
              }),
              ...menuItems<Carrier>({
                membershipList: membership.carriers,
                type: "carrier",
                subHeaderName: "Carriers",
                getName: o => o.value.name,
              }),
              ...menuItems<Supplier>({
                membershipList: membership.suppliers,
                type: "supplier",
                subHeaderName: "Suppliers",
                getName: o => o.value.name,
              }),
              ...menuItems<Purchaser>({
                membershipList: membership.purchasers,
                type: "purchaser",
                subHeaderName: "Purchasers",
                getName: o => o.value.name,
              }),
            ]}
          </Select>
        </FormControl>
      )}
    </>
  );
};

interface NavMenuItem {
  type: "single";
  title: string;
  route: AppRoutes;
  isActive: boolean;
  Icon: typeof SvgIcon;
}

interface NavMenuGroup {
  type: "group";
  title: string;
  isActive: boolean;
  initialRoute: AppRoutes;
  Icon: typeof SvgIcon;
  submenus: SubMenuItem[];
}

interface SubMenuItem {
  title: string;
  route: AppRoutes;
}

const getOrganisationMenuItems = (currentPath: string, uiConfig: UiConfigValues): (NavMenuItem | NavMenuGroup)[] => {
  const { showProcurement, showNominatedPurchaser, showProductionOrder, showStockMovement, showStockRevaluation } =
    uiConfig;
  const menuItems: (NavMenuItem | NavMenuGroup)[] = [];
  menuItems.push({
    type: "single",
    title: "Dashboard",
    route: AppRoutes.Dashboard,
    isActive: currentPath === AppRoutes.Dashboard,
    Icon: HouseOutlinedIcon,
  });
  menuItems.push({
    type: "single",
    title: "Inventory",
    route: AppRoutes.Inventory,
    isActive: currentPath === AppRoutes.Inventory,
    Icon: Inventory2OutlinedIcon,
  });
  menuItems.push({
    type: "single",
    title: "Sale Orders",
    route: AppRoutes.RepurchaseSaleOrders,
    isActive: currentPath === AppRoutes.RepurchaseSaleOrders,
    Icon: ReceiptLongOutlinedIcon,
  });
  if (showProcurement) {
    menuItems.push({
      type: "single",
      title: "Upload Stock",
      route: AppRoutes.OrganisationNewDealRequests,
      isActive: currentPath.startsWith(AppRoutes.OrganisationNewDealRequests),
      Icon: LocalOfferOutlinedIcon,
    });
  }
  if (showProductionOrder) {
    menuItems.push({
      type: "single",
      title: "Production Orders",
      route: AppRoutes.OrgProductionOrders,
      isActive: currentPath === AppRoutes.OrgProductionOrders,
      Icon: HandymanOutlinedIcon,
    });
  }
  if (showStockMovement) {
    menuItems.push({
      type: "single",
      title: "Stock Movement",
      route: AppRoutes.StockMovement,
      isActive: currentPath.startsWith(AppRoutes.StockMovement),
      Icon: LocalShippingOutlinedIcon,
    });
  }
  if (showStockRevaluation) {
    menuItems.push({
      type: "single",
      title: "Stock Revaluation",
      route: AppRoutes.StockRevaluation,
      isActive: currentPath === AppRoutes.StockRevaluation,
      Icon: StockRevaluationIcon,
    });
  }
  if (showNominatedPurchaser) {
    menuItems.push({
      type: "group",
      title: "Third Party Sales",
      Icon: StorefrontIcon,
      initialRoute: AppRoutes.OrganisationPurchaseRequests,
      isActive:
        currentPath === AppRoutes.OrganisationPurchaseRequests ||
        currentPath === AppRoutes.OrganisationPurchaseRequestByPurchaser,
      submenus: [
        {
          title: "By purchaser",
          route: AppRoutes.OrganisationPurchaseRequestByPurchaser,
        },
      ],
    });
  }
  menuItems.push({
    type: "single",
    title: "Expiring Stock",
    route: AppRoutes.OrganisationExpiringStock,
    isActive: currentPath === AppRoutes.OrganisationExpiringStock,
    Icon: WarningAmberOutlinedIcon,
  });
  return menuItems;
};

const getStorageLocationMenuItems = (currentPath: string, showProcurement: boolean): NavMenuItem[] => {
  const menuItems: NavMenuItem[] = [
    {
      type: "single",
      title: "Production Orders",
      route: AppRoutes.LocProductionOrders,
      isActive: currentPath === AppRoutes.LocProductionOrders || currentPath === AppRoutes.LocExistingProductionOrders,
      Icon: HandymanOutlinedIcon,
    },
    {
      type: "single",
      title: "Product Balances",
      route: AppRoutes.ProductBalances,
      isActive: currentPath === AppRoutes.ProductBalances,
      Icon: Inventory2OutlinedIcon,
    },
  ];

  if (showProcurement) {
    menuItems.push({
      type: "single",
      title: "Shipments",
      route: AppRoutes.LocNewDealRequestsIncoming,
      isActive: currentPath === AppRoutes.LocNewDealRequestsIncoming,
      Icon: LocalShippingRight,
    });
  }

  menuItems.push({
    type: "single",
    title: "Outgoing orders",
    route: AppRoutes.StorageLocationOutgoingOrders,
    isActive: currentPath.startsWith(AppRoutes.StorageLocationOutgoingOrders),
    // @ts-ignore - Could not figure out the typescript acrobatics to make this work
    Icon: LocalShippingLeft,
  });

  return menuItems;
};

const LocalShippingRight = LocalShippingOutlinedIcon;
const LocalShippingLeft = () => (
  <LocalShippingOutlinedIcon
    sx={{
      transform: "matrix(-1, 0, 0, 1, 0, 0)",
    }}
  />
);

const getCarrierMenuItems = (currentPath: string): NavMenuItem[] => {
  return [
    {
      type: "single",
      title: "Collection Orders",
      route: AppRoutes.CarrierRequests,
      isActive: currentPath.startsWith(AppRoutes.CarrierRequests),
      Icon: LocalShippingOutlinedIcon,
    },
  ];
};

const getSupplierMenuItems = (currentPath: string): NavMenuItem[] => {
  return [
    {
      type: "single",
      title: "Purchase Orders",
      route: AppRoutes.SupplierNewDealRequests,
      isActive: currentPath.startsWith(AppRoutes.SupplierNewDealRequests),
      Icon: ReceiptLongOutlinedIcon,
    },
  ];
};

const getPurchaserMenuItems = (currentPath: string): NavMenuItem[] => {
  return [
    {
      type: "single",
      title: "Orders",
      route: AppRoutes.PurchaserPurchaseRequests,
      isActive: currentPath === AppRoutes.PurchaserPurchaseRequests,
      Icon: StorefrontIcon,
    },
  ];
};

interface NavMenuItemsProps {
  selectedEntity: SelectedEntity;
  onItemClicked?: () => void;
}

const NavMenuItems: FC<NavMenuItemsProps> = ({ selectedEntity, onItemClicked }) => {
  const uiConfig = useUiConfig();
  const location = useLocation();
  const history = useHistory();

  const navMenuItems = useMemo(() => {
    if (selectedEntity.type === "org") {
      return getOrganisationMenuItems(location.pathname, uiConfig);
    } else if (selectedEntity.type === "storageLoc") {
      return getStorageLocationMenuItems(location.pathname, uiConfig.showProcurement);
    } else if (selectedEntity.type === "carrier") {
      return getCarrierMenuItems(location.pathname);
    } else if (selectedEntity.type === "supplier") {
      return getSupplierMenuItems(location.pathname);
    } else if (selectedEntity.type === "purchaser") {
      return getPurchaserMenuItems(location.pathname);
    } else {
      return [];
    }
  }, [selectedEntity.type, location.pathname, uiConfig]);

  return (
    <List>
      {navMenuItems.map(value => {
        if (value.type === "single") {
          const { title, Icon, isActive, route } = value;
          return (
            <ListItem key={title} disablePadding>
              <ListItemButton
                onClick={onItemClicked}
                // LinkComponent used underneath needs to be defined here instead of app-theme
                // See: https://github.com/mui/material-ui/issues/29030
                component={LinkBehavior}
                selected={location.pathname === route}
                href={route}
                sx={{ "&.Mui-selected": { backgroundColor: "background.default" } }}>
                <ListItemIcon sx={{ minWidth: "unset", pr: 2, color: "common.darkGrey" }}>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={title} primaryTypographyProps={{ fontWeight: isActive ? 700 : 400 }} />
              </ListItemButton>
            </ListItem>
          );
        } else if (value.type === "group") {
          const { title, Icon, isActive, initialRoute, submenus } = value;
          return (
            <React.Fragment key={title}>
              <ListItem key={title} disablePadding>
                <ListItemButton
                  onClick={() => {
                    history.push(initialRoute);
                    if (onItemClicked) onItemClicked();
                  }}
                  component={LinkBehavior}
                  selected={isActive}
                  href={initialRoute}
                  sx={{ "&.Mui-selected": { backgroundColor: "background.default" } }}>
                  <ListItemIcon sx={{ minWidth: "unset", pr: 2, color: "common.darkGrey" }}>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={title} primaryTypographyProps={{ fontWeight: isActive ? 700 : 400 }} />
                </ListItemButton>
              </ListItem>
              {isActive && (
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {submenus.map((smenu, idx) => {
                      const submenuSelected = location.pathname === smenu.route;
                      return (
                        <ListItemButton
                          key={idx}
                          sx={{ pl: 4 }}
                          href={smenu.route}
                          selected={submenuSelected}
                          onClick={() => {
                            history.push(smenu.route);
                          }}>
                          <ListItemIcon>{submenuSelected && <RightArrowIcon />}</ListItemIcon>
                          <ListItemText primary={smenu.title} />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          );
        }
        return null;
      })}
    </List>
  );
};
