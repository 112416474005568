import { View } from "@react-pdf/renderer";
import { Currency } from "adl-gen/ferovinum/app/db";
import React, { useMemo } from "react";
import { createBigDecimalCurrencyFormatter } from "utils/currency-utils";
import { BlankRow, ColumnLayout, Row, TextLine, rightAlign } from "./pdf-components";
import { ContractNoteView, ContractNoteViewLineItem } from "adl-gen/ferovinum/app/views";

const useCurrencyFormatterState = ({
  currency,
  maximumFractionDigits,
}: {
  currency: Currency;
  maximumFractionDigits?: number;
}) => {
  const currencyFormatter = createBigDecimalCurrencyFormatter(currency);
  const dealCurrencyFormatter = useMemo(
    () =>
      currencyFormatter({
        currency: currency,
        rounding: {
          kind: "rounded",
          minimumFractionDigits: 2,
          maximumFractionDigits: maximumFractionDigits ?? 2,
        },
      }),
    [currency, currencyFormatter, maximumFractionDigits],
  );

  return { dealCurrencyFormatter };
};

function useProductTableInfo(contractNote: ContractNoteView) {
  const { grossTotal, grossDepositTotal, depositCreditTotal, netAdvanceAmount } = contractNote;
  const totalDepositPayable = Number(grossDepositTotal) - Number(depositCreditTotal);
  const { dealCurrencyFormatter } = useCurrencyFormatterState({
    currency: contractNote.dealCurrency,
  });

  const columns = useProductTableColumns({
    lineItems: contractNote.lineItems,
    settlementCurrency: contractNote.dealCurrency,
  });

  return {
    columns,
    grossAmountPayable: dealCurrencyFormatter.format(grossTotal),
    totalDepositPayable: dealCurrencyFormatter.format(`${totalDepositPayable}`),
    netAdvanceAmount: dealCurrencyFormatter.format(`${netAdvanceAmount}`),
  };
}

export function ContractNoteProductTable(props: { contractNote: ContractNoteView }) {
  const { contractNote } = props;
  const { columns, grossAmountPayable, totalDepositPayable, netAdvanceAmount } = useProductTableInfo(contractNote);
  const { lineItems } = contractNote;
  const colCount = columns.length;

  return (
    <ColumnLayout columnSpacing={columns.map(c => c.width)}>
      <View fixed>
        <BlankRow />
        <Row cells={columns.map(c => c.name)} topBorder bottomBorder verticalBorder fontFamily="Helvetica-Bold" />
      </View>
      {lineItems.map((li, idx) => (
        <Row key={idx} verticalBorder cells={columns.map(c => `${c.getter(li)}`)} />
      ))}
      <Row
        verticalBorder
        topBorder
        fontFamily="Helvetica-Bold"
        cells={[rightAlign("Gross Purchase Price payable by Ferovinum"), grossAmountPayable]}
        cellSpans={[colCount - 1, 1]}
      />
      <Row
        verticalBorder
        topBorder
        fontFamily="Helvetica-Bold"
        cells={[rightAlign("Total Deposit payable by Client"), totalDepositPayable]}
        cellSpans={[colCount - 1, 1]}
      />
      <Row
        verticalBorder
        topBorder
        bottomBorder
        fontFamily="Helvetica-Bold"
        cells={[rightAlign("Net Amount payable by Ferovinum"), netAdvanceAmount]}
        cellSpans={[colCount - 1, 1]}
      />
    </ColumnLayout>
  );
}

export function ContractNotePlainTextProductTable(props: { contractNote: ContractNoteView }) {
  const { contractNote } = props;
  const { columns, grossAmountPayable, totalDepositPayable, netAdvanceAmount } = useProductTableInfo(contractNote);
  const { lineItems } = contractNote;

  const { header, body } = useMemo(() => {
    function formatRow(getText: (col: ColumnSpec) => string) {
      const cells = columns.map(col => {
        const text = getText(col);
        const width = col.width;
        const padFront = text.length < width - 1 ? " " + text : text;
        return padFront.padEnd(width).substring(0, width);
      });
      return ["", ...cells, ""].join("|");
    }

    const header = formatRow(col => col.name);
    const body = lineItems.map(li => formatRow(col => col.getter(li))).join("\n");
    return { header, body };
  }, [lineItems, columns]);

  const totalColWidth = columns.reduce((acc, col) => acc + col.width, 0);
  const valueColWidth = columns[columns.length - 1].width - 1;
  const labelColWidth = totalColWidth - valueColWidth + columns.length - 5;

  function formatSummaryRow(label: string, value: string) {
    const labelContent = label.padStart(labelColWidth).substring(0, labelColWidth);
    const valueContent = value.padEnd(valueColWidth).substring(0, valueColWidth);
    return `| ${labelContent} | ${valueContent}|`;
  }

  return (
    <>
      <View fixed>
        <BlankRow />
        <TextLine fontFamily="Courier-Bold">{header}</TextLine>
      </View>
      <TextLine fontFamily="Courier">{body}</TextLine>
      <TextLine fontFamily="Courier">{"|".padEnd(header.trim().length - 1) + "|"}</TextLine>
      <TextLine fontFamily="Courier-Bold">
        {formatSummaryRow("Gross Purchase Price payable by Ferovinum", grossAmountPayable)}
      </TextLine>
      <TextLine fontFamily="Courier-Bold">
        {formatSummaryRow("Total Deposit payable by Client", totalDepositPayable)}
      </TextLine>
      <TextLine fontFamily="Courier-Bold">
        {formatSummaryRow("Net Amount payable by Ferovinum", netAdvanceAmount)}
      </TextLine>
    </>
  );
}

interface ColumnSpec {
  name: string;
  width: number;
  getter: (p: ContractNoteViewLineItem) => string;
}

function useProductTableColumns(params: {
  lineItems: ContractNoteViewLineItem[];
  settlementCurrency: Currency;
}): ColumnSpec[] {
  const { lineItems, settlementCurrency } = params;
  const { dealCurrencyFormatter } = useCurrencyFormatterState({
    currency: settlementCurrency,
    maximumFractionDigits: 4,
  });
  return useMemo(() => {
    const isCask = lineItems[0].product.unitType === "cask";
    const hasFreeUnits = lineItems.some(p => p.numberOfFreeUnits.value > 0);
    const columns: (ColumnSpec | null)[] = [
      {
        name: "Product Code",
        width: 30,
        getter: p => {
          return p.product.code;
        },
      },
      {
        name: "Producer",
        width: 33,
        getter: p => {
          return p.product.producerName;
        },
      },
      {
        name: isCask ? "Distil. Yr" : "Product Yr",
        width: 10,
        getter: p => {
          const { productDate } = p.product;
          return productDate.kind === "nonVintage" ? "NV" : productDate.value.toString();
        },
      },
      {
        name: "ABV",
        width: 7,
        getter: p => {
          return `${p.product.alcoholByVolumePc}%`;
        },
      },
      isCask
        ? null
        : {
            name: "Size (cL)",
            width: 9,
            getter: p => {
              const { vesselSize } = p.product;
              switch (vesselSize?.kind) {
                case "centilitres":
                  return `${vesselSize.value}`;
                case "case":
                  return `${vesselSize.value.numberOfSingles} x ${vesselSize.value.centilitresPerSingle}`;
                default:
                  return "N/A";
              }
            },
          },
      {
        name: isCask ? "LPA" : "Quantity",
        width: 9,
        getter: p => {
          return p.totalNumberOfUnits.value.toString();
        },
      },
      hasFreeUnits
        ? {
            name: `Free ${isCask ? "LPA" : "Qty"}`,
            width: 9,
            getter: p => {
              return p.numberOfFreeUnits.value.toString();
            },
          }
        : null,
      {
        name: "Purchase Price",
        width: 14,
        getter: p => {
          return dealCurrencyFormatter.format(p.purchasePrice);
        },
      },
      {
        name: `Deposit / ${isCask ? "LPA" : "Btl"}`,
        width: 14,
        getter: p => {
          return p.depositPrice ? dealCurrencyFormatter.format(p.depositPrice) : "N/A";
        },
      },
      {
        name: `Net Sale Price / ${isCask ? "LPA" : "Btl"}`,
        width: 20,
        getter: p => {
          const purchasePrice = p.purchasePrice;
          if (purchasePrice === undefined) {
            return "N/A";
          } else {
            const depositPrice = p.depositPrice ? parseFloat(p.depositPrice) : 0;
            return dealCurrencyFormatter.format(`${parseFloat(purchasePrice) - depositPrice}`);
          }
        },
      },
    ];
    return columns.filter(nonNulLGuard);
  }, [dealCurrencyFormatter, lineItems]);
}

function nonNulLGuard<T>(n: T | null): n is T {
  return n !== null;
}
