import React, { useEffect } from "react";
import { LoginState } from "../../../app/identity-state";
import { LoadingBackdrop } from "components/widgets/loader/loading-backdrop";

export interface LogoutPageProps {
  loginState: LoginState;
}
export const LogoutPage = ({ loginState }: LogoutPageProps) => {
  useEffect(() => {
    loginState.onLogout();
  }, [loginState]);

  /** Render a loading */
  return <LoadingBackdrop open={true} />;
};
