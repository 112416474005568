import React, { MouseEventHandler } from "react";
import Button from "@mui/material/Button";
export interface DownloadPdfButtonProps {
  getDownloadUrl: () => Promise<string | undefined>;
  buttonText?: string;
}

export const DownloadPdfButton = ({ getDownloadUrl, buttonText }: DownloadPdfButtonProps) => {
  const buttonDisplayText = buttonText ? buttonText : "Download pdf";

  const viewContractNote: MouseEventHandler = async e => {
    e.stopPropagation();
    e.preventDefault();

    const downloadUrl = await getDownloadUrl();
    if (downloadUrl) {
      window.open(downloadUrl, "_blank");
    } else {
      // eslint-disable-next-line no-console
      console.error("Unable to get pdf download url");
    }
    return;
  };

  return (
    <Button variant="outlined" onClick={viewContractNote}>
      {buttonDisplayText}
    </Button>
  );
};
