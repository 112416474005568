type Loading = { state: "loading" };
type Success<T> = { state: "success"; value: T };
type Errored = { state: "error"; error: Error };
export type LoadingValue<T> = Success<T> | Loading | Errored;

export function assertValueLoaded<T>(val: LoadingValue<T>): T {
  if (!isLoaded(val)) {
    throw new Error("Value has not loaded");
  }

  return val.value;
}

export function isLoaded<T>(val: LoadingValue<T>): val is Success<T> {
  return val.state === "success";
}

export function isLoading<T>(val: LoadingValue<T>): val is Loading {
  return val.state === "loading";
}

export function isErrored<T>(val: LoadingValue<T>): val is Errored {
  return val.state === "error";
}

export function loaded<T>(value: T): Success<T> {
  return { state: "success", value };
}
export function loading(): Loading {
  return { state: "loading" };
}

export type KeyByType<T, U, K extends keyof T = keyof T> = K extends keyof T
  ? T[K] extends U | null | undefined
    ? K
    : never
  : never;

export type UnionType = { kind: string; value: unknown } | { kind: string };

export type NestedPartial<T extends object> = Partial<{ [K in keyof T]: Partial<T[K]> }>;

// Utility type to ensure that one type is a subset of another
export type Subset<T, U extends { [K in keyof T]: unknown }> = {
  [K in keyof U]: K extends keyof T ? U[K] : never;
};

export type MapKeys<From, To extends { [K in keyof From]: unknown }> = Subset<From, To>;

export type MatchKeys<From, To extends Subset<{ [K in keyof From]: unknown }, To>> = To;
