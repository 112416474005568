import { Box, Divider, Card as MUICard, Stack, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { Link } from "components/widgets/link/link";
import React from "react";

export interface CardProps {
  header: {
    title: string;
    action?: {
      label: string;
      onClick: () => void;
    };
  };
  body: React.ReactNode;
  sx?: SxProps<Theme>;
}

export const Card = ({ header, body, sx }: CardProps) => {
  const { title, action } = header;
  return (
    <MUICard title={title} sx={sx}>
      <Stack divider={<Divider />} height={"100%"}>
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
          padding={2}>
          <Typography variant="caption" padding={0} color="common.grey5" lineHeight={"14px"}>
            {title}
          </Typography>
          {action && (
            <Box padding={0}>
              <Link
                variant="small"
                color={"grey5"}
                onClick={action.onClick}
                typographyProps={{ fontSize: 12, lineHeight: "14px" }}>
                {action.label}
              </Link>
            </Box>
          )}
        </Stack>
        <Stack spacing={3} padding={2}>
          {body}
        </Stack>
      </Stack>
    </MUICard>
  );
};

/**
 * Given an array of string, filter out null and empty strings.
 * Then stack then by adding a <br /> between each string.
 * If the array is empty, return null.
 */
export const stackStrings = (arr: (string | null | undefined)[]) => {
  const values = arr.filter(item => typeof item === "string" && item.trim() !== "");
  if (values.length === 0) return null;

  return (
    <>
      {values.map((item, index) => (
        <React.Fragment key={index}>
          {item}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};

/**
 * Wrapper element for a cards subtitle and it's content.
 * If the content is null, then this will return null/not render.
 */
export const CardText = ({
  title,
  content,
  lineNumbers = 1,
}: {
  title: string;
  content: React.ReactNode | string;
  lineNumbers?: number;
}) => {
  const minHeight = lineNumbers * 20 + (lineNumbers - 1) * 8;
  return (
    <Stack spacing={1}>
      <Typography variant="caption" color="common.grey6">
        {title}
      </Typography>
      <Box minHeight={minHeight}>{typeof content === "string" ? <Typography>{content}</Typography> : content}</Box>
    </Stack>
  );
};
