import React, { useCallback, useContext } from "react";
import { Box, Drawer, drawerClasses, IconButton, Stack } from "@mui/material";
import { HEADER_HEIGHT } from "../header/header";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Loader } from "components/widgets/loader/loader";
import { NAVIGATION_DRAWER_WIDTH } from "../navigation-drawer/navigation-drawer";

const INFO_DRAWER_WIDTH = 500;
export const InfoDrawer = () => {
  const { children, isShown, close, isExpanded } = useContext(InfoDrawerContext);
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={isShown}
      onClose={close}
      sx={{
        flexShrink: 0,
        [`& .${drawerClasses.paper}`]: {
          top: HEADER_HEIGHT,
          overflowY: isExpanded ? "auto" : "hidden",
          height: `calc(100% - ${HEADER_HEIGHT}px)`,
          width: isExpanded ? `calc(100vw - ${NAVIGATION_DRAWER_WIDTH}px)` : INFO_DRAWER_WIDTH,
          boxSizing: "border-box",
          padding: 4,
          ...(isExpanded && {
            transition: theme =>
              `${theme.transitions.create("width", {
                easing: theme.transitions.easing.easeOut,
              })} !important`,
          }),
        },
      }}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={close} sx={{ color: "common.grey7", marginBottom: 2 }}>
          <CloseOutlinedIcon color="inherit" />
        </IconButton>
      </Box>
      <Stack flex={1} direction="column">
        {children}
      </Stack>
    </Drawer>
  );
};

interface InfoDrawerProps {
  children?: React.ReactNode;
}
interface InfoDrawerManagerProps extends InfoDrawerProps {
  isShown: boolean;
  isExpanded: boolean;
}
const DEFAULT_INFO_DRAWER_MANAGER_VALUES: InfoDrawerManagerProps = {
  children: <Loader loadingStates={[]} />,
  isShown: false,
  isExpanded: false,
};

interface InfoDrawerContextProps extends InfoDrawerManagerProps {
  open: (props: InfoDrawerProps) => void;
  close: () => void;
  expand: () => void;
}

export const InfoDrawerContext = React.createContext<InfoDrawerContextProps>({
  ...DEFAULT_INFO_DRAWER_MANAGER_VALUES,
  expand: () => null,
  close: () => null,
  open: _ => null,
});

interface OpenAction {
  kind: "open";
  children: React.ReactNode;
}
interface CloseAction {
  kind: "close";
}
interface ExpandAction {
  kind: "expand";
}
type Action = OpenAction | CloseAction | ExpandAction;
function drawerAction(state: InfoDrawerManagerProps, action: Action) {
  switch (action.kind) {
    case "open":
      return { ...state, children: action.children, isShown: true, isExpanded: false };
    case "expand":
      if (state.isShown) {
        return { ...state, isExpanded: true };
      }
  }
  return { ...DEFAULT_INFO_DRAWER_MANAGER_VALUES };
}

export const InfoDrawerManager = ({ children }: { children: React.ReactNode }) => {
  const [action, dispatchAction] = React.useReducer(drawerAction, DEFAULT_INFO_DRAWER_MANAGER_VALUES);

  const openDrawer = useCallback((props: InfoDrawerProps) => {
    dispatchAction({ kind: "open", children: props.children });
  }, []);

  const closeDrawer = useCallback(() => {
    dispatchAction({ kind: "close" });
  }, []);

  const expandDrawer = useCallback(async () => {
    dispatchAction({ kind: "expand" });
  }, []);

  return (
    <InfoDrawerContext.Provider
      value={{
        ...action,
        open: openDrawer,
        close: closeDrawer,
        expand: expandDrawer,
      }}>
      {children}
    </InfoDrawerContext.Provider>
  );
};

type InfoDrawerHookReturnValues = () => [(props: InfoDrawerProps) => void, () => void, () => void, boolean];
export const useInfoDrawer: InfoDrawerHookReturnValues = () => {
  const { open, close, expand, isExpanded } = useContext(InfoDrawerContext);
  return [open, close, expand, isExpanded];
};
