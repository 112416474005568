import React, { useCallback, useRef, useState } from "react";
import { Loader } from "components/widgets/loader/loader";
import {
  SelectedHoldingAdjustmentFormValues,
  StorageLocationStockAdjustmentSetupPageView,
} from "./storage-location-stock-adjustment-setup-page-view";
import { Product, TopLevelUnitType } from "adl-gen/ferovinum/app/db";
import { WithDbId } from "adl-gen/common/db";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { isLoaded } from "utils/utility-types";
import { useAppService } from "../../../../../hooks/use-app-service";
import { assertNotUndefined } from "utils/hx/util/types";
import { useSelectedStorageLocation } from "../../../../layouts/portal-page-layout/portal-page";
import { adlMapToMap } from "utils/adl-utils";
import {
  StorageLocationProductHolding,
  StorageLocationProductHolding_WithRotationNumber,
} from "adl-gen/ferovinum/app/api";
import { isSinglesUnitType, numberOfUnitsForUnitType } from "utils/model-utils";
import { useHistory, useParams } from "react-router-dom";
import { AppRoutes } from "../../../../../app/app-routes";
import { NonReimbursedStockAdjustment } from "../../../../../hooks/use-stock-adjustment";

export function holdingHasRotationNumber(
  holding: StorageLocationProductHolding,
): holding is StorageLocationProductHolding_WithRotationNumber {
  return holding.kind === "withRotationNumber";
}

export type StockAdjustmentsFlowState = (
  | {
      variant: "positiveStockAdjustment";
    }
  | {
      variant: "negativeStockAdjustment";
      reimbursed?: boolean;
    }
) & {
  topLevelUnitType: TopLevelUnitType;
  stockAdjustments: Map<WithDbId<Product>, NonReimbursedStockAdjustment[]>;
  reason?: string;
  referenceNumber?: string;
};

export const StorageLocStockAdjustmentSetupPage = () => {
  const { variant } = useParams<{ variant: "positiveStockAdjustment" | "negativeStockAdjustment" }>();
  const history = useHistory();
  const service = useAppService();
  const selectedStorageLocationId = assertNotUndefined(useSelectedStorageLocation()).id;
  const [searchTerm, setSearchTerm] = useState<string>();
  const initialFetch = useRef(false);
  const [availableStockTypes, setAvailableStockTypes] = useState<Set<TopLevelUnitType>>();
  const onProductSearch = (searchTerm: string) => setSearchTerm(searchTerm ?? undefined);

  const loadProductHoldings = useCallback(async () => {
    const resp = adlMapToMap(
      await service.getStorageLocationProductHoldings({
        storageLocationId: selectedStorageLocationId,
        searchTerm: searchTerm || null,
      }),
    );

    // First fetch initializations
    // TODO: Maybe move into a separate fetch
    if (!initialFetch.current) {
      initialFetch.current = true;
      const newAvailableStockTypes = new Set<TopLevelUnitType>(
        [...resp.keys()].filter(unitType => {
          // Only support positive adjustments for singles
          if (variant === "positiveStockAdjustment") {
            return isSinglesUnitType(unitType) && (resp.get(unitType)?.length ?? 0) > 0;
          }
          return resp.get(unitType)?.length ?? 0 > 0;
        }),
      );
      setAvailableStockTypes(newAvailableStockTypes);
    }
    return resp;
  }, [searchTerm, selectedStorageLocationId, service, variant]);
  const [loadingProductHoldings] = useLoadingDataState(loadProductHoldings);

  const onSubmit = async (formValues: SelectedHoldingAdjustmentFormValues, topLevelUnitType: TopLevelUnitType) => {
    if (isLoaded(loadingProductHoldings)) {
      const stockAdjustments: Map<WithDbId<Product>, NonReimbursedStockAdjustment[]> = new Map(
        formValues.selectedProducts.map(selectedProduct => {
          return [
            selectedProduct.product,
            selectedProduct.adjustments.map(adjustment => {
              return {
                numberOfUnits: numberOfUnitsForUnitType(
                  selectedProduct.product.value.unitType,
                  Number(adjustment.unitsToAdjust),
                ),
                rotationNumber: adjustment.rotationNumber || null,
              };
            }),
          ];
        }),
      );

      const state: StockAdjustmentsFlowState = {
        topLevelUnitType,
        variant,
        stockAdjustments,
      };
      history.push(AppRoutes.StorageLocStockAdjustmentSettings, state);
    }
  };

  return (
    <Loader loadingStates={[loadingProductHoldings]} fullScreen>
      {isLoaded(loadingProductHoldings) && availableStockTypes && (
        <StorageLocationStockAdjustmentSetupPageView
          variant={variant}
          availableStockTypes={availableStockTypes}
          productHoldings={loadingProductHoldings.value}
          onSubmit={onSubmit}
          onProductSearch={onProductSearch}
        />
      )}
    </Loader>
  );
};
