import { assertNotUndefined } from "utils/hx/util/types";
import React, { useCallback } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../../../app/app-routes";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { Loader } from "components/widgets/loader/loader";
import { isLoaded } from "utils/utility-types";
import { NewDealRequestFlowState } from "../organisation-create-new-deal-request/organisation-create-new-deal-request-page";
import {
  OrganisationNewDealRequestDeliveryOptionsPageView,
  NewDealRequestDeliveryOptionsFormValues,
} from "./organisation-new-deal-request-delivery-options-page-view";

export const OrganisationNewDealRequestDeliveryOptionsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const service = useAppService();
  const organisationId = assertNotUndefined(useSelectedOrgId());

  const purchaseOrderFlowState = location.state as NewDealRequestFlowState | undefined;

  const loadCarriers = useCallback(async () => {
    return await service.getCarriers({ kind: "all" });
  }, [service]);

  const loadStorageLocations = useCallback(async () => {
    return await service.getStorageLocations({
      orgId: organisationId,
      selector: { kind: "associated" },
    });
  }, [organisationId, service]);

  const [loadingCarriers] = useLoadingDataState(loadCarriers);
  const [loadingStorageLocations] = useLoadingDataState(loadStorageLocations);

  const onNext = useCallback(
    (formValues: NewDealRequestDeliveryOptionsFormValues) => {
      if (formValues.selectedCarrier && formValues.selectedStorageLocation) {
        history.push(AppRoutes.NewDealRequestConfirmation, {
          ...purchaseOrderFlowState,
          carrier: formValues.selectedCarrier,
          storageLocation: formValues.selectedStorageLocation,
          incoterms: formValues.selectedIncoterms,
        });
      }
    },
    [history, purchaseOrderFlowState],
  );

  const onBack = useCallback(
    (formValues: NewDealRequestDeliveryOptionsFormValues) => {
      if (purchaseOrderFlowState?.variant === "newStock") {
        const state: NewDealRequestFlowState = {
          ...assertNotUndefined(purchaseOrderFlowState),
          carrier: formValues.selectedCarrier,
          storageLocation: formValues.selectedStorageLocation,
          incoterms: formValues.selectedIncoterms,
        };
        history.push(AppRoutes.NewDealRequestSetup, state);
      }
    },
    [purchaseOrderFlowState, history],
  );

  if (purchaseOrderFlowState === undefined || purchaseOrderFlowState.variant === "existingStock") {
    return <Redirect to={AppRoutes.Index} />;
  } else {
    return (
      <Loader fullScreen loadingStates={[loadingCarriers, loadingStorageLocations]}>
        {isLoaded(loadingCarriers) && isLoaded(loadingStorageLocations) && (
          <OrganisationNewDealRequestDeliveryOptionsPageView
            carriers={loadingCarriers.value}
            storageLocations={loadingStorageLocations.value}
            onNext={onNext}
            onBack={onBack}
            initialValues={purchaseOrderFlowState}
          />
        )}
      </Loader>
    );
  }
};
