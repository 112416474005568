import { Instant } from "adl-gen/common";
import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { AppRoutes } from "../../../../../../app/app-routes";
import { formatDate, formatInstant } from "utils/date-utils";
import {
  PortalPageContentHeader,
  SubtitleProps,
} from "../../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../../layouts/portal-page-content/portal-page-content";
import { createBigDecimalCurrencyFormatter } from "utils/currency-utils";
import { useSettlementCurrency } from "../../../../../layouts/portal-page-layout/portal-page";
import { useConfirmationDialog } from "components/context/global-dialog/use-dialog";
import { Link } from "components/widgets/link/link";
import { FinishingServiceSummaryCard } from "../../../../../widgets/production-orders/finishing-service-summary-card/finishing-service-summary-card";
import { ProductionOrderTotalPriceCard } from "../../../../../widgets/production-orders/production-order-total-price-card/production-order-total-price-card";
import { NotesStickyNote } from "../../../../../widgets/sticky-note/notes-sticky-note/notes-sticky-note";
import { OrganisationInnerProductionOrderDetailsPageViewProps } from "../organisation-production-order-details-page";

export type OrganisationProductionOrderCreatedPageViewProps = OrganisationInnerProductionOrderDetailsPageViewProps & {
  onCancel?: () => Promise<void>;
};

export const OrganisationProductionOrderCreatedPageView = (props: OrganisationProductionOrderCreatedPageViewProps) => {
  const { notes, onCancel, productionOrderTasks, purchaseRequestReference } = props;
  const settlementCurrency = useSettlementCurrency();
  const currencyFormatter = createBigDecimalCurrencyFormatter(settlementCurrency)();

  return (
    <PortalPageContent header={<OrganisationProductionOrderCreatedHeader {...props} />}>
      <Grid container spacing={4}>
        {purchaseRequestReference && (
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Typography>Linked to purchase request</Typography>
              <Link
                variant="big"
                href={`${AppRoutes.OrganisationPurchaseRequestDetails}/${purchaseRequestReference.purchaseRequestId}`}>
                {purchaseRequestReference.purchaseRequestNumber}
              </Link>
            </Stack>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack spacing={2}>
            {productionOrderTasks.map(
              ({
                finishingService,
                targetQuantity,
                budgetQuantity,
                sourceProduct,
                finishingProduct,
                pricePerUnit,
                setupCost,
              }) => (
                <FinishingServiceSummaryCard
                  key={`${finishingService.code}-${sourceProduct.id}-${finishingProduct.code}`}
                  serviceName={finishingService.name}
                  targetQuantity={targetQuantity.value}
                  budgetQuantity={budgetQuantity?.value}
                  sourceProduct={sourceProduct.value}
                  finishingProduct={finishingProduct}
                  latestStatus={null}
                  additionalHeaderLabels={[
                    {
                      label: "Price per unit",
                      value: currencyFormatter.format(pricePerUnit),
                      textAlign: "right",
                      mr: 5,
                    },
                    { label: "Setup cost", value: currencyFormatter.format(setupCost), textAlign: "right" },
                  ]}
                />
              ),
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ProductionOrderTotalPriceCard
            {...props}
            setupCost={props.totalSetupCost}
            currency={settlementCurrency}
            productionOrderTasksCosts={productionOrderTasks.map(poTask => {
              return {
                ...poTask,
                targetQuantity: poTask.targetQuantity.value,
                budgetQuantity: poTask.budgetQuantity?.value,
              };
            })}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {notes && <NotesStickyNote notes={notes} />}
        </Grid>

        {onCancel && (
          <Grid item xs={12}>
            <ActionButtons onCancelOrder={onCancel} />
          </Grid>
        )}
      </Grid>
    </PortalPageContent>
  );
};

interface OrganisationProductionOrderCreateHeaderProps {
  orderNumber: string;
  orderDate: string;
  storageLocationName: string;
  requestedCompletionDate?: string;
  cancelledAt?: Instant;
}
const OrganisationProductionOrderCreatedHeader = ({
  orderNumber,
  orderDate,
  storageLocationName,
  requestedCompletionDate,
  cancelledAt,
}: OrganisationProductionOrderCreateHeaderProps) => {
  const subtitles: SubtitleProps[] = useMemo(() => {
    const defaultSubtitles: SubtitleProps[] = [
      { text: `Ordered from ${storageLocationName} on ${formatDate(new Date(orderDate))}`, icon: "receipt" },
    ];
    if (requestedCompletionDate) {
      defaultSubtitles.push({
        text: `Requested completion date: ${formatDate(new Date(requestedCompletionDate))}`,
        icon: "date",
      });
    }
    if (cancelledAt) {
      return defaultSubtitles.concat({
        text: `Cancelled on ${formatInstant(cancelledAt)}`,
        component: { type: "chip", color: "error" },
      });
    }
    return defaultSubtitles.concat({
      text: "Pending acceptance",
      component: { type: "chip", color: "warning" },
    });
  }, [cancelledAt, orderDate, requestedCompletionDate, storageLocationName]);

  return <PortalPageContentHeader title={`Production order reference number: ${orderNumber}`} subtitles={subtitles} />;
};

const ActionButtons = ({ onCancelOrder }: { onCancelOrder: () => Promise<void> }) => {
  const { showConfirmationDialog } = useConfirmationDialog();

  return (
    <Button
      variant="outlined"
      onClick={async () =>
        await showConfirmationDialog({
          title: "Are you sure you want to cancel this order?",
          confirmAction: {
            onClick: onCancelOrder,
          },
          cancelAction: {
            title: "Back",
          },
        })
      }>
      Cancel Order
    </Button>
  );
};
