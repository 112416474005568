import React, { SyntheticEvent } from "react";
import {
  StorageLocationOutgoingOrderFilter,
  StorageLocationOutgoingOrderView,
  valuesStorageLocationOutgoingOrderFilter,
} from "adl-gen/ferovinum/app/api";
import { DbKey } from "adl-gen/common/db";
import { PurchaseRequest } from "adl-gen/ferovinum/app/db";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { Alert, Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { ActionableSummaryCard } from "../../../../widgets/common/actionable-summary-card/actionable-summary-card";
import { AppRoutes } from "../../../../../app/app-routes";
import { OutgoingOrderProductTable } from "../../../../widgets/purchase-requests/outgoing-order-product-table/outgoing-order-product-table";
import { assertNever } from "utils/hx/util/types";
import { formatDateToLocalDate, formatLocalDate } from "utils/date-utils";
import { InfoField } from "components/widgets/info-field/info-field";
import { isLoaded, isLoading, LoadingValue } from "utils/utility-types";
import { Loader } from "components/widgets/loader/loader";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { LocalDate } from "adl-gen/common";
import { ButtonWithDatePickerDialog } from "components/widgets/buttons/button-with-date-picker-dialog";
export interface OutgoingOrdersViewProps {
  filter: StorageLocationOutgoingOrderFilter;
  orders: LoadingValue<StorageLocationOutgoingOrderView[]>;
  updateFilter(filter: StorageLocationOutgoingOrderFilter): void;
  markAsCollected(id: DbKey<PurchaseRequest>, collectedDate: LocalDate): Promise<void>;
  markAsDelivered(id: DbKey<PurchaseRequest>, deliveredDate: LocalDate): Promise<void>;
}

export const OutgoingOrdersPageView = (props: OutgoingOrdersViewProps) => {
  const { filter, orders, updateFilter } = props;
  const handleUpdateFilter = (_e: SyntheticEvent, val: StorageLocationOutgoingOrderFilter) => {
    if (isLoading(orders)) {
      return;
    }

    updateFilter(val);
  };

  return (
    <PortalPageContent header={<PortalPageContentHeader title="Outgoing Orders" />}>
      <Stack spacing={2}>
        <Box>
          <Tabs value={filter} onChange={handleUpdateFilter}>
            {valuesStorageLocationOutgoingOrderFilter.map(filter => (
              <Tab key={filter} label={filter} value={filter} />
            ))}
          </Tabs>
        </Box>
        <Loader loadingStates={[orders]}>
          {isLoaded(orders) &&
            (orders.value.length === 0 ? (
              <Alert severity="info">{"There are no outgoing orders to view."}</Alert>
            ) : (
              <OrderCards {...props} orders={orders.value} />
            ))}
        </Loader>
      </Stack>
    </PortalPageContent>
  );
};
const OutgoingOrderDetailsHeader = (order: StorageLocationOutgoingOrderView) => {
  const createdAt = formatLocalDate(order.createdAt);
  let actionTextPresent, actionTextPast: string;
  if (order.purchaseRequestDeliveryOption.kind === "collectFromStorageLocation") {
    actionTextPresent = "Ready for collection on";
    actionTextPast = "Collected at";
  } else if (order.purchaseRequestDeliveryOption.kind === "deliveryToNominatedPurchaser") {
    actionTextPresent = "Ready for delivery on";
    actionTextPast = "Delivered at";
  } else {
    assertNever(order.purchaseRequestDeliveryOption);
  }

  let stateText: string;
  if (order.status === "created" || order.status === "readyForCollection") {
    stateText = `${actionTextPresent} ${formatLocalDate(order.readyForCollectionAt)}`;
  } else if (order.status === "collected") {
    stateText = `${actionTextPast} ${formatLocalDate(order.collectedAt)}`;
  } else if (order.status === "delivered" && order.deliveredAt !== null) {
    stateText = `${actionTextPast} ${formatLocalDate(order.deliveredAt)}`;
  } else {
    throw new Error(`unexpected order ${JSON.stringify(order)}`);
  }

  return (
    <>
      <InfoField sx={{ alignItems: "flex-end" }} label="Created on" value={createdAt} textAlign="right" />
      <Typography color="common.grey6" sx={{ alignItems: "flex-end" }} variant="subtitle1Bold">
        {stateText}
      </Typography>
    </>
  );
};

interface OrderCardsProps extends Omit<OutgoingOrdersViewProps, "orders"> {
  orders: StorageLocationOutgoingOrderView[];
}
const OrderCards = (props: OrderCardsProps) => {
  const { orders } = props;

  return (
    <>
      {orders.map(order => {
        const isNotDelivered = order.status !== "delivered";
        return (
          <ActionableSummaryCard
            key={order.id}
            title={order.purchaseRequestNumber}
            titleHref={`${AppRoutes.StorageLocationOutgoingOrderDetails}/${order.id}`}
            expandText={"Products in this order"}
            isInitiallyExpanded={isNotDelivered}
            isLoading={false}
            cardContent={<OutgoingOrderProductTable lineItems={order.lineItems} />}
            cardActions={
              <OrderCardAction
                order={order}
                markAsCollected={props.markAsCollected}
                markAsDelivered={props.markAsDelivered}
              />
            }
            headerContent={<OutgoingOrderDetailsHeader {...order} />}
          />
        );
      })}
    </>
  );
};

const OrderCardAction = ({
  order,
  markAsCollected,
  markAsDelivered,
}: {
  order: StorageLocationOutgoingOrderView;
  markAsCollected: (id: DbKey<PurchaseRequest>, collectedDate: LocalDate) => Promise<void>;
  markAsDelivered: (id: DbKey<PurchaseRequest>, deliveredDate: LocalDate) => Promise<void>;
}) => {
  switch (order.status) {
    case "readyForCollection":
      return (
        <ButtonWithDatePickerDialog
          buttonLabel="Mark as collected"
          dateFieldLabel="Collected at"
          minDate={new Date(order.readyForCollectionAt)}
          maxDate={new Date()}
          onConfirm={date => markAsCollected(order.id, formatDateToLocalDate(date))}
        />
      );
    case "collected":
      return (
        <ButtonWithDatePickerDialog
          buttonLabel="Mark as delivered"
          dialogTitle={"Mark as delivered"}
          dateFieldLabel="Delivered at"
          minDate={new Date(order.collectedAt)}
          maxDate={new Date()}
          onConfirm={date => markAsDelivered(order.id, formatDateToLocalDate(date))}
        />
      );
    default:
      return null;
  }
};
