import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Button,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { BigDecimal } from "adl-gen/common";
import { WithDbId } from "adl-gen/common/db";
import {
  NominatedPurchaserDetailsView,
  ProductSalePriceInfo,
  ThirdPartySaleFinishableProductListing,
  ThirdPartySaleFinishedProductListing,
} from "adl-gen/ferovinum/app/api";
import { Currency, PurchaseRequestSalePriceType, StorageLocation } from "adl-gen/ferovinum/app/db";
import { FEROVINUM_CONTACT_URL } from "components/assets/url";
import { useConfirmationDialog } from "components/context/global-dialog/use-dialog";
import { PurchaseOrderUploadCard } from "components/library/widgets/wizard-card/purchase-order-upload-card";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import { InfoTooltip } from "components/widgets/info-tooltip/info-tooltip";
import { RadioGroup } from "components/widgets/inputs/radio-group/radio-group";
import { Link } from "components/widgets/link/link";
import { Loader } from "components/widgets/loader/loader";
import { FormikProps, useFormik } from "formik";
import _ from "lodash";
import React, { FC, useCallback, useMemo, useState } from "react";
import { assertNever, assertNotUndefined } from "utils/hx/util/types";
import { isValidNumber } from "utils/ts-utils";
import { LoadingValue, isLoaded } from "utils/utility-types";
import { array, lazy, number, object } from "yup";
import { AppRoutes } from "../../../../../app/app-routes";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { PurchaseRequestFlowStepper } from "../../../../widgets/flow-stepper/purchase-request-flow-stepper";
import {
  OrganisationProductSelectionTable,
  OrganisationProductSelectionTableFeature,
  OrganisationSelectableProductListing,
} from "../../../../widgets/purchase-requests/organisation-product-selection-table/organisation-product-selection-table";
import {
  PurchaseRequestFlowState,
  StorageListing,
  StorageLocationProductSale,
} from "./organisation-purchase-request-setup-page";

const SETUP_STEPS = (index: 0 | 1 | 2 | 3 | 4 | 5 | 6): { title: string; subtitle?: string } => {
  const steps = [
    {
      title: "Credit Limit",
    },
    {
      title: "PO Upload",
    },
    {
      title: "Delivery Type",
      subtitle: "Is this an 'In-Bond' or 'Out-of-Bond' sale?",
    },
    {
      title: "Sale Price Type",
      subtitle: "Does the sale price you are entering include or exclude duty?",
    },
    {
      title: "Storage Location",
      subtitle:
        "Please select the storage location from where your products are to be sold, or where your finished products will be produced.",
    },
    {
      title: "Stock Selection",
      subtitle: "Please select the stock you would like to include in this sale.",
    },
  ];
  return steps[index];
};

export interface OrganisationPurchaseRequestSetupPageViewProps {
  selectedPurchaser?: NominatedPurchaserDetailsView;
  storageLocations: WithDbId<StorageLocation>[];
  activeStorageLocation?: WithDbId<StorageLocation>;
  onChangeStorageLocation: (loc: WithDbId<StorageLocation> | null) => void;
  onNext: (values: SetupPurchaserRequestFormValues) => Promise<void>;
  initialValues?: PurchaseRequestFlowState["setup"];
  showProductionOrders?: boolean;
  finishedProducts: LoadingValue<StorageListing[]>;
  finishableProducts: LoadingValue<StorageListing[]>;
  productsDefaultSalePrices: LoadingValue<ProductSalePriceInfo[]>;
}

export const OrganisationPurchaseRequestSetupPageView = ({
  selectedPurchaser,
  storageLocations,
  activeStorageLocation,
  onChangeStorageLocation,
  onNext,
  initialValues,
  showProductionOrders,
  finishedProducts,
  finishableProducts,
  productsDefaultSalePrices,
}: OrganisationPurchaseRequestSetupPageViewProps) => {
  const { showConfirmationDialog } = useConfirmationDialog();
  const initialAvailableCredit = selectedPurchaser ? Number(selectedPurchaser.availableCredit) : 0;
  const [remainingAvailableCredit, setRemainingAvailableCredit] = useState(initialAvailableCredit);

  const form = useFormik<SetupPurchaserRequestFormValues>({
    initialValues: getInitialValuesForProductsForm(initialValues),
    validationSchema: getValidationSchema(initialAvailableCredit),
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onNext,
    enableReinitialize: true,
  });

  const onChangeLocation = async (loc: WithDbId<StorageLocation> | null) => {
    setRemainingAvailableCredit(Number(assertNotUndefined(selectedPurchaser?.availableCredit)));
    const { products } = form.touched;
    const resetProducts = () => {
      form.setFieldValue("products", []);
    };
    if ((products?.length || 0) == 0) {
      resetProducts();
      return onChangeStorageLocation(loc);
    }

    const userResponse = await showConfirmationDialog({
      title: "Are you sure you want to change the storage location?",
      body: "You will lose your current product selections and price/quantity details.",
    });
    if (userResponse.kind === "ok") {
      resetProducts();
      onChangeStorageLocation(loc);
    }
  };

  const showPoUpload = useMemo(() => selectedPurchaser?.poUploadEnabled === true, [selectedPurchaser?.poUploadEnabled]);

  return (
    <PortalPageContent
      header={
        <OrganisationPurchaseRequestSetupHeader purchaserName={selectedPurchaser?.name ?? "Nominated Purchaser"} />
      }>
      <Grid container spacing={5}>
        {!selectedPurchaser && <NoSelectedPurchaser />}
        {selectedPurchaser && (
          <>
            {initialAvailableCredit > 0 ? (
              <>
                {/** storage locations from from product listings so need to wait till one is loaded */}
                {selectedPurchaser && storageLocations.length > 0 ? (
                  <>
                    <Grid item xs={12}>
                      <CreditLimit
                        remainingAvailableCredit={remainingAvailableCredit}
                        creditLimit={selectedPurchaser.creditLimit}
                        productLoaded={isLoaded(finishableProducts) && isLoaded(finishedProducts)}
                        purchaserCurrency={selectedPurchaser.currency}
                      />
                    </Grid>
                    {showPoUpload && (
                      <Grid item xs={12}>
                        <PurchaseOrderUploadCard
                          file={form.values.poFile}
                          onFileSelected={file => form.setFieldValue("poFile", file)}
                          onFileRemoved={() => form.setFieldValue("poFile", undefined)}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <SetupStep {...SETUP_STEPS(2)}>
                        <Grid container spacing={0}>
                          <RadioGroup
                            options={[
                              {
                                value: "true",
                                label: "In-Bond / Underbond",
                              },
                              {
                                value: "false",
                                label:
                                  "Out-of-Bond / Duty Paid" +
                                  (selectedPurchaser.currency !== "GBP"
                                    ? " - Not available for purchases outside the UK."
                                    : ""),
                                disabled: selectedPurchaser.currency !== "GBP",
                              },
                            ]}
                            value={form.values.bondedSale ? "true" : "false"}
                            onChange={value => {
                              const isBonded = value === "true";
                              form.setFieldValue("salePriceType", isBonded ? "exDutyAndVat" : "incDutyExVat");
                              form.setFieldValue("bondedSale", isBonded);
                            }}
                          />
                        </Grid>
                      </SetupStep>
                      {form.values.bondedSale === false && (
                        <Grid item xs={12}>
                          <SetupStep {...SETUP_STEPS(3)}>
                            <Grid container spacing={0}>
                              <RadioGroup
                                options={[
                                  { value: "incDutyExVat", label: "Inclusive of duty, exclusive of VAT" },
                                  { value: "incDutyAndVat", label: "Inclusive of duty, inclusive of VAT" },
                                  { value: "exDutyAndVat", label: "Exclusive of duty, exclusive of VAT" },
                                ]}
                                value={form.values.salePriceType}
                                onChange={value => form.setFieldValue("salePriceType", value)}
                              />
                            </Grid>
                          </SetupStep>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <SetupStep {...SETUP_STEPS(4)}>
                        <Grid container spacing={0}>
                          <Grid item xs={12} lg={5} pl={0}>
                            <LocationInput
                              selectedLocation={activeStorageLocation}
                              locations={storageLocations}
                              onChange={onChangeLocation}
                            />
                          </Grid>
                        </Grid>
                      </SetupStep>
                    </Grid>
                    <Grid item xs={12}>
                      <Loader loadingStates={[finishableProducts, finishedProducts, productsDefaultSalePrices]}>
                        {isLoaded(finishableProducts) &&
                          isLoaded(finishedProducts) &&
                          isLoaded(productsDefaultSalePrices) && (
                            <SelectProductsSection
                              selectedPurchaser={selectedPurchaser}
                              finishableProductListings={finishableProducts.value}
                              finishedProductListings={finishedProducts.value}
                              setRemainingAvailableCredit={setRemainingAvailableCredit}
                              form={form}
                              productsDefaultSalePrices={productsDefaultSalePrices.value}
                              activeStorageLocation={activeStorageLocation}
                              showProductionOrders={showProductionOrders}
                            />
                          )}
                      </Loader>
                    </Grid>
                  </>
                ) : (
                  // Wait for all products to load before displaying this message
                  <Grid item xs={12}>
                    <Loader loadingStates={[finishedProducts, finishableProducts]}>
                      <Stack direction="row" spacing={1}>
                        <WarningAmberOutlinedIcon color="warning" fontSize="small" />
                        <Typography>
                          No products are available in any storage location for a third party sale
                        </Typography>
                      </Stack>
                    </Loader>
                  </Grid>
                )}
              </>
            ) : (
              <InitialAvailableCreditError />
            )}
          </>
        )}
      </Grid>
    </PortalPageContent>
  );
};
export interface SetupPurchaserRequestFormValues {
  products: FormStorageLocProductSale[];
  bondedSale: boolean;
  dealDiscountAmount: string | undefined;
  salePriceType: PurchaseRequestSalePriceType;
  poFile?: File;
}
export type FormStorageLocProductSale = Omit<
  StorageLocationProductSale,
  "unitPrice" | "paidUnits" | "freeUnits" | "priceDiscount"
> & {
  unitPrice?: string;
  priceDiscount?: string;
  paidUnits?: string;
  freeUnits?: string;
};

function getInitialValuesForProductsForm(
  initialValues?: PurchaseRequestFlowState["setup"],
): SetupPurchaserRequestFormValues {
  if (!initialValues) {
    return {
      products: [],
      dealDiscountAmount: undefined,
      bondedSale: true,
      salePriceType: "exDutyAndVat",
    };
  }

  return {
    products:
      initialValues.products.map(
        p =>
          ({
            product: p.product,
            totalAvailable: p.totalAvailable,
            totalProducible: p.totalProducible,
            unitPrice: assertNotUndefined(p.unitPrice.toString()),
            priceDiscount: p.priceDiscount?.toString(),
            paidUnits: assertNotUndefined(p.paidUnits.toString()),
            freeUnits: p.freeUnits?.toString(),
          }) as FormStorageLocProductSale,
      ) ?? [],
    dealDiscountAmount: initialValues.dealDiscountAmount,
    bondedSale: initialValues.bondedSale ?? true,
    salePriceType: initialValues.salePriceType ?? "exDutyAndVat",
    poFile: initialValues.poFile,
  };
}

const SelectProductsSection = ({
  selectedPurchaser,
  setRemainingAvailableCredit,
  finishableProductListings,
  finishedProductListings,
  form,
  productsDefaultSalePrices,
  activeStorageLocation,
  showProductionOrders,
}: {
  selectedPurchaser: NominatedPurchaserDetailsView;
  setRemainingAvailableCredit: (value: number) => void;
  finishedProductListings: StorageListing[];
  finishableProductListings: StorageListing[];
  form: FormikProps<SetupPurchaserRequestFormValues>;
  productsDefaultSalePrices: ProductSalePriceInfo[];
  activeStorageLocation?: WithDbId<StorageLocation>;
  showProductionOrders?: boolean;
}) => {
  const canGoNext = useMemo(() => {
    return form.values.products.length > 0;
  }, [form.values.products]);

  const selectableProducts: OrganisationSelectableProductListing[] = useMemo(() => {
    if (!activeStorageLocation) {
      return [];
    }
    const finishedProducts =
      (finishedProductListings.find(p => p.storageLocation.id === activeStorageLocation.id)
        ?.products as ThirdPartySaleFinishedProductListing[]) ?? [];
    const finishableProducts =
      (finishableProductListings.find(p => p.storageLocation.id === activeStorageLocation.id)
        ?.products as ThirdPartySaleFinishableProductListing[]) ?? [];

    const results: OrganisationSelectableProductListing[] = finishedProducts.map(p => {
      const foundDefaultPriceInfo = productsDefaultSalePrices.find(
        product => product.productCode === p.productWithId.value.code,
      );
      return {
        productWithId: p.productWithId,
        stages: p.dealLegStages.map(leg => ({ kind: "deal-leg", value: leg })),
        defaultSalePrice: extractPrice(foundDefaultPriceInfo, form.values.salePriceType),
      };
    });

    if (showProductionOrders) {
      finishableProducts.forEach(p => {
        const existing = results.find(r => r.productWithId.id === p.productWithId.id);
        if (existing) {
          existing.stages.push({ kind: "producible", value: p });
        } else {
          results.push({
            productWithId: p.productWithId,
            stages: [{ kind: "producible", value: _.omit(p, "productWithId") }],
          });
        }
      });
    }
    return results;
  }, [
    activeStorageLocation,
    finishedProductListings,
    showProductionOrders,
    finishableProductListings,
    form.values.salePriceType,
    productsDefaultSalePrices,
  ]);
  const calculateRemainingCredit = useCallback(() => {
    const availableCredit = Number(selectedPurchaser.availableCredit);
    setRemainingAvailableCredit(availableCredit - calculateTotalPurchaseAmount(form.values));
  }, [form.values, selectedPurchaser.availableCredit, setRemainingAvailableCredit]);

  const { initFeatures, initToggleGroups } = useMemo(() => {
    const initFeatures = {
      freeStock: form.values.products?.some(p => p.freeUnits),
      productDiscount: form.values.products?.some(p => p.priceDiscount),
      dealDiscount: form.values.dealDiscountAmount,
    } as { [key in OrganisationProductSelectionTableFeature]?: boolean };
    const initToggleGroups = [
      ...(initFeatures.freeStock ? ["freeStock"] : []),
      ...(initFeatures.productDiscount ? ["productDiscount"] : []),
      ...(initFeatures.dealDiscount ? ["dealDiscount"] : []),
    ] as OrganisationProductSelectionTableFeature[];
    return { initFeatures, initToggleGroups };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [features, setFeatures] = useState(initFeatures);
  const [toggleGroups, setToggleGroups] = useState(initToggleGroups);

  const { title, subtitle } = SETUP_STEPS(5);

  return (
    <Stack spacing={5}>
      <SetupStep title={title} subtitle={activeStorageLocation ? subtitle : "Please select a Storage Location"}>
        <Grid container justifyContent="flex-end">
          <ToggleButtonGroup
            value={toggleGroups}
            onChange={(_, newGroups: OrganisationProductSelectionTableFeature[]) => {
              setToggleGroups(newGroups);
              setFeatures({
                freeStock: newGroups.includes("freeStock"),
                productDiscount: newGroups.includes("productDiscount"),
                dealDiscount: newGroups.includes("dealDiscount"),
              });
            }}>
            <ToggleButton value="productDiscount">Product Discount</ToggleButton>
            <ToggleButton value="freeStock">Free Stock</ToggleButton>
            <ToggleButton value="dealDiscount">Deal Discount</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <OrganisationProductSelectionTable
          key={activeStorageLocation?.id ?? "no-location"}
          purchaserCurrency={selectedPurchaser.currency}
          form={form}
          calculateRemainingCredit={calculateRemainingCredit}
          productListings={selectableProducts}
          features={features}
        />
      </SetupStep>
      <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
        <Button variant="outlined" href={AppRoutes.Dashboard}>
          Back
        </Button>
        <LoadingButton variant="contained" onClick={form.submitForm} disabled={!canGoNext}>
          Next
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

const OrganisationPurchaseRequestSetupHeader = ({ purchaserName }: { purchaserName: string }) => {
  return (
    <PortalPageContentHeader
      variant="split"
      title={
        <Stack>
          <Typography variant={"h4"}>New Third Party Sale Order</Typography>
          <Typography>from {purchaserName}</Typography>
        </Stack>
      }
      right={<PurchaseRequestFlowStepper activeStep={0} />}
    />
  );
};

const LocationInput: FC<{
  selectedLocation?: WithDbId<StorageLocation>;
  locations: WithDbId<StorageLocation>[];
  onChange: (v: WithDbId<StorageLocation> | null) => void;
}> = ({ selectedLocation, locations, onChange }) => {
  return (
    <Autocomplete<WithDbId<StorageLocation>>
      fullWidth
      value={selectedLocation ?? null}
      onChange={(_e, value) => onChange(value)}
      getOptionLabel={(loc: WithDbId<StorageLocation>) => loc.value.locationName || ""}
      options={locations}
      renderInput={params => <TextField {...params} label="Select storage location" name="location" required />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

const CreditLimit = ({
  remainingAvailableCredit,
  creditLimit,
  productLoaded,
  purchaserCurrency,
}: {
  remainingAvailableCredit: number;
  creditLimit: BigDecimal;
  productLoaded: boolean;
  purchaserCurrency: Currency;
}) => {
  return (
    <SetupStep {...SETUP_STEPS(0)}>
      {remainingAvailableCredit <= 0 && productLoaded && (
        <Stack direction="row" spacing={1} alignItems="center">
          <WarningAmberOutlinedIcon color="warning" fontSize="small" />
          <Typography variant="hyperLinkBig" color="common.error">
            Remaining credit limit for additional sales cannot be 0 or negative. Please
          </Typography>
          <Link variant="big" href={FEROVINUM_CONTACT_URL} color="error">
            contact Ferovinum
          </Link>
          <Typography variant="hyperLinkBig" color="common.error">
            to increase limit.
          </Typography>
        </Stack>
      )}
      <Stack direction="row" spacing={2} alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography color="common.grey6">Overall payment terms credit limit for this purchaser</Typography>
          <InfoTooltip title="This is the current limit for the maximum outstanding receivable amount at any point in time from payment terms given on previous sales to this purchaser." />
        </Stack>
        <CurrencyRenderer variant="subtitle1Bold" value={creditLimit} currency={purchaserCurrency} />
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography color="common.grey6">Remaining credit limit for additional sales</Typography>
          <InfoTooltip title="This is the remaining available limit after outstanding receivables on previous sales are deducted from the total limit. As this purchaser makes payments on your previous sales, more of the limit will become available." />
        </Stack>
        <Stack>
          <CurrencyRenderer
            variant="subtitle1Bold"
            color={remainingAvailableCredit <= 0 ? "common.red" : undefined}
            value={remainingAvailableCredit}
            currency={purchaserCurrency}
          />
        </Stack>
      </Stack>
    </SetupStep>
  );
};

function extractPrice(
  priceInfo: ProductSalePriceInfo | undefined,
  salePriceType: PurchaseRequestSalePriceType,
): BigDecimal | undefined {
  switch (salePriceType) {
    case "incDutyExVat":
      return priceInfo?.incDutyExVatPrice ?? undefined;
    case "incDutyAndVat":
      return priceInfo?.incDutyAndVatPrice ?? undefined;
    case "exDutyAndVat":
      return priceInfo?.exDutyAndVatPrice ?? undefined;
    default:
      assertNever(salePriceType);
  }
}

const InitialAvailableCreditError = () => {
  return (
    <Grid item xs={12}>
      <Stack direction="row" spacing={1}>
        <WarningAmberOutlinedIcon color="warning" fontSize="small" />
        <Typography>
          You currently aren’t able to order for this purchaser as they have not yet paid money owing to Ferovinum. Once
          they do, your available purchaser amount will increase and you’ll be able to create a purchase request for
          them.
        </Typography>
      </Stack>
    </Grid>
  );
};
const NoSelectedPurchaser = () => {
  return (
    <Grid item xs={12}>
      <Stack direction="row" spacing={1}>
        <WarningAmberOutlinedIcon color="warning" fontSize="small" />
        <Typography>No purchaser selected, please return home and try again.</Typography>
      </Stack>
    </Grid>
  );
};

const SetupStep = ({ title, subtitle, children }: { title: string; subtitle?: string; children: React.ReactNode }) => {
  return (
    <Stack spacing={2} sx={{ backgroundColor: "white", borderRadius: 1 }} padding={4} width={"100%"}>
      <Typography variant="h6">{title}</Typography>
      {subtitle && <Typography variant="body1">{subtitle}</Typography>}
      {children}
    </Stack>
  );
};

function calculateTotalPurchaseAmount(formValues: SetupPurchaserRequestFormValues) {
  const productSubTotals = [...formValues.products].reduce((sum, p) => {
    if (isValidNumber(p.unitPrice) && isValidNumber(p.paidUnits)) {
      const priceDiscount = isValidNumber(p.priceDiscount) ? Number(p.priceDiscount) : 0;
      const productTotal = (Number(p.unitPrice) - priceDiscount) * Number(p.paidUnits);
      return sum + productTotal;
    }
    return sum;
  }, 0);
  const dealDiscount = isValidNumber(formValues.dealDiscountAmount) ? Number(formValues.dealDiscountAmount) : 0;
  return productSubTotals - dealDiscount;
}

const productSchema = lazy((selectedProduct: FormStorageLocProductSale) => {
  let quantitySchema = number().positive().required("Quantity is required");
  if (selectedProduct.totalAvailable && !selectedProduct.totalProducible) {
    quantitySchema = quantitySchema.max(
      selectedProduct.totalAvailable,
      `Exceeds max available: (${selectedProduct.totalAvailable})`,
    );
  } else if (!selectedProduct.totalAvailable && selectedProduct.totalProducible) {
    quantitySchema = quantitySchema.max(
      selectedProduct.totalProducible,
      `Exceeds max producible: (${selectedProduct.totalProducible})`,
    );
  } else {
    const maxAllowed = selectedProduct.totalAvailable + selectedProduct.totalProducible;
    quantitySchema = quantitySchema.max(maxAllowed, `Exceeds max available + producible: (${maxAllowed})`);
  }

  const hasFreeUnits = selectedProduct.freeUnits && Number(selectedProduct.freeUnits) > 0;
  if (hasFreeUnits) {
    return object()
      .shape(
        {
          unitPrice: number().min(0).optional(),
          priceDiscount: number().positive().optional(),
          paidUnits: number().positive().optional(),
          freeUnits: quantitySchema,
        },
        [["price", "quantity"]],
      )
      .required();
  }

  return object()
    .shape(
      {
        unitPrice: number().min(0).required("Price is required"),
        priceDiscount: number().positive().optional(),
        paidUnits: quantitySchema,
        freeUnits: number().positive().optional(),
      },
      [["price", "quantity"]],
    )
    .required();
});

const getValidationSchema = (availableCredit: number) =>
  object()
    .shape({
      products: array()
        // Note: (typescript bug): https://github.com/jquense/yup/issues/1283
        // @ts-ignore
        .of(productSchema),
    })
    .test("available-limit-reached", "Available purchase amount cannot be 0 or negative", function (selections, ctx) {
      const result =
        calculateTotalPurchaseAmount(selections as unknown as SetupPurchaserRequestFormValues) < availableCredit;
      //Workaround for yup BUG: https://github.com/jaredpalmer/formik/issues/2146
      return result || ctx.createError({ message: "Available purchase amount cannot be 0 or negative", path: "root" });
    })
    .test("check-at-least-one", function (selections, ctx) {
      const { products } = selections as unknown as SetupPurchaserRequestFormValues;
      const allProducts = [...products];
      const result =
        allProducts.length > 0 && allProducts.some(p => isValidNumber(p.unitPrice) && isValidNumber(p.paidUnits));
      //Workaround for yup BUG: https://github.com/jaredpalmer/formik/issues/2146
      return result || ctx.createError({ message: "At least one product must be selected", path: "root" });
    });
