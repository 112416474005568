import { Grid, Typography } from "@mui/material";
import { DbKey } from "adl-gen/common/db";
import { FEROVINUM_CONTACT_URL } from "components/assets/url";
import React, { useMemo } from "react";
import { DataFieldDef } from "utils/data-field/data-field-def";
import { KeyByType } from "utils/utility-types";
import { FormField, FormikForm } from "../../types/formik-types";
import { Link } from "../../widgets/link/link";
import { FormOptionPicker } from "../helpers/option-picker";

export type SelectorInfo<K> = {
  id: DbKey<K>;
  displayValue: string;
  disabled?: boolean;
};

type SelectorFieldInfo<T extends object, K extends object> =
  | {
      form: FormikForm<T>;
      fieldKey: KeyByType<T, DbKey<K>>;
    }
  | {
      field: FormField<DbKey<K>>;
    };
export interface DbKeySelectorProps<T extends object, K extends object> {
  label?: string;
  fieldInfo: SelectorFieldInfo<T, K>;
  fieldDef: DataFieldDef<DbKey<K>>;
  options: SelectorInfo<K>[];
  onValueChange?: (v: DbKey<K> | undefined) => void;
  helperText?: string;
  initialValue?: DbKey<K>;
  disabled?: boolean;
}

export function DbKeySelector<T extends object, K extends object>({
  label,
  fieldInfo,
  fieldDef,
  options,
  onValueChange,
  helperText,
  initialValue,
  disabled,
}: DbKeySelectorProps<T, K>) {
  // Add an empty option to the list of options
  const ids = useMemo(() => ["", ...options.map(opt => opt.id)], [options]);
  const field = useMemo(() => {
    if ("form" in fieldInfo) {
      return new FormField<DbKey<K>>(fieldInfo.form, fieldInfo.fieldKey);
    } else {
      return fieldInfo.field;
    }
  }, [fieldInfo]);

  return (
    <Grid container spacing={3} direction={"row"}>
      <Grid item xs={12} lg={6}>
        <FormOptionPicker
          field={field}
          labelOverride={label}
          fieldDef={fieldDef}
          fullWidth
          options={ids}
          getOptionLabel={id => options.find(opt => opt.id === id)?.displayValue ?? ""}
          getOptionDisabled={id => options.find(opt => opt.id === id)?.disabled ?? false}
          onValueChange={onValueChange}
          initialValue={initialValue}
          disabled={disabled}
        />
      </Grid>
      {helperText && (
        <Grid item xs={12} lg={6}>
          <Typography variant="body2" color="common.darkGrey" noWrap>
            {helperText}
          </Typography>
          <Link
            variant="big"
            color="grey5"
            href={FEROVINUM_CONTACT_URL}
            target="_blank"
            typographyProps={{ noWrap: true }}>
            Contact Ferovinum
          </Link>
        </Grid>
      )}
    </Grid>
  );
}
