import { ButtonProps, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography } from "@mui/material";
import React, { useState } from "react";
import { isString } from "utils/ts-utils";
import { LoadingActionButton } from "../buttons/loading-action-button/loading-action-button";

interface DialogButtonAction {
  title?: string;
  color?: ButtonProps["color"];
  onClick: () => void | Promise<void>;
  disabled?: boolean;
}

export interface ConfirmationDialogProps extends Omit<DialogProps, "title"> {
  title: React.ReactNode;
  open: boolean;
  acceptAction: DialogButtonAction;
  cancelAction?: DialogButtonAction;
  children?: React.ReactNode;
}

/// A base confirmation dialog with an accept action and an optional cancel action
export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { title, open, acceptAction, cancelAction, children, ...otherProps } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleAction = async (action: DialogButtonAction) => {
    try {
      setError(undefined);
      setLoading(true);
      await action.onClick();
    } catch (e) {
      if (e instanceof Error) {
        setError(`An error occurred: ${e.message}`);
      } else {
        setError("An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} {...otherProps} title={isString(title) ? title : ""}>
      {isString(title) ? (
        <DialogTitle>
          <Typography variant="body2" color="common.grey5">
            {title}
          </Typography>
        </DialogTitle>
      ) : (
        <>{title}</>
      )}
      {error && <Typography color="error">{error}</Typography>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {cancelAction && (
          <LoadingActionButton
            variant={"outlined"}
            onClick={async _ => handleAction(cancelAction)}
            loading={loading}
            color={cancelAction?.color ?? undefined}
            disabled={cancelAction.disabled ?? false}>
            {cancelAction?.title ?? "Cancel"}
          </LoadingActionButton>
        )}
        <LoadingActionButton
          variant={"contained"}
          loading={loading}
          color={acceptAction?.color ?? undefined}
          onClick={async _ => handleAction(acceptAction)}
          disabled={acceptAction.disabled ?? false}>
          {acceptAction?.title ?? "Confirm"}
        </LoadingActionButton>
      </DialogActions>
    </Dialog>
  );
};
