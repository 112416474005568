import { AppService } from "adl-gen/app-service";
import { useContext } from "react";
import { useNewDealRequestImpl } from "utils/hooks/use-new-deal-request-impl";
import { assertNotUndefined } from "utils/hx/util/types";
import { LoggedInContext } from "../app/app";

export const useNewDealRequest = (newDealRequestId: string, opt?: { lazyLoad?: boolean }) => {
  const appService: AppService = assertNotUndefined(useContext(LoggedInContext).loginState?.user?.apis.app);
  return useNewDealRequestImpl(newDealRequestId, appService, opt);
};
