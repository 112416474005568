import React, { FC, useMemo } from "react";
import { StageProgress } from "adl-gen/ferovinum/app/api";
import { tomorrowOrDateFormat } from "utils/date-utils";
import { max, min } from "date-fns";
import { Box, Tooltip } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import * as common from "adl-gen/common";
import { NumberOfUnits } from "adl-gen/ferovinum/app/db";

export type RepurchaseStage = {
  quantityAvailable: NumberOfUnits;
  deadline: common.LocalDate;
  // TODO: move calculation of this stage progress based on deadline date
  stageProgress: StageProgress | null;
};
export interface RepurchaseDateRangeProps {
  repurchaseStages: RepurchaseStage[];
}

export const RepurchaseDateRange: FC<RepurchaseDateRangeProps> = ({ repurchaseStages }) => {
  const deadlineDates = useMemo(() => [...repurchaseStages.map(s => new Date(s.deadline))], [repurchaseStages]);

  const minDeadlineDate = useMemo(() => {
    return tomorrowOrDateFormat(min(deadlineDates));
  }, [deadlineDates]);

  const maxDeadlineDate = useMemo(() => {
    return tomorrowOrDateFormat(max(deadlineDates));
  }, [deadlineDates]);
  const isRange = minDeadlineDate !== maxDeadlineDate;

  const isImminentOrOverdue = useMemo(() => {
    return repurchaseStages.some(
      s => (s.stageProgress === "ended" || s.stageProgress === "ending") && s.quantityAvailable.value > 0,
    );
  }, [repurchaseStages]);

  const isApproaching = useMemo(() => {
    return repurchaseStages.some(s => s.stageProgress === "secondHalf" && s.quantityAvailable.value > 0);
  }, [repurchaseStages]);

  return (
    <Box display="flex" alignItems="center">
      {isImminentOrOverdue && (
        <Tooltip title="This is overdue." arrow describeChild>
          <ErrorIcon color="warning" sx={{ mr: 1 }} />
        </Tooltip>
      )}
      {isApproaching && !isImminentOrOverdue && (
        <Tooltip title="This is due soon." arrow describeChild>
          <ErrorOutlineOutlinedIcon color="warning" sx={{ mr: 1 }} />
        </Tooltip>
      )}
      {`${isRange ? `${minDeadlineDate} - ${maxDeadlineDate}` : `${minDeadlineDate}`}`}
    </Box>
  );
};

export const RepurchaseDate: FC<{ stage: RepurchaseStage }> = ({ stage }) => {
  return <RepurchaseDateRange repurchaseStages={[stage]} />;
};
