import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  gridClasses,
} from "@mui/material";
import { WithDbId } from "adl-gen/common/db";
import {
  OrgCreditLimitDetails,
  OrgCreditLimitDetailsResp,
  SaleOrderDetailsView,
  SaleOrderPreview,
  StorageLocationView,
} from "adl-gen/ferovinum/app/api";
import { DeliveryOptionReq } from "adl-gen/ferovinum/app/deliveryoptions";
import { Currency, OrganisationDeliveryLocation } from "adl-gen/ferovinum/app/db";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";
import { useConfirmationDialog } from "components/context/global-dialog/use-dialog";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import { InfoTooltip } from "components/widgets/info-tooltip/info-tooltip";
import React, { useCallback, useState } from "react";
import { formatLocalDate } from "utils/date-utils";
import { AppRoutes } from "../../../../../app/app-routes";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { useSettlementCurrency } from "../../../../layouts/portal-page-layout/portal-page";
import { RepurchaseFlowStepper } from "../../../../widgets/flow-stepper/repurchase-flow-stepper";
import { RepurchaseProductsTable } from "../../../../widgets/repurchase/repurchase-products-table/repurchase-products-table";
import { DeliveryAddressStickyNote } from "../../../../widgets/sticky-note/delivery-address-sticky-note/delivery-address-sticky-note";
import { DeliveryContactStickyNote } from "../../../../widgets/sticky-note/delivery-contact-sticky-note/delivery-contact-sticky-note";
import { StickyNote } from "../../../../widgets/sticky-note/sticky-note";
import { PaymentMethodOption } from "./organisation-repurchase-confirmation-page";
import { ensure } from "utils/null-and-undefined-utils";

export interface OrganisationRepurchaseConfirmationPageViewProps {
  storageLocation: StorageLocationView;
  destinationLocation?: StorageLocationView;
  saleOrderPreview: SaleOrderPreview;
  deliveryOptionReq: DeliveryOptionReq;
  selectedOrganisationDeliveryLocation?: WithDbId<OrganisationDeliveryLocation>;
  navigateBackToDeliveryOptions: () => void;
  onConfirm: (paymentMethod: PaymentMethodOption) => Promise<void>;
  confirmedSaleOrder?: SaleOrderDetailsView;
  settlementCreditDetails: OrgCreditLimitDetailsResp;
}

export const OrganisationRepurchaseConfirmationPageView = (props: OrganisationRepurchaseConfirmationPageViewProps) => {
  const {
    storageLocation,
    destinationLocation,
    saleOrderPreview,
    deliveryOptionReq: delivery,
    selectedOrganisationDeliveryLocation,
    navigateBackToDeliveryOptions,
    confirmedSaleOrder,
    settlementCreditDetails,
  } = props;
  const addressIsEditable = delivery.kind === "shippedDelivery" && !confirmedSaleOrder;
  const orgAddressCanBeChanged = delivery.kind === "orgLocationDelivery" && !confirmedSaleOrder;
  const settlementCurrency = useSettlementCurrency();
  const { canUseSettlementCredit } = saleOrderPreview;
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodOption>(
    canUseSettlementCredit ? "settlementCredit" : "directTransfer",
  );

  return (
    <PortalPageContent
      header={
        confirmedSaleOrder ? (
          <RepurchaseConfirmedHeader invoiceNumber={confirmedSaleOrder.invoiceNumber} />
        ) : (
          <RepurchaseConfirmationHeader />
        )
      }>
      <Stack spacing={5}>
        <Typography variant="h6">Order Details</Typography>
        <RepurchaseProductsTable
          {...saleOrderPreview.saleOrderTotalsView}
          settlementCurrency={settlementCurrency}
          dealLegSaleOrders={saleOrderPreview.dealLegSaleOrders}
          currency={useSettlementCurrency()}
        />

        <Typography variant="h6">Delivery Details</Typography>
        <Box>
          <Grid container spacing={4}>
            {/* Delivery contact or Warehouse */}
            {(delivery.kind === "shippedDelivery" || delivery.kind === "orgLocationDelivery") && (
              <Grid item xs={3}>
                {delivery.kind === "shippedDelivery" && (
                  <DeliveryContactStickyNote
                    action={
                      addressIsEditable
                        ? {
                            onClick: () => navigateBackToDeliveryOptions(),
                          }
                        : undefined
                    }
                    {...delivery.value}
                  />
                )}
                {delivery.kind === "orgLocationDelivery" && selectedOrganisationDeliveryLocation?.value && (
                  <DeliveryContactStickyNote
                    action={
                      addressIsEditable
                        ? {
                            onClick: () => navigateBackToDeliveryOptions(),
                          }
                        : undefined
                    }
                    {...selectedOrganisationDeliveryLocation.value}
                  />
                )}
              </Grid>
            )}
            {(delivery.kind === "inWarehouseTransfer" || delivery.kind === "inWarehouseTransferWithAccountCode") && (
              <Grid item xs={3}>
                <StickyNote
                  title="Internal transfer"
                  action={
                    addressIsEditable ? { label: "Change", onClick: () => navigateBackToDeliveryOptions } : undefined
                  }
                  line1={`Location: ${storageLocation.storageLocationName}`}
                  line2={
                    ensure(destinationLocation).storageLocationName !== storageLocation.storageLocationName
                      ? `Destination: ${ensure(destinationLocation).storageLocationName}`
                      : undefined
                  }
                  line3={
                    delivery.kind === "inWarehouseTransferWithAccountCode"
                      ? `Account code: ${delivery.value.accountCode}`
                      : undefined
                  }
                />
              </Grid>
            )}
            {/* Delivery Address */}
            {(delivery.kind === "shippedDelivery" || delivery.kind === "orgLocationDelivery") && (
              <Grid item xs={3}>
                {delivery.kind === "shippedDelivery" && (
                  <DeliveryAddressStickyNote
                    action={
                      addressIsEditable || orgAddressCanBeChanged
                        ? {
                            label: orgAddressCanBeChanged ? "Change" : undefined,
                            onClick: () => navigateBackToDeliveryOptions(),
                          }
                        : undefined
                    }
                    {...delivery.value}
                  />
                )}
                {delivery.kind === "orgLocationDelivery" && selectedOrganisationDeliveryLocation?.value && (
                  <DeliveryAddressStickyNote
                    action={
                      addressIsEditable || orgAddressCanBeChanged
                        ? {
                            label: orgAddressCanBeChanged ? "Change" : undefined,
                            onClick: () => navigateBackToDeliveryOptions(),
                          }
                        : undefined
                    }
                    {...selectedOrganisationDeliveryLocation.value}
                  />
                )}
              </Grid>
            )}
            {/* Delivery date and time */}
            {delivery.kind === "shippedDelivery" &&
              (delivery.value.deliveryDate ||
                delivery.value.deliveryTimeEarliest ||
                delivery.value.deliveryTimeLatest) && (
                <Grid item xs={3}>
                  <StickyNote
                    title="Delivery date and time"
                    action={addressIsEditable ? { onClick: () => navigateBackToDeliveryOptions() } : undefined}
                    line1={
                      delivery.kind === "shippedDelivery" && delivery.value.deliveryDate
                        ? formatLocalDate(delivery.value.deliveryDate)
                        : ""
                    }
                    line2={
                      delivery.kind === "shippedDelivery" &&
                      (delivery.value.deliveryTimeEarliest || delivery.value.deliveryTimeLatest)
                        ? `Between ${delivery.value.deliveryTimeEarliest} and ${delivery.value.deliveryTimeLatest}`
                        : ""
                    }
                  />
                </Grid>
              )}
            {/* Delivery instructions */}
            {((delivery.kind === "shippedDelivery" && delivery.value.deliveryInstructions) ||
              (delivery.kind === "orgLocationDelivery" &&
                selectedOrganisationDeliveryLocation?.value.deliveryInstructions)) && (
              <Grid item xs={3}>
                <StickyNote
                  title="Delivery instructions"
                  action={addressIsEditable ? { onClick: () => navigateBackToDeliveryOptions() } : undefined}
                  line1={
                    (delivery.kind === "shippedDelivery"
                      ? delivery.value.deliveryInstructions
                      : selectedOrganisationDeliveryLocation?.value.deliveryInstructions) ?? ""
                  }
                />
              </Grid>
            )}
          </Grid>
        </Box>

        {/* Only show the payment method options if the org has credit limit set up */}
        {!confirmedSaleOrder &&
          settlementCreditDetails.kind === "details" &&
          settlementCreditDetails.value !== null &&
          settlementCreditDetails.value.creditLimit && (
            <>
              <Typography variant="h6">Payment Method</Typography>
              <PaymentMethodRadioButtons
                selectedPaymentMethod={selectedPaymentMethod}
                onChange={setSelectedPaymentMethod}
                insufficientRemainingCredit={!canUseSettlementCredit}
                {...settlementCreditDetails.value}
              />
            </>
          )}

        {confirmedSaleOrder && (
          <>
            <Typography variant="h6">Payment Method</Typography>
            <Paper sx={{ p: 2 }}>
              <Typography>{`Selected payment method is via ${
                confirmedSaleOrder.usesSettlementCredit ? "credit settlement." : "direct transfer."
              }`}</Typography>
            </Paper>
          </>
        )}
        <ActionButtons
          selectedPaymentMethod={selectedPaymentMethod}
          confirmedInvoiceNumber={confirmedSaleOrder?.invoiceNumber}
          {...props}
        />
      </Stack>
    </PortalPageContent>
  );
};

type PaymentMethodRadioButtonsProps = OrgCreditLimitDetails & {
  selectedPaymentMethod: PaymentMethodOption;
  onChange(paymentMethod: PaymentMethodOption): void;
  insufficientRemainingCredit: boolean;
};
const PaymentMethodRadioButtons = ({
  selectedPaymentMethod,
  onChange,
  insufficientRemainingCredit,
  currency,
  creditLimit,
  creditLimitRemaining,
}: PaymentMethodRadioButtonsProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="body1">Please select your preferred payment method:</Typography>

      <FormControl sx={{ width: "100%" }}>
        <RadioGroup value={selectedPaymentMethod} onChange={e => onChange(e.target.value as PaymentMethodOption)}>
          <Box>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <PaymentMethodRadioOption
                  value="settlementCredit"
                  title="Pay with Credit Settlement"
                  subtitle="Allows immediate access to stock."
                  creditLimit={
                    creditLimitRemaining
                      ? {
                          availableCreditBalance: creditLimitRemaining,
                          currency,
                        }
                      : undefined
                  }
                  disabled={insufficientRemainingCredit}
                />
              </Grid>

              <Grid item xs={6} display="flex" alignItems="center">
                <Stack>
                  <CreditLimitInfoField
                    title="Your overall payment terms credit limit"
                    tooltipText="This is the current limit for the maximum outstanding receivable amount at any point in time from payment terms given on previous sales."
                    value={creditLimit}
                    currency={currency}
                  />
                  <CreditLimitInfoField
                    title="Your available credit balance for additional sales"
                    tooltipText="This is the remaining available limit after outstanding receivables on previous sales are deducted from the total limit. As you complete payments on your previous sales, more of the limit will become available."
                    value={creditLimitRemaining}
                    currency={currency}
                  />
                </Stack>
              </Grid>

              <Grid item xs={6} sx={{ [`&.${gridClasses.item}`]: { pt: 2 } }}>
                <PaymentMethodRadioOption
                  value="directTransfer"
                  title="Pay by Direct Transfer"
                  subtitle="Requires full invoice to be paid before stock access is granted."
                />
              </Grid>
            </Grid>
          </Box>
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

const PaymentMethodRadioOption = ({
  value,
  title,
  subtitle,
  creditLimit,
  disabled,
}: {
  value: PaymentMethodOption;
  title: string;
  subtitle: string;
  creditLimit?: {
    availableCreditBalance: string;
    currency: Currency;
  };
  disabled?: boolean;
}) => {
  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Stack direction="row" justifyContent="space-between">
        <FormControlLabel
          value={value}
          disabled={disabled}
          control={<Radio />}
          label={
            <Stack>
              <Typography>{title}</Typography>
              <Typography variant="body2" color={disabled ? "common.disabled" : "common.grey5"}>
                {subtitle}
              </Typography>
            </Stack>
          }
        />
        {creditLimit && (
          <Stack alignItems="flex-end">
            <Typography variant="caption" color="common.grey5">
              Available Credit Balance
            </Typography>
            <CurrencyRenderer
              value={creditLimit.availableCreditBalance}
              currency={creditLimit.currency}
              variant="body2Bold"
              color={"common.grey7"}
            />
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

const CreditLimitInfoField = ({
  title,
  tooltipText,
  value,
  currency,
}: {
  title: string;
  tooltipText: string;
  value: MonetaryValue | null;
  currency: Currency;
}) => {
  return (
    <Stack direction="row" spacing={1}>
      <Typography variant="body1" color="common.grey6">
        {title}
      </Typography>
      <InfoTooltip title={tooltipText} />
      <CurrencyRenderer variant="subtitle1Bold" color="common.grey6" value={value} currency={currency} />
    </Stack>
  );
};

const ActionButtons = ({
  confirmedInvoiceNumber,
  navigateBackToDeliveryOptions,
  onConfirm,
  selectedPaymentMethod,
}: {
  confirmedInvoiceNumber?: string;
  navigateBackToDeliveryOptions: () => void;
  onConfirm: (paymentMethod: PaymentMethodOption) => Promise<void>;
  selectedPaymentMethod: PaymentMethodOption;
}) => {
  const [loading, setLoading] = useState(false);
  const { showConfirmationDialog } = useConfirmationDialog();

  const handleClickConfirm = useCallback(async () => {
    setLoading(true);
    if (selectedPaymentMethod === "settlementCredit") {
      await showConfirmationDialog({
        title: "",
        body: (
          <Stack spacing={5} textAlign="center">
            <Typography>
              Please note that with the chosen payment method, we will immediately release the selected stock upon
              completion of the sale order.
            </Typography>
            <Typography>
              The total amount indicated on the sale order will be added to your outstanding balance and reduce the
              availability on your credit settlement balance.
            </Typography>
            <Typography>Would you like to continue with this chosen payment method?</Typography>
          </Stack>
        ),
        confirmAction: { title: "Proceed", onClick: async () => await onConfirm(selectedPaymentMethod) },
      });
    } else {
      await onConfirm(selectedPaymentMethod);
    }
    setLoading(false);
  }, [onConfirm, selectedPaymentMethod, showConfirmationDialog]);

  return (
    <Stack direction="row" spacing={2} sx={{ mt: 7 }}>
      {confirmedInvoiceNumber ? (
        <Button href={AppRoutes.Repurchase}>Make another purchase</Button>
      ) : (
        <>
          <Button variant="outlined" onClick={() => navigateBackToDeliveryOptions()} disabled={loading}>
            Back
          </Button>
          <LoadingActionButton onClick={handleClickConfirm}>Confirm</LoadingActionButton>
        </>
      )}
    </Stack>
  );
};

const RepurchaseConfirmationHeader = () => {
  return (
    <PortalPageContentHeader
      variant="split"
      title="Repurchase Stock"
      right={<RepurchaseFlowStepper activeStep={2} />}
    />
  );
};

const RepurchaseConfirmedHeader = ({ invoiceNumber }: { invoiceNumber: string }) => {
  return (
    <PortalPageContentHeader
      variant="split"
      useConfirmationTitle
      title="Order Successfully Processed!"
      right={
        <Stack alignItems="flex-end">
          <Typography variant="caption" color="common.grey6">
            Reference Number
          </Typography>
          <Typography variant="body2Bold">{invoiceNumber}</Typography>
        </Stack>
      }
    />
  );
};
