import { yupResolver } from "@hookform/resolvers/yup";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  IconButton,
  inputBaseClasses,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  OrganisationDealRenewalsRequestedStock,
  OrganisationExpiringStockDecision,
  OrganisationExpiringStockView,
  OrganisationRepurchaseExpiringStockView,
} from "adl-gen/ferovinum/app/api";
import { DealExtensionRequestState } from "adl-gen/ferovinum/app/db";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";
import { useConfirmationDialog } from "components/context/global-dialog/use-dialog";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import { InfoTooltip } from "components/widgets/info-tooltip/info-tooltip";
import { Link } from "components/widgets/link/link";
import { Loader } from "components/widgets/loader/loader";
import { ProductSummary } from "components/widgets/product-summary/product-summary";
import { isArray } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import {
  Control,
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
  UseFormGetValues,
  UseFormReturn,
  UseFormSetValue,
  useFormState,
  useWatch,
} from "react-hook-form";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { assertNever } from "utils/hx/util/types";
import {
  formatExpiryDates,
  formatNumberOfUnits,
  formatNumberOfUnits2,
  isSinglesUnitType,
  numberOfUnitsForUnitType,
} from "utils/model-utils";
import { isLoaded, LoadingValue } from "utils/utility-types";
import { array, boolean, number, object, ref } from "yup";
import { AppRoutes } from "../../../../../app/app-routes";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { PaginatedTableV2 } from "components/widgets/paginated-table/paginated-table";

export type ExpiringStockViewsWithFilter =
  | {
      filter: "decisionRequired";
      views: OrganisationExpiringStockView[];
    }
  | {
      filter: "repurchase";
      views: OrganisationRepurchaseExpiringStockView[];
      netPayableSubtotal: MonetaryValue;
    }
  | {
      filter: "dealRenewalsRequested";
      views: OrganisationDealRenewalsRequestedStock;
    };

export interface OrganisationExpiringStockPageViewProps {
  loadExpiringStockViewsWithFilter(
    filter: ExpiringStockViewsWithFilter["filter"],
  ): Promise<ExpiringStockViewsWithFilter>;
  onRequestToRollExpiringStock(req: OrganisationExpiringStockDecision[]): Promise<void>;
}

export const OrganisationExpiringStockPageView = ({
  loadExpiringStockViewsWithFilter,
  onRequestToRollExpiringStock,
}: OrganisationExpiringStockPageViewProps) => {
  const [selectedSection, setSelectedSection] = useState<ExpiringStockViewsWithFilter["filter"]>("decisionRequired");
  const [decisionRequiredSectionFormInitialValues, setDecisionRequiredSectionFormInitialValues] =
    useState<ExpiringStockViewDecisionFormValues>({ values: [] });
  const [repurchaseSectionFormInitialValues, setRepurchaseSectionFormInitialValues] =
    useState<ExpiringStockViewDecisionFormValues>({ values: [] });

  const loadSelectedSectionData = useCallback(async () => {
    const expiringStockViews = await loadExpiringStockViewsWithFilter(selectedSection);

    if (expiringStockViews.filter === "decisionRequired") {
      setDecisionRequiredSectionFormInitialValues((form: ExpiringStockViewDecisionFormValues) => {
        const prevValues = form.values;
        return {
          values: expiringStockViews.views.map(view => {
            const existingFormValue = prevValues.find(v => v.dealLeg.id === view.dealLeg.id);
            const maxQuantity = view.quantityExpiring.value;

            // If the previous repurchase quantity > max quantity, then return the max quantity. Otherwise, return the previous repurchase quantity.
            const repurchaseQuantity = Math.min(existingFormValue?.repurchaseQuantity ?? 0, maxQuantity);

            return {
              ...view,
              isRepurchaseRequired: false,
              maxQuantity,
              // If the form already had the deal leg stage, then set the input values to the existing form values
              // but set the non-input values based on the refreshed data
              isSelected: existingFormValue?.isSelected ?? false,
              repurchaseQuantity,
              // If the previous roll quantity > max quantity, then return the max quantity. Otherwise, return the previous roll quantity.
              rollQuantity: Math.min(existingFormValue?.rollQuantity ?? maxQuantity, maxQuantity),
            };
          }),
        };
      });
    }

    if (expiringStockViews.filter === "repurchase") {
      setRepurchaseSectionFormInitialValues((form: ExpiringStockViewDecisionFormValues) => {
        const prevValues = form.values;
        return {
          values: expiringStockViews.views.map(
            ({ view, isRepurchaseRequired, quantityToBeRepurchased, repurchaseNetSubtotalCost }) => {
              // If repurchase is required, we don't want to repopulate the form using previous values because
              // rows should be disabled for repurchase required deal legs
              const existingFormValue = isRepurchaseRequired
                ? undefined
                : prevValues.find(v => v.dealLeg.id === view.dealLeg.id);

              return {
                ...view,
                subtotalRepurchaseCost: repurchaseNetSubtotalCost,
                isRepurchaseRequired: isRepurchaseRequired,
                maxQuantity: quantityToBeRepurchased.value,
                // If the form already had the deal leg stage, then set the input values to the existing form values
                // but set the non-input values based on the refreshed data
                isSelected: existingFormValue?.isSelected ?? false,
                repurchaseQuantity: existingFormValue?.repurchaseQuantity ?? 0,
                rollQuantity: existingFormValue?.rollQuantity ?? quantityToBeRepurchased.value,
              };
            },
          ),
        };
      });
    }
    return expiringStockViews;
  }, [loadExpiringStockViewsWithFilter, selectedSection]);

  const [loadingExpiringStockViews, refreshExpiringStockViews] = useLoadingDataState(loadSelectedSectionData, true);

  return (
    <PortalPageContent header={<Header />}>
      <Stack spacing={5}>
        <Box>
          <ToggleButtonGroup
            value={selectedSection}
            exclusive
            onChange={(_, v) => {
              if (v !== null) {
                setSelectedSection(v);
              }
            }}>
            <ToggleButton value="decisionRequired">Decision Required</ToggleButton>
            {/* TODO Zhi: We have removed the ability to elect for repurchase from the deal renewal screen.
                The Repurchase tab still shows previous repurchases and may be of value.
                Remove all repurchase related logic once we confirm it is no longer needed
              */}
            {/* <ToggleButton value="repurchase">Repurchase</ToggleButton> */}
            <ToggleButton value="dealRenewalsRequested">Deal Renewals Requested</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <ExpiringStock
          loadingExpiringStockViews={loadingExpiringStockViews}
          refreshExpiringStockViews={refreshExpiringStockViews}
          decisionRequiredSectionFormInitialValues={decisionRequiredSectionFormInitialValues}
          saveDecisionRequiredFormValues={setDecisionRequiredSectionFormInitialValues}
          repurchaseSectionFormInitialValues={repurchaseSectionFormInitialValues}
          saveRepurchaseSectionFormValues={setRepurchaseSectionFormInitialValues}
          onRequestToRollExpiringStock={onRequestToRollExpiringStock}
        />
      </Stack>
    </PortalPageContent>
  );
};

const Header = () => (
  <PortalPageContentHeader
    variant={"split"}
    title={"Stock Expiring Within 30 Days"}
    right={
      <Link color="grey7" href={AppRoutes.OrganisationCompletedDealExtensionRequests}>
        Completed Deal Renewals
      </Link>
    }
  />
);

interface ExpiringStockProps {
  loadingExpiringStockViews: LoadingValue<ExpiringStockViewsWithFilter>;
  decisionRequiredSectionFormInitialValues: ExpiringStockViewDecisionFormValues;
  saveDecisionRequiredFormValues(newValues: ExpiringStockViewDecisionFormValues): void;
  repurchaseSectionFormInitialValues: ExpiringStockViewDecisionFormValues;
  saveRepurchaseSectionFormValues(newValues: ExpiringStockViewDecisionFormValues): void;
  onRequestToRollExpiringStock(req: OrganisationExpiringStockDecision[]): Promise<void>;
  refreshExpiringStockViews(): Promise<void>;
}
const ExpiringStock = ({
  loadingExpiringStockViews,
  decisionRequiredSectionFormInitialValues,
  saveDecisionRequiredFormValues,
  repurchaseSectionFormInitialValues,
  saveRepurchaseSectionFormValues,
  onRequestToRollExpiringStock,
  refreshExpiringStockViews,
}: ExpiringStockProps) => {
  const { showConfirmationDialog } = useConfirmationDialog();

  const handleRequestToRollExpiringStock = useCallback(
    async (form: ExpiringStockViewDecisionFormValues) => {
      const repurchaseAll = form.values.every(v => v.isSelected && v.repurchaseQuantity === v.maxQuantity);
      const renewAll = form.values.every(v => v.isSelected && v.rollQuantity === v.maxQuantity);

      await showConfirmationDialog({
        title: "",
        body: <ConfirmationDialogBody variant={repurchaseAll ? "repurchaseAll" : renewAll ? "renewAll" : "mixed"} />,
        confirmAction: {
          title: "I'm Happy To Proceed",
          onClick: async () => {
            await onRequestToRollExpiringStock(
              form.values
                .filter(values => values.isSelected)
                .map(values => {
                  const { unitType } = values.product.value;
                  return {
                    dealLegId: values.dealLeg.id,
                    quantityToRoll: numberOfUnitsForUnitType(unitType, values.rollQuantity),
                    maxQuantity: numberOfUnitsForUnitType(unitType, values.maxQuantity),
                  };
                }),
            );
          },
        },
      });

      void refreshExpiringStockViews();
    },
    [onRequestToRollExpiringStock, refreshExpiringStockViews, showConfirmationDialog],
  );

  const handleSubmitDecisionRequiredForm = useCallback(
    async (formValues: ExpiringStockViewDecisionFormValues) => {
      // Because submitting the form will trigger the data to refresh, we want to save the form values
      // in case the submission was unsuccessful so that the user can try again
      saveDecisionRequiredFormValues(formValues);
      await handleRequestToRollExpiringStock(formValues);
    },
    [handleRequestToRollExpiringStock, saveDecisionRequiredFormValues],
  );

  const handleSubmitRepurchaseSectionForm = useCallback(
    async (formValues: ExpiringStockViewDecisionFormValues) => {
      // Because submitting the form will trigger the data to refresh, we want to save the form values
      // in case the submission was unsuccessful so that the user can try again
      saveRepurchaseSectionFormValues(formValues);
      await handleRequestToRollExpiringStock(formValues);
    },
    [handleRequestToRollExpiringStock, saveRepurchaseSectionFormValues],
  );

  return (
    <Loader loadingStates={[loadingExpiringStockViews]}>
      {isLoaded(loadingExpiringStockViews) && (
        <>
          {loadingExpiringStockViews.value.filter === "decisionRequired" && (
            <DecisionRequiredSection
              initialValues={decisionRequiredSectionFormInitialValues}
              onSubmit={handleSubmitDecisionRequiredForm}
            />
          )}
          {loadingExpiringStockViews.value.filter === "repurchase" && (
            <RepurchaseSection
              initialValues={repurchaseSectionFormInitialValues}
              onSubmit={handleSubmitRepurchaseSectionForm}
              netPayableSubtotal={loadingExpiringStockViews.value.netPayableSubtotal}
            />
          )}
          {loadingExpiringStockViews.value.filter === "dealRenewalsRequested" && (
            <DealRenewalsRequestedSection dealRenewalsRequestedStock={loadingExpiringStockViews.value.views} />
          )}
        </>
      )}
    </Loader>
  );
};

const ConfirmationDialogBody = ({ variant }: { variant: "repurchaseAll" | "renewAll" | "mixed" }) => {
  switch (variant) {
    case "repurchaseAll":
      return (
        <Stack spacing={2}>
          <Typography textAlign="center">{"Are you sure you want to repurchase all expiring stock?"}</Typography>
          <Typography textAlign="center">
            {
              "Please ensure you've requested deal renewal for any specific items you intended to renew before proceeding."
            }
          </Typography>
        </Stack>
      );
    case "renewAll":
      return (
        <Stack spacing={2}>
          <Typography textAlign="center">
            {"Are you sure you want to request a deal renewal for all expiring stock?"}
          </Typography>
          <Typography textAlign="center">
            {"Please ensure you have repurchased all necessary items before proceeding."}
          </Typography>
        </Stack>
      );
    case "mixed":
      return <Typography textAlign="center">{"Please confirm you are happy to submit your decisions."}</Typography>;
    default:
      assertNever(variant);
  }
};

type ExpiringStockViewDecisionFormValue = Omit<OrganisationExpiringStockView, "quantityExpiring"> & {
  isSelected: boolean;
  repurchaseQuantity: number;
  rollQuantity: number;
  maxQuantity: number;
  isRepurchaseRequired: boolean;
};
type ExpiringStockViewDecisionFormValues = {
  values: ExpiringStockViewDecisionFormValue[];
};

const decisionRequiredValidationSchema = object().shape({
  values: array()
    .of(
      object().shape({
        isSelected: boolean().required(),
        maxQuantity: number().when("isSelected", {
          is: true,
          then: schema => schema.min(0).required(),
        }),
        repurchaseQuantity: number().when("isSelected", {
          is: true,
          then: schema => schema.max(ref("maxQuantity")).min(0).required(),
        }),
      }),
    )
    .test({
      message: "At least one product must be selected",
      test: values => {
        return values && values.some(v => v.isSelected);
      },
    }),
});

interface DecisionRequiredSectionProps {
  initialValues: ExpiringStockViewDecisionFormValues;
  onSubmit: SubmitHandler<ExpiringStockViewDecisionFormValues>;
}
const DecisionRequiredSection = ({ initialValues, onSubmit }: DecisionRequiredSectionProps) => {
  const form = useForm<ExpiringStockViewDecisionFormValues>({
    defaultValues: initialValues,
    values: initialValues,
    // @ts-ignore - TODO - Fix form values to match the schema, our FormValues type should probably only contain the values we need for the form
    resolver: yupResolver<ExpiringStockViewDecisionFormValues>(decisionRequiredValidationSchema),
  });
  const { fields } = useFieldArray({ name: "values", control: form.control });
  const hasExpiringStock = fields.length > 0;

  const handleRenewAll = useCallback(() => {
    const values = form.getValues().values;
    form.setValue(
      "values",
      values.map(v => ({
        ...v,
        isSelected: true,
        rollQuantity: v.maxQuantity,
      })),
    );
  }, [form]);

  const indexedFields = useMemo(() => fields.map((v, rowIdx) => ({ ...v, rowIdx })), [fields]);

  return hasExpiringStock ? (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Stack spacing={5}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography>Please let us know how you would like to manage the following expiring stock.</Typography>
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={handleRenewAll}>
                Request to renew all
              </Button>
            </Stack>
          </Stack>
          <PaginatedTableV2
            initialRowsPerPage={25}
            initialRows={indexedFields}
            totalRowCount={fields.length}
            HeaderContent={
              <TableRow>
                <TableCell align="center">
                  <HeaderCheckbox control={form.control} getValues={form.getValues} setValue={form.setValue} />
                </TableCell>
                <TableCell>Product</TableCell>
                <TableCell align="center">Expiry Date</TableCell>
                <TableCell align="right">Quantity Expiring</TableCell>
                <TableCell align="right">Net Payable Per Unit</TableCell>
                <TableCell>Decision</TableCell>
              </TableRow>
            }
            renderRow={({ rowIdx, id, ...expiringStock }) => (
              <ExpiringStockDecisionRow key={id} id={id} rowIdx={rowIdx} form={form} initialValues={expiringStock} />
            )}
          />
        </Stack>

        <TotalsCards form={form} />

        <SubmitButtons control={form.control} key="decision" />
      </Stack>
    </form>
  ) : (
    <Typography>You have no expiring stock that require a decision.</Typography>
  );
};

interface CheckboxCellContentProps {
  rowIdx: number;
  control: Control<ExpiringStockViewDecisionFormValues>;
  disabled: boolean;
}

const CheckboxCellContent = ({ rowIdx, control, disabled }: CheckboxCellContentProps) => {
  return (
    <Controller
      name={`values.${rowIdx}.isSelected` as const}
      control={control}
      disabled={disabled}
      render={({ field }) => (
        <Checkbox disabled={disabled} onChange={e => field.onChange(e.target.checked)} checked={field.value} />
      )}
    />
  );
};

interface HeaderCheckboxProps {
  control: Control<ExpiringStockViewDecisionFormValues>;
  getValues: UseFormGetValues<ExpiringStockViewDecisionFormValues>;
  setValue: UseFormSetValue<ExpiringStockViewDecisionFormValues>;
}
const HeaderCheckbox = ({ control, getValues, setValue }: HeaderCheckboxProps) => {
  const watchedValues = useWatch({ control, name: "values" });
  const isAnyRowSelected = watchedValues.some(v => v.isSelected);
  const toggleSelectAll = useCallback(() => {
    const values = getValues("values");
    setValue(
      "values",
      values.map(v => ({ ...v, isSelected: !isAnyRowSelected })),
    );
  }, [isAnyRowSelected, getValues, setValue]);
  return (
    <IconButton
      onClick={() => {
        toggleSelectAll();
      }}>
      {isAnyRowSelected ? (
        <IndeterminateCheckBoxOutlinedIcon color="inherit" />
      ) : (
        <CheckBoxOutlinedIcon color="inherit" />
      )}
    </IconButton>
  );
};

interface DecisionTotals {
  totalRepurchaseCost: number;
  totalDealRenewalCost: number;
}
interface TotalsCardsProps {
  form: UseFormReturn<ExpiringStockViewDecisionFormValues>;
}
const TotalsCards = ({ form }: TotalsCardsProps) => {
  const watchedValues = useWatch({ control: form.control, name: "values" });
  const isAnyRowChecked = watchedValues.some(v => v.isSelected);
  const currency = form.getValues("values")[0].currency;

  const totals: DecisionTotals = useMemo(() => {
    // If there are no rows checked, calculate the totals for all rows
    const rowsToCalculate = isAnyRowChecked ? watchedValues.filter(v => v.isSelected) : watchedValues;

    const totalRepurchaseCost = rowsToCalculate.reduce(
      (total: number, row: ExpiringStockViewDecisionFormValue) =>
        total + row.repurchaseQuantity * Number(row.repurchaseCostPerUnit),
      0,
    );
    const totalDealRenewalCost = rowsToCalculate.reduce((total: number, row: ExpiringStockViewDecisionFormValue) => {
      const rollQuantityToCalculate = isAnyRowChecked ? row.rollQuantity : row.maxQuantity;
      return total + rollQuantityToCalculate * Number(row.dealRenewalCostPerUnit);
    }, 0);

    return { totalRepurchaseCost, totalDealRenewalCost };
  }, [isAnyRowChecked, watchedValues]);

  return (
    <Stack direction="row" justifyContent="space-between" spacing={5}>
      <Paper elevation={0} sx={{ p: 2, flex: 1, display: "flex" }}>
        <Stack spacing={1} justifyContent="space-between">
          <TextWithInfoTooltip
            text={`Total cost to repurchase ${isAnyRowChecked ? "selected" : "all"} products by earliest expiry date`}
            tooltipText="The total below represents the cost to repurchase all stock on the date of the earliest product expiry."
          />
          <CurrencyRenderer variant="h6" color="grey7" value={totals.totalRepurchaseCost} currency={currency} />
        </Stack>
      </Paper>

      <Paper elevation={0} sx={{ p: 2, flex: 1, display: "flex" }}>
        <Stack spacing={1} justifyContent="space-between">
          <TextWithInfoTooltip
            text={`Estimated fees payable on deal renewal for ${isAnyRowChecked ? "selected" : "all"} products`}
            tooltipText="The following amount shows the fees accumulated on the selected expiring products which will become payable on deal renewal. Please note that the entire deal will be re-evaluated for deal renewal, which can impact the total amount payable to renew the deal."
          />
          <CurrencyRenderer variant="h6" color="grey7" value={totals.totalDealRenewalCost} currency={currency} />
        </Stack>
      </Paper>
    </Stack>
  );
};

interface ExpiringStockDecisionRowProps {
  id: string;
  rowIdx: number;
  form: UseFormReturn<ExpiringStockViewDecisionFormValues>;
  // Non-watched form values
  initialValues: ExpiringStockViewDecisionFormValue;
}
const ExpiringStockDecisionRow = ({
  id,
  rowIdx,
  form: { control, setValue },
  initialValues,
}: ExpiringStockDecisionRowProps) => {
  const product = initialValues.product.value;
  const disabled = initialValues.isRepurchaseRequired;
  const isSelected = useWatch({ control, name: `values.${rowIdx}.isSelected` as const });
  const handleSelectRow = useCallback(() => {
    if (!disabled) {
      setValue(`values.${rowIdx}.isSelected` as const, !isSelected);
    }
  }, [isSelected, disabled, rowIdx, setValue]);

  return (
    <TableRow
      key={id}
      sx={{ backgroundColor: isSelected ? "common.veryLightPurple" : "common.white", cursor: "pointer" }}
      onClick={handleSelectRow}>
      <TableCell align="center">
        <CheckboxCellContent rowIdx={rowIdx} control={control} disabled={disabled} />
      </TableCell>
      <TableCell>
        <ProductSummary {...product} />
      </TableCell>
      <TableCell align="center">
        {formatExpiryDates(initialValues.CSDExpiryDate, initialValues.FSDExpiryDate)}
      </TableCell>
      <TableCell align="right">{formatNumberOfUnits2(initialValues.maxQuantity, product.unitType)}</TableCell>
      <TableCell align="right">
        <CurrencyRenderer
          maximumFractionDigits={4}
          currency={initialValues.currency}
          value={initialValues.repurchaseCostPerUnit}
        />
      </TableCell>
      <TableCell sx={{ width: DECISION_COLUMN_WIDTH }}>
        {isSinglesUnitType(product.unitType) ? (
          <Stack spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Renew</Typography>
              <Controller
                rules={{
                  onChange: e => {
                    const rollQuantity = Math.max(0, Math.min(initialValues.maxQuantity, Number(e.target.value))) || 0;
                    setValue(`values.${rowIdx}.rollQuantity`, rollQuantity || 0);
                  },
                }}
                render={({ field, fieldState }) => (
                  <EmbeddedTextField
                    {...field}
                    type="number"
                    onClick={e => {
                      if (!isSelected) {
                        handleSelectRow();
                      }
                      e.stopPropagation();
                    }}
                    error={fieldState.error !== undefined}
                  />
                )}
                name={`values.${rowIdx}.rollQuantity` as const}
                control={control}
              />
              <Typography noWrap overflow="visible">
                units
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Typography>Request Renewal</Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

interface ExpiringStockRepurchaseRowProps {
  id: string;
  rowIdx: number;
  control: Control<ExpiringStockViewDecisionFormValues>;
  setValue: UseFormSetValue<ExpiringStockViewDecisionFormValues>;
  // Non-watched form values
  initialValues: ExpiringStockViewDecisionFormValue;
}
const ExpiringStockRepurchaseRow = ({
  id,
  rowIdx,
  control,
  setValue,
  initialValues,
}: ExpiringStockRepurchaseRowProps) => {
  const product = initialValues.product.value;
  const disabled = initialValues.isRepurchaseRequired;
  const isSelected: boolean = useWatch({ control, name: `values.${rowIdx}.isSelected` as const });
  const repurchaseQuantity = useWatch({ control, name: `values.${rowIdx}.repurchaseQuantity` as const });
  const handleSelectRow = useCallback(() => {
    if (!disabled) {
      setValue(`values.${rowIdx}.isSelected` as const, !isSelected);
    }
  }, [isSelected, disabled, rowIdx, setValue]);

  return (
    <TableRow
      key={id}
      sx={{
        backgroundColor: isSelected ? "common.veryLightPurple" : "common.white",
        cursor: disabled ? undefined : "pointer",
      }}
      onClick={handleSelectRow}>
      <TableCell align="center">
        <CheckboxCellContent rowIdx={rowIdx} control={control} disabled={disabled} />
      </TableCell>
      <TableCell>
        <ProductSummary {...product} />
      </TableCell>
      <TableCell align="center">
        {formatExpiryDates(initialValues.CSDExpiryDate, initialValues.FSDExpiryDate)}
      </TableCell>
      <TableCell align="right">{formatNumberOfUnits2(initialValues.maxQuantity, product.unitType)}</TableCell>

      <TableCell align="right">
        <CurrencyRenderer
          maximumFractionDigits={4}
          currency={initialValues.currency}
          value={initialValues.repurchaseCostPerUnit}
        />
      </TableCell>
      <TableCell align="right">
        <CurrencyRenderer
          maximumFractionDigits={4}
          currency={initialValues.currency}
          value={initialValues.subtotalRepurchaseCost}
        />
      </TableCell>
      <TableCell sx={{ width: DECISION_COLUMN_WIDTH }}>
        {disabled ? (
          "Repurchase Required"
        ) : (
          <>
            {isSinglesUnitType(product.unitType) ? (
              <Stack spacing={1}>
                <Stack direction="row" spacing={1} alignItems="end">
                  <Typography noWrap overflow="visible">
                    Request Renewal for
                  </Typography>
                  <Controller
                    name={`values.${rowIdx}.rollQuantity` as const}
                    control={control}
                    rules={{
                      onChange: e => {
                        let rollQuantity = Number(e.target.value);
                        if (isNaN(rollQuantity) || rollQuantity < 0) {
                          setValue(`values.${rowIdx}.rollQuantity` as const, 0);
                          setValue(`values.${rowIdx}.repurchaseQuantity` as const, initialValues.maxQuantity);
                          return;
                        }
                        // Do not allow users to input more than the max quantity
                        if (rollQuantity > initialValues.maxQuantity) {
                          rollQuantity = initialValues.maxQuantity;
                          setValue(`values.${rowIdx}.rollQuantity` as const, rollQuantity);
                        }
                        const repurchaseQuantity = initialValues.maxQuantity - rollQuantity;
                        setValue(`values.${rowIdx}.repurchaseQuantity` as const, repurchaseQuantity);
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <EmbeddedTextField
                        {...field}
                        type="number"
                        onClick={e => {
                          if (!isSelected) {
                            handleSelectRow();
                          }
                          e.stopPropagation();
                        }}
                        error={fieldState.error !== undefined}
                        size="small"
                      />
                    )}
                  />
                  <Typography noWrap overflow="visible">
                    units
                  </Typography>
                </Stack>

                <Typography>
                  and Repurchase <strong>{repurchaseQuantity}</strong> units
                </Typography>
              </Stack>
            ) : (
              "Request Renewal"
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

const repurchaseSectionFormValidationSchema = object().shape({
  values: array()
    .of(
      object().shape({
        isSelected: boolean().required(),
        rollQuantity: number().when("isSelected", {
          is: true,
          then: schema => schema.max(ref("maxQuantity")).min(1).required(),
        }),
        maxQuantity: number().when("isSelected", {
          is: true,
          then: schema => schema.min(0).required(),
        }),
      }),
    )
    .required()
    .test({
      message: "At least one product must be selected",
      test: values => {
        return values && values.some(v => v.isSelected);
      },
    }),
});

interface RepurchaseSectionProps {
  initialValues: ExpiringStockViewDecisionFormValues;
  onSubmit: SubmitHandler<ExpiringStockViewDecisionFormValues>;
  netPayableSubtotal: MonetaryValue;
}
const RepurchaseSection = ({ initialValues, onSubmit, netPayableSubtotal }: RepurchaseSectionProps) => {
  const form = useForm<ExpiringStockViewDecisionFormValues>({
    defaultValues: initialValues,
    values: initialValues,
    // @ts-ignore - TODO - Fix form values to match the schema, our FormValues type should probably only contain the values we need for the form
    resolver: yupResolver<ExpiringStockViewDecisionFormValues>(repurchaseSectionFormValidationSchema),
  });
  const [showDecisionForm, setShowDecisionForm] = useState(false);
  const hasStockToRepurchase = initialValues.values.length > 0;

  return hasStockToRepurchase ? (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Stack spacing={5}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography>
              You have indicated your intention to repurchase the following products by their expiry date.
            </Typography>
            {!showDecisionForm && (
              <Stack>
                <Button variant="outlined" onClick={() => setShowDecisionForm(true)}>
                  Edit my choice
                </Button>
              </Stack>
            )}
          </Stack>

          {showDecisionForm ? (
            <RepurchaseDecisionForm form={form} onCancel={() => setShowDecisionForm(false)} />
          ) : (
            <StockToRepurchaseTable
              repurchaseStockViews={initialValues.values}
              netPayableSubtotal={netPayableSubtotal}
            />
          )}
        </Stack>
      </Stack>
    </form>
  ) : (
    <Typography>You have no expiring stock planned for repurchase.</Typography>
  );
};

interface RepurchaseDecisionFormProps {
  form: UseFormReturn<ExpiringStockViewDecisionFormValues>;
  onCancel(): void;
}
const RepurchaseDecisionForm = ({ form, onCancel }: RepurchaseDecisionFormProps) => {
  const { fields } = useFieldArray({ name: "values", control: form.control });

  return (
    <Stack spacing={5}>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <HeaderCheckbox control={form.control} getValues={form.getValues} setValue={form.setValue} />
              </TableCell>
              <TableCell>Product</TableCell>
              <TableCell align="center">Expiry Date</TableCell>
              <TableCell align="right">Quantity To Repurchase</TableCell>
              <TableCell align="right">Net Payable Per Unit</TableCell>
              <TableCell align="right">Net Payable Subtotal</TableCell>
              <TableCell>Request Deal Renewal Instead</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {fields.map(({ id, ...expiringStock }, rowIdx) => {
              return (
                <ExpiringStockRepurchaseRow
                  key={id}
                  id={id}
                  rowIdx={rowIdx}
                  control={form.control}
                  setValue={form.setValue}
                  initialValues={expiringStock}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TotalsCards form={form} />

      <SubmitButtons control={form.control} onCancel={onCancel} key="repurchase" />
    </Stack>
  );
};

const SubmitButtons = ({
  control,
  onCancel,
}: {
  control: Control<ExpiringStockViewDecisionFormValues>;
  onCancel?: () => void;
}) => {
  const { errors } = useFormState({ control });
  const rootErrorMessage = errors.values?.root?.message;
  const showValuesError = errors.values && isArray(errors.values);
  return (
    <Stack spacing={2}>
      {rootErrorMessage && <Alert severity="error">{rootErrorMessage}</Alert>}
      {showValuesError && <Alert severity="error">Please fix the errors above</Alert>}
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        {onCancel && (
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <LoadingActionButton type="submit">Submit Request</LoadingActionButton>
      </Stack>
    </Stack>
  );
};

interface StockToRepurchaseTableProps {
  repurchaseStockViews: ExpiringStockViewDecisionFormValue[];
  netPayableSubtotal: MonetaryValue;
}
const StockToRepurchaseTable = ({ repurchaseStockViews, netPayableSubtotal }: StockToRepurchaseTableProps) => {
  // Note: Assume all rows have the same currency
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="center">Expiry Date</TableCell>
            <TableCell align="right">Quantity To Repurchase</TableCell>
            <TableCell align="right">Net Payable Per Unit</TableCell>
            <TableCell align="right">Net Payable Subtotal</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {repurchaseStockViews.map(view => {
            return (
              <TableRow key={`${view.dealLeg.id}`}>
                <TableCell>
                  <ProductSummary {...view.product.value} />
                </TableCell>
                <TableCell align="center">{formatExpiryDates(view.CSDExpiryDate, view.FSDExpiryDate)}</TableCell>
                <TableCell align="right">
                  {formatNumberOfUnits2(view.maxQuantity, view.product.value.unitType)}
                </TableCell>

                <TableCell align="right">
                  <CurrencyRenderer
                    maximumFractionDigits={4}
                    currency={repurchaseStockViews[0].currency}
                    value={view.repurchaseCostPerUnit}
                  />
                </TableCell>
                <TableCell align="right">
                  <CurrencyRenderer
                    maximumFractionDigits={4}
                    currency={repurchaseStockViews[0].currency}
                    value={view.subtotalRepurchaseCost}
                  />
                </TableCell>
              </TableRow>
            );
          })}
          <SummaryRow
            title="Net Payable Subtotal"
            value={
              <CurrencyRenderer
                variant="subtitle1Bold"
                value={netPayableSubtotal}
                currency={repurchaseStockViews[0].currency}
              />
            }
            tooltip="The cost to repurchase the above expiring stock on the date of the earliest product expiry."
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface SummaryRowProps {
  title: string;
  tooltip?: string;
  value: React.ReactNode;
}
const SummaryRow = ({ title, tooltip, value }: SummaryRowProps) => {
  return (
    <TableRow sx={{ backgroundColor: "common.grey1" }}>
      <TableCell colSpan={2} sx={{ borderBottom: 0 }} />
      <TableCell colSpan={2} align="right">
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Typography variant="subtitle1Bold">{title}</Typography>
          {tooltip && <InfoTooltip title={tooltip} />}
        </Stack>
      </TableCell>
      <TableCell align="right">{value}</TableCell>
    </TableRow>
  );
};

interface DealRenewalsRequestedSectionProps {
  dealRenewalsRequestedStock: OrganisationDealRenewalsRequestedStock;
}
const DealRenewalsRequestedSection = ({ dealRenewalsRequestedStock }: DealRenewalsRequestedSectionProps) => {
  const { pendingReview, dealExtensionRequestCreated } = dealRenewalsRequestedStock;
  const anyExtensionRequestsCreated = dealExtensionRequestCreated.length > 0;
  const anyPendingReview = pendingReview.length > 0;

  const indexedRows = useMemo(() => pendingReview.map((v, rowIdx) => ({ ...v, rowIdx })), [pendingReview]);
  return (
    <Stack spacing={5}>
      {anyExtensionRequestsCreated && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Request No.</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dealExtensionRequestCreated.map(extensionRequest => (
                <TableRow key={`${extensionRequest.dealId}`}>
                  <TableCell>
                    <Link href={`${AppRoutes.OrganisationDealExtensionRequestDetails}/${extensionRequest.dealId}`}>
                      {extensionRequest.dealNumber}
                    </Link>
                  </TableCell>
                  <TableCell>{dealExtensionRequestStateToString(extensionRequest.currentStateEvent.state)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {anyPendingReview && (
        <Stack spacing={2}>
          <Typography>Deal renewal requests for the following products are still under review by Ferovinum.</Typography>
          <PaginatedTableV2
            initialRowsPerPage={25}
            initialRows={indexedRows}
            HeaderContent={
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell align="center">Expiry Date</TableCell>
                <TableCell align="right">Quantity To Renew</TableCell>
              </TableRow>
            }
            renderRow={({ rowIdx, ...view }) => (
              <TableRow key={`${view.product.code}-${rowIdx}`}>
                <TableCell>
                  <ProductSummary {...view.product} />
                </TableCell>
                <TableCell align="center">{formatExpiryDates(view.CSDExpiryDate, view.FSDExpiryDate)}</TableCell>
                <TableCell align="right">
                  {formatNumberOfUnits(view.quantityRequested, view.product.unitType)}
                </TableCell>
              </TableRow>
            )}
          />
        </Stack>
      )}

      {!anyExtensionRequestsCreated && !anyPendingReview && (
        <Typography>There are currently no deal renewals requested.</Typography>
      )}
    </Stack>
  );
};

interface TextWithInfoTooltipProps {
  text: string;
  tooltipText: string;
}
const TextWithInfoTooltip = ({ text, tooltipText }: TextWithInfoTooltipProps) => {
  return (
    <Stack spacing={1} direction="row">
      <Typography>{text}</Typography>
      <InfoTooltip title={tooltipText} />
    </Stack>
  );
};

//Note: These are all a collection of hacks to make the inputs embedded in the text
// pixel perfect like the in the design
const DECISION_COLUMN_WIDTH = "400px";
const EmbeddedTextField = styled(TextField)({
  width: "unset !important",
  minWidth: "95px",
  maxWidth: "95px !important",
  marginBottom: "4px !important",
  [`& .${inputBaseClasses.input}`]: {
    textAlign: "center",
    padding: "0px 8px",
    height: "28px",
  },
});

function dealExtensionRequestStateToString(state: DealExtensionRequestState) {
  switch (state) {
    case "newDeal":
      return "Awaiting new contract note from Ferovinum";
    case "newDealPriced":
      return "Please review the new contract note";
    case "orgAccepted":
      return "Waiting on Ferovinum to renew the deal";
    case "newDealCreated":
      return "Waiting on Ferovinum to send the invoice";
    case "nothingToExtend":
      return "Everything has been repurchased. Nothing to renew.";
    case "invoiced":
      return "Please complete invoice payment";
    case "invoicePaid":
      return "Waiting on Ferovinum to confirm receipt of this invoice payment";
    case "paymentReceived":
      return "Deal renewal completed";
    case "orgRejected":
      return "You rejected the new deal contract note";
    default:
      assertNever(state);
  }
}
