/** Collection of utility function to handle new deal request entity */
import { NewDealRequestState, valuesNewDealRequestState } from "adl-gen/ferovinum/app/db";

export const NEW_STOCK_NEW_DEAL_REQUEST_DEFAULT_ADDITIONAL_TERMS =
  "PO to be raised with supplier(s) by Ferovinum upon payment of Deposit. Where Ferovinum settles purchase currency other than GBP, Sale Price will be refreshed upon settlement of invoice to producer on the basis of spot exchange rates including any fees paid/payable.";
export const EXISTING_STOCK_NEW_DEAL_REQUEST_DEFAULT_ADDITIONAL_TERMS =
  "Client confirms that it has full legal title to Approved Product and that the same remains free of fault and contamination and as per descriptions below.";

export function isEarlierState(reference: NewDealRequestState, currentState: NewDealRequestState): boolean {
  return (
    valuesNewDealRequestState.findIndex(s => s === currentState) <
    valuesNewDealRequestState.findIndex(s => s === reference)
  );
}

export function isLaterState(reference: NewDealRequestState, currentState: NewDealRequestState): boolean {
  return (
    valuesNewDealRequestState.findIndex(s => s === reference) <
    valuesNewDealRequestState.findIndex(s => s === currentState)
  );
}
