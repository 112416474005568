import { SavedDeliveryInfo } from "adl-gen/ferovinum/app/db";
import { useEffect, useMemo, useState } from "react";
import { AppService } from "adl-gen/app-service";
import { useAppService } from "../../../../../hooks/use-app-service";
import { assertNotUndefined } from "utils/hx/util/types";
import { useSelectedOrgFeatures, useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";

type CustomerAddresses = Record<string, SavedDeliveryInfo[]> | undefined;
export const useSelectedCustomerAddress = (getDeliveryPointName: () => string | undefined) => {
  const service: AppService = useAppService();
  const orgFeatures = useSelectedOrgFeatures();
  const selectedOrgId = assertNotUndefined(useSelectedOrgId());
  const [organisationCustomerLocations, setOrganisationCustomerLocations] = useState<CustomerAddresses>(undefined);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);

  // load all the saved delivery info for the organisations customers
  useEffect(() => {
    if (orgFeatures.some(f => f === "sale_order_delivery_to_purchasers")) {
      service.getOrgCustomerSavedDeliveryInfoList({ organisationId: selectedOrgId }).then(locations => {
        const locationsPerCustomer = locations.reduce(
          (acc, location) => {
            if (location.savedDeliveryInfo.length == 0) return acc;

            acc[location.customerName] = location.savedDeliveryInfo;
            return acc;
          },
          {} as { [key: string]: SavedDeliveryInfo[] },
        );
        setOrganisationCustomerLocations(locationsPerCustomer);
      });
    }
  }, [service, selectedOrgId, orgFeatures]);

  // This effect is responsible for keeping the selected customer in sync with the selected address
  useEffect(() => {
    const selectedAddressKey = getDeliveryPointName();
    if (!selectedAddressKey) return;

    const customer =
      (organisationCustomerLocations &&
        Object.keys(organisationCustomerLocations).find(key =>
          organisationCustomerLocations[key].some(loc => loc.deliveryPointName === selectedAddressKey),
        )) ||
      null;
    if (customer !== selectedCustomer) {
      setSelectedCustomer(customer);
    }
  }, [getDeliveryPointName, organisationCustomerLocations, selectedCustomer]);

  const { selectableCustomers, selectableCustomerAddresses } = useMemo(() => {
    if (!organisationCustomerLocations)
      return { selectableCustomers: undefined, selectableCustomerAddresses: undefined };

    const customers = Object.keys(organisationCustomerLocations || {});
    const addresses = selectedCustomer
      ? organisationCustomerLocations[selectedCustomer]
      : customers.flatMap(key => organisationCustomerLocations[key]);

    return {
      selectableCustomers: customers,
      selectableCustomerAddresses: addresses,
    };
  }, [selectedCustomer, organisationCustomerLocations]);

  return { selectedCustomer, setSelectedCustomer, selectableCustomers, selectableCustomerAddresses };
};
