/** This prefix is used for all routes in the admin portal app
 * - adminui lives in a subfolder of the main ferovinum domain
 * - react-router uses this to create the correct url in the browser address bar
 * - the nginx server uses this to route to serve assets from the correct folder when running in production mode
 * */
export const APP_ROUTER_BASENAME = "/adminui";
/** All routes in the admin portal app */
export const enum AppRoutes {
  Index = "/",
  Login = "/login",
  Logout = "/logout",
  Admin = "/adminui",
  Main = "/main",
}
