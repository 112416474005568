import * as CSS from "csstype";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepContent,
  StepLabel,
  stepLabelClasses,
  Stepper,
  Typography,
} from "@mui/material";
import { NewDealRequestState, NewDealRequestStateEvent } from "adl-gen/ferovinum/app/db";
import { formatInstant, formatLocalDate } from "utils/date-utils";
import { isString } from "utils/ts-utils";
import { styled } from "@mui/material/styles";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { LocalDate } from "adl-gen/common";

/** Utility function to transform events from a procurement flow into steps data used in this widget */
export function newDealRequestStateEventsToStepsData(
  events: NewDealRequestStateEvent[],
  supplierEarliestCollectionDate: LocalDate | null,
  carrierEarliestCollectionDate: LocalDate | null,
): Partial<Record<NewDealRequestState, NewDealRequestStateStepData>> {
  return events.reduce((m, e) => {
    switch (e.state) {
      case "collectionRequested": {
        // @ts-ignore
        m[e.state] = {
          instant: e.time,
          description: supplierEarliestCollectionDate
            ? `Earliest collection ${formatLocalDate(supplierEarliestCollectionDate)}`
            : undefined,
        };
        break;
      }
      case "collectionAccepted": {
        // @ts-ignore
        m[e.state] = {
          instant: e.time,
          description: carrierEarliestCollectionDate
            ? `Confirmed collection ${formatLocalDate(carrierEarliestCollectionDate)}`
            : undefined,
        };
        break;
      }
      default: {
        // @ts-ignore
        m[e.state] = { instant: e.time };
        break;
      }
    }
    return m;
  }, {});
}

export interface NewDealRequestStep<T extends string = NewDealRequestState> {
  state: NewDealRequestState | T;
  label: string;
  description?: string;
}

export interface NewDealRequestStateStepData {
  instant: number | string;
  description?: string;
}
/// Note: T is a subset of procurement states
export interface NewDealRequestStateStepperProps<T extends string = NewDealRequestState> {
  /// Current new deal request state
  currentState?: NewDealRequestState | T;
  /// Additional data attached to a subset of the steps
  stepsData: Partial<Record<NewDealRequestState | T, NewDealRequestStateStepData>>;
  /// Optional function that returns the procurement steps for a given new deal request state
  /// Allows customization of the steps
  getNewDealRequestStateSteps: (
    currentState?: NewDealRequestState | T,
  ) => NewDealRequestStep<NewDealRequestState | T>[];
  /// If this prop is set the stepper will be scrollable and auto-scroll to the active step
  maxHeight?: CSS.Property.Height;
}

/// Custom styled components
const NewDealRequestStateStepperConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: { borderLeftStyle: "dashed" },
}));

/// Base procurement stepper - use user specific stepper instead (admin, org, supplier, carrier...)
/// Contains shared UI and common logic to be shared by specific steppers
export const NewDealRequestStateStepper = <T extends string = NewDealRequestState>({
  currentState,
  stepsData,
  getNewDealRequestStateSteps,
  maxHeight,
}: NewDealRequestStateStepperProps<T>) => {
  const steps = useMemo(() => getNewDealRequestStateSteps(currentState), [currentState, getNewDealRequestStateSteps]);
  /** The active step in the stepper */
  const activeStep: number = useMemo(() => {
    const currentStateIndex = steps.findIndex(v => v.state === currentState);
    /** The active step is the following step from the current state in the procurement flow (hence the +1) */
    return currentStateIndex + 1;
  }, [currentState, steps]);
  /** The step being scrolled to */
  const focusedStep: number = useMemo(() => {
    const lastStepIndex = steps.length - 1;
    return activeStep > lastStepIndex ? lastStepIndex : activeStep;
  }, [activeStep, steps.length]);
  const getTimestamp = useCallback(
    (step: NewDealRequestStep<NewDealRequestState | T>) => {
      const instant = stepsData[step.state]?.instant;
      const occuredOn = instant ? (isString(instant) ? instant : formatInstant(instant)) : undefined;

      //HACK: Fix this. This component was meant to be agnostic to specific step states
      if (step.state === "collectionRequested" || step.state === "collectionAccepted") {
        return occuredOn ? `on ${occuredOn}. ${stepsData[step.state]?.description}` : undefined;
      }

      return occuredOn;
    },
    [stepsData],
  );
  const getDescription = useCallback(
    (step: NewDealRequestStep<NewDealRequestState | T>) => {
      const description = stepsData[step.state]?.description;
      return description ?? undefined;
    },
    [stepsData],
  );

  const containerRef = React.createRef<HTMLDivElement>();
  const focusedStepRef = React.createRef<HTMLDivElement>();
  useEffect(() => {
    if (focusedStepRef.current && containerRef.current) {
      containerRef.current.scrollTop =
        focusedStepRef.current.offsetTop -
        containerRef.current.clientHeight / 2 +
        focusedStepRef.current.clientHeight / 2;
    }
  }, [focusedStepRef, containerRef]);

  return (
    <Box ref={containerRef} maxHeight={maxHeight} overflow={maxHeight ? "auto" : undefined} position={"relative"}>
      <Stepper orientation="vertical" activeStep={activeStep} connector={<NewDealRequestStateStepperConnector />}>
        {steps.map((step, idx) => {
          const timestamp = getTimestamp(step);
          const description = getDescription(step);

          return (
            <Step key={step.label} ref={focusedStep === idx ? focusedStepRef : undefined}>
              <StepLabel
                icon={
                  idx >= activeStep ? (
                    <Box color="common.grey">
                      <CircleOutlinedIcon color="inherit" />
                    </Box>
                  ) : undefined
                }
                optional={timestamp}
                sx={{ [`& .${stepLabelClasses.labelContainer}`]: { ml: 4 } }}>
                <Typography>{step.label}</Typography>
              </StepLabel>
              {(description || step.description) && (
                <StepContent>
                  {step.description?.length && <Typography>{step.description}</Typography>}
                  {description && <Typography>{description}</Typography>}
                </StepContent>
              )}
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
