import { createContext, useContext } from "react";
import { GuardedFeature } from "adl-gen/ferovinum/app/db";
import { UiConfig } from "adl-gen/ferovinum/app/uiconfig";

export interface UIConfigContextProps extends UiConfig {
  features: GuardedFeature[];
}

interface EnabledFeatureFlags {
  showProcurement: boolean;
  showExistingStockProcurement: boolean;
  showNewStockProcurement: boolean;
  showNominatedPurchaser: boolean;
  showProductionOrder: boolean;
  showStockMovement: boolean;
  showExpiryRoll: boolean;
  showStockRevaluation: boolean;
  dashboardPagination: boolean;
  inventoryPagination: boolean;
}

export const UiConfigContext = createContext<UIConfigContextProps>({
  environment: "",
  releaseName: "",
  title: "",
  features: [],
  hotjarConfig: {
    siteId: "",
    version: "",
  },
});

export type UiConfigValues = EnabledFeatureFlags & UiConfig;
export const useUiConfig = (): UiConfigValues => {
  const ctx = useContext(UiConfigContext);
  const featureSet = new Set(ctx.features);

  return {
    ...ctx,
    showProcurement:
      featureSet.has("existingStockProcurement") ||
      featureSet.has("newStockProcurement"),
    showExistingStockProcurement: featureSet.has("existingStockProcurement"),
    showNewStockProcurement: featureSet.has("newStockProcurement"),
    showNominatedPurchaser: featureSet.has("nominatedPurchaser"),
    showProductionOrder: featureSet.has("productionOrder"),
    showExpiryRoll: featureSet.has("expiryRoll"),
    showStockMovement: featureSet.has("stockMovement"),
    showStockRevaluation: featureSet.has("stockRevaluation"),
    dashboardPagination: featureSet.has("dashboardPagination"),
    inventoryPagination: featureSet.has("inventoryPagination"),
  };
};
