import { Grid, Stack } from "@mui/material";
import { Product } from "adl-gen/ferovinum/app/db";
import { FormField } from "components/types/formik-types";
import { ColumnDef, GridTable } from "components/widgets/grid-table/grid-table";
import React from "react";
import { WithDbId } from "adl-gen/common/db";
import { BaseWizardCard } from "components/library/widgets/wizard-card/base-wizard-card";
import { ProductSearch } from "../../../../../apps/client/src/ui/widgets/product-data/product-search";
export function ProductLookupTableCard<T extends object>(props: {
  title: string;
  subtitle: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  productTableColumns: ColumnDef<T, any>[];
  inputRowsField: FormField<T[]>;
  searchProducts: (searchTerm: string) => Promise<WithDbId<Product>[]>;
  onAddProduct: (product: WithDbId<Product>) => void;
  uniqueProductCodes: Set<string>;
  isLoading: boolean;
}) {
  const {
    title,
    subtitle,
    productTableColumns,
    inputRowsField,
    searchProducts,
    onAddProduct,
    uniqueProductCodes,
    isLoading,
  } = props;

  return (
    <BaseWizardCard
      title={title}
      subtitle={subtitle}
      inputElement={
        <Stack direction={"column"} spacing={2} width={"100%"}>
          <Grid container spacing={2}>
            <Grid item xl={5} xs={12}>
              <ProductSearch
                searchProducts={searchProducts}
                onSelectProduct={onAddProduct}
                selectedProductCodes={uniqueProductCodes}
                disabled={isLoading}
              />
            </Grid>
            {/* <Grid item xs={6}>
          <div>TODO: Add Product by CSV</div>
        </Grid> */}
          </Grid>
          <Stack direction={"row"}></Stack>
          <GridTable
            columns={productTableColumns}
            inputRowsField={inputRowsField}
            tableHeight={600}
            disabled={isLoading}
          />
        </Stack>
      }
    />
  );
}
