import React, { useContext } from "react";
import { AlertManagerProps } from "./alert-manager";
import { AlertColor } from "@mui/material";

export const DEFAULT_ALERT_MANAGER_VALUES: AlertManagerProps = {
  title: "",
  body: "",
  isShown: false,
  showDismissButton: true,
};

export interface OpenAlertProps {
  title: string;
  body: React.ReactNode;
  severity?: AlertColor;
  showDismissButton?: boolean;
}

type AlertContextCallbacks = () => [(props: OpenAlertProps) => Promise<void>, () => Promise<void>];
export interface AlertContextProps extends AlertManagerProps {
  openAlert: (props: OpenAlertProps) => Promise<void>;
  closeAlert: () => Promise<void>;
}

export const AlertContext = React.createContext<AlertContextProps>({
  ...DEFAULT_ALERT_MANAGER_VALUES,
  openAlert: ({}) => Promise.resolve(),
  closeAlert: () => Promise.resolve(),
});

export const useAlert: AlertContextCallbacks = () => {
  const { openAlert, closeAlert } = useContext(AlertContext);
  return [openAlert, closeAlert];
};
