import { DbKey } from "adl-gen/common/db";
import { DealLeg } from "adl-gen/ferovinum/app/db";
import { LoadingValue } from "utils/utility-types";
import { Dispatch, Reducer, useCallback, useContext, useEffect, useReducer, useRef } from "react";
import * as _ from "lodash";
import { AppService } from "adl-gen/app-service";
import { assertNotUndefined } from "utils/hx/util/types";
import { LoggedInContext } from "../app/app";
import { CalculatedFsp } from "adl-gen/ferovinum/app/api";
import { Map as AdlMap } from "adl-gen/sys/types";
import { assertNever } from "assert-never";
import { FSP } from "adl-gen/ferovinum/app/views";
export type LoadedFspValues = Record<DbKey<DealLeg>, LoadingValue<FSP>>;

const init = (dealLegIds: DbKey<DealLeg>[]): LoadedFspValues => {
  const initialValue: LoadedFspValues = {};
  dealLegIds.forEach(id => {
    initialValue[id] = { state: "loading" };
  });
  return initialValue;
};

interface AddFetchedResultsAction {
  kind: "addResults";
  value: AdlMap<DbKey<DealLeg>, CalculatedFsp>;
}

interface AddMoreDealLegsAction {
  kind: "addMoreDealLegs";
  value: DbKey<DealLeg>[];
}

type Action = AddFetchedResultsAction | AddMoreDealLegsAction;

const reducer: Reducer<LoadedFspValues, Action> = (state: LoadedFspValues, action: Action) => {
  const clonedState: LoadedFspValues = { ...state };
  if (action.kind === "addResults") {
    action.value.forEach(({ key: dealLegId, value: resp }) => {
      clonedState[dealLegId] =
        resp.kind === "success"
          ? { state: "success", value: resp.value }
          : { state: "error", error: new Error(resp.value) };
    });
    return clonedState;
  } else if (action.kind === "addMoreDealLegs") {
    action.value.forEach(dealLegId => {
      clonedState[dealLegId] = { state: "loading" };
    });
    return clonedState;
  } else {
    assertNever(action);
  }
};

class FspFetcher {
  private mounted = true;
  private existingIds: Set<DbKey<DealLeg>> = new Set();

  constructor(
    private readonly service: Pick<AppService, "getFspByDate">,
    private readonly dispatch: Dispatch<Action>,
    dealLegIds: DbKey<DealLeg>[],
  ) {
    this.addDealLegIds(dealLegIds);
  }

  private async fetchNewDealLegs(groupedIds: DbKey<DealLeg>[][]) {
    for (const idGroup of groupedIds) {
      const loadedValues = await this.service.getFspByDate({
        date: null,
        dealLegIds: idGroup,
      });
      if (this.mounted) {
        this.dispatch({ kind: "addResults", value: loadedValues });
      } else {
        return;
      }
    }
  }

  addDealLegIds(dealLegIds: DbKey<DealLeg>[]) {
    const filteredDealLegIds = Array.from(dealLegIds).filter(id => !this.existingIds.has(id));
    if (filteredDealLegIds.length === 0) {
      return;
    }
    this.dispatch({ kind: "addMoreDealLegs", value: filteredDealLegIds });

    filteredDealLegIds.forEach(this.existingIds.add, this.existingIds);

    void this.fetchNewDealLegs(_.chunk(filteredDealLegIds, 100));
  }

  unmount() {
    this.mounted = false;
  }
}

export const useFspState = (dealLegIds: DbKey<DealLeg>[]) => {
  const [fspValues, dispatch] = useReducer(reducer, dealLegIds, init);

  const service: AppService = assertNotUndefined(useContext(LoggedInContext).loginState?.user?.apis.app);

  const fspFetcherRef = useRef<FspFetcher>();

  useEffect(() => {
    // NOTE(Barry): Because we just run off and try to fetch all the FSP values in batches, we need to stop fetching
    // when the component is no longer mounted.
    fspFetcherRef.current = new FspFetcher(service, dispatch, dealLegIds);
    const fspFetcher = fspFetcherRef.current;
    return () => {
      fspFetcher.unmount();
    };
    // NOTE(Barry): I only want this to run on first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fspFetcher = fspFetcherRef.current;
    fspFetcher?.addDealLegIds(dealLegIds);
  }, [dealLegIds]);

  // calling this function causes new fsp value entries (in loading state) to be immediately
  // added to the `fspValues` and later replaced with the actual values when the fetch completes
  const fetchFspForDealLegs = useCallback((dealLegIds: DbKey<DealLeg>[]) => {
    fspFetcherRef.current?.addDealLegIds(dealLegIds);
  }, []);

  return { fspValues, fetchFspForDealLegs };
};
