import React, { useCallback, useMemo } from "react";
import {
  DeliveryPurchaserRequestFormValues,
  OrganisationPurchaseRequestDeliveryOptionsPageView,
  PaymentResponsibilityOption,
} from "./organisation-purchase-request-delivery-options-page-view";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { PurchaseRequestFlowState } from "../organisation-purchase-request-setup/organisation-purchase-request-setup-page";
import { AppRoutes } from "../../../../../app/app-routes";
import { PurchaseRequestDeliveryOption } from "adl-gen/ferovinum/app/db";
import { assertNever } from "assert-never";
import { assertNotUndefined } from "utils/hx/util/types";
import { formatDateToLocalTime, formatDateToISO8601 } from "utils/date-utils";

export const OrganisationPurchaseRequestDeliveryOptionsPage = () => {
  const location = useLocation();
  const history = useHistory();
  const purchaseRequestFlowState = location.state as PurchaseRequestFlowState | undefined;

  const deliveryToUkIsAvailable = useMemo(() => {
    const setupFlowState = assertNotUndefined(purchaseRequestFlowState?.setup);
    return assertNotUndefined(setupFlowState.storageLocation).value.hasD2cFulfilment;
  }, [purchaseRequestFlowState?.setup]);

  const storageLocationName = useMemo(() => {
    const setupFlowState = assertNotUndefined(purchaseRequestFlowState?.setup);
    return assertNotUndefined(setupFlowState.storageLocation).value.locationName;
  }, [purchaseRequestFlowState?.setup]);

  const isBondedSale = useMemo(() => {
    const setupFlowState = assertNotUndefined(purchaseRequestFlowState?.setup);
    return assertNotUndefined(setupFlowState.bondedSale);
  }, [purchaseRequestFlowState?.setup]);

  const onNext = async (values: DeliveryPurchaserRequestFormValues) => {
    const newState: PurchaseRequestFlowState = {
      ...purchaseRequestFlowState,
      delivery: {
        preparationDaysType: assertNotUndefined(values.preparationDaysType),
        preparationDays: assertNotUndefined(values.preparationDays),
        deliveryOption: toDeliveryOption(values, purchaseRequestFlowState?.setup?.bondedSale ?? false),
        purchaserCoversDeliveryCost: values.deliveryFeePaidBy === PaymentResponsibilityOption.Purchaser,
        customerPoRef: values.customerPoRef,
        paymentTermsPeriod: {
          kind: values.paymentTermsType,
          value:
            values.paymentTermsType === "daysAfterCollection"
              ? values.numDaysAfterDelivery
              : values.numMonthsFollowingDelivery,
        },
        deliveryInstructionDocs: values.deliveryInstructionDocs,
      },
    };
    history.push(AppRoutes.OrganisationPurchaseRequestConfirmation, newState);
  };

  const onBack = useCallback(() => {
    if (purchaseRequestFlowState?.productionPlan !== undefined) {
      history.push(AppRoutes.OrganisationPurchaseRequestProductionPlan, purchaseRequestFlowState);
    } else {
      history.push(AppRoutes.OrganisationCreatePurchaseRequest, purchaseRequestFlowState);
    }
  }, [history, purchaseRequestFlowState]);

  if (purchaseRequestFlowState?.setup === undefined) {
    return <Redirect to={AppRoutes.Index} />;
  } else {
    return (
      <OrganisationPurchaseRequestDeliveryOptionsPageView
        purchaser={purchaseRequestFlowState.setup.purchaser}
        initialValues={purchaseRequestFlowState.delivery}
        deliveryToUkIsAvailable={deliveryToUkIsAvailable}
        hasProductionPlanAttached={purchaseRequestFlowState?.productionPlan !== undefined}
        storageLocationName={storageLocationName}
        nominatedPurchaserPaymentTerms={purchaseRequestFlowState.setup.purchaser.paymentTermsPeriod}
        isBondedSale={isBondedSale}
        onNext={onNext}
        onBack={onBack}
      />
    );
  }
};

function toDeliveryOption(
  values: DeliveryPurchaserRequestFormValues,
  bondedSale: boolean,
): PurchaseRequestDeliveryOption {
  switch (values.deliveryType) {
    case "collectFromStorageLocation":
      return {
        kind: "collectFromStorageLocation",
        value: {
          nominatedPurchaserCollection: { bonded: bondedSale },
          incoterms: assertNotUndefined(values.collectionIncoterms),
          purchaserCollectionDetails: { kind: "notAvailable" },
        },
      };
    case "deliveryToNominatedPurchaser":
      return {
        kind: "deliveryToNominatedPurchaser",
        value: {
          incoterms: assertNotUndefined(values.deliveryIncoterms),
          deliveryDetails: {
            ...values.deliveryDetails,
            bonded: bondedSale,
            // Need to put the dates into the right format for the API
            deliveryDate: values.deliveryDetails.deliveryDate
              ? formatDateToISO8601(values.deliveryDetails.deliveryDate)
              : null,
            deliveryTimeEarliest: values.deliveryDetails.deliveryTimeEarliest
              ? formatDateToLocalTime(values.deliveryDetails.deliveryTimeEarliest)
              : null,
            deliveryTimeLatest: values.deliveryDetails.deliveryTimeLatest
              ? formatDateToLocalTime(values.deliveryDetails.deliveryTimeLatest)
              : null,
          },
        },
      };
    default:
      assertNever(values.deliveryType);
  }
}
