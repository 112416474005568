import { LoadingValue } from "../utility-types";
import { useCallback, useEffect, useRef, useState } from "react";

type LoadingValuePromise<T> = () => Promise<T>;

export const useLoadingDataState = <T>(
  fn: LoadingValuePromise<T>,
  // If this parameter is set the loading value will transition between loading and success every time we need to re-fetch
  withVisualRefresh?: boolean,
): [LoadingValue<T>, () => Promise<void>] => {
  const [loadingValue, setLoadingValue] = useState<LoadingValue<T>>({ state: "loading" });
  const mountedRef = useRef(true);

  const loadValue = useCallback(
    async (fn: LoadingValuePromise<T>) => {
      if (withVisualRefresh) {
        setLoadingValue({ state: "loading" });
      }
      return await fn()
        .then(value => {
          if (mountedRef.current) {
            setLoadingValue({ state: "success", value });
          }
        })
        .catch(err => {
          if (mountedRef.current) {
            setLoadingValue({ state: "error", error: err });
          }
        });
    },
    [withVisualRefresh],
  );

  useEffect(() => {
    void loadValue(fn);
  }, [fn, loadValue]);

  // Note: if the component is no longer mounted avoid setting the state in loadValue
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const refresh = useCallback(async () => await loadValue(fn), [fn, loadValue]);

  return [loadingValue, refresh];
};
