import { StorageLocationView } from "adl-gen/ferovinum/app/api";
import { DeliveryOptionReq } from "adl-gen/ferovinum/app/deliveryoptions";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { WithDbId } from "adl-gen/common/db";
import {
  OrganisationDeliveryLocation,
  OrganisationDeliveryLocationId,
  StorageLocationId,
} from "adl-gen/ferovinum/app/db";
import { useFormik } from "formik";
import { formatDateToISO8601, formatDateToLocalTime, parseLocalTimeToDate } from "utils/date-utils";
import { object, string } from "yup";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import {
  DeliveryDetailsWidget,
  INITIAL_ADDRESS_VALUES,
  setDeliveryDetailsSchemaShape,
} from "../../../../widgets/common/delivery-details/delivery-details-widget";
import { RepurchaseFlowStepper } from "../../../../widgets/flow-stepper/repurchase-flow-stepper";
import { RepurchaseRequest } from "../organisation-repurchase-stock/organisation-repurchase-stock-page";
import { FormikForm } from "components/types/formik-types";
import { ensure } from "utils/null-and-undefined-utils";
import { OptionPicker } from "components/library/helpers/option-picker";
import { useSelectedCustomerAddress } from "./useSelectedCustomerAddress";

export interface DeliveryFormValues {
  selectedType: DeliveryType;
  accountCode: string;
  siteCode: string;
  locationId: OrganisationDeliveryLocationId;
  address: {
    deliveryPointName: string;
    streetName: string;
    addressLine1: string;
    addressLine2: string;
    town: string;
    postCode: string;
    deliveryContact: string;
    deliveryContactEmail: string;
    deliveryContactNumber: string;
    deliveryInstructions: string;
    deliveryDate: Date | null;
    deliveryTimeEarliest: Date | null;
    deliveryTimeLatest: Date | null;
  };
}

function getInitialFormValues(
  initialReqValues: DeliveryOptionReq,
  storageLocation: StorageLocationView,
): DeliveryFormValues {
  let selectedType: DeliveryType = "warehouse";
  let accountCode = "";
  let siteCode = storageLocation.siteCode ?? "";
  let locationId = "";
  let address = INITIAL_ADDRESS_VALUES as DeliveryFormValues["address"];
  switch (initialReqValues.kind) {
    case "inWarehouseTransfer":
      break;
    case "inWarehouseTransferWithAccountCode":
      accountCode = initialReqValues.value.accountCode;
      siteCode = initialReqValues.value.siteCode ?? "";
      break;
    case "orgLocationDelivery":
      selectedType = "savedDeliveryLocation";
      locationId = initialReqValues.value;
      break;
    case "shippedDelivery":
      selectedType = "customerLocation";
      const v = initialReqValues.value;
      address = {
        deliveryPointName: v.deliveryPointName ?? "",
        streetName: v.streetName ?? "",
        addressLine1: v.addressLine1 ?? "",
        addressLine2: v.addressLine2 ?? "",
        town: v.town ?? "",
        postCode: v.postCode ?? "",
        deliveryContact: v.deliveryContact ?? "",
        deliveryContactEmail: v.deliveryContactEmail ?? "",
        deliveryContactNumber: v.deliveryContactNumber ?? "",
        deliveryInstructions: v.deliveryInstructions ?? "",
        deliveryDate: v.deliveryDate ? new Date(v.deliveryDate) : null,
        deliveryTimeEarliest: v.deliveryTimeEarliest ? parseLocalTimeToDate(v.deliveryTimeEarliest) : null,
        deliveryTimeLatest: v.deliveryTimeLatest ? parseLocalTimeToDate(v.deliveryTimeLatest) : null,
      };
      break;
    default:
      throw new Error(`Invalid delivery option type for repurchase: [${initialReqValues.kind}]`);
  }
  return {
    selectedType,
    accountCode,
    siteCode,
    locationId,
    address,
  };
}

const today = new Date();
today.setHours(0, 0, 0, 0);

export interface OrganisationRepurchaseDeliveryPageViewProps {
  repurchaseRequest: RepurchaseRequest;
  storageLocation: StorageLocationView;
  organisationDeliveryLocations: WithDbId<OrganisationDeliveryLocation>[];
  internalTransferLocations: StorageLocationView[];
  initialDeliveryReq: DeliveryOptionReq;
  onConfirm(deliveryReq: DeliveryOptionReq, destinationLocationId?: StorageLocationId): void;
  onBack(): void;
}
export const OrganisationRepurchaseDeliveryPageView = (props: OrganisationRepurchaseDeliveryPageViewProps) => {
  const { initialDeliveryReq, storageLocation, onConfirm, onBack } = props;
  const validationSchema = useMemo(
    () =>
      object().shape({
        selectedType: string().required(),
        accountCode: string()
          .trim()
          .when("selectedType", {
            is: (selectedType: string) =>
              selectedType === "warehouse" && storageLocation.requiresAccountCodeForInWarehouseTransfer,
            then: schema => schema.required(),
          }),
        siteCode: string().trim().optional(),
        locationId: string().when("selectedType", {
          is: (selectedType: string) => selectedType === "savedDeliveryLocation",
          then: schema => schema.required(),
        }),
        address: object().when("selectedType", {
          is: (selectedType: string) => selectedType === "customerLocation",
          then: schema => setDeliveryDetailsSchemaShape(schema),
        }),
      }),
    [storageLocation.requiresAccountCodeForInWarehouseTransfer],
  );

  const onSubmit = useCallback(
    (formValues: DeliveryFormValues) => {
      //Note(Berto): Beware of .trim() and other transformations functionalities in yup, it will only apply the transformations
      // for validation purposes and not for the actual submitted values
      // We need to manually cast the submitted values in order to apply yup transformations
      const values = validationSchema.cast(formValues) as unknown as DeliveryFormValues;
      let destinationLocationId: string | undefined = storageLocation.id;
      let request: DeliveryOptionReq = {
        kind: "inWarehouseTransfer",
        value: {},
      };
      switch (values.selectedType) {
        case "warehouse":
          if (values.accountCode) {
            request = {
              kind: "inWarehouseTransferWithAccountCode",
              value: {
                siteCode: values.siteCode,
                accountCode: values.accountCode,
              },
            };
          }
          destinationLocationId = props.internalTransferLocations.find(loc => loc.siteCode === values.siteCode)?.id;
          break;
        case "savedDeliveryLocation":
          request = { kind: "orgLocationDelivery", value: values.locationId };
          break;
        case "customerLocation":
          request = {
            kind: "shippedDelivery",
            value: {
              ...values.address,
              bonded: false,
              deliveryDate: values.address.deliveryDate ? formatDateToISO8601(values.address.deliveryDate) : null,
              deliveryTimeEarliest: values.address.deliveryTimeEarliest
                ? formatDateToLocalTime(values.address.deliveryTimeEarliest)
                : null,
              deliveryTimeLatest: values.address.deliveryTimeLatest
                ? formatDateToLocalTime(values.address.deliveryTimeLatest)
                : null,
            },
          };
      }
      onConfirm(request, destinationLocationId);
    },
    [onConfirm, props.internalTransferLocations, storageLocation.id, validationSchema],
  );

  const initialValues = useMemo(
    () => getInitialFormValues(initialDeliveryReq, storageLocation),
    [initialDeliveryReq, storageLocation],
  );
  const form = useFormik<DeliveryFormValues>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <PortalPageContent header={<OrganisationRepurchaseDeliveryStockHeader />}>
      <Stack spacing={7} justifyContent="space-between">
        <AvailableDeliveryOptions form={form} {...props} />
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={onBack}>
            Back
          </Button>
          <Button onClick={() => form.submitForm()} disabled={!form.isValid}>
            Next
          </Button>
        </Stack>
      </Stack>
    </PortalPageContent>
  );
};

const OrganisationRepurchaseDeliveryStockHeader = () => {
  return (
    <PortalPageContentHeader
      variant="split"
      title="Repurchase Stock"
      right={<RepurchaseFlowStepper activeStep={1} />}
    />
  );
};

function getAvailableDeliveryOptions(
  repurchaseRequest: RepurchaseRequest,
  storageLocationView: StorageLocationView,
  organisationDeliveryLocations: WithDbId<OrganisationDeliveryLocation>[],
): Record<DeliveryType, boolean> {
  const availableDeliveryOptions: Record<DeliveryType, boolean> = {
    warehouse: true,
    savedDeliveryLocation: false,
    customerLocation: false,
  };
  if (repurchaseRequest.type !== "cask" && storageLocationView.availableForShipping) {
    if (organisationDeliveryLocations.length > 0) {
      availableDeliveryOptions.savedDeliveryLocation = true;
    }
    availableDeliveryOptions.customerLocation = true;
  }
  return availableDeliveryOptions;
}

type DeliveryType = "warehouse" | "savedDeliveryLocation" | "customerLocation";
const AvailableDeliveryOptions = ({
  repurchaseRequest,
  storageLocation,
  organisationDeliveryLocations,
  internalTransferLocations,
  form,
}: {
  repurchaseRequest: RepurchaseRequest;
  storageLocation: StorageLocationView;
  organisationDeliveryLocations: WithDbId<OrganisationDeliveryLocation>[];
  internalTransferLocations: StorageLocationView[];
  initialDeliveryReq: DeliveryOptionReq;
  form: FormikForm<DeliveryFormValues>;
}) => {
  const getDeliveryPointName = useCallback(() => form.values.address.deliveryPointName, [form.values.address]);
  const { selectedCustomer, setSelectedCustomer, selectableCustomers, selectableCustomerAddresses } =
    useSelectedCustomerAddress(getDeliveryPointName);

  const availableDeliveryOptions = useMemo(
    () => getAvailableDeliveryOptions(repurchaseRequest, storageLocation, organisationDeliveryLocations),
    [organisationDeliveryLocations, repurchaseRequest, storageLocation],
  );

  const onChange = useCallback(
    (_e, value) => {
      form.setFieldValue("selectedType", value);
    },
    [form],
  );

  const isSelected = useCallback(
    (option: DeliveryType) => form.values.selectedType === option,
    [form.values.selectedType],
  );

  const { canSelectSiteCode, siteCodeIsRequired } = useMemo(
    () => ({
      canSelectSiteCode: internalTransferLocations.length > 1,
      siteCodeIsRequired: internalTransferLocations.length >= 1,
    }),
    [internalTransferLocations.length],
  );

  return (
    <Stack spacing={2}>
      <FormControl>
        <RadioGroup value={form.values.selectedType} onChange={onChange} name="selectedType">
          {availableDeliveryOptions.warehouse && (
            <RadioDeliveryOption selected={isSelected("warehouse")} value="warehouse" label="Keep it in the warehouse">
              <Grid container direction={"column"} gap={2}>
                <Grid item xs={12} lg={6}>
                  <Typography variant="caption">
                    {`Please input ${canSelectSiteCode ? "the site code, and" : ""} your account code below. `}
                    {!storageLocation.requiresAccountCodeForInWarehouseTransfer &&
                      "If you don’t have one, you can leave this field blank."}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid container direction={"row"} gap={2}>
                    {siteCodeIsRequired && (
                      <Grid item xs={6} lg={3}>
                        <FormControl fullWidth>
                          <InputLabel id="locationId-label">Site Code</InputLabel>
                          <Select
                            id="siteCode"
                            labelId="siteCode"
                            autoFocus={false}
                            name="siteCode"
                            label="Site Code"
                            value={form.values.siteCode}
                            onChange={form.handleChange}
                            required={siteCodeIsRequired}
                            disabled={!canSelectSiteCode}>
                            {internalTransferLocations.map(loc => (
                              <MenuItem value={ensure(loc.siteCode)} key={loc.siteCode}>
                                {loc.siteCode} ({loc.storageLocationName})
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={6} lg={3}>
                      <TextField
                        fullWidth={true}
                        name="accountCode"
                        label="Account code"
                        required={storageLocation.requiresAccountCodeForInWarehouseTransfer}
                        value={form.values.accountCode}
                        onChange={form.handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </RadioDeliveryOption>
          )}
          {availableDeliveryOptions.savedDeliveryLocation && (
            <RadioDeliveryOption
              selected={isSelected("savedDeliveryLocation")}
              value="savedDeliveryLocation"
              label="Deliver to my saved location">
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <InputLabel id="locationId-label">Saved locations</InputLabel>
                    <Select
                      id="locationId"
                      labelId="locationId-label"
                      autoFocus={false}
                      name="locationId"
                      label="Saved locations"
                      value={form.values.locationId}
                      onChange={form.handleChange}>
                      {organisationDeliveryLocations.map(loc => (
                        <MenuItem value={loc.id} key={loc.id}>
                          {loc.value.deliveryPointName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </RadioDeliveryOption>
          )}
          {availableDeliveryOptions.customerLocation && (
            <RadioDeliveryOption
              selected={isSelected("customerLocation")}
              value="customerLocation"
              label="Deliver to my customer">
              <Grid container spacing={4}>
                {selectableCustomers && (
                  <Grid item xs={12} lg={6}>
                    <Stack spacing={2}>
                      <FormLabel component="legend">Customer</FormLabel>
                      <OptionPicker
                        value={selectedCustomer}
                        options={selectableCustomers}
                        disabled={selectableCustomers.length === 0}
                        onValueChange={value => {
                          form.setFieldValue("address", INITIAL_ADDRESS_VALUES);
                          setSelectedCustomer(value ? value : null);
                        }}
                        textFieldProps={{
                          label: "Select Customer",
                        }}
                      />
                    </Stack>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <DeliveryDetailsWidget
                    form={form}
                    formKey="address"
                    storageLocationName={storageLocation.storageLocationName}
                    savedDeliveryInfoList={selectableCustomerAddresses}
                  />
                </Grid>
              </Grid>
            </RadioDeliveryOption>
          )}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

const RadioDeliveryOption = ({
  value,
  label,
  selected,
  children,
}: {
  value: DeliveryType;
  label: string;
  selected: boolean;
  children?: React.ReactNode;
}) => {
  const radioRef = useRef<HTMLButtonElement | null>(null);
  const [radioWidth, setRadioWidth] = useState<number | null>(null);

  useEffect(() => {
    if (radioRef.current) {
      setRadioWidth(radioRef.current.getBoundingClientRect().width - 9);
    }
  }, []);

  return (
    <>
      <FormControlLabel
        sx={{ ...(selected && { alignItems: "flex-start", pt: 1 }) }}
        value={value}
        control={<Radio ref={radioRef} sx={{ ...(selected && { pt: 0 }) }} />}
        componentsProps={{ typography: { flex: 1 } }}
        label={
          <Stack spacing={2}>
            <Typography variant={"body1"}>{label}</Typography>
          </Stack>
        }
      />
      {radioWidth !== null && (
        <Collapse in={selected}>
          <Box sx={{ ml: `${radioWidth}px`, pt: 2, pb: 4 }}>{children}</Box>
        </Collapse>
      )}
    </>
  );
};
