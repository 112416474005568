import { assertNever, assertNotUndefined } from "utils/hx/util/types";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { FinishingService, Product, ProductionOrderId, ProductionOrderTask } from "adl-gen/ferovinum/app/db";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { Loader } from "components/widgets/loader/loader";
import { assertValueLoaded, isLoaded } from "utils/utility-types";
import {
  StorageLocNewProductionOrderDetailsView,
  StorageLocProductionOrderCreatedPageView,
} from "./storage-loc-production-order-created/storage-loc-production-order-created-page-view";
import { ProductionOrderDetailsView, ProductionOrderTaskRunView } from "adl-gen/ferovinum/app/api";
import {
  StorageLocProductionOrderAcceptedPageView,
  StorageLocProductionOrderAcceptedPageViewProps,
} from "./storage-loc-production-order-accepted/storage-loc-production-order-accepted-page-view";
import { singles } from "utils/model-utils";
import { DbKey, WithDbId } from "adl-gen/common/db";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";

interface AcceptProps {
  kind: "accepted";
}

interface RejectProps {
  kind: "rejected";
  reason?: string;
}

export type AcceptOrRejectProps = AcceptProps | RejectProps;

export const StorageLocProductionOrderDetailsPage = () => {
  const [showAlert] = useAlert();
  const service = useAppService();
  const { productionOrderId } = useParams<{
    productionOrderId: ProductionOrderId;
  }>();

  const loadProductionOrderDetailsView = useCallback(async () => {
    return await service.getProductionOrder({ productionOrderId });
  }, [productionOrderId, service]);

  const [loadingProductionOrderDetailsView, refreshProductionOrderDetails] =
    useLoadingDataState(loadProductionOrderDetailsView);

  const onAcceptOrReject = useCallback(
    async (props: AcceptOrRejectProps) => {
      try {
        if (props.kind === "accepted") {
          await service.acceptProductionOrder({ productionOrderId });
          await refreshProductionOrderDetails();
        } else if (props.kind === "rejected") {
          await service.rejectProductionOrder({
            productionOrderId,
            reason: props.reason || null,
          });
          await refreshProductionOrderDetails();
        } else {
          void assertNever(props);
        }
      } catch (e) {
        await showAlert({
          title: "Error",
          body: `Production order could not be ${props.kind}. Please try again later.`,
        });
      }
    },
    [service, productionOrderId, refreshProductionOrderDetails, showAlert],
  );

  const onSubmitProductionRun = useCallback(
    async (taskId: DbKey<ProductionOrderTask>, sourceQuantity: number, resultingQuantity: number) => {
      const productionOrderTask = assertNotUndefined(
        assertValueLoaded(loadingProductionOrderDetailsView).productionOrderTasks.find(
          poTask => poTask.productionOrderTaskId === taskId,
        ),
      );
      // Note(Berto): Production orders currently only support 1 source product item
      // but the underlying model allows multiple already
      const sourceProduct = productionOrderTask.sourceProductItems[0].product;

      await service.completeProductionOrderTask({
        taskId,
        resultingQuantity: singles(resultingQuantity),
        sourceProductItems: [
          {
            quantity: singles(sourceQuantity),
            productId: sourceProduct.id,
            lossQuantity: singles(sourceQuantity - resultingQuantity),
          },
        ],
      });

      // Await this promise so the onSubmit won't be finished until the user can see the new production order run
      await refreshProductionOrderDetails();
    },
    [loadingProductionOrderDetailsView, refreshProductionOrderDetails, service],
  );

  return (
    <Loader loadingStates={[loadingProductionOrderDetailsView]} fullScreen>
      {isLoaded(loadingProductionOrderDetailsView) && (
        <StorageLocProductionOrderDetailsPageView
          productionOrder={loadingProductionOrderDetailsView.value}
          onAcceptOrReject={onAcceptOrReject}
          onSubmitProductionRun={onSubmitProductionRun}
        />
      )}
    </Loader>
  );
};

export interface StorageLocProductionOrderTaskView {
  productionOrderTaskId: DbKey<ProductionOrderTask>;
  sourceProduct: WithDbId<Product>;
  budgetQuantity?: number;
  targetQuantity: number;
  finishingService: FinishingService;
  finishingProduct: Product;
  productionOrderTaskRuns: ProductionOrderTaskRunView[];
  pricePerUnit: MonetaryValue;
  setupCost: MonetaryValue;
  subtotalCost: MonetaryValue;
  totalCost: MonetaryValue;
  isComplete: boolean;
}

interface StorageLocProductionOrderDetailsPageView {
  productionOrder: ProductionOrderDetailsView;
  onAcceptOrReject: (props: AcceptOrRejectProps) => Promise<void>;
  onSubmitProductionRun: (taskId: DbKey<ProductionOrderTask>, sourceQty: number, resultingQty: number) => Promise<void>;
}
const StorageLocProductionOrderDetailsPageView = ({
  productionOrder,
  onAcceptOrReject,
  onSubmitProductionRun,
}: StorageLocProductionOrderDetailsPageView) => {
  const productionOrderHasNotBeenAcceptedOrRejectedYet =
    productionOrder.latestStatus === null ||
    productionOrder.latestStatusCreatedAt === null ||
    // This should never happen as pending production orders are not visible to storage location (But just in case, to avoid a crash)
    productionOrder.latestStatus === "pending";

  const productionOrderTasks: StorageLocProductionOrderTaskView[] = productionOrder.productionOrderTasks.map(poTask => {
    return {
      ...poTask,
      // Note (Nicole): Production order tasks currently only support 1 source product but the model supports multiple already
      sourceProduct: poTask.sourceProductItems[0].product,
      taskId: poTask.productionOrderTaskId,
      finishingProduct: poTask.resultingProduct,
      targetQuantity: poTask.targetQuantity.value,
      budgetQuantity: poTask.sourceProductItems[0].budgetQuantity?.value,
      totalCost: poTask.totalCost,
      subtotalCost: poTask.subtotalCost,
    };
  });

  if (productionOrderHasNotBeenAcceptedOrRejectedYet) {
    const newProductionOrderProps: StorageLocNewProductionOrderDetailsView = {
      ...productionOrder,
      productionOrderTasks,
      subtotalCost: productionOrder.subtotalCost,
      totalCost: productionOrder.totalCost,
    };

    return (
      <StorageLocProductionOrderCreatedPageView productionOrder={newProductionOrderProps} onAction={onAcceptOrReject} />
    );
  }

  const acceptedProps: StorageLocProductionOrderAcceptedPageViewProps = {
    orderNumber: productionOrder.orderNumber,
    orderDate: productionOrder.createdAt,
    productionOrderTasks,
    requestedCompletionDate: productionOrder.requestedCompletionDate ?? undefined,
    notes: productionOrder.comments ?? undefined,
    subtotalCost: productionOrder.subtotalCost,
    totalCost: productionOrder.totalCost,
    // Note (Nicole): Typescript can't tell that this non-null check has been performed above
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    latestStatus: { status: productionOrder.latestStatus!, timestamp: productionOrder.latestStatusCreatedAt! },
    totalSetupFee: productionOrder.totalSetupCost,
    completionPercentage: productionOrder.completionPercentage,
    onSubmitProductionRun,
  };
  return <StorageLocProductionOrderAcceptedPageView {...acceptedProps} onSubmitProductionRun={onSubmitProductionRun} />;
};
