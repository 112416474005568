import { AppService } from "adl-gen/app-service";
import { useApiServices } from "./use-api-services";

/**
 * Hook that returns the AppService instance provided by the LoggedInContext. It expects that the component using this
 * hook is for a logged in user only and will therefore throw an exception if the LoggedInContext is not fully defined.
 */
export const useAppService = (): AppService => {
  const { app } = useApiServices();
  return app;
};
