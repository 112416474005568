import { Text, View } from "@react-pdf/renderer";
import React, { createContext } from "react";

const textPaddingStyle = {
  marginBottom: -3,
  paddingTop: 3,
  paddingHorizontal: 3,
};

export function TextBlock(props: React.PropsWithChildren<{ fontFamily?: string }>) {
  return <Text style={{ ...textPaddingStyle, fontFamily: props.fontFamily, marginVertical: 5 }}>{props.children}</Text>;
}

export function TextLine(props: React.PropsWithChildren<{ fontFamily?: string }>) {
  return <Text style={{ ...textPaddingStyle, fontFamily: props.fontFamily }}>{props.children}</Text>;
}

export function MultiLineText(props: { texts: string[] }) {
  return (
    <>
      {props.texts.map((t, i) => (
        <DocText key={i}>{t}</DocText>
      ))}
    </>
  );
}

export function BlankRow() {
  return (
    <Row
      cells={[
        <Text key={0} style={{ ...textPaddingStyle, opacity: 0 }}>
          .
        </Text>,
      ]}
    />
  );
}

export function NamedField(props: { name: string; value: string }) {
  return (
    <View
      style={{
        flexDirection: "row",
      }}>
      <Text style={{ ...textPaddingStyle, textAlign: "left", minWidth: 40, fontFamily: "Helvetica-Bold" }}>
        {props.name}:
      </Text>
      <Text style={{ ...textPaddingStyle, marginLeft: 20 }}>{props.value}</Text>
    </View>
  );
}

export function FlexSpread(props: React.PropsWithChildren<unknown>) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      {props.children}
    </View>
  );
}

export function DocText(
  props: React.PropsWithChildren<{
    textAlign?: "left" | "right" | "center";
  }>,
) {
  const { children, textAlign } = props;
  const textComponent = <Text style={textPaddingStyle}>{children}</Text>;
  if (textAlign === "right" || textAlign === "center") {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: textAlign === "right" ? "flex-end" : "center",
          alignItems: "center",
        }}>
        {textComponent}
      </View>
    );
  } else {
    return textComponent;
  }
}

export function rightAlign(text: string) {
  return <DocText textAlign="right">{text}</DocText>;
}

export function DocSection(
  props: React.PropsWithChildren<{
    name?: string;
    fontFamily?: string;
    fixSectionHeader?: boolean;
  }>,
) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        marginVertical: 5,
        fontFamily: props.fontFamily,
      }}>
      {props.name != undefined && (
        <View style={{ borderBottom: 1 }} fixed={props.fixSectionHeader}>
          <Text style={{ ...textPaddingStyle, fontFamily: "Helvetica-Bold" }}>{props.name}</Text>
        </View>
      )}
      <View>{props.children}</View>
    </View>
  );
}

const ColumnContext = createContext<number[]>([]);

export function ColumnLayout(
  props: React.PropsWithChildren<{
    columnSpacing: number[];
  }>,
) {
  return (
    <View>
      <ColumnContext.Provider value={props.columnSpacing}>{props.children}</ColumnContext.Provider>
    </View>
  );
}

type CellContent = string | JSX.Element;

// Create cells with appropriate widths (using flex), gets column spacing from context
// set by ColumnLayout
export function Row(props: {
  topBorder?: boolean;
  bottomBorder?: boolean;
  verticalBorder?: boolean;
  fontFamily?: string;
  cells: CellContent[];
  cellSpans?: number[];
}) {
  return (
    <View style={{ display: "flex", flexDirection: "row", fontFamily: props.fontFamily }}>
      <ColumnContext.Consumer>
        {columnSpacing => {
          const cells: { spacing: number; content: string | JSX.Element }[] = [];

          for (let colIdx = 0, cellIdx = 0; colIdx < columnSpacing.length; cellIdx++) {
            let spacing = 0;
            const cellSpan = props.cellSpans?.[cellIdx] ?? 1;
            const endColIdx = colIdx + cellSpan;
            // add up column spacing and increment colIdx
            for (; colIdx < endColIdx; colIdx++) {
              spacing += columnSpacing[colIdx];
            }
            const content: CellContent = props.cells[cellIdx] ?? "";
            cells.push({ spacing, content });
          }
          return cells.map(({ spacing, content }, index) => {
            return (
              <View
                key={index}
                style={{
                  flex: spacing,
                  border: 1,
                  borderTop: props.topBorder ? 1 : 0,
                  borderLeft: props.verticalBorder ? 1 : 0,
                  borderRight: props.verticalBorder && index == cells.length - 1 ? 1 : 0,
                  borderBottom: props.bottomBorder ? 1 : 0,
                }}>
                {typeof content === "string" ? <DocText>{content}</DocText> : content}
              </View>
            );
          });
        }}
      </ColumnContext.Consumer>
    </View>
  );
}
