import React, { useMemo, useState } from "react";
import { PortalPageContent } from "../../../../../layouts/portal-page-content/portal-page-content";
import { Button, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { ProductionOrderTotalPriceCard } from "../../../../../widgets/production-orders/production-order-total-price-card/production-order-total-price-card";
import { Currency, ProductionOrderId, ProductionOrderStatus } from "adl-gen/ferovinum/app/db";
import { formatDate, formatLocalDate } from "utils/date-utils";
import { FinishingServiceSummaryCard } from "../../../../../widgets/production-orders/finishing-service-summary-card/finishing-service-summary-card";
import {
  PortalPageContentHeader,
  SubtitleProps,
} from "../../../../../layouts/portal-page-content-header/portal-page-content-header";
import { useSettlementCurrency } from "../../../../../layouts/portal-page-layout/portal-page";
import { NotesStickyNote } from "../../../../../widgets/sticky-note/notes-sticky-note/notes-sticky-note";
import { AppRoutes } from "../../../../../../app/app-routes";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";
import { ProductionOrderTasksSummaryTable } from "../../../../../widgets/production-orders/production-order-tasks-summary-table/production-order-tasks-summary-table";
import {
  OrganisationInnerProductionOrderDetailsPageViewProps,
  OrgProductionOrderTaskView,
} from "../organisation-production-order-details-page";
import { LocalDate } from "adl-gen/common";
import { assertNever } from "utils/hx/util/types";
import { PurchaseRequestReference } from "adl-gen/ferovinum/app/api";
import { Link } from "components/widgets/link/link";

type Sections = "Summary" | "InProgressTasks" | "CompletedTasks";

interface OrganisationAcceptedProductionOrderStatus {
  status: ProductionOrderStatus;
  date: LocalDate;
}
export type OrganisationAcceptedProductionOrderDetailsPageViewProps =
  OrganisationInnerProductionOrderDetailsPageViewProps & {
    latestStatus: OrganisationAcceptedProductionOrderStatus;
    completionPercentage: number;
  };
export const OrganisationProductionOrderAcceptedPageView = ({
  productionOrderTasks,
  latestStatus,
  isProducer,
  ...props
}: OrganisationAcceptedProductionOrderDetailsPageViewProps) => {
  const inProgressTasks = productionOrderTasks.filter(task => !task.isComplete);
  const completedTasks = productionOrderTasks.filter(task => task.isComplete);

  const hasInProgressTasks = inProgressTasks.length > 0;
  const hasCompletedTasks = completedTasks.length > 0;
  const isRejected = latestStatus.status === "rejected";

  const [selectedTab, setSelectedTab] = useState<Sections>(hasInProgressTasks ? "InProgressTasks" : "CompletedTasks");
  const currency = useSettlementCurrency();

  return (
    <PortalPageContent header={<ProductionOrderDetailsHeader {...props} latestStatus={latestStatus} />}>
      <Tabs value={selectedTab} onChange={(_e, v) => setSelectedTab(v)}>
        {hasInProgressTasks && <Tab label={isRejected ? "Tasks" : "In progress tasks"} value="InProgressTasks" />}
        {hasCompletedTasks && <Tab label="Completed tasks" value="CompletedTasks" />}
        <Tab label="Summary" value="Summary" />
      </Tabs>
      <Stack paddingTop={5}>
        {selectedTab === "InProgressTasks" && (
          <TasksSection
            {...props}
            productionOrderTasks={inProgressTasks}
            showProductionRunButton={isProducer && latestStatus.status !== "completed"}
            latestStatus={latestStatus.status}
          />
        )}
        {selectedTab === "CompletedTasks" && (
          <TasksSection
            {...props}
            productionOrderTasks={completedTasks}
            showProductionRunButton={false}
            latestStatus={latestStatus.status}
          />
        )}
        {selectedTab === "Summary" && (
          <SummarySection productionOrderTasks={productionOrderTasks} {...props} currency={currency} />
        )}
      </Stack>
    </PortalPageContent>
  );
};

interface ProductionOrderDetailsHeaderProps {
  orderNumber: string;
  orderDate: string;
  storageLocationName: string;
  requestedCompletionDate?: string;
  latestStatus: OrganisationAcceptedProductionOrderStatus;
  completionPercentage: number;
}
const ProductionOrderDetailsHeader = ({
  orderNumber,
  orderDate,
  storageLocationName,
  requestedCompletionDate,
  latestStatus,
  completionPercentage,
}: ProductionOrderDetailsHeaderProps) => {
  const chipProps: { text: string; color: "warning" | "success" | "error" } = useMemo(() => {
    switch (latestStatus.status) {
      case "accepted":
        return {
          text: `Accepted on ${formatLocalDate(latestStatus.date)} - Production is ${completionPercentage}% complete`,
          color: "warning",
        };
      case "completed":
        return { text: "Production complete", color: "success" };
      case "rejected":
        return { text: "Production order rejected", color: "error" };
      case "pending":
        return { text: "Production order pending", color: "warning" };
      default:
        assertNever(latestStatus.status);
    }
  }, [completionPercentage, latestStatus.date, latestStatus.status]);

  const subtitles: SubtitleProps[] = useMemo(() => {
    const defaultSubtitles: SubtitleProps[] = [
      { text: `Ordered from ${storageLocationName} on ${formatDate(new Date(orderDate))}`, icon: "receipt" },
    ];
    if (requestedCompletionDate) {
      defaultSubtitles.push({
        text: `Requested completion date: ${formatDate(new Date(requestedCompletionDate))}`,
        icon: "date",
      });
    }
    return defaultSubtitles.concat({
      text: chipProps.text,
      component: { type: "chip", color: chipProps.color },
    });
  }, [chipProps, orderDate, requestedCompletionDate, storageLocationName]);

  return <PortalPageContentHeader title={`Production order reference number: ${orderNumber}`} subtitles={subtitles} />;
};

interface SummarySectionProps {
  productionOrderTasks: OrgProductionOrderTaskView[];
  currency: Currency;
  totalSetupCost: MonetaryValue;
  subtotalCost: MonetaryValue;
  totalCost: MonetaryValue;
  notes?: string;
  purchaseRequestReference: PurchaseRequestReference | null;
}
const SummarySection = ({
  productionOrderTasks,
  currency,
  notes,
  purchaseRequestReference,
  ...props
}: SummarySectionProps) => {
  return (
    <Grid container spacing={4}>
      {purchaseRequestReference && (
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <Typography>Linked to purchase request</Typography>
            <Link
              variant="big"
              href={`${AppRoutes.OrganisationPurchaseRequestDetails}/${purchaseRequestReference.purchaseRequestId}`}>
              {purchaseRequestReference.purchaseRequestNumber}
            </Link>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12}>
        <ProductionOrderTasksSummaryTable
          productionOrderTasks={productionOrderTasks.map(poTask => {
            return {
              ...poTask,
              targetQuantity: poTask.targetQuantity.value,
              budgetQuantity: poTask.budgetQuantity?.value,
            };
          })}
          currency={currency}
        />
      </Grid>
      {productionOrderTasks.length > 1 && (
        <Grid item xs={12} lg={6}>
          <ProductionOrderTotalPriceCard
            currency={currency}
            setupCost={props.totalSetupCost}
            productionOrderTasksCosts={productionOrderTasks.map(poTask => {
              return {
                ...poTask,
                targetQuantity: poTask.targetQuantity.value,
                budgetQuantity: poTask.budgetQuantity?.value,
              };
            })}
            {...props}
          />
        </Grid>
      )}
      {notes && (
        <Grid item xs={12} lg={6}>
          <NotesStickyNote notes={notes} />
        </Grid>
      )}
    </Grid>
  );
};

interface TasksSectionProps {
  productionOrderTasks: OrgProductionOrderTaskView[];
  showProductionRunButton: boolean;
  productionOrderId: ProductionOrderId;
  latestStatus: ProductionOrderStatus | null;
}
const TasksSection = ({
  productionOrderTasks,
  showProductionRunButton,
  productionOrderId,
  latestStatus,
}: TasksSectionProps) => {
  return (
    <Stack spacing={2}>
      {showProductionRunButton && (
        <Stack alignItems="flex-end">
          <Button href={`${AppRoutes.LocProductionOrderDetails}/${productionOrderId}`}>Record production run</Button>
        </Stack>
      )}
      {productionOrderTasks.map(
        ({
          finishingService,
          targetQuantity: quantity,
          sourceProduct,
          finishingProduct,
          productionOrderTaskRuns,
          budgetQuantity,
        }) => (
          <FinishingServiceSummaryCard
            key={finishingService.code}
            serviceName={finishingService.name}
            targetQuantity={quantity.value}
            budgetQuantity={budgetQuantity?.value}
            sourceProduct={sourceProduct.value}
            finishingProduct={finishingProduct}
            productionOrderTaskRuns={productionOrderTaskRuns}
            latestStatus={latestStatus}
          />
        ),
      )}
    </Stack>
  );
};
