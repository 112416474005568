import React, { useCallback } from "react";

import { Loader } from "components/widgets/loader/loader";
import { isLoaded } from "utils/utility-types";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { OrgRevaluationRequestDetailsPageView } from "./revaluation-request-details-page-view";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../hooks/use-app-service";
import { ContractNoteSourceRequest } from "adl-gen/ferovinum/app/api";

export const OrgRevaluationRequestDetailsPage = () => {
  const service = useAppService();

  const { revaluationRequestId } = useParams<{
    revaluationRequestId: string;
  }>();

  const loadRevaluationDetails = useCallback(async () => {
    const contractNoteFetchReq: ContractNoteSourceRequest = {
      kind: "revaluationRequestId",
      value: revaluationRequestId,
    };

    const [loadingRevaluationDetails, loadingContractNoteFetchResp] = await Promise.all([
      service.revaluationDetails(revaluationRequestId),
      service.getContractNoteView({ kind: "sourceRequest", value: contractNoteFetchReq }),
    ]);
    return { loadingRevaluationDetails, loadingContractNoteFetchResp };
  }, [service, revaluationRequestId]);

  const [loadingRevaluationDetails] = useLoadingDataState(loadRevaluationDetails);

  return (
    <Loader loadingStates={[loadingRevaluationDetails]} fullScreen>
      {isLoaded(loadingRevaluationDetails) && (
        <OrgRevaluationRequestDetailsPageView
          revaluationDetails={loadingRevaluationDetails?.value.loadingRevaluationDetails}
          contractNoteView={loadingRevaluationDetails?.value.loadingContractNoteFetchResp}
          revaluationRequestId={revaluationRequestId}
        />
      )}
    </Loader>
  );
};
