import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StorageLocationOutgoingOrderFilter } from "adl-gen/ferovinum/app/api";
import { OutgoingOrdersPageView } from "./outgoing-orders-page-view";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useSelectedStorageLocation } from "../../../../layouts/portal-page-layout/portal-page";
import { assertNotUndefined } from "utils/hx/util/types";
import { AppRoutes } from "../../../../../app/app-routes";
import { useMarkAsCollected, useMarkAsDelivered } from "../../../../../hooks/use-mark-as-delivered";

export const OutgoingOrdersPage = () => {
  const { filter } = useParams<{ filter: StorageLocationOutgoingOrderFilter }>();
  const appService = useAppService();
  const selectedStorageLocation = assertNotUndefined(useSelectedStorageLocation());
  const history = useHistory();

  const loadOrders = useCallback(async () => {
    const resp = await appService.getOutgoingOrdersForStorageLocation({
      kind: "allOutgoingOrders",
      value: {
        storageLocationId: selectedStorageLocation.id,
        filter,
      },
    });
    return resp.orders;
  }, [appService, selectedStorageLocation, filter]);

  const [loadingOrders, refresh] = useLoadingDataState(loadOrders, true);
  const markAsCollected = useMarkAsCollected(appService, refresh);
  const markAsDelivered = useMarkAsDelivered(appService, refresh);

  const onSetFilter = (val: StorageLocationOutgoingOrderFilter) =>
    history.push(`${AppRoutes.StorageLocationOutgoingOrders}/${val}`);

  return (
    <OutgoingOrdersPageView
      filter={filter}
      orders={loadingOrders}
      updateFilter={onSetFilter}
      markAsCollected={markAsCollected}
      markAsDelivered={markAsDelivered}
    />
  );
};
