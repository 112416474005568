import React, { useState } from "react";
import {
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  Alert,
  TableCellProps,
  Typography,
} from "@mui/material";
import { Link } from "components/widgets/link/link";
import { PurchaseRequestProgressStepper } from "components/widgets/base-progress-stepper/purchase-request-progress-stepper";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { AppRoutes } from "../../../../../app/app-routes";
import { createBigDecimalCurrencyFormatter } from "utils/currency-utils";
import { formatLocalDate } from "utils/date-utils";
import { WithDbId } from "adl-gen/common/db";
import { NominatedPurchaserDetails, PurchaseRequestView } from "adl-gen/ferovinum/app/api";
import { NominatedPurchaserTerms, Organisation, Currency } from "adl-gen/ferovinum/app/db";
import { LoadingBackdrop } from "components/widgets/loader/loading-backdrop";
import { GridTable, GridTableColumnFactory } from "components/widgets/grid-table/grid-table";
import { GridSortModel } from "@mui/x-data-grid";
import { PurchaseRequestsDashboardSection } from "./organisation-purchase-requests-page";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { TabbedPage } from "components/library/widgets/tabbed-page";
import { Box } from "@mui/system";
import { Download } from "@mui/icons-material";
import { UploadProductSalePricesButton } from "./upload-product-sale-price-components";

export interface CreditModalValues {
  organisations: WithDbId<Organisation>[];
  nominatedPurchaserDetails: NominatedPurchaserDetails[];
  nominatedPurchaserTerms: WithDbId<NominatedPurchaserTerms>[];
}

export interface PurchaseRequestsPageViewProps {
  inProgressPurchaseRequests: PurchaseRequestView[];
  completedPurchaseRequests: PurchaseRequestView[];
  onSelectSection: (section: PurchaseRequestsDashboardSection) => void;
  completedPurchaseRequestsLoaded: boolean;
  onDownloadCSV: () => void;
}

const SmallTableCell = (props: TableCellProps) => (
  <TableCell align="left" sx={{ width: "10%" }} {...props}>
    {props.children}
  </TableCell>
);

const InProgressTableHead = () => (
  <TableHead>
    <TableRow>
      <SmallTableCell>Reference No.</SmallTableCell>
      <SmallTableCell>Purchaser</SmallTableCell>
      <TableCell align="center">Progress</TableCell>
      <SmallTableCell align="center">Purchaser Invoice</SmallTableCell>
    </TableRow>
  </TableHead>
);

const renderInProgressRows = (prView: PurchaseRequestView) => {
  const pr = prView.purchaseRequest.value;
  const prId = prView.purchaseRequest.id;
  const purchaser = prView.purchaser.value;
  const stateEvents = pr.stateEvents;
  const lastStateSet = stateEvents[stateEvents.length - 1];
  const purchaseCurrencyFormatter = createBigDecimalCurrencyFormatter(pr.purchaserCurrency)();

  return (
    <TableRow key={prId}>
      <TableCell>
        <Link href={`${AppRoutes.OrganisationPurchaseRequestDetails}/${prId}`}>{pr.purchaseRequestNumber}</Link>
      </TableCell>
      <TableCell>{purchaser.name}</TableCell>
      <TableCell sx={{ paddingY: 4 }}>
        <PurchaseRequestProgressStepper
          upcomingStateDeadline={prView.nextStateDeadline ?? undefined}
          currentState={lastStateSet.state}
          lastStateSet={lastStateSet.time}
        />
      </TableCell>
      <TableCell align="center">
        <Stack>
          <Typography>{purchaseCurrencyFormatter.format(prView.purchaserInvoiceAmount)}</Typography>
          {prView.nextState && prView.nextStateDeadline && (
            <>
              <Typography variant="caption">Invoice Due Date</Typography>
              <Typography variant="caption">{formatLocalDate(prView.invoiceDueDate)}</Typography>
            </>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

interface CompletedTableProps {
  completedPurchaseRequests: PurchaseRequestView[];
  sortModel: GridSortModel;
  onSortModelChange: (model: GridSortModel) => void;
}

interface CompletedRowData {
  purchaseRequestRef: string;
  purchaseRequestId: string;
  purchaser: string;
  purchaserInvoiceAmount: number;
  time: string;
  currency: Currency;
}

const CompletedTable = ({ completedPurchaseRequests, sortModel, onSortModelChange }: CompletedTableProps) => {
  const completedRowsData: CompletedRowData[] = completedPurchaseRequests.map(prView => ({
    purchaseRequestRef: prView.purchaseRequest.value.purchaseRequestNumber,
    purchaseRequestId: prView.purchaseRequest.id,
    purchaser: prView.purchaser.value.name,
    purchaserInvoiceAmount: parseFloat(prView.purchaserInvoiceAmount),
    time: formatLocalDate(
      new Date(prView.purchaseRequest.value.stateEvents[prView.purchaseRequest.value.stateEvents.length - 1].time)
        .toISOString()
        .split("T")[0],
    ),
    currency: prView.purchaseRequest.value.purchaserCurrency,
  }));

  const columnFactory = new GridTableColumnFactory<CompletedRowData>();
  const columns = [
    columnFactory.makeHyperLinkColumn({
      header: "Reference No.",
      name: "purchaseRequestRef",
      hrefGetter: row => `${AppRoutes.OrganisationPurchaseRequestDetails}/${row.purchaseRequestId}`,
      getter: row => row.purchaseRequestRef,
    }),
    columnFactory.makeTextColumn({
      header: "Purchaser",
      name: "purchaser",
      getter: row => row.purchaser,
    }),
    columnFactory.makeTextColumn({
      header: "Invoice Amount",
      name: "purchaserInvoiceAmount",
      getter: row => createBigDecimalCurrencyFormatter(row.currency)().format(row.purchaserInvoiceAmount.toString()),
    }),
    columnFactory.makeDateColumn({
      header: "Completion Date",
      key: "time",
      displayFormatter: row => formatLocalDate(row.time),
    }),
  ];

  return (
    <GridTable
      columns={columns}
      inputRowsField={completedRowsData}
      tableHeight="auto"
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
    />
  );
};

export const PurchaseRequestsPageView = ({
  inProgressPurchaseRequests,
  completedPurchaseRequests,
  onSelectSection,
  completedPurchaseRequestsLoaded,
  onDownloadCSV,
}: PurchaseRequestsPageViewProps) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "time", sort: "desc" }]);

  const handleDownloadCSV = async () => onDownloadCSV();

  const hasInProgressPurchaseRequests = inProgressPurchaseRequests.length > 0;
  const hasCompletedPurchaseRequests = completedPurchaseRequests.length > 0 && completedPurchaseRequestsLoaded;

  const CreateReportDownloadButton = () => (
    <LoadingActionButton variant="outlined" onClick={handleDownloadCSV} startIcon={<Download />}>
      Download Detailed Report (CSV)
    </LoadingActionButton>
  );

  return (
    <PortalPageContent header={<PortalPageContentHeader title="Third Party Sales" />}>
      <TabbedPage
        defaultTab="inProgress"
        handleTabChange={({ key }) => onSelectSection(key as PurchaseRequestsDashboardSection)}
        tabs={[
          {
            label: "In Progress",
            key: "inProgress",
            content: (
              <Box>
                {hasInProgressPurchaseRequests ? (
                  <TableContainer component={Paper}>
                    <Table>
                      <InProgressTableHead />
                      <TableBody>{inProgressPurchaseRequests.map(prView => renderInProgressRows(prView))}</TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Alert severity="info">There are currently no purchase requests in progress</Alert>
                )}
              </Box>
            ),
            toolbar: (
              <Stack spacing={1} direction="row">
                <CreateReportDownloadButton />
                <UploadProductSalePricesButton />
              </Stack>
            ),
          },
          {
            label: "Completed",
            key: "completed",
            content: (
              <Box>
                {hasCompletedPurchaseRequests ? (
                  <CompletedTable
                    completedPurchaseRequests={completedPurchaseRequests}
                    sortModel={sortModel}
                    onSortModelChange={(newModel: GridSortModel) => setSortModel(newModel)}
                  />
                ) : completedPurchaseRequestsLoaded ? (
                  <Alert severity="info">There are currently no completed purchase requests</Alert>
                ) : (
                  <LoadingBackdrop open={!completedPurchaseRequestsLoaded} />
                )}
              </Box>
            ),
            toolbar: <CreateReportDownloadButton />,
          },
        ]}
      />
    </PortalPageContent>
  );
};
