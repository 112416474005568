import React, { useCallback, useContext } from "react";
import { OrganisationPurchaseRequestDetailsPageView } from "./organisation-purchase-request-details-page-view";
import { Loader } from "components/widgets/loader/loader";
import { useOrganisationPurchaseRequest } from "../../../../../hooks/use-purchase-request";
import { isLoaded } from "utils/utility-types";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { UpdatePurchaseRequestResp } from "adl-gen/ferovinum/app/api";
import { LoggedInContext } from "../../../../../app/app";
import { PurchaseRequestAdminAction } from "../organisation-purchase-requests-by-purchaser/organisation-purchase-requests-by-purchaser-page";
import { assertNever } from "utils/hx/util/types";
import { isAdmin } from "utils/user-utils";

export const OrganisationPurchaseRequestDetailsPage = () => {
  const { purchaseRequestId } = useParams<{
    purchaseRequestId: string;
  }>();
  const service = useAppService();
  const userProfile = useContext(LoggedInContext).loginState?.user?.profile;
  const [showAlert] = useAlert();
  const { loadingPurchaseRequest, refresh } = useOrganisationPurchaseRequest(purchaseRequestId);

  const onUpdatePurchaseRequestState = useCallback(
    async (resp: UpdatePurchaseRequestResp) => {
      if (resp !== "success") {
        await showAlert({
          title: "Error occurred",
          body: "Invalid state for Purchase request.",
        });
      }
      await refresh();
    },
    [refresh, showAlert],
  );

  const onMarkReadyForCollection = useCallback(async () => {
    await onUpdatePurchaseRequestState(
      await service.organisationMarkPurchaseRequestReadyForCollection({
        purchaseRequestId,
      }),
    );
  }, [onUpdatePurchaseRequestState, purchaseRequestId, service]);

  const onAdminAction = useCallback(
    async (adminAction: PurchaseRequestAdminAction) => {
      let resp: UpdatePurchaseRequestResp;
      if (adminAction.action === "markCollected") {
        resp = await service.markPurchaseRequestCollected({ purchaseRequestId, date: adminAction.collectedAt });
      } else if (adminAction.action === "markDelivered") {
        resp = await service.markPurchaseRequestDelivered({ purchaseRequestId, date: adminAction.deliveredAt });
      } else if (adminAction.action === "markPurchaserPaid") {
        // TODO (Nicole): Add user input to set this paidAt field
        resp = await service.adminMarkPurchaseRequestPurchaserPaid({
          purchaseRequestId,
          paidAt: adminAction.paidAt.getTime(),
          fxRate: adminAction.fxRate,
        });
      } else {
        assertNever(adminAction);
      }

      await onUpdatePurchaseRequestState(resp);
    },
    [onUpdatePurchaseRequestState, purchaseRequestId, service],
  );
  return (
    <Loader loadingStates={[loadingPurchaseRequest]} fullScreen>
      {isLoaded(loadingPurchaseRequest) && (
        <OrganisationPurchaseRequestDetailsPageView
          purchaseRequestData={loadingPurchaseRequest.value}
          onMarkReadyForCollection={onMarkReadyForCollection}
          onAdminAction={userProfile !== undefined && isAdmin(userProfile) ? onAdminAction : undefined}
        />
      )}
    </Loader>
  );
};
