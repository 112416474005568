import React from "react";
import { ApiServices } from "adl-service/api-services";
import { LoggedInContext } from "../app/app";
import { assertNotUndefined } from "utils/hx/util/types";

/**
 * Hook that returns the Apis provided by the LoggedInContext. It expects that the component using this
 * hook is for a logged in user only and will therefore throw an exception if the LoggedInContext is not fully defined.
 */
export const useApiServices = (): ApiServices => {
  return assertNotUndefined(React.useContext(LoggedInContext).loginState?.user?.apis);
};
