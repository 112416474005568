import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { assertNever } from "utils/hx/util/types";
import { LoginReq, UserProfile, UserResetPasswordActionReq } from "adl-gen/ferovinum/app/api";
import { ApiServices } from "adl-service/api-services";

import { AppRoutes } from "./app-routes";
import RemoteLogger from "adl-service/remote-logger";
import { GuardedFeature } from "adl-gen/ferovinum/app/db";

export type LoginRespType = { kind: "logged-in" } | { kind: "login-failed"; error: string };

export interface IdentityState {
  /** The authenticated users details */
  profile: UserProfile;
  /** The http APIs authenticated for the current user  */
  apis: ApiServices;
}

export interface LoginState {
  user: IdentityState | undefined;
  loginError: string | undefined;
  resetPasswordError: string | undefined;
  token: string | undefined;

  onLogin(req: LoginReq): Promise<LoginRespType>;
  onResetPassword(req: UserResetPasswordActionReq, authToken: string): Promise<{ success: boolean }>;
  onLogout(): void;
  onRequestResetPasswordLink(email: string): Promise<{ success: boolean }>;
  /** A boolean state tracking whether the user profile has loaded. */
  userProfileIsLoading: boolean;
}

export interface LoginStateProps {
  createApiServices(token?: string): ApiServices;
  setFeatures(features: GuardedFeature[]): void;
}

/**
 * Manages login state with react hooks
 */
export function useLoginState(props: LoginStateProps): LoginState {
  const [loginError, setLoginError] = React.useState<string | undefined>(undefined);
  const [resetPasswordError, setResetPasswordError] = React.useState<string | undefined>(undefined);
  const [user, setUser] = useState<IdentityState | undefined>(undefined);
  const [token, setToken] = useState<string>();
  const [userProfileIsLoading, setUserProfileIsLoading] = useState(true);

  const history = useHistory();

  const loadUserState = React.useCallback(
    async (authToken: string): Promise<IdentityState | undefined> => {
      if (!user) {
        try {
          const apis = props.createApiServices(authToken);
          const profile = await apis.app.whoAmI();
          setUser({ apis, profile });
          props.setFeatures(profile.enabledFeatures);
          setToken(authToken);
          RemoteLogger.authorise(authToken);
          return { apis, profile };
        } catch (e) {
          setUserProfileIsLoading(false);
          throw e;
        }
      }
    },
    [props, user],
  );

  async function onRequestResetPasswordLink(email: string) {
    try {
      const apis = props.createApiServices();
      await apis.app.forgotUserPassword({ email });
      return { success: true };
    } catch (error) {
      return { success: false };
    }
  }

  async function onLogin(req: LoginReq): Promise<LoginRespType> {
    const apis0 = props.createApiServices();
    const r = await apis0.app.login(req);

    if (r.kind === "invalidCredentials") {
      const errorStr = "Invalid username and/or password";
      setLoginError(errorStr);
      return { kind: "login-failed", error: errorStr };
    } else if (r.kind === "accessToken") {
      setLoginError(undefined);
      const authToken = r.value;
      const user = await loadUserState(authToken);
      storeLocalToken(authToken);

      const params = new URLSearchParams(location.search);
      // If the login was from a redirect
      if (params.has("referrer")) {
        location.pathname = params.get("referrer") || "";
        params.delete("referrer");
        location.search = params.toString();
        // If the password has expired on profile load, we should redirect them to the change password page
      } else if (user && user.profile.passwordExpired) {
        location.pathname = AppRoutes.ResetPassword;
      } else {
        location.pathname = AppRoutes.Index;
      }
      history.push(location);

      return { kind: "logged-in" };
    } else {
      assertNever(r);
    }
  }

  async function onResetPassword(req: UserResetPasswordActionReq, authToken: string) {
    const apis = props.createApiServices(authToken);
    try {
      setResetPasswordError(undefined);
      await apis.app.resetUserPasswordAction(req);
      return { success: true };
    } catch (e) {
      setResetPasswordError("Something went wrong, try again later or get in contact with us.");
    }
    return { success: false };
  }

  function onLogout(): void {
    setUser(undefined);
    setToken(undefined);
    history.push(AppRoutes.Login);
    clearLocalToken();
  }

  // On initial mount, load the logged in user profile.
  useEffect(() => {
    const localToken = getLocalToken();
    // If the user is logged out, then stop loading and don't attempt to load the user profile.
    if (localToken === null || localToken.length < 0) {
      setUserProfileIsLoading(false);
    } else {
      void loadUserState(localToken);
    }
    /**
     * NB: We don't want to re-run this effect, so we don't include any dependencies.
     * i.e. rerunning it caused race conditions where it would load a users profile even after they log out
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When the logged in user profile changes, check if it is defined and set
  // update the user profile loading state tracker accordingly.
  useEffect(() => {
    if (user) {
      setUserProfileIsLoading(false);
    }
  }, [user]);

  return {
    user,
    loginError,
    resetPasswordError,
    token,
    onLogin,
    onResetPassword,
    onLogout,
    onRequestResetPasswordLink,
    userProfileIsLoading,
  };
}

export function storeLocalToken(authToken: string) {
  localStorage.setItem(AUTH_TOKEN_KEY, authToken);
}

export function getLocalToken() {
  return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function clearLocalToken() {
  localStorage.removeItem(AUTH_TOKEN_KEY);
}

const AUTH_TOKEN_KEY = "app-access-token";
