import { HttpFetch, HttpRequest } from "utils/hx/service/http";

import { HttpGet, HttpPost } from "adl-gen/common/http";
import { ATypeExpr, DeclResolver } from "adl-gen/runtime/adl";
import {
  createJsonBinding,
  getAnnotation,
  Json,
  JsonBinding,
  JsonParseException,
} from "adl-gen/runtime/json";
import * as adlast from "adl-gen/sys/adlast";

import { HttpServiceError } from "../http-service-error";
import { GetFn, PostFn } from "../types";

/**
 * A Base class for HttpServices (eg main and admin APIs)
 */
export class AdminUiHttpServiceBase {
  constructor(
    /** Fetcher over HTTP */
    private readonly http: HttpFetch,
    /** Base URL of the API endpoints */
    private readonly baseUrl: string,
    /** Resolver for ADL types */
    protected readonly resolver: DeclResolver,
    /** The authentication token (if any) */
    private readonly authToken: string | undefined,
    /** Error handler to allow for cross cutting concerns, e.g. authorization errors */
    private readonly handleError: (error: HttpServiceError) => void,
  ) {}

  protected annotatedApi<API extends {}>(
    apisn: adlast.ScopedName,
    api: API,
  ): AnnotatedApi<API> {
    return annotatedApi(this.resolver, apisn, api);
  }

  protected mkGetFn<O>(req: AnnotatedReq<HttpGet<O>>): GetFn<O> {
    const jb = createJsonBinding(this.resolver, req.rtype.respType);
    return {
      description: () => req.description,
      rtype: req.rtype,
      call: () => {
        return this.getAdl(req.rtype.path, jb, req.actionName);
      },
    };
  }

  protected mkPostFn<I, O>(req: AnnotatedReq<HttpPost<I, O>>): PostFn<I, O> {
    const bb = createBiBinding<I, O>(this.resolver, req.rtype);
    return {
      description: () => req.description,
      rtype: req.rtype,
      call: (ival: I) => {
        return this.postAdl(req.rtype.path, bb, ival, req.actionName);
      },
    };
  }

  private async getAdl<O>(
    path: string,
    respJB: JsonBinding<O>,
    actionName: string,
  ): Promise<O> {
    return this.requestAdl("get", path, undefined, respJB, actionName);
  }

  private async postAdl<I, O>(
    path: string,
    post: BiBinding<I, O>,
    req: I,
    actionName: string,
  ): Promise<O> {
    const jv = post.reqJB.toJson(req);
    return this.requestAdl("post", path, { body: jv }, post.respJB, actionName);
  }

  private async requestAdl<O>(
    method: "get" | "post",
    path: string,
    jsonBody: { body: Json } | undefined,
    respJB: JsonBinding<O>,
    /** Publicly consumable action of the request for error alerting purposes */
    actionName: string,
  ): Promise<O> {
    // Construct request
    const headers: { [key: string]: string } = {};
    headers["Content-Type"] = "application/json";
    if (this.authToken) {
      headers["X-Auth-Token"] = this.authToken;
    }
    const httpReq: HttpRequest = {
      url: this.baseUrl + path,
      headers,
      method,
      body: jsonBody === undefined ? undefined : JSON.stringify(jsonBody.body),
    };

    // Make request
    const resp = await this.http.fetch(httpReq);

    if (!resp.ok) {
      const bodyText = await resp.text();
      let publicMessageFragment = "";
      try {
        const bodyJson = JSON.parse(bodyText);
        if (bodyJson.publicMessage) {
          publicMessageFragment = `: ${bodyJson.publicMessage}`;
        }
      } catch (e) {
        // Not JSON
      }

      const error = new HttpServiceError(
        `Encountered server error attempting to call ${actionName} ${publicMessageFragment}`,
        `${httpReq.method} request to ${httpReq.url} failed: ${resp.statusText} (${resp.status}): ${bodyText}`,
        resp.status,
        bodyText,
      );
      this.handleError(error);
      throw error;
    }

    const bodyText = await resp.text();
    let bodyJson: Json;
    try {
      bodyJson = JSON.parse(bodyText);
    } catch (e: unknown) {
      const error = new HttpServiceError(
        `Encountered error attempting to call ${actionName} response was invalid json`,
        `${httpReq.method} request to ${httpReq.url} failed: ${bodyText}`,
        resp.status,
        bodyText,
      );
      this.handleError(error);
      throw error;
    }

    // Parse response
    try {
      return respJB.fromJson(bodyJson);
    } catch (e: unknown) {
      const err = e as JsonParseException;
      const error = new HttpServiceError(
        "Encountered parse error attempting to call " + actionName,
        err.getMessage(),
        resp.status,
        bodyText,
      );
      this.handleError(error);
      throw error;
    }
  }
}

interface BiTypeExpr<I, O> {
  reqType: ATypeExpr<I>;
  respType: ATypeExpr<O>;
}

interface BiBinding<I, O> {
  reqJB: JsonBinding<I>;
  respJB: JsonBinding<O>;
}

function createBiBinding<I, O>(
  resolver: DeclResolver,
  rtype: BiTypeExpr<I, O>,
): BiBinding<I, O> {
  return {
    reqJB: createJsonBinding(resolver, rtype.reqType),
    respJB: createJsonBinding(resolver, rtype.respType),
  };
}

const texprDocString: ATypeExpr<string> = {
  value: {
    typeRef: {
      kind: "reference",
      value: { moduleName: "sys.annotations", name: "Doc" },
    },
    parameters: [],
  },
};

interface AnnotatedReq<RT> {
  actionName: string;
  description: string;
  rtype: RT;
}

type AnnotatedApi<A> = {
  [RT in keyof A]: AnnotatedReq<A[RT]>;
};

/**
 * Merge the information available as API annotations into the API value
 */
function annotatedApi<API extends {}>(
  resolver: DeclResolver,
  apisn: adlast.ScopedName,
  api: API,
): AnnotatedApi<API> {
  const apiDecl = resolver(apisn);
  if (apiDecl.decl.type_.kind !== "struct_") {
    throw new Error("BUG: api is not a struct");
  }
  const apiStruct = apiDecl.decl.type_.value;

  const result = {} as AnnotatedApi<API>;

  for (const k of Object.keys(api)) {
    // @ts-ignore
    const rtype = api[k];
    const apiField = apiStruct.fields.find((f) => f.name === k);
    if (!apiField) {
      throw new Error("BUG: api  missing a field");
    }
    const description =
      getAnnotation(
        createJsonBinding(resolver, texprDocString),
        apiField.annotations,
      ) || "";
    // @ts-ignore
    result[k] = {
      actionName: apiField.name,
      description,
      rtype,
    };
  }
  return result;
}
