import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Loader } from "components/widgets/loader/loader";
import React, { useCallback, useEffect, useState } from "react";
import { LoadingValue } from "utils/utility-types";

interface TableState<T> {
  pageIdx: number;
  rowsPerPage: number;
  currentPageRows: T[];
  initialRowCount: number;
  cachedRows: T[];
  loadState: LoadingValue<null>;
}

interface PaginatedTableCoreProps<T> {
  initialRows: T[];
  initialRowsPerPage?: 10 | 25 | 50 | 100;
  rowsPerPageOptions?: TablePaginationProps["rowsPerPageOptions"];
  totalRowCount?: number;
  loadMoreData?: (offset: number) => Promise<T[] | undefined>;
  sx?: TableContainerProps["sx"];
}

export function PaginatedTable<T>(
  props: {
    HeaderContent?: React.ReactElement;
    BodyContent: React.ReactElement<{ rows: T[] }>;
    BodyBaseline?: React.ReactElement;
    containerRef?: React.RefObject<HTMLDivElement>;
    elevation?: number;
  } & PaginatedTableCoreProps<T>,
) {
  const { HeaderContent, BodyContent, BodyBaseline, containerRef, elevation, sx } = props;
  const { currentPageRows, paginationCtrl } = usePaginatedTableInternalLogic(props);

  return (
    <TableContainer ref={containerRef} elevation={elevation} component={Paper} sx={sx}>
      <Table>
        <TableHead>
          {paginationCtrl}
          {HeaderContent}
        </TableHead>
        <TableBody>
          {React.cloneElement(BodyContent, { rows: currentPageRows })}
          {paginationCtrl}
          {BodyBaseline}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function PaginatedTableV2<T extends { rowIdx: number }>(
  props: {
    HeaderContent?: React.ReactElement;
    renderRow: (row: T) => React.ReactElement;
    BodyBaseline?: React.ReactElement;
    containerRef?: React.RefObject<HTMLDivElement>;
    elevation?: number;
  } & PaginatedTableCoreProps<T>,
) {
  const { HeaderContent, renderRow, BodyBaseline, containerRef, elevation, sx } = props;
  const { currentPageRows, paginationCtrl } = usePaginatedTableInternalLogic(props);

  return (
    <TableContainer ref={containerRef} elevation={elevation} component={Paper} sx={sx}>
      <Table>
        <TableHead>
          {paginationCtrl}
          {HeaderContent}
        </TableHead>
        <TableBody>
          <>{currentPageRows.map(renderRow)}</>
          {paginationCtrl}
          {BodyBaseline}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// This variant of paginated table is used for tables with horizontal scroll where the
// paginations are outside the scrollable area.
export function PaginatedTableWithHorizontalScroll<T>(
  props: {
    HeaderContent?: React.ReactElement;
    BodyContent: React.ReactElement<{ rows: T[] }>;
    minWidth?: number;
  } & PaginatedTableCoreProps<T>,
) {
  const { HeaderContent, BodyContent, minWidth, sx } = props;
  const { currentPageRows, paginationCtrl } = usePaginatedTableInternalLogic(props);

  // paginationCtrl is a table cell and needs to be wrapped in a table head to avoid
  // dom validation errors
  const paginationCtrlComponent = (
    <Table>
      <TableHead>{paginationCtrl}</TableHead>
    </Table>
  );

  return (
    <Stack width="100%" spacing={0}>
      {paginationCtrlComponent}
      <TableContainer component={Paper} sx={sx}>
        <Table sx={{ minWidth }}>
          <TableHead>{HeaderContent}</TableHead>
          <TableBody>{React.cloneElement(BodyContent, { rows: currentPageRows })}</TableBody>
        </Table>
      </TableContainer>
      {paginationCtrlComponent}
    </Stack>
  );
}

export function PaginatedTableWithHorizontalScrollV2<T>(
  props: {
    HeaderContent?: React.ReactElement;
    renderRow: (row: T) => React.ReactElement;
    minWidth?: number;
  } & PaginatedTableCoreProps<T>,
) {
  const { HeaderContent, renderRow, minWidth, sx } = props;
  const { currentPageRows, paginationCtrl } = usePaginatedTableInternalLogic(props);

  // paginationCtrl is a table cell and needs to be wrapped in a table head to avoid
  // dom validation errors
  const paginationCtrlComponent = (
    <Table>
      <TableHead>{paginationCtrl}</TableHead>
    </Table>
  );

  return (
    <Stack width="100%" spacing={0}>
      {paginationCtrlComponent}
      <TableContainer component={Paper} sx={sx}>
        <Table sx={{ minWidth }}>
          <TableHead>{HeaderContent}</TableHead>
          <TableBody>
            <>{currentPageRows.map(renderRow)}</>
          </TableBody>
        </Table>
      </TableContainer>
      {paginationCtrlComponent}
    </Stack>
  );
}

function calcInitialRowsPerPage(params: { initialRowsPerPage: number; totalRowCount: number }) {
  if (params.totalRowCount <= 2 * params.initialRowsPerPage) {
    return params.totalRowCount;
  } else {
    return params.initialRowsPerPage;
  }
}

// This hook contains the core pagination logic, allowing us to construct different variants of
// paginated tables
function usePaginatedTableInternalLogic<T>(props: PaginatedTableCoreProps<T>) {
  const {
    initialRows,
    initialRowsPerPage: initialRowsPerPageArg,
    rowsPerPageOptions,
    totalRowCount: totalRowCountArg,
    loadMoreData,
  } = props;
  const totalRowCount = totalRowCountArg ?? initialRows.length;
  const initialRowsPerPage = calcInitialRowsPerPage({
    initialRowsPerPage: initialRowsPerPageArg ?? 10,
    totalRowCount,
  });
  const checkedTotalRowCount = totalRowCount ?? initialRows.length;
  const [tableState, setTableState] = useState<TableState<T>>({
    pageIdx: 0,
    rowsPerPage: initialRowsPerPage,
    currentPageRows: initialRows.slice(0, initialRowsPerPage),
    initialRowCount: initialRows.length,
    cachedRows: initialRows,
    loadState: { state: "success", value: null },
  });

  // Need to react to changes in initialRows to update the cached rows, this should really only
  // happen when initialRows contains the full data set and no dynamic loading is involved.
  useEffect(() => {
    setTableState(prev => {
      const cachedRows = [...initialRows, ...prev.cachedRows.slice(prev.initialRowCount)];
      return {
        ...prev,
        initialRowCount: initialRows.length,
        cachedRows,
        currentPageRows: slicePageRows(cachedRows, prev.pageIdx, prev.rowsPerPage),
      };
    });
  }, [initialRows]);

  // this is the core pagination logic, triggered when either page index or rows per page changes
  // we assume pageIndex only increases by 1 at a time and pageIndex resets to 0 when rowsPerPage changes
  const updatePageState = useCallback(
    async (params: Pick<TableState<T>, "pageIdx" | "rowsPerPage">) => {
      const { pageIdx, rowsPerPage } = params;

      if (pageIdx < 0 || rowsPerPage < 1) {
        // invalid param values are ingored
        return;
      }
      const { currentPageRows, cachedRows, loadState } = tableState;
      const maxRowIdx = checkedTotalRowCount - 1;
      const lastRowIdx = Math.min((pageIdx + 1) * rowsPerPage - 1, maxRowIdx);
      if (lastRowIdx < cachedRows.length) {
        // if we already have enough rows in cache, we can just use them
        const newPageRows = slicePageRows(cachedRows, pageIdx, rowsPerPage);
        setTableState({
          ...params,
          currentPageRows: newPageRows,
          cachedRows,
          loadState: { state: "success", value: null },
          initialRowCount: tableState.initialRowCount,
        });
      } else if (loadState.state === "success" && loadMoreData) {
        // If we don't have enough rows in cache, we need to load more rows
        // unless we are already loading or have errored out.
        // First put the table in loading state
        setTableState({
          ...tableState,
          ...params,
          currentPageRows,
          cachedRows,
          loadState: { state: "loading" },
        });
        // load more rows iteratively until we have enough rows in cache
        // this while loop runs asynchrnously after this function returns
        while (lastRowIdx > cachedRows.length) {
          const newRows = await loadMoreData(cachedRows.length);
          if (newRows?.length) {
            cachedRows.push(...newRows);
          } else {
            // if we failed to load more rows, we need to put the table in error state
            setTableState({
              ...params,
              currentPageRows,
              cachedRows,
              loadState: { state: "error", error: new Error("Failed to load more data") },
              initialRowCount: tableState.initialRowCount,
            });
            return;
          }
        }
        // finally, we can update the table state with the new rows
        setTableState({
          ...params,
          currentPageRows: cachedRows.slice(pageIdx * rowsPerPage, lastRowIdx + 1),
          cachedRows,
          loadState: { state: "success", value: null },
          initialRowCount: tableState.initialRowCount,
        });
      }
    },
    [tableState, checkedTotalRowCount, loadMoreData],
  );

  function slicePageRows<T>(rows: T[], pageIdx: number, rowsPerPage: number) {
    return rows.slice(pageIdx * rowsPerPage, (pageIdx + 1) * rowsPerPage);
  }

  const handleChangePage = (_e: React.MouseEvent<HTMLButtonElement> | null, pageIdx: number) => {
    updatePageState({ pageIdx, rowsPerPage: tableState.rowsPerPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    updatePageState({ pageIdx: 0, rowsPerPage: +event.target.value });
  };

  const { pageIdx, rowsPerPage, currentPageRows, loadState } = tableState;
  const isLoading = loadState.state === "loading";

  // When table data is NOT dynamically loaded, we can add the "All" option to show all rows
  const updateRowsPerPageOptions =
    loadMoreData && initialRows.length < totalRowCount
      ? rowsPerPageOptions
      : rowsPerPageOptions ?? [10, 25, 50, 100, { value: checkedTotalRowCount, label: "All" }];

  const paginationCtrl = totalRowCount > initialRowsPerPage && (
    <TableRow>
      {/* Place loader widget here gives very subtle hint during loading also a blank space
          for displaying error message.
        */}
      <BorderlessTableCell>
        <Loader loadingStates={[loadState]} />
      </BorderlessTableCell>
      <StickyTablePagination
        style={{ opacity: isLoading ? 0.5 : 1, pointerEvents: isLoading ? "none" : "auto" }}
        count={checkedTotalRowCount}
        page={pageIdx}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={updateRowsPerPageOptions}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableRow>
  );
  return { currentPageRows, paginationCtrl };
}

const StickyTablePagination = styled(TablePagination)(({ theme }) => ({
  alignItems: "baseline",
  position: "sticky",
  // Note: Negative bottom due to rendering issue in chrome
  // bottom: 0
  bottom: "-1px",
  backgroundColor: theme.palette.common.white,
  border: "none",
}));

const BorderlessTableCell = styled(TableCell)(() => ({
  border: "none",
}));
