import { AppService } from "adl-gen/app-service";
import { StockData, StockListing, makeStockDataReq } from "adl-gen/ferovinum/app/api";
import { TopLevelUnitType } from "adl-gen/ferovinum/app/db";
import { Map as AdlMap } from "adl-gen/sys/types";
import { Loader } from "components/widgets/loader/loader";
import React, { useCallback, useMemo } from "react";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { assertNotUndefined } from "utils/hx/util/types";
import { isLoaded } from "utils/utility-types";
import { useAppService } from "../../../../hooks/use-app-service";
import { useSelectedOrgId } from "../../../layouts/portal-page-layout/portal-page";
import { OrganisationDashboardPageView, StockDataDisplayInfo } from "./organisation-dashboard-page-view";

export const makeStockDataMapDisplayInfo = (stockData: StockData): StockDataDisplayInfo => {
  const stockDataMap: Map<string, StockListing[]> = new Map();

  stockData.stockListings.forEach(listing => {
    const { code: productCode } = listing.product.value;

    if (stockDataMap.has(productCode)) {
      const listings = stockDataMap.get(productCode);

      if (listings !== undefined) {
        listings.push(listing);
      }
    } else {
      stockDataMap.set(productCode, [listing]);
    }
  });

  return {
    stockDataMap,
    totalRowCount: stockData.totalProductCount,
  };
};

const STOCK_DATA_QUERY_LIMIT = 500;

export const OrganisationDashboardPage = () => {
  const service: AppService = useAppService();
  const selectedOrgId = assertNotUndefined(useSelectedOrgId());

  const getStockData = useCallback(async () => {
    const stockDataByTopLevelUnitType: AdlMap<TopLevelUnitType, StockData> = await service.getStockData(
      makeStockDataReq({
        organisationId: selectedOrgId,
        topLevelUnitTypeOption: { kind: "all" },
        offset: 0,
        count: STOCK_DATA_QUERY_LIMIT,
      }),
    );

    const result: Map<TopLevelUnitType, StockDataDisplayInfo> = new Map();
    for (const { key, value } of stockDataByTopLevelUnitType) {
      result.set(key, makeStockDataMapDisplayInfo(value));
    }
    return result;
  }, [selectedOrgId, service]);

  const [loadingStockData] = useLoadingDataState(getStockData);

  const availableStockTypes = useMemo<Set<TopLevelUnitType>>(() => {
    return isLoaded(loadingStockData)
      ? new Set(
          [...loadingStockData.value.keys()].filter(key => loadingStockData.value.get(key)?.stockDataMap.size ?? 0 > 0),
        )
      : new Set();
  }, [loadingStockData]);

  const loadImportantReminders = useCallback(
    async () => await service.getOrganisationImportantReminders({ organisationId: selectedOrgId }),
    [selectedOrgId, service],
  );
  const [loadingImportantReminders] = useLoadingDataState(loadImportantReminders);

  const loadMoreStockDataMap = async (topLevelUnitType: TopLevelUnitType, offset: number) => {
    const stockDataResp = await service.getStockData(
      makeStockDataReq({
        organisationId: selectedOrgId,
        topLevelUnitTypeOption: { kind: "byUnitType", value: topLevelUnitType },
        offset,
        count: STOCK_DATA_QUERY_LIMIT,
      }),
    );
    const stockData = stockDataResp.find(entry => entry.key === topLevelUnitType)?.value;
    return stockData && makeStockDataMapDisplayInfo(stockData).stockDataMap;
  };

  return (
    <Loader loadingStates={[loadingStockData]} fullScreen>
      {isLoaded(loadingStockData) && (
        <OrganisationDashboardPageView
          availableStockTypes={availableStockTypes}
          stockDataLookup={loadingStockData.value}
          loadingImportantReminders={loadingImportantReminders}
          loadMoreStockDataMap={loadMoreStockDataMap}
        />
      )}
    </Loader>
  );
};
