import React, { useCallback, useMemo } from "react";
import {
  Stack,
  Alert,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TextField,
  Button,
  Box,
} from "@mui/material";
import {
  NewDealRequestIncomingShipmentView,
  NewDealRequestDeliveredShipmentView,
  StorageLocConfirmDeliveryReq,
  ProductInShipment,
} from "adl-gen/ferovinum/app/api";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../../app/app-routes";
import { formatDateToISO8601, formatLocalDate } from "utils/date-utils";
import { PortalPageContent } from "../../../layouts/portal-page-content/portal-page-content";
import { useConfirmationDialog } from "components/context/global-dialog/use-dialog";
import { OrganisationProductSummary } from "../../../widgets/organisation-product-summary/organisation-product-summary";
import { array, number, object, string } from "yup";
import { FormikProps, useFormik } from "formik";
import { Product } from "adl-gen/ferovinum/app/db";
import { DbKey } from "adl-gen/common/db";
import { numberOfUnitsForUnitType } from "utils/model-utils";
import { PortalPageContentHeader } from "../../../layouts/portal-page-content-header/portal-page-content-header";
import { TabbedPage } from "components/library/widgets/tabbed-page";
import { assertNotUndefined } from "utils/hx/util/types";

export type StorageLocIncomingShipmentsViewTabType = "incoming" | "delivered";
export interface StorageLocIncomingShipmentsPageViewProps {
  filter: StorageLocIncomingShipmentsViewTabType;
  incomingShipments: NewDealRequestIncomingShipmentView[];
  deliveredShipments: NewDealRequestDeliveredShipmentView[];
  onConfirmDelivery: (shipment: StorageLocConfirmDeliveryReq) => Promise<void>;
}

export const StorageLocIncomingShipmentsPageView = ({
  incomingShipments,
  deliveredShipments,
  onConfirmDelivery,
}: StorageLocIncomingShipmentsPageViewProps) => {
  const history = useHistory();

  const handleTabChange = ({ key }: { key?: string }) => {
    history.push(`${AppRoutes.LocNewDealRequests}/${key}`);
  };

  return (
    <PortalPageContent header={<PortalPageContentHeader title={"Shipments"} />}>
      <TabbedPage
        handleTabChange={handleTabChange}
        tabs={[
          {
            label: "Incoming Shipments",
            key: "incoming",
            content: (
              <>
                {incomingShipments.length > 0 ? (
                  incomingShipments.map(shipment => (
                    <ShipmentDetails
                      key={shipment.newDealRequestId}
                      status="incoming"
                      shipment={shipment}
                      onConfirmDelivery={onConfirmDelivery}
                    />
                  ))
                ) : (
                  <Alert severity="info">You currently have no incoming shipments</Alert>
                )}
              </>
            ),
          },
          {
            label: "Delivered Shipments",
            key: "delivered",
            content: (
              <>
                {deliveredShipments.map(shipment => (
                  <ShipmentDetails status="delivered" key={shipment.newDealRequestId} shipment={shipment} />
                ))}
              </>
            ),
          },
        ]}
      />
    </PortalPageContent>
  );
};

interface IncomingShipmentProps {
  status: "incoming";
  shipment: NewDealRequestIncomingShipmentView;
  onConfirmDelivery: (shipment: StorageLocConfirmDeliveryReq) => Promise<void>;
}

interface DeliveredShipmentProps {
  status: "delivered";
  shipment: NewDealRequestDeliveredShipmentView;
}

type ShipmentDetailsProps = IncomingShipmentProps | DeliveredShipmentProps;

interface IncomingShipmentFormValues {
  data: {
    productId: DbKey<Product>;
    quantity: number;
    rotationNumber?: string;
  }[];
}

const ShipmentDetails = (details: ShipmentDetailsProps) => {
  const { status, shipment } = details;
  const { showConfirmationDialog } = useConfirmationDialog();

  const isIncoming = useCallback(
    (
      _: NewDealRequestDeliveredShipmentView | NewDealRequestIncomingShipmentView,
    ): _ is NewDealRequestIncomingShipmentView => {
      return status === "incoming";
    },
    [status],
  );

  const validationSchema = useMemo(() => {
    const rotationNumberSchema = string();
    return object().shape({
      data: array().of(
        object().shape({
          quantity: number().required("This is a required field").min(0, "Please input 0 or greater"),
          rotationNumber:
            isIncoming(shipment) && shipment.rotationNumberRequired
              ? rotationNumberSchema.required("This is a required field")
              : rotationNumberSchema,
        }),
      ),
    });
  }, [isIncoming, shipment]);

  const initialFormValues: IncomingShipmentFormValues = useMemo(() => {
    if (isIncoming(shipment)) {
      return {
        data: shipment.productsInShipment.map(p => {
          return {
            productId: p.id,
            quantity: p.requestedQuantity.value,
            rotationNumber: p.rotationNumber?.trim() || undefined,
          };
        }),
      };
    }
    return { data: [] };
  }, [isIncoming, shipment]);

  const confirmDelivery = useCallback(
    async (formValues: IncomingShipmentFormValues) => {
      if (!isDeliveredShipmentDetails(details)) {
        await details.onConfirmDelivery({
          newDealRequestId: shipment.newDealRequestId,
          productCollectionData: formValues.data.map(value => {
            const unitType = assertNotUndefined(details.shipment.productsInShipment.find(p => p.id === value.productId))
              .product.unitType;
            return {
              ...value,
              quantity: numberOfUnitsForUnitType(unitType, value.quantity),
              rotationNumber: value.rotationNumber?.trim() || null,
            };
          }),
          deliveryDate: formatDateToISO8601(new Date()),
        });
      }
    },
    [details, shipment.newDealRequestId],
  );

  const form = useFormik<IncomingShipmentFormValues>({
    initialValues: initialFormValues,
    validationSchema,
    onSubmit: confirmDelivery,
    validateOnMount: true,
  });

  return (
    <Paper sx={{ padding: theme => theme.spacing(3) }}>
      <Typography variant="h6" fontWeight="bold">
        {/* TODO (Nicole): change this to deal number */}
        Deal #{shipment.newDealRequestId}
      </Typography>

      <Stack spacing={3}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography fontWeight="bold">Organisation:</Typography>
                </TableCell>
                <TableCell>{shipment.organisationName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography fontWeight="bold">Supplier:</Typography>
                </TableCell>
                <TableCell>{shipment.supplierName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography fontWeight="bold">Carrier:</Typography>
                </TableCell>
                <TableCell>{shipment.carrierName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography fontWeight="bold">{isIncoming(shipment) ? "ETA" : "Delivered on"}:</Typography>
                </TableCell>
                <TableCell>
                  {isIncoming(shipment) && shipment.carrierDeliveryEta
                    ? formatLocalDate(shipment.carrierDeliveryEta)
                    : "-"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Stack spacing={3}>
          <Typography variant="h6">
            {isIncoming(shipment) ? "Products to be delivered" : "Delivered products"}
          </Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Singles per case</TableCell>
                  <TableCell align={isIncoming(shipment) ? "center" : "right"}>Delivered Quantity</TableCell>
                  <TableCell align={isIncoming(shipment) ? "center" : "right"}>
                    Rotation Number {isIncoming(shipment) && !shipment.rotationNumberRequired ? "(optional)" : null}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {shipment.productsInShipment.map((item, idx) => (
                  <React.Fragment key={item.id}>
                    {isIncoming(shipment) ? (
                      <IncomingProductRow
                        incomingProduct={item}
                        rowId={idx}
                        form={form}
                        rotationNumberRequired={shipment.rotationNumberRequired}
                      />
                    ) : (
                      <DeliveredProductRow deliveredProduct={item} />
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {isIncoming(shipment) && (
            <Box>
              <Button
                variant="contained"
                onClick={async () =>
                  await showConfirmationDialog({
                    title: "Confirm delivery?",
                    confirmAction: {
                      onClick: form.submitForm,
                    },
                  })
                }
                disabled={!form.isValid}>
                Confirm Delivery
              </Button>
            </Box>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

interface IncomingProductRowProps {
  incomingProduct: ProductInShipment;
  form: FormikProps<IncomingShipmentFormValues>;
  rowId: number;
  rotationNumberRequired: boolean;
}
const IncomingProductRow = ({ incomingProduct, form, rowId, rotationNumberRequired }: IncomingProductRowProps) => {
  return (
    <TableRow key={incomingProduct.id}>
      <TableCell>
        <OrganisationProductSummary {...incomingProduct.product} />
      </TableCell>
      <TableCell align="right">{incomingProduct.requestedQuantity.value}</TableCell>
      <TableCell align="right">{incomingProduct.singlesPerCase ?? "-"}</TableCell>
      <>
        <TableCell align="center">
          <TextField
            type="number"
            required
            name={`data[${rowId}].quantity`}
            value={form.values.data[rowId].quantity}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={
              form.touched.data &&
              Boolean(
                form.errors.data !== undefined &&
                  form.errors.data[rowId] !== undefined &&
                  // @ts-ignore
                  form.errors.data[rowId]["quantity"],
              )
            }
            // @ts-ignore
            helperText={form.touched.data && form.errors.data?.[rowId]?.["quantity"]}
          />
        </TableCell>
        <TableCell align="center">
          <TextField
            required={rotationNumberRequired}
            value={form.values.data[rowId].rotationNumber ?? ""}
            name={`data[${rowId}].rotationNumber`}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={
              rotationNumberRequired &&
              form.touched.data &&
              Boolean(
                form.errors.data !== undefined &&
                  form.errors.data[rowId] !== undefined &&
                  // @ts-ignore
                  form.errors.data[rowId]["rotationNumber"],
              )
            }
            // @ts-ignore
            helperText={form.touched.data && form.errors.data?.[rowId]?.["rotationNumber"]}
          />
        </TableCell>
      </>
    </TableRow>
  );
};

interface DeliveredProductRowProps {
  deliveredProduct: ProductInShipment;
}
const DeliveredProductRow = ({ deliveredProduct }: DeliveredProductRowProps) => {
  return (
    <TableRow key={deliveredProduct.id}>
      <TableCell>
        <OrganisationProductSummary {...deliveredProduct.product} />
      </TableCell>
      <TableCell align="right">{deliveredProduct.requestedQuantity.value}</TableCell>
      <TableCell align="right">{deliveredProduct.singlesPerCase ?? "-"}</TableCell>
      <TableCell align="right">{deliveredProduct.deliveredQuantity?.value ?? "-"}</TableCell>
      <TableCell align="right">{deliveredProduct.rotationNumber ?? "-"}</TableCell>
    </TableRow>
  );
};

function isDeliveredShipmentDetails(
  value: IncomingShipmentProps | DeliveredShipmentProps,
): value is DeliveredShipmentProps {
  // @ts-ignore
  return value["onConfirmDelivery"] === undefined;
}
