import { Button, ButtonProps, Stack, Typography } from "@mui/material";
import { AppService } from "adl-gen/app-service";
import {
  NominatedPurchaserDetailsView,
  ProductSalePricesScope,
  makePresetSalePricesUploadReq,
  makeProductSalePriceInfo,
} from "adl-gen/ferovinum/app/api";
import { Currency } from "adl-gen/ferovinum/app/db";
import { PRODUCT_PRICE_UPLOAD_CSV_TEMPLATE_URL } from "components/assets/url";
import { DialogContext } from "components/context/global-dialog/dialog-manager";
import { useInfoDialog } from "components/context/global-dialog/use-dialog";
import { CsvUploadButtonWithArrayData } from "components/widgets/buttons/csv-upload-button/csv-upload-button";
import { Dropdown } from "components/widgets/dropdown/dropdown";
import { Loader } from "components/widgets/loader/loader";
import { ParseResult } from "papaparse";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { CsvUploadResult, WithCsvRowNumber } from "utils/csv-utils";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { assertNotUndefined } from "utils/hx/util/types";
import { isLoaded } from "utils/utility-types";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { ProductSalePriceCsvParser, ProductSalePriceRowData } from "./product-sale-price-csv-parser";

export const UploadProductSalePricesButton = () => {
  const { showInfoDialog } = useInfoDialog();
  const service = useAppService();

  const selectedOrgId = assertNotUndefined(useSelectedOrgId());

  return (
    <Button
      variant="outlined"
      onClick={async e => {
        e.preventDefault();
        await showInfoDialog({
          body: <DialogView organisationId={selectedOrgId} service={service} />,
          size: "sm",
        });
      }}>
      Upload Product Prices
    </Button>
  );
};

const DialogView = ({ organisationId, service }: { organisationId: string; service: AppService }) => {
  const { handleClose } = useContext(DialogContext);

  const loadNominatedPurchasers = useCallback(async () => {
    const resp = await service.getNominatedPurchasersForOrg({ organisationId });
    return resp.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));
  }, [organisationId, service]);
  const [loadingNominatedPurchasers] = useLoadingDataState(loadNominatedPurchasers);

  const saveProductSalePrices = useCallback(
    async (scope: ProductSalePricesScope, salePrices: ProductSalePriceRowData[]) => {
      const req = makePresetSalePricesUploadReq({
        scope,
        salePrices: salePrices.map(row => {
          return makeProductSalePriceInfo({
            productCode: row.productCode,
            currency: row.currency,
            incDutyExVatPrice: row.incDutyExVatPrice?.toFixed(2) ?? null,
            incDutyAndVatPrice: row.incDutyAndVatPrice?.toFixed(2) ?? null,
            exDutyAndVatPrice: row.exDutyAndVatPrice?.toFixed(2) ?? null,
          });
        }),
      });

      await service.saveProductSalePrices(req);
      setTimeout(handleClose);
    },
    [service, handleClose],
  );

  return (
    <Loader loadingStates={[loadingNominatedPurchasers]}>
      {isLoaded(loadingNominatedPurchasers) && (
        <DialogViewInner
          organisationId={organisationId}
          service={service}
          nomPurchasers={loadingNominatedPurchasers.value}
          saveProductSalePrices={saveProductSalePrices}
        />
      )}
    </Loader>
  );
};

const DialogViewInner = ({
  organisationId,
  service,
  nomPurchasers,
  saveProductSalePrices,
}: {
  organisationId: string;
  service: AppService;
  nomPurchasers: NominatedPurchaserDetailsView[];
  saveProductSalePrices: (scope: ProductSalePricesScope, salePrices: ProductSalePriceRowData[]) => Promise<void>;
}) => {
  const [selectedPriceScope, setSelectedPriceScope] = useState<ProductSalePricesScope>({
    kind: "organisationWide",
    value: organisationId,
  });

  return (
    <Stack spacing={4} alignItems="center">
      <Typography variant="h5">Upload Product Sale Prices</Typography>
      <PriceScopePicker
        organisationId={organisationId}
        nomPurchasers={nomPurchasers}
        initialValue={selectedPriceScope}
        onChange={scope => {
          setSelectedPriceScope(scope);
        }}
      />

      <CsvLoaderButton
        organisationId={organisationId}
        service={service}
        requiredCurrency={getRequiredCurrency(selectedPriceScope, nomPurchasers)}
        onSuccess={prices => saveProductSalePrices(selectedPriceScope, prices)}
        fullWidth
      />
    </Stack>
  );
};

function getRequiredCurrency(scope: ProductSalePricesScope, nomPurchasers: NominatedPurchaserDetailsView[]) {
  if (scope.kind === "perPurchaser") {
    return nomPurchasers.find(p => p.nominatedPurchaserId === scope.value)?.currency ?? null;
  } else {
    return null;
  }
}

const PriceScopePicker = (props: {
  organisationId: string;
  nomPurchasers: NominatedPurchaserDetailsView[];
  initialValue: ProductSalePricesScope;
  onChange: (scope: ProductSalePricesScope) => void;
}) => {
  const { organisationId, nomPurchasers } = props;
  const menuItems = useMemo(
    () => [
      {
        label: "Default Pricing",
        value: { kind: "organisationWide", value: organisationId } as ProductSalePricesScope,
      },
      ...nomPurchasers.map(p => ({
        label: p.name,
        value: { kind: "perPurchaser", value: p.nominatedPurchaserId } as ProductSalePricesScope,
      })),
    ],
    [organisationId, nomPurchasers],
  );

  const lookupMenuItem = useCallback(
    (v: ProductSalePricesScope) => {
      return menuItems.find(m => m.value.kind === v.kind && m.value.value === v.value);
    },
    [menuItems],
  );

  const [selectedItem, setSelectedItem] = useState(lookupMenuItem(props.initialValue));

  return (
    <Dropdown<ProductSalePricesScope>
      inputLabel=""
      menuItems={menuItems}
      defaultValue={selectedItem}
      onChange={v => {
        const menuItem = v && lookupMenuItem(v);
        if (menuItem) {
          setSelectedItem(menuItem);
          props.onChange(v);
        }
      }}
    />
  );
};

const CsvLoaderButton = ({
  organisationId,
  service,
  requiredCurrency,
  onSuccess,
  ...buttonProps
}: {
  organisationId: string;
  service: AppService;
  requiredCurrency: Currency | null;
  onSuccess(uploaded: ProductSalePriceRowData[]): void;
} & ButtonProps) => {
  const onParseCsv = useCallback(
    async (parseResult: ParseResult<string[]>): Promise<CsvUploadResult<ProductSalePriceRowData>> => {
      const trimmedData = parseResult.data.map(row => row.map(s => s.trim()));
      const header = trimmedData[0]?.map(s => s?.trim());
      const body = trimmedData.slice(1);

      if (!body || body.length === 0) {
        return { kind: "errors", value: [{ rowNumber: 0, value: { errors: ["No data found."] } }] };
      }

      const csvParser = new ProductSalePriceCsvParser(header);
      return csvParser.process(body, service, organisationId, requiredCurrency);
    },
    [service, organisationId, requiredCurrency],
  );

  const onSuccessfulUpload = useCallback(
    async (uploaded: WithCsvRowNumber<ProductSalePriceRowData>[]) => {
      await onSuccess(uploaded.map(({ value }) => value));
    },
    [onSuccess],
  );

  return (
    <CsvUploadButtonWithArrayData
      rowIdentifierFieldName="Product Code - Currency"
      onParseCsv={onParseCsv}
      onSuccessfulUpload={onSuccessfulUpload}
      csvTemplate={PRODUCT_PRICE_UPLOAD_CSV_TEMPLATE_URL}
      {...buttonProps}>
      Select Price CSV File
    </CsvUploadButtonWithArrayData>
  );
};
