import React from "react";
import { FastField } from "formik";
import { CurrencyInput, CurrencyInputProps } from "./currency-input";

type MetaProps = { meta: { currency: CurrencyInputProps["currency"]; error: CurrencyInputProps["error"] } };
// Note: FastField only re-renders if one of the formik managed values changed
// Because we also display the currency as a startAdornment we want to force trigger re-render of this field if the unitType changes
// https://github.com/jaredpalmer/formik/issues/1188
function shouldUpdate(nextProps: MetaProps, prevProps: MetaProps) {
  return nextProps.meta.currency !== prevProps.meta.currency || nextProps.meta.error !== prevProps.meta.error;
}
export const CurrencyInputFastField = (props: CurrencyInputProps) => (
  <FastField name={props.name} meta={{ currency: props.currency, error: props.error }} shouldUpdate={shouldUpdate}>
    {() => <CurrencyInput {...props} />}
  </FastField>
);
