import { WizardStepComponent, useWizardStepSetup } from "components/library/components/wizard/wizard";
import { StockMovementSummary } from "components/widgets/stock-movement/stock-movement-summary";
import { default as React } from "react";
import { assertNotUndefined } from "utils/hx/util/types";
import { useSelectedOrg } from "../../../layouts/portal-page-layout/portal-page";
import {
  CONFIRMATION_FIELD_DEF,
  StockMovementWizardContext,
  StockMovementWizardData,
} from "./stock-movement-wizard-types";

export const StockMovementConfirmationSectionStep: WizardStepComponent<
  StockMovementWizardData,
  StockMovementWizardContext,
  "confirmation"
> = ({ stepContext, registerDataGetter }) => {
  const organisation = assertNotUndefined(useSelectedOrg());
  useWizardStepSetup({}, CONFIRMATION_FIELD_DEF, registerDataGetter);
  return <StockMovementSummary {...stepContext.stockMovementSummary} organisation={organisation.value} />;
};
