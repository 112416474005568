import { Grid, Box } from "@mui/material";
import { FerovinumLogo } from "components/logos/logos";
import React from "react";

export interface PublicPageLayoutProps {
  children: React.ReactNode;
  imageURL: string;
}

/** Page layout used in public pages accessible by not logged-in users */
export const PublicPageLayout = ({ children, imageURL }: PublicPageLayoutProps) => {
  return (
    <Grid container sx={{ minHeight: "100vh" }}>
      <Grid
        item
        xs={6}
        sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
        <Box sx={{ position: "absolute", paddingRight: "40px", bottom: "30px" }}>
          <FerovinumLogo color="white" />
        </Box>

        <Box component="img" src={imageURL} sx={{ objectFit: "cover", maxWidth: "100%", minHeight: "100%" }} />
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box
          sx={{ marginLeft: "125px", marginRight: "125px", display: "flex", flexDirection: "column", width: "100%" }}>
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};
