import React from "react";
import { Instant, LocalDate } from "adl-gen/common";
import { NewDealRequestState, NewDealRequestType } from "adl-gen/ferovinum/app/db";
import {
  ExistingStockProcurementStateCode,
  NewStockProcurementStateCode,
  valuesExistingStockProcurementStateCode,
  valuesNewStockProcurementStateCode,
} from "adl-gen/ferovinum/app/procurement";
import { useMemo } from "react";
import { mapExistingStockProcurementStateCode, mapNewStockProcurementStateCode } from "utils/conversion-utils";
import { BaseProgressStepper } from "../../base-progress-stepper/base-progress-stepper";

type ProcurementStateCode = NewStockProcurementStateCode | ExistingStockProcurementStateCode;

type NotRejectedProcurementStateCode = Exclude<
  ProcurementStateCode,
  // These cases should never be reached because rejected new deal requests are not included in the dashboard
  "FEROREJECTED" | "ORGREJECTED"
>;

// Gets the progress stepper label for in progress new deal requests
function getProgressStepperLabelFromState(state: NotRejectedProcurementStateCode) {
  switch (state) {
    case "NEWDEAL":
      return "Purchase order requested";
    case "FEROACCEPTED":
      return "Ferovinum accepted";
    case "ORGACCEPTED":
      return "Contract note accepted";
    case "DEPOSITINVOICED":
      return "Deposit invoice sent";
    case "DEPOSITPAID":
      return "Deposit paid";
    case "DEPOSITPAYMENTRECEIVED":
      return "Deposit received";
    case "POCREATED":
      return "Purchase order sent to supplier";
    case "POACCEPTED":
      return "Purchase order accepted by supplier";
    case "COLLECTIONREQUESTED":
      return "Availability date provided";
    case "COLLECTIONACCEPTED":
      return "Availability date confirmed";
    case "COLLECTIONCONFIRMED":
      return "Collected";
    case "CARRIERDELIVERYCONFIRMED":
      return "Delivered";
    case "WAREHOUSEDELIVERYCONFIRMED":
      return "Landed";
    case "STOCKTRANSFERRED":
      return "Transfer confirmed by storage location";
    case "PAYMENTTRANSFERRED":
      return "Ferovinum paid";
    default:
      throw Error("not supported state: " + state);
  }
}

function assertNotRejectedStateCode(stateCode: ProcurementStateCode): NotRejectedProcurementStateCode {
  if (stateCode === "FEROREJECTED" || stateCode === "ORGREJECTED") {
    throw new Error("Expected state code to be not rejected");
  }

  return stateCode;
}

export interface NewDealRequestProgressStepperProps {
  variant: NewDealRequestType;
  currentState: NewDealRequestState;
  lastStateSet: Instant;
  upcomingStateDeadline?: LocalDate;
  actionRequiredStateCode: NotRejectedProcurementStateCode;
}
export const NewDealRequestProgressStepper = ({
  variant,
  currentState,
  lastStateSet,
  upcomingStateDeadline,
  actionRequiredStateCode,
}: NewDealRequestProgressStepperProps) => {
  const isNewStock = variant === "newStock";
  const currentStateCode = useMemo(
    () =>
      isNewStock ? mapNewStockProcurementStateCode(currentState) : mapExistingStockProcurementStateCode(currentState),
    [currentState, isNewStock],
  );
  const states = useMemo(
    () =>
      isNewStock
        ? valuesNewStockProcurementStateCode.filter(
            s => s !== "ORGREJECTED" && s !== "FEROREJECTED" && s !== "CANCELLED",
          )
        : valuesExistingStockProcurementStateCode.filter(
            s => s !== "ORGREJECTED" && s !== "FEROREJECTED" && s !== "CANCELLED",
          ),
    [isNewStock],
  );

  return (
    <BaseProgressStepper<NewStockProcurementStateCode | ExistingStockProcurementStateCode>
      currentState={currentStateCode}
      states={states}
      lastStateSet={lastStateSet}
      upcomingStateDeadline={upcomingStateDeadline}
      actionRequiredState={actionRequiredStateCode}
      getHumanReadableState={state => getProgressStepperLabelFromState(assertNotRejectedStateCode(state))}
    />
  );
};
