import React from "react";
import { Paper, Stack, TableCell, TableRow, Typography, styled } from "@mui/material";
import { DbKey, WithDbId } from "adl-gen/common/db";
import { RevaluationDetailsResp, RevaluationLineItemDetails } from "adl-gen/ferovinum/app/api";
import { Currency, DealLeg, MonthlyFee, NumberOfUnits, Product } from "adl-gen/ferovinum/app/db";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import { InfoTooltip } from "components/widgets/info-tooltip/info-tooltip";
import { ProductSummary } from "components/widgets/product-summary/product-summary";
import { formatNumberOfUnits } from "utils/model-utils";
import { PaginatedTable } from "components/widgets/paginated-table/paginated-table";

export interface RevaluationLineItemData {
  onClickProductId?: () => void;
  sourceDealLegId: DbKey<DealLeg>;
  product: WithDbId<Product>;
  quantity: NumberOfUnits;
  prevPurchasePrice: string;
  prevDepositPrice: string;
  newPurchasePrice: string;
  newDepositPrice: string;
  monthlyFee: MonthlyFee;
  newThroughputFeePct: string;
  netAdvance: string;
}

export interface RevaluationPricingTotalsTableProps {
  revaluationDetails: RevaluationDetailsResp;
}

function RevaluationConfirmationTableHeader() {
  return (
    <TableRow>
      <TableCell>Product</TableCell>
      <TableCell align="right">Quantity</TableCell>
      <TableCell align="center">Previous Price</TableCell>
      <TableCell align="right">Previous Deposit</TableCell>
      <TableCell align="right">New Price</TableCell>
      <TableCell align="right">New Deposit</TableCell>
      <TableCell align="right">Fees</TableCell>
      <TableCell align="right">Net Advance</TableCell>
    </TableRow>
  );
}

function RevaluationConfirmationTableBody(props: { rows: RevaluationLineItemDetails[]; currency: Currency }) {
  const { rows, currency } = props;
  return (
    <>
      {rows.map(li => (
        <LineItemDetailsRow key={li.product.id} currency={currency} {...li} />
      ))}
    </>
  );
}

export const RevaluationPricingTotalsTable = ({ revaluationDetails }: RevaluationPricingTotalsTableProps) => {
  return (
    <Paper sx={{ backgroundColor: "common.grey1" }} elevation={0}>
      <PaginatedTable
        initialRows={revaluationDetails.lineItems}
        totalRowCount={revaluationDetails.lineItems.length}
        HeaderContent={<RevaluationConfirmationTableHeader />}
        BodyContent={
          <RevaluationConfirmationTableBody
            rows={revaluationDetails.lineItems}
            currency={revaluationDetails.currency}
          />
        }
      />

      <Stack alignItems="flex-end">
        <TableCell />
        <SummaryRow
          title="Gross Purchase Price Payable by Ferovinum"
          value={revaluationDetails.pricingTotals.grossPurchasePricePayable}
          currency={revaluationDetails.currency}
        />
        <SummaryRow
          title="Total Deposit Due"
          value={revaluationDetails.pricingTotals.totalDepositDue}
          currency={revaluationDetails.currency}
          bottomUnderline
        />
        <SummaryRow
          title="Net Advance Amount"
          value={revaluationDetails.pricingTotals.netAdvance}
          currency={revaluationDetails.currency}
        />
      </Stack>
    </Paper>
  );
};

type LineItemDetailsRowProps = RevaluationLineItemData & {
  currency: Currency;
};

const BasicTableCell = styled(TableCell)({
  textAlign: "right",
  rowSpan: 2,
});

const MAX_DIGITS_FOR_DISPLAY = 2;
const SHOW_TOOL_TIP = true;

const LineItemDetailsRow = ({
  currency,
  onClickProductId,
  product,
  quantity,
  prevPurchasePrice,
  prevDepositPrice,
  newPurchasePrice,
  newDepositPrice,
  monthlyFee,
  newThroughputFeePct,
  netAdvance,
}: LineItemDetailsRowProps) => {
  const { unitType } = product.value;
  return (
    <>
      <TableRow>
        <TableCell>
          <ProductSummary {...product.value} onClick={onClickProductId ? () => onClickProductId() : undefined} />
        </TableCell>
        <BasicTableCell>{formatNumberOfUnits(quantity, unitType)}</BasicTableCell>
        <BasicTableCell>
          <CurrencyRenderer currency={currency} value={prevPurchasePrice} showTooltip={SHOW_TOOL_TIP} />
        </BasicTableCell>
        <BasicTableCell>
          <CurrencyRenderer currency={currency} value={prevDepositPrice} showTooltip={SHOW_TOOL_TIP} />
        </BasicTableCell>
        <BasicTableCell>
          <CurrencyRenderer currency={currency} value={newPurchasePrice} showTooltip={SHOW_TOOL_TIP} />
        </BasicTableCell>
        <BasicTableCell>
          <CurrencyRenderer currency={currency} value={newDepositPrice} showTooltip={SHOW_TOOL_TIP} />
        </BasicTableCell>
        <Stack>
          <BasicTableCell>
            <Stack>
              <Typography variant="caption" color="common.grey6">
                Monthly fees
              </Typography>
              <Typography>{`${monthlyFeePercentage(monthlyFee)}% ${monthlyFee.kind}`}</Typography>
            </Stack>
          </BasicTableCell>
          <BasicTableCell>
            <Stack>
              <Typography variant="caption" color="common.grey6">
                Throughput fees
              </Typography>
              <Typography>{`${parseFloat(newThroughputFeePct).toFixed(MAX_DIGITS_FOR_DISPLAY)}%`}</Typography>
            </Stack>
          </BasicTableCell>
        </Stack>
        <BasicTableCell>
          <CurrencyRenderer currency={currency} value={netAdvance} showTooltip={SHOW_TOOL_TIP} />
        </BasicTableCell>
      </TableRow>
    </>
  );
};

export interface SummaryRowProps {
  title: string;
  tooltip?: string;
  value: string | undefined;
  currency: Currency;
  bottomUnderline?: boolean;
}
export const SummaryRow = ({ title, tooltip, value, currency }: SummaryRowProps) => {
  return (
    <TableRow sx={{ backgroundColor: "common.grey1" }}>
      <TableCell />
      <TableCell>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Typography variant="subtitle1Bold">{title}</Typography>
          {tooltip && <InfoTooltip title={tooltip} />}
        </Stack>
      </TableCell>
      <TableCell>
        <CurrencyRenderer currency={currency} value={value} emptyValue="-" showTooltip={SHOW_TOOL_TIP} />
      </TableCell>
    </TableRow>
  );
};

function monthlyFeePercentage(monthlyFee: MonthlyFee) {
  if (monthlyFee.kind === "fixed") {
    return monthlyFee.value;
  } else {
    return monthlyFee.value.base;
  }
}
