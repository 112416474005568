import { BigDecimal } from "adl-gen/common";
import {
  Currency,
  DeliveryFee,
  DutyAndVat,
  PurchaseRequestSalePriceType,
  PurchaseRequestState,
} from "adl-gen/ferovinum/app/db";
import { PurchaseRequestLineItem } from "adl-gen/ferovinum/app/nompurchaser";
import React from "react";
import { PurchaserPurchaseRequestDetailsInnerView } from "../../../page/purchaser/purchaser-purchase-request-details/purchaser-purchase-request-details-page-view";

export const OrganisationPurchaserSummarySection = ({
  purchaseRequestData,
}: {
  purchaseRequestData: {
    state: PurchaseRequestState;
    purchaserCurrency: Currency;
    settlementCurrency: Currency;
    summary: {
      netSubtotal: string;
      dealDiscountAmount: BigDecimal | null;
      dutyAndVat: DutyAndVat | null;
      deliveryFee: DeliveryFee | null;
      lineItems: Pick<
        PurchaseRequestLineItem,
        "product" | "paidUnits" | "freeUnits" | "salePricePerPaidUnit" | "priceDiscount"
      >[];
    };
    salePriceType: PurchaseRequestSalePriceType;
    purchaserCoversDeliveryCost: boolean;
  };
}) => {
  const calculateTotalPurchaseAmount = (): string => {
    let numbers: (BigDecimal | undefined | null)[] = [
      purchaseRequestData.summary.netSubtotal,
      purchaseRequestData.summary.dutyAndVat?.duty,
      purchaseRequestData.summary.dutyAndVat?.vat,
    ];
    if (purchaseRequestData.purchaserCoversDeliveryCost) {
      numbers = [
        ...numbers,
        purchaseRequestData.summary.deliveryFee?.deliveryFee,
        purchaseRequestData.summary.deliveryFee?.vat,
      ];
    }
    return sumBigDecimal(numbers);
  };

  const calculateNetSubtotal = (): string => {
    // If sale price is inc duty/vat then this is bundled into the net subtotal
    const addDuty =
      purchaseRequestData.salePriceType === "incDutyAndVat" || purchaseRequestData.salePriceType === "incDutyExVat";
    const addVat = purchaseRequestData.salePriceType === "incDutyAndVat";
    return sumBigDecimal([
      purchaseRequestData.summary.netSubtotal,
      addDuty ? purchaseRequestData.summary.dutyAndVat?.duty : null,
      addVat ? purchaseRequestData.summary.dutyAndVat?.vat : null,
    ]);
  };

  return (
    <PurchaserPurchaseRequestDetailsInnerView
      purchaseRequestData={{
        ...purchaseRequestData,
        breakdown: {
          ...purchaseRequestData.summary,
          netSubtotal: calculateNetSubtotal(),
          totalPayable: calculateTotalPurchaseAmount(),
          lineItems: purchaseRequestData.summary.lineItems.map(li => ({
            product: li.product,
            paidUnits: li.paidUnits,
            freeUnits: li.freeUnits,
            priceDiscount: li.priceDiscount,
            unitPrice: li.salePricePerPaidUnit,
            subTotal: String(Number(li.paidUnits.value) * Number(li.salePricePerPaidUnit)),
          })),
        },
      }}
    />
  );
};

const sumBigDecimal = (bigDecimals: (BigDecimal | undefined | null)[]): BigDecimal => {
  const sum = bigDecimals
    .filter(v => v !== null && v !== undefined && !isNaN(Number(v)))
    .map(v => Number(v))
    .reduce((a, b) => a + b, 0);
  return String(sum);
};
