import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Box, Chip, ChipProps, Grid, IconProps, Stack, Typography, TypographyProps } from "@mui/material";
import React, { isValidElement } from "react";

type PortalPageContentHeaderVariant = "default" | "split";

interface PortalPageContentDefaultHeaderProps {
  variant?: PortalPageContentHeaderVariant;
  title: string | React.ReactNode;
  useConfirmationTitle?: boolean;
  subtitles?: SubtitleProps[];
}

type PortalPageContentSplitHeaderProps = {
  variant: "split";
  right: React.ReactNode;
} & Omit<PortalPageContentDefaultHeaderProps, "variant">;

export type PortalPageContentHeaderProps = PortalPageContentDefaultHeaderProps | PortalPageContentSplitHeaderProps;

function isSplit(props: PortalPageContentHeaderProps): props is PortalPageContentSplitHeaderProps {
  return props.variant === "split";
}

export const PortalPageContentHeader = (props: PortalPageContentHeaderProps) => {
  const { title, subtitles, useConfirmationTitle } = props;
  return (
    <Grid container alignItems="center">
      <Grid item xs={isSplit(props) ? 7 : 12}>
        <Stack spacing={2}>
          <Box>
            <Stack spacing={2} direction="row" alignItems="center">
              {useConfirmationTitle && <CheckCircleRoundedIcon color="success" sx={{ fontSize: 50 }} />}
              {typeof title === "string" ? <Typography variant="h4">{title}</Typography> : title}
            </Stack>
          </Box>

          {subtitles && subtitles.map((s, id) => <Subtitle key={s.text + id} {...s} />)}
        </Stack>
      </Grid>

      {isSplit(props) && (
        <Grid item xs={5} justifyContent={"flex-end"} textAlign={"right"}>
          {props.right}
        </Grid>
      )}
    </Grid>
  );
};

type SubtitleIcon = "receipt" | "date" | "warning";

const renderIcon = (icon: SubtitleIcon | React.FC<IconProps>) => {
  switch (icon) {
    case "receipt":
      return <ReceiptOutlinedIcon />;
    case "date":
      return <CalendarTodayOutlinedIcon />;
    case "warning":
      return <WarningAmberOutlinedIcon />;
    default:
      if (!isValidElement(icon)) {
        return React.createElement(icon);
      }
      return <>{icon}</>;
  }
};

type SubtitleComponentType =
  | ({
      type: "text";
    } & TypographyProps)
  | ({
      type: "chip";
    } & Omit<ChipProps, "label">);

export interface SubtitleProps {
  text: string;
  icon?: SubtitleIcon | React.FC<IconProps>;
  iconProps?: IconProps;

  // Optional. The default component type is "text"
  component?: SubtitleComponentType;
}

const Subtitle = ({ icon, text, iconProps, component = { type: "text" } }: SubtitleProps) => {
  const Icon = icon ? (
    React.cloneElement(renderIcon(icon), {
      sx: component.type === "text" ? { color: iconProps?.color ? undefined : "common.grey6" } : undefined,
      ...iconProps,
    })
  ) : (
    <></>
  );

  if (component.type === "text") {
    return <TextSubtitle icon={Icon} text={text} typographyProps={{ ...component }} />;
  } else {
    return <ChipSubtitle text={text} icon={Icon} chipProps={{ ...component }} />;
  }
};

interface TextSubtitleProps {
  icon?: React.ReactElement;
  text: string;
  typographyProps?: TypographyProps;
}
const TextSubtitle = ({ icon, text, typographyProps }: TextSubtitleProps) => {
  const subtitleProps: TypographyProps = {
    variant: "body1",
    color: "common.grey7",
    ...typographyProps,
  };

  return (
    <Stack spacing={2} direction={"row"} alignItems={"end"}>
      {icon}
      <Typography {...subtitleProps}>{text}</Typography>
    </Stack>
  );
};

interface ChipSubtitleProps {
  icon?: React.ReactElement;
  text: string;
  chipProps?: ChipProps;
}
const ChipSubtitle = ({ text, icon, chipProps }: ChipSubtitleProps) => {
  return (
    <Box>
      <Chip label={text} icon={icon} sx={{ typography: "body2Bold" }} {...chipProps} />
    </Box>
  );
};
