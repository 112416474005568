import { ApiServices } from "./api-services";
import { AppService } from "adl-gen/app-service";
import { RESOLVER } from "adl-gen/resolver";
import { AdminUiHttpService } from "./admin-ui/admin-ui-http-service";
import { HttpWithRetries } from "utils/hx/service/retrying-http";
import { BrowserHttp } from "utils/hx/service/browser-http";
import { AccountingService } from "./accounting-service";
import { HttpHeaders } from "utils/hx/service/http";

// Configure http to retry up to 8 times, with an initial delay of 100ms, with
// exponential increases.  The precise delays are randomised, but this will
// result in a typical max delay before failure of 25 seconds
const http = new HttpWithRetries(new BrowserHttp(), 8, 100);

function handleHttpServiceError() {
  // Note (Nicole): This function has been moved to error-boundary.tsx
  // to avoid multiple error handlers.
}
function noopHandleHeaders(_headers: HttpHeaders) {
  //Noop
}

export function makeApiServices(
  authToken?: string,
  handleHeaders?: (headers: HttpHeaders) => void,
): ApiServices {
  const app = new AppService(
    http,
    "/_a",
    RESOLVER,
    authToken,
    handleHttpServiceError,
    handleHeaders ?? noopHandleHeaders,
  );

  const adminUI = new AdminUiHttpService(
    http,
    "/_a",
    RESOLVER,
    authToken,
    handleHttpServiceError,
  );

  const accounting = new AccountingService(
    http,
    "/accounting",
    authToken,
    handleHttpServiceError,
  );

  return {
    app,
    adminUI,
    accounting,
  };
}
