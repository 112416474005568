import React, { useContext } from "react";
import { Snackbar, Typography, Paper, Stack, Backdrop, IconButton, Button } from "@mui/material";
import { AlertContext } from "./use-alert-context";
import { SeverityIcon } from "../../icons/severity-icon";
import CloseIcon from "@mui/icons-material/Close";

export const GlobalAlert = () => {
  const { isShown, title, body, severity, closeAlert, showDismissButton, noClose } = useContext(AlertContext);
  return (
    <>
      {isShown && (
        <Backdrop
          sx={{ zIndex: theme => theme.zIndex.drawer + 1, color: "common.white" }}
          open={true}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        // @override for anchorOrigin as vertical cannot be custom configured through props
        sx={{ top: "30% !important" }}
        open={isShown}
        autoHideDuration={showDismissButton || noClose ? undefined : 6000}
        onClose={async (_e, reason) => {
          if (showDismissButton && reason === "clickaway") {
            return;
          }
          await closeAlert();
        }}
        key={title}>
        <Paper sx={{ m: 4, p: 4, position: "relative" }}>
          {!noClose && (
            <IconButton
              onClick={closeAlert}
              size="small"
              sx={{
                color: "common.grey5",
                position: "absolute",
                right: theme => theme.spacing(3),
                top: theme => theme.spacing(3),
              }}>
              <CloseIcon />
            </IconButton>
          )}
          <Stack spacing={2} alignItems="center">
            <SeverityIcon severity={severity ?? "error"} />
            <Typography variant="h5" color="common.grey5">
              {title}
            </Typography>
            <Typography variant="body1" align="center" style={{ whiteSpace: "pre-wrap" }} color="common.grey7">
              {body}
            </Typography>
            {showDismissButton && <Button onClick={closeAlert}>GOT IT</Button>}
          </Stack>
        </Paper>
      </Snackbar>
    </>
  );
};
