import { useCallback } from "react";
import { PurchaseRequest } from "adl-gen/ferovinum/app/db";
import { DbKey } from "adl-gen/common/db";
import { AppService } from "adl-gen/app-service";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { LocalDate } from "adl-gen/common";

export const useMarkAsCollected = (appService: AppService, refresh: () => void) => {
  const [showAlert] = useAlert();

  return useCallback(
    async (purchaseRequestId: DbKey<PurchaseRequest>, collectedDate: LocalDate) => {
      const resp = await appService.markPurchaseRequestCollected({ purchaseRequestId, date: collectedDate });
      if (resp === "success") {
        void refresh();
      } else if (resp === "invalidState") {
        await showAlert({ title: "Error occurred", body: "Invalid state for purchase request" });
      }
    },
    [showAlert, refresh, appService],
  );
};

export const useMarkAsDelivered = (appService: AppService, refresh: () => void) => {
  const [showAlert] = useAlert();

  return useCallback(
    async (purchaseRequestId: DbKey<PurchaseRequest>, deliveredDate: LocalDate) => {
      const resp = await appService.markPurchaseRequestDelivered({ purchaseRequestId, date: deliveredDate });
      if (resp === "success") {
        void refresh();
      } else if (resp === "invalidState") {
        await showAlert({ title: "Error occurred", body: "Invalid state for purchase request" });
      }
    },
    [showAlert, refresh, appService],
  );
};
