import React, { useCallback } from "react";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { snTopLevelUnitType, TopLevelUnitType } from "adl-gen/ferovinum/app/db";
import { getFormLabelForUnionField } from "utils/adl-utils";

export interface StockTypeToggleProps {
  topLevelUnitType?: TopLevelUnitType;
  onChange: (stockType?: TopLevelUnitType) => void;
  availableTypes: Set<TopLevelUnitType>;
}

export const StockTypeToggle = ({ topLevelUnitType, onChange, availableTypes }: StockTypeToggleProps) => {
  const onChangeStockType = useCallback(
    (_: React.MouseEvent<HTMLElement>, value: TopLevelUnitType) => {
      if (value) {
        onChange(value);
      }
    },
    [onChange],
  );
  return (
    <Box>
      <ToggleButtonGroup value={topLevelUnitType} exclusive onChange={onChangeStockType}>
        {[...availableTypes].sort().map(type => (
          <ToggleButton key={type} value={type}>
            {getFormLabelForUnionField(snTopLevelUnitType, type)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};
