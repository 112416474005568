import { WfQuery } from "adl-gen/ferovinum/app/api";
import { WfState, WorkflowId } from "adl-gen/ferovinum/app/workflow";

export class WorkflowQueryBuilder {
  private wfStates: WfState[] | null = null;
  private ids: WorkflowId[] | null = null;
  private parentWfIds: WorkflowId[] | null = null;
  private wfType: WfState["kind"][] | null = null;
  private reference: string | null = null;

  public addStates(...states: WfState[]) {
    this.wfStates = [...(this.wfStates ?? []), ...states];
    return this;
  }

  public addTypes(...wfTypes: WfState["kind"][]) {
    this.wfType = [...(this.wfType ?? []), ...wfTypes];
    return this;
  }

  public addIds(...ids: WorkflowId[]) {
    if (!this.ids) this.ids = [];
    this.ids = [...this.ids, ...ids];
    return this;
  }

  public addParentWfIds(...ids: WorkflowId[]) {
    this.parentWfIds = [...(this.parentWfIds ?? []), ...ids];
    return this;
  }

  public addReference(reference: string) {
    this.reference = reference;
    return this;
  }

  public build(): WfQuery {
    // check that at least one of the fields is set
    if (!this.wfStates && !this.ids && !this.wfType && !this.reference && !this.parentWfIds) {
      throw new Error("At least one field must be set");
    }

    return {
      wfStates: this.wfStates,
      wfType: this.wfType,
      wfIds: this.ids,
      reference: this.reference,
      parentWfIds: this.parentWfIds,
    };
  }

  public static createTypeQuery(...types: WfState["kind"][]) {
    const builder = new WorkflowQueryBuilder().addTypes(...types);
    return builder.build();
  }

  public static createIdQuery(...ids: WorkflowId[]) {
    const builder = new WorkflowQueryBuilder().addIds(...ids);
    return builder.build();
  }

  public static createParentIdQuery(...parentWfIds: WorkflowId[]) {
    const builder = new WorkflowQueryBuilder().addParentWfIds(...parentWfIds);
    return builder.build();
  }
}
