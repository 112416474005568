import React from "react";
import { FlowStepper } from "components/library/widgets/flow-stepper";

export interface PurchaseRequestFlowStepperProps {
  activeStep: number;
  withProductionPlan?: boolean;
}

export const PurchaseRequestFlowStepper = ({ activeStep, withProductionPlan }: PurchaseRequestFlowStepperProps) => {
  const steps = ["Sale Details", "Delivery Details", "Confirmation"];
  if (withProductionPlan) {
    steps.splice(1, 0, "Production Plan");
  }

  return <FlowStepper steps={steps} activeStep={activeStep} />;
};
