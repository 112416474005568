import { AppService } from "adl-gen/app-service";
import { LocalDate, LocalTime } from "adl-gen/common";
import { DbKey, WithDbId } from "adl-gen/common/db";
import { GetProductResp, StorageLocationView, WfQuery } from "adl-gen/ferovinum/app/api";
import {
  DeliveryStatus,
  NumberOfUnits,
  OrganisationId,
  Product,
  StorageLocationId,
  UnitType,
} from "adl-gen/ferovinum/app/db";
import {
  StockMovementCollection,
  StockMovementDeliveryOption,
  StockMovementWfAction,
  TransportWfAction,
  WfData,
  Workflow,
  WorkflowId,
} from "adl-gen/ferovinum/app/workflow";
import { useCallback } from "react";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { assertNotUndefined } from "utils/hx/util/types";
import { LoadingValue } from "utils/utility-types";
import { WorkflowQueryBuilder } from "../WorkflowQueryBuilder";
import { formatDateToLocalDate, formatInstant } from "../date-utils";
import { numberOfUnitsForUnitType } from "../model-utils";
import { isNotNull } from "../ts-utils";

export interface CarrierDeliveryDetails {
  kind: "carrier";
  carrier: string;
  carrierContactName: string;
  carrierContactEmail: string;
  carrierContactNumber: string;
  deliveryDate: LocalDate;
  deliveryTimeEarliest: LocalTime;
  deliveryTimeLatest: LocalTime;
  deliveryInstructions: string | null;
}
export interface SelfDeliveryDetails extends StockMovementCollection {
  kind: "self";
}

export type StockMovementDeliveryDetails = CarrierDeliveryDetails | SelfDeliveryDetails;
export interface StockMovementProductData {
  productId: DbKey<Product>;
  product: Product;
  quantity: number;
  numberOfUnits: NumberOfUnits;
  unitType: UnitType;
}

export type StockMovementStates =
  | "Requested"
  | "Approved"
  | "Collected"
  | "Delivered"
  | "Delivery Confirmed"
  | "Rejected"
  | "Cancelled";
export interface StockMovementStatusStep {
  label: StockMovementStates;
  timestamp?: string;
}
export interface StockMovementProgress {
  nextStateIndex: number;
  activeState: StockMovementStates | undefined;
  states: StockMovementStatusStep[];
  deliveryStatuses: DeliveryStatus[];
}
export interface StockMovementSummary {
  reference?: string;
  workflows: {
    stockMovement?: WithDbId<Workflow>;
    transport?: WithDbId<Workflow>;
  };
  startingLocation: StorageLocationView;
  destinationLocation: StorageLocationView;
  deliveryDetails: StockMovementDeliveryDetails;
  earliestCollectionDate: LocalDate;
  productData: StockMovementProductData[];
  progress: StockMovementProgress;
}
interface FetchStockMovementDataProps {
  service: AppService;
  organisationId: OrganisationId;
  stockMovementWf?: WithDbId<Workflow>;
  transportWf?: WithDbId<Workflow>;
  startLocation: StorageLocationId;
  destinationLocation: StorageLocationId;
  deliveryOption: StockMovementDeliveryOption;
  products: { productId: DbKey<Product>; quantity: NumberOfUnits | number }[];
}
export type StockMovementData =
  | {
      kind: "withRaw";
      startLocation: StorageLocationId;
      destinationLocation: StorageLocationId;
      deliveryOption: StockMovementDeliveryOption;
      products: { productId: DbKey<Product>; quantity: number }[];
    }
  | {
      kind: "withId";
      stockMovementId: WorkflowId;
    };

type StockMovementSummaryProps = {
  service: AppService;
  organisationId: OrganisationId;
  stockMovementData: StockMovementData;
};

export function useStockMovementSummary({
  service,
  organisationId,
  stockMovementData,
}: StockMovementSummaryProps): LoadingValue<StockMovementSummary> {
  const fetch = useCallback(async (): Promise<StockMovementSummary> => {
    return await fetchStockMovementSummary(service, organisationId, stockMovementData);
  }, [organisationId, service, stockMovementData]);

  const [loadingDisplayData] = useLoadingDataState<StockMovementSummary>(fetch);

  return loadingDisplayData;
}

export async function fetchStockMovementSummary(
  service: AppService,
  organisationId: OrganisationId,
  stockMovementData: StockMovementData,
): Promise<StockMovementSummary> {
  let reqData: Omit<FetchStockMovementDataProps, "service" | "organisationId">;
  // without ref => the stock movement is not yet created
  if (stockMovementData.kind === "withRaw") {
    reqData = {
      ...stockMovementData,
    };
  } else {
    reqData = await fetchStockMovementWfData(service, organisationId, stockMovementData.stockMovementId);
  }
  reqData.stockMovementWf;

  return await fetchStockMovementSummaryInner({
    service,
    organisationId,
    ...reqData,
  });
}

const fetchStockMovementSummaryInner = async ({
  service,
  organisationId,
  stockMovementWf,
  transportWf,
  startLocation,
  destinationLocation,
  deliveryOption,
  products,
}: FetchStockMovementDataProps): Promise<StockMovementSummary> => {
  const [storageLocations, deliveryDetails, productData] = await Promise.all([
    getStorageLocationData({ service, organisationId, startLocation, destinationLocation }),
    getDeliveryOptionData({ service, deliveryOption }),
    getProductData({ service, organisationId: organisationId, products }),
  ]);

  // TODO: supply delivery date & statuses
  const progress = getStockMovementProgress({ stockMovementWf, transportWf });
  const createdAt = progress.states.find(step => step.label === "Requested")?.timestamp;
  return {
    reference: stockMovementWf?.value.reference,
    workflows: {
      stockMovement: stockMovementWf,
      transport: transportWf,
    },
    startingLocation: storageLocations.startingLocation,
    destinationLocation: storageLocations.destinationLocation,
    deliveryDetails: deliveryDetails,
    productData: productData,
    earliestCollectionDate: formatDateToLocalDate(createdAt ? new Date(createdAt) : new Date()),
    progress: progress,
  };
};

const fetchStockMovementWfData = async (
  service: AppService,
  organisationId: OrganisationId,
  stockMovementId: WorkflowId,
): Promise<Omit<FetchStockMovementDataProps, "service" | "organisationId">> => {
  const smWf = await fetchWf(
    service,
    organisationId,
    WorkflowQueryBuilder.createIdQuery(stockMovementId),
    "stock_movement",
  );

  if (smWf.value.data.kind !== "stock_movement") {
    throw new Error("Expected a stock movement request, but got " + smWf.value.data.kind);
  }

  const smReq = smWf.value.data.value;
  let transportWf;
  if (smWf) {
    transportWf = await fetchWf(
      service,
      organisationId,
      new WorkflowQueryBuilder().addParentWfIds(smWf.id).build(),
      "transport",
    );
  }

  return {
    stockMovementWf: smWf,
    transportWf: transportWf,
    startLocation: smReq.request.startingLocationId,
    destinationLocation: smReq.request.destinationLocationId,
    deliveryOption: smReq.request.stockMovementDeliveryOption,
    products: smReq.request.stockToMove,
  };
};

const fetchWf = async (
  service: AppService,
  organisationId: OrganisationId,
  params: WfQuery,
  kind: Extract<WfData["kind"], "transport" | "stock_movement">,
) => {
  const wf = await service
    .orgWfQuery({
      organisationId: organisationId,
      params: params,
    })
    .then(resp => {
      const first = resp[0];
      if (first && first.value.data.kind !== kind) {
        throw new Error("Expected a stock movement request, but got " + first.value.data.kind);
      }
      return first;
    });

  return wf;
};

const getStorageLocationData = async ({
  service,
  organisationId,
  startLocation,
  destinationLocation,
}: { organisationId: OrganisationId } & Pick<
  FetchStockMovementDataProps,
  "service" | "startLocation" | "destinationLocation"
>) => {
  const storageLocations = await service.getStorageLocations({
    orgId: organisationId,
    selector: {
      kind: "byIds",
      value: [startLocation, destinationLocation],
    },
  });
  return {
    startingLocation: assertNotUndefined(storageLocations.find(location => location.id === startLocation)),
    destinationLocation: assertNotUndefined(storageLocations.find(location => location.id === destinationLocation)),
  };
};

const getDeliveryOptionData = async ({
  service,
  deliveryOption,
}: Pick<FetchStockMovementDataProps, "service" | "deliveryOption">): Promise<StockMovementDeliveryDetails> => {
  if (deliveryOption.kind === "organisedDelivery") {
    const carrier = await service
      .getCarriers({ kind: "ids", value: [deliveryOption.value.carrier] })
      .then(resp => assertNotUndefined(resp[0]));
    return {
      kind: "carrier",
      carrier: "LCB",
      carrierContactName: carrier.value.contactName,
      carrierContactEmail: carrier.value.contactEmail,
      carrierContactNumber: carrier.value.contactMobile,
      deliveryDate: deliveryOption.value.deliveryDate,
      deliveryTimeEarliest: deliveryOption.value.deliveryTimeEarliest,
      deliveryTimeLatest: deliveryOption.value.deliveryTimeLatest,
      deliveryInstructions: deliveryOption.value.deliveryInstructions,
    };
  }
  return { kind: "self", ...deliveryOption.value };
};

const getProductData = async ({
  service,
  organisationId,
  products,
}: { organisationId: OrganisationId } & Pick<FetchStockMovementDataProps, "service" | "products">): Promise<
  StockMovementProductData[]
> => {
  return service
    .getProducts({
      organisationId,
      ids: products.map(product => product.productId),
    })
    .then((resp: GetProductResp) => {
      return products.map(productRow => {
        const product = assertNotUndefined(resp.find(product => product.id === productRow.productId));

        const quantity = typeof productRow.quantity === "number" ? productRow.quantity : productRow.quantity.value;

        const numberOfUnits =
          typeof productRow.quantity === "number"
            ? numberOfUnitsForUnitType(product.value.unitType, productRow.quantity)
            : productRow.quantity;

        return {
          productId: productRow.productId,
          product: product.value,
          quantity: quantity,
          numberOfUnits: numberOfUnits,
          unitType: product.value.unitType,
        };
      });
    });
};

const getStockMovementProgress = ({
  stockMovementWf,
  transportWf,
}: {
  stockMovementWf?: WithDbId<Workflow>;
  transportWf?: WithDbId<Workflow>;
}): StockMovementProgress => {
  const history: StockMovementStatusStep[] = [
    ...(stockMovementWf?.value.history ?? []),
    ...(transportWf?.value.history ?? []),
  ]
    .sort((a, b) => a.timestamp - b.timestamp)
    .map(h => {
      switch (h.action.kind) {
        case "stock_movement": {
          const stateMapping: { [key in StockMovementWfAction["kind"]]?: StockMovementStates } = {
            request: "Requested",
            approve: "Approved",
            reject: "Rejected",
            cancel: "Cancelled",
          };
          const label = stateMapping[h.action.value.kind];
          return label ? { label, timestamp: formatInstant(h.timestamp) } : null;
        }
        case "transport": {
          const stateMapping: { [k in TransportWfAction["kind"]]?: StockMovementStates } = {
            collect: "Collected",
            deliver: "Delivered",
            confirm_delivery: "Delivery Confirmed",
          };
          const label = stateMapping[h.action.value.kind];
          return label ? { label, timestamp: formatInstant(h.timestamp) } : null;
        }
        default:
          return null;
      }
    })
    .filter(isNotNull);

  const currentStep = history[history.length - 1]?.label;
  const stepSequences: StockMovementStates[][] = [
    ["Requested", "Approved", "Collected", "Delivered", "Delivery Confirmed"],
    ["Rejected"],
    ["Cancelled"],
  ];
  const currentSequence = stepSequences.find(seq => seq.includes(currentStep));
  const remainingSteps = currentSequence?.slice(currentSequence.indexOf(currentStep) + 1) ?? [];

  // All historic states + remaining steps
  const states = [...history, ...remainingSteps.map(label => ({ label }))];

  // Delivery status stored on the transport workflow
  const deliveryStatuses =
    transportWf?.value.data.kind === "transport" ? transportWf.value.data.value.deliveryStatuses : [];

  return {
    activeState: currentStep,
    nextStateIndex: history.length,
    states,
    deliveryStatuses,
  };
};
