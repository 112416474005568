import React from "react";
import { AlertColor } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

/** Displayed the icon that corresponds to the severity level provided */
export function SeverityIcon({ severity }: { severity: AlertColor }) {
  const iconProps = { color: severity, sx: { fontSize: 50 } };
  switch (severity) {
    case "success":
      return <CheckCircleOutlinedIcon {...iconProps} />;
    case "error":
      return <ErrorOutlineOutlinedIcon {...iconProps} />;
    case "info":
      return <InfoOutlinedIcon {...iconProps} />;
    case "warning":
      return <WarningAmberOutlinedIcon {...iconProps} />;
  }
}
