import React from "react";
import { FlowStepper } from "components/library/widgets/flow-stepper";

export interface StockAdjustmentFlowStepperProps {
  activeStep: number;
}

export const StockAdjustmentFlowStepper = ({ activeStep }: StockAdjustmentFlowStepperProps) => {
  const steps = ["Input details", "Settings", "Confirmation"];

  return <FlowStepper steps={steps} activeStep={activeStep} />;
};
