import React, { useState, useCallback, useEffect } from "react";
import { assertNotUndefined } from "utils/hx/util/types";
import { StorageLocation, Product, FinishingService } from "adl-gen/ferovinum/app/db";
import { assertValueLoaded, isLoaded } from "utils/utility-types";
import { AppService } from "adl-gen/app-service";
import { OrganisationProductSelectionPageView } from "./organisation-product-selection-page-view";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { Loader } from "components/widgets/loader/loader";
import { useHistory } from "react-router-dom";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { AppRoutes } from "../../../../../app/app-routes";
import { ProductionOrderFlowState } from "../organisation-production-order-setup/organisation-production-order-setup-page";
import { DbKey } from "adl-gen/common/db";
import { WithDbId } from "adl-gen/common/db";
import { isBottle } from "utils/model-utils";
import { HttpServiceError } from "adl-service/http-service-error";
import { makeOrganisationStockReq } from "adl-gen/ferovinum/app/api";

//Note(Berto): Production orders currently only support bottles
export const OrganisationProductSelectionPage = () => {
  const history = useHistory();
  const service: AppService = useAppService();
  const selectedOrgId = assertNotUndefined(useSelectedOrgId());
  const [showAlert] = useAlert();

  const [activeStorageLocation, setActiveStorageLocation] = useState<DbKey<StorageLocation>>();

  const loadInventory = useCallback(async () => {
    const req = makeOrganisationStockReq({ organisationId: selectedOrgId });
    return (await service.getOrganisationInventory(req)).items.filter(
      p =>
        p.storageLocStocks.length > 0 &&
        p.storageLocStocks.some(s => s.totalQuantityAvailable.value > 0) &&
        isBottle(p.unitType),
    );
  }, [service, selectedOrgId]);
  const [loadingProductInventory] = useLoadingDataState(loadInventory);

  const loadProductToFinishingServicesMap = useCallback(async () => {
    if (activeStorageLocation) {
      const response = await service.getProductToFinishingServicesMap({
        organisationId: selectedOrgId,
        storageLocationId: activeStorageLocation,
      });
      return new Map(response.map(r => [r.key, r.value]));
    } else {
      return new Map<string, WithDbId<FinishingService>[]>();
    }
  }, [activeStorageLocation, selectedOrgId, service]);

  const [loadingProductToFinishingServicesMap] = useLoadingDataState(loadProductToFinishingServicesMap);

  const onSelectSourceProduct = useCallback(
    async (selectedProduct: DbKey<Product>) => {
      try {
        if (activeStorageLocation) {
          const productInventory = assertNotUndefined(
            [...assertValueLoaded(loadingProductInventory)].find(pl => pl.productId === selectedProduct),
          );
          const quantityAvailable = assertNotUndefined(
            productInventory.storageLocStocks.find(sl => sl.storageLocationId === activeStorageLocation)
              ?.totalQuantityAvailable,
          );

          const state: ProductionOrderFlowState = {
            sourceProduct: {
              id: selectedProduct,
              name: productInventory.productName,
              code: productInventory.productCode,
              productDate: productInventory.productDate,
              producerName: productInventory.producerName,
              unitType: productInventory.unitType,
              vesselSize: productInventory.vesselSize,
            },
            quantityAvailable,
            storageLocationId: activeStorageLocation,
          };
          history.push(AppRoutes.OrgProductionOrderSetup, state);
          return true;
        } else {
          return false;
        }
      } catch (e: unknown) {
        const body = e instanceof HttpServiceError ? e.publicMessage : String(e);
        await showAlert({ title: "Error", body });
        return false;
      }
    },
    [activeStorageLocation, history, loadingProductInventory, showAlert],
  );

  useEffect(() => {
    if (!activeStorageLocation && isLoaded(loadingProductInventory) && loadingProductInventory.value.length > 0) {
      setActiveStorageLocation(loadingProductInventory.value[0].storageLocStocks[0].storageLocationId);
    }
  }, [activeStorageLocation, loadingProductInventory]);

  return (
    <Loader loadingStates={[loadingProductInventory, loadingProductToFinishingServicesMap]} fullScreen>
      {isLoaded(loadingProductInventory) && isLoaded(loadingProductToFinishingServicesMap) && (
        <OrganisationProductSelectionPageView
          activeStorageLocId={activeStorageLocation}
          onChangeActiveStorageLocation={setActiveStorageLocation}
          bottles={loadingProductInventory.value}
          onSelectProduct={onSelectSourceProduct}
          productToFinishingServicesMap={loadingProductToFinishingServicesMap.value}
        />
      )}
    </Loader>
  );
};
