import { Button, SnackbarContent, Stack, TextField, Typography } from "@mui/material";
import React from "react";

import { FEROVINUM_CONTACT_URL } from "components/assets/url";
import { FerovinumLogo } from "components/logos/logos";
import { Link } from "components/widgets/link/link";
import { AppRoutes } from "../../../app/app-routes";
import { LoginState } from "../../../app/identity-state";

export type LoginPageProps = Pick<LoginState, "loginError" | "onLogin">;

/**
 * Landing page of the app.
 */
export const LoginPage = (props: LoginPageProps) => {
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  /** Callback to log in */
  const onLogin = async () => {
    await props.onLogin({
      email,
      password,
    });
  };

  /** Render the page */
  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <FerovinumLogo color="purple" />
        <Typography variant="h4">Welcome back</Typography>
      </Stack>
      <Stack spacing={2}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={e => setPassword(e.target.value)}
        />
        {props.loginError && (
          <SnackbarContent
            message={props.loginError}
            sx={{
              marginTop: theme => theme.spacing(1),
              marginBottom: theme => theme.spacing(1),
              backgroundColor: theme => theme.palette.error.light,
            }}
          />
        )}
      </Stack>
      <Stack spacing={2}>
        <Button variant="contained" color="primary" fullWidth onClick={onLogin}>
          Sign-In
        </Button>
        <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={1}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Typography variant="body2">Not a member?</Typography>
            <Link variant="big" href={FEROVINUM_CONTACT_URL} target={"_blank"}>
              Contact us
            </Link>
          </Stack>
          <Link variant="big" href={AppRoutes.ForgotPassword}>
            Forgot Password
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};
