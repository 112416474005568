import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/** Helper component that auto-scroll to the top on re-render */
// Note: https://v5.reactrouter.com/web/guides/scroll-restoration
export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
