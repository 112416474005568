import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Currency } from "adl-gen/ferovinum/app/db";
import { PurchaseRequestLineItem, PurchaseRequestRejectedLineItem } from "adl-gen/ferovinum/app/nompurchaser";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import React from "react";
import { getVesselCapacity } from "utils/model-utils";
import { ColGroup } from "../../colgroup";
import { OrganisationProductSummary } from "../../organisation-product-summary/organisation-product-summary";

export interface OrganisationPurchaseRequestSummaryTableProps {
  purchaserCurrency: Currency;
  settlementCurrency: Currency;
  products: PurchaseRequestLineItem[] | PurchaseRequestRejectedLineItem[];
  isRejectedOrExpired?: boolean;
}

export const OrganisationPurchaseRequestSummaryTable = ({
  isRejectedOrExpired = false,
  products,
  purchaserCurrency,
  settlementCurrency,
}: OrganisationPurchaseRequestSummaryTableProps) => {
  const hasFreeUnit = products.some(product => product.freeUnits != null);
  const hasDiscount = products.some(product => product.priceDiscount != null);

  return (
    <Table sx={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
      <ColGroup widths={[...[50, 20, 20, 20], ...(!isRejectedOrExpired ? [20] : []), ...(hasDiscount ? [20] : [])]} />
      <TableHead>
        <TableRow>
          <TableCell sx={{ borderTopLeftRadius: 8 }}>Product</TableCell>
          <SummaryTableCell>Quantity</SummaryTableCell>
          {!isRejectedOrExpired && (
            <SummaryTableCell>
              Average Net Payable
              <br />
              Per Unit {hasFreeUnit ? " (Incl Free)" : ""}
            </SummaryTableCell>
          )}
          <SummaryTableCell>
            Sale Price Per{hasFreeUnit ? " Paid " : " "}Unit
            <br />
            (Ex Duty, Ex VAT)
          </SummaryTableCell>
          {hasDiscount && <SummaryTableCell>Price Discount Included</SummaryTableCell>}
          <SummaryTableCell textAlign="right" paddingRight sx={{ borderTopRightRadius: 8 }}>
            Subtotal
          </SummaryTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map((productData, idx) => (
          <PurchaseRequestSummaryTableRow
            key={productData.product.id + idx}
            productData={productData}
            isRejectedOrExpired={isRejectedOrExpired}
            purchaserCurrency={purchaserCurrency}
            settlementCurrency={settlementCurrency}
            hasDiscount={hasDiscount}
          />
        ))}
      </TableBody>
    </Table>
  );
};

interface PurchaseRequestSummaryTableRowProps {
  productData: PurchaseRequestLineItem | PurchaseRequestRejectedLineItem;
  isRejectedOrExpired: boolean;
  purchaserCurrency: Currency;
  settlementCurrency: Currency;
  hasDiscount: boolean;
}
const PurchaseRequestSummaryTableRow = ({
  productData,
  isRejectedOrExpired,
  purchaserCurrency,
  settlementCurrency,
  hasDiscount,
}: PurchaseRequestSummaryTableRowProps) => {
  const { product, paidUnits, cleanPricePerPaidUnit, priceDiscount, freeUnits } = productData;
  const averageNetPayablePerUnit =
    "averageNetPayablePerUnit" in productData ? productData.averageNetPayablePerUnit : undefined;

  return (
    <TableRow>
      <TableCell>
        <OrganisationProductSummary
          {...product.value}
          productId={product.id}
          vesselCapacity={getVesselCapacity(product.value.vesselSize)}
        />
      </TableCell>
      <SummaryTableCell>
        <Typography>{paidUnits.value}</Typography>
        {freeUnits && <Typography variant="caption">+{freeUnits.value} free</Typography>}
      </SummaryTableCell>
      {!isRejectedOrExpired && (
        <SummaryTableCell>
          <CurrencyRenderer maximumFractionDigits={4} currency={settlementCurrency} value={averageNetPayablePerUnit} />
        </SummaryTableCell>
      )}
      <SummaryTableCell>
        <CurrencyRenderer maximumFractionDigits={4} currency={purchaserCurrency} value={cleanPricePerPaidUnit} />
      </SummaryTableCell>
      {hasDiscount && (
        <SummaryTableCell>
          {priceDiscount ? <CurrencyRenderer value={priceDiscount} currency={purchaserCurrency} /> : "-"}
        </SummaryTableCell>
      )}
      <SummaryTableCell textAlign="right" paddingRight>
        <CurrencyRenderer
          maximumFractionDigits={4}
          currency={purchaserCurrency}
          value={productData.paidUnits.value * Number(productData.cleanPricePerPaidUnit)}
        />
      </SummaryTableCell>
    </TableRow>
  );
};

export const SummaryTableCell = styled(TableCell, {
  shouldForwardProp: prop => prop !== "paddingRight" && prop != "textAlign",
})<{
  paddingRight?: boolean;
  textAlign?: "left" | "center" | "right";
}>(({ theme, paddingRight, textAlign }) => ({
  textAlign: textAlign ?? "center",
  paddingRight: paddingRight ? theme.spacing(4) : 0,
}));
