import React from "react";
import { Typography } from "@mui/material";

export interface Address {
  streetName?: string;
  addressLine1: string;
  addressLine2: string;
  town: string;
  postCode: string;
  country?: string;
}

export const AddressTypography = ({ streetName, addressLine1, addressLine2, town, postCode, country }: Address) => {
  return (
    <>
      {streetName && <Typography>{streetName}</Typography>}
      <Typography>{addressLine1}</Typography>
      <Typography>
        {addressLine2 && addressLine2 + ", "}
        {town}
      </Typography>
      <Typography>{postCode}</Typography>
      {country && <Typography>{country}</Typography>}
    </>
  );
};
