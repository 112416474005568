import React, { useCallback, useEffect, useRef, useState } from "react";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { useConfirmationDialog } from "../../../context/global-dialog/use-dialog";

// Note: Should probably extend LoadingButtonProps but it currently kills typescript
/// https://github.com/mui/material-ui/issues/30038
export interface LoadingActionButtonProps {
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  disabled?: boolean;
  type?: LoadingButtonProps["type"];
  variant?: LoadingButtonProps["variant"];
  color?: LoadingButtonProps["color"];
  loading?: LoadingButtonProps["loading"];
  children?: React.ReactNode;
  startIcon?: LoadingButtonProps["startIcon"];
}

export const LoadingActionButton: React.FC<LoadingActionButtonProps> = ({
  children,
  onClick,
  disabled,
  type,
  variant = "contained",
  loading,
  color,
  startIcon,
}) => {
  const [innerLoading, setInnerLoading] = useState<boolean>(false);
  const isMounted = useRef<boolean>(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleButtonClick = useCallback(
    async ev => {
      try {
        setInnerLoading(true);
        if (onClick) {
          await onClick(ev);
        }
      } finally {
        if (isMounted.current) {
          setInnerLoading(false);
        }
      }
    },
    [setInnerLoading, onClick],
  );

  return (
    <LoadingButton
      onClick={handleButtonClick}
      loading={innerLoading || loading}
      disabled={disabled}
      type={type}
      variant={variant}
      color={color}
      startIcon={startIcon}>
      {children}
    </LoadingButton>
  );
};

type LoadingActionButtonWithConfirmationProps = Omit<LoadingActionButtonProps, "onClick"> & {
  confirmationTitle: string;
  onConfirm: () => Promise<void>;
};
export const LoadingActionButtonWithConfirmation: React.FC<LoadingActionButtonWithConfirmationProps> = props => {
  const { showConfirmationDialog } = useConfirmationDialog();

  return (
    <LoadingActionButton
      {...props}
      onClick={async () => {
        await showConfirmationDialog({
          title: props.confirmationTitle,
          confirmAction: {
            onClick: props.onConfirm,
          },
        });
      }}>
      {props.children}
    </LoadingActionButton>
  );
};
