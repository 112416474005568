import React from "react";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { boolean, object, string } from "yup";
import { TextArea } from "components/widgets/inputs/text-area/text-area";
import { StockAdjustment } from "adl-gen/ferovinum/app/api";
import { StockAdjustmentFlowStepper } from "../../../../widgets/flow-stepper/stock-adjustment-flow-stepper";

const SETUP_STEPS = [
  "A. What caused these stock adjustments?",
  "B. Enter your reference number for this stock adjustment. If you don’t have one, Ferovinum will assign a random unique identifier.",
  "C. Will you be reimbursing the lost stock?",
];
export interface StockAdjustmentSettingsFormValues {
  reason?: string;
  referenceNumber?: string;
  // Because material ui checkboxed always return a string instead of a boolean
  reimbursed?: boolean;
}
export interface StorageLocStockAdjustmentSettingsPageViewProps {
  variant: StockAdjustment["kind"];
  initialValues: StockAdjustmentSettingsFormValues;
  onNext: (values: StockAdjustmentSettingsFormValues) => Promise<void>;
  onBack: () => void;
}
export const StorageLocStockAdjustmentSettingsPageView = ({
  variant,
  initialValues,
  onNext,
  onBack,
}: StorageLocStockAdjustmentSettingsPageViewProps) => {
  const form = useFormik<StockAdjustmentSettingsFormValues>({
    initialValues,
    validationSchema: validationSchema(variant),
    validateOnMount: true,
    onSubmit: onNext,
  });
  return (
    <PortalPageContent header={<StockAdjustmentSettingsHeader />}>
      <Stack spacing={4}>
        <SettingsStep question={SETUP_STEPS[0]}>
          <TextArea
            fullWidth
            sx={{ maxWidth: "400px" }}
            name="reason"
            maxCharacters={120}
            rows={5}
            value={form.values.reason || ""}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            label="Reason for stock adjustment"
            required
            error={form.touched.reason && Boolean(form.errors.reason)}
            helperText={form.touched.reason && form.errors.reason}
          />
        </SettingsStep>
        <SettingsStep question={SETUP_STEPS[1]}>
          <TextField
            fullWidth
            sx={{ maxWidth: "400px" }}
            label="Reference Number"
            name="referenceNumber"
            value={form.values.referenceNumber || ""}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </SettingsStep>
        {variant === "negativeStockAdjustment" && (
          <SettingsStep question={SETUP_STEPS[2]}>
            <FormControl>
              <RadioGroup name="reimbursed" value={form.values.reimbursed} onChange={form.handleChange}>
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </SettingsStep>
        )}
        <Stack spacing={2} direction="row">
          <Button variant="outlined" onClick={onBack}>
            Back
          </Button>
          <Button onClick={form.submitForm} disabled={!form.isValid}>
            Next
          </Button>
        </Stack>
      </Stack>
    </PortalPageContent>
  );
};

const SettingsStep = ({ question, children }: { question: string; children: React.ReactNode }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="body1" color="common.grey7">
        {question}
      </Typography>
      {children}
    </Stack>
  );
};

const StockAdjustmentSettingsHeader = () => {
  return (
    <PortalPageContentHeader variant="split" title="Settings" right={<StockAdjustmentFlowStepper activeStep={1} />} />
  );
};

const validationSchema = (variant: StockAdjustment["kind"]) => {
  const reimbursedSchema =
    variant === "negativeStockAdjustment" ? boolean().required("Required") : boolean().optional();

  return object({
    reason: string().required("Required"),
    referenceNumber: string(),
    reimbursed: reimbursedSchema,
  });
};
