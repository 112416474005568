import { assertNotUndefined } from "utils/hx/util/types";
import React, { useCallback, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { AppService } from "adl-gen/app-service";
import { ExistingProductLineItem, NewProductLineItem, NewStockNewDealReq } from "adl-gen/ferovinum/app/api";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { OrganisationNewDealRequestConfirmationPageView } from "./organisation-new-deal-request-confirmation-page-view";
import { AppRoutes } from "../../../../../app/app-routes";
import {
  isNewSupplier,
  ProductLineItemData,
  NewDealRequestFlowState,
} from "../organisation-create-new-deal-request/organisation-create-new-deal-request-page";
import { DbKey } from "adl-gen/common/db";
import { NewDealRequestType, Organisation } from "adl-gen/ferovinum/app/db";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { Loader } from "components/widgets/loader/loader";
import { isLoaded } from "utils/utility-types";
import { MinimalProductRequest } from "../../../../widgets/new-deal-requests/organisation-new-deal-request-net-subtotal-table/organisation-new-deal-request-net-subtotal-table";
import { NewDealRequestViewNetSubtotal, NewDealRequestViewTotalDiscount } from "adl-gen/ferovinum/app/procurement";
import { numberOfUnitsForUnitType } from "utils/model-utils";
import { isNewProduct } from "../organisation-new-deal-request-setup/organisation-new-deal-request-setup-page";
import { HttpServiceError } from "adl-service/http-service-error";

function getNewDealReqProductLineItems(products: ProductLineItemData[]) {
  const existingProducts: ExistingProductLineItem[] = [];
  const newProducts: NewProductLineItem[] = [];

  products.forEach(p => {
    const unitType = isNewProduct(p) ? p.value.unitType : p.value.value.unitType;
    if (isNewProduct(p)) {
      newProducts.push({
        ...p.value,
        numberOfUnits: p.numberOfUnits ?? numberOfUnitsForUnitType(unitType, 0),
        numberOfFreeUnits: p.numberOfFreeUnits ?? {
          kind: p.numberOfUnits ? p.numberOfUnits.kind : numberOfUnitsForUnitType(unitType, 0).kind,
          value: 0,
        },
        individualDiscountPct:
          p.individualDiscountPct != null || p.individualDiscountPct === "" ? p.individualDiscountPct.toString() : "0",
        clientSpecifiedPurchasePrice: p.clientSpecifiedPurchasePrice?.toString() ?? "0.00",
        purchasePrice: p.purchasePrice?.toString() ?? "0.00",
      });
    } else {
      existingProducts.push({
        productId: p.value.id,
        numberOfUnits: p.numberOfUnits ?? numberOfUnitsForUnitType(unitType, 0),
        numberOfFreeUnits: p.numberOfFreeUnits ?? {
          kind: p.numberOfUnits ? p.numberOfUnits.kind : numberOfUnitsForUnitType(unitType, 0).kind,
          value: 0,
        },
        individualDiscountPct:
          p.individualDiscountPct != null || p.individualDiscountPct === "" ? p.individualDiscountPct.toString() : "0",
        clientSpecifiedPurchasePrice: p.clientSpecifiedPurchasePrice?.toString() ?? "0.00",
        purchasePrice: p.purchasePrice?.toString() ?? "0",
      });
    }
  });

  return { existingProducts, newProducts };
}

function getNewStockNewDealReq(
  organisationId: DbKey<Organisation>,
  state: NewDealRequestFlowState,
): NewStockNewDealReq {
  const { existingProducts: existingProductItems, newProducts: newProductLineItems } = getNewDealReqProductLineItems(
    state.selectedProducts,
  );

  if (
    state.variant === "newStock" &&
    state.carrier !== undefined &&
    state.supplier !== undefined &&
    state.storageLocation !== undefined &&
    state.discount !== undefined
  ) {
    return {
      ndrToUpdateId: state.existingNdrId ?? null,
      carrierId: state.carrier.id,
      destinationStorageLocationId: state.storageLocation.id,
      supplierId: isNewSupplier(state.supplier) ? null : state.supplier.id,
      organisationId,
      existingProductItems,
      newProductLineItems,
      purchaseCurrency: state.currency,
      newSupplier: isNewSupplier(state.supplier) ? state.supplier : null,
      incoterms: state.incoterms ?? null,
      discountPct: state.discount === "" || state.discount === undefined ? "0" : state.discount,
    };
  }
  throw new Error("Invalid state: new stock deal request");
}

export interface PreviewNewDealRequest {
  lineItems: MinimalProductRequest[];
  netSubtotal: NewDealRequestViewNetSubtotal;
  discount: NewDealRequestViewTotalDiscount;
}
export const OrganisationNewDealRequestConfirmationPage = () => {
  const location = useLocation();
  const history = useHistory();
  const purchaseOrderFlowState = location.state as NewDealRequestFlowState | undefined;
  const organisationId = assertNotUndefined(useSelectedOrgId());
  const service: AppService = useAppService();
  const selectedOrgId = assertNotUndefined(useSelectedOrgId());
  const [showAlert] = useAlert();
  const [confirmedDealNumber, setConfirmedDealNumber] = useState<string>();

  const previewNewDealRequest = useCallback(async (): Promise<PreviewNewDealRequest> => {
    const products = assertNotUndefined(purchaseOrderFlowState?.selectedProducts);
    const purchaseCurrency = assertNotUndefined(purchaseOrderFlowState?.currency);
    const newDealRequestType: NewDealRequestType = assertNotUndefined(purchaseOrderFlowState?.variant);
    const discount =
      purchaseOrderFlowState?.discount && purchaseOrderFlowState?.discount != ""
        ? purchaseOrderFlowState?.discount.toString()
        : "0";
    const resp = await service.organisationPreviewNewDealRequest({
      purchaseCurrency,
      newDealRequestType,
      organisationId,
      discount,
      lineItems: products.map(p => {
        const unitType = isNewProduct(p) ? p.value.unitType : p.value.value.unitType;
        return {
          code: p.productCode,
          numberOfUnits: p.numberOfUnits ?? numberOfUnitsForUnitType(unitType, 0),
          numberOfFreeUnits: p.numberOfFreeUnits ?? {
            kind: p.numberOfUnits ? p.numberOfUnits.kind : numberOfUnitsForUnitType(unitType, 0).kind,
            value: 0,
          },
          clientSpecifiedPurchasePrice: p.clientSpecifiedPurchasePrice
            ? p.clientSpecifiedPurchasePrice.toString()
            : "0",
          purchasePrice: p.purchasePrice ? p.purchasePrice.toString() : "0",
          unitType: p.kind === "new" ? p.value.unitType : p.value.value.unitType,
          // Ensure that individualDiscount is 0 if it's null or an empty string
          individualDiscountPct:
            p.individualDiscountPct || p.individualDiscountPct === "" ? p.individualDiscountPct.toString() : "0.00",
        };
      }),
    });
    return {
      lineItems: resp.lineItems.map(lineItem => {
        const product = assertNotUndefined(products.find(p => lineItem.code === p.productCode));
        product.purchasePrice = Number(lineItem.purchasePrice);
        return {
          ...lineItem,
          individualDiscountPct: lineItem.individualDiscountPct ? lineItem.individualDiscountPct : "0.00",
          purchasePrice: lineItem.purchasePrice ? lineItem.purchasePrice : "0.00",
          product: product.value,
        };
      }),
      netSubtotal: resp.netSubtotal,
      discount: resp.discount,
    };
  }, [
    organisationId,
    purchaseOrderFlowState?.currency,
    purchaseOrderFlowState?.selectedProducts,
    purchaseOrderFlowState?.variant,
    purchaseOrderFlowState?.discount,
    service,
  ]);

  const [loadingNewDealRequestPreview] = useLoadingDataState(previewNewDealRequest);

  const createPurchaseOrder = useCallback(async () => {
    try {
      const { variant, selectedProducts, storageLocation } = assertNotUndefined(purchaseOrderFlowState);

      const products = getNewDealReqProductLineItems(selectedProducts);

      const createdNewDealRequestId =
        variant === "newStock"
          ? await service.createOrUpdateNewStockNewDealRequest(
              getNewStockNewDealReq(selectedOrgId, assertNotUndefined(purchaseOrderFlowState)),
            )
          : await service.createOrUpdateExistingStockNewDealRequest({
              ndrToUpdateId: purchaseOrderFlowState?.existingNdrId ?? null,
              organisationId: selectedOrgId,
              destinationStorageLocationId: assertNotUndefined(storageLocation).id,
              existingProductItems: products.existingProducts,
              newProductLineItems: products.newProducts,
            });
      const createdNewDealRequest = await service.getNewDealRequestDetails({
        newDealsRequestId: createdNewDealRequestId,
      });
      setConfirmedDealNumber(createdNewDealRequest.newDealRequest.value.dealNumber);

      // Note: if the users manually reloads the page it will take them to the correct sale order page
      // (use native window.location to avoid react-router redirect)
      window.history.replaceState(
        null,
        "Purchase order details",
        `${AppRoutes.OrganisationNewDealRequestDetails}/${encodeURIComponent(createdNewDealRequest.newDealRequest.id)}`,
      );
    } catch (e: unknown) {
      const body = e instanceof HttpServiceError ? e.publicMessage : String(e);
      await showAlert({ title: "Error creating a purchase order, please try again.", body });
    }
  }, [purchaseOrderFlowState, selectedOrgId, service, showAlert]);

  const navigateToPreviousStep = useCallback(() => {
    const variant = assertNotUndefined(purchaseOrderFlowState).variant;
    history.push(
      variant === "newStock" ? AppRoutes.NewDealRequestDeliveryOptions : AppRoutes.NewDealRequestSetup,
      purchaseOrderFlowState,
    );
  }, [purchaseOrderFlowState, history]);

  const navigateToCreatePurchaseOrder = useCallback(
    ({ withState }: { withState?: boolean }) => {
      history.push(AppRoutes.CreateNewDealRequest, withState ? purchaseOrderFlowState : undefined);
    },
    [history, purchaseOrderFlowState],
  );

  const navigateToOrderSetup = useCallback(() => {
    history.push(AppRoutes.NewDealRequestSetup, purchaseOrderFlowState);
  }, [history, purchaseOrderFlowState]);

  const navigateToDeliveryOptions = useCallback(() => {
    history.push(AppRoutes.NewDealRequestDeliveryOptions, purchaseOrderFlowState);
  }, [history, purchaseOrderFlowState]);

  if (purchaseOrderFlowState === undefined) {
    return <Redirect to={AppRoutes.Index} />;
  } else {
    return (
      <Loader loadingStates={[loadingNewDealRequestPreview]}>
        {isLoaded(loadingNewDealRequestPreview) && (
          <OrganisationNewDealRequestConfirmationPageView
            isUpdate={purchaseOrderFlowState.existingNdrId !== undefined}
            purchaseOrderFlowState={assertNotUndefined(purchaseOrderFlowState)}
            navigateToPreviousStep={navigateToPreviousStep}
            navigateToCreatePurchaseOrder={() => navigateToCreatePurchaseOrder({})}
            onConfirm={createPurchaseOrder}
            confirmedDealNumber={confirmedDealNumber}
            onChangeSupplier={() => navigateToCreatePurchaseOrder({ withState: true })}
            onChangeCarrierOrIncoterms={navigateToDeliveryOptions}
            onChangeStorageLocation={
              purchaseOrderFlowState.variant === "newStock"
                ? navigateToDeliveryOptions
                : () => navigateToCreatePurchaseOrder({ withState: true })
            }
            onChangeProductsSelection={navigateToOrderSetup}
            previewNewDealRequest={loadingNewDealRequestPreview.value}
          />
        )}
      </Loader>
    );
  }
};
