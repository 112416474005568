import React, { useMemo, useReducer, useState } from "react";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PurchaseRequestFlowStepper } from "../../../../widgets/flow-stepper/purchase-request-flow-stepper";
import { FinishedProductId, ProductionOptionsForFinishedProduct } from "adl-gen/ferovinum/app/api";
import { FinishingProductMappingId, NumberOfUnits, Product } from "adl-gen/ferovinum/app/db";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grow,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DbKey, WithDbId } from "adl-gen/common/db";
import { isPositiveNumber, isValidNumber } from "utils/ts-utils";
import { OrganisationProductSummary } from "../../../../widgets/organisation-product-summary/organisation-product-summary";
import { getVesselCapacity, unitsLabelForUnitType } from "utils/model-utils";
import { ConfirmationDialog } from "components/widgets/confirmation-dialog/confirmation-dialog";
import { UnitsInput } from "components/widgets/inputs/units-input/units-input";
import { CurrencyInput } from "components/widgets/inputs/currency-input/currency-input";
import { useSettlementCurrency } from "../../../../layouts/portal-page-layout/portal-page";
import { titleCase } from "utils/conversion-utils";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { array, boolean, number, object, ref, string } from "yup";
import { assertNotUndefined } from "utils/hx/util/types";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { OrganisationPurchaseRequestProductionPlanCard } from "../../../../widgets/purchase-requests/organisation-purchase-request-production-plan-card/organisation-purchase-request-production-plan-card";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";

interface FinishedProductRequest {
  product: WithDbId<Product>;
  targetQuantity: NumberOfUnits;
}
interface ProductionPlanTask {
  finishingProductMappingId: string;
  serviceName: string;
  pricePerUnit: number;
  setupFee: number;
  quantity: number;
}
export interface ProductionPlanForFinishedProduct {
  finishedProduct: FinishedProductRequest;
  productionOrders: {
    sourceProduct: SourceProduct;
    tasks: ProductionPlanTask[];
  }[];
}
export type ProductionPlanPurchaseRequest = ProductionPlanForFinishedProduct[];
type SourceProduct = WithDbId<Product>;
type ProductionPlanAction = {
  kind: "edit";
  finishedProductId: FinishedProductId;
  plan: EditTaskFormValues["values"];
};
function productionPlanAction(
  state: ProductionPlanPurchaseRequest,
  action: ProductionPlanAction,
): ProductionPlanPurchaseRequest {
  switch (action.kind) {
    case "edit":
      const { finishedProductId, plan } = action;
      const finishedProductIdx = state.findIndex(
        finishedProduct => finishedProduct.finishedProduct.product.id === finishedProductId,
      );
      const newState = [...state];
      if (finishedProductIdx > -1) {
        newState.splice(finishedProductIdx, 1, {
          finishedProduct: state[finishedProductIdx].finishedProduct,
          productionOrders: plan
            .filter(src => src.selected)
            .map(src => {
              return {
                sourceProduct: src.sourceProduct,
                tasks: src.mappings
                  .filter(m => isPositiveNumber(m.quantity))
                  .map(mapping => {
                    return {
                      finishingProductMappingId: mapping.finishingMappingId,
                      serviceName: titleCase(mapping.serviceName),
                      pricePerUnit: Number(mapping.pricePerUnit),
                      setupFee: Number(mapping.setupFee),
                      quantity: Number(mapping.quantity),
                    };
                  }),
              };
            }),
        });
      }
      return newState;
  }
  return state;
}
export interface OrganisationPurchaseRequestProductionPlanPageViewProps {
  initialProductionPlan: ProductionPlanPurchaseRequest;
  // Available production order mappings by finished product Id
  productionOrderMappings: Map<FinishedProductId, Map<SourceProduct, ProductionOptionsForFinishedProduct[]>>;
  onNext: (productionPlan: ProductionPlanPurchaseRequest) => Promise<void>;
  onBack: () => void;
  isProducer: boolean;
}
export const OrganisationPurchaseRequestProductionPlanPageView = ({
  initialProductionPlan,
  productionOrderMappings,
  onNext,
  onBack,
  isProducer,
}: OrganisationPurchaseRequestProductionPlanPageViewProps) => {
  const [editFinishedProductPlan, setEditFinishedProductPlan] = useState<ProductionPlanForFinishedProduct>();
  const [productionPlan, setProductionPlan] = useReducer(productionPlanAction, initialProductionPlan);
  const disableSubmit = useMemo(() => !isProductionPlanValid(productionPlan), [productionPlan]);
  const settlementCurrency = useSettlementCurrency();
  return (
    <PortalPageContent header={<OrganisationPurchaseRequestSetupHeader />}>
      <Stack spacing={5}>
        {editFinishedProductPlan && (
          <EditTasksDialog
            finishedProduct={editFinishedProductPlan.finishedProduct.product}
            targetQuantity={editFinishedProductPlan.finishedProduct.targetQuantity}
            initialValues={getInitialValuesForEditTasksForm(
              editFinishedProductPlan.finishedProduct.product.id,
              productionOrderMappings.get(editFinishedProductPlan.finishedProduct.product.id) ?? new Map(),
              productionPlan,
            )}
            onConfirm={(values: EditTaskFormValues["values"]) => {
              setProductionPlan({
                kind: "edit",
                finishedProductId: editFinishedProductPlan.finishedProduct.product.id,
                plan: values,
              });
            }}
            onClose={() => setEditFinishedProductPlan(undefined)}
            isProducer={isProducer}
          />
        )}
        <Stack spacing={3}>
          <Typography color="common.grey6">
            Add tasks to each product below to choose which input product(s) and service(s) will be used to produce
            these output products.
          </Typography>
          {productionPlan.map(productionPlan => (
            <OrganisationPurchaseRequestProductionPlanCard
              key={productionPlan.finishedProduct.product.id}
              productionPlan={productionPlan}
              currency={settlementCurrency}
              CallToAction={
                <Stack justifyContent="center" p={4} sx={{ backgroundColor: "common.grey4" }}>
                  <Button onClick={() => setEditFinishedProductPlan(productionPlan)}>
                    {productionPlan.productionOrders.length === 0 ? "Add tasks" : "Edit tasks"}
                  </Button>
                </Stack>
              }
            />
          ))}
        </Stack>
        <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
          <Button variant="outlined" onClick={onBack}>
            Back
          </Button>
          <LoadingActionButton
            variant="contained"
            disabled={disableSubmit}
            onClick={async () => await onNext(productionPlan)}>
            Next
          </LoadingActionButton>
        </Stack>
      </Stack>
    </PortalPageContent>
  );
};

function isProductionPlanValid(productionPlan: ProductionPlanPurchaseRequest) {
  return productionPlan.every(req => {
    const targetQuantity = req.finishedProduct.targetQuantity.value;
    const plannedForUnits = req.productionOrders.flatMap(po => po.tasks).reduce((acc, task) => acc + task.quantity, 0);
    return targetQuantity === plannedForUnits;
  });
}

const OrganisationPurchaseRequestSetupHeader = () => {
  return (
    <PortalPageContentHeader
      variant="split"
      title="Production plan"
      right={<PurchaseRequestFlowStepper activeStep={1} withProductionPlan />}
    />
  );
};

interface EditTasksDialogProps {
  finishedProduct: WithDbId<Product>;
  targetQuantity: NumberOfUnits;
  initialValues: EditTaskFormValues;
  onConfirm: (values: EditTaskFormValues["values"]) => void;
  onClose: () => void;
  isProducer: boolean;
}
interface EditTaskFormValues {
  values: EditTaskFormSourceValues[];
}
interface EditTaskFormSourceValues {
  sourceProduct: WithDbId<Product>;
  quantityAvailable: number;
  selected: boolean;
  mappings: EditTaskFormMappingValues[];
}
interface EditTaskFormMappingValues {
  finishingMappingId: string;
  serviceName: string;
  quantityAvailable: number;
  pricePerUnit?: number | null;
  setupFee?: number | null;
  quantity?: number | null;
}
const EditTasksDialog = ({
  finishedProduct,
  targetQuantity,
  initialValues,
  onConfirm,
  onClose,
  isProducer,
}: EditTasksDialogProps) => {
  const unitsLabel = unitsLabelForUnitType(finishedProduct.value.unitType);
  const settlementCurrency = useSettlementCurrency();

  const form = useFormik<EditTaskFormValues>({
    initialValues,
    validationSchema: getValidationSchema(targetQuantity.value),
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: form => {
      onConfirm(form.values);
      onClose();
    },
  });
  const selectedQuantity = useMemo(() => calculateSelectedQuantity(form.values), [form.values]);
  const statusColor =
    selectedQuantity < targetQuantity.value
      ? "common.grey6"
      : selectedQuantity === targetQuantity.value
      ? "success.main"
      : "error";

  return (
    <ConfirmationDialog
      open
      scroll="body"
      fullWidth
      maxWidth="lg"
      title={
        <Stack
          spacing={2}
          divider={<Divider />}
          // Note: Negative margins due to design not respecting common external padding to all dialogs in the platform
          sx={{ backgroundColor: "common.grey3", mx: "-25px", mt: "-20px", p: "45px" }}>
          <Typography variant="body2" color="common.grey6">
            Add Tasks
          </Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <OrganisationProductSummary
              {...finishedProduct.value}
              vesselCapacity={getVesselCapacity(finishedProduct.value.vesselSize)}
            />
            <Stack alignItems="center">
              <Typography
                variant="subtitle1Bold"
                textAlign="center"
                color={statusColor}>{`${selectedQuantity}/${targetQuantity.value}`}</Typography>
              <Typography
                variant="body2"
                textAlign="center"
                color="common.grey6">{`${unitsLabel} accounted for`}</Typography>
            </Stack>
          </Stack>
        </Stack>
      }
      acceptAction={{
        onClick: form.submitForm,
        disabled: !form.isValid,
      }}
      cancelAction={{ onClick: onClose }}>
      <FormikProvider value={form}>
        <Stack spacing={3}>
          <Typography>Which input product(s) would you like to use?</Typography>
          <FieldArray
            name={"values"}
            render={({ name }) => {
              return (
                <>
                  {form.values.values.map(({ sourceProduct, quantityAvailable, selected, mappings }, index) => {
                    const noQuantityAvailable = quantityAvailable === 0;
                    return (
                      <Stack key={`mappings-for-${sourceProduct.id}`} direction="row" alignItems="flex-start">
                        <FormControlLabel
                          label=""
                          disabled={noQuantityAvailable}
                          value={selected}
                          name={`${name}.${index}.selected`}
                          onChange={form.handleChange}
                          control={<Checkbox checked={selected} sx={{ mt: 3 }} />}
                        />
                        <Stack flex={1}>
                          <Card
                            elevation={0}
                            sx={{ backgroundColor: "common.grey2", p: 2, cursor: "pointer" }}
                            onClick={() => form.setFieldValue(`${name}.${index}.selected`, !selected)}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                              <OrganisationProductSummary {...sourceProduct.value} />
                              <Stack direction="row" spacing={2}>
                                <Divider orientation="vertical" flexItem />
                                <Stack alignItems="center" sx={{ p: 1 }}>
                                  <Typography variant="subtitle1Bold" textAlign="center">
                                    {quantityAvailable}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    textAlign="center"
                                    color="common.grey6">{`${unitsLabel} available`}</Typography>
                                </Stack>
                              </Stack>
                            </Stack>
                          </Card>
                          {noQuantityAvailable && (
                            <Stack direction="row" spacing={1} alignItems="center" mt={1}>
                              <WarningAmberOutlinedIcon color="warning" />
                              <Typography color="common.error">
                                You don’t have enough bottles to produce this output product as you’ve allocated
                                available bottles to a different product in this request.
                              </Typography>
                            </Stack>
                          )}
                          <Grow in={selected} unmountOnExit>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <ProductionPlanTableCell align="left" sx={{ width: "10%" }}>
                                    Service
                                  </ProductionPlanTableCell>
                                  <ProductionPlanTableCell>Price per unit</ProductionPlanTableCell>
                                  <ProductionPlanTableCell>Setup fee</ProductionPlanTableCell>
                                  <ProductionPlanTableCell>{`Output quantity (${unitsLabel})`}</ProductionPlanTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <FieldArray
                                  name={`${name}.${index}.mappings`}
                                  render={({ name }) => {
                                    return (
                                      <>
                                        {mappings.map(
                                          (
                                            { finishingMappingId, serviceName, pricePerUnit, setupFee, quantity },
                                            idx,
                                          ) => {
                                            const lastRow = idx === mappings.length - 1;
                                            return (
                                              <TableRow key={finishingMappingId}>
                                                <ProductionPlanTableCell
                                                  align="left"
                                                  noBottom={lastRow}
                                                  sx={{ width: "10%" }}>
                                                  {titleCase(serviceName)}
                                                </ProductionPlanTableCell>
                                                <ProductionPlanTableCell noBottom={lastRow}>
                                                  {isProducer ? (
                                                    <CurrencyRenderer
                                                      value={pricePerUnit}
                                                      currency={settlementCurrency}
                                                    />
                                                  ) : (
                                                    <CurrencyInput
                                                      currency={settlementCurrency}
                                                      onChange={form.handleChange}
                                                      onBlur={form.handleBlur}
                                                      name={`${name}.${idx}.pricePerUnit`}
                                                      required={true}
                                                      value={pricePerUnit}
                                                      error={
                                                        //@ts-ignore
                                                        !!form.errors.values?.[index]?.mappings?.[idx]?.pricePerUnit
                                                      }
                                                      helperText={
                                                        form.touched.values?.[index]?.mappings?.[idx]?.pricePerUnit &&
                                                        //@ts-ignore
                                                        form.errors.values?.[index]?.mappings?.[idx]?.pricePerUnit
                                                      }
                                                    />
                                                  )}
                                                </ProductionPlanTableCell>
                                                <ProductionPlanTableCell noBottom={lastRow}>
                                                  {isProducer ? (
                                                    <CurrencyRenderer value={setupFee} currency={settlementCurrency} />
                                                  ) : (
                                                    <CurrencyInput
                                                      currency={settlementCurrency}
                                                      name={`${name}.${idx}.setupFee`}
                                                      value={setupFee}
                                                      onChange={form.handleChange}
                                                      onBlur={form.handleBlur}
                                                      required={true}
                                                      error={
                                                        // @ts-ignore
                                                        !!form.errors.values?.[index]?.mappings?.[idx]?.setupFee
                                                      }
                                                      helperText={
                                                        form.touched.values?.[index]?.mappings?.[idx]?.setupFee &&
                                                        //@ts-ignore
                                                        form.errors.values?.[index]?.mappings?.[idx]?.setupFee
                                                      }
                                                    />
                                                  )}
                                                </ProductionPlanTableCell>
                                                <ProductionPlanTableCell noBottom={lastRow}>
                                                  <UnitsInput
                                                    hideUnitsLabel
                                                    unitType={sourceProduct.value.unitType}
                                                    name={`${name}.${idx}.quantity`}
                                                    value={quantity}
                                                    onChange={form.handleChange}
                                                    onBlur={form.handleBlur}
                                                    error={
                                                      !!form.touched.values?.[index] &&
                                                      (!!form.errors.values?.[index] ||
                                                        //@ts-ignore
                                                        !!form.errors.values?.[index]?.mappings?.[idx]?.quantity)
                                                    }
                                                    required={true}
                                                    helperText={
                                                      form.touched.values?.[index]?.mappings?.[idx]?.quantity &&
                                                      //@ts-ignore
                                                      form.errors.values?.[index]?.mappings?.[idx]?.quantity
                                                    }
                                                  />
                                                </ProductionPlanTableCell>
                                              </TableRow>
                                            );
                                          },
                                        )}
                                      </>
                                    );
                                  }}
                                />
                              </TableBody>
                            </Table>
                          </Grow>
                        </Stack>
                      </Stack>
                    );
                  })}
                </>
              );
            }}
          />
        </Stack>
      </FormikProvider>
    </ConfirmationDialog>
  );
};

const ProductionPlanTableCell = styled(TableCell, { shouldForwardProp: propName => propName !== "noBottom" })<{
  noBottom?: boolean;
}>(({ noBottom, ...props }) => ({
  textAlign: props.align ?? "right",
  ...(noBottom && { borderBottom: "unset" }),
}));

function getInitialValuesForEditTasksForm(
  finishedProductId: FinishedProductId,
  productionOrderMappings: Map<SourceProduct, ProductionOptionsForFinishedProduct[]>,
  productionPlan: ProductionPlanPurchaseRequest,
) {
  const productionPlanForFinishedProduct = productionPlan.find(p => p.finishedProduct.product.id === finishedProductId);
  const previouslySelectedSourceProductIds = new Set(
    productionPlanForFinishedProduct?.productionOrders.map(({ sourceProduct }) => sourceProduct.id),
  );
  const previouslyMappingsSelected = new Map<FinishingProductMappingId, ProductionPlanTask>(
    productionPlanForFinishedProduct?.productionOrders
      .flatMap(({ tasks }) => tasks)
      .map(mapping => [mapping.finishingProductMappingId, mapping]),
  );

  const availableQuantitiesBySourceProduct = calculateQuantityAvailableForSourceProduct(
    finishedProductId,
    productionOrderMappings,
    productionPlan,
  );

  return {
    values: Array.from(productionOrderMappings.entries()).map(([sourceProduct, productionOptions]) => {
      const sourceProductId = sourceProduct.id;
      const quantityAvailable = availableQuantitiesBySourceProduct.get(sourceProductId) || 0;
      return {
        sourceProduct,
        selected:
          previouslySelectedSourceProductIds.has(sourceProductId) ||
          // auto-select if only one option and there is quantity available
          (productionOrderMappings.size === 1 && quantityAvailable > 0),
        quantityAvailable,
        mappings: productionOptions.map(mapping => {
          const previouslySelected = previouslyMappingsSelected.get(mapping.finishingProductMappingId);
          return {
            finishingMappingId: mapping.finishingProductMappingId,
            serviceName: mapping.finishingService.value.name,
            quantityAvailable,
            pricePerUnit:
              previouslySelected?.pricePerUnit ?? Number(mapping.finishingService.value.defaultPricePerUnit),
            setupFee: previouslySelected?.setupFee ?? Number(mapping.finishingService.value.defaultSetupCost),
            quantity: previouslySelected?.quantity ?? null,
          };
        }),
      };
    }),
  };
}

function calculateQuantityAvailableForSourceProduct(
  finishedProductId: FinishedProductId,
  productionOrderMappings: Map<SourceProduct, ProductionOptionsForFinishedProduct[]>,
  productionPlan: ProductionPlanPurchaseRequest,
): Map<DbKey<Product>, number> {
  // Note: Quantity available should be the same across all production order options initially as they all share the same source product at this point
  // So we pick the quantity available from the first production order option for each source product
  const result = new Map(
    [...productionOrderMappings.entries()].map(([sourceProduct, productionOptions]) => [
      sourceProduct.id,
      productionOptions[0].quantityAvailable.value,
    ]),
  );

  // Now take into account selected sources in the production plan
  // Other finished products might have already used some of the same source product units
  productionPlan
    //Do not take into account the finished product we are editing
    .filter(p => p.finishedProduct.product.id !== finishedProductId)
    .forEach(({ productionOrders }) => {
      // For each production order, find the source product that is being used
      productionOrders.forEach(({ sourceProduct, tasks }) => {
        // Find the quantity used
        tasks.forEach(({ quantity: quantityUsed }) => {
          const quantityAvailable = result.get(sourceProduct.id) || 0;
          const remainingQuantity = quantityAvailable - quantityUsed;
          result.set(sourceProduct.id, remainingQuantity);
        });
      });
    });

  return result;
}

function calculateSelectedQuantity(form: EditTaskFormValues): number {
  return form.values.reduce((acc, { selected, mappings }) => {
    if (selected) {
      const q = mappings.reduce((acc, { quantity }) => acc + (isValidNumber(quantity) ? Number(quantity) : 0), 0);
      return acc + q;
    }
    return acc;
  }, 0);
}

const mappingSchema = object().shape({
  finishingMappingId: string().required(),
  serviceName: string().required(),
  quantityAvailable: number().required(),
  pricePerUnit: number()
    .nullable()
    .when("quantity", {
      is: (quantity: number) => isValidNumber(quantity),
      then: schema => schema.required("This is a required field"),
    }),
  setupFee: number()
    .nullable()
    .when("quantity", {
      is: (quantity: number) => isValidNumber(quantity),
      then: schema => schema.required("This is a required field"),
    }),
  quantity: number()
    .min(1, "Please input a quantity greater than 1")
    .max(ref("quantityAvailable"), "Exceeded available quantity")
    .integer("Please input a number")
    .positive("Please input a quantity greater than 0")
    .nullable(),
});
const getValidationSchema = (targetQuantity: number) => {
  return object()
    .shape({
      values: array().of(
        object()
          .shape({
            selected: boolean().required(),
            quantityAvailable: number().required(),
            mappings: array().of(mappingSchema),
          })
          .test("check-at-least-one-mapping", "At least one mapping must be fully selected", function (source) {
            return (
              !source.selected ||
              assertNotUndefined(source?.mappings).some(
                ({ quantity, pricePerUnit, setupFee }) =>
                  isValidNumber(pricePerUnit) &&
                  isValidNumber(setupFee) &&
                  isValidNumber(quantity) &&
                  (Number(quantity) ?? 0) > 0,
              )
            );
          })
          .test("check-not-above-available", "Not enough units available", function (source) {
            const selectedQuantity = assertNotUndefined(source?.mappings).reduce(
              (acc, { quantity }) => acc + (isValidNumber(quantity) ? Number(quantity) : 0),
              0,
            );
            return !source.selected || selectedQuantity <= assertNotUndefined(source.quantityAvailable);
          }),
      ),
    })
    .required()
    .test("target-limit", "Invalid target quantity", function (form, ctx) {
      //TODO: Fix types so casting is not needed
      const result = form && calculateSelectedQuantity(form as EditTaskFormValues) === targetQuantity;
      //Workaround for yup BUG: https://github.com/jaredpalmer/formik/issues/2146
      return result || ctx.createError({ message: "Invalid target quantity", path: "root" });
    })
    .test("check-at-least-one-source", "At least one source product must be selected", function (form, ctx) {
      const result = form.values && form.values.some(({ selected }) => selected);
      //Workaround for yup BUG: https://github.com/jaredpalmer/formik/issues/2146
      return result || ctx.createError({ message: "At least one source must be selected", path: "root" });
    });
};
