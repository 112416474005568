import { Stack } from "@mui/material";
import { Organisation } from "adl-gen/ferovinum/app/db";
import { WorkflowId } from "adl-gen/ferovinum/app/workflow";
import { PageHeader } from "components/library/widgets/page-header";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { Loader } from "components/widgets/loader/loader";
import { StockMovementSummary as StockMovementSummaryPage } from "components/widgets/stock-movement/stock-movement-summary";
import React from "react";
import { StockMovementSummary } from "utils/hooks/use-stock-movement-summary";
import { assertNotUndefined } from "utils/hx/util/types";
import { LoadingValue, isLoaded } from "utils/utility-types";
import { PortalPageContent } from "../../../layouts/portal-page-content/portal-page-content";

export interface StockMovementDetailsPageViewProps {
  stockMovementSummary: LoadingValue<StockMovementSummary>;
  organisation: Organisation;
  handleCancel: (crWorkflowId: WorkflowId) => Promise<void>;
}

export const StockMovementDetailsPageView = (props: StockMovementDetailsPageViewProps) => {
  return (
    <PortalPageContent
      header={
        <Loader loadingStates={[props.stockMovementSummary]}>
          {isLoaded(props.stockMovementSummary) && (
            <StockMovementDetailsHeader
              // Ref should be present if you are on this page
              referenceNumber={assertNotUndefined(props.stockMovementSummary.value.reference)}
              startingLocationName={props.stockMovementSummary.value.startingLocation.storageLocationName}
              destinationLocationName={props.stockMovementSummary.value.destinationLocation.storageLocationName}
            />
          )}
        </Loader>
      }>
      <Loader loadingStates={[props.stockMovementSummary]}>
        <Stack spacing={7}>
          {isLoaded(props.stockMovementSummary) && (
            <StockMovementPageInner
              stockMovementSummary={props.stockMovementSummary.value}
              organisation={props.organisation}
              handleCancel={props.handleCancel}
            />
          )}
        </Stack>
      </Loader>
    </PortalPageContent>
  );
};

const StockMovementPageInner = ({
  stockMovementSummary,
  organisation,
  handleCancel,
}: {
  stockMovementSummary: StockMovementSummary;
  organisation: Organisation;
  handleCancel: (crWorkflowId: WorkflowId) => Promise<void>;
}) => {
  const currentStateIndex = stockMovementSummary.progress.nextStateIndex - 1;
  const showCancelButton = stockMovementSummary.progress.states[currentStateIndex].label == "Requested";
  return (
    <Stack spacing={7}>
      <StockMovementSummaryPage {...stockMovementSummary} organisation={organisation} />
      {showCancelButton && (
        <StockMovementCancelButton
          handleCancel={async () => {
            if (stockMovementSummary.workflows.stockMovement?.id) {
              handleCancel(stockMovementSummary.workflows.stockMovement?.id);
            }
          }}
        />
      )}
    </Stack>
  );
};

const StockMovementDetailsHeader = ({
  referenceNumber,
  startingLocationName,
  destinationLocationName,
}: {
  referenceNumber: string;
  startingLocationName: string;
  destinationLocationName: string;
}) => {
  const titledTexts = [
    {
      title: "Reference Number",
      text: referenceNumber,
    },
  ];
  return (
    <PageHeader
      title="Stock Movement"
      subtitle={"from " + startingLocationName + " to " + destinationLocationName}
      right={{
        type: "titledText",
        titledTexts: titledTexts,
      }}
    />
  );
};

const StockMovementCancelButton = ({ handleCancel }: { handleCancel: () => Promise<void> }) => {
  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
      <Stack>
        <LoadingActionButton onClick={handleCancel}>Cancel Stock Movement</LoadingActionButton>
      </Stack>
    </Stack>
  );
};
