import React, { useRef } from "react";
import { Box, Collapse, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { useRefDimensions } from "utils/hooks/use-ref-dimensions";

export type ExpandableRadioOption = Omit<RadioOptionsProps, "show">;
export interface ExpandableRadioProps {
  options: (ExpandableRadioOption | null)[];
  value?: string;
  onChange(v: string): void;
  row?: boolean;
}

export const ExpandableRadio = ({ options, onChange, value, row }: ExpandableRadioProps) => {
  const filteredOptions: ExpandableRadioOption[] = options.filter(option => option != null) as Omit<
    RadioOptionsProps,
    "show"
  >[];
  return (
    <FormControl sx={{ width: "100%" }}>
      <RadioGroup row={row} value={value} onChange={(_, v) => onChange(v)}>
        {filteredOptions.map(option => (
          <RadioOption
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            label={option.label}
            show={option.value === value}
            row={row}>
            {option.children}
          </RadioOption>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

interface RadioOptionsProps {
  value: string;
  label: React.ReactNode;
  show: boolean;
  disabled?: boolean;
  row?: boolean;
  children?: React.ReactNode;
}
const RadioOption = ({ value, label, show, disabled, row, children }: RadioOptionsProps) => {
  const radioRef = useRef<HTMLButtonElement | null>(null);
  /// Note: radio group has a negative margin hence the (-9)
  const radioWidth = useRefDimensions(radioRef).width - 9;

  return (
    <>
      <FormControlLabel
        id={value}
        disabled={disabled}
        sx={{ alignItems: "flex-start", pt: 1 }}
        value={value}
        control={<Radio ref={radioRef} sx={{ p: 1 }} disabled={disabled} />}
        componentsProps={{ typography: { flex: 1, pt: 1 } }}
        label={<Stack spacing={2}>{label}</Stack>}
      />
      {children && (
        <Collapse in={show} orientation={row ? "horizontal" : "vertical"}>
          <Box sx={{ ml: row ? 2 : `${radioWidth}px`, pt: row ? 1 : 2, pb: 4, mr: row ? 2 : 0 }}>{children}</Box>
        </Collapse>
      )}
    </>
  );
};
