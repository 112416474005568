import { Checkbox, Divider, FormControlLabel, FormGroup, Stack, Tab, Tabs, Typography } from "@mui/material";
import {
  DealExtensionRequestPricingDetails,
  OrganisationDealExtensionRequestAction,
  OrganisationDealExtensionRequestDetailsView,
  OrganisationDealExtensionRequestLineItemView,
} from "adl-gen/ferovinum/app/api";
import { Currency, DealExtensionRequestState, DealExtensionRequestStateEvent } from "adl-gen/ferovinum/app/db";
import { LabelledCard } from "components/widgets/labelled-card/labelled-card";
import React, { useCallback, useMemo, useState } from "react";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { DealExtensionRequestStepper } from "components/widgets/deal-extension-requests/deal-extension-request-stepper/deal-extension-request-stepper";
import { DealExtensionRequestContractNoteTable } from "components/widgets/deal-extension-requests/deal-extension-request-contract-note-table/deal-extension-request-contract-note-table";
import { addDays } from "date-fns";
import { useInfoDrawer } from "../../../../layouts/info-drawer/info-drawer";
import { ProductMovement } from "../../../../widgets/product-movement/product-movement";
import { Instant } from "adl-gen/common";
import { Link } from "components/widgets/link/link";
import { useConfirmationDialog, useTermsDialog } from "components/context/global-dialog/use-dialog";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { assertNever, assertNotUndefined } from "utils/hx/util/types";
import { formatDate, formatLocalDate } from "utils/date-utils";
import { CsvDownloadButton } from "components/widgets/buttons/csv-download-button/csv-download-button";
import { createBigDecimalCurrencyFormatter } from "utils/currency-utils";
import { formatNumberOfUnits } from "utils/model-utils";
import { TermsLink } from "components/widgets/link/terms-link";
import { DownloadPdfButton } from "components/widgets/buttons/download-pdf-button";

const OrganisationDealExtensionRequestDetailsPageHeader = ({ requestNumber }: { requestNumber: string }) => {
  const title = "Deal Renewal Request";
  return (
    <PortalPageContentHeader
      variant="split"
      title={title}
      right={
        <Stack width={"100%"} textAlign={"right"}>
          <Typography sx={{ fontSize: 12, color: "common.grey5" }}>Reference Number</Typography>
          <Typography variant="subtitle1Bold">{requestNumber}</Typography>
        </Stack>
      }
    />
  );
};

type DealExtensionRequestDetailsSection = "progress" | "contractNote";

export type OrganisationDealExtensionRequestDetailsPageViewProps = OrganisationDealExtensionRequestDetailsView & {
  onClickAction(action: OrganisationDealExtensionRequestAction): Promise<void>;
  getContractNotePdfUrl?: () => Promise<string | undefined>;
};
export const OrganisationDealExtensionRequestDetailsPageView = ({
  dealNumber,
  stateEvents,
  organisationName,
  pricingDetails,
  lineItems,
  currency,
  onClickAction,
  getContractNotePdfUrl,
}: OrganisationDealExtensionRequestDetailsPageViewProps) => {
  const [selectedSection, setSelectedSection] = useState<DealExtensionRequestDetailsSection>("progress");

  const header = <OrganisationDealExtensionRequestDetailsPageHeader requestNumber={dealNumber} />;

  return (
    <PortalPageContent header={header}>
      <Stack spacing={5}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Tabs value={selectedSection} onChange={(_, v) => setSelectedSection(v)}>
            <Tab value="progress" label="Progress" />
            {pricingDetails && <Tab value="contractNote" label="Contract Note" />}
          </Tabs>
        </Stack>

        <ProgressSection
          display={selectedSection === "progress"}
          dealNumber={dealNumber}
          onClickAction={onClickAction}
          currency={currency}
          stateEvents={stateEvents}
          organisationName={organisationName}
          lineItems={lineItems}
          pricingDetails={pricingDetails ?? undefined}
        />

        <ContractNoteSection
          display={selectedSection === "contractNote"}
          currency={currency}
          pricingDetails={pricingDetails ?? undefined}
          lineItems={lineItems}
          getPdfUrl={getContractNotePdfUrl}
        />
      </Stack>
    </PortalPageContent>
  );
};

interface ProgressSectionProps {
  display: boolean;
  dealNumber: string;
  currency: Currency;
  stateEvents: DealExtensionRequestStateEvent[];
  organisationName: string;
  pricingDetails?: DealExtensionRequestPricingDetails;
  lineItems: OrganisationDealExtensionRequestLineItemView[];
  onClickAction(action: OrganisationDealExtensionRequestAction): Promise<void>;
}
const ProgressSection = ({
  display,
  dealNumber,
  currency,
  stateEvents,
  organisationName,
  pricingDetails,
  lineItems,
  onClickAction,
}: ProgressSectionProps) => {
  const currentState = useMemo(() => stateEvents[stateEvents.length - 1], [stateEvents]);
  return (
    <Stack spacing={5} display={display ? undefined : "none"}>
      {currentState.state !== "paymentReceived" && (
        <LabelledCard label="Next Action">
          <NextAction
            dealNumber={dealNumber}
            onClickAction={onClickAction}
            currency={currency}
            currentState={currentState.state}
            lastStateSet={currentState.time}
            pricingDetails={pricingDetails}
            lineItems={lineItems}
          />
        </LabelledCard>
      )}
      <LabelledCard label="Progress">
        <DealExtensionRequestStepper stateEvents={stateEvents} orgName={organisationName} />
      </LabelledCard>
    </Stack>
  );
};

interface NextActionProps {
  dealNumber: string;
  currentState: Exclude<DealExtensionRequestState, "paymentReceived">;
  lastStateSet: Instant;
  currency: Currency;
  pricingDetails?: DealExtensionRequestPricingDetails;
  lineItems: OrganisationDealExtensionRequestLineItemView[];
  onClickAction(action: OrganisationDealExtensionRequestAction): Promise<void>;
}
const NextAction = ({
  dealNumber,
  currentState,
  lastStateSet,
  currency,
  pricingDetails,
  lineItems,
  onClickAction,
}: NextActionProps) => {
  const { showConfirmationDialog } = useConfirmationDialog();

  const onClickInvoicePaid = useCallback(async () => {
    await showConfirmationDialog({
      title: "Would you like to confirm payment of the invoice?",
      confirmAction: { onClick: async () => await onClickAction("invoicePaid") },
    });
  }, [onClickAction, showConfirmationDialog]);

  switch (currentState) {
    case "newDeal":
      return <Typography>Waiting on Ferovinum to provide the contract note. No action required.</Typography>;
    case "newDealPriced":
      return (
        <AcceptContractNoteAction
          dealNumber={dealNumber}
          currency={currency}
          pricingDetails={assertNotUndefined(pricingDetails)}
          lineItems={lineItems}
          onClickAction={onClickAction}
        />
      );
    case "orgRejected":
      return (
        <Typography>
          Following the rejection of the contract note, please repurchase all the stock included in this renewal request
          before the expiry date.
        </Typography>
      );
    case "orgAccepted":
      return <Typography>Waiting on Ferovinum to renew the deal. No action required.</Typography>;
    case "nothingToExtend":
      return <Typography>Everything has been repurchased. Nothing to renew.</Typography>;
    case "newDealCreated":
      return <Typography>Waiting on Ferovinum to provide the invoice. No action required.</Typography>;
    case "invoiced":
      return (
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
          <Stack py={2}>
            <Typography>We have sent you the invoice for the deal renewal request via email.</Typography>
            <Typography>
              Kindly confirm the payment of this invoice to proceed with the deal renewal process.
            </Typography>
          </Stack>

          <LoadingActionButton onClick={onClickInvoicePaid}>{"I've paid the invoice"}</LoadingActionButton>
        </Stack>
      );
    case "invoicePaid":
      return (
        <Stack>
          <Typography>Waiting on Ferovinum to confirm receipt of this invoice payment. No action required.</Typography>
          <Typography variant="caption" color="common.darkGrey">{`due on ${formatDate(
            addDays(lastStateSet, 1),
          )}`}</Typography>
        </Stack>
      );
    default:
      assertNever(currentState);
  }
};

type ContractNoteAction = Exclude<OrganisationDealExtensionRequestAction, "invoicePaid">;
interface AcceptContractNoteActionProps {
  dealNumber: string;
  currency: Currency;
  pricingDetails: DealExtensionRequestPricingDetails;
  lineItems: OrganisationDealExtensionRequestLineItemView[];
  onClickAction(action: ContractNoteAction): Promise<void>;
}
const AcceptContractNoteAction = ({
  dealNumber,
  currency,
  pricingDetails,
  lineItems,
  onClickAction,
}: AcceptContractNoteActionProps) => {
  const { showTermsDialog } = useTermsDialog();
  const { showConfirmationDialog } = useConfirmationDialog();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const { dealTermsView } = pricingDetails;

  const onShowTerms = async (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    await showTermsDialog({
      masterAgreementDate: dealTermsView.masterAgreementDate,
      terms: [dealTermsView.dealTerms.value.general, dealTermsView.dealTerms.value.fsp],
      markdownAdditionalTerms: dealTermsView.additionalTerms ?? undefined,
    });
  };

  const handleClickAction = useCallback(
    async (action: ContractNoteAction) => {
      await showConfirmationDialog({
        title: `Would you like to ${action === "acceptTerms" ? "accept" : "reject"} the contract note?`,
        confirmAction: { onClick: async () => await onClickAction(action) },
      });
    },
    [onClickAction, showConfirmationDialog],
  );

  const generateCsvData = useCallback(() => {
    const currencyFormatter = createBigDecimalCurrencyFormatter(currency)({
      rounding: {
        kind: "rounded",
        minimumFractionDigits: 2,
        maximumFractionDigits: 4,
      },
    });
    const resultCsv = lineItems.reduce(
      (result: Record<string, string>[], lineItem: OrganisationDealExtensionRequestLineItemView) => {
        const {
          product: { value: product },
          totalQuantityRequested,
          pricingDetails,
        } = lineItem;
        const pricing = assertNotUndefined(pricingDetails ?? undefined);

        const newRow = {
          ["Code"]: product.code,
          ["Description"]: product.name,
          ["Quantity"]: formatNumberOfUnits(totalQuantityRequested, product.unitType),
          ["CS Expiry Date"]: pricing.newCsStage ? formatLocalDate(pricing.newCsStage.deadlineDate) : "-",
          ["Final Expiry Date"]: formatLocalDate(pricing.newFinalStage.deadlineDate),
          ["Prior Purchase Price Per Unit"]: currencyFormatter.format(pricing.previousPurchasePricePerUnit),
          ["New Purchase Price Per Unit"]: currencyFormatter.format(pricing.newPurchasePricePerUnit),
          ["Prior Deposit Per Unit"]: currencyFormatter.format(pricing.previousDepositPricePerUnit),
          ["New Deposit Per Unit"]: currencyFormatter.format(pricing.newDepositPricePerUnit),
          ["Monthly Fee Type"]: pricing.monthlyFee.kind,
          ["Monthly Fee Percentage"]:
            pricing.monthlyFee.kind === "fixed" ? pricing.monthlyFee.value : pricing.monthlyFee.value.base,
          ["Throughput Fee Percentage"]: pricing.throughputFeePc,
        };

        return result.concat(newRow);
      },
      [],
    );

    return resultCsv;
  }, [currency, lineItems]);

  return (
    <Stack spacing={3}>
      <Typography>
        Please review and accept the following contract note, pertaining to the deal renewal request.
      </Typography>
      <Typography sx={{ pl: 2 }}>Summary of New Contract Note</Typography>
      <Divider />
      <ContractNoteTable
        currency={currency}
        lineItems={lineItems}
        pricingDetails={pricingDetails}
        showPaymentOnDealRenewal
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CsvDownloadButton
          variant="outlined"
          outputFileName={`${dealNumber}_DEAL_RENEWAL_REQUEST_ITEMS`}
          getCsvData={generateCsvData}>
          Download Contract Note comparison CSV
        </CsvDownloadButton>
        <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox value={acceptTerms} onClick={() => setAcceptTerms(!acceptTerms)} />}
              label={
                <Stack direction="row" spacing={1}>
                  <Typography>I agree to the </Typography>
                  <Link onClick={async (e: React.MouseEvent) => await onShowTerms(e)}>Terms</Link>
                </Stack>
              }
            />
          </FormGroup>
          <LoadingActionButton variant="outlined" onClick={async () => await handleClickAction("rejectTerms")}>
            Reject
          </LoadingActionButton>
          <LoadingActionButton
            variant="contained"
            disabled={!acceptTerms}
            onClick={async () => await handleClickAction("acceptTerms")}>
            Accept
          </LoadingActionButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

interface ContractNoteSectionProps {
  display: boolean;
  currency: Currency;
  pricingDetails?: DealExtensionRequestPricingDetails;
  lineItems: OrganisationDealExtensionRequestLineItemView[];
  getPdfUrl?: () => Promise<string | undefined>;
}
const ContractNoteSection = ({ display, currency, pricingDetails, lineItems, getPdfUrl }: ContractNoteSectionProps) => {
  const dealTermsView = pricingDetails?.dealTermsView;
  return (
    <Stack display={display ? undefined : "none"}>
      <ContractNoteTable currency={currency} lineItems={lineItems} pricingDetails={pricingDetails} />
      <Stack direction="row" justifyContent={"space-between"} spacing={2} marginTop={2}>
        {getPdfUrl && <DownloadPdfButton getDownloadUrl={getPdfUrl} />}
        {dealTermsView && <TermsLink dealTermsView={dealTermsView} />}
      </Stack>
    </Stack>
  );
};

interface ContractNoteTableProps {
  showPaymentOnDealRenewal?: boolean;
  currency: Currency;
  pricingDetails?: DealExtensionRequestPricingDetails;
  lineItems: OrganisationDealExtensionRequestLineItemView[];
}
const ContractNoteTable = ({
  showPaymentOnDealRenewal,
  currency,
  pricingDetails,
  lineItems,
}: ContractNoteTableProps) => {
  const [openProductInfo] = useInfoDrawer();

  return (
    <DealExtensionRequestContractNoteTable
      currency={currency}
      lineItems={lineItems.map(lineItem => {
        const { pricingDetails } = lineItem;

        return {
          onClickProductId: () => openProductInfo({ children: <ProductMovement productId={lineItem.product.id} /> }),
          product: lineItem.product.value,
          compulsoryStage: pricingDetails?.newCsStage
            ? {
                quantity: pricingDetails.newCsStage.quantity,
                deadlineDate: pricingDetails.newCsStage.deadlineDate,
              }
            : undefined,
          finalStage: pricingDetails?.newFinalStage
            ? {
                quantity: pricingDetails.newFinalStage.quantity,
                deadlineDate: pricingDetails.newFinalStage.deadlineDate,
              }
            : { quantity: lineItem.totalQuantityRequested },
          depositPrice: pricingDetails?.newDepositPricePerUnit,
          netAdvance: pricingDetails?.totalNetAdvanceAmount,
          purchasePricePerUnit: pricingDetails?.newPurchasePricePerUnit,
          monthlyFee: pricingDetails?.monthlyFee,
          throughputFeePc: pricingDetails?.throughputFeePc,
        };
      })}
      grossPurchasePricePayableByFerovinum={pricingDetails?.totalPurchasePrice}
      totalDepositDue={pricingDetails?.totalDepositPrice}
      totalNetAdvance={pricingDetails?.totalNetAdvanceAmount}
      paymentOnDealRenewalDetails={
        showPaymentOnDealRenewal && pricingDetails
          ? {
              totalFeesDue: pricingDetails.totalDealRenewalCost,
              changeInNetAdvanceAmount: pricingDetails.changeInNetAdvanceAmount,
              netReceivableOnDealRenewal: pricingDetails.netReceivable,
            }
          : undefined
      }
    />
  );
};
