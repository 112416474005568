import React, { useState, useCallback, useEffect } from "react";
import { Loader } from "components/widgets/loader/loader";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { isLoaded } from "utils/utility-types";
import { PurchaseRequestsPageView } from "./organisation-purchase-requests-page-view";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { assertNotUndefined } from "utils/hx/util/types";
import { PurchaseRequestView, PurchaseRequestReportData } from "adl-gen/ferovinum/app/api";
import { downloadTPSDataToCSV } from "../../../../../service/TPS-csv-download-service";

export type PurchaseRequestsDashboardSection = "inProgress" | "completed";

export const OrganisationPurchaseRequestsPage = () => {
  const service = useAppService();
  const organisationId = assertNotUndefined(useSelectedOrgId());
  const [selectedSection, setSelectedSection] = useState<PurchaseRequestsDashboardSection>("inProgress");
  const [completedPurchaseRequestsLoaded, setCompletedPurchaseRequestsLoaded] = useState(false);
  const [completedPurchaseRequests, setCompletedPurchaseRequests] = useState<PurchaseRequestView[]>([]);

  const loadInProgressPurchaseRequests = useCallback(async () => {
    const inProgressPurchaseRequests = await service.getPurchaseRequestsForOrganisation({
      organisationId,
      stateType: "in_progress",
      lookbackDays: null,
    });
    return inProgressPurchaseRequests;
  }, [organisationId, service]);

  const loadCompletedPurchaseRequests = useCallback(async () => {
    const completedPurchaseRequests = await service.getPurchaseRequestsForOrganisation({
      organisationId,
      stateType: "completed",
      lookbackDays: null,
    });
    setCompletedPurchaseRequests(completedPurchaseRequests);
    setCompletedPurchaseRequestsLoaded(true);
  }, [organisationId, service]);

  const loadPurchaseRequestDownloadData = useCallback(async (): Promise<PurchaseRequestReportData[]> => {
    return await service.getPurchaseRequestsForOrganisationReport({
      organisationId,
      stateType: selectedSection === "inProgress" ? "in_progress" : "completed",
      lookbackDays: null,
    });
  }, [organisationId, service, selectedSection]);

  const onDownloadCSV = async () => {
    if (isLoaded(loadingData)) {
      const downloadData = await loadPurchaseRequestDownloadData();
      downloadTPSDataToCSV(
        downloadData,
        selectedSection === "inProgress" ? "InProgressPurchaseRequests" : "CompletedPurchaseRequests",
      );
    }
  };

  const [loadingData] = useLoadingDataState(loadInProgressPurchaseRequests);

  useEffect(() => {
    if (selectedSection === "completed" && !completedPurchaseRequestsLoaded) {
      loadCompletedPurchaseRequests();
    }
  }, [selectedSection, completedPurchaseRequestsLoaded, loadCompletedPurchaseRequests]);

  return (
    <Loader loadingStates={[loadingData]}>
      {isLoaded(loadingData) && (
        <PurchaseRequestsPageView
          inProgressPurchaseRequests={loadingData.value}
          completedPurchaseRequests={completedPurchaseRequests}
          onSelectSection={setSelectedSection}
          completedPurchaseRequestsLoaded={completedPurchaseRequestsLoaded}
          onDownloadCSV={onDownloadCSV}
        />
      )}
    </Loader>
  );
};
