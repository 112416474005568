import {
  StockMovementCollection,
  StockMovementDelivery,
  StockMovementDeliveryOption,
} from "adl-gen/ferovinum/app/workflow";
import {
  arrayField,
  localDateField,
  localTimeField,
  numberField,
  objectField,
  stringField,
  unionField,
} from "utils/data-field/data-field-builder";
import { makeObjectDef } from "utils/data-field/object-field-def";

import { StorageLocationView } from "adl-gen/ferovinum/app/api";
import { Carrier, Empty, ProductId, StorageLocationId, UnitType } from "adl-gen/ferovinum/app/db";
import { MatchKeys } from "utils/utility-types";
import { ProductSummaryProps } from "components/widgets/product-summary/product-summary";
import { formatDateToLocalDate } from "utils/date-utils";
import { StockMovementSummary } from "utils/hooks/use-stock-movement-summary";
import { SelectorInfo } from "components/library/widgets/db-key-selector";
const TODAY = new Date();

export interface StockMovementWizardData {
  productSelection: StockMovementProductSelectionData;
  deliveryDetails: DeliveryDetailsStepData;
  confirmation: ConfirmationStepData;
}

export type StockMovementWizardContext = MatchKeys<
  StockMovementWizardData,
  {
    productSelection: StockMovementProductSelectionContext;
    deliveryDetails: StockMovementDeliverySelectionContext;
    confirmation: StockMovementConfirmationSelectionContext;
  }
>;

export interface StockMovementProductSelectionData {
  startLocation: StorageLocationId;
  destinationLocation: StorageLocationId;
  products: ProductRow[];
}

export interface StockMovementProductSelectionContext {
  hasStockLocations: StorageLocationView[];
  associatedAndPublicLocations: StorageLocationView[];
  productInfos: Record<ProductId, { productSummary: ProductSummaryProps; unitType: UnitType }>;
}

export interface StockMovementDeliverySelectionContext {
  deliveryEnabled: boolean;
  carrierOptions: SelectorInfo<Carrier>[];
}

export interface StockMovementConfirmationSelectionContext {
  stockMovementSummary: StockMovementSummary;
}

export interface ProductRow {
  productId: ProductId;
  quantity: number;
  maxQuantity: number;
}

export interface DeliveryDetailsStepData {
  stockMovementDeliveryOption: StockMovementDeliveryOption;
}

export type ConfirmationStepData = Empty;

export const PRODUCT_ROW_DEF = makeObjectDef({
  productId: stringField("Product ID").required(),
  quantity: numberField("Quantity to Relocate", { range: "positive" }).required(),
  maxQuantity: numberField("Max Quantity").required(),
});

export const STOCK_MOVEMENT_PRODUCT_SELECTION_DATA_DEF = makeObjectDef<StockMovementProductSelectionData>({
  startLocation: stringField("Starting Location").required(),
  destinationLocation: stringField("Destination Location").required(),
  products: arrayField<ProductRow>("Products", objectField("Product", PRODUCT_ROW_DEF).required()).required(),
});

export const STOCK_MOVEMENT_PRODUCT_SELECTION_FIELD_DEF = objectField(
  "Product Selection",
  STOCK_MOVEMENT_PRODUCT_SELECTION_DATA_DEF,
).required();

const COLLECTION_DATA_DEF = makeObjectDef<StockMovementCollection>({
  carrierName: stringField("Carrier Name").required(),
  contactName: stringField("Contact Name").required(),
  contactEmail: stringField("Contact Email").required(),
  contactNumber: stringField("Contact Number").required(),
  vehicleRegistration: stringField("Vehicle Registration").nullable(),
  collectionDate: localDateField("Collection Date", {
    range: {
      min: formatDateToLocalDate(TODAY),
    },
  }).required(),
  collectionTime: localTimeField("Collection Time").required(),
  deliveryDate: localDateField("Delivery Date", {
    range: {
      min: formatDateToLocalDate(TODAY),
    },
  }).required(),
  movementGuaranteeNumber: stringField("Movement Guarantee Number").nullable(),
});

export const DELIVERY_DATA_DEF = makeObjectDef<StockMovementDelivery>({
  carrier: stringField("Carrier").required(),
  deliveryDate: localDateField("Delivery Date", {
    range: {
      min: formatDateToLocalDate(TODAY),
    },
  }).required(),
  deliveryTimeEarliest: localTimeField("Earliest Delivery Time").required(),
  deliveryTimeLatest: localTimeField("Latest Delivery Time").required(),
  deliveryInstructions: stringField("Delivery Instructions").nullable(),
});

export const DELIVERY_METHOD_DATA_DEF = makeObjectDef<DeliveryDetailsStepData>({
  stockMovementDeliveryOption: unionField<DeliveryDetailsStepData["stockMovementDeliveryOption"]>("Delivery Option", {
    collectionByOrg: objectField("OrganisedCollection", COLLECTION_DATA_DEF).required(),
    organisedDelivery: objectField("OrganisedDelivery", DELIVERY_DATA_DEF).required(),
  }).required(),
});
export const DELIVERY_METHOD_FIELD_DEF = objectField("Delivery Details", DELIVERY_METHOD_DATA_DEF).required();

const CONFIRMATION_DATA_DEF = makeObjectDef<ConfirmationStepData>({});
export const CONFIRMATION_FIELD_DEF = objectField("Confirmation", CONFIRMATION_DATA_DEF).required();
