import React from "react";
import { BaseWizardCard } from "./base-wizard-card";
import { DbKeySelector, DbKeySelectorProps } from "../db-key-selector";
import { StorageLocation } from "adl-gen/ferovinum/app/db";

export function StartingStorageLocationCard<T extends object>(
  props: Omit<DbKeySelectorProps<T, StorageLocation>, "label">,
) {
  return (
    <BaseWizardCard
      title="Starting Storage Location"
      subtitle="Please select the storage location where your products are currently being held."
      inputElement={<DbKeySelector {...props} label="Storage Location" helperText="Can't find the warehouse?" />}
    />
  );
}
