import React, { FC, useMemo } from "react";
import { Alert, Box, CircularProgress } from "@mui/material";
import { isErrored, isLoading, LoadingValue } from "utils/utility-types";
import { LoadingBackdrop } from "./loading-backdrop";

export interface LoaderProps {
  loadingStates: LoadingValue<unknown>[];
  fullScreen?: boolean;
  errorMessage?: string;
  children?: React.ReactNode;
}

const getDefaultErrorText = (states: LoadingValue<unknown>[]) => {
  return (
    states
      .filter(isErrored)
      ?.map(s => s.error.message)
      ?.join(", ") ?? "Error while loading"
  );
};

/// A loader component that renders a spinner while loading or an error message if any of the states the component depends on has errored
export const Loader: FC<LoaderProps> = ({ loadingStates, fullScreen = false, errorMessage, children }) => {
  const loading = useMemo(() => loadingStates.some(isLoading), [loadingStates]);
  const errored = useMemo(() => loadingStates.some(isErrored), [loadingStates]);

  /// error
  if (errored) {
    return <Alert severity={"error"}>{errorMessage ?? getDefaultErrorText(loadingStates)}</Alert>;
  }
  return (
    <Loading loading={loading} fullScreen={fullScreen}>
      {children}
    </Loading>
  );
};

export const Loading: FC<{ loading: boolean; fullScreen?: boolean }> = ({ loading, fullScreen, children }) => {
  return fullScreen ? (
    <>
      <LoadingBackdrop open={loading} />
      {children}
    </>
  ) : (
    <>
      {loading ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </>
  );
};
