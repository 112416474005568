import React, { useCallback, useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionSummary,
  accordionSummaryClasses,
  Card,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Product, ProductDate, ProductionOrderStatus, UnitType, VesselSize } from "adl-gen/ferovinum/app/db";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { ArrowDropDown } from "@mui/icons-material";
import { ProductionOrderTaskRunView } from "adl-gen/ferovinum/app/api";
import { formatDate } from "utils/date-utils";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { DbKey } from "adl-gen/common/db";
import { ProductDetails } from "../../../../models/product";
import { InfoField } from "components/widgets/info-field/info-field";
import { InfoFieldProps } from "components/widgets/info-field/info-field";
import { vesselSizeMetricForUnitType, unitsLabelForUnitType } from "utils/model-utils";
import { assertNotUndefined } from "utils/hx/util/types";
import { titleCase } from "utils/conversion-utils";

export interface FinishingServiceSummaryCardProps {
  serviceName: string;
  targetQuantity: number;
  budgetQuantity?: number;
  sourceProduct: ProductDetails;
  finishingProduct: Product;
  productionOrderTaskRuns?: ProductionOrderTaskRunView[];
  children?: React.ReactNode;
  additionalHeaderLabels?: InfoFieldProps[];
  latestStatus: ProductionOrderStatus | null;
}

export const FinishingServiceSummaryCard = ({
  serviceName,
  targetQuantity,
  budgetQuantity,
  sourceProduct,
  finishingProduct,
  productionOrderTaskRuns,
  children,
  additionalHeaderLabels,
  latestStatus,
}: FinishingServiceSummaryCardProps) => {
  const displayProgress = latestStatus === "accepted" && productionOrderTaskRuns !== undefined;
  const hasProductionRuns = productionOrderTaskRuns !== undefined && productionOrderTaskRuns.length > 0;

  const getQuantityLabel = useCallback(
    (displayQty: number, calculation: (p: ProductionOrderTaskRunView[]) => number): string => {
      const totalQuantityStr = `${displayQty} ${unitsLabelForUnitType(finishingProduct.unitType)}`;

      if (displayProgress) {
        const calculatedQty = calculation(assertNotUndefined(productionOrderTaskRuns));
        return `${calculatedQty} / ${totalQuantityStr}`;
      }

      return totalQuantityStr;
    },
    [displayProgress, finishingProduct.unitType, productionOrderTaskRuns],
  );

  return (
    <Card>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          p={3}
          sx={{ backgroundColor: "common.grey3" }}>
          <InfoField label="Service" value={titleCase(serviceName)} sx={{ flex: "50%" }} />
          {additionalHeaderLabels && additionalHeaderLabels.map(label => <InfoField key={label.label} {...label} />)}
        </Stack>
        <Stack p={3} direction="row" alignItems="center" justifyContent="space-between">
          <ProductionRunProductSummary
            {...sourceProduct}
            quantityLabel={
              budgetQuantity
                ? {
                    label: displayProgress ? "Quantity used" : "Maximum input quantity",
                    value: getQuantityLabel(budgetQuantity, calculateQuantityUsed),
                  }
                : undefined
            }
          />
          <ArrowRightAltIcon sx={{ fontSize: 60, color: "common.grey4" }} />
          <ProductionRunProductSummary
            {...finishingProduct}
            quantityLabel={
              targetQuantity
                ? {
                    label: displayProgress ? "Quantity produced" : "Quantity to produce",
                    value: getQuantityLabel(targetQuantity, calculateQuantityProduced),
                  }
                : undefined
            }
          />
        </Stack>
        {children}
        {hasProductionRuns && <ProductionRuns productionOrderTaskRuns={productionOrderTaskRuns ?? []} />}
      </Stack>
    </Card>
  );
};

interface ProductionRunProductSummaryProps {
  code: string;
  productDate: ProductDate;
  name: string;
  producerName: string;
  vesselSize: VesselSize | null;
  unitType: UnitType;
  quantityLabel?: InfoFieldProps;
}
const ProductionRunProductSummary = ({
  code,
  productDate,
  name,
  producerName,
  vesselSize,
  unitType,
  quantityLabel,
}: ProductionRunProductSummaryProps) => {
  const year = productDate.kind === "vintageYear" ? productDate.value : undefined;

  return (
    <Paper elevation={0} sx={{ backgroundColor: "common.grey3", maxWidth: 450 }}>
      <Grid container>
        <Grid item xs={quantityLabel ? 8 : 12} sx={{ p: 2 }}>
          <Typography variant="body2" color="common.grey6">
            {code}
          </Typography>
          <Typography variant="body1">{`${year ? `${year} | ` : ""} ${name}`}</Typography>
          <Typography variant="body2" color="common.grey7">
            {`Produced by ${producerName}`}
          </Typography>
          {vesselSize && (
            <Typography variant="body2" color="common.grey7">
              {`Unit size: ${vesselSize.value} ${vesselSizeMetricForUnitType(unitType)}`}
            </Typography>
          )}
        </Grid>
        {quantityLabel && (
          <Grid item xs={4}>
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                backgroundColor: "common.grey1",
                height: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
              <InfoField {...quantityLabel} />
            </Paper>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export function calculateQuantityUsed(productionOrderTaskRuns: ProductionOrderTaskRunView[]) {
  return productionOrderTaskRuns.reduce(
    (sum, run) =>
      sum + run.resultingProductItem.quantity.value + run.productLosses.reduce((s, l) => s + l.quantity.value, 0),
    0,
  );
}

function calculateQuantityProduced(productionOrderTaskRuns: ProductionOrderTaskRunView[]) {
  return productionOrderTaskRuns.reduce((sum, run) => sum + run.resultingProductItem.quantity.value, 0);
}

function calculateProductLosses(productionOrderTaskRuns: ProductionOrderTaskRunView[]): ProductionRunRowProps[] {
  return productionOrderTaskRuns.map(taskRun => {
    const productLosses: Map<DbKey<Product>, number> = taskRun.productLosses.reduce((m, p) => {
      m.set(p.product.id, p.quantity.value);
      return m;
    }, new Map<DbKey<Product>, number>());
    //Note: we only support product task run with a single source item
    const sourceProduct = taskRun.sourceProductItems[0];

    // Note: Losses are attached to either the source or the result, based on the billing basis of the storage location
    const isOrderedBillingBasis = productLosses.get(sourceProduct.product.id) ? true : false;

    // Ordered billing basis means that the losses are attached to the source product.
    const lossesQuantity = isOrderedBillingBasis
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        productLosses.get(sourceProduct.product.id)!
      : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        productLosses.get(taskRun.resultingProductItem.product.id)!;

    return {
      sourceQuantity: isOrderedBillingBasis
        ? sourceProduct.quantity.value + lossesQuantity
        : sourceProduct.quantity.value,
      // Resulting product item always includes the losses so no calculation is necessary
      resultingQuantity: taskRun.resultingProductItem.quantity.value,
      lossesQuantity,
      date: taskRun.completedAt,
    };
  });
}

interface ProductionRunsProps {
  productionOrderTaskRuns: ProductionOrderTaskRunView[];
}

const ProductionRuns = ({ productionOrderTaskRuns }: ProductionRunsProps) => {
  const runs = useMemo(() => calculateProductLosses(productionOrderTaskRuns), [productionOrderTaskRuns]);
  return (
    <Stack>
      <ProductionRunsAccordion disableGutters defaultExpanded>
        <AccordionSummary expandIcon={<ArrowDropDown />}>
          <Typography variant="subtitle1" color="common.grey6">
            Production runs
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransparentTable>
            <TableHead>
              <TableRow>
                <RunTableCell>Completion date</RunTableCell>
                <RunTableCell align="right">{"Quantity used"}</RunTableCell>
                <RunTableCell align="right">{"Quantity produced"}</RunTableCell>
                <RunTableCell align="right">{"Losses"}</RunTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {runs.map((run, idx) => (
                <ProductionRunRow key={`${run.date}-${idx}`} {...run} />
              ))}
            </TableBody>
          </TransparentTable>
        </AccordionDetails>
      </ProductionRunsAccordion>
    </Stack>
  );
};

interface ProductionRunRowProps {
  sourceQuantity: number;
  resultingQuantity: number;
  lossesQuantity: number;
  date: string;
}
const ProductionRunRow = ({ sourceQuantity, resultingQuantity, lossesQuantity, date }: ProductionRunRowProps) => {
  return (
    <TableRow>
      <RunTableCell>{formatDate(new Date(date))}</RunTableCell>
      <RunTableCell align="right">{sourceQuantity}</RunTableCell>
      <RunTableCell align="right">{resultingQuantity}</RunTableCell>
      <RunTableCell align="right">{lossesQuantity}</RunTableCell>
    </TableRow>
  );
};

const ProductionRunsAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.common.grey3,
  [`& .${accordionSummaryClasses.root}`]: {
    padding: 0,
  },
  [`& .${accordionSummaryClasses.content}`]: {
    flexGrow: 0,
  },
  [`& .${accordionDetailsClasses.root}`]: {
    padding: 0,
    borderTop: "1px solid",
    borderColor: theme.palette.common.grey4,
  },
}));

const TransparentTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.common.grey3,
  [`& .${tableCellClasses.head}`]: {
    backgroundColor: "inherit",
  },
}));

const RunTableCell = styled(TableCell)(({ theme }) => ({
  borderColor: theme.palette.common.grey4,
}));
