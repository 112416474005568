import {
  Alert,
  Box,
  Button,
  Divider,
  Fab,
  FabProps,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { StockAdjustmentFlowStepper } from "../../../../widgets/flow-stepper/stock-adjustment-flow-stepper";
import { NumberOfUnits, Product, ProductId, snTopLevelUnitType, TopLevelUnitType } from "adl-gen/ferovinum/app/db";
import { WithDbId } from "adl-gen/common/db";
import { holdingHasRotationNumber } from "./storage-loc-stock-adjustment-setup-page";
import { StockTypeToggle } from "../../../../widgets/stock-type-toggle/stock-type-toggle";
import { Map as AdlMap, MapEntry } from "adl-gen/sys/types";
import { ClientSidePaginatedTable } from "../../../../widgets/common/client-side-paginated-table/client-side-paginated-table";
import { unitsLabelForUnitType } from "utils/model-utils";
import { StockAdjustment, StorageLocationProductHolding } from "adl-gen/ferovinum/app/api";
import { OrganisationProductSummary } from "../../../../widgets/organisation-product-summary/organisation-product-summary";
import AddIcon from "@mui/icons-material/Add";
import { assertNotUndefined } from "utils/hx/util/types";
import { UnitsInput } from "components/widgets/inputs/units-input/units-input";
import { styled } from "@mui/material/styles";
import { array, lazy, number, object, string } from "yup";
import { FieldArray, FormikProps, FormikProvider, useFormik } from "formik";
import { Link } from "components/widgets/link/link";
import { getFormLabelForUnionField, getTopLevelForUnitType } from "utils/adl-utils";
import { useConfirmationDialog } from "components/context/global-dialog/use-dialog";
import { SearchInput } from "components/widgets/inputs/search-input/search-input";

interface SelectedProduct {
  product: WithDbId<Product>;
  adjustments: Adjustment[];
}
interface Adjustment {
  totalUnitsAvailable: NumberOfUnits;
  rotationNumber: string | null;
  unitsToAdjust: string;
}
export interface SelectedHoldingAdjustmentFormValues {
  selectedProducts: SelectedProduct[];
}

const validationSchema = (variant: StockAdjustment["kind"]) => {
  let unitsToAdjustSchema = number().positive("Cannot be less than 1").required("This is a required field");

  const adjustmentSchema = lazy((adjustment: Adjustment) => {
    const totalQtyAvailable = adjustment.totalUnitsAvailable.value;
    if (variant === "negativeStockAdjustment") {
      unitsToAdjustSchema = unitsToAdjustSchema.max(totalQtyAvailable, `Exceeds max quantity (${totalQtyAvailable})`);
    }
    return object().shape({
      rotationNumber: string().nullable(),
      unitsToAdjust: unitsToAdjustSchema,
    });
  });

  const productSchema = object().shape({
    product: object().required(),
    adjustments: array()
      // Note: (typescript bug): https://github.com/jquense/yup/issues/1283
      // @ts-ignore
      .of(adjustmentSchema)
      .min(1)
      .required(),
  });

  return object().shape({
    selectedProducts: array()
      // Note: (typescript bug): https://github.com/jquense/yup/issues/1283
      // @ts-ignore
      .of(productSchema)
      .min(1)
      .required(),
  });
};

export interface StorageLocationStockAdjustmentSetupPageViewProps {
  /// Stock data by top level unit type (cans, bottles, cask) and by product
  variant: StockAdjustment["kind"];
  productHoldings: Map<TopLevelUnitType, AdlMap<WithDbId<Product>, StorageLocationProductHolding[]>>;
  availableStockTypes: Set<TopLevelUnitType>;
  onSubmit: (formValues: SelectedHoldingAdjustmentFormValues, topLevelUnitType: TopLevelUnitType) => Promise<void>;
  onProductSearch(searchTerm: string): void;
}

export const StorageLocationStockAdjustmentSetupPageView = ({
  variant,
  productHoldings,
  availableStockTypes,
  onSubmit,
  onProductSearch,
}: StorageLocationStockAdjustmentSetupPageViewProps) => {
  const hasAnyStock = useMemo<boolean>(() => availableStockTypes.size > 0, [availableStockTypes.size]);
  const hasMoreThanOneTypeOfStock = useMemo<boolean>(() => availableStockTypes.size > 1, [availableStockTypes]);
  const [stockDisplayed, setStockDisplayed] = useState<TopLevelUnitType | undefined>(
    availableStockTypes.size > 0 ? [...availableStockTypes].sort()[0] : undefined,
  );

  useEffect(() => {
    const firstAvailableStockType = [...availableStockTypes].sort()[0];
    setStockDisplayed(firstAvailableStockType);
  }, [availableStockTypes]);

  const form = useFormik<SelectedHoldingAdjustmentFormValues>({
    initialValues: { selectedProducts: [] },
    validationSchema: validationSchema(variant),
    onSubmit: async values => await onSubmit(values, assertNotUndefined(stockDisplayed)),
    validateOnMount: true,
  });

  const onAddAdjustment = useCallback(
    (
      product: WithDbId<Product>,
      rotationNumber: string | null,
      unitsToAdjust: number,
      totalUnitsAvailable: NumberOfUnits,
    ) => {
      const adjustment: Adjustment = {
        unitsToAdjust: unitsToAdjust.toString(),
        totalUnitsAvailable,
        rotationNumber,
      };
      const existingSelection = form.values.selectedProducts.find(p => p.product.id === product.id);
      const adjustments = existingSelection ? [...existingSelection.adjustments, adjustment] : [adjustment];
      if (!existingSelection) {
        form.setFieldValue("selectedProducts", [...form.values.selectedProducts, { product, adjustments }]);
      } else {
        const index = form.values.selectedProducts.findIndex(p => p.product.id === product.id);
        const newSelection = [...form.values.selectedProducts];
        newSelection.splice(index, 1, { product, adjustments });
        form.setFieldValue("selectedProducts", newSelection);
      }
    },
    [form],
  );
  const resetSelectedProducts = useCallback(async () => form.setFieldValue("selectedProducts", []), [form]);

  const selectedProductsMap: Map<ProductId, SelectedProduct> = useMemo(() => {
    return form.values.selectedProducts.reduce((m, selectedProduct) => {
      m.set(selectedProduct.product.id, selectedProduct);
      return m;
    }, new Map<ProductId, SelectedProduct>());
  }, [form.values.selectedProducts]);

  // Filters out the holdings that are already in the cart
  const notInCartHoldings: Map<
    TopLevelUnitType,
    AdlMap<WithDbId<Product>, StorageLocationProductHolding[]>
  > = useMemo(() => {
    const result = new Map<TopLevelUnitType, AdlMap<WithDbId<Product>, StorageLocationProductHolding[]>>();
    for (const topLevelUnitType of availableStockTypes) {
      const stockByProduct = assertNotUndefined(productHoldings.get(topLevelUnitType));
      const remainingStockHoldingsByProduct: AdlMap<WithDbId<Product>, StorageLocationProductHolding[]> = [];
      // For each entry in the stock by product map, filter out the product holdings that are already in the cart
      for (const [idx, entry] of stockByProduct.entries()) {
        const product = entry.key;
        const productHoldings = entry.value;
        if (!selectedProductsMap.has(product.id)) {
          remainingStockHoldingsByProduct.push({ key: entry.key, value: productHoldings });
        } else {
          const selectedProduct = assertNotUndefined(selectedProductsMap.get(product.id));
          const remainingStockHoldings = productHoldings.filter(productHolding => {
            // Do not display if the non-rotation number holdings are already in the cart
            if (!holdingHasRotationNumber(productHolding)) {
              return !selectedProduct.adjustments.some(adjustment => adjustment.rotationNumber === null);
            }
            return !selectedProduct.adjustments.some(
              adjustment => adjustment.rotationNumber === productHolding.value.rotationNumber,
            );
          });
          if (remainingStockHoldings.length > 0) {
            remainingStockHoldingsByProduct.splice(idx, 1, { key: product, value: remainingStockHoldings });
          } else {
            // Remove the product from the list if there are no remaining holdings
            remainingStockHoldingsByProduct.splice(idx, 1);
          }
        }
      }
      result.set(topLevelUnitType, remainingStockHoldingsByProduct);
    }
    return result;
  }, [availableStockTypes, productHoldings, selectedProductsMap]);

  const { showConfirmationDialog } = useConfirmationDialog();
  const onChangeStockType = useCallback(
    async (newStockType?: TopLevelUnitType) => {
      if (!newStockType) {
        return;
      }

      if (!form.dirty || stockDisplayed === undefined) {
        setStockDisplayed(newStockType);
        return;
      }
      const userResponse = await showConfirmationDialog({
        title: `Are you sure you want to change this from '${getFormLabelForUnionField(
          snTopLevelUnitType,
          stockDisplayed,
        )}' to '${getFormLabelForUnionField(snTopLevelUnitType, newStockType)}'?`,
        body: "You will lose your current product selections.",
      });
      if (userResponse.kind === "ok") {
        await resetSelectedProducts();
        setStockDisplayed(newStockType);
      }
    },
    [form.dirty, resetSelectedProducts, showConfirmationDialog, stockDisplayed],
  );

  return (
    <PortalPageContent
      header={<LocProductSelectionHeader />}
      sidePanel={hasAnyStock ? <StockAdjustmentSideCart form={form} variant={variant} /> : undefined}>
      <Stack spacing={3}>
        {hasAnyStock ? (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {hasMoreThanOneTypeOfStock ? (
                <StockTypeToggle
                  topLevelUnitType={stockDisplayed}
                  onChange={onChangeStockType}
                  availableTypes={availableStockTypes}
                />
              ) : (
                <Box />
              )}
              <SearchInput label="Product search" onChange={onProductSearch} />
            </Stack>
            <Stack spacing={8}>
              {stockDisplayed && (
                <StockAdjustmentProductTable
                  variant={variant}
                  rows={assertNotUndefined(notInCartHoldings.get(stockDisplayed))}
                  topLevelUnitType={stockDisplayed}
                  onAddAdjustment={onAddAdjustment}
                />
              )}
            </Stack>
          </>
        ) : (
          <Alert severity="info">You currently have no available products held on the platform.</Alert>
        )}
      </Stack>
    </PortalPageContent>
  );
};
const LocProductSelectionHeader = () => {
  return (
    <PortalPageContentHeader
      variant="split"
      title="Input order details"
      subtitles={[{ text: "Select products and input the stock adjustment details." }]}
      right={<StockAdjustmentFlowStepper activeStep={0} />}
    />
  );
};

interface StockAdjustmentSideCartProps {
  form: FormikProps<SelectedHoldingAdjustmentFormValues>;
  variant: StockAdjustment["kind"];
}
const StockAdjustmentSideCart = ({ form, variant }: StockAdjustmentSideCartProps) => {
  const onUpdateSelectedAdjustment = useCallback(
    (productId: string, rotationNumber: string | null, unitsToAdjust: string) => {
      const productIdx = form.values.selectedProducts.findIndex(p => p.product.id === productId);
      if (productIdx > -1) {
        const newSelectedProducts = [...form.values.selectedProducts];
        const newProduct = { ...form.values.selectedProducts[productIdx] };
        const newAdjustments = [...newProduct.adjustments];
        const adjustmentIdx = newAdjustments.findIndex(a => a.rotationNumber === rotationNumber);
        newAdjustments.splice(adjustmentIdx, 1, { ...newAdjustments[adjustmentIdx], unitsToAdjust });
        newProduct.adjustments = newAdjustments;
        newSelectedProducts.splice(productIdx, 1, newProduct);
        form.setFieldValue("selectedProducts", newSelectedProducts);
      }
    },
    [form],
  );

  return (
    <Stack spacing={2} paddingBottom={2}>
      <Typography variant="caption" color="common.grey6">
        Selected products
      </Typography>
      <Divider />
      {form.values.selectedProducts.length === 0 ? (
        <Typography variant="caption" color="common.grey7">
          You have not selected any products.
        </Typography>
      ) : (
        <FormikProvider value={form}>
          <FieldArray
            name="selectedProducts"
            render={({ remove: removeProduct }) => (
              <Stack spacing={2} divider={<Divider flexItem />}>
                {form.values.selectedProducts.map((p, productIndex) => {
                  const quantityLabel = unitsLabelForUnitType(getTopLevelForUnitType(p.product.value.unitType));
                  return (
                    <Stack spacing={2} key={p.product.id}>
                      <OrganisationProductSummary {...p.product.value} />
                      <FieldArray
                        name={`selectedProducts.${productIndex}.adjustments`}
                        render={({ remove: removeAdjustment }) => (
                          <>
                            {form.values.selectedProducts[productIndex].adjustments.map((a, adjustmentIndex) => (
                              <React.Fragment key={`adjustment-${p.product.id}-${adjustmentIndex}`}>
                                {a.rotationNumber !== null && (
                                  <Box>
                                    <Typography variant="body2" color="common.grey6">
                                      Rotation number
                                    </Typography>
                                    <Typography variant="subtitle1" color="common.grey7">
                                      {a.rotationNumber}
                                    </Typography>
                                  </Box>
                                )}
                                <UnitsInput
                                  unitType={p.product.value.unitType}
                                  hideUnitsLabel
                                  label={`${
                                    variant === "positiveStockAdjustment" ? "Extra" : "Lost"
                                  } quantity (${quantityLabel})`}
                                  size="small"
                                  variant="outlined"
                                  value={a.unitsToAdjust}
                                  onChange={e => {
                                    const n = e.target.value;
                                    onUpdateSelectedAdjustment(p.product.id, a.rotationNumber, n);
                                  }}
                                  error={Boolean(
                                    form.errors.selectedProducts && form.errors.selectedProducts[productIndex],
                                  )}
                                  helperText={
                                    // @ts-ignore
                                    form.errors.selectedProducts?.[productIndex]?.["adjustments"]?.[0].unitsToAdjust
                                  }
                                />
                                <Link
                                  onClick={() => {
                                    const nAdjustments = form.values.selectedProducts[productIndex].adjustments.length;
                                    removeAdjustment(adjustmentIndex);
                                    if (nAdjustments === 1) {
                                      removeProduct(productIndex);
                                    }
                                  }}>
                                  Remove
                                </Link>
                              </React.Fragment>
                            ))}
                          </>
                        )}
                      />
                    </Stack>
                  );
                })}
              </Stack>
            )}
          />
        </FormikProvider>
      )}
      <Stack
        alignItems="flex-end"
        position="fixed"
        bottom={theme => theme.spacing(2)}
        right={theme => theme.spacing(2)}>
        <Button onClick={form.submitForm} disabled={!form.isValid}>
          Next
        </Button>
      </Stack>
    </Stack>
  );
};

type OnAddAdjustment = (
  product: WithDbId<Product>,
  rotationNumber: string | null,
  unitsToAdjust: number,
  totalUnitsAvailable: NumberOfUnits,
) => void;
interface StockAdjustmentProductTableProps {
  variant: StockAdjustment["kind"];
  rows: AdlMap<WithDbId<Product>, StorageLocationProductHolding[]>;
  topLevelUnitType: TopLevelUnitType;
  onAddAdjustment: OnAddAdjustment;
}
const StockAdjustmentProductTable = ({
  variant,
  rows,
  topLevelUnitType,
  onAddAdjustment,
}: StockAdjustmentProductTableProps) => {
  const quantityLabel = unitsLabelForUnitType(topLevelUnitType);
  const stockHasRotationNumbers = useMemo<boolean>(() => {
    return rows.some(holdings => holdings.value.some(holdingHasRotationNumber));
  }, [rows]);

  return (
    <ClientSidePaginatedTable
      Header={
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            {stockHasRotationNumbers && <TableCell>Rotation number</TableCell>}
            <TableCell align="right">Available</TableCell>
            <TableCell align="right">{`${
              variant === "positiveStockAdjustment" ? "Extra" : "Lost"
            } quantity (${quantityLabel})`}</TableCell>
            <TableCell align="center" />
          </TableRow>
        </TableHead>
      }
      Body={
        <StockAdjustmentProductTableBody
          stockHasRotationNumbers={stockHasRotationNumbers}
          rows={rows}
          onAddAdjustment={onAddAdjustment}
          variant={variant}
        />
      }
      rows={rows}
    />
  );
};

interface StockAdjustmentProductTableBodyProps {
  rows: MapEntry<WithDbId<Product>, StorageLocationProductHolding[]>[];
  stockHasRotationNumbers: boolean;
  onAddAdjustment: OnAddAdjustment;
  variant: StockAdjustment["kind"];
}
const StockAdjustmentProductTableBody = ({
  rows,
  stockHasRotationNumbers,
  onAddAdjustment,
  variant,
}: StockAdjustmentProductTableBodyProps) => {
  return (
    <TableBody>
      {rows.length === 0 && <NoResultsRow colSpan={stockHasRotationNumbers ? 4 : 3} />}
      {rows.map(row => (
        <StockAdjustmentProductTableRow
          key={row.key.id}
          row={row}
          stockHasRotationNumbers={stockHasRotationNumbers}
          onAddAdjustment={onAddAdjustment}
          variant={variant}
        />
      ))}
    </TableBody>
  );
};

interface StockAdjustmentProductTableRowProps {
  row: MapEntry<WithDbId<Product>, StorageLocationProductHolding[]>;
  stockHasRotationNumbers: boolean;
  onAddAdjustment: OnAddAdjustment;
  variant: StockAdjustment["kind"];
}
const StockAdjustmentProductTableRow = ({
  row,
  stockHasRotationNumbers,
  onAddAdjustment,
  variant,
}: StockAdjustmentProductTableRowProps) => {
  return (
    <TableRow>
      <TableCell>
        <OrganisationProductSummary
          {...row.key.value}
          disabled={row.value.length === 1 && row.value[0].value.multipleClients}
        />
      </TableCell>
      <StockHoldingCells
        row={row}
        stockHasRotationNumbers={stockHasRotationNumbers}
        onAddAdjustment={onAddAdjustment}
        variant={variant}
      />
    </TableRow>
  );
};

const NoResultsRow = ({ colSpan }: { colSpan: number }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Alert severity="info">No products</Alert>
      </TableCell>
    </TableRow>
  );
};

const StockHoldingCells = ({
  row,
  stockHasRotationNumbers,
  onAddAdjustment,
  variant,
}: StockAdjustmentProductTableRowProps) => {
  const [adjustmentValues, setAdjustmentValues] = useState<string[]>(row.value.map(() => ""));
  const [unitsInputError, setUnitsInputError] = useState<boolean[]>(row.value.map(() => false));

  const handleChangeUnitsInput = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number) => {
      const n = e.target.value;
      setAdjustmentValues(value => {
        const newValue = [...value];
        newValue.splice(idx, 1, n.toString());
        return newValue;
      });

      const newUnitsError = [...unitsInputError];
      newUnitsError.splice(
        idx,
        1,
        variant === "negativeStockAdjustment" && Number(n) > row.value[idx].value.numberOfUnits.value,
      );
      setUnitsInputError(newUnitsError);
    },
    [row.value, unitsInputError, variant],
  );

  return (
    <>
      {stockHasRotationNumbers && (
        <StockHoldingCell>
          <PaddedStack divider={<Divider flexItem />}>
            {row.value.map((holding, idx) => {
              const rotationNumber = holdingHasRotationNumber(holding) ? holding.value.rotationNumber : "";
              return (
                <Stack key={`rot-${rotationNumber}-${idx}`} justifyContent="center">
                  <Typography noWrap>{rotationNumber}</Typography>
                </Stack>
              );
            })}
          </PaddedStack>
        </StockHoldingCell>
      )}
      <StockHoldingCell align="right">
        <PaddedStack divider={<Divider flexItem />}>
          {row.value.map((holding, idx) => {
            const availableQty = holding.value.numberOfUnits.value;
            return (
              <Stack key={`${row.key.id}-${idx}-quantity`} justifyContent="center">
                <Typography noWrap>{availableQty}</Typography>
              </Stack>
            );
          })}
        </PaddedStack>
      </StockHoldingCell>
      <StockHoldingCell align="right">
        <PaddedStack divider={<Divider flexItem />} alignItems="flex-end">
          {row.value.map((holding, idx) => {
            return (
              <UnitsInput
                key={`${row.key.id}-${idx}`}
                hideUnitsLabel
                unitType={row.key.value.unitType}
                size="small"
                disabled={holding.value.multipleClients}
                variant="outlined"
                value={adjustmentValues[idx]}
                onChange={e => handleChangeUnitsInput(e, idx)}
                sx={{ backgroundColor: theme => theme.palette.common.white }}
                error={unitsInputError[idx]}
                helperText={unitsInputError[idx] && "Exceeds available quantity"}
              />
            );
          })}
        </PaddedStack>
      </StockHoldingCell>
      <StockHoldingCell align="center">
        <PaddedStack divider={<Divider flexItem />}>
          {row.value.map((holding, idx) => {
            const rotationNumber = holdingHasRotationNumber(holding) ? holding.value.rotationNumber : null;
            return (
              <Stack key={`add-${rotationNumber}-${idx}`} alignItems="center">
                <AddToCartButton
                  disabled={holding.value.multipleClients || !adjustmentValues[idx] || unitsInputError[idx]}
                  onClick={() => {
                    onAddAdjustment(
                      row.key,
                      rotationNumber,
                      Number(adjustmentValues[idx]),
                      holding.value.numberOfUnits,
                    );
                    setAdjustmentValues(value => {
                      const newValue = [...value];
                      newValue.splice(idx, 1, "");
                      return newValue;
                    });
                  }}
                />
              </Stack>
            );
          })}
        </PaddedStack>
      </StockHoldingCell>
    </>
  );
};

const StockHoldingCell = styled(TableCell)(({}) => ({
  padding: 0,
}));

const PaddedStack = styled(Stack)(({ theme }) => ({
  "> *:not(hr)": { padding: theme.spacing(2), minHeight: 72 },
}));

//TODO(Berto): Move this into a button widget that will also get used in the organisation repurchase stock page view
// and stop using Fab for this (as it is not a floating action button)
const AddToCartButton = (props: FabProps) => {
  return (
    <Tooltip
      title={
        props.disabled
          ? "Please contact Ferovinum to record a stock adjustment for this product."
          : "Add to selected products"
      }
      placement="top"
      arrow
      describeChild>
      <Box>
        <Fab
          color="secondary"
          size="small"
          sx={{
            "&.Mui-disabled": { backgroundColor: "transparent" },
          }}
          {...props}>
          <AddIcon />
        </Fab>
      </Box>
    </Tooltip>
  );
};
