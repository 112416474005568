import React from "react";
import { DeliveryStatus } from "adl-gen/ferovinum/app/db";
import { formatDate, formatDateToLocalTime, parseLocalTimeToDate } from "utils/date-utils";
import { Card, CardText, stackStrings } from "components/library/widgets/card/card";

export const DeliveryUpdatesCard = ({ deliveryStatuses }: { deliveryStatuses: DeliveryStatus[] }) => {
  const entry0 = deliveryStatuses[0];
  const statusValid =
    entry0 && entry0.bookedDate && entry0.confirmedDepartedAt && entry0.confirmedDeliveredAt && entry0.status;
  return (
    <Card
      header={{ title: "Delivery Updates" }}
      body={statusValid ? <DeliveryDateTimeCard {...deliveryStatuses[0]} /> : "None"}
    />
  );
};

export const DeliveryDateTimeCard = ({
  status,
  bookedDate,
  originalEtaWindowFrom,
  originalEtaWindowTo,
  revisedEtaWindowFrom,
  revisedEtaWindowTo,
  confirmedDepartedAt,
  confirmedDeliveredAt,
}: DeliveryStatus) => {
  let statusCardTextContent;
  let dateTimeCardTextTitle;
  let dateTimeCardTextContent;
  if (confirmedDeliveredAt) {
    const deliveryDateTime = confirmedDeliveredAt.split("T");
    const deliveryDate = deliveryDateTime[0];
    const deliveryTime = deliveryDateTime[1];
    statusCardTextContent = "Delivered";
    dateTimeCardTextTitle = "Delivered At";
    dateTimeCardTextContent = stackStrings([
      formatDate(new Date(deliveryDate)),
      formatDateToLocalTime(parseLocalTimeToDate(deliveryTime)),
    ]);
  } else if (status === "delivered") {
    statusCardTextContent = "Delivered";
  } else {
    if (status === "deliveryPlanningHeld") {
      statusCardTextContent = "On Hold";
    } else if (status === "outForDelivery" || confirmedDepartedAt) {
      statusCardTextContent = "Out For Delivery";
    } else if (bookedDate) {
      statusCardTextContent = "Delivery Scheduled";
    } else {
      statusCardTextContent = "Scheduling Delivery";
    }
    let deliveryDate;
    if (bookedDate) {
      deliveryDate = bookedDate;
    }
    let deliveryTimeEarliest;
    let deliveryTimeLatest;
    if (revisedEtaWindowFrom && revisedEtaWindowTo) {
      deliveryTimeEarliest = revisedEtaWindowFrom;
      deliveryTimeLatest = revisedEtaWindowTo;
    } else if (originalEtaWindowFrom && originalEtaWindowTo) {
      deliveryTimeEarliest = originalEtaWindowFrom;
      deliveryTimeLatest = originalEtaWindowTo;
    }
    const formattedDate = deliveryDate && formatDate(new Date(deliveryDate));
    const formattedTime =
      deliveryTimeEarliest &&
      deliveryTimeLatest &&
      `${formatDateToLocalTime(parseLocalTimeToDate(deliveryTimeEarliest))} - ${formatDateToLocalTime(
        parseLocalTimeToDate(deliveryTimeLatest),
      )}`;
    dateTimeCardTextTitle = "Estimated Delivery Date & Time";
    dateTimeCardTextContent = stackStrings([formattedDate, formattedTime]);
  }
  return (
    <>
      <CardText title="Delivery Status" content={statusCardTextContent} lineNumbers={1} />
      {dateTimeCardTextTitle && dateTimeCardTextContent && (
        <CardText title={dateTimeCardTextTitle} content={dateTimeCardTextContent} lineNumbers={1} />
      )}
    </>
  );
};
