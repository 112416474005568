import { BigDecimal } from "adl-gen/common";
/** Collection of typescript helpers */

export type Extends<T, U extends T> = U;

/** Type guards (runtime checks) */
export const isString = (value: unknown): value is string => {
  return typeof value === "string";
};

export const isBigDecimal = (value: unknown): value is BigDecimal => {
  return typeof value === "string" && !isNaN(Number(value)) && value.trim() !== "";
};

export const isNumber = (value: unknown): value is number => {
  return typeof value === "number";
};

export const isValidNumber = (value: unknown): boolean => {
  return (isNumber(value) || isBigDecimal(value)) && !isNaN(Number(value));
};

export const isZeroNumber = (value: unknown): boolean => {
  return isValidNumber(value) && Number(value) === 0;
};

export const isPositiveNumber = (value: unknown): boolean => {
  return isValidNumber(value) && Number(value) > 0;
};

export const isPositiveOrZeroNumber = (value: unknown): boolean => {
  return isValidNumber(value) && Number(value) >= 0;
};

export const isNegativeNumber = (value: unknown): boolean => {
  return isValidNumber(value) && Number(value) < 0;
};

export const isStringArray = (value: unknown): value is string[] => {
  return Array.isArray(value) && value.every(isString);
};

export const isNotNull = <T>(arg: T): arg is Exclude<T, null> => {
  return arg !== null;
};

/** A noop function typescript compatible */
export const noop = () => {
  /** noop */
};
