import React from "react";
import { BaseNoteProps, StickyNote } from "../sticky-note";

export interface DeliveryContactStickyNoteProps extends Partial<BaseNoteProps> {
  deliveryContact: string | null;
  deliveryContactNumber: string | null;
  deliveryContactEmail: string | null;
  companyName?: string;
}
export const DeliveryContactStickyNote = ({
  title,
  action,
  deliveryContact,
  deliveryContactNumber,
  deliveryContactEmail,
  companyName,
}: DeliveryContactStickyNoteProps) => {
  const isNotAvailable = !deliveryContact && !deliveryContactEmail && !deliveryContactNumber && !companyName;
  let line1, line2, line3, line4: string | undefined;
  if (isNotAvailable) {
    line1 = "Contact details not available";
  } else if (companyName) {
    line1 = companyName;
    line2 = deliveryContact ?? undefined;
    line3 = deliveryContactEmail ?? undefined;
    line4 = deliveryContactNumber ?? undefined;
  } else {
    line1 = deliveryContact ?? "";
    line2 = deliveryContactEmail ?? undefined;
    line3 = deliveryContactNumber ?? undefined;
  }
  return (
    <StickyNote
      title={title ?? "Delivery contact"}
      line1={line1}
      line2={line2}
      line3={line3}
      line4={line4}
      action={action}
    />
  );
};
