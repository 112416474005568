import React from "react";
import { FlowStepper } from "components/library/widgets/flow-stepper";

export interface RepurchaseFlowStepperProps {
  activeStep: number;
}

export const RepurchaseFlowStepper = ({ activeStep }: RepurchaseFlowStepperProps) => {
  const steps = ["Order details", "Delivery options", "Confirmation"];

  return <FlowStepper steps={steps} activeStep={activeStep} />;
};
