import { LocalDate } from "adl-gen/common";
import { Schema } from "yup";
import { ObjectFields } from "./object-field-def";
import { UnionFieldDefMapping } from "./data-field-builder";
import { UnionType } from "../utility-types";

// Information relatint to each field that we might want to expose to the UI and other components
export type FieldMetaData = Readonly<
  | { kind: "string" }
  | { kind: "boolean" }
  | { kind: "array"; elementDef: DataFieldDef<unknown> }
  | { kind: "number"; range?: Readonly<{ min?: number; max?: number } | "positive" | "negative">; precision?: number }
  | { kind: "enum"; values: Readonly<string[]> }
  | { kind: "union"; defMapping: UnionFieldDefMapping<UnionType> }
  | { kind: "object"; fieldDefs: Readonly<ObjectFields<object>> }
  | { kind: "date"; range?: Readonly<{ min?: LocalDate; max?: LocalDate }> }
  | { kind: "partialDate" }
  | { kind: "time" }
  | { kind: "static" }
  | { kind: "any" }
>;

// A rapper around yup's Schema and a toString function, it represents a field in an object definition.
// It may be expanded to other information about the field, such as those used by UI components.
export class DataFieldDef<T> {
  // TODO Zhi: SIMPLIFY
  // Long term we want to move all non-trivial validation (those that involve other fields)
  // out of yup schemas into validation functions. This is a fairly involved process because
  // how we use it in UI components. Our starting point is to simplify CSV parsing and validation
  // and still maintain support for non-trivial schema validations for UI components.
  private readonly baseSchema: Schema<T>;
  private readonly schemaWithNonTrivialValidation?: Schema<T>;

  constructor(
    readonly label: string,
    baseSchema: Schema<T>,
    schemaWithNonTrivialValidation: Schema<T> | undefined,
    readonly toString: (v?: T) => string | undefined,
    readonly metaData: FieldMetaData,
    public readonly isRequired: boolean,
  ) {
    this.baseSchema = baseSchema.label(label);
    this.schemaWithNonTrivialValidation = schemaWithNonTrivialValidation?.label(label);
  }

  cast(value: unknown): T {
    // don't need the full schema to cast
    return this.baseSchema.cast(value);
  }

  safeCast(value: unknown): T | undefined {
    try {
      return this.cast(value);
    } catch {
      return undefined;
    }
  }

  baseValidate(value: unknown): T {
    return this.baseSchema.validateSync(value);
  }

  validate(value: unknown): T {
    return this.getSchema().validateSync(value);
  }

  isValid(value: unknown): value is T {
    return this.getSchema().isValidSync(value);
  }

  getBaseSchema(): Schema<T> {
    return this.baseSchema;
  }

  getSchema(): Schema<T> {
    return this.schemaWithNonTrivialValidation ?? this.baseSchema;
  }

  withUpdatedSchema(schemaUpdater: (schema: Schema<T>) => Schema<T>): DataFieldDef<T> {
    return new DataFieldDef(
      this.label,
      this.baseSchema,
      schemaUpdater(this.getSchema()),
      this.toString,
      this.metaData,
      this.isRequired,
    );
  }
}
