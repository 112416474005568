import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import { IdentityState, LoginState } from "../../../app/identity-state";

import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import { FerovinumLogo } from "components/logos/logos";
import { LinkBehavior } from "components/widgets/link/link";
import { isAdmin } from "utils/user-utils";
import { AppRoutes } from "../../../app/app-routes";
import { useAdminPortalRootRoute } from "../../../hooks/use-admin-portal-root-route";
import { useAdminUIRootRoute } from "../../../hooks/use-admin-ui-root-route";

export interface HeaderProps {
  loginState: LoginState;
  toggleDrawer: () => void;
}

export const HEADER_HEIGHT = 58;
export const Header = ({ loginState, toggleDrawer }: HeaderProps) => {
  return (
    <AppBar position="fixed">
      <Toolbar variant="dense" sx={{ minHeight: HEADER_HEIGHT }}>
        <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <IconButton href={AppRoutes.Index}>
            <FerovinumLogo color="white" />
          </IconButton>
        </Box>
        {loginState?.user !== undefined && (
          <>
            <HelpMenu />
            <UserMenu user={loginState.user} />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

interface UserMenuProps {
  user: IdentityState;
}

interface UserMenuItemProps {
  title: string;
  href: string;
  newTab?: boolean;
}

/** user actions menu */
const UserMenu = ({ user }: UserMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const appAdminHref = useAdminPortalRootRoute();
  const appAdminUIHref = useAdminUIRootRoute();
  const userProfile = user.profile;
  const items: UserMenuItemProps[] = [];
  if (isAdmin(userProfile)) {
    items.push({
      title: "Admin UI",
      href: appAdminUIHref,
      newTab: true,
    });
    items.push({
      title: "Admin Portal",
      href: appAdminHref,
      newTab: true,
    });
  }
  items.push({ title: "Logout", href: AppRoutes.Logout });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menu = {
    title: userProfile.fullname,
    items,
  };

  return (
    <>
      <IconButton color="inherit" aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
        {isAdmin(userProfile) ? <AdminPanelSettingsOutlinedIcon /> : <LogoutIcon />}
      </IconButton>
      <Menu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {menu.items.map(i => (
          <MenuItem
            component={LinkBehavior}
            key={`menu-item-${i.title}`}
            target={i.newTab ? "_blank" : undefined}
            href={i.href}
            onClick={() => {
              handleClose();
            }}>
            {i.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

interface HelpMenuItemProps {
  title: string;
  href: string;
}

const HelpMenu = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const items: HelpMenuItemProps[] = [
    {
      title: "User Manual",
      href: AppRoutes.HelpUserManual,
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
        <HelpOutlineIcon />
      </IconButton>
      <Menu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {items.map(i => (
          <MenuItem
            component={LinkBehavior}
            key={`menu-item-${i.title}`}
            href={i.href}
            onClick={() => {
              handleClose();
            }}>
            {i.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
