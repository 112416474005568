import React from "react";
import {
  Box,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  stepLabelClasses,
  Stepper,
  Typography,
} from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { SxProps, Theme } from "@mui/system";
import { LabelledCard } from "components/widgets/labelled-card/labelled-card";

export interface StatusStep {
  label: string;
  timestamp?: string;
}
export interface StatusStepperProps {
  steps: StatusStep[];
  activeStep: number;
  sx?: SxProps<Theme>;
}
export const StatusStepper = ({ steps, activeStep, sx }: StatusStepperProps) => {
  return (
    <LabelledCard label="Progress" sx={{ ...sx }}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<StepConnector sx={{ [`& .${stepConnectorClasses.line}`]: { borderLeftStyle: "dashed" } }} />}>
        {steps.map((step, idx) => (
          <Step key={`${step.label}-${idx}`}>
            <StepLabel
              icon={
                idx >= activeStep ? (
                  <Box color="common.grey">
                    <CircleOutlinedIcon color="inherit" />
                  </Box>
                ) : undefined
              }
              optional={
                <Typography variant="caption" color="common.grey5">
                  {step.timestamp}
                </Typography>
              }
              sx={{ [`& .${stepLabelClasses.labelContainer}`]: { ml: 4 } }}>
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </LabelledCard>
  );
};
