import { Box, Slide } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useRefDimensions, useWindowSize } from "utils/hooks/use-ref-dimensions";
import { useActionsSpeedDial } from "../../widgets/common/actions-speed-dial/use-actions-speed-dial";
import { NAVIGATION_DRAWER_WIDTH } from "../navigation-drawer/navigation-drawer";
import { PortalPageContentHeaderProps } from "../portal-page-content-header/portal-page-content-header";

export interface PortalPageContentProps {
  header?: React.ReactElement<PortalPageContentHeaderProps>;
  footer?: React.ReactElement;
  sidePanel?: React.ReactNode;
  children?: React.ReactNode;
  fixedHeight?: boolean;
}

const MIN_ULTRAWIDE_SCREEN_WIDTH = 2560;

/** A wrapper for the inner pages of the portal, intended to be used in each of the top level pages of the application */
export const PortalPageContent: React.FC<PortalPageContentProps> = ({
  header,
  footer,
  sidePanel,
  children,
  fixedHeight,
}) => {
  const [contentHeight, setContentHeight] = useState<number | undefined>();
  const [contentWidth, setContentWidth] = useState<number | undefined>();
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const showingSpeedDial = useActionsSpeedDial();
  const contentRef = useRef<HTMLElement>(null);
  const sidePanelRef = useRef<HTMLElement>(null);
  const footerRef = useRef<HTMLElement>(null);
  const { width: sidePanelWidth } = useRefDimensions(sidePanelRef);
  const { height: footerHeight } = useRefDimensions(footerRef);

  useEffect(() => {
    if (contentRef.current) {
      const availableScreenSpace = windowHeight - contentRef.current.offsetTop - footerHeight;
      setContentHeight(availableScreenSpace);
      if (windowWidth) {
        setContentWidth(contentRef.current.clientWidth + sidePanelWidth);
      }
    }
  }, [contentRef, fixedHeight, footerHeight, sidePanelWidth, windowHeight, windowWidth]);

  return (
    <Page overflow="hidden">
      {header && (
        <BleedingBackground>
          <Header>{header}</Header>
        </BleedingBackground>
      )}
      <BleedingBackground>
        <Content
          ref={contentRef}
          height={fixedHeight ? contentHeight : undefined}
          showingSpeedDialOrFooter={showingSpeedDial || footer !== undefined}>
          {children}
        </Content>
        <Slide direction="left" in={sidePanel !== undefined} unmountOnExit>
          <SidePanel
            minHeight={contentHeight}
            showingSpeedDialOrFooter={showingSpeedDial || footer !== undefined}
            ref={sidePanelRef}>
            <Box>{sidePanel}</Box>
          </SidePanel>
        </Slide>
        {footer && (
          <Slide direction="up" in={true}>
            <Footer ref={footerRef} width={contentWidth ?? "100%"}>
              <Box>{footer}</Box>
            </Footer>
          </Slide>
        )}
      </BleedingBackground>
    </Page>
  );
};

const BleedingBackground = styled(Box, { shouldForwardProp: prop => prop !== "white" })<{ white?: true }>(
  ({ theme, white }) => ({
    display: "flex",
    ...(white && { backgroundColor: theme.palette.common.white }),
  }),
);

const SidePanel = styled(Box, { shouldForwardProp: prop => prop !== "showingSpeedDialOrFooter" })<{
  showingSpeedDialOrFooter: boolean;
}>(({ theme, showingSpeedDialOrFooter }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  backgroundColor: theme.palette.common.grey1,
  padding: theme.spacing(4, 3),
  /// If the speed dial or footer are shown in the screen we make sure we leave enough space at the bottom to scroll it out
  ...(showingSpeedDialOrFooter && { paddingBottom: theme.spacing(14) }),
  borderLeft: "1px solid",
  borderLeftColor: theme.palette.common.grey3,
  maxWidth: "270px",
  overflow: "auto",
}));

const Content = styled(Box, { shouldForwardProp: prop => prop !== "showingSpeedDialOrFooter" })<{
  showingSpeedDialOrFooter?: boolean;
}>(({ theme, showingSpeedDialOrFooter }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  padding: theme.spacing(5, 5, 2.5, 5),
  /// If the speed dial is shown in the screen we make sure we leave enough space at the bottom to scroll it out
  ...(showingSpeedDialOrFooter && { paddingBottom: theme.spacing(14) }),
  maxWidth: "100%",
  [`@media (min-width: ${MIN_ULTRAWIDE_SCREEN_WIDTH}px)`]: {
    maxWidth: `${MIN_ULTRAWIDE_SCREEN_WIDTH - NAVIGATION_DRAWER_WIDTH}px`,
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  padding: theme.spacing(5, 2.5, 2, 5),
  [`@media (min-width: ${MIN_ULTRAWIDE_SCREEN_WIDTH}px)`]: {
    maxWidth: `${MIN_ULTRAWIDE_SCREEN_WIDTH}px`,
  },
}));

const Page = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const Footer = styled(Box, { shouldForwardProp: prop => prop !== "open" })<{
  open?: boolean;
}>(({ theme }) => ({
  backgroundColor: theme.palette.common.grey1,
  minHeight: "88px",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  position: "fixed",
  padding: theme.spacing(2),
  bottom: 0,
  /// Custom shadow as it is an inverted shadow (top instead of bottom shadow)
  boxShadow: "3px -1px 8px rgba(0, 0, 0, 0.25)",
}));
