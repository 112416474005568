import React from "react";
import { Typography } from "@mui/material";

export interface Address {
  name: string;
  addressLine1: string | null;
  addressLine2: string | null;
  postCode: string | null;
  country?: string | null;
}

export const AddressTypographyCompressed = ({ name, addressLine1, addressLine2, postCode, country }: Address) => {
  return (
    <Typography variant="body1">
      {name}
      {addressLine1 && (
        <>
          <br />
          {addressLine1}
        </>
      )}
      {addressLine2 && (
        <>
          <br />
          {addressLine2}
        </>
      )}
      {postCode && (
        <>
          <br />
          {postCode}
        </>
      )}
      {country && (
        <>
          <br />
          {country}
        </>
      )}
    </Typography>
  );
};
