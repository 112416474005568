import React, { useCallback, useEffect, useState } from "react";
import { compareDesc, getYear } from "date-fns";
import { DbKey } from "adl-gen/common/db";
import { Product, StorageLocation, StorageLocationId } from "adl-gen/ferovinum/app/db";
import { assertNotUndefined } from "utils/hx/util/types";
import { useSelectedOrgId } from "../../layouts/portal-page-layout/portal-page";
import { useAppService } from "../../../hooks/use-app-service";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { ProductMovementView } from "./product-movement-view";
import {
  AvailableStockLineItem,
  MinimalStorageLocationView,
  PendingStockLineItem,
  ProductMovementReport,
  ProductMovementTotals,
} from "adl-gen/ferovinum/app/views";
import { Instant } from "adl-gen/common";
import { assertNever } from "assert-never";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { isLoaded, LoadingValue } from "utils/utility-types";

export interface ProductMovementProps {
  productId: DbKey<Product>;
  initialStorageLocationId?: DbKey<StorageLocation>;
}

export function isAvailableStockLineItem(lineItem: TransactionHistoryLineItem): lineItem is AvailableStockLineItem {
  switch (lineItem.lineItemType.kind) {
    case "addedFromDealLeg":
    case "repurchased":
    case "allocatedToProductionOrder":
    case "productionOrderTaskRunLoss":
    case "allocatedToStockMovement":
    case "resultOfStockMovement":
    case "resultOfNewStockDelivery":
    case "resultOfExistingStockNewDealRequest":
    case "allocatedToPurchaseRequest":
    case "allocatedToNegativeStockAdjustment":
      return true;
    case "resultOfProductionOrder":
      return "availableTimestamp" in lineItem;
    case "newDealRequest":
      return false;
    default:
      return assertNever(lineItem.lineItemType);
  }
}

export function isPendingStockLineItem(lineItem: TransactionHistoryLineItem): lineItem is PendingStockLineItem {
  switch (lineItem.lineItemType.kind) {
    case "newDealRequest":
      return true;
    case "resultOfProductionOrder":
      return "availableTimestamp" in lineItem;
    case "allocatedToProductionOrder":
    case "repurchased":
    case "addedFromDealLeg":
    case "productionOrderTaskRunLoss":
    case "allocatedToStockMovement":
    case "resultOfStockMovement":
    case "resultOfNewStockDelivery":
    case "resultOfExistingStockNewDealRequest":
    case "allocatedToPurchaseRequest":
    case "allocatedToNegativeStockAdjustment":
      return false;
    default:
      assertNever(lineItem.lineItemType);
  }
}

export const NO_YEAR_AVAILABLE = -1;

function compareLineItemTimestampsFn(
  lineItemA: TransactionHistoryLineItem,
  lineItemB: TransactionHistoryLineItem,
): number {
  const timestampA: Instant = lineItemA.timestamp;
  const timestampB: Instant = lineItemB.timestamp;

  return compareDesc(timestampA, timestampB);
}

export function transactionHistoryFromProductMovementReport(
  movementReport: ProductMovementReport,
): TransactionHistoryByYear {
  const lineItems = [...movementReport.availableStockLineItems, ...movementReport.pendingStockLineItems];
  const sortedLineItems = lineItems.sort(compareLineItemTimestampsFn);
  const result = new Map<number, TransactionHistoryLineItems>();
  for (const lineItem of sortedLineItems) {
    const { timestamp } = lineItem;
    const year: number =
      timestamp !== null ? (isNaN(getYear(timestamp)) ? NO_YEAR_AVAILABLE : getYear(timestamp)) : NO_YEAR_AVAILABLE;
    if (result.has(year)) {
      assertNotUndefined(result.get(year)).push(lineItem);
    } else {
      result.set(year, [lineItem]);
    }
  }
  return result;
}

export function allWarehousesStockFromProductMovementReport(movementReport: ProductMovementReport): AllWarehousesStock {
  return {
    transactionHistory: transactionHistoryFromProductMovementReport({
      ...movementReport,
      // Stock movement should not be shown in transaction history for all warehouses
      availableStockLineItems: movementReport.availableStockLineItems.filter(
        item =>
          item.lineItemType.kind !== "allocatedToStockMovement" && item.lineItemType.kind !== "resultOfStockMovement",
      ),
    }),
    warehouseTotals: movementReport.totalsPerStorageLocationName.reduce((map, totals) => {
      map.set(totals.key.storageLocationId, { ...totals.value, ...totals.key });
      return map;
    }, new Map()),
  };
}

export type TotalsPerStorageLocationId = Map<StorageLocationId, ProductMovementTotals & MinimalStorageLocationView>;
/// Record of transaction history line items mapped by year
export type TransactionHistoryLineItem = AvailableStockLineItem | PendingStockLineItem;
export type TransactionHistoryLineItems = Array<TransactionHistoryLineItem>;
export type TransactionHistoryByYear = Map<number, TransactionHistoryLineItems>;
export interface AllWarehousesStock {
  transactionHistory: TransactionHistoryByYear;
  warehouseTotals: TotalsPerStorageLocationId;
}

export const ProductMovement = ({ productId }: ProductMovementProps) => {
  const organisationId = assertNotUndefined(useSelectedOrgId());
  const service = useAppService();
  const [storageLocation, setStorageLocation] = useState<DbKey<StorageLocation> | null>(null);
  const [showAlert] = useAlert();

  const loadProduct = useCallback(async () => {
    const resp = await service.getProducts({ organisationId, ids: [productId] });
    if (!resp) {
      void showAlert({ title: "Error encountered", body: "Unable to find product" });
    }
    return assertNotUndefined(resp.find(p => p.id === productId)).value;
  }, [organisationId, productId, service, showAlert]);

  const [loadingProduct] = useLoadingDataState(loadProduct);

  const loadTransactionHistory = useCallback(
    async (storageLocationId: string | null): Promise<TransactionHistoryByYear> => {
      const movementReport = await service.productMovementReport({
        productId,
        organisationId,
        storageLocationId,
      });
      if (movementReport.kind === "just") {
        return transactionHistoryFromProductMovementReport(movementReport.value);
      }
      if (movementReport.kind === "nothing") {
        return new Map();
      }
      throw new Error("Error retrieving transaction history");
    },
    [service, productId, organisationId],
  );
  const [loadingTransactionHistory, setLoadingTransactionHistory] = useState<LoadingValue<TransactionHistoryByYear>>({
    state: "loading",
  });

  const loadAllWarehousesStock = useCallback(async (): Promise<AllWarehousesStock> => {
    const report = await service.productMovementReport({
      productId,
      organisationId,
      storageLocationId: null,
    });
    if (report.kind === "just") {
      return allWarehousesStockFromProductMovementReport(report.value);
    }
    if (report.kind === "nothing") {
      return { transactionHistory: new Map(), warehouseTotals: new Map() };
    }
    throw new Error("Error retrieving product totals");
  }, [service, productId, organisationId]);
  const [loadingWarehousesStock] = useLoadingDataState(loadAllWarehousesStock);

  useEffect(() => {
    async function fetchTransactionHistory(loc: DbKey<StorageLocation> | null) {
      try {
        const value = await loadTransactionHistory(loc);
        setLoadingTransactionHistory({ state: "success", value });
      } catch (error: unknown) {
        setLoadingTransactionHistory({
          state: "error",
          error: error instanceof Error ? error : new Error(String(error)),
        });
      }
    }

    if (isLoaded(loadingWarehousesStock)) {
      // Default to the first storage location for stock with a single storage location
      if (storageLocation === null && loadingWarehousesStock.value.warehouseTotals.size === 1) {
        setStorageLocation([...loadingWarehousesStock.value.warehouseTotals.keys()][0]);
        // Note(Berto): We don't want to fetch the transaction history across all storage locations
        // this useEffect will be re-triggered again now that we have defaulted to the first location
        return;
      }
      setLoadingTransactionHistory({ state: "loading" });
      void fetchTransactionHistory(storageLocation);
    }
  }, [loadTransactionHistory, loadingWarehousesStock, storageLocation]);

  return (
    <ProductMovementView
      product={loadingProduct}
      selectedWarehouse={storageLocation}
      warehousesStock={loadingWarehousesStock}
      transactionHistory={loadingTransactionHistory}
      onChangeStorageLocation={setStorageLocation}
    />
  );
};
