import { createCurrencyFormatter } from "utils/currency-utils";
import { Card, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { OrganisationProductSummary } from "../../organisation-product-summary/organisation-product-summary";
import { formatNumberOfUnits, getVesselCapacity } from "utils/model-utils";
import { InfoField } from "components/widgets/info-field/info-field";
import React from "react";
import { titleCase } from "utils/conversion-utils";
import { ProductionPlanForFinishedProduct } from "../../../page/organisation/purchase-requests/organisation-purchase-request-production-plan/organisation-purchase-request-production-plan-page-view";
import { Currency } from "adl-gen/ferovinum/app/db";

export interface OrganisationPurchaseRequestProductionPlanCardProps {
  productionPlan: ProductionPlanForFinishedProduct;
  currency: Currency;
  CallToAction?: React.ReactNode;
}
export const OrganisationPurchaseRequestProductionPlanCard = ({
  productionPlan: {
    finishedProduct: { product, targetQuantity },
    productionOrders,
  },
  currency,
  CallToAction,
}: OrganisationPurchaseRequestProductionPlanCardProps) => {
  const currencyFormatter = createCurrencyFormatter(currency)();
  return (
    <Card>
      <Stack direction="row">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flex={1}
          p={4}
          sx={{ backgroundColor: "common.grey3" }}>
          <OrganisationProductSummary
            {...product.value}
            productId={product.id}
            vesselCapacity={getVesselCapacity(product.value.vesselSize)}
          />
          <InfoField
            label="Quantity to produce"
            value={formatNumberOfUnits(targetQuantity, product.value.unitType)}
            sx={{ textAlign: "right" }}
          />
        </Stack>
        {CallToAction && <>{CallToAction}</>}
      </Stack>
      {productionOrders.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Input product</TableCell>
              <TableCell>Service details</TableCell>
              <TableCell align="right">Output Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productionOrders.map(src => {
              const nTasks = src.tasks.length;
              const firstTaskPricePerUnit = src.tasks[0].pricePerUnit;
              const firstTaskSetupFee = src.tasks[0].setupFee;
              const firstTaskServiceName = src.tasks[0].serviceName;
              const firstTaskQuantity = src.tasks[0].quantity;
              return (
                <React.Fragment key={src.sourceProduct.id}>
                  <TableRow>
                    <TableCell rowSpan={nTasks}>
                      <OrganisationProductSummary
                        {...src.sourceProduct.value}
                        vesselCapacity={getVesselCapacity(src.sourceProduct.value.vesselSize)}
                      />
                    </TableCell>
                    <TableCell>
                      <Stack>
                        <Typography>{`${titleCase(firstTaskServiceName)}`}</Typography>
                        <Typography>{`Price per unit: ${currencyFormatter.format(firstTaskPricePerUnit)}`}</Typography>
                        <Typography>{`Setup fee: ${currencyFormatter.format(firstTaskSetupFee)}`}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="right">{firstTaskQuantity}</TableCell>
                  </TableRow>
                  {src.tasks.slice(1).map(task => {
                    return (
                      <TableRow key={`task-${task.finishingProductMappingId}`}>
                        <TableCell>
                          <Stack>
                            <Typography>{`${titleCase(task.serviceName)}`}</Typography>
                            <Typography>{`Price per unit: ${currencyFormatter.format(task.pricePerUnit)}`}</Typography>
                            <Typography>{`Setup fee: ${currencyFormatter.format(task.setupFee)}`}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="right">{task.quantity}</TableCell>
                      </TableRow>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      )}
    </Card>
  );
};
