import React, { useCallback } from "react";
import { assertNotUndefined } from "utils/hx/util/types";
import { ProductionOrderAction, StorageLocProductionOrdersPageView } from "./storage-loc-production-orders-page-view";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { Loader } from "components/widgets/loader/loader";
import { isLoaded } from "utils/utility-types";
import { AppRoutes } from "../../../../../app/app-routes";
import { AppService } from "adl-gen/app-service";
import { useSelectedStorageLocation } from "../../../../layouts/portal-page-layout/portal-page";

export type StorageLocProductionOrdersPageSection = "new orders" | "existing orders";
export const pageSections: Record<StorageLocProductionOrdersPageSection, AppRoutes> = {
  "new orders": AppRoutes.LocProductionOrders,
  "existing orders": AppRoutes.LocExistingProductionOrders,
};

export const StorageLocProductionOrdersPage = () => {
  const service: AppService = useAppService();
  const storageLocationId = assertNotUndefined(useSelectedStorageLocation()).id;

  const loadProductionOrders = useCallback(async () => {
    return await service.getStorageLocationProductionOrders({ storageLocationId });
  }, [service, storageLocationId]);

  const [loadingProductionOrders, refreshProductionOrders] = useLoadingDataState(loadProductionOrders);

  const onAction = React.useCallback(
    async (props: ProductionOrderAction) => {
      if (props.action === "accept") {
        await service.acceptProductionOrder({ productionOrderId: props.productionOrderId });
      } else {
        await service.rejectProductionOrder({
          productionOrderId: props.productionOrderId,
          reason: props.rejectionReason,
        });
      }
      // Refresh the current production order data
      await refreshProductionOrders();
    },
    [refreshProductionOrders, service],
  );

  return (
    <Loader loadingStates={[loadingProductionOrders]} fullScreen>
      {isLoaded(loadingProductionOrders) && (
        <StorageLocProductionOrdersPageView
          productionOrders={loadingProductionOrders.value}
          onActionProductionOrder={onAction}
        />
      )}
    </Loader>
  );
};
