import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import {
  Box,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  stepLabelClasses,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import {
  DealExtensionRequestState,
  DealExtensionRequestStateEvent,
  valuesDealExtensionRequestState,
} from "adl-gen/ferovinum/app/db";
import React, { useCallback, useMemo } from "react";
import { formatInstant } from "utils/date-utils";
import { assertNever } from "utils/hx/util/types";

export interface DealExtensionRequestStepperProps {
  stateEvents: DealExtensionRequestStateEvent[];
  orgName: string;
}
export const DealExtensionRequestStepper = ({ stateEvents, orgName }: DealExtensionRequestStepperProps) => {
  const { state: currentState } = stateEvents[stateEvents.length - 1];
  const steps = getDisplayStates(currentState);
  /** The active step in the stepper */
  const activeStep: number = useMemo(() => {
    const currentStateIndex = steps.findIndex(state => state === currentState);
    /** The active step is the following step from the current state in the deal extension request flow (hence the +1) */
    return currentStateIndex + 1;
  }, [currentState, steps]);

  const getFormattedTimestamp = useCallback(
    (state: DealExtensionRequestState): string | undefined => {
      const occuredStep = stateEvents.find(event => event.state === state);
      return occuredStep ? formatInstant(occuredStep.time) : undefined;
    },
    [stateEvents],
  );

  return (
    <Stepper orientation="vertical" activeStep={activeStep} connector={<DealExtensionRequestStepperConnector />}>
      {steps.map((state, idx) => (
        <Step key={state}>
          <StepLabel
            icon={
              idx >= activeStep ? (
                <Box color="common.grey">
                  <CircleOutlinedIcon color="inherit" />
                </Box>
              ) : undefined
            }
            sx={{ [`& .${stepLabelClasses.labelContainer}`]: { ml: 4 } }}
            optional={getFormattedTimestamp(state)}>
            <Typography>{getStateDescription(state, orgName)}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const getDisplayStates = (currentState: DealExtensionRequestState): DealExtensionRequestState[] => {
  switch (currentState) {
    case "orgRejected":
      return ["newDeal", "newDealPriced", "orgRejected"];
    case "nothingToExtend":
      return ["newDeal", "newDealPriced", "orgAccepted", "nothingToExtend"];
    default:
      return valuesDealExtensionRequestState.filter(s => !["orgRejected", "nothingToExtend"].includes(s));
  }
};

function getStateDescription(state: DealExtensionRequestState, orgName: string) {
  switch (state) {
    case "newDeal":
      return `Deal renewal requested by ${orgName}`;
    case "newDealPriced":
      return `Contract note provided by Ferovinum`;
    case "orgAccepted":
      return `Contract note accepted by ${orgName}`;
    case "newDealCreated":
      return "Deal renewed";
    case "invoiced":
      return `Invoice sent by Ferovinum`;
    case "invoicePaid":
      return `${orgName} paid invoice`;
    case "paymentReceived":
      return `Ferovinum confirmed receipt of payment`;
    case "orgRejected":
      return `Contract note rejected by ${orgName}`;
    case "nothingToExtend":
      return `Everything has been repurchased. Nothing to renew.`;
    default:
      assertNever(state);
  }
}

const DealExtensionRequestStepperConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: { borderLeftStyle: "dashed" },
}));
