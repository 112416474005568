/* @generated from adl module ferovinum.app.types */

import * as ADL from './../../runtime/adl';

/**
 * A value that represents money, i.e. has two decimal places.
 */
export type MonetaryValue = string;

const MonetaryValue_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.types","decl":{"annotations":[{"value":"A value that represents money, i.e. has two decimal places.\n","key":{"moduleName":"sys.annotations","name":"Doc"}}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"MonetaryValue","version":{"kind":"nothing"}}};

export const snMonetaryValue: ADL.ScopedName = {moduleName:"ferovinum.app.types", name:"MonetaryValue"};

export function texprMonetaryValue(): ADL.ATypeExpr<MonetaryValue> {
  return {value : {typeRef : {kind: "reference", value : snMonetaryValue}, parameters : []}};
}

export type Decimal = string;

const Decimal_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.types","decl":{"annotations":[{"value":"numeric","key":{"moduleName":"common.db","name":"DbColumnType"}}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"Decimal","version":{"kind":"nothing"}}};

export const snDecimal: ADL.ScopedName = {moduleName:"ferovinum.app.types", name:"Decimal"};

export function texprDecimal(): ADL.ATypeExpr<Decimal> {
  return {value : {typeRef : {kind: "reference", value : snDecimal}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "ferovinum.app.types.MonetaryValue" : MonetaryValue_AST,
  "ferovinum.app.types.Decimal" : Decimal_AST
};
