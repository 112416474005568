import { Instant, LocalDate } from "adl-gen/common";
import React, { useCallback } from "react";
import {
  Box,
  Chip,
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepConnectorProps,
  StepLabel,
  Stepper,
  styled,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import { formatInstant, formatLocalDate, isAfterToday } from "utils/date-utils";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Check } from "@mui/icons-material";
import { assertNever } from "assert-never";

export interface BaseProgressStepperProps<T> {
  currentState: T;
  states: T[];
  lastStateSet: Instant;
  upcomingStateDeadline?: LocalDate;
  actionRequiredState?: T;
  getHumanReadableState: (state: T) => string;
}
export const BaseProgressStepper = <T extends string>({
  currentState,
  states,
  lastStateSet,
  upcomingStateDeadline,
  actionRequiredState,
  getHumanReadableState,
}: BaseProgressStepperProps<T>) => {
  // Manual findLastIndex because Array<T>.findLastIndex(...) requires updated version of typescript
  const activeStep =
    states.length -
    states
      .slice()
      .reverse()
      .findIndex((s: T) => s === currentState) -
    1;

  return (
    <Stepper activeStep={activeStep} alternativeLabel connector={<ProgressConnector />}>
      {states.map((label: T, idx: number) => {
        const isActive = idx === activeStep;
        const isUpcoming = idx === activeStep + 1;
        const isOverdue = isUpcoming && upcomingStateDeadline && isAfterToday(new Date(upcomingStateDeadline));
        const upcomingState = activeStep < states.length ? states[activeStep + 1] : undefined;
        const actionRequired = upcomingState === actionRequiredState;

        const getStepLabelVariant = (): StepLabelVariant => {
          if (isActive) {
            return "active";
          }
          if (isUpcoming) {
            return isOverdue ? "overdue" : "next";
          }

          return idx < activeStep ? "completed" : "toBeCompleted";
        };

        const commonTypographyProps: TypographyProps = {
          color: isOverdue ? "common.error" : undefined,
        };

        return (
          <Step
            key={`${label}-${idx}`}
            sx={{
              [`&& .${stepConnectorClasses.line}`]: isUpcoming
                ? {
                    borderTopStyle: "solid",
                  }
                : undefined,
            }}>
            <StepLabel StepIconComponent={props => <ProgressStepIcon {...props} variant={getStepLabelVariant()} />}>
              {isActive || isUpcoming ? (
                <Stack spacing={0.5}>
                  <Typography variant="captionMedium" {...commonTypographyProps}>
                    {getHumanReadableState(label)}
                  </Typography>
                  {isUpcoming && (
                    <>
                      {upcomingStateDeadline && (
                        <Typography variant="caption" {...commonTypographyProps}>
                          Estimate: <br />
                          {formatLocalDate(upcomingStateDeadline)}
                        </Typography>
                      )}
                      {actionRequired && (
                        <Chip
                          label="Action required"
                          color="warning"
                          sx={{
                            "typography": "caption",
                            "fontWeight": "medium",
                            // multiline chip
                            // ref: https://mui.com/material-ui/react-chip/#multiline-chip
                            "height": "auto",
                            "& .MuiChip-label": {
                              display: "block",
                              whiteSpace: "normal",
                            },
                          }}
                          size="small"
                        />
                      )}
                    </>
                  )}
                  {isActive && <Typography variant="caption">{formatInstant(lastStateSet)}</Typography>}
                </Stack>
              ) : (
                ""
              )}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

type StepLabelVariant = "completed" | "active" | "next" | "overdue" | "toBeCompleted";

const ProgressStepIcon = ({ variant }: { variant: StepLabelVariant }) => {
  const DEFAULT_ICON_SIZE = 22;
  const INACTIVE_ICON_SIZE = 12;

  const ActiveStepLabel = styled(StepLabel)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.purple,
    borderRadius: "50%",
    height: DEFAULT_ICON_SIZE,
    width: DEFAULT_ICON_SIZE,
    justifyContent: "center",
  }));

  const NotCompletedStepLabel = styled(Box)(({ theme }) => ({
    width: INACTIVE_ICON_SIZE,
    height: INACTIVE_ICON_SIZE,
    borderRadius: "50%",
    backgroundColor: theme.palette.common.grey3,
  }));

  const UpcomingStepLabel = styled(Box)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.white,
    border: "2px solid",
    borderColor: theme.palette.common.grey,
    borderRadius: "50%",
    height: DEFAULT_ICON_SIZE,
    width: DEFAULT_ICON_SIZE,
  }));

  const CompletedStepLabel = styled(Box)(() => ({
    display: "none",
  }));

  const OverdueStepLabel = styled(ErrorOutlineOutlinedIcon)(({ theme }) => ({
    color: theme.palette.common.error,
    backgroundColor: theme.palette.common.white,
  }));

  const getStepLabel = useCallback(() => {
    switch (variant) {
      case "active":
        return <ActiveStepLabel icon={<Check sx={{ fontSize: 14 }} />} />;
      case "completed":
        return <CompletedStepLabel />;
      case "next":
        return <UpcomingStepLabel />;
      case "overdue":
        return (
          <Tooltip title="This is overdue" placement="top" arrow>
            <OverdueStepLabel />
          </Tooltip>
        );
      case "toBeCompleted":
        return <NotCompletedStepLabel />;
      default:
        assertNever(variant);
    }
  }, [ActiveStepLabel, CompletedStepLabel, NotCompletedStepLabel, OverdueStepLabel, UpcomingStepLabel, variant]);

  return (
    <Box
      sx={{
        color: "common.grey3",
        display: "flex",
        height: DEFAULT_ICON_SIZE,
        alignItems: "center",
        padding: 0,
        zIndex: 1,
      }}>
      {getStepLabel()}
    </Box>
  );
};

const ProgressConnector = styled(StepConnector)<StepConnectorProps>(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.common.purple,
      borderTopStyle: "solid",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.common.purple,
      borderTopStyle: "solid",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 3,
    borderColor: theme.palette.common.grey3,
    borderTopStyle: "dashed",
  },
  // This will make it so that the connector and step are touching
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: `-50%`,
    right: `50%`,
  },
}));
