import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

const DEFAULT_LAYOUT_INFO_VALUES = {
  isMobile: false,
};

interface LayoutInfoContextProps {
  isMobile: boolean;
}

const LayoutInfoContext = React.createContext<LayoutInfoContextProps>({
  ...DEFAULT_LAYOUT_INFO_VALUES,
});

export const LayoutInfoProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return <LayoutInfoContext.Provider value={{ isMobile }}>{children}</LayoutInfoContext.Provider>;
};

export function useLayoutInfo(): LayoutInfoContextProps {
  return React.useContext(LayoutInfoContext);
}
