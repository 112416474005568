import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PortalPageContent } from "../../../../../layouts/portal-page-content/portal-page-content";
import {
  Checkbox,
  Fade,
  Grid,
  Paper,
  Stack,
  Tab,
  Table,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Currency, ProductionOrderStatus, ProductionOrderTask } from "adl-gen/ferovinum/app/db";
import { ProductionOrderTaskRunView } from "adl-gen/ferovinum/app/api";
import { FinishingServiceSummaryCard } from "../../../../../widgets/production-orders/finishing-service-summary-card/finishing-service-summary-card";
import { ProductionOrderTotalPriceCard } from "../../../../../widgets/production-orders/production-order-total-price-card/production-order-total-price-card";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { number, object } from "yup";
import { useFormik } from "formik";
import {
  PortalPageContentHeader,
  SubtitleProps,
} from "../../../../../layouts/portal-page-content-header/portal-page-content-header";
import { useSettlementCurrency } from "../../../../../layouts/portal-page-layout/portal-page";
import { NotesStickyNote } from "../../../../../widgets/sticky-note/notes-sticky-note/notes-sticky-note";
import { DbKey } from "adl-gen/common/db";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { TableBody } from "semantic-ui-react";
import { formatLocalDate } from "utils/date-utils";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";
import { ProductionOrderTasksSummaryTable } from "../../../../../widgets/production-orders/production-order-tasks-summary-table/production-order-tasks-summary-table";
import { StorageLocProductionOrderTaskView } from "../storage-loc-production-order-details-page";
import { assertNever } from "utils/hx/util/types";

interface ProductionOrderStatusEvent {
  status: ProductionOrderStatus;
  timestamp: string;
}

export interface StorageLocProductionOrderAcceptedPageViewProps {
  orderNumber: string;
  orderDate: string;
  productionOrderTasks: StorageLocProductionOrderTaskView[];
  latestStatus: ProductionOrderStatusEvent;
  requestedCompletionDate?: string;
  notes?: string;
  subtotalCost: MonetaryValue;
  totalCost: MonetaryValue;
  totalSetupFee: MonetaryValue;
  completionPercentage: number;
  onSubmitProductionRun: (taskId: DbKey<ProductionOrderTask>, sourceQty: number, resultingQty: number) => Promise<void>;
}

type Sections = "Summary" | "InProgressTasks" | "CompletedTasks";
export const StorageLocProductionOrderAcceptedPageView = ({
  productionOrderTasks,
  latestStatus,
  ...props
}: StorageLocProductionOrderAcceptedPageViewProps) => {
  const inProgressTasks = productionOrderTasks.filter(task => !task.isComplete);
  const completedTasks = productionOrderTasks.filter(task => task.isComplete);

  const hasInProgressTasks = inProgressTasks.length > 0;
  const hasCompletedTasks = completedTasks.length > 0;
  const isRejected = latestStatus.status === "rejected";

  const [selectedTab, setSelectedTab] = useState<Sections>(hasInProgressTasks ? "InProgressTasks" : "CompletedTasks");
  const currency = useSettlementCurrency();

  useEffect(() => {
    // Note (Nicole): This is necessary to update the selected tab if all tasks have been completed
    setSelectedTab(hasInProgressTasks ? "InProgressTasks" : "CompletedTasks");
  }, [hasInProgressTasks]);

  return (
    <PortalPageContent header={<ProductionOrderDetailsHeader latestStatus={latestStatus} {...props} />}>
      <Tabs value={selectedTab} onChange={(_e, v) => setSelectedTab(v)}>
        {hasInProgressTasks && <Tab label={isRejected ? "Tasks" : "In progress tasks"} value="InProgressTasks" />}
        {hasCompletedTasks && <Tab label="Completed tasks" value="CompletedTasks" />}
        <Tab label="Summary" value="Summary" />
      </Tabs>
      <Stack paddingTop={5}>
        {selectedTab === "InProgressTasks" && (
          <TasksSection productionOrderTasks={inProgressTasks} {...props} latestStatus={latestStatus.status} />
        )}
        {selectedTab === "CompletedTasks" && (
          <TasksSection productionOrderTasks={completedTasks} {...props} latestStatus={latestStatus.status} />
        )}
        {selectedTab === "Summary" && (
          <SummarySection productionOrderTasks={productionOrderTasks} {...props} currency={currency} />
        )}
      </Stack>
    </PortalPageContent>
  );
};

interface ProductionOrderDetailsHeaderProps {
  orderNumber: string;
  latestStatus: ProductionOrderStatusEvent;
  completionPercentage: number;
  requestedCompletionDate?: string;
}
const ProductionOrderDetailsHeader = ({
  orderNumber,
  latestStatus,
  completionPercentage,
  requestedCompletionDate,
}: ProductionOrderDetailsHeaderProps) => {
  const subtitles: SubtitleProps[] = useMemo(() => {
    const defaultSubtitles: SubtitleProps[] = requestedCompletionDate
      ? [{ text: `Requested completion date: ${formatLocalDate(requestedCompletionDate)}`, icon: "date" }]
      : [];

    const formattedTimestamp = formatLocalDate(latestStatus.timestamp);
    switch (latestStatus.status) {
      case "accepted":
        defaultSubtitles.push({
          text: `Accepted on ${formattedTimestamp} - Production order is ${completionPercentage}% complete`,
          component: { type: "chip", color: "warning" },
        });
        break;
      case "completed":
        defaultSubtitles.push({
          text: `Completed on ${formattedTimestamp}`,
          component: { type: "chip", color: "success" },
        });
        break;
      case "rejected":
        defaultSubtitles.push({
          text: `Rejected on ${formattedTimestamp}`,
          component: { type: "chip", color: "error" },
        });
        break;
      case "pending":
        throw new Error();
      default:
        assertNever(latestStatus.status);
    }

    return defaultSubtitles;
  }, [completionPercentage, latestStatus.status, latestStatus.timestamp, requestedCompletionDate]);
  return <PortalPageContentHeader title={`Production order reference number: ${orderNumber}`} subtitles={subtitles} />;
};

interface SummarySectionProps {
  productionOrderTasks: StorageLocProductionOrderTaskView[];
  currency: Currency;
  notes?: string;
  totalSetupFee: MonetaryValue;
  subtotalCost: MonetaryValue;
  totalCost: MonetaryValue;
}
const SummarySection = (props: SummarySectionProps) => {
  const { notes, productionOrderTasks } = props;
  const hasMultipleTasks = productionOrderTasks.length > 1;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ProductionOrderTasksSummaryTable {...props} />
      </Grid>
      {hasMultipleTasks && (
        <Grid item xs={12} lg={6}>
          <ProductionOrderTotalPriceCard
            {...props}
            setupCost={props.totalSetupFee}
            productionOrderTasksCosts={productionOrderTasks}
          />
        </Grid>
      )}
      {notes && (
        <Grid item xs={12} lg={hasMultipleTasks ? 6 : 12}>
          <NotesStickyNote notes={notes} />
        </Grid>
      )}
    </Grid>
  );
};

interface TasksSectionProps {
  productionOrderTasks: StorageLocProductionOrderTaskView[];
  latestStatus: ProductionOrderStatus | null;
  onSubmitProductionRun: (taskId: DbKey<ProductionOrderTask>, sourceQty: number, resultingQty: number) => Promise<void>;
}
const TasksSection = ({ productionOrderTasks, latestStatus, onSubmitProductionRun }: TasksSectionProps) => {
  return (
    <Stack spacing={2}>
      {productionOrderTasks.map(poTask => {
        const {
          finishingService,
          targetQuantity,
          budgetQuantity,
          sourceProduct,
          finishingProduct,
          productionOrderTaskRuns,
          productionOrderTaskId: taskId,
        } = poTask;

        return (
          <FinishingServiceSummaryCard
            key={taskId}
            serviceName={finishingService.name}
            targetQuantity={targetQuantity}
            budgetQuantity={budgetQuantity}
            sourceProduct={sourceProduct.value}
            finishingProduct={finishingProduct}
            productionOrderTaskRuns={productionOrderTaskRuns}
            latestStatus={latestStatus}>
            {!poTask.isComplete && latestStatus === "accepted" && (
              <AddProductionRunForm
                totalQuantity={targetQuantity}
                budgetQuantity={budgetQuantity}
                productionOrderTaskRuns={productionOrderTaskRuns}
                onSubmitProductionRun={(sourceQty, resultingQty) =>
                  onSubmitProductionRun(taskId, sourceQty, resultingQty)
                }
              />
            )}
          </FinishingServiceSummaryCard>
        );
      })}
    </Stack>
  );
};

interface AddProductionRunFormValues {
  quantityUsed: string;
  quantityProduced: string;
}
interface AddProductionRunFormProps {
  totalQuantity: number;
  budgetQuantity?: number;
  productionOrderTaskRuns: ProductionOrderTaskRunView[];
  onSubmitProductionRun: (sourceQty: number, resultingQty: number) => Promise<void>;
}
const AddProductionRunForm = ({
  totalQuantity,
  budgetQuantity,
  productionOrderTaskRuns,
  onSubmitProductionRun,
}: AddProductionRunFormProps) => {
  const [openAlert] = useAlert();
  const hasNoBudgetQuantity = budgetQuantity === undefined;

  // Note (Nicole): We are not including the actual budget quantity in this calculation because the task is marked
  // as complete when the budget quantity runs out, so users won't see this form when that happens.
  const quantityRemaining = useMemo(() => {
    const processedItemsInTask = productionOrderTaskRuns.reduce((sum, run) => {
      const totalLosses = run.productLosses.reduce((s, l) => s + l.quantity.value, 0);

      // If there is no budget quantity, we don't need to take the losses into account when
      // calculating quantity remaining.
      return sum + run.resultingProductItem.quantity.value + (hasNoBudgetQuantity ? 0 : totalLosses);
    }, 0);
    return totalQuantity - processedItemsInTask;
  }, [hasNoBudgetQuantity, productionOrderTaskRuns, totalQuantity]);

  const validationSchema = useMemo(() => {
    let quantityUsedSchema = number().min(1).integer().positive().required();

    // If there is a budget on the source, the user should only be able to use whatever is remaining
    // in that budget. Otherwise, the max only applies to the quantity produced and we don't care how much they used.
    if (budgetQuantity) {
      quantityUsedSchema = quantityUsedSchema.max(quantityRemaining);
    }

    return object().shape({
      quantityUsed: quantityUsedSchema,
      quantityProduced: number().min(1).max(quantityRemaining).integer().positive().required(),
    });
  }, [budgetQuantity, quantityRemaining]);

  const onSubmit = useCallback(
    async (values: AddProductionRunFormValues) => {
      const srqQty = parseInt(values.quantityUsed);
      const resQty = parseInt(values.quantityProduced);
      await onSubmitProductionRun(srqQty, resQty);
      await openAlert({ title: "Successfully added production task run", body: "", severity: "success" });
    },
    [onSubmitProductionRun, openAlert],
  );

  const form = useFormik<AddProductionRunFormValues>({
    initialValues: { quantityProduced: "", quantityUsed: "" },
    validationSchema,
    validateOnMount: true,
    onSubmit,
  });

  const [highLossAcknowledged, setHighLossAcknowledged] = useState(false);

  const { losses, lossesAreHigh } = useMemo(() => {
    const result = parseInt(form.values.quantityUsed) - parseInt(form.values.quantityProduced);
    const losses = isNaN(result) || result < 0 ? 0 : result;
    const lossesAreHigh = losses > 0.05 * parseInt(form.values.quantityUsed);
    return { losses, lossesAreHigh };
  }, [form.values.quantityUsed, form.values.quantityProduced]);

  const commonInputProps: Partial<TextFieldProps> = {
    type: "number",
    onBlur: form.handleBlur,
    onChange: form.handleChange,
    fullWidth: true,
    required: true,
    sx: { maxWidth: "200px", backgroundColor: "common.white" },
    inputProps: { style: { textAlign: "right" } },
  };

  return (
    <TableContainer component={Paper} variant="outlined" square sx={{ backgroundColor: "common.grey1" }}>
      <Table
        sx={{
          backgroundColor: "transparent",
          [`& .${tableCellClasses.head}`]: {
            backgroundColor: "inherit",
          },
        }}>
        <TableHead>
          <TableRow>
            <TableCell>Quantity used</TableCell>
            <TableCell>Quantity produced</TableCell>
            <TableCell>Losses</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              <TextField
                name={"quantityUsed"}
                value={form.values.quantityUsed}
                error={form.touched.quantityUsed && Boolean(form.errors.quantityUsed)}
                {...commonInputProps}
              />
            </TableCell>
            <TableCell>
              <TextField
                name={"quantityProduced"}
                value={form.values.quantityProduced}
                error={form.touched.quantityProduced && Boolean(form.errors.quantityProduced)}
                {...commonInputProps}
              />
            </TableCell>
            <TableCell>{losses}</TableCell>
            <TableCell>
              <Fade in={lossesAreHigh} timeout={{ enter: 3000, exit: 0 }}>
                <Stack direction="row">
                  <Checkbox
                    value={highLossAcknowledged}
                    onClick={() => setHighLossAcknowledged(!highLossAcknowledged)}
                  />
                  <div>
                    <p style={{ color: "red", paddingRight: 5 }}> I confirm a high loss of </p>
                    <p style={{ color: "red", paddingRight: 5 }}> {losses} units during the run. </p>
                  </div>
                </Stack>
              </Fade>
            </TableCell>
            <TableCell align="right">
              <LoadingActionButton
                onClick={async () => {
                  await form.submitForm();
                  form.resetForm();
                }}
                disabled={!form.isValid || (lossesAreHigh && !highLossAcknowledged)}>
                Add run
              </LoadingActionButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
