import React, { useCallback, useState } from "react";
import { useContext } from "react";
import { DialogContext } from "./dialog-manager";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import CloseIcon from "@mui/icons-material/Close";

/** Represents a singleton instance. There can be only a global dialog open at any time in the whole app
 * Note: Do not use directly. Intention is to be used programmatically through the hooks in [use-dialog.tsx]
 * */
export const GlobalDialog = () => {
  const { isShown, body, handleClose, title, confirmAction, cancelAction, customProps, showCloseIcon } =
    useContext(DialogContext);
  const [loading, setLoading] = useState<boolean>(false);
  const onClose = useCallback(
    (_event, reason) => {
      if ((cancelAction || showCloseIcon || (reason !== "backdropClick" && reason !== "escapeKeyDown")) && !loading) {
        cancelAction?.onClick ? cancelAction.onClick() : handleClose();
      }
    },
    [cancelAction, handleClose, loading, showCloseIcon],
  );

  return (
    <>
      {isShown && (
        <Dialog open={true} onClose={onClose} {...customProps?.dialog}>
          {showCloseIcon && (
            <IconButton
              onClick={handleClose}
              size="small"
              sx={{
                color: "common.grey5",
                position: "absolute",
                right: theme => theme.spacing(3),
                top: theme => theme.spacing(3),
              }}>
              <CloseIcon />
            </IconButton>
          )}
          {title && <>{React.isValidElement(title) ? <>{title}</> : <DialogTitle>{title}</DialogTitle>}</>}
          {body && <DialogContent {...customProps?.content}>{body}</DialogContent>}
          {(confirmAction || cancelAction) && (
            <DialogActions {...customProps?.actions}>
              {cancelAction && (
                <LoadingActionButton
                  variant={"outlined"}
                  onClick={async _ => {
                    setLoading(true);
                    await cancelAction.onClick();
                    setLoading(false);
                  }}
                  loading={loading}
                  color={cancelAction?.color ?? undefined}
                  disabled={cancelAction.disabled ?? false}>
                  {cancelAction?.title ?? "Cancel"}
                </LoadingActionButton>
              )}
              {confirmAction && (
                <LoadingActionButton
                  variant={"contained"}
                  loading={loading}
                  color={confirmAction?.color ?? undefined}
                  onClick={async () => {
                    setLoading(true);
                    await confirmAction.onClick();
                    setLoading(false);
                  }}
                  disabled={confirmAction.disabled ?? false}>
                  {confirmAction?.title ?? "Confirm"}
                </LoadingActionButton>
              )}
            </DialogActions>
          )}
        </Dialog>
      )}
    </>
  );
};
