import React from "react";
import { BaseWizardCard } from "./base-wizard-card";
import { RemovableFileItem, FilePicker } from "../file-picker";
import { Alert, Stack } from "@mui/material";

export function PurchaseOrderUploadCard({
  file,
  onFileSelected,
  onFileRemoved,
}: {
  file: File | undefined;
  onFileSelected: (files: File) => void;
  onFileRemoved: () => void;
}) {
  return (
    <BaseWizardCard
      title="Purchase Order addressed to Ferovinum"
      subtitle="If available, this can be uploaded to complete the order."
      titleTooltip="Only available for select purchasers."
      inputElement={
        <Stack spacing={2} width={"100%"}>
          <Alert severity="info">
            Please note: this functionality is only available for Purchase Orders that have been addressed to Ferovinum
            specifically by name as the credit counterparty to the transaction.
          </Alert>
          <FilePicker accept="application/pdf" onFilesAccepted={files => onFileSelected(files[0])} />
          {file && <RemovableFileItem file={file} onClick={onFileRemoved} />}
        </Stack>
      }
    />
  );
}
