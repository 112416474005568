import React, { MouseEventHandler } from "react";

import { ContractNoteDocumentView } from "../../widgets/contract-note/contract-note-document-view";
import { useInfoDialog } from "../../context/global-dialog/use-dialog";
import { StorageInfo } from "adl-gen/ferovinum/app/api";
import { ContractNoteView } from "adl-gen/ferovinum/app/views";
import Button from "@mui/material/Button";

export interface LegacyDownloadContractPDFButtonProps {
  contractNoteView: ContractNoteView;
  storageInfo: StorageInfo;
  buttonText?: string;
}

export const LegacyDownloadContractPDFButton: React.FC<LegacyDownloadContractPDFButtonProps> = ({
  contractNoteView,
  storageInfo,
  buttonText,
}) => {
  const buttonDisplayText = buttonText ? buttonText : "DOWNLOAD PDF";
  const { showInfoDialog } = useInfoDialog();

  const viewContractNote: MouseEventHandler = async e => {
    e.stopPropagation();
    e.preventDefault();
    await showInfoDialog({
      body: (
        <>
          {contractNoteView && storageInfo && (
            <ContractNoteDocumentView
              contractNote={contractNoteView}
              storageLocationDetails={{
                name: storageInfo?.locationName,
                storageAccountCode: storageInfo?.locationAccountCode,
              }}
            />
          )}
        </>
      ),
    });
    return;
  };

  return (
    <Button variant="outlined" onClick={viewContractNote}>
      {buttonDisplayText}
    </Button>
  );
};
