import { Stack, StackProps, Typography, TypographyProps } from "@mui/material";
import React from "react";
import { CurrencyRenderer, CurrencyValue } from "./currency-renderer";
import { Currency } from "adl-gen/ferovinum/app/db";

export interface ProvisionalCurrencyRendererProps extends TypographyProps {
  base: {
    /**
     * The base value
     */
    value: CurrencyValue;
    /**
     * The base currency
     */
    currency: Currency;
  };
  provisional: {
    /**
     * The provisional value to be rendered in brackets
     */
    value?: CurrencyValue;
    /**
     * The provisional currency
     */
    currency?: Currency;
  };
  /**
   * The minimum number of fraction digits to display.
   */
  minimumFractionDigits?: number;
  /**
   * The maximum number of fraction digits to display.
   */
  maximumFractionDigits?: number;
  /**
   * Determines whether to show a tooltip for the currency value.
   */
  showTooltip?: boolean;
  /**
   * The value to be displayed when the main value is empty.
   */
  emptyValue?: string;
  justify?: StackProps["justifyContent"];
}

/**
 * This component will render currencies in the shape "$base (£provisional)" ->  "$100.51 (£80.56)"
 * If the base and provisional currencies are the same, only the base currency will be shown
 */
export const ProvisionalCurrencyRenderer = ({
  base,
  provisional,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  showTooltip = true,
  emptyValue,
  justify = "flex-end",
  ...rest
}: ProvisionalCurrencyRendererProps) => {
  const baseCurrencyComponent = (
    <CurrencyRenderer
      currency={base.currency}
      value={base.value}
      minimumFractionDigits={minimumFractionDigits}
      maximumFractionDigits={maximumFractionDigits}
      showTooltip={showTooltip}
      emptyValue={emptyValue}
      {...rest}
    />
  );

  // If base and provisional currencies are the same, or no provisional currency was provided
  // then return the formatted base currency.
  if (base.currency === provisional.currency || !provisional.currency) {
    return baseCurrencyComponent;
  }

  return (
    <Stack direction={"row"} spacing={0.5} justifyContent={justify}>
      {baseCurrencyComponent}
      <Typography {...rest}>{"("}</Typography>
      <CurrencyRenderer
        currency={provisional.currency}
        value={provisional.value}
        minimumFractionDigits={minimumFractionDigits}
        maximumFractionDigits={maximumFractionDigits}
        showTooltip={showTooltip}
        emptyValue={emptyValue}
        {...rest}
      />
      <Typography {...rest}>{")"}</Typography>
    </Stack>
  );
};
