import { OrganisationDealExtensionRequestAction } from "adl-gen/ferovinum/app/api";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { Loader } from "components/widgets/loader/loader";
import React, { useCallback } from "react";
import { useParams } from "react-router";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { isLoaded } from "utils/utility-types";
import { useAppService } from "../../../../../hooks/use-app-service";
import { OrganisationDealExtensionRequestDetailsPageView } from "./organisation-deal-extension-request-details-page-view";

export const OrganisationDealExtensionRequestDetailsPage = () => {
  const service = useAppService();
  const { dealExtensionRequestId } = useParams<{
    dealExtensionRequestId: string;
  }>();
  const [showAlert] = useAlert();

  const loadContractNote = useCallback(
    async () =>
      await service.getContractNote({
        kind: "sourceRequest",
        value: { kind: "dealExtensionRequestId", value: dealExtensionRequestId },
      }),
    [dealExtensionRequestId, service],
  );
  const [loadingContractNote] = useLoadingDataState(loadContractNote);

  const loadDealExtensionRequestDetails = useCallback(
    async () => await service.getOrganisationDealExtensionRequestDetails({ dealExtensionRequestId }),
    [dealExtensionRequestId, service],
  );
  const [loadingDealExtensionRequestDetails, refreshDealExtensionRequestDetails] = useLoadingDataState(
    loadDealExtensionRequestDetails,
    true,
  );

  const onClickAction = useCallback(
    async (action: OrganisationDealExtensionRequestAction) => {
      const resp = await service.progressDealExtensionRequestState({ dealExtensionRequestId, action });
      if (resp !== "success") {
        void showAlert({
          title: "Error",
          body: "We are unable to perform this action for this deal. Please try again.",
          severity: "error",
        });
      }
      await refreshDealExtensionRequestDetails();
    },
    [dealExtensionRequestId, refreshDealExtensionRequestDetails, service, showAlert],
  );

  return (
    <Loader fullScreen loadingStates={[loadingDealExtensionRequestDetails, loadingContractNote]}>
      {isLoaded(loadingDealExtensionRequestDetails) && (
        <OrganisationDealExtensionRequestDetailsPageView
          {...loadingDealExtensionRequestDetails.value}
          onClickAction={onClickAction}
          getContractNotePdfUrl={
            isLoaded(loadingContractNote) && loadingContractNote.value?.pdfUrl
              ? async () => {
                  if (loadingContractNote.value?.pdfUrl) {
                    const getDownloadUrlResp = await service.getDownloadUrl({
                      downloadCategory: "ContractNote",
                      path: loadingContractNote.value.pdfUrl,
                    });
                    return getDownloadUrlResp.downloadUrl;
                  } else {
                    return undefined;
                  }
                }
              : undefined
          }
        />
      )}
    </Loader>
  );
};
