import React from "react";
import { Stack, Typography, TypographyProps } from "@mui/material";
import { SxProps, Theme } from "@mui/system";

export interface InfoFieldProps extends Omit<TypographyProps, "variant"> {
  label: string;
  value: string;
  sx?: SxProps<Theme>;
}

/** Widget that displays a field label with its value */
export const InfoField = (props: InfoFieldProps) => {
  const { label, value, sx } = props;

  return (
    <Stack sx={sx}>
      <Typography color="common.grey6" {...props} variant="body2">
        {label}
      </Typography>
      <Typography color="common.grey6" {...props} variant="subtitle1Bold">
        {value}
      </Typography>
    </Stack>
  );
};
