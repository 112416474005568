import React, { useCallback } from "react";
import { RevaluationRequestOverview } from "./revaluation-request-dashboard-page-view";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { isLoaded } from "utils/utility-types";
import { Loader } from "components/widgets/loader/loader";
import { useAppService } from "../../../../hooks/use-app-service";
import { assertNotUndefined } from "utils/hx/util/types";
import { useSelectedOrgId } from "../../../layouts/portal-page-layout/portal-page";

export const RevaluationRequestOverviewPage = () => {
  const service = useAppService();
  const organisationId = assertNotUndefined(useSelectedOrgId());

  const loadRevaluationRequests = useCallback(async () => {
    const res = await service.revaluationRequestOverview(organisationId);
    return res;
  }, [service, organisationId]);

  const [loadingRevaluationRequests] = useLoadingDataState(loadRevaluationRequests);

  return (
    <Loader loadingStates={[loadingRevaluationRequests]} fullScreen>
      {isLoaded(loadingRevaluationRequests) && (
        <RevaluationRequestOverview revaluationRequests={loadingRevaluationRequests.value} />
      )}
    </Loader>
  );
};
