import {
  NegativeStockAdjustmentProductSaleView,
  PositiveStockAdjustmentDetailsView,
  PositiveStockAdjustmentState,
  StockAdjustment,
} from "adl-gen/ferovinum/app/api";
import { NegativeStockAdjustmentState, NumberOfUnits, Product, TopLevelUnitType } from "adl-gen/ferovinum/app/db";
import { Instant } from "adl-gen/common";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";
import { WithDbId } from "adl-gen/common/db";
import { useAppService } from "./use-app-service";
import { AppService } from "adl-gen/app-service";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { useCallback } from "react";
import { adlMapToMap, getTopLevelForUnitType } from "utils/adl-utils";

export interface NonReimbursedStockAdjustment {
  // Only for negative stock adjustments
  rotationNumber: string | null;
  numberOfUnits: NumberOfUnits;
  // Only applicable to positive stock adjustments
  //TODO(Berto): Split into an union type
  state?: PositiveStockAdjustmentState;
}
export type NonReimbursedStockAdjustments = Map<WithDbId<Product>, NonReimbursedStockAdjustment[]>;

export type ReimbursedStockAdjustments = {
  adjustments: Map<WithDbId<Product>, NegativeStockAdjustmentProductSaleView[]>;
  grossReimbursementCost: MonetaryValue;
  storageFees?: MonetaryValue;
  landingFees?: MonetaryValue;
  netReimbursementCost?: MonetaryValue;
  state: NegativeStockAdjustmentState;
};

export type StockAdjustmentDetailsView = (
  | {
      kind: "positiveStockAdjustment";
      stockAdjustments: NonReimbursedStockAdjustments;
    }
  | {
      kind: "negativeStockAdjustment";
      stockAdjustments:
        | { kind: "noReimbursement"; value: NonReimbursedStockAdjustments }
        | {
            kind: "reimbursement";
            value: ReimbursedStockAdjustments;
          };
    }
) & {
  id: string;
  referenceNumber: string;
  createdAt: Instant;
  reason: string;
  topLevelUnitType: TopLevelUnitType;
};

const loadPositiveStockAdjustmentDetails = async (
  appService: AppService,
  positiveStockAdjustmentId: string,
): Promise<StockAdjustmentDetailsView> => {
  const resp: PositiveStockAdjustmentDetailsView = await appService.getPositiveStockAdjustmentDetails({
    positiveStockAdjustmentId,
  });

  //Note(Berto): Assumes all products in the stock adjustment have the same top level unit type
  const topLevelUnitType = getTopLevelForUnitType(resp.productItemViews[0].product.value.unitType);
  return {
    id: positiveStockAdjustmentId,
    kind: "positiveStockAdjustment",
    referenceNumber: resp.positiveStockAdjustment.referenceNumber,
    createdAt: resp.positiveStockAdjustment.createdAt,
    reason: resp.positiveStockAdjustment.comment ?? "",
    topLevelUnitType,
    stockAdjustments: new Map(
      resp.productItemViews.map(p => {
        return [p.product, p.productBreakdown];
      }),
    ),
  };
};

const loadNegativeStockAdjustmentDetails = async (
  appService: AppService,
  negativeStockAdjustmentId: string,
): Promise<StockAdjustmentDetailsView> => {
  const resp = await appService.getNegativeStockAdjustmentDetails({ negativeStockAdjustmentId });
  //Note(Berto): Assumes all products in the stock adjustment have the same top level unit type
  const topLevelUnitType = getTopLevelForUnitType(
    resp.details.kind === "withReimbursement"
      ? resp.details.value.grossCosts.previews[0].key.value.unitType
      : resp.details.value.stockAdjustments[0].key.value.unitType,
  );

  return {
    kind: "negativeStockAdjustment",
    id: negativeStockAdjustmentId,
    ...resp,
    referenceNumber: resp.referenceNumber,
    createdAt: resp.createdAt,
    reason: resp.comment ?? "",
    topLevelUnitType,
    stockAdjustments:
      resp.details.kind === "withReimbursement"
        ? {
            kind: "reimbursement",
            value: {
              grossReimbursementCost: resp.details.value.grossCosts.grossReimbursementCost,
              storageFees: resp.details.value.additionalCosts?.storage,
              landingFees: resp.details.value.additionalCosts?.landing,
              netReimbursementCost: resp.details.value.netCost ?? undefined,
              state: resp.details.value.state,
              adjustments: adlMapToMap(resp.details.value.grossCosts.previews),
            },
          }
        : {
            kind: "noReimbursement",
            value: new Map(
              resp.details.value.stockAdjustments.map(({ key, value }) => {
                return [
                  key,
                  value.map(v => ({
                    rotationNumber: v.rotationNumber,
                    numberOfUnits: v.units,
                  })),
                ];
              }),
            ),
          },
  };
};

/** Hook that returns the details of a stock adjustment relevant to a storage location */
export const useStorageLocationStockAdjustmentDetails = (
  stockAdjustmentId: string,
  variant: StockAdjustment["kind"],
) => {
  const appService = useAppService();
  const [loadingDetails, refresh] = useLoadingDataState<StockAdjustmentDetailsView>(
    useCallback(async () => {
      if (variant === "positiveStockAdjustment") {
        return await loadPositiveStockAdjustmentDetails(appService, stockAdjustmentId);
      }
      return await loadNegativeStockAdjustmentDetails(appService, stockAdjustmentId);
    }, [variant, appService, stockAdjustmentId]),
  );
  return { loadingDetails, refresh };
};
