import React from "react";
import { Stack, Typography } from "@mui/material";
import { ConfirmationDialog } from "components/widgets/confirmation-dialog/confirmation-dialog";

export interface AcceptPurchaseRequestDeliveryDialogProps {
  open: boolean;
  onAccept: () => Promise<void>;
  onCancel: () => void;
}

export const AcceptPurchaseRequestDeliveryDialog = ({
  open,
  onAccept,
  onCancel,
}: AcceptPurchaseRequestDeliveryDialogProps) => {
  return (
    <ConfirmationDialog
      title="Accept purchase request"
      open={open}
      maxWidth="xs"
      fullWidth
      acceptAction={{
        title: "Confirm",
        onClick: async () => {
          await onAccept();
        },
      }}
      cancelAction={{ title: "Back", onClick: onCancel }}>
      <Stack spacing={3}>
        <Typography>Would you like to approve this purchase request?</Typography>
      </Stack>
    </ConfirmationDialog>
  );
};
