import React from "react";
import * as _ from "lodash";
import { Box, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { assertValueLoaded, LoadingValue } from "utils/utility-types";
import { Currency, DealLeg } from "adl-gen/ferovinum/app/db";
import { CurrencyRange } from "components/widgets/currency-renderer/currency-range";
import { DbKey } from "adl-gen/common/db";

export interface FspRangeProps<T> {
  currency: Currency;
  values: Record<DbKey<DealLeg>, LoadingValue<T>>;
  transformation?(fspVal: number): number;
  accessor: (val: T) => number;
}

/** Displays a currency range range */
export const FspRange = <T,>({ currency, accessor, values, transformation = identity }: FspRangeProps<T>) => {
  const { anyLoading, anyError } = validateValues<T>(values);

  if (anyLoading) {
    return <Box>Loading...</Box>;
  } else if (anyError) {
    return (
      <Tooltip describeChild title={"Error calculating range"}>
        <StyledError>Error</StyledError>
      </Tooltip>
    );
  } else {
    return (
      <Box>
        <CurrencyRange
          currency={currency}
          values={Object.values(values).map(val => transformation(_.toNumber(accessor(assertValueLoaded(val)))))}
        />
      </Box>
    );
  }
};

const StyledError = styled("div")(({ theme }) => ({
  color: theme.palette.common.red,
  fontWeight: theme.typography.fontWeightBold,
}));

// NOTE(Barry): Not using _.identity because TS complains that function could also return undefined
const identity = (val: number) => val;

function validateValues<T>(values: Record<DbKey<DealLeg>, LoadingValue<T>>) {
  let anyLoading = false;
  let anyError: Error | undefined = undefined;
  Object.values(values).forEach(val => {
    if (val === undefined || val.state === "loading") {
      anyLoading = true;
    } else if (val.state === "error") {
      anyError = val.error;
    }
  });
  return { anyLoading, anyError };
}
