import { useEffect } from "react";
import { FormikErrors } from "formik";

export interface ScrollToFieldErrorProps<T = unknown> {
  isValid: boolean;
  submitCount: number;
  errors: FormikErrors<T>;
  fieldNames?: string[];
}
// Component that auto scrolls the user view into the first errored field of a formik form when they try to submit the form
// Place this component anywhere in the render after the formik hook has been declared
// Note: It relies on naming array fields by a dot notation
// I.e: selectedProducts.0.purchasePrice

// Alternatively, you can pass an array of field names to the fieldNames prop to specify which fields to scroll to
// I.e: <ScrollToFieldError fieldNames={['selectedProducts.0.purchasePrice', 'selectedProducts.0.quantity']} />
export const ScrollToFieldError = ({ isValid, submitCount, errors, fieldNames }: ScrollToFieldErrorProps) => {
  // This code below forces a re-run of the useEffect on the first submit validation
  // formik will increase the submitCount before the errors have been set
  const nErrors = fieldNames ? fieldNames.length : Object.keys(errors).length;
  // Note(Berto):
  // We rely in submitCount from formik in order to know when to run this hook effect
  useEffect(() => {
    if (isValid) return;
    const fieldErrorNames = fieldNames ?? getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);
    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [submitCount, nErrors]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

function getFieldErrorNames<T>(formikErrors: FormikErrors<T>) {
  const transformObjectToDotNotation = (obj: object, prefix = "", result: Array<string> = []) => {
    Object.keys(obj).forEach(key => {
      // @ts-ignore
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
}
