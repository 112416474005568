import { Box, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import { Link } from "components/widgets/link/link";
import React from "react";
import { AppRoutes } from "../../../../app/app-routes";
import { OrganisationPurchaseRequestData } from "../../../../hooks/use-purchase-request";
import { DeliveryUpdatesCard } from "../../card/delivery-updates-card/delivery-updates-card";
import {
  PurchaseRequestStepper,
  PurchaseRequestStepperProps,
} from "../purchase-request-stepper/purchase-requests-stepper";

export const OrganisationPurchaseRequestProgressSection = ({
  ...purchaseRequestData
}: OrganisationPurchaseRequestData) => {
  const {
    paymentTermsPeriod,
    timeline,
    productionOrderReference,
    stateEvents,
    collectionDays,
    state,
    purchaseRequestDeliveryOption,
    deliveryStatuses,
    purchaserName,
    collectedDate,
    deliveredDate,
  } = purchaseRequestData;
  return (
    <>
      {purchaseRequestData.productionOrderReference && (
        <Grid item xs={12}>
          <Stack spacing={1} direction="row">
            <Typography>{"Linked to production order "}</Typography>
            <Link
              href={`${AppRoutes.OrgProductionOrderDetails}/${purchaseRequestData.productionOrderReference.productionOrderId}`}>
              {purchaseRequestData.productionOrderReference.orderNumber}
            </Link>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12} lg={6}>
        <Stack spacing={5}>
          {purchaseRequestDeliveryOption.kind === "deliveryToNominatedPurchaser" && (
            <>
              <DeliveryUpdatesCard deliveryStatuses={deliveryStatuses} />
            </>
          )}
          <ProgressStepperCard
            purchaseRequest={{
              paymentTermsPeriod,
              purchaseRequestDeliveryOption,
              stateEvents,
              collectionDays,
              state,
              purchaserName,
              withProductionOrder: productionOrderReference !== undefined,
              collectedDate,
              deliveredDate,
            }}
            timeline={timeline}
          />
        </Stack>
      </Grid>
    </>
  );
};

const ProgressStepperCard = ({ timeline, purchaseRequest }: PurchaseRequestStepperProps) => {
  return (
    <Card>
      <Stack divider={<Divider />} height="100%">
        <Box display={"flex"} padding={2}>
          <Typography variant="caption" color="common.grey5" lineHeight={"14px"}>
            Progress
          </Typography>
        </Box>
        <Box paddingLeft={2} paddingBottom={2}>
          <PurchaseRequestStepper purchaseRequest={purchaseRequest} timeline={timeline} />
        </Box>
      </Stack>
    </Card>
  );
};
