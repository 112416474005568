/* @generated from adl module ferovinum.app.messaging */

import * as ADL from './../../runtime/adl';
import * as ferovinum_app_db from './db';

export interface DepositInvoiced {
  invoiceNumber: string;
}

export function makeDepositInvoiced(
  input: {
    invoiceNumber: string,
  }
): DepositInvoiced {
  return {
    invoiceNumber: input.invoiceNumber,
  };
}

const DepositInvoiced_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.messaging","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"invoiceNumber","default":{"kind":"nothing"},"name":"invoiceNumber","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}]}},"name":"DepositInvoiced","version":{"kind":"nothing"}}};

export const snDepositInvoiced: ADL.ScopedName = {moduleName:"ferovinum.app.messaging", name:"DepositInvoiced"};

export function texprDepositInvoiced(): ADL.ATypeExpr<DepositInvoiced> {
  return {value : {typeRef : {kind: "reference", value : snDepositInvoiced}, parameters : []}};
}

export interface PurchaseOrderCreated {
  purchaseOrderNumber: string;
}

export function makePurchaseOrderCreated(
  input: {
    purchaseOrderNumber: string,
  }
): PurchaseOrderCreated {
  return {
    purchaseOrderNumber: input.purchaseOrderNumber,
  };
}

const PurchaseOrderCreated_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.messaging","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"purchaseOrderNumber","default":{"kind":"nothing"},"name":"purchaseOrderNumber","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}]}},"name":"PurchaseOrderCreated","version":{"kind":"nothing"}}};

export const snPurchaseOrderCreated: ADL.ScopedName = {moduleName:"ferovinum.app.messaging", name:"PurchaseOrderCreated"};

export function texprPurchaseOrderCreated(): ADL.ATypeExpr<PurchaseOrderCreated> {
  return {value : {typeRef : {kind: "reference", value : snPurchaseOrderCreated}, parameters : []}};
}

export interface PurchaseRequestStateChangeFeedback {
  purchaseRequestId: ferovinum_app_db.PurchaseRequestId;
  feedbackType: PurchaseRequestStateChangeFeedbackType;
}

export function makePurchaseRequestStateChangeFeedback(
  input: {
    purchaseRequestId: ferovinum_app_db.PurchaseRequestId,
    feedbackType: PurchaseRequestStateChangeFeedbackType,
  }
): PurchaseRequestStateChangeFeedback {
  return {
    purchaseRequestId: input.purchaseRequestId,
    feedbackType: input.feedbackType,
  };
}

const PurchaseRequestStateChangeFeedback_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.messaging","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"purchaseRequestId","default":{"kind":"nothing"},"name":"purchaseRequestId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"ferovinum.app.db","name":"PurchaseRequestId"}},"parameters":[]}},{"annotations":[],"serializedName":"feedbackType","default":{"kind":"nothing"},"name":"feedbackType","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"ferovinum.app.messaging","name":"PurchaseRequestStateChangeFeedbackType"}},"parameters":[]}}]}},"name":"PurchaseRequestStateChangeFeedback","version":{"kind":"nothing"}}};

export const snPurchaseRequestStateChangeFeedback: ADL.ScopedName = {moduleName:"ferovinum.app.messaging", name:"PurchaseRequestStateChangeFeedback"};

export function texprPurchaseRequestStateChangeFeedback(): ADL.ATypeExpr<PurchaseRequestStateChangeFeedback> {
  return {value : {typeRef : {kind: "reference", value : snPurchaseRequestStateChangeFeedback}, parameters : []}};
}

export type PurchaseRequestStateChangeFeedbackType = 'nominatedPurchaserInvoiced';
export const valuesPurchaseRequestStateChangeFeedbackType : PurchaseRequestStateChangeFeedbackType[] = ['nominatedPurchaserInvoiced'];

const PurchaseRequestStateChangeFeedbackType_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.messaging","decl":{"annotations":[],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"nominatedPurchaserInvoiced","default":{"kind":"nothing"},"name":"nominatedPurchaserInvoiced","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}}]}},"name":"PurchaseRequestStateChangeFeedbackType","version":{"kind":"nothing"}}};

export const snPurchaseRequestStateChangeFeedbackType: ADL.ScopedName = {moduleName:"ferovinum.app.messaging", name:"PurchaseRequestStateChangeFeedbackType"};

export function texprPurchaseRequestStateChangeFeedbackType(): ADL.ATypeExpr<PurchaseRequestStateChangeFeedbackType> {
  return {value : {typeRef : {kind: "reference", value : snPurchaseRequestStateChangeFeedbackType}, parameters : []}};
}

export interface PurchaseBillCreated {
  invoiceNumber: string;
}

export function makePurchaseBillCreated(
  input: {
    invoiceNumber: string,
  }
): PurchaseBillCreated {
  return {
    invoiceNumber: input.invoiceNumber,
  };
}

const PurchaseBillCreated_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.messaging","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"invoiceNumber","default":{"kind":"nothing"},"name":"invoiceNumber","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}]}},"name":"PurchaseBillCreated","version":{"kind":"nothing"}}};

export const snPurchaseBillCreated: ADL.ScopedName = {moduleName:"ferovinum.app.messaging", name:"PurchaseBillCreated"};

export function texprPurchaseBillCreated(): ADL.ATypeExpr<PurchaseBillCreated> {
  return {value : {typeRef : {kind: "reference", value : snPurchaseBillCreated}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "ferovinum.app.messaging.DepositInvoiced" : DepositInvoiced_AST,
  "ferovinum.app.messaging.PurchaseOrderCreated" : PurchaseOrderCreated_AST,
  "ferovinum.app.messaging.PurchaseRequestStateChangeFeedback" : PurchaseRequestStateChangeFeedback_AST,
  "ferovinum.app.messaging.PurchaseRequestStateChangeFeedbackType" : PurchaseRequestStateChangeFeedbackType_AST,
  "ferovinum.app.messaging.PurchaseBillCreated" : PurchaseBillCreated_AST
};
