import React from "react";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { Grid } from "@mui/material";
import { formatInstant } from "utils/date-utils";
import { StickyNote } from "../../../../widgets/sticky-note/sticky-note";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { StockAdjustmentsTable } from "../../../../widgets/stock-adjustments/stock-adjustments-table/stock-adjustments-table";
import { StockAdjustmentDetailsView } from "../../../../../hooks/use-stock-adjustment";
import { useSettlementCurrency } from "../../../../layouts/portal-page-layout/portal-page";

export interface StorageLocStockAdjustmentDetailsPageViewProps {
  details: StockAdjustmentDetailsView;
}
export const StorageLocAdjustmentDetailsPageView = ({ details }: StorageLocStockAdjustmentDetailsPageViewProps) => {
  const settlementCurrency = useSettlementCurrency();
  return (
    <PortalPageContent header={<StockAdjustmentDetailsHeader details={details} />}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <StockAdjustmentsTable
            topLevelUnitType={details.topLevelUnitType}
            currency={settlementCurrency}
            variant={
              details.kind === "positiveStockAdjustment"
                ? {
                    kind: details.kind,
                    stockAdjustments: details.stockAdjustments,
                  }
                : {
                    kind: details.kind,
                    stockAdjustments:
                      details.stockAdjustments.kind === "reimbursement"
                        ? { kind: "reimbursement", value: details.stockAdjustments.value }
                        : { kind: "noReimbursement", value: details.stockAdjustments.value },
                  }
            }
          />
        </Grid>
        {details.reason && (
          <Grid item xs={12}>
            <StickyNote title="Reason for stock adjustment" line1={details.reason} />
          </Grid>
        )}
      </Grid>
    </PortalPageContent>
  );
};

const StockAdjustmentDetailsHeader = ({ details }: { details: StockAdjustmentDetailsView }) => {
  const isPositive = details.kind === "positiveStockAdjustment";
  return (
    <PortalPageContentHeader
      title={`${isPositive ? "Positive" : "Negative"} stock adjustment reference: ${details.referenceNumber}`}
      subtitles={[
        {
          text: `Recorded on: ${formatInstant(details.createdAt)}`,
          icon: "date",
        },
      ]}
    />
  );
};
