/** This prefix is used for all routes in the admin portal app
 * - admin-portal lives in a subfolder of the main ferovinum domain
 * - react-router uses this to create the correct url in the browser address bar
 * - the nginx server uses this to route to serve assets from the correct folder when running in production mode
 * */
export const APP_ROUTER_BASENAME = "/admin-portal";

/** All routes in the admin portal app */
export const enum AppRoutes {
  Index = "/",
  Dashboard = "/dashboard",
  Login = "/login",
  Logout = "/logout",
  Admin = "/admin",
  Treasury = "/treasury",
  NewDealRequests = "/new-deal-requests",
  OverdueNewDealRequests = "/new-deal-requests/overdue",
  ViewNewDealRequest = "/new-deal-request/view",
  PurchaseRequestDetails = "/purchase-request",
  PurchaserCreditLimits = "/purchase-requests/credit-limits",
  PurchaseRequests = "/purchase-requests/dashboard",

  StockSyncList = "/stock-sync-list",
  StockSyncCSVUpload = "/stock-sync/csv-upload",
  StockSyncPendingAndReservedUnits = "/stock-sync/pending-and-reserved-units",
  StockSyncProdOrders = "/stock-sync/prod-orders",
  StockSyncNewTankProdOrders = "/stock-sync/new-tank-prod-orders",
  StockSyncNewProdOrders = "/stock-sync/new-prod-orders",
  StockSyncConfirmation = "/stock-sync/confirmation",

  SettlementCreditLimit = "/settlement-credit-limit",
  ExpiringStock = "/expiring-stock",
  OrganisationExpiringStock = "/expiring-stock/organisation",
  DealRenewalRequests = "/expiring-stock/deal-renewal-requests",
  ViewDealRenewalRequest = "/expiring-stock/deal-renewal-request/view",

  AssetRevaluation = "/revaluation",
  RequestWorkflows = "/request-workflows",
  RequestWorkflowDetails = "/request-workflow-details",
  InitiateRevaluationRequest = "/revaluation/initiate",
  CreateRevaluationRequest = "/revaluation/create",
  ConfirmRevaluationRequest = "/revaluation/confirm",
  ViewRevaluationRequest = "/revaluation/view",

  LegacyAppAdmin = "/legacy-app-admin",
}
