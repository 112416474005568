import { RefObject, useEffect, useState } from "react";

interface Dimensions {
  width: number;
  height: number;
}
export const useRefDimensions = (ref: RefObject<HTMLElement>) => {
  const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 });
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  useEffect(() => {
    if (windowWidth || windowHeight) {
      // Note: to trigger recalculating of the dimensions if the window height / width changes
    }
    if (ref.current) {
      const { current } = ref;
      const boundingRect = current.getBoundingClientRect();
      const { width, height } = boundingRect;
      setDimensions({ width: Math.round(width), height: Math.round(height) });
    }
  }, [ref, windowHeight, windowWidth]);
  return dimensions;
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Dimensions>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
