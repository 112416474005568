import React from "react";
import { OrganisationPurchaseRequestSummaryTable } from "../organisation-purchase-request-summary-table/organisation-purchase-request-summary-table";
import { OrganisationPurchaseRequestValuationCard } from "../organisation-purchase-request-valuation-card/organisation-purchase-request-valuation-card";
import { useTermsDialog } from "components/context/global-dialog/use-dialog";
import { getIncoterms } from "../../../../utils/purchase-request-utils";
import { PurchaseRequestTermsCard } from "../../card/purchase-request-terms/purchase-request-terms-card";
import { Box, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import { Link } from "components/widgets/link/link";
import { PurchaseRequestCollection } from "../../card/purchase-request-collection/purchase-request-collection";
import { DeliveryDetailsCard } from "../../card/delivery-details-card/delivery-details-card";
import {
  PurchaseRequestStepper,
  PurchaseRequestStepperProps,
} from "../purchase-request-stepper/purchase-requests-stepper";
import {
  OrgPurchaseRequestSummary,
  OrgRejectedPurchaseRequestSummary,
  PurchaseRequestLineItem,
  PurchaseRequestTimeline,
} from "adl-gen/ferovinum/app/nompurchaser";
import { AppRoutes } from "../../../../app/app-routes";
import { RejectedPurchaseRequestValuationCard } from "../rejected-purchase-request-valuation-card/rejected-purchase-request-valuation-card";
import {
  NominatedPurchaserTerms,
  ProductionOrderId,
  PurchaseRequestDeliveryOption,
  PurchaseRequestPaymentTermsPeriod,
  PurchaseRequestState,
  PurchaseRequestStateEvent,
  StorageLocation,
} from "adl-gen/ferovinum/app/db";
import {
  OrganisationPurchaseRequestData,
  OrganisationRejectedOrExpiredPurchaseRequestData,
  OrganisationValidPurchaseRequestData,
} from "../../../../hooks/use-purchase-request";
import { LocalDate } from "adl-gen/common";

export const OrganisationPurchaseRequestOverviewSection = ({
  previewProps,
  ...purchaseRequestData
}: {
  previewProps?: {
    isPreview: boolean;
    advanceCashUponDelivery: boolean;
    setAdvanceCashUponDelivery: (advanceUponDelivery: boolean) => void;
  };
} & OrganisationPurchaseRequestData) => {
  const { state } = purchaseRequestData;
  // Note(Dyl): Fees & other costs are estimated up to the point of purchaser paying, after which they are finalised
  const isEstimate = !purchaseRequestData.stateEvents.some(e => e.state === "purchaserPaid");
  const isRejectedOrExpired = state === "purchaserRejected" || state === "expired";

  return (
    <>
      {purchaseRequestData.productionOrderReference && (
        <Grid item xs={12}>
          <Stack spacing={1} direction="row">
            <Typography>{"Linked to production order "}</Typography>
            <Link
              href={`${AppRoutes.OrgProductionOrderDetails}/${purchaseRequestData.productionOrderReference.productionOrderId}`}>
              {purchaseRequestData.productionOrderReference.orderNumber}
            </Link>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12}>
        <OrganisationPurchaseRequestSummaryTable
          {...purchaseRequestData}
          isRejectedOrExpired={isRejectedOrExpired}
          products={purchaseRequestData.summary.lineItems as PurchaseRequestLineItem[]}
        />
        {isRejectedOrExpired ? (
          <RejectedPurchaseRequestValuationCard
            {...(purchaseRequestData as OrganisationRejectedOrExpiredPurchaseRequestData)}
            {...(purchaseRequestData.summary as OrgRejectedPurchaseRequestSummary)}
            showDuty={purchaseRequestData.summary.excludeDutyInAdvancedAmount}
          />
        ) : (
          <OrganisationPurchaseRequestValuationCard
            {...(purchaseRequestData as OrganisationValidPurchaseRequestData)}
            {...(purchaseRequestData.summary as OrgPurchaseRequestSummary)}
            {...(previewProps !== undefined
              ? {
                  ...previewProps,
                }
              : {
                  isPreview: false,
                })}
            isEstimate={isEstimate}
          />
        )}
      </Grid>
      <StickyNotes {...purchaseRequestData} />
    </>
  );
};

const StickyNotes = ({
  terms,
  paymentTermsPeriod,
  storageLocation,
  timeline,
  productionOrderReference,
  stateEvents,
  collectionDays,
  state,
  purchaseRequestDeliveryOption,
  purchaserName,
  collectedDate,
  deliveredDate,
}: {
  state: PurchaseRequestState;
  paymentTermsPeriod: PurchaseRequestPaymentTermsPeriod;
  expiryDays: number;
  collectionDays: number;
  purchaseRequestDeliveryOption: PurchaseRequestDeliveryOption;
  stateEvents: PurchaseRequestStateEvent[];
  storageLocation: StorageLocation;
  purchaserName: string;
  terms: NominatedPurchaserTerms;
  productionOrderReference?: {
    orderNumber: string;
    productionOrderId: ProductionOrderId;
  };
  timeline: PurchaseRequestTimeline;
  collectedDate: LocalDate | null;
  deliveredDate: LocalDate | null;
}) => {
  const { showTermsDialog } = useTermsDialog();
  const renderTermsNote = () => {
    return (
      <PurchaseRequestTermsCard
        paymentTermsPeriod={paymentTermsPeriod}
        showTerms={async () => {
          await showTermsDialog({ terms: [terms.terms] });
        }}
        incoterms={getIncoterms({ purchaseRequestDeliveryOption })}
      />
    );
  };
  return (
    <>
      <Grid item xs={12} lg={6}>
        <Stack spacing={5}>
          <ProgressStepperCard
            purchaseRequest={{
              paymentTermsPeriod,
              purchaseRequestDeliveryOption,
              stateEvents,
              collectionDays,
              state,
              purchaserName,
              withProductionOrder: productionOrderReference !== undefined,
              collectedDate,
              deliveredDate,
            }}
            timeline={timeline}
          />
          {purchaseRequestDeliveryOption.kind === "deliveryToNominatedPurchaser" && renderTermsNote()}
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack spacing={5}>
          {purchaseRequestDeliveryOption.kind === "collectFromStorageLocation" && (
            <>
              <PurchaseRequestCollection
                {...storageLocation}
                {...purchaseRequestDeliveryOption.value.nominatedPurchaserCollection}
              />
              {renderTermsNote()}
            </>
          )}
          {purchaseRequestDeliveryOption.kind === "deliveryToNominatedPurchaser" && (
            <DeliveryDetailsCard {...purchaseRequestDeliveryOption.value.deliveryDetails} />
          )}
        </Stack>
      </Grid>
    </>
  );
};

const ProgressStepperCard = ({ timeline, purchaseRequest }: PurchaseRequestStepperProps) => {
  return (
    <Card>
      <Stack divider={<Divider />} height="100%">
        <Box display={"flex"} padding={2}>
          <Typography variant="caption" color="common.grey5" lineHeight={"14px"}>
            Progress
          </Typography>
        </Box>
        <Box paddingLeft={2} paddingBottom={2}>
          <PurchaseRequestStepper purchaseRequest={purchaseRequest} timeline={timeline} />
        </Box>
      </Stack>
    </Card>
  );
};
