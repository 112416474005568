import React from "react";
import RemoteLogger from "../../../../packages/adl-service/remote-logger";
import { AlertContext } from "components/context/global-alert/use-alert-context";
import { HttpServiceError } from "adl-service/http-service-error";
import { clearLocalToken } from "../app/identity-state";
import { AppRoutes } from "../app/app-routes";
import { Redirect } from "react-router-dom";

function isHttpServiceError(value: Error | HttpServiceError): value is HttpServiceError {
  // @ts-ignore
  return value["status"] !== undefined;
}

type AppError = Error | HttpServiceError;

const ReportedErrors: { [key: string]: number } = {};

export class ErrorBoundary extends React.Component<Record<string, unknown>, { error: AppError | null }> {
  constructor(props: Record<string, unknown>, context: unknown) {
    super(props, context);
    this.state = { error: null };
  }

  handleError(eventType: "global error" | "unhandled rejection", error: AppError, event?: Event) {
    //Note(Berto): Safe guard for a specific Safari BUG which will throw a null error
    if (error != null) {
      // Prevent the same error from being reported again and again and again and again...
      const key = isHttpServiceError(error) ? error.additionalDetails + error.body : "randomunknown";
      const now = Date.now();
      const last = ReportedErrors[key];
      if (last && last + 60000 > Date.now()) {
        return;
      }
      ReportedErrors[key] = now;

      const { openAlert } = this.context;
      // sets this error boundary as errored
      this.setState({ error });
      // eslint-disable-next-line no-console
      console.error(`${eventType}: ${String(error)}, error:`, error, "event:", event);
      RemoteLogger.exception(error);

      if (isHttpServiceError(error)) {
        this.handleHttpServiceError(error);
      } else {
        void openAlert({
          title: "Error",
          body: "Sorry, an unhandled error has occurred. Please try refreshing and performing the action again.",
          noClose: true,
        });
      }
    }
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will not try to re-render the component that generated the error.
    return { error };
  }

  componentDidCatch(error: AppError, _: unknown) {
    this.handleError("global error", error);
  }

  componentDidMount() {
    const handleGlobalError = (event: ErrorEvent) => this.handleError("global error", event.error, event);
    window.addEventListener("error", handleGlobalError);

    const handleGlobalUnhandledRejection = (event: PromiseRejectionEvent) =>
      this.handleError("unhandled rejection", event.reason, event);
    // Global unhandled rejection handler
    window.addEventListener("unhandledrejection", handleGlobalUnhandledRejection);
  }

  render() {
    const error = this.state.error;
    // Redirect user to login if it is an authorisation error.
    if (error && isHttpServiceError(error) && error.status === 401) {
      return (
        <>
          <Redirect to={AppRoutes.Login} />
          {this.props.children}
        </>
      );
    }
    //Note: regarding fallback UI for error across the app
    // we do not render a fallback UI
    // instead we pop a global alert with the unhandled error and render as normal
    return !error && this.props.children;
  }

  handleHttpServiceError(error: HttpServiceError) {
    const { openAlert } = this.context;

    if (error.status === 401) {
      // clear the jwt token if it is an authorisation error
      clearLocalToken();
    } else {
      void openAlert({
        title: `${error.status} Error`,
        body:
          error.publicMessage ??
          "Sorry, an unhandled error has occurred. Please try refreshing and performing the action again.",
        noClose: true,
      });
    }
  }
}

ErrorBoundary.contextType = AlertContext;
