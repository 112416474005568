import {
  CollectionIncoterms,
  Country,
  Currency,
  DeliveryIncoterms,
  DeliveryStatus,
  Incoterms,
  NewDealRequestState,
  Product,
  ProductType,
  snCollectionIncoterms,
  snCountry,
  snCurrency,
  snDeliveryIncoterms,
  snIncoterms,
  snUnitType,
  UnitType,
  valuesCollectionIncoterms,
  valuesCountry,
  valuesProductType,
  valuesUnitType,
} from "adl-gen/ferovinum/app/db";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";
import { getFormLabelForUnionField, getFormLabelToUnionFieldMap } from "./adl-utils";
import { ExistingStockProcurementStateCode, NewStockProcurementStateCode } from "adl-gen/ferovinum/app/procurement";
import { assertNever } from "assert-never";

export const unitTypeToString = (unitType: UnitType) => {
  return getFormLabelForUnionField(snUnitType, unitType);
};

export const stringToUnitType = (value: string): UnitType | undefined => {
  const regex = /[()\s]/gi;
  const scapedValue = value.replace(regex, "");
  return valuesUnitType.find(v => v === camelCase(scapedValue));
};

export function isValidUnitType(v: unknown): v is UnitType {
  return (
    valuesUnitType.find(unitType => v && unitType.trim().toLowerCase() === `${v}`.trim().toLowerCase()) !== undefined
  );
}

export const titleCase = (v: string) => {
  return startCase(camelCase(v));
};

export const getCurrencySymbol = (currency: Currency): string => {
  return getFormLabelForUnionField(snCurrency, currency);
};

export const countryCodeToCountryName = (country: Country): string => {
  return getFormLabelForUnionField(snCountry, country);
};

export const stringToCountryCode = (country: string): Country | undefined => {
  const countryMap = getFormLabelToUnionFieldMap<Country>(snCountry, valuesCountry);
  for (const [code, name] of countryMap) {
    if (lowerCaseAndTrim(name) === lowerCaseAndTrim(country)) {
      return code;
    }
  }
  return undefined;
};

export const stringToProductType = (string: string): ProductType | undefined => {
  return valuesProductType.find(productType => productType === camelCase(string.trim()));
};

export const getValuesCountryNames = () => {
  const countryMap = getFormLabelToUnionFieldMap<Country>(snCountry, valuesCountry);
  return [...countryMap.values()].sort();
};

export const incotermsToString = (incoterms: Incoterms) => {
  const description = getFormLabelForUnionField(snIncoterms, incoterms);
  return `${incoterms} - ${description}`;
};

export const centilitresToHectolitres = (cl: number) => cl / 10000;

export type PurchaseRequestIncoterms = CollectionIncoterms | DeliveryIncoterms;

export function collectionIncotermsToString(option: CollectionIncoterms): string {
  return getFormLabelForUnionField(snCollectionIncoterms, option);
}

export function deliveryIncotermsToString(option: DeliveryIncoterms): string {
  return getFormLabelForUnionField(snDeliveryIncoterms, option);
}

const collectionIncotermsSet: Set<PurchaseRequestIncoterms> = new Set(valuesCollectionIncoterms);

function isCollectionIncoterms(option: PurchaseRequestIncoterms): option is CollectionIncoterms {
  return collectionIncotermsSet.has(option);
}
export function purchaseRequestIncotermsToString(option: PurchaseRequestIncoterms): string {
  return isCollectionIncoterms(option) ? collectionIncotermsToString(option) : deliveryIncotermsToString(option);
}

function lowerCaseAndTrim(string: string): string {
  return string.toLowerCase().trim();
}

export function productToString(product: Product): string {
  const { productDate, vesselSize, countryOfOrigin, alcoholByVolumePc, unitType, code, name, producerName } = product;
  const vintageYear = productDate.kind === "vintageYear" ? productDate.value : "";
  const vesselSizeString = vesselSize ? `${vesselSize.value}cl` : "";
  const unitTypeString = unitType ? `${unitTypeToString(unitType)}` : "";
  const vesselSizeAndUnitType = `${vesselSizeString} ${unitTypeString}`;
  const avb = alcoholByVolumePc ? `${alcoholByVolumePc}% AVB` : "";
  const country = countryOfOrigin ? countryCodeToCountryName(countryOfOrigin) : "";
  return [code, vintageYear ? `${vintageYear} | ${name}` : name, producerName, vesselSizeAndUnitType, avb, country]
    .map(value => value.trim())
    .filter(value => value)
    .join("\n");
}

export function stateCodeToNewDealRequestState(
  stateCode: NewStockProcurementStateCode | ExistingStockProcurementStateCode,
): NewDealRequestState {
  switch (stateCode) {
    case "NEWDEAL":
      return "newDeal";
    case "FEROACCEPTED":
      return "feroAccepted";
    case "FEROREJECTED":
      return "feroRejected";
    case "ORGACCEPTED":
      return "orgAccepted";
    case "ORGREJECTED":
      return "orgRejected";
    case "CANCELLED":
      return "cancelled";
    case "DEPOSITINVOICED":
      return "depositInvoiced";
    case "DEPOSITPAID":
      return "depositPaid";
    case "DEPOSITPAYMENTRECEIVED":
      return "depositPaymentReceived";
    case "POCREATED":
      return "poCreated";
    case "POACCEPTED":
      return "poAccepted";
    case "COLLECTIONREQUESTED":
      return "collectionRequested";
    case "COLLECTIONACCEPTED":
      return "collectionAccepted";
    case "COLLECTIONCONFIRMED":
      return "collectionConfirmed";
    case "CARRIERDELIVERYCONFIRMED":
      return "carrierDeliveryConfirmed";
    case "WAREHOUSEDELIVERYCONFIRMED":
      return "warehouseDeliveryConfirmed";
    case "STOCKTRANSFERRED":
      return "stockTransferred";
    case "PAYMENTTRANSFERRED":
      return "paymentTransferred";
    default:
      assertNever(stateCode);
  }
}

export function mapNewStockProcurementStateCode(state: NewDealRequestState): NewStockProcurementStateCode {
  switch (state) {
    case "newDeal":
      return "NEWDEAL";
    case "feroAccepted":
      return "FEROACCEPTED";
    case "feroRejected":
      return "FEROREJECTED";
    case "orgAccepted":
      return "ORGACCEPTED";
    case "orgRejected":
      return "ORGREJECTED";
    case "depositInvoiced":
      return "DEPOSITINVOICED";
    case "depositPaid":
      return "DEPOSITPAID";
    case "depositPaymentReceived":
      return "DEPOSITPAYMENTRECEIVED";
    case "poCreated":
      return "POCREATED";
    case "poAccepted":
      return "POACCEPTED";
    case "collectionRequested":
      return "COLLECTIONREQUESTED";
    case "collectionAccepted":
      return "COLLECTIONACCEPTED";
    case "collectionConfirmed":
      return "COLLECTIONCONFIRMED";
    case "carrierDeliveryConfirmed":
      return "CARRIERDELIVERYCONFIRMED";
    case "warehouseDeliveryConfirmed":
      return "WAREHOUSEDELIVERYCONFIRMED";
    default:
      throw new Error("Unexpected new stock new deal request state");
  }
}

export function mapExistingStockProcurementStateCode(state: NewDealRequestState): ExistingStockProcurementStateCode {
  switch (state) {
    case "newDeal":
      return "NEWDEAL";
    case "feroAccepted":
      return "FEROACCEPTED";
    case "feroRejected":
      return "FEROREJECTED";
    case "orgAccepted":
      return "ORGACCEPTED";
    case "orgRejected":
      return "ORGREJECTED";
    case "stockTransferred":
      return "STOCKTRANSFERRED";
    case "paymentTransferred":
      return "PAYMENTTRANSFERRED";
    default:
      throw new Error("Unexpected existing stock new deal request state");
  }
}

export const extractDeliveryStatus = (deliveryStatuses: DeliveryStatus[]) => {
  if (
    deliveryStatuses.length < 1 ||
    (!deliveryStatuses[0].bookedDate &&
      !deliveryStatuses[0].confirmedDepartedAt &&
      !deliveryStatuses[0].confirmedDeliveredAt &&
      !deliveryStatuses[0].status)
  ) {
    return "-";
  } else if (deliveryStatuses[0].confirmedDeliveredAt || deliveryStatuses[0].status === "delivered") {
    return "Delivered";
  } else if (deliveryStatuses[0].status === "deliveryPlanningHeld") {
    return "On Hold";
  } else if (deliveryStatuses[0].confirmedDepartedAt || deliveryStatuses[0].status === "outForDelivery") {
    return "Out For Delivery";
  } else if (deliveryStatuses[0].bookedDate) {
    return "Delivery Scheduled";
  } else {
    return "Scheduling Delivery";
  }
};
