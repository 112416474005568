import { makeApiServices } from "./api-utils";
import { ClientLogReq } from "adl-gen/ferovinum/app/api";

// Prevent the same error from being reported again and again and again and again...
const ReportedErrors: { [key: string]: number } = {};
function isReplay(err: string) {
  const now = Date.now();
  const last = ReportedErrors[err];
  if (last && last + 60000 > now) {
    return true;
  }

  ReportedErrors[err] = now;
  return false;
}

class RemoteLogger {
  token: string | null = null;

  authorise = (authToken: string) => {
    this.token = authToken;
  };

  invalidate = () => {
    this.token = null;
  };

  logRemote = (error: ClientLogReq) => {
    // TODO(Barry): Validate that the token hasn't expired
    if (this.token) {
      void makeApiServices(this.token as string).app.logClientErrorUser(error);
    } else {
      void makeApiServices(undefined).app.logClientErrorPublic(error);
    }
  };

  exception = (error: Error) => {
    const errDesc = `${error.name}: ${error.message}`;
    if (isReplay(errDesc)) {
      return;
    }

    const err: ClientLogReq = {
      // name, and message fields are not enumerable on Error
      // so JSON.stringify does not work-directly on Errors
      // When defining custom error types, add an extra `name` field as recommended by MDN
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
      // https://stackoverflow.com/questions/1382107/whats-a-good-way-to-extend-error-in-javascript
      error: `${error.name}: ${error.message}`,
      stacktrace: error.stack || null,
      level: "ERROR",
    };
    this.logRemote(err);
  };

  error = (msg: string) => {
    if (isReplay(msg)) {
      return;
    }

    const err: ClientLogReq = { error: msg, level: "ERROR", stacktrace: null };
    this.logRemote(err);
  };

  warn = (msg: string) => {
    if (isReplay(msg)) {
      return;
    }

    const err: ClientLogReq = { error: msg, level: "WARN", stacktrace: null };
    this.logRemote(err);
  };

  trace = (msg: string) => {
    if (isReplay(msg)) {
      return;
    }

    const err: ClientLogReq = { error: msg, level: "TRACE", stacktrace: null };
    this.logRemote(err);
  };
}

export default new RemoteLogger();
