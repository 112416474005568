import { Box, Grid, Stack, Typography } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import React from "react";
import { FlowStepper } from "./flow-stepper";

interface PageHeaderProps {
  title: string;
  useConfirmationTitle?: boolean;
  subtitle?: string;
  right?: RightProps;
}

interface PageHeaderTitledTextProps {
  type: "titledText";
  titledTexts: TitledText[];
}

interface TitledText {
  title: string;
  text: string;
}

interface PageHeaderSimpleTextProps {
  type: "simpleText";
  text: string;
}

interface PageHeaderWizardProps {
  type: "wizard";
  steps: string[];
  activeStep: number;
}

type RightProps = PageHeaderTitledTextProps | PageHeaderSimpleTextProps | PageHeaderWizardProps;

export const PageHeader = (props: PageHeaderProps) => {
  const { title, subtitle, right, useConfirmationTitle } = props;

  return (
    <Grid container alignItems="center">
      <Grid item xs={right ? 7 : 12}>
        <Stack spacing={2} direction="row" alignItems="center">
          {useConfirmationTitle && <CheckCircleRoundedIcon color="success" sx={{ fontSize: 50 }} />}
          <Box>
            <Stack spacing={2} direction="row" alignItems="center">
              <Typography variant="h4">{title}</Typography>
            </Stack>
            {subtitle && (
              <Stack spacing={2} direction={"row"} alignItems={"end"}>
                <Typography>{subtitle}</Typography>
              </Stack>
            )}
          </Box>
        </Stack>
      </Grid>

      {right && (
        <Grid item xs={5}>
          {RightWidget(right)}
        </Grid>
      )}
    </Grid>
  );
};

function RightWidget(props: RightProps) {
  switch (props.type) {
    case "titledText": {
      return <PageHeaderTitledText {...props} />;
    }
    case "simpleText": {
      return <PageHeaderSimpleText {...props} />;
    }
    case "wizard": {
      return <PageHeaderWizard {...props} />;
    }
  }
}

const PageHeaderTitledText = (props: PageHeaderTitledTextProps) => {
  const { titledTexts } = props;
  return (
    <Stack width={"100%"} textAlign={"right"}>
      {titledTexts.map((titledText, id) => (
        <Stack key={id} width={"100%"} textAlign={"right"}>
          <Typography sx={{ fontSize: 12, color: "common.grey5" }}>{titledText.title}</Typography>
          <Typography variant="subtitle1Bold">{titledText.text}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

const PageHeaderSimpleText = (props: PageHeaderSimpleTextProps) => {
  const { text } = props;
  return (
    <Stack width={"100%"} textAlign={"right"}>
      <Stack width={"100%"} textAlign={"right"}>
        <Typography variant="h5">{text}</Typography>
      </Stack>
    </Stack>
  );
};

const PageHeaderWizard = (props: PageHeaderWizardProps) => {
  const { steps, activeStep } = props;
  return (
    <Stack width={"100%"} textAlign={"right"}>
      <Stack width={"100%"} textAlign={"right"}>
        <FlowStepper steps={steps} activeStep={activeStep} />
      </Stack>
    </Stack>
  );
};
