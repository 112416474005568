import React, { useState } from "react";

import { Alert, Box, Stack, Tab, TableCell, TableRow, Tabs, styled } from "@mui/material";

import { Link } from "components/widgets/link/link";
import { lowerCase } from "lodash";

import { RevaluationRequestSummary } from "adl-gen/ferovinum/app/api";
import { PaginatedTable } from "components/widgets/paginated-table/paginated-table";
import { formatLocalDate } from "utils/date-utils";
import { AppRoutes } from "../../../../app/app-routes";
import { PortalPageContentHeader } from "../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../layouts/portal-page-content/portal-page-content";

export type RevaluationDashboardSection = "inProgress" | "completed";

export const RevaluationRequestOverview = ({
  revaluationRequests,
}: {
  revaluationRequests: RevaluationRequestSummary[];
}) => {
  const [selectedSection, setSelectedSection] = useState<RevaluationDashboardSection>("completed");

  const filteredRevaluationRequests =
    selectedSection === "inProgress"
      ? revaluationRequests.filter(revalReq => revalReq.state === "orgAccepted")
      : revaluationRequests.filter(revalReq => revalReq.state === "created" || revalReq.state === "orgRejected");

  return (
    <PortalPageContent header={<PortalPageContentHeader title="Stock Revaluation" />}>
      <Stack spacing={5}>
        <Box display="flex" justifyContent="flex-start">
          <Tabs value={selectedSection} onChange={(_, v) => setSelectedSection(v)}>
            <Tab label="In Progress" value="inProgress" sx={{ textTransform: "none" }} />
            <Tab label="Completed" value="completed" sx={{ textTransform: "none" }} />
          </Tabs>
        </Box>
      </Stack>
      <Stack marginTop={5}>
        {revaluationRequests.length > 0 ? (
          <OverviewTable key={selectedSection} requests={filteredRevaluationRequests} />
        ) : (
          <Alert severity="info">{`There are currently no ${lowerCase(selectedSection)} new deal requests`}</Alert>
        )}
      </Stack>
    </PortalPageContent>
  );
};

const OverviewTable = ({ requests }: { requests: RevaluationRequestSummary[] }) => {
  return (
    <PaginatedTable
      HeaderContent={<RevaluationRequestsTableHeader />}
      BodyContent={<RevaluationRequestsTableBody rows={requests} />}
      initialRows={requests}
      totalRowCount={requests.length}
    />
  );
};

function RevaluationRequestsTableHeader() {
  return (
    <TableRow>
      <TableCell>Deal Number</TableCell>
      <BasicTableCell>Requested Date</BasicTableCell>
      <BasicTableCell>Status</BasicTableCell>
    </TableRow>
  );
}

function RevaluationRequestsTableBody(props: { rows: RevaluationRequestSummary[] }) {
  const { rows } = props;

  return (
    <>
      {rows.map((rr: RevaluationRequestSummary, idx: number) => (
        <RevaluationRequestsTableRow key={idx} revaluationRequest={rr} />
      ))}
    </>
  );
}

const BasicTableCell = styled(TableCell)({
  textAlign: "left",
});

interface RevaluationRequestCardProps {
  key: number;
  revaluationRequest: RevaluationRequestSummary;
}

const RevaluationRequestsTableRow = ({ revaluationRequest }: RevaluationRequestCardProps) => {
  return (
    <TableRow>
      <BasicTableCell>
        <Link href={`${AppRoutes.StockRevaluationDetails}/${revaluationRequest.revaluationRequestId}`}>
          {revaluationRequest.dealNumber}
        </Link>
      </BasicTableCell>
      <BasicTableCell>{formatLocalDate(revaluationRequest.dateOfRequest)}</BasicTableCell>
      <BasicTableCell>{getStateDescription(revaluationRequest)}</BasicTableCell>
    </TableRow>
  );
};

function getStateDescription(revaluationRequest: RevaluationRequestSummary): string {
  const { state } = revaluationRequest;
  switch (state) {
    case "orgAccepted":
      return `Accpted by + ${revaluationRequest.orgName}`;
    case "orgRejected":
      return `Rejected by ${revaluationRequest.orgName}`;
    case "created":
      return `Revaluation Request created`;
  }
}
