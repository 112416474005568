import { Stack, TableCell, TableRow, Typography } from "@mui/material";
import { BigDecimal } from "adl-gen/common";
import { Currency, MonthlyFee, NumberOfUnits, Product } from "adl-gen/ferovinum/app/db";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";
import React, { useMemo } from "react";
import { ProductSummary } from "../../product-summary/product-summary";
import { formatNumberOfUnits } from "utils/model-utils";
import { InfoTooltip } from "../../info-tooltip/info-tooltip";
import { isNegativeNumber } from "utils/ts-utils";
import { formatLocalDate } from "utils/date-utils";
import { CurrencyRenderer } from "../../currency-renderer/currency-renderer";
import { PaginatedTableV2 } from "../../paginated-table/paginated-table";

interface DealLegStage {
  quantity: NumberOfUnits;
  deadlineDate?: string;
}
interface PaymentOnDealRenewalDetails {
  totalFeesDue: MonetaryValue;
  changeInNetAdvanceAmount: MonetaryValue;
  netReceivableOnDealRenewal: MonetaryValue;
}
interface DealExtensionRequestLineItemPricingDetails {
  onClickProductId?: () => void;
  product: Product;
  compulsoryStage?: DealLegStage;
  finalStage: DealLegStage;
  purchasePricePerUnit?: MonetaryValue;
  depositPrice?: MonetaryValue;
  monthlyFee?: MonthlyFee;
  throughputFeePc?: BigDecimal;
  netAdvance?: MonetaryValue;
}

export interface DealExtensionRequestContractNoteTableProps {
  currency: Currency;
  lineItems: DealExtensionRequestLineItemPricingDetails[];
  grossPurchasePricePayableByFerovinum?: MonetaryValue;
  totalDepositDue?: MonetaryValue;
  totalNetAdvance?: MonetaryValue;
  paymentOnDealRenewalDetails?: PaymentOnDealRenewalDetails;
}
export const DealExtensionRequestContractNoteTable = ({
  currency,
  lineItems,
  grossPurchasePricePayableByFerovinum,
  totalDepositDue,
  totalNetAdvance,
  paymentOnDealRenewalDetails,
}: DealExtensionRequestContractNoteTableProps) => {
  const indexedRows = useMemo(() => lineItems.map((lineItem, rowIdx) => ({ ...lineItem, rowIdx })), [lineItems]);
  return (
    <PaginatedTableV2
      elevation={0}
      initialRowsPerPage={10}
      initialRows={indexedRows}
      HeaderContent={
        <TableRow>
          <TableCell>Product</TableCell>
          <TableCell align="right">Quantity</TableCell>
          <TableCell align="center">Expiry Date</TableCell>
          <TableCell align="right">Purchase Price Per Unit</TableCell>
          <TableCell align="right">Deposit</TableCell>
          <TableCell align="right">Fees</TableCell>
          <TableCell align="right">Net Advance</TableCell>
        </TableRow>
      }
      renderRow={({ rowIdx, ...lineItem }) => (
        <LineItemDetailsRow key={`${lineItem.product.code}-${rowIdx}`} currency={currency} {...lineItem} />
      )}
      BodyBaseline={
        <>
          <SummaryRow
            title="Gross Purchase Price Payable by Ferovinum"
            value={grossPurchasePricePayableByFerovinum}
            currency={currency}
          />
          <SummaryRow title="Total Deposit Due" value={totalDepositDue} currency={currency} bottomUnderline />
          <SummaryRow title="Net Advance Amount" value={totalNetAdvance} currency={currency} />
          {paymentOnDealRenewalDetails && (
            <>
              <TableRow>
                <TableCell colSpan={7}>Payment on Deal Renewal</TableCell>
              </TableRow>
              <SummaryRow
                title="Total Fees Due"
                tooltip="The total fees which have accumulated for the expiring products over the tenor of the current deal. This amount takes monthly fees, the outstanding portion of the initial throughput fee and any capital expenditure fees into account."
                value={paymentOnDealRenewalDetails.totalFeesDue}
                currency={currency}
              />
              <SummaryRow
                title="Change in Net Advance Amount"
                tooltip="This is calculated by taking the difference between new and prior purchase price multiplied by the quantity of units expiring."
                value={paymentOnDealRenewalDetails.changeInNetAdvanceAmount}
                currency={currency}
              />
              <SummaryRow
                title={
                  isNegativeNumber(paymentOnDealRenewalDetails.netReceivableOnDealRenewal)
                    ? "Net Receivable on Deal Renewal"
                    : "Net Payable on Deal Renewal"
                }
                value={paymentOnDealRenewalDetails.netReceivableOnDealRenewal.replace("-", "")}
                currency={currency}
              />
            </>
          )}
        </>
      }
    />
  );
};

type LineItemDetailsRowProps = DealExtensionRequestLineItemPricingDetails & {
  currency: Currency;
};
const LineItemDetailsRow = ({
  currency,
  onClickProductId,
  product,
  compulsoryStage,
  finalStage,
  purchasePricePerUnit,
  depositPrice,
  monthlyFee,
  throughputFeePc,
  netAdvance,
}: LineItemDetailsRowProps) => {
  const splitDealLegStageCells = compulsoryStage !== undefined;
  const { unitType } = product;

  return (
    <>
      <TableRow>
        <TableCell rowSpan={2}>
          <ProductSummary {...product} onClick={onClickProductId ? () => onClickProductId() : undefined} />
        </TableCell>
        {compulsoryStage !== undefined ? (
          <>
            <TableCell align="right">{formatNumberOfUnits(compulsoryStage.quantity, unitType)}</TableCell>
            <TableCell align="center">
              {compulsoryStage.deadlineDate !== undefined ? formatLocalDate(compulsoryStage.deadlineDate) : "-"}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell align="right" rowSpan={2}>
              {formatNumberOfUnits(finalStage.quantity, unitType)}
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              {finalStage.deadlineDate !== undefined ? formatLocalDate(finalStage.deadlineDate) : "-"}
            </TableCell>
          </>
        )}
        <TableCell align="right" rowSpan={2}>
          <CurrencyRenderer currency={currency} value={purchasePricePerUnit} maximumFractionDigits={4} />
        </TableCell>
        <TableCell align="right" rowSpan={2}>
          <CurrencyRenderer currency={currency} value={depositPrice} maximumFractionDigits={4} />
        </TableCell>
        {monthlyFee !== undefined ? (
          <TableCell align="right" sx={{ borderBottom: 0 }}>
            <Stack>
              <Typography variant="caption" color="common.grey6">
                Monthly fees
              </Typography>
              <Typography>{`${monthlyFeePercentage(monthlyFee)}% ${monthlyFee.kind}`}</Typography>
            </Stack>
          </TableCell>
        ) : (
          <TableCell align="right" rowSpan={2}>
            <Typography>-</Typography>
          </TableCell>
        )}
        <TableCell rowSpan={2} align="right">
          <CurrencyRenderer currency={currency} value={netAdvance} maximumFractionDigits={4} />
        </TableCell>
      </TableRow>
      <TableRow>
        {splitDealLegStageCells && (
          <>
            <TableCell align="right">{formatNumberOfUnits(finalStage.quantity, unitType)}</TableCell>
            <TableCell align="center">
              {finalStage.deadlineDate !== undefined ? formatLocalDate(finalStage.deadlineDate) : "-"}
            </TableCell>
          </>
        )}
        {throughputFeePc !== undefined && (
          <TableCell align="right">
            <Stack>
              <Typography variant="caption" color="common.grey6">
                Throughput fees
              </Typography>
              <Typography>{`${throughputFeePc}%`}</Typography>
            </Stack>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

interface SummaryRowProps {
  title: string;
  tooltip?: string;
  value: string | undefined;
  currency: Currency;
  bottomUnderline?: boolean;
}
const SummaryRow = ({ title, tooltip, value, currency, bottomUnderline }: SummaryRowProps) => {
  return (
    <TableRow sx={{ backgroundColor: "common.grey1" }}>
      <TableCell colSpan={3} sx={{ borderBottom: 0 }} />
      <TableCell colSpan={3} align="right" sx={{ borderBottom: bottomUnderline ? undefined : 0 }}>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Typography variant="subtitle1Bold">{title}</Typography>
          {tooltip && <InfoTooltip title={tooltip} />}
        </Stack>
      </TableCell>
      <TableCell align="right" sx={{ borderBottom: bottomUnderline ? undefined : 0 }}>
        <CurrencyRenderer currency={currency} value={value} emptyValue="-" />
      </TableCell>
    </TableRow>
  );
};

function monthlyFeePercentage(monthlyFee: MonthlyFee) {
  if (monthlyFee.kind === "fixed") {
    return monthlyFee.value;
  } else {
    return monthlyFee.value.base;
  }
}
