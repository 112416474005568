import React, { useCallback, useMemo, useState } from "react";
import {
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Grow,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { OrganisationProductSummary } from "../../organisation-product-summary/organisation-product-summary";
import { Currency, Product, TopLevelUnitType } from "adl-gen/ferovinum/app/db";
import { isSinglesUnitType, unitsLabelForUnitType } from "utils/model-utils";
import { WithDbId } from "adl-gen/common/db";
import { Link } from "components/widgets/link/link";
import {
  ReimbursedStockAdjustments,
  NonReimbursedStockAdjustments,
  NonReimbursedStockAdjustment,
} from "../../../../hooks/use-stock-adjustment";
import { createBigDecimalCurrencyFormatter } from "utils/currency-utils";
import WarningIcon from "@mui/icons-material/Warning";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import { CurrencyRange } from "components/widgets/currency-renderer/currency-range";
import { NegativeStockAdjustmentProductSaleView, PositiveStockAdjustmentState } from "adl-gen/ferovinum/app/api";
import { titleCase } from "utils/conversion-utils";
import assertNever from "assert-never";

type StockAdjustmentTableVariant =
  | {
      kind: "positiveStockAdjustment";
      stockAdjustments: NonReimbursedStockAdjustments;
    }
  | {
      kind: "negativeStockAdjustment";
      stockAdjustments:
        | { kind: "noReimbursement"; value: NonReimbursedStockAdjustments }
        | { kind: "reimbursement"; value: ReimbursedStockAdjustments };
    };

type TableAction = {
  label: string;
  onClick(): void;
};
type OnAcknowledgmentAction = {
  value: boolean;
  setValue: (value: boolean) => void;
};
export interface StockAdjustmentsTableProps {
  variant: StockAdjustmentTableVariant;
  topLevelUnitType: TopLevelUnitType;
  currency: Currency;
  action?: TableAction;
  acknowledgementNotFinal?: OnAcknowledgmentAction;
}

export const StockAdjustmentsTable = ({
  variant,
  topLevelUnitType,
  currency,
  action,
  acknowledgementNotFinal,
}: StockAdjustmentsTableProps) => {
  return (
    <TableContainer>
      {variant.kind === "negativeStockAdjustment" && variant.stockAdjustments.kind === "reimbursement" ? (
        <ReimbursementStockAdjustmentTable
          stockAdjustments={variant.stockAdjustments.value}
          topLevelUnitType={topLevelUnitType}
          currency={currency}
          action={action}
          onAcknowledgeNotFinal={acknowledgementNotFinal}
        />
      ) : (
        <NonReimbursementStockAdjustmentTable
          variant={variant.kind}
          stockAdjustments={
            variant.kind === "positiveStockAdjustment"
              ? variant.stockAdjustments
              : // Note: We know that the variant is a negativeStockAdjustment with noReimbursement
                // unfortunately typescript doesn't
                (variant.stockAdjustments.value as NonReimbursedStockAdjustments)
          }
          topLevelUnitType={topLevelUnitType}
          action={action}
        />
      )}
    </TableContainer>
  );
};

interface NonReimbursementStockAdjustmentsTableProps {
  variant: StockAdjustmentTableVariant["kind"];
  stockAdjustments: NonReimbursedStockAdjustments;
  topLevelUnitType: TopLevelUnitType;
  action?: TableAction;
}
const NonReimbursementStockAdjustmentTable = ({
  variant,
  stockAdjustments,
  topLevelUnitType,
  action,
}: NonReimbursementStockAdjustmentsTableProps) => {
  // Note: Stock adjustments are always of a single type of product (E.g all bottles, all casks, etc...)
  const unitsLabel = unitsLabelForUnitType(topLevelUnitType);
  const positiveVariantProps = useMemo(() => {
    // Positive props are only relevant if there are states or rotation numbers
    if (variant === "negativeStockAdjustment") {
      return undefined;
    }

    const adjustments = [...stockAdjustments.values()].flatMap(a => [...a.values()]);
    /// Each adjustment has a state attached to it (Unreconciled, Reconciled or Rejected)
    const hasStates = adjustments.some(a => a.state !== undefined);
    /// Some of the adjustments are attached to a rotation number
    const hasRotationNumbers = adjustments.some(a => a.rotationNumber !== null);

    // Positive props are only relevant if there are states or rotation numbers
    if (!hasStates && !hasRotationNumbers) {
      return undefined;
    }

    return { hasStates, hasRotationNumbers };
  }, [stockAdjustments, variant]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Product</TableCell>
          {positiveVariantProps?.hasStates && positiveVariantProps.hasRotationNumbers && (
            <TableCell align="left">Rotation number</TableCell>
          )}
          <TableCell align="right">{`${
            variant === "positiveStockAdjustment" ? "Extra" : "Lost"
          } quantity (${unitsLabel})`}</TableCell>
          {positiveVariantProps?.hasStates && <TableCell align="right">Status</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {[...stockAdjustments.entries()].map(([product, adjustments]) => (
          <NonReimbursementStockAdjustmentTableRow
            key={product.id}
            product={product.value}
            stockAdjustments={adjustments}
            positiveVariantProps={positiveVariantProps}
          />
        ))}
      </TableBody>
      {action && (
        <TableFooter>
          <TableRow>
            <TableCell colSpan={2}>
              <Link variant="small" onClick={action.onClick} color={"grey6"}>
                {action.label}
              </Link>
            </TableCell>
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};
const NonReimbursementStockAdjustmentTableRow = ({
  product,
  stockAdjustments,
  positiveVariantProps,
}: {
  product: Product;
  stockAdjustments: NonReimbursedStockAdjustment[];
  positiveVariantProps?: { hasStates: boolean; hasRotationNumbers: boolean };
}) => {
  const totalForProduct = stockAdjustments.reduce((sum, val) => sum + val.numberOfUnits.value, 0);

  return (
    <>
      {positiveVariantProps?.hasStates ? (
        <>
          {stockAdjustments.map((adjustment, index) => {
            const state = adjustment.state;
            return (
              <TableRow key={`${product.code}-${adjustment.rotationNumber}-${index}`}>
                {index === 0 && (
                  <TableCell rowSpan={stockAdjustments.length}>
                    <OrganisationProductSummary {...product} />
                  </TableCell>
                )}
                {positiveVariantProps.hasRotationNumbers && (
                  <TableCell align="left">{adjustment.rotationNumber || ""}</TableCell>
                )}
                <TableCell align="right">{adjustment.numberOfUnits.value.toString()}</TableCell>
                <TableCell align="right">
                  {state && <Chip color={getPositiveStockAdjustmentStateColor(state)} label={titleCase(state)} />}
                </TableCell>
              </TableRow>
            );
          })}
        </>
      ) : (
        <TableRow>
          <TableCell>
            <OrganisationProductSummary {...product} />
          </TableCell>
          <TableCell align="right">{totalForProduct.toString()}</TableCell>
        </TableRow>
      )}
    </>
  );
};

interface ReimbursementStockAdjustmentsTableProps {
  stockAdjustments: ReimbursedStockAdjustments;
  topLevelUnitType: TopLevelUnitType;
  currency: Currency;
  action?: TableAction;
  onAcknowledgeNotFinal?: OnAcknowledgmentAction;
}
export const ReimbursementStockAdjustmentTable = ({
  stockAdjustments,
  topLevelUnitType,
  currency,
  action,
  onAcknowledgeNotFinal,
}: ReimbursementStockAdjustmentsTableProps) => {
  const currencyFormatter = createBigDecimalCurrencyFormatter(currency)().format;
  // Note: Stock adjustments are always of a single type of product (E.g all bottles, all casks, etc...)
  const unitsLabel = unitsLabelForUnitType(topLevelUnitType);
  const hasRotationNumbers = useMemo(() => {
    return [...stockAdjustments.adjustments.values()].some(adjustments =>
      adjustments.some(adjustment => adjustment.rotationNumber !== null),
    );
  }, [stockAdjustments.adjustments]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Product</TableCell>
          {hasRotationNumbers && <TableCell>Rotation number</TableCell>}
          <TableCell align="right">{`Price per ${
            isSinglesUnitType(topLevelUnitType) ? "unit" : unitsLabel
          }`}</TableCell>
          <TableCell align="right">{`Lost quantity (${unitsLabel})`}</TableCell>
          <TableCell align="right">Reimbursement costs</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {[...stockAdjustments.adjustments.entries()].map(([product, adjustments]) => (
          <ReimbursementStockAdjustmentTableRow
            key={product.id}
            currency={currency}
            product={product}
            stockAdjustments={adjustments}
            hasRotationNumbers={hasRotationNumbers}
            formatter={currencyFormatter}
          />
        ))}
        <ReimbursementTotalsRows
          currencyFormatter={currencyFormatter}
          reimbursedAdjustments={stockAdjustments}
          hasRotationNumbers={hasRotationNumbers}
          onAcknowledgeNotFinal={onAcknowledgeNotFinal}
        />
      </TableBody>
      {action && (
        <TableFooter sx={{ backgroundColor: "common.grey3" }}>
          <TableRow>
            <TableCell colSpan={6}>
              <Link variant="small" onClick={action.onClick} color={"grey6"}>
                {action.label}
              </Link>
            </TableCell>
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

interface ReimbursementTotalsRowsProps {
  currencyFormatter: (val: MonetaryValue) => string;
  reimbursedAdjustments: ReimbursedStockAdjustments;
  hasRotationNumbers?: boolean;
  onAcknowledgeNotFinal?: {
    value: boolean;
    setValue: (value: boolean) => void;
  };
}
const ReimbursementTotalsRows = ({
  currencyFormatter,
  reimbursedAdjustments,
  onAcknowledgeNotFinal,
  hasRotationNumbers,
}: ReimbursementTotalsRowsProps) => {
  const reimbursementCostsAreFinal = reimbursedAdjustments.state === "complete";
  const totalLineItems = reimbursementCostsAreFinal
    ? 1 + // gross reimbursement cost
      (reimbursedAdjustments.storageFees !== undefined ? 1 : 0) +
      (reimbursedAdjustments.landingFees !== undefined ? 1 : 0) +
      (reimbursedAdjustments.netReimbursementCost !== undefined ? 1 : 0)
    : 2; // Display gross + warning/checkbox about not being final
  const acknowledgementCellMaxContentWidth = "500px";
  return (
    <>
      <TotalTableRow hideBottomBorder={reimbursementCostsAreFinal}>
        <TableCell colSpan={hasRotationNumbers ? 3 : 2} rowSpan={totalLineItems} />
        <TableCell align="right">
          <Typography variant="subtitle1Bold" noWrap>
            Gross reimbursement cost
          </Typography>
        </TableCell>
        <TableCell align="right">{currencyFormatter(reimbursedAdjustments.grossReimbursementCost)}</TableCell>
        <TableCell />
      </TotalTableRow>
      {reimbursementCostsAreFinal ? (
        <>
          {reimbursedAdjustments.storageFees && (
            <TotalTableRow hideBottomBorder>
              <TableCell align="right">
                <Typography variant="subtitle1Bold">Storage fees</Typography>
              </TableCell>
              <TableCell align="right">{currencyFormatter(reimbursedAdjustments.storageFees)}</TableCell>
              <TableCell />
            </TotalTableRow>
          )}
          {reimbursedAdjustments.landingFees && (
            <TotalTableRow>
              <TableCell align="right">
                <Typography variant="subtitle1Bold">Landing fees</Typography>
              </TableCell>
              <TableCell align="right">{currencyFormatter(reimbursedAdjustments.landingFees)}</TableCell>
              <TableCell />
            </TotalTableRow>
          )}
          {reimbursedAdjustments.netReimbursementCost && (
            <TotalTableRow hideBottomBorder>
              <TableCell align="right">
                <Typography variant="subtitle1Bold">Net reimbursement cost</Typography>
              </TableCell>
              <TableCell align="right">{currencyFormatter(reimbursedAdjustments.netReimbursementCost)}</TableCell>
              <TableCell />
            </TotalTableRow>
          )}
        </>
      ) : (
        <TotalTableRow>
          {onAcknowledgeNotFinal !== undefined ? (
            <TableCell colSpan={2}>
              <Stack alignItems="flex-end">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={onAcknowledgeNotFinal.value}
                        onClick={() => onAcknowledgeNotFinal.setValue(!onAcknowledgeNotFinal.value)}
                      />
                    }
                    label={
                      <Typography sx={{ maxWidth: acknowledgementCellMaxContentWidth }}>
                        I acknowledge that this gross reimbursement cost is not yet final as Ferovinum still needs to
                        review this adjustment and enter any landing and storage fees.
                      </Typography>
                    }
                  />
                </FormGroup>
              </Stack>
            </TableCell>
          ) : (
            <TableCell colSpan={2}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                <WarningIcon color="warning" fontSize="small" />
                <Typography maxWidth={acknowledgementCellMaxContentWidth}>
                  This gross reimbursement cost is not yet final as Ferovinum still needs to review this adjustment and
                  enter any landing and storage fees.
                </Typography>
              </Stack>
            </TableCell>
          )}
          <TableCell />
        </TotalTableRow>
      )}
    </>
  );
};

const TotalTableRow = styled(TableRow, { shouldForwardProp: propName => propName !== "hideBottomBorder" })<{
  hideBottomBorder?: boolean;
}>(({ theme, hideBottomBorder }) => ({
  backgroundColor: theme.palette.common.grey1,
  ...(hideBottomBorder && {
    [`&  > .${tableCellClasses.root}`]: {
      borderBottom: "none",
    },
  }),
}));

interface ReimbursementStockAdjustmentTableRowProps {
  currency: Currency;
  product: WithDbId<Product>;
  stockAdjustments: NegativeStockAdjustmentProductSaleView[];
  formatter: (val: MonetaryValue) => string;
  hasRotationNumbers?: boolean;
}
/** Displays a row splitted by each rotation number that can also be expanded to display prices and units breakdown */
const ReimbursementStockAdjustmentTableRow = ({
  currency,
  product,
  stockAdjustments,
  formatter,
  hasRotationNumbers,
}: ReimbursementStockAdjustmentTableRowProps) => {
  return (
    <>
      {stockAdjustments.map((adjustment, index) => {
        return (
          <ReimbursementStockAdjustmentTableRotationRow
            key={`${adjustment.rotationNumber}-${index}`}
            index={index}
            currency={currency}
            product={product}
            adjustment={adjustment}
            numberOfAdjustments={stockAdjustments.length}
            formatter={formatter}
            hasRotationNumbers={hasRotationNumbers}
          />
        );
      })}
    </>
  );
};

interface ReimbursementStockAdjustmentTableRotationRowProps {
  index: number;
  currency: Currency;
  product: WithDbId<Product>;
  adjustment: NegativeStockAdjustmentProductSaleView;
  numberOfAdjustments: number;
  formatter: (val: MonetaryValue) => string;
  hasRotationNumbers?: boolean;
}
const ReimbursementStockAdjustmentTableRotationRow = ({
  index,
  currency,
  product,
  adjustment,
  numberOfAdjustments,
  formatter,
  hasRotationNumbers,
}: ReimbursementStockAdjustmentTableRotationRowProps) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen(open => !open), []);

  const ExpandedInnerCells = useCallback(
    <T,>(key: string, values: T[], render: (value: T) => string) => (
      <Stack divider={<Divider flexItem />}>
        {open &&
          values.map((v, idx) => {
            const value = render(v);
            return (
              <React.Fragment key={`${key}-${value}-${idx}`}>
                <Typography padding={2}>{value}</Typography>
              </React.Fragment>
            );
          })}
      </Stack>
    ),
    [open],
  );

  return (
    <TableRow key={`${product.id}-${index}`}>
      {index === 0 && (
        <TableCell rowSpan={numberOfAdjustments}>
          <OrganisationProductSummary {...product.value} />
        </TableCell>
      )}
      {hasRotationNumbers && <TableCell>{adjustment.rotationNumber || ""}</TableCell>}
      <ExpandableCell open={open}>
        <Grow in={open}>
          {ExpandedInnerCells(
            adjustment.rotationNumber || "",
            adjustment.costs.map(a => a.pricePerUnit),
            price => formatter(price),
          )}
        </Grow>
        {!open && (
          <CurrencyRange currency={currency} values={adjustment.costs.map(cost => Number(cost.pricePerUnit))} />
        )}
      </ExpandableCell>
      <ExpandableCell open={open}>
        <Grow in={open}>
          {ExpandedInnerCells(
            adjustment.rotationNumber || "",
            adjustment.costs.map(a => a.numberOfUnits.value),
            num => num.toString(),
          )}
        </Grow>
        {!open && <Typography>{adjustment.units.value.toString()}</Typography>}
      </ExpandableCell>
      <ExpandableCell open={open}>
        <Grow in={open}>
          {ExpandedInnerCells(
            adjustment.rotationNumber || "",
            adjustment.costs.map(a => a.totalCost),
            price => formatter(price),
          )}
        </Grow>
        {!open && <Typography>{formatter(adjustment.totalCost)}</Typography>}
      </ExpandableCell>
      <TableCell align="center">
        {adjustment.costs.length > 1 && (
          <IconButton onClick={toggleOpen}>{open ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}</IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

const ExpandableCell = styled(TableCell, { shouldForwardProp: prop => prop !== "open" })<{
  open: boolean;
}>(({ open }) => ({ ...(open && { padding: 0 }) }));
ExpandableCell.defaultProps = {
  align: "right",
};

function getPositiveStockAdjustmentStateColor(state: PositiveStockAdjustmentState) {
  switch (state) {
    case "reconciled":
      return "success";
    case "unreconciled":
      return "warning";
    case "rejected":
      return "error";
    default:
      assertNever(state);
  }
}
