import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { ProductionOrderFlowStepper } from "../../../../widgets/flow-stepper/production-order-flow-stepper";
import { Link } from "components/widgets/link/link";
import { OrganisationProductSummary } from "../../../../widgets/organisation-product-summary/organisation-product-summary";
import { WithDbId } from "adl-gen/common/db";
import { FinishingService, NumberOfUnits, Product } from "adl-gen/ferovinum/app/db";
import { FEROVINUM_CONTACT_URL } from "components/assets/url";
import { styled } from "@mui/material/styles";
import { date, number, object, string } from "yup";
import { FormikProps, useFormik } from "formik";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { assertNotUndefined } from "utils/hx/util/types";
import { DatePicker } from "@mui/lab";
import { ProductionOrderFlowState, SetupValues } from "./organisation-production-order-setup-page";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { ProductDetails } from "../../../../../models/product";
import { AppRoutes } from "../../../../../app/app-routes";
import { useSettlementCurrency } from "../../../../layouts/portal-page-layout/portal-page";
import { UnitsInput } from "components/widgets/inputs/units-input/units-input";
import { TextArea } from "components/widgets/inputs/text-area/text-area";
import { CurrencyInput } from "components/widgets/inputs/currency-input/currency-input";
import { singles } from "utils/model-utils";
import { titleCase } from "utils/conversion-utils";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";

const SETUP_STEPS = [
  "A. How many bottles would you like to process?",
  "B. What would the resulting product be?",
  "C. What date would you need this completed by?",
  "D. Notes to include in your order",
];
interface OrganisationProductionSetupFormValues {
  serviceId: string;
  quantity: string;
  finishingProductId: string;
  completionDate: string;
  notes: string;
  pricePerUnit: string;
  setupCost: string;
}
function getInitialFormValues(
  initialSetup: ProductionOrderFlowState["setup"],
  defaultFinishingService: WithDbId<FinishingService>,
): OrganisationProductionSetupFormValues {
  return {
    serviceId: initialSetup?.finishingService.id ?? defaultFinishingService.id,
    finishingProductId: initialSetup?.finishingProduct.id ?? "",
    quantity: initialSetup?.quantity.toString() ?? "",
    completionDate: initialSetup?.requestedCompletionDate ?? "",
    notes: initialSetup?.notes ?? "",
    pricePerUnit: initialSetup?.pricePerUnit ?? defaultFinishingService.value.defaultPricePerUnit,
    setupCost: initialSetup?.setupCost ?? defaultFinishingService.value.defaultSetupCost,
  };
}
export interface OrganisationProductionOrderSetupPageViewProps {
  sourceProduct: ProductDetails;
  quantityAvailable: NumberOfUnits;
  finishingServices: Map<WithDbId<FinishingService>, WithDbId<Product>[]>;
  initialSetupValues?: ProductionOrderFlowState["setup"];
  onNext: (values: SetupValues) => void;
  isProducer: boolean;
}
const today = new Date();
export const OrganisationProductionOrderSetupPageView = ({
  sourceProduct,
  quantityAvailable,
  finishingServices,
  initialSetupValues,
  onNext,
  isProducer,
}: OrganisationProductionOrderSetupPageViewProps) => {
  const [currentSetupStep, setCurrentSetupStep] = useState(0);

  const validateFinishingProduct = useCallback(
    (finishingServiceId: string, finishingProductId: string) => {
      const service = assertNotUndefined([...finishingServices.keys()].find(s => s.id === finishingServiceId));
      return finishingServices.get(service)?.find(p => p.id === finishingProductId) !== undefined ?? false;
    },
    [finishingServices],
  );
  const validationSchema = useMemo(
    () =>
      object().shape({
        serviceId: string().required(),
        quantity: number().min(1).positive().max(quantityAvailable.value).required("This is a required field"),
        finishingProductId: string()
          .test("valid finishing product", (finishingProductId, context) => {
            const serviceId: string | undefined = context.parent.serviceId;
            return finishingProductId && serviceId ? validateFinishingProduct(serviceId, finishingProductId) : false;
          })
          .required(),
        completionDate: date().optional().min(today),
        notes: string().optional(),
        pricePerUnit: number().min(0).required(),
        setupCost: number().min(0).required(),
      }),
    [quantityAvailable, validateFinishingProduct],
  );

  const onSubmit = useCallback(
    (formValues: OrganisationProductionSetupFormValues) => {
      const finishingService = assertNotUndefined(
        [...finishingServices.keys()].find(s => s.id === formValues.serviceId),
      );
      const finishingProduct = assertNotUndefined(
        finishingServices.get(finishingService)?.find(p => p.id === formValues.finishingProductId),
      );
      onNext({
        quantity: singles(Number(formValues.quantity)),
        finishingService,
        finishingProduct,
        requestedCompletionDate: formValues.completionDate,
        notes: formValues.notes,
        pricePerUnit: formValues.pricePerUnit,
        setupCost: formValues.setupCost,
      });
    },
    [finishingServices, onNext],
  );

  const initialValues = useMemo(
    () => getInitialFormValues(initialSetupValues, [...finishingServices.keys()][0]),
    [finishingServices, initialSetupValues],
  );
  const form = useFormik<OrganisationProductionSetupFormValues>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit,
  });

  const actionButtonsRef = useRef<HTMLDivElement>(null);
  const finishingProductRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (form.values.serviceId && form.values.quantity) {
      if (currentSetupStep < 1) {
        setCurrentSetupStep(1);
      }
    }
    if (form.values.finishingProductId) {
      if (currentSetupStep < 2) {
        setCurrentSetupStep(2);
      }
    }
  }, [currentSetupStep, form.values.finishingProductId, form.values.quantity, form.values.serviceId]);

  useLayoutEffect(() => {
    if (currentSetupStep === 1 && finishingProductRef.current) {
      finishingProductRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (currentSetupStep === 2 && actionButtonsRef.current) {
      actionButtonsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentSetupStep]);

  const services = useMemo(() => [...finishingServices.keys()], [finishingServices]);
  const products = useMemo(() => {
    const service = [...finishingServices.keys()].find(s => s.id === form.values.serviceId);
    return service ? assertNotUndefined(finishingServices.get(service)) : [];
  }, [finishingServices, form.values.serviceId]);

  return (
    <PortalPageContent header={<OrganisationProductionOrderSetupHeader />}>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={5}>
          <SelectedProductCard product={sourceProduct} quantityAvailable={quantityAvailable} />
        </Grid>
        <Grid item lg={7} />
        <Grid item xs={12} lg={8}>
          <SetupStep question={SETUP_STEPS[0]}>
            <QuantityCard isProducer={isProducer} form={form} services={services} sourceProduct={sourceProduct} />
          </SetupStep>
        </Grid>
        <Grid item lg={4} />
        <Grid item xs={12} lg={4} ref={finishingProductRef}>
          {currentSetupStep >= 1 && (
            <SetupStep question={SETUP_STEPS[1]}>
              <FinishingProductCard products={products} form={form} />
            </SetupStep>
          )}
        </Grid>
        <Grid item xs={12} lg={9} />
        {currentSetupStep >= 2 && (
          <>
            <Grid item xs={12} lg={4}>
              <SetupStep question={SETUP_STEPS[2]}>
                <RequestedCompletionDate form={form} />
              </SetupStep>
            </Grid>
            <Grid item lg={8} />
            <Grid item xs={12} lg={4}>
              <SetupStep question={SETUP_STEPS[3]}>
                <Notes form={form} />
              </SetupStep>
            </Grid>
            <Grid item lg={8} />
            <Grid item xs={12} ref={actionButtonsRef}>
              <ActionButtons onNext={form.submitForm} isValid={form.isValid} />
            </Grid>
          </>
        )}
      </Grid>
    </PortalPageContent>
  );
};

const OrganisationProductionOrderSetupHeader = () => {
  return (
    <PortalPageContentHeader
      variant="split"
      title="Order details"
      right={<ProductionOrderFlowStepper activeStep={1} />}
    />
  );
};

interface SelectedProductCardProps {
  quantityAvailable: NumberOfUnits;
  product: ProductDetails;
}

const SelectedProductCard = ({ quantityAvailable, product }: SelectedProductCardProps) => {
  return (
    <Card>
      <Stack>
        <Stack spacing={2} sx={{ p: 2 }}>
          <Typography variant="caption" color="common.grey5">
            Selected product
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            divider={<Divider flexItem orientation="vertical" />}
            spacing={5}>
            <Box flex={1}>
              <OrganisationProductSummary {...product} />
            </Box>
            <Stack alignItems="center" flex={1}>
              <Typography variant="h5">{quantityAvailable.value}</Typography>
              <Typography variant="subtitle2">Available</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Box sx={{ p: 1, pl: 2, pr: 2 }}>
          <Link variant="small" color="grey5" href={AppRoutes.OrgProductionOrderProductSelection}>
            Edit
          </Link>
        </Box>
      </Stack>
    </Card>
  );
};

interface QuantityCardProps {
  services: WithDbId<FinishingService>[];
  form: FormikProps<OrganisationProductionSetupFormValues>;
  sourceProduct: ProductDetails;
  isProducer: boolean;
}
const QuantityCardCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const QuantityCard = ({ services, form, sourceProduct, isProducer }: QuantityCardProps) => {
  const settlementCurrency = useSettlementCurrency();

  const onChangeService = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newService = assertNotUndefined(services.find(s => s.id === e.target.value));
      form.setFieldValue("pricePerUnit", newService.value.defaultPricePerUnit);
      form.setFieldValue("setupCost", newService.value.defaultSetupCost);
      form.handleChange(e);
    },
    [form, services],
  );

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Service</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Price per unit</TableCell>
            <TableCell align="right">Setup fee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map(service => {
            const isSelected = service.id === form.values.serviceId;
            return (
              <TableRow key={service.id}>
                <TableCell>
                  <Radio name="serviceId" checked={isSelected} onChange={onChangeService} value={service.id} />
                </TableCell>
                <TableCell>{titleCase(service.value.name)}</TableCell>
                <QuantityCardCell align="right">
                  <UnitsInput
                    hideUnitsLabel
                    unitType={sourceProduct.unitType}
                    name="quantity"
                    required={true}
                    disabled={!(form.values.serviceId === service.id)}
                    value={form.values.serviceId === service.id ? form.values.quantity : ""}
                    error={Boolean(form.errors.quantity) && form.touched.quantity}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    helperText={form.touched.quantity && form.errors.quantity}
                  />
                </QuantityCardCell>
                <QuantityCardCell align="right">
                  {isProducer ? (
                    <CurrencyRenderer value={service.value.defaultPricePerUnit} currency={settlementCurrency} />
                  ) : (
                    <CurrencyInput
                      currency={settlementCurrency}
                      name="pricePerUnit"
                      required={true}
                      value={isSelected ? form.values.pricePerUnit : service.value.defaultPricePerUnit}
                      error={Boolean(form.errors.pricePerUnit) && form.touched.pricePerUnit}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      disabled={!isSelected}
                    />
                  )}
                </QuantityCardCell>
                <QuantityCardCell align="right">
                  {isProducer ? (
                    <CurrencyRenderer value={service.value.defaultSetupCost} currency={settlementCurrency} />
                  ) : (
                    <CurrencyInput
                      currency={settlementCurrency}
                      name="setupCost"
                      required={true}
                      value={isSelected ? form.values.setupCost : service.value.defaultSetupCost}
                      error={Boolean(form.errors.setupCost) && form.touched.setupCost}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      disabled={!isSelected}
                    />
                  )}
                </QuantityCardCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2} padding={2}>
        <Typography variant="caption">Can’t see the right service?</Typography>
        <Link variant="small" color="grey5" href={FEROVINUM_CONTACT_URL} target="_blank">
          Contact Ferovinum
        </Link>
      </Stack>
    </Card>
  );
};

interface ResultingProductCardProps {
  products: WithDbId<Product>[];
  form: FormikProps<OrganisationProductionSetupFormValues>;
}
const FinishingProductCard = ({ products, form }: ResultingProductCardProps) => {
  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Product</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map(product => (
            <TableRow key={product.id}>
              <TableCell>
                <Radio
                  name="finishingProductId"
                  checked={product.id === form.values.finishingProductId}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={product.id}
                />
              </TableCell>
              <TableCell>
                <OrganisationProductSummary {...product.value} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2} padding={2}>
        <Typography variant="caption">Can’t see the right product?</Typography>
        <Link variant="small" color="grey5" href={FEROVINUM_CONTACT_URL} target="_blank">
          Contact Ferovinum
        </Link>
      </Stack>
    </Card>
  );
};

const RequestedCompletionDate = ({ form }: { form: FormikProps<OrganisationProductionSetupFormValues> }) => {
  /// Note: Date picker won't correctly set the text field name used by formik
  /// need to set the state manually for date fields
  const handleDateChange = (date: Date | null) => {
    if (date) {
      form.setFieldTouched("completionDate", true);
      form.setFieldValue("completionDate", date, true);
    }
  };
  return (
    <DatePicker
      inputFormat="dd/MM/yyyy"
      minDate={today}
      value={form.values.completionDate ? form.values.completionDate : null}
      renderInput={params => (
        <TextField
          {...params}
          name={"completionDate"}
          error={form.touched.completionDate && Boolean(form.errors.completionDate)}
          fullWidth
          onBlur={form.handleBlur}
          onChange={form.handleChange}
        />
      )}
      onChange={handleDateChange}
    />
  );
};

const Notes = ({ form }: { form: FormikProps<OrganisationProductionSetupFormValues> }) => {
  return (
    <TextArea
      name="notes"
      rows={5}
      value={form.values.notes}
      error={form.touched.notes && Boolean(form.errors.notes)}
      fullWidth
      onChange={form.handleChange}
      onBlur={form.handleBlur}
    />
  );
};

const SetupStep = ({ question, children }: { question: string; children: React.ReactNode }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="body1" color="common.grey5">
        {question}
      </Typography>
      {children}
    </Stack>
  );
};

const ActionButtons = ({ onNext, isValid }: { onNext: () => void; isValid: boolean }) => {
  return (
    <Stack direction="row" spacing={2}>
      <Button variant="outlined" href={AppRoutes.OrgProductionOrderProductSelection}>
        Back
      </Button>
      <Button onClick={() => onNext()} disabled={!isValid}>
        Next
      </Button>
    </Stack>
  );
};
