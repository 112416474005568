import React from "react";
import { SplitStickyNote } from "../sticky-note";
import { PurchaseRequestIncoterms, purchaseRequestIncotermsToString } from "utils/conversion-utils";

export interface CollectionPointStickyNoteProps {
  locationName: string;
  incoterms: PurchaseRequestIncoterms;
}
export const CollectionPointStickyNote = ({ locationName, incoterms }: CollectionPointStickyNoteProps) => {
  return (
    <SplitStickyNote
      top={{
        title: "Collection point",
        line: locationName,
      }}
      bottom={{ title: "Tax status", line: purchaseRequestIncotermsToString(incoterms) }}
    />
  );
};
