import React, { useCallback } from "react";
import { Loader } from "components/widgets/loader/loader";
import { OrganisationNewDealRequestsPageView } from "./organisation-new-deal-requests-page-view";
import { InProgressNewDealRequestOverview, OrganisationAdvanceNewDealRequestResp } from "adl-gen/ferovinum/app/api";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { useAppService } from "../../../../../hooks/use-app-service";
import { assertNotUndefined } from "utils/hx/util/types";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { NewDealRequestOrganisationView } from "adl-gen/ferovinum/app/procurement";
import { isLoaded } from "utils/utility-types";
import { now } from "utils/date-utils";

export const OrganisationNewDealRequestsPage = () => {
  const appService = useAppService();
  const [showAlert] = useAlert();

  const organisationId = assertNotUndefined(useSelectedOrgId());

  const getInProgressPurchaseOrders = useCallback(async () => {
    try {
      const deals = await appService.getNewDealRequestsForOrganisation({
        organisationId,
        filter: "inProgress",
        count: -1,
        offset: 0,
      });

      const overviews = await appService.getInProgressNewDealRequestsOverview({ organisationId });
      return {
        overviews,
        actionablePurchaseOrders: deals.items.filter(
          deal => deal.currentState === "feroAccepted" || deal.currentState === "depositInvoiced",
        ),
        nonActionablePurchaseOrders: deals.items.filter(
          deal => deal.currentState !== "feroAccepted" && deal.currentState !== "depositInvoiced",
        ),
      };
    } catch (e) {
      await showAlert({ title: "Error", body: "Error retrieving purchase orders. Please try again." });
      return { overviews: [], actionablePurchaseOrders: [], nonActionablePurchaseOrders: [] };
    }
  }, [appService, organisationId, showAlert]);
  const [inProgressPurchaseOrders, refreshInProgressPurchaseOrders] = useLoadingDataState<{
    overviews: InProgressNewDealRequestOverview[];
    actionablePurchaseOrders: NewDealRequestOrganisationView[];
    nonActionablePurchaseOrders: NewDealRequestOrganisationView[];
  }>(getInProgressPurchaseOrders);

  const getCompletedPurchaseOrders = useCallback(async () => {
    try {
      const deals = await appService.getNewDealRequestsForOrganisation({
        organisationId,
        filter: "completed",
        count: -1,
        offset: 0,
      });
      return deals.items;
    } catch (e) {
      await showAlert({ title: "Error", body: "Error retrieving purchase orders. Please try again." });
      return [];
    }
  }, [appService, organisationId, showAlert]);
  const [completedPurchaseOrders, refreshCompletedPurchaseOrders] =
    useLoadingDataState<NewDealRequestOrganisationView[]>(getCompletedPurchaseOrders);

  const handleOrgAction = useCallback(
    async (action: () => Promise<OrganisationAdvanceNewDealRequestResp>) => {
      const resp = await action();
      if (resp.kind !== "success") {
        switch (resp.kind) {
          case "invalidDealId":
            await showAlert({ title: "Invalid Deal Id", body: "This deal id was not found" });
            break;
          case "invalidAction":
            await showAlert({ title: "Invalid Action", body: resp.value });
        }
      } else {
        // Reload
        await refreshInProgressPurchaseOrders();
        await refreshCompletedPurchaseOrders();
      }
    },
    [refreshCompletedPurchaseOrders, refreshInProgressPurchaseOrders, showAlert],
  );

  const markDepositAsPaid = useCallback(
    async (newDealRequestId: string) => {
      await handleOrgAction(async () =>
        appService.organisationAdvanceNewStockNewDealRequest({
          action: "depositPaid",
          newDealRequestId,
          actionTime: now(),
        }),
      );
    },
    [handleOrgAction, appService],
  );
  const acknowledgeDealTerms = useCallback(
    async (newDealRequestId: string, newStock: boolean, accept: boolean) => {
      if (newStock) {
        await handleOrgAction(async () =>
          appService.organisationAdvanceNewStockNewDealRequest({
            action: accept ? "confirmTerms" : "rejectTerms",
            newDealRequestId,
            actionTime: now(),
          }),
        );
      } else {
        await handleOrgAction(async () =>
          appService.organisationAdvanceExistingStockNewDealRequest({
            action: accept ? "confirmTerms" : "rejectTerms",
            newDealRequestId,
            actionTime: now(),
          }),
        );
      }
    },
    [handleOrgAction, appService],
  );

  return (
    <Loader loadingStates={[inProgressPurchaseOrders, completedPurchaseOrders]} fullScreen>
      {isLoaded(inProgressPurchaseOrders) && isLoaded(completedPurchaseOrders) && (
        <OrganisationNewDealRequestsPageView
          inProgress={inProgressPurchaseOrders.value}
          completedPurchaseOrders={completedPurchaseOrders.value}
          acknowledgeDealTerms={acknowledgeDealTerms}
          markDepositAsPaid={markDepositAsPaid}
        />
      )}
    </Loader>
  );
};
