import React from "react";
import { PortalPageContent } from "../../layouts/portal-page-content/portal-page-content";

export const HelpUserManualPage = () => {
  return (
    <PortalPageContent fixedHeight={true}>
      <iframe
        title="User Manual"
        height="100%"
        width="100%"
        src="https://doc.clickup.com/9012051727/p/h/8cjhzrf-992/de72f20a0a66f3b"
        allowFullScreen></iframe>
    </PortalPageContent>
  );
};
