import React from "react";
import { useInfoDrawer } from "../../../layouts/info-drawer/info-drawer";
import { ProductMovement } from "../../product-movement/product-movement";
import {
  NewDealRequestSummaryTable,
  NewDealRequestSummaryTableProps,
} from "components/widgets/new-deal-request-summary-table/new-deal-request-summary-table";

export type OrganisationNewDealRequestSummaryTableProps = Omit<NewDealRequestSummaryTableProps, "onProductClicked">;
export const OrganisationNewDealRequestSummaryTable = (props: OrganisationNewDealRequestSummaryTableProps) => {
  const [openProductInfo] = useInfoDrawer();
  return (
    <NewDealRequestSummaryTable
      {...props}
      onProductClicked={productId => openProductInfo({ children: <ProductMovement productId={productId} /> })}
    />
  );
};
