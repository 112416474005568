import { Grid, Stack } from "@mui/material";
import React from "react";
import { Card, CardProps } from "./card";

export const CardStack = ({
  direction,
  spacing,
  cards,
}: {
  direction?: "row" | "column";
  spacing: number;
  cards: CardProps[];
}) => {
  const checkedDirection = direction || "column";
  const extraProp = checkedDirection === "column" ? { height: "100%" } : { width: "100%" };
  return (
    <Stack direction={checkedDirection} spacing={spacing} {...extraProp}>
      {cards.map((card, i) => (
        <Card key={i} {...{ ...card, sx: { ...card.sx, flexGrow: 1 } }} />
      ))}
    </Stack>
  );
};

export const CardGrid = ({
  direction,
  spacing,
  cardsGrid,
}: {
  direction?: "row" | "column";
  spacing: number;
  cardsGrid: CardProps[][];
}) => {
  return (
    <Stack spacing={spacing}>
      <Grid container spacing={spacing}>
        {cardsGrid.map((cards, i) => (
          <Grid key={i} item xs={4}>
            <CardStack {...{ direction, spacing, cards }} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
