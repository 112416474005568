/** Brand colors */
export const BRAND_COLORS = {
  purple: "#2D2940",
  lightPurple: "#F5F4FA",
  veryLightPurple: "#F5F4FA",
  yellow: "#F2A73D",
  lightYellow: "#FAE0BB",
  grey: "#A8A8A8",
  darkGrey: "#585858",
};

export const GREY_COLORS = {
  white: "#FFFFFF",
  grey1: "#F7FAFC", // grey 100
  grey2: "#EDF2F7", //grey 200
  grey3: "#E8E6F2", //grey 300
  grey4: "#D0CED9", //grey 400
  grey5: "#86858C", //grey 600
  grey6: "#565559", //grey 700
  grey7: "#252426", //grey 900
};

export const SIGNALS_COLORS = {
  errorLight: "#F9D1D1",
  error: "#800D0D",
  errorDark: "#830101",
  warningLight: "#FFD8B4",
  warning: "#CC5500",
  warningDark: "#863304",
  successLight: "#B3DED0",
  success: "#297954",
  successDark: "#276749",
};

export const COMMON_COLORS = {
  hyperLink: "#0085FF",
  lightBackground: "#F8F7FA",
};

/** App bar colors for the different environment levels */
export const ENV_APP_BAR_HEADER_COLORS: {
  [key: string]: string;
} = {
  prod: BRAND_COLORS.purple,
  uat: "#CC5500",
  demo: "#545659",
  dev: "#11B38C",
};
