import { LocalDate, LocalTime } from "adl-gen/common";
import { PurchaseRequestPaymentTermsPeriod } from "adl-gen/ferovinum/app/db";
import assertNever from "assert-never";
import React from "react";
import { formatDate } from "utils/date-utils";
import { Address, AddressTypography } from "../address/address-typography";
import { CardProps, CardText, stackStrings } from "./card";
import { MONTHS_AFTER_DELIVERY_FIELD_DESCRIPTIONS } from "../payment-terms-input";

export function makeDeliveryDetailsNoAddressCard({
  deliveryContact,
  deliveryContactEmail,
  deliveryContactNumber,
  deliveryInstructions,
  deliveryDate,
  deliveryTimeEarliest,
  deliveryTimeLatest,
}: {
  deliveryContact: string | null;
  deliveryContactEmail: string | null;
  deliveryContactNumber: string | null;
  deliveryInstructions: string | null;
  deliveryDate: LocalDate | null;
  deliveryTimeEarliest: LocalTime | null;
  deliveryTimeLatest: LocalTime | null;
}): CardProps {
  const contact = stackStrings([deliveryContact, deliveryContactEmail, deliveryContactNumber]);

  const time =
    deliveryDate && deliveryTimeEarliest && deliveryTimeLatest
      ? stackStrings([formatDate(new Date(deliveryDate)), `${deliveryTimeEarliest} - ${deliveryTimeLatest}`])
      : null;
  return {
    header: {
      title: "Delivery Details",
    },
    body: (
      <>
        <CardText title="Date & Time Requested" content={time} lineNumbers={2} />
        <CardText title="Contact" content={contact} lineNumbers={3} />
        <CardText title="Delivery instructions" content={deliveryInstructions} lineNumbers={3} />
      </>
    ),
  };
}

export function makeDeliveryLocationCard(locationName: string, address?: Address, date?: LocalDate): CardProps {
  return makeLocationCard({
    title: "Deliver To",
    locationName,
    address,
    dateHeading: "Requested Delivery Date",
    date: date,
  });
}

export function makeCollectionLocationCard(locationName: string, address?: Address, date?: LocalDate): CardProps {
  return makeLocationCard({
    title: "Collect From",
    locationName,
    address,
    dateHeading: "Earliest Collection Date",
    date: date,
  });
}

function makeLocationCard({
  title,
  locationName,
  address,
  dateHeading,
  date,
}: {
  title: string;
  locationName: string;
  address?: Address;
  dateHeading?: string;
  date?: LocalDate;
}) {
  return {
    header: { title },
    body: (
      <>
        <CardText title="" content={locationName} />
        {address && <CardText title="Address" content={<AddressTypography {...address} />} lineNumbers={4} />}
        {dateHeading && date && <CardText title={dateHeading} content={date} />}
      </>
    ),
  };
}

export function makeTradeTermsCard(bonded: boolean, incoterms: string, showTerms?: () => void): CardProps {
  return {
    header: {
      title: "Trade Terms",
      action: showTerms && {
        label: "Terms and Conditions",
        onClick: showTerms,
      },
    },
    body: (
      <>
        <CardText title="" content={bonded ? "In-Bond" : "Out-of-Bond"} lineNumbers={1} />
        <CardText title="Incoterms" content={incoterms} lineNumbers={4} />
      </>
    ),
  };
}

export function makePaymentMethodCard(usesSettlementCredit: boolean): CardProps {
  return {
    header: { title: "Payment Method" },
    body: (
      <CardText title="" content={usesSettlementCredit ? "Credit Settlement" : "Direct Transfer"} lineNumbers={1} />
    ),
  };
}

export function makePaymentTermsCard(paymentTermsPeriod: PurchaseRequestPaymentTermsPeriod): CardProps {
  const paymentTermsDescription =
    paymentTermsPeriod.kind === "daysAfterCollection"
      ? `${paymentTermsPeriod.value} days from invoice`
      : paymentTermsPeriod.kind === "endOfMonth"
      ? `${MONTHS_AFTER_DELIVERY_FIELD_DESCRIPTIONS[paymentTermsPeriod.value]} after delivery`
      : (assertNever(paymentTermsPeriod), "");

  return {
    header: { title: "Payment Terms" },
    body: <CardText title="Payment terms" content={paymentTermsDescription} />,
  };
}

export function makeCarrierCard(
  carrier: string,
  carrierContactName?: string,
  carrierContactEmail?: string,
  carrierContactNumber?: string,
): CardProps {
  const contact = stackStrings([carrierContactName, carrierContactEmail, carrierContactNumber]);
  return {
    header: { title: "Carrier" },
    body: (
      <>
        <CardText title="" content={carrier} />
        <CardText title="Contact" content={contact} lineNumbers={3} />
      </>
    ),
  };
}
