import { StorageLocationOutgoingOrderLineItem } from "adl-gen/ferovinum/app/api";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import { OrganisationProductSummary } from "../../organisation-product-summary/organisation-product-summary";
import React from "react";

export interface OutgoingOrderProductTableProps {
  lineItems: StorageLocationOutgoingOrderLineItem[];
}
export const OutgoingOrderProductTable = ({ lineItems }: OutgoingOrderProductTableProps) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Product</TableCell>
        <TableCell align={"right"}>Quantity</TableCell>
      </TableRow>
    </TableHead>
    {lineItems.map(lineItem => {
      const product = lineItem.product.value;
      return (
        <TableRow key={lineItem.product.id}>
          <TableCell>
            <OrganisationProductSummary {...product} />
          </TableCell>
          <TableCell align={"right"}>{lineItem.numberOfUnits.value}</TableCell>
        </TableRow>
      );
    })}
  </Table>
);
