import React from "react";
import { ProductionOrderListingView } from "adl-gen/ferovinum/app/api";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AppRoutes } from "../../../../app/app-routes";
import { formatInstant } from "utils/date-utils";
import { OrganisationProductSummary } from "../../organisation-product-summary/organisation-product-summary";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { Link } from "components/widgets/link/link";
import { DbKey } from "adl-gen/common/db";
import { ProductionOrder } from "adl-gen/ferovinum/app/db";

export interface ProductionOrderListingsTableProps {
  productionOrders: ProductionOrderListingView[];
  productionOrderDetailsLink: AppRoutes;
  includeActionButton?: {
    onClickActionButton: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: DbKey<ProductionOrder>) => void;
  };
}
export const ProductionOrderListingsTable = ({
  productionOrders,
  productionOrderDetailsLink,
  includeActionButton,
}: ProductionOrderListingsTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Order Number</TableCell>
            <TableCell>Order date</TableCell>
            <TableCell>Input product(s)</TableCell>
            <TableCell>Service(s)</TableCell>
            {includeActionButton && <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>

        <TableBody>
          {productionOrders.map(po => {
            const rowSpan = po.sourceProductAndFinishingServiceNames.length;
            const { sourceProduct, finishingServiceName } = po.sourceProductAndFinishingServiceNames[0];

            return (
              <React.Fragment key={po.productionOrderId}>
                <TableRow>
                  <TableCell rowSpan={rowSpan}>
                    <Link variant="big" href={`${productionOrderDetailsLink}/${po.productionOrderId}`}>
                      {po.orderNumber}
                    </Link>
                  </TableCell>
                  <TableCell rowSpan={rowSpan}>{formatInstant(po.createdAt)}</TableCell>

                  <TableCell>
                    <OrganisationProductSummary {...sourceProduct.value} />
                  </TableCell>

                  <TableCell>
                    <Typography>{finishingServiceName}</Typography>
                  </TableCell>

                  {includeActionButton && (
                    <TableCell rowSpan={rowSpan}>
                      <ActionButton onClick={e => includeActionButton.onClickActionButton(e, po.productionOrderId)} />
                    </TableCell>
                  )}
                </TableRow>

                {po.sourceProductAndFinishingServiceNames.slice(1).map(({ sourceProduct, finishingServiceName }) => (
                  <TableRow key={sourceProduct.id}>
                    <TableCell>
                      <OrganisationProductSummary {...sourceProduct.value} />
                    </TableCell>

                    <TableCell>{finishingServiceName}</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface ActionButtonProps {
  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}
const ActionButton = ({ onClick }: ActionButtonProps) => {
  return (
    <IconButton onClick={onClick}>
      <MoreVertOutlinedIcon sx={{ fill: theme => theme.palette.common.grey4 }} />
    </IconButton>
  );
};
