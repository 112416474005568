import React from "react";
import { Currency, FinishingService, Product } from "adl-gen/ferovinum/app/db";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { unitsLabelForUnitType } from "utils/model-utils";
import { WithDbId } from "adl-gen/common/db";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";

export interface ProductionOrderTasksSummaryTableProps {
  productionOrderTasks: {
    sourceProduct: WithDbId<Product>;
    finishingService: FinishingService;
    pricePerUnit: MonetaryValue;
    setupCost: MonetaryValue;
    subtotalCost: MonetaryValue;
    totalCost: MonetaryValue;
    targetQuantity: number;
    budgetQuantity?: number;
  }[];
  currency: Currency;
}

export const ProductionOrderTasksSummaryTable = ({
  productionOrderTasks,
  currency,
}: ProductionOrderTasksSummaryTableProps) => {
  // There can only be 1 unit type for all tasks in a production order
  const poUnitType = productionOrderTasks[0].sourceProduct.value.unitType;
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Service details</TableCell>
            <TableCell align="right">Price per unit</TableCell>
            <TableCell align="right">{`Input quantity (${unitsLabelForUnitType(poUnitType)})`}</TableCell>
            <TableCell align="right">Subtotal</TableCell>
            <TableCell align="right">Setup fee</TableCell>
            <TableCell align="right">Estimated total</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {productionOrderTasks.map(
            (
              { finishingService, pricePerUnit, subtotalCost, setupCost, totalCost, targetQuantity, budgetQuantity },
              idx,
            ) => {
              const hasNoBudget = budgetQuantity === undefined;
              return (
                <TableRow key={`${finishingService.code}-${idx}`}>
                  <TableCell>{finishingService.name}</TableCell>
                  <PriceCell value={pricePerUnit} currency={currency} />
                  <TableCell align="right">{`${hasNoBudget ? "min. " : ""}${targetQuantity}`}</TableCell>
                  <PriceCell value={subtotalCost} currency={currency} isMin={hasNoBudget} />
                  <PriceCell value={setupCost} currency={currency} />
                  <PriceCell value={totalCost} currency={currency} isMin={hasNoBudget} />
                </TableRow>
              );
            },
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface PriceCellProps {
  currency: Currency;
  value: MonetaryValue;
  isMin?: boolean;
}
const PriceCell = ({ currency, value, isMin }: PriceCellProps) => {
  return (
    <TableCell align="right">
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
        {isMin && <Typography>min.</Typography>}
        <CurrencyRenderer currency={currency} value={value} />
      </Stack>
    </TableCell>
  );
};
