import { LocalDate } from "adl-gen/common";
import { StorageLocationView } from "adl-gen/ferovinum/app/api";
import React from "react";
import { StockMovementDeliveryDetails } from "utils/hooks/use-stock-movement-summary";
import { makeCarrierCard, makeCollectionLocationCard, makeDeliveryLocationCard } from "./card/card-makers";
import { CardGrid } from "./card/card-widgets";

export const StockMovementCardGrid = ({
  startingLocation,
  destinationLocation,
  earliestCollectionDate,
  deliveryDetails,
}: {
  startingLocation: StorageLocationView;
  destinationLocation: StorageLocationView;
  earliestCollectionDate: LocalDate;
  deliveryDetails: StockMovementDeliveryDetails;
}) => {
  const collectionAddress = {
    addressLine1: startingLocation.addressLine1 ?? "",
    addressLine2: startingLocation.addressLine2 ?? "",
    town: startingLocation.town ?? "",
    postCode: startingLocation.postCode ?? "",
  };
  const destinationAddress = {
    addressLine1: destinationLocation.addressLine1 ?? "",
    addressLine2: destinationLocation.addressLine2 ?? "",
    town: destinationLocation.town ?? "",
    postCode: destinationLocation.postCode ?? "",
  };

  const deliveryDate = deliveryDetails.kind === "carrier" ? deliveryDetails.deliveryDate : earliestCollectionDate;
  const carrierCardData = {
    carrier: deliveryDetails.kind === "carrier" ? deliveryDetails.carrier : "Self Organised Delivery",
    carrierContactName:
      deliveryDetails.kind === "carrier" ? deliveryDetails.carrierContactName : deliveryDetails.contactName,
    carrierContactEmail:
      deliveryDetails.kind === "carrier" ? deliveryDetails.carrierContactEmail : deliveryDetails.contactEmail,
    carrierContactNumber:
      deliveryDetails.kind === "carrier" ? deliveryDetails.carrierContactNumber : deliveryDetails.contactNumber ?? "",
  };
  return (
    <CardGrid
      spacing={4}
      direction="column"
      cardsGrid={[
        [
          // Column 1
          makeCollectionLocationCard(startingLocation.storageLocationName, collectionAddress, earliestCollectionDate),
        ],
        [
          // Column 2
          makeDeliveryLocationCard(destinationLocation.storageLocationName, destinationAddress, deliveryDate),
        ],
        [
          // Column 3
          makeCarrierCard(
            carrierCardData.carrier,
            carrierCardData.carrierContactName,
            carrierCardData.carrierContactEmail,
            carrierCardData.carrierContactNumber,
          ),
        ],
      ]}
    />
  );
};
