import { Divider, FormControl, FormLabel, Stack, TextField, TextFieldProps } from "@mui/material";
import { SavedDeliveryInfo, ShippingDelivery } from "adl-gen/ferovinum/app/db";
import { TextArea } from "components/widgets/inputs/text-area/text-area";
import { addDays } from "date-fns";
import { FormikErrors, FormikProps, FormikTouched, FormikValues } from "formik";
import { isValid as isValidUKPostcode } from "postcode";
import React, { useMemo } from "react";
import { EMAILREGEX } from "utils/email-regex";
import { KeyByType } from "utils/utility-types";
import { AnyObjectSchema, date, string } from "yup";
import { DateAndTimeRange, DateFieldType } from "./date-and-time-range";
import { OptionPicker } from "components/library/helpers/option-picker";

export const INITIAL_ADDRESS_VALUES: DeliveryDetailsForm = {
  deliveryPointName: "",
  streetName: "",
  addressLine1: "",
  addressLine2: "",
  town: "",
  postCode: "",
  deliveryContact: "",
  deliveryContactEmail: "",
  deliveryContactNumber: "",
  deliveryInstructions: "",
  deliveryDate: null,
  deliveryTimeEarliest: null,
  deliveryTimeLatest: null,
};
interface DeliveryDetailsForm
  extends Omit<ShippingDelivery, "deliveryDate" | "deliveryTimeEarliest" | "deliveryTimeLatest" | "bonded"> {
  deliveryDate: Date | null;
  deliveryTimeEarliest: Date | null;
  deliveryTimeLatest: Date | null;
}

export function DeliveryDetailsWidget<T extends FormikValues>({
  form,
  formKey,
  storageLocationName,
  savedDeliveryInfoList,
}: {
  form: FormikProps<T>;
  formKey: KeyByType<T, DeliveryDetailsForm> & string;
  storageLocationName: string;
  savedDeliveryInfoList?: SavedDeliveryInfo[];
}) {
  const commonInputProps: Partial<TextFieldProps> = {
    fullWidth: true,
    onChange: form.handleChange,
    onBlur: form.handleBlur,
    inputProps: { maxLength: 50 },
  };

  const deliveryDateRange = useMemo(() => makeDeliveryDateRange(), []);

  const savedAddressOptions = useMemo(() => {
    if (savedDeliveryInfoList) {
      return savedDeliveryInfoList.length > 0 ? savedDeliveryInfoList.map(info => info.deliveryPointName) : [];
    } else {
      return undefined;
    }
  }, [savedDeliveryInfoList]);

  const formValues = form.values[formKey];
  const formTouched = form.touched[formKey] as FormikTouched<DeliveryDetailsForm> | undefined;
  const formErrors = form.errors[formKey] as FormikErrors<DeliveryDetailsForm> | undefined;

  return (
    <Stack direction="row" spacing={4} divider={<Divider orientation="vertical" flexItem />}>
      <Stack spacing={3} flex={1}>
        <FormLabel component="legend">Delivery Address</FormLabel>
        {savedAddressOptions && (
          <FormControl fullWidth>
            <OptionPicker
              value={form.values[formKey]?.deliveryPointName ?? ""}
              options={savedAddressOptions}
              onValueChange={value => {
                const selection = savedDeliveryInfoList?.find(info => info.deliveryPointName == value);
                let newAddressDetails = INITIAL_ADDRESS_VALUES;
                if (selection) {
                  newAddressDetails = {
                    ...newAddressDetails,
                    deliveryPointName: selection.deliveryPointName,
                    streetName: selection.address.streetName,
                    addressLine1: selection.address.line1 ?? "",
                    addressLine2: selection.address.line2 ?? "",
                    town: selection.address.town,
                    postCode: selection.address.postCode,
                    deliveryContact: selection.contact?.name ?? "",
                    deliveryContactEmail: selection.contact?.email ?? "",
                    deliveryContactNumber: selection.contact?.phoneNumber ?? "",
                    deliveryInstructions: selection.instruction ?? "",
                  };
                }

                form.setFieldValue(formKey, { ...formValues, ...newAddressDetails });
              }}
              textFieldProps={{
                label: savedAddressOptions.length === 0 ? "No Saved Addresses" : "Select Saved Delivery Addresses",
              }}
              disabled={savedAddressOptions.length === 0}
            />
          </FormControl>
        )}
        <TextField
          name={`${formKey}.deliveryPointName`}
          label="Delivery point name"
          required={true}
          value={formValues?.deliveryPointName ?? ""}
          error={(formTouched?.deliveryPointName ?? false) && Boolean(formErrors?.deliveryPointName ?? false)}
          {...commonInputProps}
        />
        <TextField
          name={`${formKey}.streetName`}
          label="Number and Street"
          required={true}
          value={formValues.streetName}
          error={(formTouched?.streetName ?? false) && Boolean(formErrors?.streetName ?? false)}
          {...commonInputProps}
        />
        <TextField
          name={`${formKey}.addressLine1`}
          label="Address line 1"
          value={formValues.addressLine1}
          error={(formTouched?.addressLine1 ?? false) && Boolean(formErrors?.addressLine1 ?? false)}
          {...commonInputProps}
        />
        <TextField
          name={`${formKey}.addressLine2`}
          label="Address line 2"
          value={formValues.addressLine2}
          error={(formTouched?.addressLine2 ?? false) && Boolean(formErrors?.addressLine2 ?? false)}
          {...commonInputProps}
        />
        <TextField
          name={`${formKey}.town`}
          label="Town"
          required={true}
          value={formValues.town}
          error={(formTouched?.town ?? false) && Boolean(formErrors?.town ?? false)}
          {...commonInputProps}
        />
        <TextField
          name={`${formKey}.postCode`}
          label="Postcode"
          required={true}
          value={formValues.postCode}
          error={(formTouched?.postCode ?? false) && Boolean(formErrors?.postCode ?? false)}
          {...commonInputProps}
          inputProps={{ maxLength: 15 }}
        />
      </Stack>
      <Stack spacing={3} flex={1}>
        <FormLabel component="legend">Delivery Contact</FormLabel>
        <TextField
          name={`${formKey}.deliveryContact`}
          label="Name"
          value={formValues.deliveryContact}
          error={(formTouched?.deliveryContact ?? false) && Boolean(formErrors?.deliveryContact ?? false)}
          {...commonInputProps}
        />
        <TextField
          name={`${formKey}.deliveryContactEmail`}
          label="Email"
          value={formValues.deliveryContactEmail}
          error={(formTouched?.deliveryContactEmail ?? false) && Boolean(formErrors?.deliveryContactEmail ?? false)}
          {...commonInputProps}
        />
        <TextField
          name={`${formKey}.deliveryContactNumber`}
          label="Contact number"
          value={formValues.deliveryContactNumber}
          error={(formTouched?.deliveryContactNumber ?? false) && Boolean(formErrors?.deliveryContactNumber ?? false)}
          {...commonInputProps}
        />
        <DateAndTimeRange
          label="Delivery Details"
          name="address"
          minDate={deliveryDateRange.min}
          maxDate={deliveryDateRange.max}
          minDeliveryWindowInMinutes={getMinimumDeliveryWindowInMinutesByStorageLocationName(storageLocationName)}
          formLabels={{
            date: "Delivery date",
            timeEarliest: "Earliest delivery time",
            timeLatest: "Delivery Window Duration",
          }}
          values={{
            date: formValues.deliveryDate,
            timeEarliest: formValues.deliveryTimeEarliest,
            timeLatest: formValues.deliveryTimeLatest,
          }}
          errors={{
            date: formErrors?.deliveryDate,
            timeEarliest: formErrors?.deliveryTimeEarliest,
            timeLatest: formErrors?.deliveryTimeLatest,
          }}
          handleDateChange={(value: Date, fieldType: DateFieldType) => {
            const fieldMap = {
              [DateFieldType.Date]: `${formKey}.deliveryDate`,
              [DateFieldType.TimeEarliest]: `${formKey}.deliveryTimeEarliest`,
              [DateFieldType.TimeLatest]: `${formKey}.deliveryTimeLatest`,
            };

            const fieldName = fieldMap[fieldType];
            form.setFieldTouched(fieldName, true);
            form.setFieldValue(fieldName, value, true);
          }}
        />
        <TextArea
          name={`${formKey}.deliveryInstructions`}
          rows={5}
          label="Delivery instructions"
          value={formValues.deliveryInstructions}
          error={(formTouched?.deliveryInstructions ?? false) && Boolean(formErrors?.deliveryInstructions ?? false)}
          maxCharacters={120}
          {...commonInputProps}
          inputProps={{ maxLength: 120 }}
        />
      </Stack>
    </Stack>
  );
}

function getMinimumDeliveryWindowInMinutesByStorageLocationName(storageLocationName: string): number {
  if (storageLocationName.startsWith("LCB, ")) {
    return 3 * 60; // 3 hours
  } else {
    return 15;
  }
}

const SHIPPING_DELIVERY_BOOKING_LIMIT = 7;

function makeDeliveryDateRange() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return {
    min: today,
    max: addDays(today, SHIPPING_DELIVERY_BOOKING_LIMIT),
  };
}

export function setDeliveryDetailsSchemaShape(schema: AnyObjectSchema) {
  const deliveryDateRange = makeDeliveryDateRange();
  return schema.required().shape(
    {
      deliveryPointName: string().trim().required().max(50),
      streetName: string().trim().required().max(50),
      addressLine1: string().trim().optional().max(50),
      addressLine2: string().trim().optional().max(50),
      town: string().trim().required().max(50),
      postCode: string()
        .test("UK postcode validation", p => isValidUKPostcode(p ?? ""))
        .trim()
        .required()
        .max(15),
      deliveryContact: string().trim().nullable().toNull().optional().max(50),
      deliveryContactEmail: string().matches(EMAILREGEX).trim().nullable().toNull().optional().max(50),
      deliveryContactNumber: string().trim().nullable().toNull().optional().max(30),
      deliveryDate: date()
        .nullable()
        .min(deliveryDateRange.min)
        .max(deliveryDateRange.max)
        .when(["deliveryTimeEarliest", "deliveryTimeLatest"], {
          is: (t1: number, t2: number) => {
            return (t1 !== null && !isNaN(t1)) || (t2 !== null && !isNaN(t2));
          },
          then: schema => schema.required("Delivery Date is Required"),
        }),
      deliveryTimeEarliest: date()
        .nullable()
        .when(["deliveryDate", "deliveryTimeLatest"], {
          is: (date: number, t1: number) => {
            return (date !== null && !isNaN(date)) || (t1 !== null && !isNaN(t1));
          },
          then: schema => schema.required(),
        }),
      deliveryTimeLatest: date()
        .nullable()
        .when(["deliveryDate", "deliveryTimeEarliest"], {
          is: (date: number, t1: number) => {
            return (date !== null && !isNaN(date)) || (t1 !== null && !isNaN(t1));
          },
          then: schema => schema.required(),
        }),
      deliveryInstructions: string().trim().nullable().toNull().optional().max(120),
    },
    //Note:
    // https://github.com/jquense/yup#objectshapefields-object-nosortedges-arraystring-string-schema
    // https://runkit.com/5908cecfe97ebf0012f2e3c9/5a8d87d03a470f00122666d8
    [
      ["deliveryTimeLatest", "deliveryTimeEarliest"],
      ["deliveryDate", "deliveryTimeEarliest"],
      ["deliveryDate", "deliveryTimeLatest"],
    ],
  );
}
