import { TableCell, styled } from "@mui/material";

export const GroupHeaderCell = styled(TableCell)(({ theme }) => ({
  "textAlign": "center",
  "background": `
  linear-gradient(to right,
  transparent,
  transparent 10%,
  ${theme.palette.common.grey3} 10%,
  ${theme.palette.common.grey3} 90%,
  transparent 90%)`,
  "&:before": {
    position: "absolute",
    top: 0,
    left: "10px",
    content: "''",
    width: "10%",
    height: "100%",
    backgroundColor: theme.palette.common.grey3,
    transform: "skew(-20deg)",
  },
  "&:after": {
    position: "absolute",
    top: 0,
    right: "10px",
    content: "''",
    width: "10%",
    height: "100%",
    backgroundColor: theme.palette.common.grey3,
    transform: "skew(20deg)",
  },
}));
