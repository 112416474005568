import React, { useCallback, useMemo } from "react";
import {
  OrganisationNewDealRequestSetupPageView,
  SetupNewDealRequestFormValues,
} from "./organisation-new-deal-request-setup-page-view";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../../../app/app-routes";
import { Currency, Product } from "adl-gen/ferovinum/app/db";
import { WithDbId } from "adl-gen/common/db";
import { assertNotUndefined } from "utils/hx/util/types";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useSelectedOrgId, useSettlementCurrency } from "../../../../layouts/portal-page-layout/portal-page";
import {
  isNewSupplier,
  NewProduct,
  NewProductData,
  ProductLineItemData,
  ProductLineItemFormData,
  NewDealRequestFlowState,
} from "../organisation-create-new-deal-request/organisation-create-new-deal-request-page";
import { CsvProductDataProcessor } from "../../../../../service/csv-product-data-processor";
import { BulkQuantityUnitType, isSinglesUnitType, numberOfUnitsForUnitType } from "utils/model-utils";
import { isValidNumber } from "utils/ts-utils";
import { ParseResult } from "papaparse";
import { makeProductSearchQueryReq } from "adl-gen/ferovinum/app/api";

export function isNewProduct(product: ProductLineItemData | ProductLineItemFormData): product is NewProduct {
  return product.kind === "new";
}

export function isNewProductData(product: WithDbId<Product> | NewProductData): product is NewProductData {
  // @ts-ignore
  return product["id"] === undefined;
}

export function productLineItemIsSingles(product: ProductLineItemFormData) {
  return isSinglesUnitType(isNewProduct(product) ? product.value.unitType : product.value.value.unitType);
}

export const numberToBigDecimal = (num: number | undefined): string | undefined =>
  num !== undefined ? num.toString() : undefined;

function fromProductFormDataToProductData(p: ProductLineItemFormData): ProductLineItemData {
  const unitType = isNewProduct(p) ? p.value.unitType : p.value.value.unitType;
  return {
    ...p,
    numberOfUnits:
      p.numberOfUnits !== undefined && isValidNumber(p.numberOfUnits)
        ? numberOfUnitsForUnitType(unitType, p.numberOfUnits, p.bulkQuantityUnitType)
        : undefined,
    numberOfFreeUnits:
      p.numberOfFreeUnits !== undefined && isValidNumber(p.numberOfFreeUnits)
        ? numberOfUnitsForUnitType(unitType, p.numberOfFreeUnits, p.bulkQuantityUnitType)
        : undefined,
    purchasePrice: isValidNumber(p.purchasePrice) ? p.purchasePrice : undefined,
    individualDiscountPct: numberToBigDecimal(p.individualDiscountPct),
  };
}

export const OrganisationNewDealRequestSetupPage = () => {
  const location = useLocation();
  const history = useHistory();
  const appService = useAppService();
  const organisationId = assertNotUndefined(useSelectedOrgId());
  const purchaseOrderFlowState = location.state as NewDealRequestFlowState | undefined;
  const settlementCurrency = useSettlementCurrency();
  const csvReader = useMemo(
    () => new CsvProductDataProcessor({ appService, orgId: organisationId }),
    [appService, organisationId],
  );

  const onValidateProductCode = useCallback(
    async (productCode: string | undefined) => {
      if (productCode) {
        return appService.checkIfProductCodeExists({ productCode });
      } else {
        return false;
      }
    },
    [appService],
  );

  const submitProductSelection = useCallback(
    ({ currency, selectedProducts, discount }: SetupNewDealRequestFormValues) => {
      const state: NewDealRequestFlowState = {
        ...assertNotUndefined(purchaseOrderFlowState),
        selectedProducts: selectedProducts.map(fromProductFormDataToProductData),
        currency,
        discount,
      };
      history.push(
        purchaseOrderFlowState?.variant === "newStock"
          ? AppRoutes.NewDealRequestDeliveryOptions
          : AppRoutes.NewDealRequestConfirmation,
        state,
      );
    },
    [history, purchaseOrderFlowState],
  );

  const searchProducts = useCallback(
    async (searchTerm: string) => {
      return await appService.productSearch(makeProductSearchQueryReq({ organisationId, searchTerm }));
    },
    [appService, organisationId],
  );

  const navigateBackToCreateNewDealRequest = useCallback(
    ({ currency, selectedProducts }: SetupNewDealRequestFormValues) => {
      const state: NewDealRequestFlowState = {
        ...assertNotUndefined(purchaseOrderFlowState),
        selectedProducts: selectedProducts.map(fromProductFormDataToProductData),
        currency,
      };
      history.push(AppRoutes.CreateNewDealRequest, state);
    },
    [history, purchaseOrderFlowState],
  );

  const bigDecimalToNumber = (bigDecimal: string | undefined): number | undefined =>
    bigDecimal !== undefined ? parseFloat(bigDecimal) : undefined;

  const onClickUploadProducts = useCallback(
    async (parseResult: ParseResult<string[]>) => {
      return await csvReader.parseCSVToProductLineItemData(parseResult, purchaseOrderFlowState?.variant === "newStock");
    },
    [csvReader, purchaseOrderFlowState?.variant],
  );

  if (purchaseOrderFlowState === undefined) {
    return <Redirect to={AppRoutes.OrganisationNewDealRequests} />;
  } else {
    const { variant, currency, selectedProducts, discount } = purchaseOrderFlowState;
    const approvedCurrencies: Currency[] = [];
    const initialFormProducts = selectedProducts.map(p => ({
      ...p,
      numberOfUnits: p.numberOfUnits?.value,
      numberOfFreeUnits: p.numberOfFreeUnits?.value,
      individualDiscountPct: bigDecimalToNumber(p.individualDiscountPct),
      bulkQuantityUnitType: p.numberOfUnits?.kind as BulkQuantityUnitType,
    }));
    if (purchaseOrderFlowState.variant === "newStock") {
      const supplier = assertNotUndefined(purchaseOrderFlowState.supplier);
      isNewSupplier(supplier)
        ? approvedCurrencies.push(...supplier.approvedCurrencies)
        : approvedCurrencies.push(...supplier.value.approvedCurrencies);
    } else {
      approvedCurrencies.push(settlementCurrency);
    }
    return (
      <OrganisationNewDealRequestSetupPageView
        variant={variant}
        approvedSupplierCurrencies={approvedCurrencies}
        initialFormValues={{
          discount,
          currency,
          selectedProducts: initialFormProducts,
        }}
        navigateBackToCreateNewDealRequest={navigateBackToCreateNewDealRequest}
        searchProducts={searchProducts}
        onNext={submitProductSelection}
        onValidateProductCode={onValidateProductCode}
        onClickUploadProducts={onClickUploadProducts}
      />
    );
  }
};
