import React, { isValidElement, useCallback, useContext } from "react";
import { DialogContext, GenericDialogButtonAction } from "./dialog-manager";
import { Breakpoint, Stack, Typography } from "@mui/material";
import { noop } from "utils/ts-utils";
import { formatLocalDate } from "utils/date-utils";
import MuiMarkdown from "mui-markdown";
import { LocalDate } from "adl-gen/common";
import { Term } from "adl-gen/ferovinum/app/db";

// Approach note: A dialog represents an action/dialog between the user and the app
// different responses to the dialog might have different reactions from the system

// Confirmation dialog that accepts a generic async action when confirming and returns the value of that action when the users accept
// optional param, cancel action
type UseDialogAction<T = void> = Partial<GenericDialogButtonAction<T>>;
export const useConfirmationDialog = () => {
  const { showDialog } = useContext(DialogContext);

  return {
    showConfirmationDialog: useCallback(
      async <T,>({
        title,
        body,
        confirmAction,
        cancelAction,
      }: {
        title: string;
        body?: React.ReactNode | string;
        confirmAction?: UseDialogAction<T>;
        cancelAction?: UseDialogAction;
      }) => {
        return await showDialog<T>({
          title: <Typography variant="h5">{title}</Typography>,
          body: body ? isValidElement(body) ? body : <Typography>{body}</Typography> : undefined,
          confirmAction: {
            onClick: noop as () => Promise<T>,
            ...confirmAction,
          },
          cancelAction: {
            onClick: noop,
            ...cancelAction,
          },
          customProps: {
            actions: { sx: { justifyContent: "center" } },
          },
        });
      },
      [showDialog],
    ),
  };
};

// Display terms in a dialog using a markdown renderer
// Optionally displays a master agreement date
export const useTermsDialog = () => {
  const { showDialog } = useContext(DialogContext);

  return {
    showTermsDialog: useCallback(
      async ({
        terms,
        markdownAdditionalTerms,
        masterAgreementDate,
      }: {
        terms: Term[];
        markdownAdditionalTerms?: string;
        masterAgreementDate?: LocalDate;
      }) => {
        const body = (
          <Stack spacing={4}>
            {masterAgreementDate && (
              <Typography variant="body1" align={"left"}>
                Date of Master agreement: {formatLocalDate(masterAgreementDate)}
              </Typography>
            )}
            <Stack>
              {terms.map((term, index) => (
                <Stack key={`term-md-${index}`} spacing={2} sx={{ mt: 1 }}>
                  <Typography variant={"h4"}>{term.header}</Typography>
                  <MuiMarkdown>{term.content}</MuiMarkdown>
                </Stack>
              ))}
              {markdownAdditionalTerms && (
                <Stack spacing={2} sx={{ mt: 1 }}>
                  <Typography variant={"h4"}>Additional Terms</Typography>
                  <MuiMarkdown>{markdownAdditionalTerms}</MuiMarkdown>
                </Stack>
              )}
            </Stack>
          </Stack>
        );
        return await showDialog<void>({
          body,
          showCloseIcon: true,
          customProps: {
            dialog: { scroll: "body", maxWidth: "md", PaperProps: { sx: { p: 4 } }, fullWidth: true },
          },
        });
      },
      [showDialog],
    ),
  };
};

export const useTradeSaleTermsDialog = () => {
  const { showDialog } = useContext(DialogContext);

  return {
    showTradeSaleTermsDialog: useCallback(async () => {
      const body = (
        <Stack>
          <object
            data="https://com-hx-ferovinum-assets.s3.eu-west-2.amazonaws.com/files/Ferovinum-General-Terms-and-Conditions-2024.pdf"
            type="application/pdf"
            width="100%"
            height="900"></object>
        </Stack>
      );

      return await showDialog<void>({
        body,
        showCloseIcon: true,
        customProps: {
          dialog: { scroll: "body", maxWidth: "lg", PaperProps: { sx: { p: 4 } }, fullWidth: true },
        },
      });
    }, [showDialog]),
  };
};

// Returns an informational dialog with a custom body
export const useInfoDialog = () => {
  const { showDialog } = useContext(DialogContext);

  return {
    showInfoDialog: useCallback(
      async ({ body, size }: { body: React.ReactNode; size?: Breakpoint }) => {
        return await showDialog<void>({
          body,
          showCloseIcon: true,
          customProps: {
            dialog: { scroll: "body", maxWidth: size ?? "xl", PaperProps: { sx: { p: 4 } }, fullWidth: true },
          },
        });
      },
      [showDialog],
    ),
  };
};
