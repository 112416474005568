import { AppService } from "adl-gen/app-service";
import { Currency, valuesCurrency } from "adl-gen/ferovinum/app/db";
import { CsvRowParser, CsvRowParserResult } from "utils/csv-row-parser";
import { CsvUploadResult, RowIdentifierAndErrors, WithCsvRowNumber } from "utils/csv-utils";
import { enumField, numberField, stringField } from "utils/data-field/data-field-builder";
import { makeObjectDef } from "utils/data-field/object-field-def";
import { isNotNull } from "utils/ts-utils";
import { collectToRecord } from "utils/type-utils";

export interface ProductSalePriceRowData {
  productCode: string;
  currency: Currency;
  incDutyExVatPrice?: number;
  incDutyAndVatPrice?: number;
  exDutyAndVatPrice?: number;
}

const PRODUCT_SALE_PRICE_INFO_DEF = makeObjectDef<ProductSalePriceRowData>({
  productCode: stringField("Product code").required(),
  currency: enumField("Currency", valuesCurrency).required(),
  incDutyExVatPrice: numberField("Inc Duty Ex VAT Price", { precision: 2 }).optional(),
  incDutyAndVatPrice: numberField("Inc Duty and VAT Price", { precision: 2 }).optional(),
  exDutyAndVatPrice: numberField("Ex Duty and VAT Price", { precision: 2 }).optional(),
});

export class ProductSalePriceCsvParser {
  private parser: CsvRowParser<ProductSalePriceRowData>;

  constructor(headers: string[]) {
    this.parser = CsvRowParser.make(PRODUCT_SALE_PRICE_INFO_DEF, headers, row => {
      if (!row.incDutyExVatPrice && !row.incDutyAndVatPrice && !row.exDutyAndVatPrice) {
        return "Missing price information";
      }
    });
  }

  async process(
    rows: string[][],
    appService: AppService,
    organisationId: string,
    requiredCurrency: Currency | null,
  ): Promise<CsvUploadResult<ProductSalePriceRowData>> {
    const parsedResults = rows.map(row => {
      const productCode = this.parser.getRawData(row, "productCode");
      const currency = this.parser.getRawData(row, "currency");
      return {
        ...this.parser.validate(row),
        rowIdentifier: `${productCode} - ${currency}`,
      };
    });

    this.parser.getRawData(rows[0], "productCode");

    const dataEntries = parsedResults.map(e => e.value ?? null).filter(isNotNull);

    const productCodes: Set<string> = dataEntries.reduce((set, val) => set.add(val.productCode), new Set<string>());
    const productMapResp = await appService.getProductCodeToProductMap({
      organisationId,
      productCodes: [...productCodes.values()],
    });
    const existingProductCodes = new Set(
      productMapResp.filter(({ value }) => value.kind === "existingProduct").map(({ key }) => key),
    );

    const entryIndexesByKey = collectToRecord<ProductSalePriceRowData, number[]>(
      dataEntries,
      e => makeEntryKey(e),
      (_, idx) => [idx],
      (a, b) => a.concat(b),
    );

    parsedResults.forEach(({ value, errors }, idx) => {
      if (!value) {
        return;
      }
      if (!existingProductCodes.has(value.productCode)) {
        errors.push(`Invalid product code '${value.productCode}'`);
      }
      if (requiredCurrency && value.currency !== requiredCurrency) {
        errors.push(
          `Invalid currency for selected purchaser, provided ${value.currency}, expected '${requiredCurrency}'`,
        );
      }
      const entryKey = makeEntryKey(value);
      const entryIndexes = entryIndexesByKey[entryKey];
      if (entryIndexes.length > 1) {
        const otherIndexes = entryIndexes.filter(i => i != idx);
        const otherRowNumbers = otherIndexes.map(i => i + 1);
        errors.push(`Duplicate entries for ${entryKey} found on row ${otherRowNumbers.join(", ")}`);
      }
    });
    return convertToCsvUploadResult(parsedResults);
  }
}

const makeEntryKey = (data: ProductSalePriceRowData) => {
  return `${data.productCode} - ${data.currency}`;
};

function convertToCsvUploadResult(
  parsedResults: (CsvRowParserResult<ProductSalePriceRowData> & { rowIdentifier: string })[],
): CsvUploadResult<ProductSalePriceRowData> {
  const errors: WithCsvRowNumber<RowIdentifierAndErrors>[] = parsedResults
    .map(({ errors, rowIdentifier }, index) => {
      const rowNumber = index + 1;
      return errors.length > 0 ? { rowNumber, value: { errors, rowIdentifier } } : null;
    })
    .filter(isNotNull);
  if (errors.length > 0) {
    return { kind: "errors", value: errors };
  } else {
    const values: WithCsvRowNumber<ProductSalePriceRowData>[] = parsedResults
      .map(({ value }, index) => (value ? { rowNumber: index + 1, value } : null))
      .filter(isNotNull);
    return { kind: "successes", value: values };
  }
}
