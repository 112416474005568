import {
  HttpFetch,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from "utils/hx/service/http";
import { HttpServiceError } from "./http-service-error";
import { Json, JsonBinding, JsonParseException } from "adl-gen/runtime/json";

export class HttpServiceBase {
  constructor(
    /** Fetcher over HTTP */
    private readonly http: HttpFetch,
    /** Base URL of the API endpoints */
    private readonly baseUrl: string,
    /** The authentication token (if any) */
    private readonly authToken: string | undefined,
    /** Error handler to allow for cross cutting concerns, e.g. authorization errors */
    private readonly handleError: (error: HttpServiceError) => void,
    private readonly handleHeaders: (headers: HttpHeaders) => void,
  ) {}

  private async makeRequest(
    method: "get" | "post",
    path: string,
    jsonBody: { body: Json } | undefined,
    /** Publicly consumable action of the request for error alerting purposes */
    actionName: string,
  ): Promise<HttpResponse> {
    // Construct request
    const headers: { [key: string]: string } = {};
    headers["Content-Type"] = "application/json";
    if (this.authToken) {
      headers["X-Auth-Token"] = this.authToken;
    }
    const httpReq: HttpRequest = {
      url: this.baseUrl + path,
      headers,
      method,
      body: jsonBody === undefined ? undefined : JSON.stringify(jsonBody.body),
    };

    // Make request
    const resp = await this.http.fetch(httpReq);
    this.handleHeaders(resp.headers);

    // Check for errors
    if (!resp.ok) {
      const bodyText = await resp.text();
      let publicMessageFragment = "";
      try {
        const bodyJson = JSON.parse(bodyText);
        if (bodyJson.publicMessage) {
          publicMessageFragment = `: ${bodyJson.publicMessage}`;
        }
      } catch (e) {
        // Not JSON
      }

      const error = new HttpServiceError(
        `Encountered server error attempting to call ${actionName} ${publicMessageFragment}`,
        `${httpReq.method} request to ${httpReq.url} failed: ${resp.statusText} (${resp.status}): ${bodyText}`,
        resp.status,
        bodyText,
      );
      this.handleError(error);
      throw error;
    }

    return resp;
  }
  protected async makeRequestWithTextResponse(
    method: "get" | "post",
    path: string,
    jsonBody: { body: Json } | undefined,
    /** Publicly consumable action of the request for error alerting purposes */
    actionName: string,
  ): Promise<string> {
    const resp = await this.makeRequest(method, path, jsonBody, actionName);
    return resp.text();
  }

  protected async makeRequestWithJsonResponse<O>(
    method: "get" | "post",
    path: string,
    jsonBody: { body: Json } | undefined,
    respJB: JsonBinding<O>,
    /** Publicly consumable action of the request for error alerting purposes */
    actionName: string,
  ): Promise<O> {
    // Parse response
    const resp = await this.makeRequest(method, path, jsonBody, actionName);

    const bodyText = await resp.text();
    let bodyJson: Json;
    try {
      bodyJson = JSON.parse(bodyText);
    } catch (e: unknown) {
      const url = this.baseUrl + path;
      const error = new HttpServiceError(
        `Encountered error attempting to call ${actionName} response was invalid json`,
        `${method} request to ${url} failed: ${bodyText}`,
        resp.status,
        bodyText,
      );
      this.handleError(error);
      throw error;
    }

    // Parse response
    try {
      return respJB.fromJson(bodyJson);
    } catch (e: unknown) {
      const err = e as JsonParseException;
      const error = new HttpServiceError(
        "Encountered parse error attempting to call " + actionName,
        err.getMessage(),
        resp.status,
        bodyText,
      );
      this.handleError(error);
      throw error;
    }
  }
}
