/* @generated from adl module ferovinum.app.invoicing */

import * as ADL from './../../runtime/adl';

/**
 * Values from XeroAuth and DB used in the Xero SDK
 */
export interface XeroAccess {
  accessToken: string;
  tenantId: string;
  tenantName: string;
  enableLiveInvoices: boolean;
  enableInvoiceEmail: boolean;
}

export function makeXeroAccess(
  input: {
    accessToken: string,
    tenantId: string,
    tenantName: string,
    enableLiveInvoices: boolean,
    enableInvoiceEmail: boolean,
  }
): XeroAccess {
  return {
    accessToken: input.accessToken,
    tenantId: input.tenantId,
    tenantName: input.tenantName,
    enableLiveInvoices: input.enableLiveInvoices,
    enableInvoiceEmail: input.enableInvoiceEmail,
  };
}

const XeroAccess_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.invoicing","decl":{"annotations":[{"value":"Values from XeroAuth and DB used in the Xero SDK\n","key":{"moduleName":"sys.annotations","name":"Doc"}}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"accessToken","default":{"kind":"nothing"},"name":"accessToken","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[],"serializedName":"tenantId","default":{"kind":"nothing"},"name":"tenantId","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[],"serializedName":"tenantName","default":{"kind":"nothing"},"name":"tenantName","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[],"serializedName":"enableLiveInvoices","default":{"kind":"nothing"},"name":"enableLiveInvoices","typeExpr":{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}},{"annotations":[],"serializedName":"enableInvoiceEmail","default":{"kind":"nothing"},"name":"enableInvoiceEmail","typeExpr":{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}}]}},"name":"XeroAccess","version":{"kind":"nothing"}}};

export const snXeroAccess: ADL.ScopedName = {moduleName:"ferovinum.app.invoicing", name:"XeroAccess"};

export function texprXeroAccess(): ADL.ATypeExpr<XeroAccess> {
  return {value : {typeRef : {kind: "reference", value : snXeroAccess}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "ferovinum.app.invoicing.XeroAccess" : XeroAccess_AST
};
