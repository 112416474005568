import { DatePicker, TimePicker } from "@mui/lab";
import { Autocomplete, Grid, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { LocalDate, LocalTime } from "adl-gen/common";
import { PartialDate } from "adl-gen/ferovinum/app/db";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { DataFieldDef } from "utils/data-field/data-field-def";
import { ObjectFields } from "utils/data-field/object-field-def";
import {
  parseLocalDate,
  parseLocalTimeToDate,
  parsePartialDate,
  safeFormatDateToLocalDate,
  safeFormatDateToLocalTime,
} from "utils/date-utils";
import { isString } from "utils/ts-utils";
import { mapOptional } from "utils/type-utils";
import { KeyByType, UnionType } from "utils/utility-types";
import { FormField, FormikForm } from "../../types/formik-types";
import { TextArea } from "../../widgets/inputs/text-area/text-area";
import { FormOptionPicker } from "./option-picker";
import { UnionInputWidget, UnionUiMapping } from "./union-input-widget";

type InputUiParams<V> = {
  label?: string; // overrides field label
  notFullWidth?: boolean;
  grid?: React.ComponentProps<typeof Grid>;
  onChange?: (v: V | undefined) => void;
};

function MaybeGrid(props: PropsWithChildren<Pick<InputUiParams<unknown>, "grid">>) {
  const { grid, children } = props;
  if (grid) {
    return (
      <Grid item xs={grid.xs}>
        {children}
      </Grid>
    );
  }
  return <>{children}</>;
}

// Builds UI components such as TextField and DatePicker for fields of an object definition
// Dramatically streamline the process of building forms
export class InputUiBuilder<T extends object> {
  constructor(
    private readonly objectDef: Readonly<ObjectFields<T>>,
    private readonly parent: FormField<T> | FormikForm<T>,
  ) {}

  private getValueField<V>(fieldKey: KeyByType<T, V>): FormField<V> {
    if (this.parent instanceof FormField) {
      return this.parent.getSubField(fieldKey);
    } else {
      return new FormField<V>(this.parent, fieldKey);
    }
  }

  private getFieldDef<V>(fieldKey: KeyByType<T, V>): DataFieldDef<V> {
    return this.objectDef[fieldKey] as DataFieldDef<V>;
  }

  createTextField(fieldKey: KeyByType<T, string>, params?: InputUiParams<string>) {
    const fieldDef = this.getFieldDef(fieldKey);
    const valueField = this.getValueField(fieldKey);

    return (
      <MaybeGrid grid={params?.grid}>
        <TextField
          key={valueField.path}
          label={params?.label ?? fieldDef.label}
          name={valueField.path}
          required={fieldDef.isRequired}
          value={valueField.get() ?? ""}
          error={valueField.hasError(true)}
          // TODO(Berto): Modify all TextField in a generic way so the helper text has always reserved space in the ui + ellipsis so we avoid
          // inputs jumping up and down when they are in an errored state
          helperText={valueField.getError(true)}
          {...makeCommonInputProps(valueField, params)}
        />
      </MaybeGrid>
    );
  }

  createTextAreaField(
    fieldKey: KeyByType<T, string>,
    params?: InputUiParams<string> & { rows?: number; maxLength?: number },
  ) {
    const fieldDef = this.getFieldDef(fieldKey);
    const valueField = this.getValueField(fieldKey);
    const { maxLength = 120, rows = 5 } = params ?? {};
    return (
      <MaybeGrid grid={params?.grid}>
        <TextArea
          key={valueField.path}
          label={params?.label ?? fieldDef.label}
          name={valueField.path}
          value={valueField.get() ?? ""}
          error={valueField.hasError(true)}
          maxCharacters={maxLength}
          rows={rows}
          inputProps={{ maxLength: maxLength }}
          {...makeCommonInputProps(valueField, params)}
        />
      </MaybeGrid>
    );
  }

  createNumberField(
    fieldKey: KeyByType<T, number>,
    params?: InputUiParams<number> & { step?: number; suffix?: string },
  ) {
    const fieldDef = this.getFieldDef(fieldKey);
    const valueField = this.getValueField(fieldKey);
    return InputUiBuilder.createNumberField(valueField, fieldDef, params);
  }

  public static createNumberField(
    valueField: FormField<number>,
    fieldDef: DataFieldDef<number>,
    params?: InputUiParams<number> & { step?: number; suffix?: string },
  ) {
    return (
      <MaybeGrid grid={params?.grid}>
        <TextField
          key={valueField.path}
          label={params?.label ?? fieldDef.label}
          name={valueField.path}
          type="number"
          inputProps={{
            step: params?.step ?? 0.01,
          }}
          value={valueField.get() ?? ""}
          required={fieldDef.isRequired}
          error={valueField.hasError(true)}
          helperText={valueField.getError(true)}
          InputProps={mapOptional(params?.suffix, suffix => ({
            endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
          }))}
          {...makeCommonInputProps(valueField, params)}
        />
      </MaybeGrid>
    );
  }

  createEnumField<E extends string>(
    fieldKey: KeyByType<T, E>,
    params?: InputUiParams<E> &
      Pick<React.ComponentProps<typeof Autocomplete<E>>, "renderOption" | "getOptionDisabled">,
  ) {
    const fieldDef = this.getFieldDef(fieldKey);
    const valueField = this.getValueField<E>(fieldKey);
    if (fieldDef.metaData.kind !== "enum") {
      throw new Error(`Field ${valueField.path} is not an enum`);
    }
    return (
      <MaybeGrid grid={params?.grid}>
        <FormOptionPicker
          field={valueField}
          fullWidth={!params?.notFullWidth}
          fieldDef={fieldDef}
          labelOverride={params?.label}
          options={fieldDef.metaData.values as E[]}
          getOptionDisabled={params?.getOptionDisabled}
          onValueChange={params?.onChange}
          renderOption={params?.renderOption}
        />
      </MaybeGrid>
    );
  }

  createLocalDateField(
    fieldKey: KeyByType<T, LocalDate>,
    params?: InputUiParams<LocalDate> & { minDate?: Date; maxDate?: Date },
  ) {
    const fieldDef = this.getFieldDef(fieldKey);
    const range = fieldDef.metaData.kind === "date" ? fieldDef.metaData.range : undefined;
    const minDate = params?.minDate ?? (range?.min ? new Date(range.min) : undefined);
    const maxDate = params?.maxDate ?? (range?.max ? new Date(range.max) : undefined);
    const valueField = this.getValueField(fieldKey);
    const value = valueField.get();
    // dateValue needs to be null and not undefined for the DatePicker to be blank
    const dateValue = (isString(value) && parseLocalDate(value)) || null;
    return (
      <MaybeGrid grid={params?.grid}>
        <DatePicker
          inputFormat="yyyy-MM-dd"
          mask="____-__-__"
          minDate={minDate}
          maxDate={maxDate}
          value={dateValue}
          renderInput={renderParams => (
            <PickerInputField<LocalDate>
              textFieldProps={{ ...renderParams, ...makeCommonInputProps(valueField, params) }}
              fieldDef={fieldDef}
              field={valueField}
            />
          )}
          onChange={(date: Date | null) => {
            valueField.setValidateAndTouch(safeFormatDateToLocalDate(date));
          }}
        />
      </MaybeGrid>
    );
  }

  createPartialDateField(fieldKey: KeyByType<T, PartialDate>, params?: InputUiParams<PartialDate>) {
    const fieldDef = this.getFieldDef(fieldKey);
    const valueField = this.getValueField(fieldKey);
    return (
      <MaybeGrid grid={params?.grid}>
        <PartialDateField label={params?.label ?? fieldDef.label} field={valueField} />
      </MaybeGrid>
    );
  }

  createLocalTimeField(fieldKey: KeyByType<T, LocalTime>, params?: InputUiParams<LocalTime>) {
    const valueField = this.getValueField(fieldKey);
    const formValue = valueField.get();
    return (
      <MaybeGrid grid={params?.grid}>
        <TimePicker
          inputFormat="HH:mm"
          value={isString(formValue) ? parseLocalTimeToDate(formValue) : null}
          mask="__:__"
          ampm={false}
          renderInput={renderParams => (
            <PickerInputField<LocalTime>
              textFieldProps={{ ...renderParams, ...makeCommonInputProps(valueField, params) }}
              fieldDef={this.getFieldDef(fieldKey)}
              field={valueField}
            />
          )}
          onChange={(date: Date | null) => {
            valueField.setValidateAndTouch(safeFormatDateToLocalTime(date));
          }}
        />
      </MaybeGrid>
    );
  }

  createUnionField<U extends UnionType>(fieldKey: KeyByType<T, U>, uiMapping: UnionUiMapping<U>) {
    const valueField = this.getValueField(fieldKey);
    const fieldDef = this.getFieldDef(fieldKey);
    return <UnionInputWidget field={valueField} fieldDef={fieldDef} uiMapping={uiMapping} />;
  }
}

function makeCommonInputProps<V extends string | number>(
  valueField: FormField<V>,
  params?: InputUiParams<V>,
): Pick<React.ComponentProps<typeof TextField>, "onBlur" | "onChange" | "fullWidth"> {
  return {
    onBlur: e => valueField.handleBlur(e),
    onChange: e => {
      valueField.clearError();
      // reset error of the field when user types in for better UX
      // valueField.handleChange(e);
      valueField.setValidateAndTouch(e.target.value as V);
      params?.onChange?.(e.target.value as V);
    },
    fullWidth: !params?.notFullWidth,
  };
}

function PickerInputField<T>(props: {
  textFieldProps: TextFieldProps;
  fieldDef: DataFieldDef<T>;
  field: FormField<T>;
}) {
  const { textFieldProps, fieldDef, field } = props;
  return (
    <TextField
      key={field.path}
      {...textFieldProps}
      label={textFieldProps?.label ?? fieldDef.label}
      name={field.path}
      required={fieldDef.isRequired}
      error={textFieldProps.error || field.hasError(true)}
      InputProps={textFieldProps?.InputProps}
      helperText={field.getError(true)}
      onChange={e => {
        // This setTimeout is a hacky way to ensure the error state of the parent picker is updated
        // after the value is set. Without it, the error state is updated immediately and the picker
        // is not updated until the next render. This causes the error state to be out of sync with the picker.
        setTimeout(() => {
          field.setValidateAndTouch(fieldDef.safeCast(e.target.value));
        });
      }}
    />
  );
}

function PartialDateField(props: {
  label: string;
  field: FormField<PartialDate>;
  required?: boolean;
  params?: InputUiParams<PartialDate>;
}) {
  const { label, field, required, params } = props;
  const [text, setText] = useState<string | undefined>(undefined);
  const [error, setError] = useState("");

  useEffect(() => {
    if (text === undefined) {
      const partialDate = field.get();
      setText(partialDate?.value ?? "");
    }
  }, [field, text]);

  return (
    <TextField
      key={field.path}
      label={label}
      name={field.path}
      value={text ?? ""}
      error={Boolean(error)}
      helperText={error}
      required={required}
      onChange={e => {
        const text = e.target.value;
        setText(text);
        const partialDate = parsePartialDate(text) ?? null;
        if (text && !partialDate) {
          setError("Invalid partial date");
        } else {
          setError("");
        }
        field.setValidateAndTouch(partialDate || undefined);
        params?.onChange?.(partialDate || undefined);
      }}
      onBlur={e => {
        const partialDate = text && parsePartialDate(text);
        if (partialDate) {
          setText(partialDate.value);
        }
        field.handleBlur(e);
      }}
      fullWidth={!params?.notFullWidth}
    />
  );
}
