import { Card, Checkbox, Table, TableBody, TableContainer, Theme, Typography, useMediaQuery } from "@mui/material";
import { BigDecimal, LocalDate } from "adl-gen/common";
import { WithDbId } from "adl-gen/common/db";
import { Currency, DeliveryFee, DutyAndVat, Organisation } from "adl-gen/ferovinum/app/db";
import { PurchaseRequestTimeline } from "adl-gen/ferovinum/app/nompurchaser";
import { formatCurrencyValue } from "components/widgets/currency-renderer/currency-renderer";
import React, { useState } from "react";
import { formatLocalDate } from "utils/date-utils";
import { useSelectedOrg } from "../../../layouts/portal-page-layout/portal-page";
import {
  DividerRow,
  ExpandButton,
  FinancialValueRow,
  FinancialValueRowWithCustomValue,
  PaymentStatusChip,
} from "components/library/widgets/financial-value-table-widgets";

interface CommonProps {
  dealDiscountAmount: BigDecimal | null;
  netSubtotal: BigDecimal;
  dutyAndVat: DutyAndVat | null;
  freeStockDutyAndVat: DutyAndVat | null;
  deliveryFee: DeliveryFee | null;
  nomPurchaserServiceFee: BigDecimal;
  netPayable: BigDecimal;
  additionalMonthlyFees: BigDecimal;
  netReceivable: BigDecimal;
  purchaserCoversDeliveryCost: boolean;
  purchaserCurrency: Currency;
  settlementCurrency: Currency;
  purchaserName: string;
  isEstimate?: boolean; // Has the purchase request been delivered? (i.e. are these values estimate or actual)
  cashAmountToAdvanceUponDelivery: BigDecimal | null;
  timeline: PurchaseRequestTimeline;
  fxRate: BigDecimal | null;
  advanceUponDeliveryPaymentDate: LocalDate | null;
  orgNetReceivablePaymentDate: LocalDate | null;
  excludeDutyInAdvancedAmount: boolean;
}

interface NonPreviewProps {
  isPreview: false;
}

interface PreviewProps {
  isPreview: true;
  advanceCashUponDelivery: boolean;
  setAdvanceCashUponDelivery: (advanceUponDelivery: boolean) => void;
}

export type OrganisationPurchaseRequestValuationCardProps = CommonProps & (NonPreviewProps | PreviewProps);
export const OrganisationPurchaseRequestValuationCard = (props: OrganisationPurchaseRequestValuationCardProps) => {
  const {
    dutyAndVat,
    freeStockDutyAndVat,
    deliveryFee,
    nomPurchaserServiceFee,
    dealDiscountAmount,
    netPayable,
    additionalMonthlyFees,
    netReceivable,
    netSubtotal,
    purchaserCoversDeliveryCost,
    purchaserCurrency,
    settlementCurrency,
    purchaserName,
    isPreview,
    cashAmountToAdvanceUponDelivery,
    isEstimate = true,
    timeline,
    fxRate,
    advanceUponDeliveryPaymentDate,
    orgNetReceivablePaymentDate,
    excludeDutyInAdvancedAmount,
  } = props;
  const org: WithDbId<Organisation> | undefined = useSelectedOrg();
  const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down("md"));

  const { advanceCashUponDelivery, setAdvanceCashUponDelivery } = props as PreviewProps;
  const purchaserPaidDateStr = formatLocalDate(timeline.value.purchaserPaidDate);
  const orgName = org?.value.businessName ?? "Your Company";

  // TODO Zhi
  // Below calculations translates the old way of presenting trade sale economics to the new way.
  // This is a band-aid solution until the backend can be updated to provide these values directly.
  const netSubtotalNum = Number(netSubtotal);
  const invoiceInPurchaserCurrencyNum =
    netSubtotalNum +
    (dutyAndVat ? Number(dutyAndVat.duty) + Number(dutyAndVat.vat) : 0) +
    (purchaserCoversDeliveryCost && deliveryFee ? Number(deliveryFee.deliveryFee) + Number(deliveryFee.vat) : 0);

  const totalDutyNum = excludeDutyInAdvancedAmount
    ? 0
    : Number(dutyAndVat?.duty ?? 0) + Number(freeStockDutyAndVat?.duty ?? 0);
  const totalVatNum = Number(dutyAndVat?.vat ?? 0) + Number(freeStockDutyAndVat?.vat ?? 0);
  const deliveryCostNum = Number(deliveryFee?.deliveryFee ?? 0) + Number(deliveryFee?.vat ?? 0);

  const buyBackNum = Number(netPayable);
  const serviceFeeNum = Number(nomPurchaserServiceFee);
  const outstandingTotalNum = buyBackNum + totalDutyNum + totalVatNum + deliveryCostNum + serviceFeeNum;

  const availableFundingNum = cashAmountToAdvanceUponDelivery ? Number(cashAmountToAdvanceUponDelivery) : null;
  const additionalFundingNum = advanceCashUponDelivery ? availableFundingNum : null;

  const fundingLimit = outstandingTotalNum + (availableFundingNum ?? 0);

  const additionalFeesNum = Number(additionalMonthlyFees);
  const finalReceivableNum = Number(netReceivable);

  return (
    <Card sx={{ backgroundColor: "common.grey1", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      <TableContainer sx={{ overflowX: "hidden" }}>
        <Table stickyHeader={false} sx={{ backgroundColor: "common.grey1" }}>
          <colgroup>
            <col style={{ width: isSmallScreen ? "50%" : "85%" }} />
            <col style={{ width: isSmallScreen ? "50%" : "15%" }} />
          </colgroup>
          <TableBody>
            {dealDiscountAmount && (
              <FinancialValueRow title={"Deal Discount"} value={dealDiscountAmount} currency={purchaserCurrency} />
            )}
            <FinancialValueRow title={"Net Subtotal"} value={netSubtotal} currency={purchaserCurrency} />

            <DividerRow text={"Receivable breakdown"} />

            <InvoiceTotalSection
              purchaserName={purchaserName}
              invoiceTotaInPurchaserCurrencylNum={invoiceInPurchaserCurrencyNum}
              purchaserCurrency={purchaserCurrency}
              settlementCurrency={settlementCurrency}
              fxRate={fxRate}
              isEstimate={isEstimate}
            />

            <OutstandingBalanceSection
              orgName={orgName}
              outstandingTotalNum={outstandingTotalNum}
              buyBackNum={buyBackNum}
              totalDutyNum={totalDutyNum}
              totalVatNum={totalVatNum}
              deliveryCostNum={deliveryCostNum}
              serviceFeeNum={serviceFeeNum}
              settlementCurrency={settlementCurrency}
            />

            {cashAmountToAdvanceUponDelivery && (
              <AdditionalFundingSection
                orgName={orgName}
                advanceCashUponDelivery={advanceCashUponDelivery}
                setAdvanceCashUponDelivery={setAdvanceCashUponDelivery}
                cashAmountToAdvanceUponDelivery={cashAmountToAdvanceUponDelivery}
                additionalFundingNum={additionalFundingNum}
                fundingLimit={fundingLimit}
                outstandingTotalNum={outstandingTotalNum}
                settlementCurrency={settlementCurrency}
                advanceUponDeliveryPaymentDate={advanceUponDeliveryPaymentDate}
                isPreview={isPreview}
              />
            )}

            <FinancialValueRow
              title="Additional Monthly Fees"
              caption={isEstimate ? `estimated based on invoice paid on ${purchaserPaidDateStr}` : undefined}
              tooltip={`Monthly service fees accrued from the date the order is created until the date of
                payment, based on the total funding advanced for this order (Outstanding Balance + Additional
                Funding Advanced)`}
              value={additionalFeesNum}
              currency={settlementCurrency}
            />
            <FinancialValueRow
              title="Final Receivable"
              caption={isEstimate ? `estimated based on invoice paid on ${purchaserPaidDateStr}` : undefined}
              tooltip={`The net receivable amount on date of invoice payment, post deduction of the
                        Outstanding Balance and any additional fees accrued.`}
              afterTitle={
                isPreview ? null : (
                  <PaymentStatusChip
                    paymentStatus={
                      orgNetReceivablePaymentDate
                        ? { kind: "paidOn", value: orgNetReceivablePaymentDate }
                        : { kind: "pending" }
                    }
                  />
                )
              }
              value={finalReceivableNum}
              currency={settlementCurrency}
              inError={finalReceivableNum <= 0}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

const InvoiceTotalSection = ({
  purchaserName,
  invoiceTotaInPurchaserCurrencylNum,
  purchaserCurrency,
  settlementCurrency,
  fxRate,
  isEstimate,
}: {
  purchaserName: string;
  invoiceTotaInPurchaserCurrencylNum: number;
  purchaserCurrency: Currency;
  settlementCurrency: Currency;
  fxRate: BigDecimal | null;
  isEstimate: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const fxRateNum = fxRate != null ? Number(fxRate) : null;
  const invoiceTotalInSettlementCurrencyNum =
    fxRateNum != null ? invoiceTotaInPurchaserCurrencylNum * fxRateNum : invoiceTotaInPurchaserCurrencylNum;
  const missingFxRate = purchaserCurrency !== settlementCurrency && !fxRateNum;

  return (
    <>
      <FinancialValueRow
        title={"Invoice Total" + (fxRate != null ? ` in ${settlementCurrency}` : "")}
        caption={`payable by ${purchaserName}`}
        tooltip="Total invoiced amount the purchaser is expected to pay."
        value={invoiceTotalInSettlementCurrencyNum}
        emptyValue={missingFxRate ? "NO FX RATE" : undefined}
        inError={missingFxRate}
        currency={settlementCurrency}
        afterValue={fxRate != null ? <ExpandButton initState={open} setState={setOpen} /> : null}
      />
      {/* only show dropdown for cross currency trades */}
      {open && fxRate != null && (
        <>
          <FinancialValueRow
            weak
            title={`Invoice Total in ${purchaserCurrency}`}
            value={invoiceTotaInPurchaserCurrencylNum}
            currency={purchaserCurrency}
          />
          <FinancialValueRowWithCustomValue
            weak
            title={`${isEstimate ? "Current " : ""}Exchange Rate (${purchaserCurrency} to ${settlementCurrency})`}
            valueNode={<Typography>{`1${purchaserCurrency} = ${fxRate}${settlementCurrency}`}</Typography>}
          />
        </>
      )}
    </>
  );
};

const OutstandingBalanceSection = ({
  orgName,
  outstandingTotalNum,
  buyBackNum,
  totalDutyNum,
  totalVatNum,
  deliveryCostNum,
  serviceFeeNum,
  settlementCurrency,
}: {
  orgName: string;
  outstandingTotalNum: number;
  buyBackNum: number;
  totalDutyNum: number;
  totalVatNum: number;
  deliveryCostNum: number;
  serviceFeeNum: number;
  settlementCurrency: Currency;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <FinancialValueRow
        title="Outstanding Balance"
        caption={`payable by ${orgName}`}
        tooltip={`The current balance Ferovinum has funded against the goods to be sold, plus any additional
          fees and charges Ferovinum will advance on behalf of ${orgName} by facilitating the order. Note -
          any fees incurred by Ferovinum for this order that are not covered in this amount will be recharged
          to ${orgName} as they are incurred. `}
        value={outstandingTotalNum}
        currency={settlementCurrency}
        afterValue={<ExpandButton initState={open} setState={setOpen} />}
      />
      {open && (
        <>
          <FinancialValueRow
            weak
            ifNull="hide"
            title={"Repurchase Cost"}
            tooltip={`The current balance Ferovinum has funded against the goods to be sold, including any
              accrued and capitalised fees.`}
            value={zeroToNull(buyBackNum)}
            currency={settlementCurrency}
          />
          <FinancialValueRow
            weak
            ifNull="hide"
            title={"Total Duty"}
            value={zeroToNull(totalDutyNum)}
            currency={settlementCurrency}
          />
          <FinancialValueRow
            weak
            ifNull="hide"
            title={"Total VAT"}
            value={zeroToNull(totalVatNum)}
            currency={settlementCurrency}
          />
          <FinancialValueRow
            weak
            ifNull="hide"
            title={"Delivery Cost"}
            value={zeroToNull(deliveryCostNum)}
            currency={settlementCurrency}
          />
          <FinancialValueRow
            weak
            ifNull="hide"
            title="Service Fee"
            tooltip={`Calculated by multiplying agreed Trade Sales fee by total invoice value. This fee
              covers the platform usage and standard operational services related to the order.`}
            value={zeroToNull(serviceFeeNum)}
            currency={settlementCurrency}
          />
        </>
      )}
    </>
  );
};

function zeroToNull(n: number) {
  return Math.abs(n) < 1e-8 ? null : n;
}

const AdditionalFundingSection = ({
  orgName,
  advanceCashUponDelivery,
  setAdvanceCashUponDelivery,
  cashAmountToAdvanceUponDelivery,
  additionalFundingNum,
  fundingLimit,
  outstandingTotalNum,
  settlementCurrency,
  advanceUponDeliveryPaymentDate,
  isPreview,
}: {
  orgName: string;
  advanceCashUponDelivery: boolean;
  setAdvanceCashUponDelivery: (v: boolean) => void;
  cashAmountToAdvanceUponDelivery: BigDecimal;
  additionalFundingNum: number | null;
  fundingLimit: number;
  outstandingTotalNum: number;
  settlementCurrency: Currency;
  advanceUponDeliveryPaymentDate: LocalDate | null;
  isPreview: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const additionalFundingTooltip = `Additional funding available to ${orgName} on the date of invoicing.
    This amount is the total funding Ferovinum can advance against the invoice, less the current
    outstanding balance.`;

  if (isPreview) {
    return (
      <>
        <FinancialValueRow
          title="Check box for Additional Funding"
          caption={`receive ${formatCurrencyValue({
            value: cashAmountToAdvanceUponDelivery,
            currency: settlementCurrency,
          })} upon Delivery`}
          tooltip={additionalFundingTooltip + " Receiving this payment is optional."}
          beforeTitle={
            <Checkbox
              checked={advanceCashUponDelivery}
              onChange={event => {
                setAdvanceCashUponDelivery(event.target.checked);
              }}
            />
          }
          value={additionalFundingNum}
          currency={settlementCurrency}
          emptyValue="Opt Out"
          afterValue={advanceCashUponDelivery ? <ExpandButton initState={open} setState={setOpen} /> : null}
        />
        {advanceCashUponDelivery && open && (
          <>
            <FinancialValueRow
              weak
              title="Ferovinum Funding Limit"
              tooltip={`The total amount Ferovinum can advance for this order.
                This amount can be up to maximum of 90% of the ex Duty, ex VAT invoice amount, plus 100% of
                the Duty and VAT. The limit provided depends on the credit risk assessment of the trade.`}
              value={fundingLimit}
              currency={settlementCurrency}
            />
            <FinancialValueRow
              weak
              title={"Outstanding Balance"}
              value={outstandingTotalNum}
              currency={settlementCurrency}
            />
          </>
        )}
      </>
    );
  } else {
    return (
      <FinancialValueRow
        ifNull="hide"
        title="Additional Funding"
        caption={`received upon Delivery`}
        tooltip={additionalFundingTooltip}
        afterTitle={
          <PaymentStatusChip
            paymentStatus={
              advanceUponDeliveryPaymentDate
                ? { kind: "paidOn", value: advanceUponDeliveryPaymentDate }
                : { kind: "pending" }
            }
          />
        }
        value={cashAmountToAdvanceUponDelivery}
        currency={settlementCurrency}
      />
    );
  }
};
