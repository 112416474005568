import { OrganisationExpiringStockDecision } from "adl-gen/ferovinum/app/api";
import { useAlert } from "components/context/global-alert/use-alert-context";
import React, { useCallback } from "react";
import { assertNever, assertNotUndefined } from "utils/hx/util/types";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import {
  ExpiringStockViewsWithFilter,
  OrganisationExpiringStockPageView,
} from "./organisation-expiring-stock-page-view";

export const OrganisationExpiringStockPage = () => {
  const service = useAppService();
  const organisationId = assertNotUndefined(useSelectedOrgId());
  const [showAlert] = useAlert();

  const loadExpiringStockViewsWithFilter = useCallback(
    async (filter: ExpiringStockViewsWithFilter["filter"]): Promise<ExpiringStockViewsWithFilter> => {
      let views;
      switch (filter) {
        case "repurchase":
          const resp = await service.getOrganisationExpiringStockViewsToBeRepurchased({ organisationId });
          return {
            filter,
            views: resp.expiringStock,
            netPayableSubtotal: resp.netPayableSubtotal,
          };
        case "decisionRequired":
          views = await service.getDecisionRequiredOrganisationExpiringStockViews({ organisationId });
          return { filter, views };
        case "dealRenewalsRequested":
          views = await service.getOrganisationDealRenewalsRequestedStock({ organisationId });
          return { filter, views };
        default:
          assertNever(filter);
      }
    },
    [organisationId, service],
  );

  const onRequestToRollExpiringStock = useCallback(
    async (req: OrganisationExpiringStockDecision[]) => {
      const resp = await service.requestToRollExpiringStock({ organisationId, expiringStockDecisions: req });
      switch (resp) {
        case "success":
          void showAlert({
            title: "Success",
            body: "Your request(s) have successfully been submitted",
            severity: "success",
          });
          break;
        case "insufficientQuantity":
          void showAlert({
            title: "Insufficient Quantity For Renewal Request",
            body: "Some of the products you've selected have insufficient quantity to request renewal. Please try again.",
            severity: "error",
          });
          break;
        case "rejectedStock":
          void showAlert({
            title: "Rejected Stock",
            body: "Some of the products you've selected have been rejected by Ferovinum Ltd. Please try again.",
            severity: "error",
          });
          break;
        case "unavailableDealLegs":
          void showAlert({
            title: "Products Unavailable",
            body: "Some of the products you've selected have been depleted. Please try again.",
            severity: "error",
          });
          break;
        default:
          assertNever(resp);
      }
    },
    [organisationId, service, showAlert],
  );

  return (
    <OrganisationExpiringStockPageView
      loadExpiringStockViewsWithFilter={loadExpiringStockViewsWithFilter}
      onRequestToRollExpiringStock={onRequestToRollExpiringStock}
    />
  );
};
