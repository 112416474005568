import { startCase } from "lodash";
import { ISchema, Schema, StringSchema, number, object } from "yup";

export type YupFieldSchema<T> = ISchema<T>;

type MessageParams = { path: string };

export const MISSING_HANDLER = (messageParams: MessageParams) => `${startCase(messageParams.path)} is missing.`;
export const DISCOUNT_RANGE_ERROR = `Invalid Discount`;
export const NUMBER_TYPE_HANDLER = (messageParams: MessageParams) =>
  `${startCase(messageParams.path)} needs to be a number.`;
export function INVALID_VALUE_HANDLER(validValues: string | string[]) {
  return (messageParams: MessageParams) => {
    const fieldName = startCase(messageParams.path);
    const validValuesString =
      typeof validValues === "string" ? validValues : validValues.map(startCase).sort().join("; ");
    return `${fieldName} can only be: ${validValuesString}.`;
  };
}
export const DUPLICATE_PRODUCT_MESSAGE = "Product Code already exists";

export function getYupSchemaForProductLineItemQuantityAndPrice(isNewStock?: boolean) {
  // Base schemas without the required validation
  let purchasePriceSchema = number().label("Price").typeError(NUMBER_TYPE_HANDLER).positive();
  let paidUnitsSchema = number().label("Quantity").typeError(NUMBER_TYPE_HANDLER).positive();
  const freeUnitsSchema = number().label("Free Stock").typeError(NUMBER_TYPE_HANDLER).min(0);
  const individualDiscountSchema = number()
    .label("Individual Discount")
    .typeError(NUMBER_TYPE_HANDLER)
    .min(0, DISCOUNT_RANGE_ERROR)
    .max(100, DISCOUNT_RANGE_ERROR);

  if (isNewStock) {
    // Apply conditional required validation only when isNewStock is true
    purchasePriceSchema = purchasePriceSchema.when("numberOfFreeUnits", {
      is: (numberOfFreeUnits: number) => numberOfFreeUnits > 0, // When there are free units
      then: schema => schema.notRequired(), // Price is not required
      otherwise: schema => schema.required(MISSING_HANDLER), // Price is required when no free units
    });

    paidUnitsSchema = paidUnitsSchema.when("numberOfFreeUnits", {
      is: (numberOfFreeUnits: number) => numberOfFreeUnits > 0, // When there are free units
      then: schema => schema.notRequired(), // Quantity is not required
      otherwise: schema => schema.required(MISSING_HANDLER), // Quantity is required when no free units
    });
  } else {
    // For existing stock, always require purchasePrice and numberOfUnits
    purchasePriceSchema = purchasePriceSchema.required(MISSING_HANDLER);
    paidUnitsSchema = paidUnitsSchema.required(MISSING_HANDLER);
  }

  // The overall schema
  const schema = object().shape({
    purchasePrice: purchasePriceSchema,
    numberOfUnits: paidUnitsSchema,
    numberOfFreeUnits: freeUnitsSchema,
    individualDiscount: individualDiscountSchema,
  });

  return schema;
}

// Create a yup schema that validates a product code by checking its validity, length and
// checking it against exsiting codes
export function updateProductCodeSchema(
  schema: Schema<string>,
  localProductCodes: Set<string>,
  validateProductCode?: (code: string) => Promise<boolean>,
) {
  return (schema as StringSchema<string>)
    .test(
      "Check if product code is duplicated in the input data",
      "Product Code already exists in input data",
      code => !localProductCodes.has(code),
    )
    .max(30, params => `${params.label} cannot exceed 30 characters.`)
    .test(
      "Check if product code exists",
      "Product Code already exists.",
      validateProductCode
        ? async productCode => {
            if (productCode) {
              const codeIsUnique = await validateProductCode(productCode);
              return !codeIsUnique;
            } else {
              return true;
            }
          }
        : () => true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as Schema<any>;
}
