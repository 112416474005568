/* @generated from adl module ferovinum.app.common */

import * as ADL from './../../runtime/adl';
import * as common_db from './../../common/db';
import * as ferovinum_app_db from './db';

export interface ProductIdQuantity {
  productId: common_db.DbKey<ferovinum_app_db.Product>;
  quantity: ferovinum_app_db.NumberOfUnits;
}

export function makeProductIdQuantity(
  input: {
    productId: common_db.DbKey<ferovinum_app_db.Product>,
    quantity: ferovinum_app_db.NumberOfUnits,
  }
): ProductIdQuantity {
  return {
    productId: input.productId,
    quantity: input.quantity,
  };
}

const ProductIdQuantity_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.common","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"productId","default":{"kind":"nothing"},"name":"productId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"ferovinum.app.db","name":"Product"}},"parameters":[]}]}},{"annotations":[],"serializedName":"quantity","default":{"kind":"nothing"},"name":"quantity","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"ferovinum.app.db","name":"NumberOfUnits"}},"parameters":[]}}]}},"name":"ProductIdQuantity","version":{"kind":"nothing"}}};

export const snProductIdQuantity: ADL.ScopedName = {moduleName:"ferovinum.app.common", name:"ProductIdQuantity"};

export function texprProductIdQuantity(): ADL.ATypeExpr<ProductIdQuantity> {
  return {value : {typeRef : {kind: "reference", value : snProductIdQuantity}, parameters : []}};
}

export interface ProductQuantity {
  product: common_db.WithDbId<ferovinum_app_db.Product>;
  quantity: ferovinum_app_db.NumberOfUnits;
}

export function makeProductQuantity(
  input: {
    product: common_db.WithDbId<ferovinum_app_db.Product>,
    quantity: ferovinum_app_db.NumberOfUnits,
  }
): ProductQuantity {
  return {
    product: input.product,
    quantity: input.quantity,
  };
}

const ProductQuantity_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.common","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"product","default":{"kind":"nothing"},"name":"product","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"WithDbId"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"ferovinum.app.db","name":"Product"}},"parameters":[]}]}},{"annotations":[],"serializedName":"quantity","default":{"kind":"nothing"},"name":"quantity","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"ferovinum.app.db","name":"NumberOfUnits"}},"parameters":[]}}]}},"name":"ProductQuantity","version":{"kind":"nothing"}}};

export const snProductQuantity: ADL.ScopedName = {moduleName:"ferovinum.app.common", name:"ProductQuantity"};

export function texprProductQuantity(): ADL.ATypeExpr<ProductQuantity> {
  return {value : {typeRef : {kind: "reference", value : snProductQuantity}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "ferovinum.app.common.ProductIdQuantity" : ProductIdQuantity_AST,
  "ferovinum.app.common.ProductQuantity" : ProductQuantity_AST
};
