import React, { useCallback } from "react";
import { assertNotUndefined } from "utils/hx/util/types";
import { AppService } from "adl-gen/app-service";
import { downloadCSVFile } from "utils/csv-utils";
import { useSelectedStorageLocation } from "../../../layouts/portal-page-layout/portal-page";
import { useAppService } from "../../../../hooks/use-app-service";
import { StorageLocProductBalancesPageView } from "./storage-loc-product-balances-page-view";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { ProductId, snTopLevelUnitType, TopLevelUnitType } from "adl-gen/ferovinum/app/db";
import { adlMapToMap, getFormLabelForUnionField } from "utils/adl-utils";
import { ProductBalance } from "adl-gen/ferovinum/app/api";

export const StorageLocProductBalancesPageTypes = ["Current balance", "Stock adjustments"] as const;
export type StorageLocProductBalancePageTab = (typeof StorageLocProductBalancesPageTypes)[number];

const downloadCSVWithStoragePrefix = (csvData: string, fileNamePrefix: string, storageLocationName: string) =>
  downloadCSVFile(csvData, `${fileNamePrefix}@${storageLocationName}`);

export const StorageLocProductBalancesPage = () => {
  const service: AppService = useAppService();
  const [showAlert] = useAlert();

  const selectedStorageLocation = assertNotUndefined(useSelectedStorageLocation());
  const [selectedTab, setSelectedTab] = React.useState<StorageLocProductBalancePageTab>("Current balance");

  const getStockProductBalances = useCallback(async () => {
    const productBalances = await service.getProductBalances({
      storageLocationId: selectedStorageLocation.id,
      topLevelUnitTypeOption: { kind: "all" },
    });

    return productBalances.reduce((map, pb) => {
      map.set(pb.key, pb.value);
      return map;
    }, new Map<TopLevelUnitType, ProductBalance[]>());
  }, [selectedStorageLocation, service]);
  const [loadingStockProductBalances] = useLoadingDataState(getStockProductBalances, true);

  const getProductionOrderReservations = useCallback(
    async (productId: ProductId) => {
      const resp = await service.getProductReservationsAtLoc({
        storageLocationId: selectedStorageLocation.id,
        productId,
      });
      if (resp.kind === "notFound") {
        await showAlert({ title: "Error", body: "Product reservations could not be found" });
        return [];
      }
      return resp.value;
    },
    [selectedStorageLocation.id, service, showAlert],
  );

  const getStockAdjustments = useCallback(async () => {
    return adlMapToMap(
      await service.getStorageLocationStockAdjustments({
        storageLocationId: selectedStorageLocation.id,
        topLevelUnitTypeOption: {
          kind: "all",
        },
      }),
    );
  }, [selectedStorageLocation.id, service]);
  const [loadingStockAdjustments] = useLoadingDataState(getStockAdjustments, true);

  const downloadAsCSV = useCallback(
    async (topLevelUnitType: TopLevelUnitType) => {
      return await service
        .getProductTypeBalancesCSV({ storageLocationId: selectedStorageLocation.id, topLevelUnitType })
        .then(csv => {
          downloadCSVWithStoragePrefix(
            csv,
            `Ferovinum ${getFormLabelForUnionField(snTopLevelUnitType, topLevelUnitType)}`,
            selectedStorageLocation.value.locationName,
          );
        });
    },
    [service, selectedStorageLocation],
  );

  return (
    <StorageLocProductBalancesPageView
      selectedTab={selectedTab}
      onChangeTab={setSelectedTab}
      productBalances={loadingStockProductBalances}
      stockAdjustments={loadingStockAdjustments}
      downloadAsCSV={downloadAsCSV}
      getProductionOrderReservations={getProductionOrderReservations}
    />
  );
};
