import { assertNever } from "utils/hx/util/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { makeApiServices } from "adl-service/api-utils";
import { SupplierPOView } from "adl-gen/ferovinum/app/procurement";
import { LoadingValue, isErrored, isLoaded, isLoading } from "utils/utility-types";
import { SupplierNewDealRequestsTabView } from "./supplier-new-deal-requests-page-view";
import { SupplierNewDealRequestAction } from "./supplier-new-deal-requests-page";
import { makeActionLinkConstants } from "adl-gen/ferovinum/app/api";

const { SupplierNewDealRequestsJwtParam, SupplierNewDealRequestsSupplierIdParam } = makeActionLinkConstants({});

export const ActionLinkSupplierNewDealRequestsPage = () => {
  const { supplierId, service } = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const supplierId = searchParams.get(SupplierNewDealRequestsSupplierIdParam);
    const jwt = searchParams.get(SupplierNewDealRequestsJwtParam);
    const service = jwt ? makeApiServices(jwt).app : undefined;
    return { supplierId, service };
  }, []);
  const [dealView, setDealView] = useState<LoadingValue<SupplierPOView>>({ state: "loading" });
  const [showAlert] = useAlert();

  const fetchNewDealRequestsForSupplier = useCallback(() => {
    if (service && supplierId) {
      service
        .actionLinkSupplierPurchaseOrders({
          supplierId,
          filter: "inProgress",
          offset: 0,
          count: 0,
        })
        .then(result => setDealView({ state: "success", value: result }))
        .catch(_ => showAlert({ title: "Error loading requests", body: "Could not load requests" }));
    } else {
      setDealView({ state: "error", error: new Error("Cannot create api service.") });
    }
  }, [service, supplierId, setDealView, showAlert]);

  useEffect(() => {
    fetchNewDealRequestsForSupplier();
  }, [fetchNewDealRequestsForSupplier]);

  const loadingNewDealRequests = useMemo<LoadingValue<SupplierPOView[]>>(() => {
    if (isLoaded(dealView)) {
      return { state: "success", value: [dealView.value] };
    } else if (isErrored(dealView)) {
      return dealView;
    } else if (isLoading(dealView)) {
      return dealView;
    } else {
      assertNever(dealView);
    }
  }, [dealView]);

  const onNewDealRequestAction = useCallback(
    async (action: SupplierNewDealRequestAction) => {
      if (action.kind === "accept") {
        await service?.actionLinkSupplierAcceptNewDealRequest(action.value);
      } else {
        throw new Error("Update new deal request not supported.");
      }
    },
    [service],
  );

  return (
    <SupplierNewDealRequestsTabView
      filter={"inProgress"}
      loadingNewDealRequests={loadingNewDealRequests}
      refreshNewDealRequests={() => fetchNewDealRequestsForSupplier()}
      onNewDealRequestAction={onNewDealRequestAction}
      disallowUpdate={true}
    />
  );
};
