// Custom hook used to determine whether the speed dial is displayed or not

import { useMemo } from "react";
import { AppRoutes } from "../../../../app/app-routes";
import { useSelectedEntity } from "../../../layouts/portal-page-layout/portal-page";
import { useLocation } from "react-router-dom";

export const useActionsSpeedDial = () => {
  const location = useLocation();
  const selectedEntity = useSelectedEntity();
  const showSpeedDial = useMemo<boolean>(() => {
    switch (selectedEntity.type) {
      case "org": {
        return (
          location.pathname === AppRoutes.Dashboard ||
          location.pathname === AppRoutes.RepurchaseSaleOrders ||
          location.pathname === AppRoutes.OrgProductionOrders ||
          location.pathname === AppRoutes.Inventory ||
          location.pathname.includes(AppRoutes.OrganisationNewDealRequests) ||
          location.pathname === AppRoutes.OrganisationPurchaseRequests ||
          location.pathname === AppRoutes.OrganisationExpiringStock
        );
      }
      default:
        return false;
    }
  }, [location.pathname, selectedEntity.type]);
  return showSpeedDial;
};
