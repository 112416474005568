import { FormikValues, useFormik } from "formik";
import _ from "lodash";

export type FormikForm<T extends FormikValues = FormikValues> = ReturnType<typeof useFormik<T>>;

export type FormikValuesRecord = Record<string, FormikValues>;

function buildPath(path: (string | number | symbol)[] | string | number | symbol): string {
  if (Array.isArray(path)) {
    return path.reduce((res: string, k, idx) => {
      if (typeof k === "number") {
        return res + `[${k}]`;
      } else {
        return res + ((idx === 0 ? "" : ".") + String(k));
      }
    }, "");
  } else {
    return String(path);
  }
}

export class FormField<V> {
  public readonly path: string;

  constructor(
    private readonly form: FormikForm<any>,
    path: (string | number | symbol)[] | string | number | symbol,
  ) {
    this.path = buildPath(path);
  }

  getSubField<E>(path: (string | number | symbol)[] | string | number | symbol): FormField<E> {
    return new FormField<E>(this.form, Array.isArray(path) ? [this.path, ...path] : [this.path, path]);
  }

  handleChange<E>(e: React.ChangeEvent<E>): void {
    this.form.handleChange(e);
    this.setTouched(true);
  }

  get(): V | undefined {
    return _.get(this.form.values, this.path);
  }

  set(value: V | undefined, shouldValidate?: boolean): void {
    this.form.setFieldValue(this.path, value, shouldValidate);
  }

  setValidateAndTouch(value: V | undefined): void {
    this.set(value, true);
    this.setTouched(true);
  }

  getError(checkTouched = false) {
    return this.checkTouched(checkTouched) ? _.get(this.form.errors, this.path) : undefined;
  }

  get formErrors() {
    return this.form.errors;
  }

  hasError(checkTouched = false): boolean {
    return this.checkTouched(checkTouched) && Boolean(this.getError());
  }

  clearError(): void {
    this.form.setFieldError(this.path, undefined);
  }

  handleBlur(e: React.FocusEvent): void {
    this.form.handleBlur(e);
    this.setTouched(true);
  }

  private isTouched() {
    return Boolean(_.get(this.form.touched, this.path));
  }

  private setTouched(v: boolean) {
    this.form.setFieldTouched(this.path, v);
  }

  private checkTouched(checkTouched: boolean): boolean {
    return !checkTouched || this.isTouched();
  }
}
