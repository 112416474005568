import React from "react";
import { Loader } from "components/widgets/loader/loader";
import { useParams } from "react-router-dom";
import { QrProductInfoPageView } from "./product-info-page-view";
import { useAppService } from "../../../hooks/use-app-service";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { isLoaded } from "utils/utility-types";

export interface QrProductInfoPageRouteParams {
  hash: string;
}

export const QrProductInfoPage = ({}) => {
  const service = useAppService();
  const { hash } = useParams<QrProductInfoPageRouteParams>();

  const loadProductInfo = React.useCallback(async () => {
    return service.qrProductInfo(hash);
  }, [service, hash]);

  const [loadingProductInfo] = useLoadingDataState(loadProductInfo);
  return (
    <Loader loadingStates={[loadingProductInfo]}>
      {isLoaded(loadingProductInfo) && <QrProductInfoPageView info={loadingProductInfo.value} />}
    </Loader>
  );
};
