import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FEROVINUM_CONTACT_URL } from "components/assets/url";
import { useFormik } from "formik";
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { DbKey, WithDbId } from "adl-gen/common/db";
import {
  NewSupplier,
  StorageLocation,
  Supplier,
  Country,
  valuesCountry,
  valuesCurrency,
  Currency,
  NewDealRequestType,
} from "adl-gen/ferovinum/app/db";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { Link } from "components/widgets/link/link";
import { NewDealRequestFlowStepper } from "../../../../widgets/flow-stepper/new-deal-request-flow-stepper";
import { MixedSchema, array, mixed, object, string } from "yup";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import _ from "lodash";
import { ExpandableRadio } from "components/widgets/inputs/expandable-radio/expandable-radio";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { isNewSupplier, NewDealRequestFlowState } from "./organisation-create-new-deal-request-page";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { ConfirmationDialog } from "components/widgets/confirmation-dialog/confirmation-dialog";
import { countryCodeToCountryName, getCurrencySymbol, stringToCountryCode } from "utils/conversion-utils";
import { StorageLocationView } from "adl-gen/ferovinum/app/api";
import { EMAILREGEX } from "utils/email-regex";

export interface CreateNewDealRequestFormValues {
  variant?: NewDealRequestType;
  selectedSupplier?: WithDbId<Supplier> | NewSupplier;
  selectedStorageLocation?: StorageLocationView;
}

export interface OrganisationCreateNewDealRequestPageViewProps {
  availableNewDealRequestTypes: Set<NewDealRequestType>;
  onSupplierSearch(searchTerm: string): Promise<WithDbId<Supplier>[]>;
  storageLocations: StorageLocationView[];
  onNext(formValues: CreateNewDealRequestFormValues): void;
  initialValues?: NewDealRequestFlowState;
}

export const OrganisationCreateNewDealRequestPageView = ({
  availableNewDealRequestTypes,
  onSupplierSearch,
  storageLocations,
  onNext,
  initialValues,
}: OrganisationCreateNewDealRequestPageViewProps) => {
  const validationSchema = useMemo(
    () =>
      object().shape({
        variant: mixed<NewDealRequestType>().required(),
        selectedSupplier: mixed<WithDbId<Supplier> | NewSupplier>()
          .nullable()
          .when("variant", {
            is: (variant: string) => variant === "newStock",
            then: (schema: MixedSchema<unknown>) => schema.required(),
          }),
        selectedStorageLocation: mixed<StorageLocationView>().when("variant", {
          is: (variant: string) => variant === "existingStock",
          then: schema => schema.required(),
        }),
      }),
    [],
  );

  const initialFormValues = useMemo((): CreateNewDealRequestFormValues => {
    const variant =
      initialValues !== undefined
        ? initialValues.variant
        : availableNewDealRequestTypes.size > 0
        ? [...availableNewDealRequestTypes][0]
        : undefined;
    return {
      variant,
      selectedSupplier: initialValues?.variant === "newStock" ? initialValues?.supplier : undefined,
      selectedStorageLocation: initialValues?.storageLocation,
    };
  }, [initialValues, availableNewDealRequestTypes]);

  const handleSubmit = useCallback((values: CreateNewDealRequestFormValues) => onNext(values), [onNext]);

  const form = useFormik<CreateNewDealRequestFormValues>({
    initialValues: initialFormValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  function comingSoonLabel(label: string, disabled: boolean) {
    return (
      <Stack direction="row" spacing={1}>
        <Typography>{label}</Typography>
        {disabled && <Typography color="common.grey7">{" (coming soon)"}</Typography>}
      </Stack>
    );
  }

  return (
    <PortalPageContent header={<OrganisationCreatePurchaseOrderHeader variant={form.values.variant ?? "newStock"} />}>
      <Stack spacing={2} justifyContent="space-between">
        <Grid container>
          <Grid item xl={7} xs={12}>
            <ExpandableRadio
              value={form.values.variant}
              onChange={v => form.setFieldValue("variant", v)}
              options={[
                {
                  value: "newStock",
                  label: comingSoonLabel(
                    "Procure new stock from a supplier",
                    !availableNewDealRequestTypes.has("newStock"),
                  ),
                  disabled: !availableNewDealRequestTypes.has("newStock"),
                  children: (
                    <SupplierSearch
                      onSearch={onSupplierSearch}
                      value={form.values.selectedSupplier ?? null}
                      onChange={v => form.setFieldValue("selectedSupplier", v)}
                    />
                  ),
                },
                {
                  value: "existingStock",
                  disabled: !availableNewDealRequestTypes.has("existingStock"),
                  label: comingSoonLabel(
                    "Upload existing stock that I own",
                    !availableNewDealRequestTypes.has("existingStock"),
                  ),
                  children: (
                    <StorageLocationSelect
                      storageLocations={storageLocations}
                      value={form.values.selectedStorageLocation}
                      onChange={v => form.setFieldValue("selectedStorageLocation", v)}
                    />
                  ),
                },
              ]}
            />
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={() => form.submitForm()} disabled={!form.isValid}>
            Next
          </Button>
        </Stack>
      </Stack>
    </PortalPageContent>
  );
};

interface OrganisationCreatePurchaseOrderHeaderProps {
  variant: NewDealRequestType;
}
const OrganisationCreatePurchaseOrderHeader = ({ variant }: OrganisationCreatePurchaseOrderHeaderProps) => {
  return (
    <PortalPageContentHeader
      variant="split"
      title="Stock Type"
      subtitles={[{ text: "What type of stock would you like to onboard?" }]}
      right={<NewDealRequestFlowStepper activeStep={0} variant={variant} />}
    />
  );
};

const SEARCH_TERM_LENGTH_THRESHOLD = 2;

type SupplierTypes = WithDbId<Supplier> | NewSupplier;
interface SupplierSearchProps {
  onSearch(searchTerm: string): Promise<WithDbId<Supplier>[]>;
  value: SupplierTypes | null;
  onChange(supplier: SupplierTypes | null): void;
}
const SupplierSearch = ({ onSearch, value, onChange }: SupplierSearchProps) => {
  const [suppliers, setSuppliers] = useState<SupplierTypes[]>([]);
  const [loadingMatchedSuppliers, setLoadingMatchedSuppliers] = useState(false);
  const [openOptions, setOpenOptions] = useState<boolean>(false);
  const [addNewSupplierDialogOpen, setAddNewSupplierDialogOpen] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const onSupplierSearch = useCallback(
    async (searchTerm: string) => {
      setLoadingMatchedSuppliers(true);
      const matchedSuppliers = await onSearch(searchTerm.trim());
      setSuppliers(matchedSuppliers);
      setLoadingMatchedSuppliers(false);
    },
    [onSearch],
  );

  const debouncedOnSupplierSearch = useMemo(() => {
    return _.debounce(onSupplierSearch, 350);
  }, [onSupplierSearch]);

  useEffect(() => {
    return debouncedOnSupplierSearch.cancel;
  }, [debouncedOnSupplierSearch]);

  return (
    <Stack spacing={3}>
      <Typography>Which supplier do you want to source from?</Typography>
      <Autocomplete<SupplierTypes>
        onInputChange={(_e, v) => {
          if (v.length === SEARCH_TERM_LENGTH_THRESHOLD) {
            setOpenOptions(true);
            setLoadingMatchedSuppliers(true);
          } else if (v.length < SEARCH_TERM_LENGTH_THRESHOLD) {
            setSuppliers([]);
            setOpenOptions(false);
          }
          void debouncedOnSupplierSearch(v);
          setSearchTerm(v);
        }}
        open={openOptions}
        onOpen={() => {
          if (searchTerm.length >= SEARCH_TERM_LENGTH_THRESHOLD) {
            setOpenOptions(true);
          }
        }}
        onClose={() => setOpenOptions(false)}
        options={searchTerm.length >= SEARCH_TERM_LENGTH_THRESHOLD ? suppliers : ([] as WithDbId<Supplier>[])}
        loading={loadingMatchedSuppliers}
        value={value}
        onChange={(_, v) => {
          onChange(v);
          setOpenOptions(false);
        }}
        inputValue={searchTerm}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label="Search by supplier name"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loadingMatchedSuppliers && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        clearIcon={<CancelOutlinedIcon sx={{ color: theme => theme.palette.common.grey4 }} />}
        getOptionLabel={options => (isNewSupplier(options) ? options.name : options.value.name)}
        noOptionsText={
          <Stack direction="row" spacing={1}>
            <Typography>Supplier could not be found.</Typography>
            <Link variant="big" color="grey5" onClick={() => setAddNewSupplierDialogOpen(true)}>
              Add new supplier
            </Link>
          </Stack>
        }
        filterOptions={o => o}
        isOptionEqualToValue={(option: SupplierTypes, value: SupplierTypes) => {
          return isNewSupplier(value) ? true : isNewSupplier(option) ? false : option.id === value.id;
        }}
      />

      <AddNewSupplierDialog
        open={addNewSupplierDialogOpen}
        onAddNewSupplier={(supplier: NewSupplier) => {
          onChange(supplier);
          setAddNewSupplierDialogOpen(false);
        }}
        onClose={() => setAddNewSupplierDialogOpen(false)}
      />
    </Stack>
  );
};

interface AddNewSupplierDialogProps {
  open: boolean;
  onAddNewSupplier(newSupplier: Partial<NewSupplier>): void;
  onClose(): void;
}

const newSupplierValidationSchema = object().shape({
  name: string().trim().required(),
  contactName: string().trim().required(),
  contactEmail: string().trim().matches(EMAILREGEX).required(),
  contactMobile: string().trim().max(15).required(),
  addressLine1: string().trim().optional(),
  addressLine2: string().trim().optional(),
  town: string().trim().required(),
  postCode: string().trim().required(),
  country: string().oneOf(valuesCountry).required(),
  approvedCurrencies: array().of(string().oneOf(valuesCurrency)).required().min(1),
});

const AddNewSupplierDialog = ({ open, onAddNewSupplier, onClose }: AddNewSupplierDialogProps) => {
  const onSubmit = useCallback(
    async (newSupplier: Partial<NewSupplier>) => {
      const values = newSupplierValidationSchema.cast(newSupplier) as unknown as Partial<NewSupplier>;
      return await onAddNewSupplier(values);
    },
    [onAddNewSupplier],
  );

  const form = useFormik<Partial<NewSupplier>>({
    initialValues: {
      name: "",
      contactName: "",
      contactEmail: "",
      contactMobile: "",
      addressLine1: "",
      addressLine2: "",
      town: "",
      postCode: "",
      country: undefined,
      approvedCurrencies: [],
    },
    validationSchema: newSupplierValidationSchema,
    validateOnMount: true,
    onSubmit,
  });

  const clearForm = useCallback(() => {
    form.resetForm();
  }, [form]);

  const onClickAdd = useCallback(async () => {
    await form.submitForm();
    clearForm();
  }, [clearForm, form]);

  const commonInputProps = useMemo(
    () => ({
      onBlur: form.handleBlur,
      onChange: form.handleChange,
      fullWidth: true,
    }),
    [form.handleBlur, form.handleChange],
  );

  /// Note: Autocomplete won't correctly set the text field name used by formik
  /// need to set the state manually for autocomplete
  const handleAutoCompleteChange = useCallback(
    (fieldName: string) => (_e: SyntheticEvent, value: unknown) => {
      form.setFieldTouched(fieldName, true);
      form.setFieldValue(fieldName, value, true);
    },
    [form],
  );

  return (
    <ConfirmationDialog
      open={open}
      title="Add new supplier"
      acceptAction={{ onClick: onClickAdd, title: "Add", disabled: !form.isValid }}
      cancelAction={{ onClick: onClose }}
      maxWidth="sm"
      fullWidth>
      <Stack spacing={2} mt={2}>
        <TextField
          label="Name"
          name={"name"}
          required
          value={form.values.name}
          error={form.touched.name && Boolean(form.errors.name)}
          {...commonInputProps}
        />
        <TextField
          label="Contact name"
          name={"contactName"}
          required
          value={form.values.contactName}
          error={form.touched.contactName && Boolean(form.errors.contactName)}
          {...commonInputProps}
        />
        <TextField
          label="Contact email"
          name={"contactEmail"}
          required
          value={form.values.contactEmail}
          error={form.touched.contactEmail && Boolean(form.errors.contactEmail)}
          {...commonInputProps}
        />
        <TextField
          label="Contact number"
          name={"contactMobile"}
          required
          value={form.values.contactMobile}
          error={form.touched.contactMobile && Boolean(form.errors.contactMobile)}
          {...commonInputProps}
        />
        <TextField
          label="Address line 1"
          name={"addressLine1"}
          value={form.values.addressLine1}
          error={form.touched.addressLine1 && Boolean(form.errors.addressLine1)}
          {...commonInputProps}
        />
        <TextField
          label="Address line 2"
          name={"addressLine2"}
          value={form.values.addressLine2}
          error={form.touched.addressLine2 && Boolean(form.errors.addressLine2)}
          {...commonInputProps}
        />
        <TextField
          label="Town"
          name={"town"}
          required
          value={form.values.town}
          error={form.touched.town && Boolean(form.errors.town)}
          {...commonInputProps}
        />
        <TextField
          label="Postcode"
          name={"postCode"}
          required
          value={form.values.postCode}
          error={form.touched.postCode && Boolean(form.errors.postCode)}
          {...commonInputProps}
        />
        <Autocomplete<Country>
          fullWidth
          options={valuesCountry
            .map(c => countryCodeToCountryName(c))
            .sort()
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map(n => stringToCountryCode(n)!)}
          value={form.values.country ?? null}
          onChange={handleAutoCompleteChange("country")}
          getOptionLabel={(c: Country) => countryCodeToCountryName(c)}
          renderInput={params => (
            <TextField
              {...params}
              label="Country"
              name={"country"}
              error={form.touched.country && Boolean(form.errors.country)}
              required
            />
          )}
        />
        {/** Autocomplete generic params is: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
         * In order to enable multiple select, the Multiple param must be set to true.
         * https://github.com/mui/material-ui/issues/21185#issuecomment-635208868 */}
        <Autocomplete<Currency, true, undefined, undefined>
          multiple
          options={valuesCurrency.sort()}
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} />
              {`${option} (${getCurrencySymbol(option)})`}
            </li>
          )}
          value={form.values.approvedCurrencies ?? []}
          onChange={handleAutoCompleteChange("approvedCurrencies")}
          renderInput={params => (
            <TextField
              {...params}
              label="Approved currencies"
              name={"approvedCurrencies"}
              error={form.touched.approvedCurrencies && Boolean(form.errors.approvedCurrencies)}
              required
              fullWidth
            />
          )}
        />
      </Stack>
    </ConfirmationDialog>
  );
};

interface StorageLocationSelectProps {
  storageLocations: StorageLocationView[];
  value?: StorageLocationView;
  onChange(loc: StorageLocationView): void;
}
const StorageLocationSelect = ({ storageLocations, value, onChange }: StorageLocationSelectProps) => {
  const [selectedStorageLoc, setSelectedStorageLoc] = useState<DbKey<StorageLocation>>(value?.id ?? "");

  const handleChange = useCallback(
    (id: DbKey<StorageLocation>) => {
      setSelectedStorageLoc(id);

      const foundLoc = storageLocations.find(sl => sl.id === id);
      if (foundLoc) {
        onChange(foundLoc);
      }
    },
    [onChange, storageLocations],
  );

  return (
    <Stack spacing={3}>
      <Typography>Which warehouse is the stock currently stored at?</Typography>

      <Stack direction="row" spacing={2} alignItems="center">
        <FormControl fullWidth>
          <InputLabel>Select warehouse</InputLabel>
          <Select value={selectedStorageLoc} label="Select warehouse" onChange={e => handleChange(e.target.value)}>
            {storageLocations.map(sl => (
              <MenuItem key={sl.id} value={sl.id}>
                {sl.storageLocationName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box>
          <Typography variant="body2" color="common.darkGrey" noWrap>
            {"Can't find the warehouse?"}
          </Typography>
          <Link
            variant="big"
            color="grey5"
            href={FEROVINUM_CONTACT_URL}
            target="_blank"
            typographyProps={{ noWrap: true }}>
            Contact Ferovinum
          </Link>
        </Box>
      </Stack>
    </Stack>
  );
};
