/* @generated from adl module ferovinum.app.productsaleorder */

import * as ADL from './../../runtime/adl';
import * as common_db from './../../common/db';
import * as ferovinum_app_db from './db';

export interface ProductSaleOrder {
  productId: common_db.DbKey<ferovinum_app_db.Product>;
  unitsOrdered: ferovinum_app_db.NumberOfUnits;
}

export function makeProductSaleOrder(
  input: {
    productId: common_db.DbKey<ferovinum_app_db.Product>,
    unitsOrdered: ferovinum_app_db.NumberOfUnits,
  }
): ProductSaleOrder {
  return {
    productId: input.productId,
    unitsOrdered: input.unitsOrdered,
  };
}

const ProductSaleOrder_AST : ADL.ScopedDecl =
  {"moduleName":"ferovinum.app.productsaleorder","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"productId","default":{"kind":"nothing"},"name":"productId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"ferovinum.app.db","name":"Product"}},"parameters":[]}]}},{"annotations":[],"serializedName":"unitsOrdered","default":{"kind":"nothing"},"name":"unitsOrdered","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"ferovinum.app.db","name":"NumberOfUnits"}},"parameters":[]}}]}},"name":"ProductSaleOrder","version":{"kind":"nothing"}}};

export const snProductSaleOrder: ADL.ScopedName = {moduleName:"ferovinum.app.productsaleorder", name:"ProductSaleOrder"};

export function texprProductSaleOrder(): ADL.ATypeExpr<ProductSaleOrder> {
  return {value : {typeRef : {kind: "reference", value : snProductSaleOrder}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "ferovinum.app.productsaleorder.ProductSaleOrder" : ProductSaleOrder_AST
};
