import React from "react";
import { Link as MaterialUiLink, LinkProps as MUILinkProps, Typography, TypographyProps } from "@mui/material";
import { CommonColors } from "@mui/material/styles/createPalette";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

interface LinkProps extends Omit<MUILinkProps, "variant" | "color"> {
  variant?: "small" | "big" | TypographyProps["variant"];
  color?: keyof CommonColors;
  typographyProps?: TypographyProps;
}

export const Link = ({ variant = "big", color = "hyperLink", typographyProps, ...otherProps }: LinkProps) => {
  const variantProp: MUILinkProps["variant"] =
    variant === "small" ? "hyperLinkSmall" : variant === "big" ? "hyperLinkBig" : variant;
  return (
    <Typography {...typographyProps} color={`common.${color}`}>
      <MaterialUiLink
        variant={variantProp}
        {...otherProps}
        color="inherit"
        fontSize={typographyProps?.variant ? "inherit !important" : undefined}
      />
    </Typography>
  );
};

export const INTERNAL_LINK_PREFIX = "internal:";

// Note: https://mui.com/material-ui/guides/routing/
export const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href?: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;

  // TODO Zhi, Martin hacky way to allow routing from admin-portal to main app.
  const isInternalLinkFromRoot = href?.startsWith(INTERNAL_LINK_PREFIX);
  const isExternalLink = href?.includes("http");
  // Map href (MUI) -> to (react-router)
  // Note: React router link doesn't work with external links out of the box https://github.com/remix-run/react-router/issues/1147#issuecomment-113180884
  if (href !== undefined) {
    if (isInternalLinkFromRoot) {
      const realHref = href.replace(INTERNAL_LINK_PREFIX, "");
      return <a ref={ref} href={realHref} target="_blank" rel="noreferrer" {...other} />;
    } else if (isExternalLink) {
      return <a ref={ref} href={href} target="_blank" rel="noreferrer" {...other} />;
    } else {
      // @ts-ignore - Slightly incompatible types of the key prop, should be solved by upgrading to react-router v6
      return <RouterLink ref={ref} to={href} {...other} />;
    }
  }
  return <a ref={ref} {...other} />;
});
LinkBehavior.displayName = "LinkBehavior";
