import { LoadingButton } from "@mui/lab";
import { SnackbarContent, Stack, TextField, Typography } from "@mui/material";
import React from "react";

import { makeActionLinkConstants } from "adl-gen/ferovinum/app/api";
import { LoginState } from "../../../app/identity-state";

export type ResetPasswordFormProps = Pick<LoginState, "resetPasswordError" | "onResetPassword" | "onLogout">;

type ValidationResult =
  | {
      valid: false;
      message: string;
    }
  | { valid: true };

/**
 * Helper method to validate some requirements of a password
 */
const validatePassword = (password: string, passwordConfirmation: string): ValidationResult => {
  if (password !== passwordConfirmation) {
    return { valid: false, message: "Passwords do not match" };
  }

  if (password.length < 8) {
    return { valid: false, message: "Password should be 8 or more characters" };
  }

  return { valid: true };
};

const { UserResetPasswordActionJwtParam } = makeActionLinkConstants({});

/**
 * Reset password page
 */
export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const activateAccountPage = location.pathname.includes("activate-account");
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState<string>("");
  const [formError, setFormError] = React.useState<undefined | string>(undefined);

  const onResetPassword = async () => {
    // Extract the JWT
    const searchParams = new URLSearchParams(location.search);
    const jwt = searchParams.get(UserResetPasswordActionJwtParam);

    if (!jwt) {
      setFormError("Something went wrong, close this window, try using the link in your email again.");
      return;
    }

    setFormError(undefined);
    const validationResult = validatePassword(password, passwordConfirmation);
    if (!validationResult.valid) {
      setFormError(validationResult.message);
      return;
    }

    setLoading(true);
    const res = await props.onResetPassword({ password }, jwt);
    setLoading(false);
    if (res.success) {
      props.onLogout();
    }
  };

  /** Render the page */
  return (
    <Stack spacing={8}>
      <Stack spacing={2}>
        {activateAccountPage && <Typography>Set your password to complete your Ferovinum account</Typography>}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="New Password"
          type="password"
          id="password"
          autoComplete="new-password"
          onChange={e => setPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="passwordConfirmation"
          label="Confirm New Password"
          type="password"
          id="passwordConfirmation"
          autoComplete="new-password"
          onChange={e => setPasswordConfirmation(e.target.value)}
        />

        {(props.resetPasswordError || formError) && (
          <SnackbarContent
            message={props.resetPasswordError || formError}
            sx={{
              marginTop: theme => theme.spacing(1),
              marginBottom: theme => theme.spacing(1),
              backgroundColor: theme => theme.palette.error.main,
            }}
          />
        )}
      </Stack>

      <LoadingButton variant="contained" color="primary" fullWidth onClick={onResetPassword} loading={loading}>
        {activateAccountPage ? "Set" : "Change"} Password
      </LoadingButton>
    </Stack>
  );
};
