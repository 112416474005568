import { Currency } from "adl-gen/ferovinum/app/db";
import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { createBigDecimalCurrencyFormatter } from "utils/currency-utils";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";

export interface ProductionOrderTotalPriceCardProps {
  currency: Currency;
  setupCost: MonetaryValue;
  subtotalCost: MonetaryValue;
  totalCost: MonetaryValue;
  productionOrderTasksCosts: {
    targetQuantity: number;
    budgetQuantity?: number;
    pricePerUnit: MonetaryValue;
    setupCost: MonetaryValue;
  }[];
}
export const ProductionOrderTotalPriceCard = ({
  currency,
  setupCost,
  subtotalCost,
  totalCost,
  productionOrderTasksCosts,
}: ProductionOrderTotalPriceCardProps) => {
  const showMinLabel = productionOrderTasksCosts.some(costs => costs.budgetQuantity === undefined);
  return (
    <TableContainer component={Paper} sx={{ p: 2 }}>
      <Table>
        <TableBody>
          <PriceSummaryRow title="Subtotal" value={subtotalCost} currency={currency} isMinValue={showMinLabel} />
          <PriceSummaryRow title="Total setup fee" value={setupCost} currency={currency} />
          <PriceSummaryRow
            title="Estimated total"
            value={totalCost}
            topBorder
            currency={currency}
            isMinValue={showMinLabel}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PriceCell = styled(TableCell, { shouldForwardProp: prop => prop !== "topBorder" })<{ topBorder?: boolean }>(
  ({ theme, topBorder }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderBottom: 0,
    ...(topBorder && {
      borderTop: 1,
      borderTopStyle: "solid",
      borderTopColor: theme.palette.common.grey3,
    }),
  }),
);

const PriceSummaryRow = ({
  title,
  value,
  topBorder,
  currency,
  isCurrency = true,
  isMinValue,
}: {
  title: string;
  value: string;
  currency: Currency;
  topBorder?: boolean;
  isCurrency?: boolean;
  isMinValue?: boolean;
}) => {
  const bigDecimalFormatter = createBigDecimalCurrencyFormatter(currency)();

  return (
    <TableRow>
      <PriceCell align="right" topBorder={topBorder}>
        <Typography variant="subtitle1Bold" noWrap>
          {title}
        </Typography>
      </PriceCell>
      <PriceCell align="right" topBorder={topBorder}>
        <Typography variant="subtitle1" noWrap>
          {isCurrency ? `${isMinValue ? "min. " : ""}${bigDecimalFormatter.format(value)}` : value}
        </Typography>
      </PriceCell>
    </TableRow>
  );
};
