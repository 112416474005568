import { Card, Table, TableBody, TableContainer, Theme, useMediaQuery } from "@mui/material";
import { BigDecimal } from "adl-gen/common";
import { Currency, DutyAndVat } from "adl-gen/ferovinum/app/db";
import { DividerRow, FinancialValueRow } from "components/library/widgets/financial-value-table-widgets";
import React from "react";
export interface RejectedPurchaseRequestValuationCardProps {
  dealDiscountAmount: BigDecimal | null;
  netSubtotal: BigDecimal;
  dutyAndVat: DutyAndVat | null;
  purchaserCurrency: Currency;
  purchaserName?: string;
  showDuty?: boolean;
  subtotalIncVat?: boolean;
}

export const RejectedPurchaseRequestValuationCard = ({
  dealDiscountAmount,
  netSubtotal,
  dutyAndVat,
  purchaserCurrency,
  purchaserName,
  showDuty = true,
  subtotalIncVat = true,
}: RejectedPurchaseRequestValuationCardProps) => {
  const isSmScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down("md"));
  return (
    <Card sx={{ backgroundColor: "common.grey1", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      <TableContainer sx={{ overflowX: "hidden" }}>
        <Table stickyHeader={false} sx={{ backgroundColor: "common.grey1" }}>
          <colgroup>
            <col style={{ width: isSmScreen ? "50%" : "85%" }} />
            <col style={{ width: isSmScreen ? "50%" : "15%" }} />
          </colgroup>
          <TableBody>
            {dealDiscountAmount && (
              <FinancialValueRow title="Deal Discount" value={dealDiscountAmount} currency={purchaserCurrency} />
            )}
            {purchaserName && <DividerRow text={`Paid by ${purchaserName}`} />}
            <FinancialValueRow title="Net Subtotal" value={netSubtotal} currency={purchaserCurrency} />
            {dutyAndVat && (
              <>
                {showDuty && <FinancialValueRow title="Duty" value={dutyAndVat.duty} currency={purchaserCurrency} />}
                <FinancialValueRow
                  title={subtotalIncVat ? "Includes VAT" : "VAT"}
                  value={dutyAndVat.vat}
                  currency={purchaserCurrency}
                />
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
